/**
 * Required for specific business logic rules, includes a list of Japanese Writing Ids that are considered constant in the application.
 * TODO: Guarantee that the Ids present here are saved as such in the Database through proper seeding.
 * @todo DELETE! Replace with the new enum
 */
export enum JapaneseWritingOptions {
  /**
   * When a candidate can't write any Japanese at all.
   */
  CantWrite = 1,
}
