
import { defineComponent, PropType } from 'vue'
import { LanguageOriginal, LanguageLocaleToOriginalMapper, LocalizationPath } from '../../../../../../core/src'
import { LocalizationService } from '../../../../../core/src'
import { ButtonFactory } from '../../components/c-button/button.factory'
import CButton from '../../components/c-button/c-button.component.vue'
import { getLocalization } from '../../helper/c-translation-helper'
import { CAccordion } from '../c-accordion'

/**
 * Custom language switch accordion menu.
 */
export default defineComponent({
  components: {
    CAccordion,
    CButton
  },
  props: {
    App: {
      type: Object as PropType<any>,
      required: true
    }
  },
  setup(props, { emit }) {
    const selectLanguageTxt = LocalizationService.t<string>(getLocalization(props.App), LocalizationPath.selectLanguageTxt)
    const localization = getLocalization(props.App)
    const languages = LanguageLocaleToOriginalMapper
    const closeButton = ButtonFactory.button({
      classes: 'theme-shades-light1-white row-center-fullwidth align-center g-10 btn-md',
      label: LocalizationService.t<string>(localization, LocalizationPath.closeBtn),
      width: '150px',
      cyTag: 'alert-modal-close-btn'
    })

    const methods = {
      closeLanguageSwitchModal: (): void => {
        emit('close')
      },
      setLanguage: (language: LanguageOriginal): void => {
        emit('set', language)
      }
    }

    return {
      closeButton,
      methods,
      languages,
      selectLanguageTxt
    }
  }
})
