import {
  FilterKey,
  IDNameSeparator,
  IDSeparator,
  IFilterableDataSetItem,
  IJapaneseConversation,
  LocalizationData
} from '@tokyojob/core'
import { FilterQueryManager, FilterTagFactory, IFilterTag, IVacancyFilterTreeData } from '@tokyojob/frontend-components'
import { IApp } from '~/types/nuxt'
import { FilterNameMap } from '../contants/filter-name-map'
import { ISelectedVacancyFilters } from '../interface/i-selected-vacancy-filters'
import { Route } from 'vue-router'
import {
  ContractTypeDictionaryFactory,
  getJapaneseLanguageListStore,
  JapaneseLevelTypeDictionaryFactory
} from '@tokyojob/frontend-core'

interface IFilterQueryResult {
  hasRemovedAQuery: boolean
  query: Dictionary<string | (string | null)[]>
}

export class SelectedVacancyFiltersDomain {
  constructor(
    /**
     * Contains the domain for controlling the filter data in the URL
     */
    private readonly filterQueryManager: FilterQueryManager,
    /**
     * Generates the IFilterTag
     */
    private readonly filterTagFactory: FilterTagFactory,
    /**
     * Contains the tree data structure of the vacancy filters
     */
    private treeData: IVacancyFilterTreeData,
    /**
     * Contains the japanese language level
     */
    private readonly japaneseLanguageLevel: Record<string, IJapaneseConversation>
  ) {}

  /**
   * Sets the tree data of this object
   * @param treeData
   */
  setTreeData(treeData: IVacancyFilterTreeData): void {
    this.treeData = treeData
  }

  /**
   * Returns the data source of the passed FilterKey
   * @param filterKey
   * @param treeData
   * @returns
   */
  getDataSource(filterKey: FilterKey, localization?: LocalizationData): Record<string, any> {
    if (filterKey === FilterKey.contract) return ContractTypeDictionaryFactory.build(localization)
    else if (filterKey === FilterKey.japanese) {
      const jpStore = getJapaneseLanguageListStore()
      const jpList = jpStore.values()
      return JapaneseLevelTypeDictionaryFactory.build(jpList)
    }
    return this.treeData[filterKey]
  }

  /**
   * Extracts a data from the hierarchy using a specified path
   * @param hierarchy
   * @param path
   * @returns
   */
  getDataFromIFilterableDataSetDictionary(dataSet: Record<string, any>, path: number[]): IFilterableDataSetItem | undefined {
    try {
      if (!path || !path.length) throw Error('path is empty')
      let node = dataSet[path[0].toString()]
      for (let i = 1; i < path.length; ++i) {
        const key = path[i].toString()
        if (!node.children) throw Error('path is wrong')
        else node = node.children[key]
      }
      return node
    } catch (e) {
      return undefined
    }
  }

  /**
   * Returns the data of the passed FilterKey from the passed ISelectedVacancyFilters
   * @param filtersData
   * @param filterKey
   * @returns
   */
  getDataFromISelectedVacancyFilters<T>(filtersData: ISelectedVacancyFilters, filterKey: FilterKey): T {
    const propKey = FilterNameMap[filterKey as FilterKey]
    const path = propKey.split('.')
    let result: any = (filtersData as any)[path[0]]
    for (let i = 1; i < path.length; ++i) {
      if (!result) break
      result = result[path[i]]
    }
    if (typeof result === 'number') return (result.toString() as any) as T
    return result as T
  }

  /**
   * Returns a new object containing the newly added data
   * @param filtersData
   * @param filterKey
   * @param data
   * @returns
   */
  addDataToISelectedVacancyFilters(
    filtersData: ISelectedVacancyFilters,
    filterKey: FilterKey,
    data: string
  ): ISelectedVacancyFilters {
    const newFiltersData = { ...filtersData }
    const propKey = FilterNameMap[filterKey as FilterKey]
    const path = propKey.split('.')
    if (path.length === 1) {
      // Ex. propKey = ['visaType'] -> path = ['visaType']
      // then newFiltersData['visaType'] = data
      ;(newFiltersData as any)[path[0]] = data
    } else {
      // Ex. propKey = ['multiOption.contractType'] -> path = ['multiOption', 'contractType']
      // then newFiltersData['multiOption']['contractType'] = data
      let target: any = (newFiltersData as any)[path[0]]
      for (let i = 1; i < path.length - 1; ++i) {
        target = target[path[i]]
      }
      target[path[path.length - 1]] = data
    }
    return newFiltersData
  }

  /**
   * Removes a selected filter in the passed selection
   * @param filtersData
   * @param filterKey
   * @returns
   */
  removeSelection(filtersData: ISelectedVacancyFilters, filterKey: FilterKey): ISelectedVacancyFilters {
    const newFiltersData: ISelectedVacancyFilters = { ...filtersData }
    const propKey = FilterNameMap[filterKey as FilterKey]
    const path = propKey.split('.')
    if (path.length === 1) {
      // Ex. propKey = ['visaType'] -> path = ['visaType']
      // then delete newFiltersData['visaType']
      delete (newFiltersData as any)[path[0]]
    } else {
      // Ex. propKey = ['multiOption.contractType'] -> path = ['multiOption', 'contractType']
      // then delete newFiltersData['multiOption']['contractType']
      let target: any = (newFiltersData as any)[path[0]]
      for (let i = 1; i < path.length - 1; ++i) {
        target = target[path[i]]
      }
      delete target[path[path.length - 1]]
    }
    return newFiltersData
  }

  /**
   * Returns an array of IFilterTag depending on the passed ISelectedVacancyFilters
   * @param filtersData
   * @returns
   */
  createFilterTags(
    localization: LocalizationData,
    filtersData: ISelectedVacancyFilters,
    excludedFilterKeys: FilterKey[] = []
  ): IFilterTag[] {
    const filterTags: IFilterTag[] = []

    for (const filterKey in FilterKey) {
      if (excludedFilterKeys.findIndex((e) => e == (filterKey as FilterKey)) !== -1) continue

      const data: string = this.getDataFromISelectedVacancyFilters<string>(filtersData, filterKey as FilterKey)
      if (!data) continue

      const path = data.split('-').map((e) => parseInt(e))

      const dataSource = this.getDataSource(filterKey as FilterKey, localization)
      const item = this.getDataFromIFilterableDataSetDictionary(dataSource, path)
      if (item) {
        const iFilterTag = this.filterTagFactory.toTag(localization, item, filterKey as FilterKey)
        filterTags.push(iFilterTag)
      }
    }
    return filterTags
  }

  /**
   * Converts query object to ISelectedVacancyFilters
   * @param query
   * @returns
   */
  fromQuery(query: Dictionary<string | (string | null)[]>): ISelectedVacancyFilters {
    const visaType = this.filterQueryManager.getQueryValue(query, FilterKey.visa)
    const location = this.filterQueryManager.getQueryValue(query, FilterKey.location)
    const jobCategory = this.filterQueryManager.getQueryValue(query, FilterKey.category)
    const japaneseLevel = this.filterQueryManager.getQueryValue(query, FilterKey.japanese)
    const contractType = this.filterQueryManager.getQueryValue(query, FilterKey.contract)

    const jpLevel = japaneseLevel ? parseInt(japaneseLevel) : undefined

    return {
      visaType,
      location,
      jobCategory,
      japaneseLevel: jpLevel,
      multiOption: {
        contractType
      }
    }
  }

  /**
   * Removes the invalid queries
   * Ex.
   * {
   *  visa: '1034_japaneses-citizenship',
   *  contract: 1
   * }
   * is going to be
   * {
   *  contract: 1
   * }
   * because 1034_japaneses-citizenship is an invalid visa type path it should be 5-1034_japaneses-citizenship
   * @param query
   * @returns
   */
  filterInvalidQuery(App: IApp, route: Route, isSRR: boolean, url: string, redirect: Function): IFilterQueryResult {
    // We extract the query data from a data source, either from the URL or from the cookie
    let query: Dictionary<string | (string | null)[]> = {}
    if (isSRR) query = route.query
    else {
      const formattedFilters = App.state.formattedFilters
      if (formattedFilters && formattedFilters !== '') query = JSON.parse(formattedFilters)
    }

    // Process the filtering of invalid queries
    let hasRemovedAQuery: boolean = false
    const result: Dictionary<string | (string | null)[]> = { ...query }
    for (const filterKey in FilterKey) {
      const value = this.filterQueryManager.getQueryValue(query, filterKey as FilterKey)
      // If the filter key doesn't exist in the query, skip the filter key check
      if (!value) continue
      // If the data source doesn't exist, delete the filter key in the query string
      const source = this.getDataSource(filterKey as FilterKey)
      if (!source) {
        delete result[filterKey]
        hasRemovedAQuery = true
        continue
      }
      const path = value.split('-').map((e) => parseInt(e))
      const item = this.getDataFromIFilterableDataSetDictionary(source, path)
      // If the item doesn't exist, delete the filter key in the query string (most likely the path is invalid)
      if (!item) {
        delete result[filterKey]
        hasRemovedAQuery = true
      }
    }

    // If a query has been removed redirect the user to the properUrl with proper query string
    if (hasRemovedAQuery) {
      const queryString = Object.keys(result)
        .filter((key) => result[key] !== '')
        .map((key) => key + '=' + result[key])
        .join('&')
      App.state.formattedFilters = JSON.stringify(result)
      redirect(`${url}?${queryString}`)
    }

    return {
      hasRemovedAQuery,
      query: result
    }
  }

  /**
   * Converts the current ISelectedVacancyFilters to a query object
   * @param selectedVacancyFilters
   * @returns
   */
  toQuery(selectedVacancyFilters: ISelectedVacancyFilters): Dictionary<string | (string | null)[]> {
    const query: Dictionary<string | (string | null)[]> = {}
    for (const filterKey in FilterKey) {
      const propKey = FilterNameMap[filterKey as FilterKey]
      const pathKey = propKey.split('.')
      let target: any = { ...selectedVacancyFilters }
      do {
        const key = pathKey.splice(0, 1)[0]
        target = target[key]
      } while (pathKey.length > 0 && target)
      if (target) {
        const path = (target.toString() as string).split('-').map((e) => parseInt(e))
        const dataSource = this.getDataSource(filterKey as FilterKey)
        const item = this.getDataFromIFilterableDataSetDictionary(dataSource, path)
        if (item) {
          const formattedID = item.path ? `${item.path}${IDSeparator}${item.id}` : item.id.toString()
          if (item.slug) query[filterKey] = `${formattedID}${IDNameSeparator}${item.slug}`
          else query[filterKey] = `${formattedID}`
        }
      }
    }
    return query
  }
}
