import { ICandidateVisa } from '../../../models';
import { CompositeSpecification } from '../../base/specification';

/**
 * Whether the provided candidateVisa has a visa subtype that belongs to its visaType property.
 * @remarks The visaType and its children properties must be properly set for this to return the appropriate response.
 * The subtype id can be set in either visaSubTypeId or the visaSubType.id properties.
 */
export class VisaSubTypeBelongsToParentSpecification extends CompositeSpecification<ICandidateVisa> {
  isSatisfiedBy(candidateVisa: ICandidateVisa): boolean {
    const hasChildren =
      candidateVisa && candidateVisa.visaType && candidateVisa.visaType.children && candidateVisa.visaType.children.length > 0;
    if (!hasChildren) return false;

    const subType = candidateVisa.visaType!.children!.find(
      (e) => e.id === candidateVisa.visaSubTypeId || e.id === candidateVisa.visaSubType?.id,
    );
    return !!subType;
  }
}
