/**
 * The way in which a user is accessing our application.
 */
export enum UserPlatform {
  Web = 'Web',
  /**
   * Only used by candidates, since agents and admins cannot do anything anonymously
   */
  WebAnonymous = 'WebAnonymous',
  Messenger = 'Messenger',
  IOS = 'iOS',
  Android = 'Android',
}
