import { GatewayBase } from '@/base/gateways/base'
import {
  AnonymousApplicationVerifyPhoneDto,
  AnonymousApplicationVerifyVisaTypeDto,
  AnonymousApplicationWithdrawDto,
  ApplicationDtoFactory,
  IApplication,
  IApplicationStatusCandidateLog,
  IFilterableDataSetContextualDictionary,
  IUser,
  IVacancy,
  LanguageLocale,
  TransferApplicationsDto
} from '@tokyojob/core'
import {
  ApplicationLogsRequest,
  ApplicationRequest,
  ApplicationVerifyRequest,
  ApplicationVisaTypeVerifyRequest,
  ApplicationWithdrawRequest,
  ApplyVacancyRequest,
  CandidateApplicationRequest,
  CandidateStatusHierarchyRequest,
  GetGuestApplicationListRequest,
  GetGuestApplicationSingleRequest,
  MyApplicationsRequest,
  MyApplicationSingleRequest,
  CandidateApplicationIdAndVacancyIdListRequest,
  GuestApplicationTransferRequest
} from '../network/requests/application'

/**
 * Deals with all of the candidate's vacancy applications.
 */
export default class Application extends GatewayBase {
  /**
   * Creates a new application.
   * @todo Delete the old application methods.
   * @returns
   */
  async CreateApplication(
    user: IUser,
    application: IApplication,
    vacancy: IVacancy,
    locale: LanguageLocale
  ): Promise<IApplication> {
    const factory = new ApplicationDtoFactory()
    const applicationDto = factory.newAnonymousApplication(user, vacancy, locale)
    applicationDto.residenceCardConfirmation = application.residenceCardRequirementAccepted!
    const res = await this.apiClient.request(new ApplicationRequest(applicationDto))
    return res
  }

  /**
   * Creates a new application.
   * @todo Delete the old application methods.
   * @returns
   */
  async CandidateCreateApplication(vacancyId: number, locale: LanguageLocale): Promise<boolean> {
    const res = await this.apiClient.request(new CandidateApplicationRequest({ vacancyId, locale }))
    return res
  }

  async VerifyAnonymousApplication(vacancyId: number, phoneNumber: string): Promise<boolean> {
    const dto = new AnonymousApplicationVerifyPhoneDto()
    dto.vacancyId = vacancyId
    dto.phoneNumber = phoneNumber
    return this.apiClient.request(new ApplicationVerifyRequest(dto))
  }

  async VerifyAnonymousVisaTypeApplication(vacancyId: number, visaTypeId: number, visaSubTypeId: number): Promise<boolean> {
    const dto = new AnonymousApplicationVerifyVisaTypeDto()
    dto.vacancyId = vacancyId
    dto.visaTypeId = visaTypeId
    dto.visaSubTypeId = visaSubTypeId
    return this.apiClient.request(new ApplicationVisaTypeVerifyRequest(dto))
  }

  async GetGuestApplicationList(locale: string, showAllArchived?: boolean): Promise<IApplication[]> {
    return this.apiClient.request(new GetGuestApplicationListRequest(locale, showAllArchived))
  }

  async GetGuestApplicationSingle(locale: string, id: string, shortUrlId: string): Promise<IApplication> {
    return this.apiClient.request(new GetGuestApplicationSingleRequest(locale, id, shortUrlId))
  }

  async GetCandidateApplicationsList(locale: string, showAllArchived?: boolean): Promise<IApplication[]> {
    return this.apiClient.request(new MyApplicationsRequest(locale, showAllArchived))
  }

  async GetCandidateApplicationIdAndVacancyIdList(): Promise<IApplication[]> {
    return this.apiClient.request(new CandidateApplicationIdAndVacancyIdListRequest())
  }

  async GetCandidateApplicationSingle(locale: string, appId: string): Promise<IApplication> {
    return this.apiClient.request(new MyApplicationSingleRequest(locale, appId))
  }
  /**
   * Retrieves the candidate status hierarchy for the applications.
   * @returns
   */
  async GetCandidateStatusHierarchy(
    locale: LanguageLocale = LanguageLocale.USen
  ): Promise<IFilterableDataSetContextualDictionary> {
    return this.apiClient.request(new CandidateStatusHierarchyRequest(locale))
  }

  /**
   * Retrieves the logs for the specified application.
   * @param applicationId
   * @returns
   */
  async GetLogs(applicationId: number, locale: LanguageLocale): Promise<IApplicationStatusCandidateLog[]> {
    return this.apiClient.request(new ApplicationLogsRequest(applicationId, locale))
  }

  /**
   * Withdraws from the specified application.
   * @param applicationId
   * @param dto
   * @returns
   */
  async WithdrawApplication(applicationId: number, dto: AnonymousApplicationWithdrawDto): Promise<void> {
    return this.apiClient.request(new ApplicationWithdrawRequest(applicationId, dto))
  }

  /**
   * Transfers all guest applications to the candidate.
   * Only applications with a short url and that use the same phone number may be transferred.
   * @returns
   */
  async guestApplicationTransfer(dto: TransferApplicationsDto): Promise<boolean> {
    return this.apiClient.request(new GuestApplicationTransferRequest(dto))
  }
}
