
/**
 * Typed representation of language locales.
 */
export enum LanguageLocale {
  JAjp = 'JA-jp',
  USen = 'US-en',
  VIvn = 'VI-vn',
  ZHcn = 'ZH-cn'
}

/**
 * Default locale used throughout the application by default.
 */
export const defaultLanguageLocale = LanguageLocale.USen

/**
 * Default locale used by calendar components in the application.
 */
export const defaultCalendarLocale = LanguageLocale.JAjp
