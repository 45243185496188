import { IsDate, IsDefined, IsNumber, IsOptional } from 'class-validator';

/**
 * Candidate Japanese Language Skills updpate request structure.
 */
export class CandidateJapaneseLanguageSkillsDto {
  /**
   * Conversation skill id.
   */
  @IsOptional()
  @IsNumber()
  japaneseConversationId?: number;
}
