export class LocalizationPath {
  // SIDE MENU
  public static readonly followsUsOn = ['sideMenu', 'followsUsOn'];
  public static readonly selectLanguageTxt = ['sideMenu', 'selectLanguageTxt'];
  public static readonly goToAgentDashboardText = ['sideMenu', 'goToAgentDashboardText'];

  // INDEX PAGE
  public static readonly allJobs = ['sideMenu', 'home', 'allJobs'];
  public static readonly jobsInMyArea = ['sideMenu', 'home', 'jobsInMyArea'];
  public static readonly sidebarCandidateTitle = ['sideMenu', 'candidate', 'sidebarCandidateTitle'];
  public static readonly myJobs = ['sideMenu', 'candidate', 'myJobs'];
  public static readonly myProfile = ['sideMenu', 'candidate', 'myProfile'];
  public static readonly myAccount = ['sideMenu', 'candidate', 'myAccountLink'];

  public static readonly jobsForText = ['indexPage', 'bannerText', 'text1', 'text'];
  public static readonly jobsForClass = ['indexPage', 'bannerText', 'text1', 'class'];
  public static readonly foreignersText = ['indexPage', 'bannerText', 'text2', 'text'];
  public static readonly foreignersClass = ['indexPage', 'bannerText', 'text2', 'class'];
  public static readonly inText = ['indexPage', 'bannerText', 'text3', 'text'];
  public static readonly inClass = ['indexPage', 'bannerText', 'text3', 'class'];
  public static readonly japanText = ['indexPage', 'bannerText', 'text4', 'text'];
  public static readonly japanClass = ['indexPage', 'bannerText', 'text4', 'class'];
  public static readonly map = ['indexPage', 'mapSearchRibbon'];
  public static readonly filterSideMenu = ['indexPage', 'filterSideMenu'];
  public static readonly filterSideMenuMap = ['indexPage', 'filterSideMenuMap'];

  // FOOTER
  public static readonly tokyoJobSummary = ['footer', 'tokyoJobSummary'];
  public static readonly jobsNewsletter = ['footer', 'jobsNewsletter'];
  public static readonly jobsNewsletterBody = ['footer', 'jobsNewsletterBody'];
  public static readonly subscribe = ['footer', 'subscribe'];

  // OTP MODAL
  public static readonly otpModalTitle = ['otpModal', 'title'];
  public static readonly otpInstructions1 = ['otpModal', 'otpInstructions1'];
  public static readonly otpInstructions2 = ['otpModal', 'otpInstructions2'];
  public static readonly otpRemainingTime = ['otpModal', 'remainingTime'];
  public static readonly otpSubmitBtnText = ['otpModal', 'submitBtnText'];
  public static readonly otpRemainingAttempts = ['otpModal', 'remainingAttempts'];
  public static readonly otpTextboxLabel = ['otpModal', 'otpTextboxLabel'];
  public static readonly otpTextboxPlaceholder = ['otpModal', 'otpTextboxPlaceholder'];

  // CHAT COMPONENT
  public static readonly chatHeaderTitleText = ['chat', 'header', 'titleText'];
  public static readonly chatBodyChatStartText = ['chat', 'body', 'chatStartText'];
  public static readonly chatBodyUserText = ['chat', 'body', 'userText'];
  public static readonly chatBodyAgentText = ['chat', 'body', 'agentText'];
  public static readonly chatBodyCandidateText = ['chat', 'body', 'candidateText'];
  public static readonly chatFooterPlaceholderText = ['chat', 'footer', 'placeholderText'];
  public static readonly chatFooterChatClosedText = ['chat', 'footer', 'chatClosedText'];

  // TEXT INPUT VALIDATION
  public static readonly textInputMinCharInit = ['validation', 'minCharInit'];
  public static readonly textInputMinCharsCounter = ['validation', 'minCharsCounter'];
  public static readonly textInputMaxCharsCounter = ['validation', 'maxCharsCounter'];
  public static readonly textInputCurrentCharCounter = ['validation', 'currentCharCounter'];

  // FILTER BAR
  public static readonly contractType = ['filterBar', 'filter', 'otherFilters', 'header'];
  public static readonly video = ['filterBar', 'filter', 'visaType', 'video'];
  public static readonly regularEmployee = ['filterBar', 'filter', 'otherFilters', 'regularEmployee'];
  public static readonly temporaryEmployee = ['filterBar', 'filter', 'otherFilters', 'temporaryEmployee'];
  public static readonly watchAShortVideo = ['filterBar', 'filter', 'visaType', 'watchAShortVideo'];
  public static readonly dispatch = ['filterBar', 'filter', 'otherFilters', 'dispatch'];
  public static readonly dayLabor = ['filterBar', 'filter', 'otherFilters', 'dayLabor'];
  public static readonly outsourcing = ['filterBar', 'filter', 'otherFilters', 'outsourcing'];
  public static readonly contracting = ['filterBar', 'filter', 'otherFilters', 'contracting'];
  public static readonly jobCategory = ['filterBar', 'filter', 'jobCategory', 'title'];
  public static readonly partTimeEmployee = ['filterBar', 'filter', 'otherFilters', 'partTimeEmployee'];
  public static readonly jobCategoryPlaceholder = ['filterBar', 'filter', 'jobCategory', 'placeholder'];
  public static readonly location = ['filterBar', 'filter', 'location', 'title'];
  public static readonly locationPlaceholder = ['filterBar', 'filter', 'location', 'placeholder'];
  public static readonly visaType = ['filterBar', 'filter', 'visaType', 'title'];
  public static readonly visaTypePlaceholder = ['filterBar', 'filter', 'visaType', 'placeholder'];
  public static readonly otherFilter = ['filterBar', 'filter', 'otherFilters', 'title'];
  public static readonly japaneseLevel = ['filterBar', 'filter', 'japaneseLevel', 'title'];
  public static readonly noTags = ['filterBar', 'tagPlaceholderText'];
  public static readonly filterResultsCategoriesLabel = ['filterBar', 'filterResultsCategories'];
  public static readonly MinCharText = ['filterBar', 'minCharText'];
  public static readonly NoResultsFound = ['filterBar', 'noResultsFound'];
  public static readonly findMyVisaType = ['filterBar', 'filter', 'visaType', 'findMyVisaType'];
  public static readonly FilterSideMenuTitle = ['filterBar', 'filterSideMenu', 'title'];

  public static readonly visa = ['filterKeys', 'visa'];
  public static readonly locationFilterKeys = ['filterKeys', 'location'];
  public static readonly category = ['filterKeys', 'category'];
  public static readonly contract = ['filterKeys', 'contract'];
  public static readonly japanese = ['filterKeys', 'japanese'];

  // MAP PAGE
  public static readonly multipleVacanciesAtLocation = ['mapPage', 'addressSearchBar', 'multipleVacancies'];
  public static readonly mapSearchInstructions = ['mapPage', 'addressSearchBar', 'helperText'];
  public static readonly mapPageFindMyLocation = ['mapPage', 'addressSearchBar', 'findMyLocationBtnText'];
  public static readonly mapPagePlaceholder = ['mapPage', 'addressSearchBar', 'placeholderText'];
  public static readonly mapPageGPSError = ['mapPage', 'addressSearchBar', 'gpsErrorMessage'];
  public static readonly mapPagePermissionError = ['mapPage', 'addressSearchBar', 'gpsPermissionsErrorMessage'];
  public static readonly mapPageGpsNotFoundError = ['mapPage', 'addressSearchBar', 'gpsNotFoundErrorMessage'];
  public static readonly mapPageGpsTimeoutError = ['mapPage', 'addressSearchBar', 'gpsTimeoutErrorMessage'];
  public static readonly mapPageGPSUnknownError = ['mapPage', 'addressSearchBar', 'gpsUnkownErrorMessage'];
  public static readonly mapPageDevice = ['mapPage', 'addressSearchBar', 'device'];
  public static readonly mapPagePhone = ['mapPage', 'addressSearchBar', 'phone'];

  // RESUME PAGE
  public static readonly resumePageBuilderTitle = ['resumePage', 'resumeBuilder', 'title'];
  public static readonly resumePageEditTitle = ['resumePage', 'resumeEdit', 'title'];

  // RESUME LIST PAGE
  public static readonly resumeListDeleteModalHeader = ['resumePage', 'resumeList', 'modals', 'deleteModal', 'header'];
  public static readonly resumeListTranslationErrorTextOnly = ['resumePage', 'resumeList', 'translationErrorTextOnly'];
  public static readonly resumeListDeleteModalBody = ['resumePage', 'resumeList', 'modals', 'deleteModal', 'body'];
  public static readonly resumeListClickToFixTranslation = ['resumePage', 'resumeList', 'clickToFixTranslation'];
  public static readonly resumeListTitle = ['resumePage', 'resumeList', 'title'];
  public static readonly resumeListNoResumes = ['resumePage', 'resumeList', 'noResumes'];

  // FILE INFORMATION FORM
  public static readonly resumePageFileInformationFormStepHeaderText = ['resumePage', 'fileInformationForm', 'stepHeaderText'];
  public static readonly resumePageFileInformationFormResumeNameLabel = ['resumePage', 'fileInformationForm', 'resumeNameLabel'];
  public static readonly resumePageFileInformationFormResumeNamePlaceholder = [
    'resumePage',
    'fileInformationForm',
    'resumeNamePlaceholder',
  ];
  public static readonly resumePageFileInformationFormResumeNameTooltip = [
    'resumePage',
    'fileInformationForm',
    'resumeNameTooltip',
  ];
  public static readonly resumePageFileInformationFormJobTypeLabel = ['resumePage', 'fileInformationForm', 'jobTypeLabel'];
  public static readonly resumePageFileInformationFormJobTypePlaceholder = [
    'resumePage',
    'fileInformationForm',
    'jobTypePlaceholder',
  ];
  public static readonly resumePageFileInformationFormJobTypeTooltip = ['resumePage', 'fileInformationForm', 'jobTypeTooltip'];
  public static readonly resumePageFileInformationFormCompanyNameLabel = [
    'resumePage',
    'fileInformationForm',
    'companyNameLabel',
  ];
  public static readonly resumePageFileInformationFormCompanyNamePlaceholder = [
    'resumePage',
    'fileInformationForm',
    'companyNamePlaceholder',
  ];
  public static readonly resumePageFileInformationFormCompanyNameTooltip = [
    'resumePage',
    'fileInformationForm',
    'companyNameTooltip',
  ];

  // ABOUT YOU PAGE
  public static readonly resumePageAboutYouFormStepHeaderText = ['resumePage', 'aboutYouForm', 'stepHeaderText'];
  public static readonly resumePageAboutYouFormFirstNameLabel = ['resumePage', 'aboutYouForm', 'firstNameLabel'];
  public static readonly resumePageAboutYouFormFirstNamePlaceholder = ['resumePage', 'aboutYouForm', 'firstNamePlaceholder'];
  public static readonly resumePageAboutYouFormFirstNameTooltip = ['resumePage', 'aboutYouForm', 'firstNameTooltip'];
  public static readonly resumePageAboutYouFormLastNameLabel = ['resumePage', 'aboutYouForm', 'lastNameLabel'];
  public static readonly resumePageAboutYouFormLastNamePlaceholder = ['resumePage', 'aboutYouForm', 'lastNamePlaceholder'];
  public static readonly resumePageAboutYouFormLastNameTooltip = ['resumePage', 'aboutYouForm', 'lastNameTooltip'];
  public static readonly resumePageAboutYouFormFirstNameFuriganaLabel = ['resumePage', 'aboutYouForm', 'firstNameFuriganaLabel'];
  public static readonly resumePageAboutYouFormFirstNameFuriganaPlaceholder = [
    'resumePage',
    'aboutYouForm',
    'firstNameFuriganaPlaceholder',
  ];
  public static readonly resumePageAboutYouFormFirstNameFuriganaTooltip = [
    'resumePage',
    'aboutYouForm',
    'firstNameFuriganaTooltip',
  ];
  public static readonly resumePageAboutYouFormFirstNameFuriganaTooltipJP = [
    'resumePage',
    'aboutYouForm',
    'firstNameFuriganaTooltipJP',
  ];
  public static readonly resumePageAboutYouFormLastNameFuriganaLabel = ['resumePage', 'aboutYouForm', 'lastNameFuriganaLabel'];
  public static readonly resumePageAboutYouFormLastNameFuriganaPlaceholder = [
    'resumePage',
    'aboutYouForm',
    'lastNameFuriganaPlaceholder',
  ];
  public static readonly resumePageAboutYouFormLastNameFuriganaTooltip = [
    'resumePage',
    'aboutYouForm',
    'lastNameFuriganaTooltip',
  ];
  public static readonly resumePageAboutYouFormLastNameFuriganaTooltipJP = [
    'resumePage',
    'aboutYouForm',
    'lastNameFuriganaTooltipJP',
  ];
  public static readonly resumePageAboutYouFormSexLabel = ['resumePage', 'aboutYouForm', 'sexLabel'];
  public static readonly resumePageAboutYouFormSexPlaceholder = ['resumePage', 'aboutYouForm', 'sexPlaceholder'];
  public static readonly resumePageAboutYouFormSexTooltip = ['resumePage', 'aboutYouForm', 'sexTooltip'];
  public static readonly resumePageAboutYouFormDobLabel = ['resumePage', 'aboutYouForm', 'dobLabel'];
  public static readonly resumePageAboutYouFormDobPlaceholder = ['resumePage', 'aboutYouForm', 'dobPlaceholder'];
  public static readonly resumePageAboutYouFormDobTooltip = ['resumePage', 'aboutYouForm', 'dobTooltip'];
  public static readonly resumePageAboutYouFormNationalityLabel = ['resumePage', 'aboutYouForm', 'nationalityLabel'];
  public static readonly resumePageAboutYouFormNationalityPlaceholder = ['resumePage', 'aboutYouForm', 'nationalityPlaceholder'];
  public static readonly resumePageAboutYouFormNationalityTooltip = ['resumePage', 'aboutYouForm', 'nationalityTooltip'];
  public static readonly resumePageAboutYouFormNativeLanguageLabel = ['resumePage', 'aboutYouForm', 'nativeLanguageLabel'];
  public static readonly resumePageAboutYouFormNativeLanguagePlaceholder = [
    'resumePage',
    'aboutYouForm',
    'nativeLanguagePlaceholder',
  ];
  public static readonly resumePageAboutYouFormNativeLanguageTooltip = ['resumePage', 'aboutYouForm', 'nativeLanguageTooltip'];
  public static readonly resumePageAboutYouFormNativeLanguageTooltipJP = [
    'resumePage',
    'aboutYouForm',
    'nativeLanguageTooltipJP',
  ];
  public static readonly resumePageAboutYouFormJapaneseSkillLabel = ['resumePage', 'aboutYouForm', 'japaneseSkillLabel'];
  public static readonly resumePageAboutYouFormJapaneseSkillPlaceholder = [
    'resumePage',
    'aboutYouForm',
    'japaneseSkillPlaceholder',
  ];
  public static readonly resumePageAboutYouFormJapaneseSkillTooltip = ['resumePage', 'aboutYouForm', 'japaneseSkillTooltip'];
  public static readonly resumePageAboutYouFormJapaneseSkillTooltipJP = ['resumePage', 'aboutYouForm', 'japaneseSkillTooltipJP'];

  // RESUME ADDRESS-CONTACT PAGE
  public static readonly resumePageAddressContactFormStepHeaderText = ['resumePage', 'addressContactForm', 'stepHeaderText'];
  public static readonly resumePageAddressContactFormAboutYouJPStepHeaderText = [
    'resumePage',
    'addressContactForm',
    'stepHeaderAboutYouEditJPText',
  ];
  public static readonly resumePageAddressContactFormAddressContactJPStepHeaderText = [
    'resumePage',
    'addressContactForm',
    'stepHeaderAddressContactEditJPText',
  ];

  public static readonly resumePageAddressContactFormCountryOfResidenceSectionHeader = [
    'resumePage',
    'addressContactForm',
    'countryOfResidenceSectionHeader',
  ];
  public static readonly resumePageAddressContactFormCountryOfResidenceLabel = [
    'resumePage',
    'addressContactForm',
    'countryOfResidenceLabel',
  ];
  public static readonly resumePageAddressContactFormCountryOfResidencePlaceholder = [
    'resumePage',
    'addressContactForm',
    'countryOfResidencePlaceholder',
  ];
  public static readonly resumePageAddressContactFormCountryOfResidenceTooltip = [
    'resumePage',
    'addressContactForm',
    'countryOfResidenceTooltip',
  ];
  public static readonly resumePageAddressContactFormAddressSectionHeader = [
    'resumePage',
    'addressContactForm',
    'addressSectionHeader',
  ];
  public static readonly resumePageAddressContactFormZipCodeLabel = ['resumePage', 'addressContactForm', 'zipCodeLabel'];
  public static readonly resumePageAddressContactFormZipCodePlaceholder = [
    'resumePage',
    'addressContactForm',
    'zipCodePlaceholder',
  ];
  public static readonly resumePageAddressContactFormZipCodeTooltip = ['resumePage', 'addressContactForm', 'zipCodeTooltip'];
  public static readonly resumePageAddressContactFormPrefectureLabel = ['resumePage', 'addressContactForm', 'prefectureLabel'];
  public static readonly resumePageAddressContactFormPrefecturePlaceholder = [
    'resumePage',
    'addressContactForm',
    'prefecturePlaceholder',
  ];
  public static readonly resumePageAddressContactFormPrefectureTooltip = [
    'resumePage',
    'addressContactForm',
    'prefectureTooltip',
  ];
  public static readonly resumePageAddressContactFormCityLabel = ['resumePage', 'addressContactForm', 'cityLabel'];
  public static readonly resumePageAddressContactFormCityPlaceholder = ['resumePage', 'addressContactForm', 'cityPlaceholder'];
  public static readonly resumePageAddressContactFormCityTooltip = ['resumePage', 'addressContactForm', 'cityTooltip'];
  public static readonly resumePageAddressContactFormTownLabel = ['resumePage', 'addressContactForm', 'townLabel'];
  public static readonly resumePageAddressContactFormAddressLineLabel = ['resumePage', 'addressContactForm', 'addressLineLabel'];
  public static readonly resumePageAddressContactFormAddressLinePlaceholder = [
    'resumePage',
    'addressContactForm',
    'addressLinePlaceholder',
  ];
  public static readonly resumePageAddressContactFormAddressLinePlaceholderNoTown = [
    'resumePage',
    'addressContactForm',
    'addressLinePlaceholderNoTown',
  ];
  public static readonly resumePageAddressContactFormAddressLineTooltip = [
    'resumePage',
    'addressContactForm',
    'addressLineTooltip',
  ];
  public static readonly resumePageAddressContactFormAddressLineTooltipJP = [
    'resumePage',
    'addressContactForm',
    'addressLineTooltipJP',
  ];
  public static readonly resumePageAddressContactFormBuildingMansionNumberLabel = [
    'resumePage',
    'addressContactForm',
    'buildingMansionNumberLabel',
  ];
  public static readonly resumePageAddressContactFormBuildingMansionNumberPlaceholder = [
    'resumePage',
    'addressContactForm',
    'buildingMansionNumberPlaceholder',
  ];
  public static readonly resumePageAddressContactFormBuildingMansionNumberTooltip = [
    'resumePage',
    'addressContactForm',
    'buildingMansionNumberTooltip',
  ];
  public static readonly resumePageAddressContactFormBuildingMansionNumberTooltipJP = [
    'resumePage',
    'addressContactForm',
    'buildingMansionNumberTooltipJP',
  ];
  public static readonly resumePageAddressContactFormBuildingMansionNumberKanaTooltipJP = [
    'resumePage',
    'addressContactForm',
    'buildingMansionNumberKanaTooltipJP',
  ];
  public static readonly resumePageAddressContactFormContactInformationSectionHeader = [
    'resumePage',
    'addressContactForm',
    'contactInformationSectionHeader',
  ];
  public static readonly resumePageAddressContactFormPhoneNumberLabel = ['resumePage', 'addressContactForm', 'phoneNumberLabel'];
  public static readonly resumePageAddressContactFormPhoneNumberPlaceholder = [
    'resumePage',
    'addressContactForm',
    'phoneNumberPlaceholder',
  ];
  public static readonly resumePageAddressContactFormPhoneNumberTooltip = [
    'resumePage',
    'addressContactForm',
    'phoneNumberTooltip',
  ];
  public static readonly resumePageAddressContactFormEmailLabel = ['resumePage', 'addressContactForm', 'emailLabel'];
  public static readonly resumePageAddressContactFormEmailPlaceholder = ['resumePage', 'addressContactForm', 'emailPlaceholder'];
  public static readonly resumePageAddressContactFormEmailTooltip = ['resumePage', 'addressContactForm', 'emailTooltip'];

  // RESUME VISA PAGE
  public static readonly resumePageVisaTypeFormStepHeaderText = ['resumePage', 'visaTypeForm', 'stepHeaderText'];
  public static readonly resumePageVisaTypeFormVisaExpiryLabel = ['resumePage', 'visaTypeForm', 'visaExpiryDateLabel'];
  public static readonly resumePageVisaTypeFormVisaType = ['resumePage', 'visaTypeForm', 'visaType'];
  public static readonly resumePageVisaTypeFormVisaExpiryTooltip = ['resumePage', 'visaTypeForm', 'visaExpiryDateTooltip'];
  public static readonly resumePageVisaTypeFormVisaExpiryPlaceholder = [
    'resumePage',
    'visaTypeForm',
    'visaExpiryDatePlaceholder',
  ];

  // RESUME EDUCATION LIST
  public static readonly resumePageEducationFormStepHeaderText = ['resumePage', 'educationList', 'stepHeaderText'];
  public static readonly resumePageEducationEditStepHeaderOriginal = [
    'resumePage',
    'educationList',
    'editPage',
    'stepHeaderTextOriginal',
  ];
  public static readonly resumePageEducationEditStepHeaderJP = ['resumePage', 'educationList', 'editPage', 'stepHeaderTextJP'];
  public static readonly resumePageEducationFormEducationListSectionTitle = [
    'resumePage',
    'educationList',
    'educationListSectionTitle',
  ];
  public static readonly resumePageEducationFormEducationListTooltip = ['resumePage', 'educationList', 'educationListTooltip'];
  public static readonly resumePageEducationFormNoEducationText = ['resumePage', 'educationList', 'noEducationText'];
  public static readonly resumePageEducationFormSchoolNameLabel = [
    'resumePage',
    'educationList',
    'educationForm',
    'schoolNameLabel',
  ];
  public static readonly resumePageEducationFormSchoolNamePlaceholder = [
    'resumePage',
    'educationList',
    'educationForm',
    'schoolNamePlaceholder',
  ];
  public static readonly resumePageEducationFormSchoolNameTooltip = [
    'resumePage',
    'educationList',
    'educationForm',
    'schoolNameTooltip',
  ];
  public static readonly resumePageEducationFormEducationLevelLabel = [
    'resumePage',
    'educationList',
    'educationForm',
    'educationLevelLabel',
  ];
  public static readonly resumePageEducationFormEducationLevelPlaceholder = [
    'resumePage',
    'educationList',
    'educationForm',
    'educationLevelPlaceholder',
  ];
  public static readonly resumePageEducationFormEducationLevelTooltip = [
    'resumePage',
    'educationList',
    'educationForm',
    'educationLevelTooltip',
  ];
  public static readonly resumePageEducationFormCourseNameLabel = [
    'resumePage',
    'educationList',
    'educationForm',
    'courseNameLabel',
  ];
  public static readonly resumePageEducationFormCourseNamePlaceholder = [
    'resumePage',
    'educationList',
    'educationForm',
    'courseNamePlaceholder',
  ];
  public static readonly resumePageEducationFormCourseNameTooltip = [
    'resumePage',
    'educationList',
    'educationForm',
    'courseNameTooltip',
  ];
  public static readonly resumePageEducationFormCourseNameTooltipJP = [
    'resumePage',
    'educationList',
    'educationForm',
    'courseNameTooltipJP',
  ];
  public static readonly resumePageEducationFormStartDateLabel = [
    'resumePage',
    'educationList',
    'educationForm',
    'startDateLabel',
  ];
  public static readonly resumePageEducationFormStartDateTooltip = [
    'resumePage',
    'educationList',
    'educationForm',
    'startDateTooltip',
  ];
  public static readonly resumePageEducationFormGraduateDateLabel = [
    'resumePage',
    'educationList',
    'educationForm',
    'graduateDateLabel',
  ];
  public static readonly resumePageEducationFormGraduateDateTooltip = [
    'resumePage',
    'educationList',
    'educationForm',
    'graduateDateTooltip',
  ];
  public static readonly resumePageEducationFormStillStudyingLabel = [
    'resumePage',
    'educationList',
    'educationForm',
    'stillStudyingLabel',
  ];
  public static readonly resumePageEducationFormEducationLevelHighschool = [
    'resumePage',
    'educationList',
    'educationLevel',
    'highschool',
  ];
  public static readonly resumePageEducationFormEducationLevelUndergraduate = [
    'resumePage',
    'educationList',
    'educationLevel',
    'undergraduate',
  ];
  public static readonly resumePageEducationFormEducationLevelPostgraduate = [
    'resumePage',
    'educationList',
    'educationLevel',
    'postgraduate',
  ];

  // RESUME JOB HISTORY LIST
  public static readonly resumePageJobHistoryListStepHeaderText = ['resumePage', 'jobHistoryList', 'stepHeaderText'];
  public static readonly resumePageJobHistoryListJobHistoryListSectionTitle = [
    'resumePage',
    'jobHistoryList',
    'jobHistoryListSectionTitle',
  ];
  public static readonly resumePageJobHistoryListJobHistoryListTooltip = [
    'resumePage',
    'jobHistoryList',
    'jobHistoryListTooltip',
  ];
  public static readonly resumePageJobHistoryListNoJobText = ['resumePage', 'jobHistoryList', 'noJobText'];

  public static readonly resumePageJobHistoryListJobTitleLabel = [
    'resumePage',
    'jobHistoryList',
    'jobHistoryForm',
    'jobTitleLabel',
  ];
  public static readonly resumePageJobHistoryListJobTitlePlaceholder = [
    'resumePage',
    'jobHistoryList',
    'jobHistoryForm',
    'jobTitlePlaceholder',
  ];
  public static readonly resumePageJobHistoryListJobTitleTooltip = [
    'resumePage',
    'jobHistoryList',
    'jobHistoryForm',
    'jobTitleTooltip',
  ];

  public static readonly resumePageJobHistoryListCompanyNameLabel = [
    'resumePage',
    'jobHistoryList',
    'jobHistoryForm',
    'companyNameLabel',
  ];
  public static readonly resumePageJobHistoryListCompanyNamePlaceholder = [
    'resumePage',
    'jobHistoryList',
    'jobHistoryForm',
    'companyNamePlaceholder',
  ];
  public static readonly resumePageJobHistoryListCompanyNameTooltip = [
    'resumePage',
    'jobHistoryList',
    'jobHistoryForm',
    'companyNameTooltip',
  ];

  public static readonly resumePageJobHistoryListJobDescriptionLabel = [
    'resumePage',
    'jobHistoryList',
    'jobHistoryForm',
    'jobDescriptionLabel',
  ];
  public static readonly resumePageJobHistoryListJobDescriptionPlaceholder = [
    'resumePage',
    'jobHistoryList',
    'jobHistoryForm',
    'jobDescriptionPlaceholder',
  ];
  public static readonly resumePageJobHistoryListJobDescriptionTooltip = [
    'resumePage',
    'jobHistoryList',
    'jobHistoryForm',
    'jobDescriptionTooltip',
  ];
  public static readonly resumePageJobHistoryListJobDescriptionTooltipJP = [
    'resumePage',
    'jobHistoryList',
    'jobHistoryForm',
    'jobDescriptionTooltipJP',
  ];

  public static readonly resumePageJobHistoryListContractTypeLabel = [
    'resumePage',
    'jobHistoryList',
    'jobHistoryForm',
    'contractTypeLabel',
  ];

  public static readonly resumePageJobHistoryListContractTypeTooltip = [
    'resumePage',
    'jobHistoryList',
    'jobHistoryForm',
    'contractTypeTooltip',
  ];

  public static readonly resumePageJobHistoryListStartDateLabel = [
    'resumePage',
    'jobHistoryList',
    'jobHistoryForm',
    'startDateLabel',
  ];
  public static readonly resumePageJobHistoryListStartDateTooltip = [
    'resumePage',
    'jobHistoryList',
    'jobHistoryForm',
    'startDateTooltip',
  ];

  public static readonly resumePageJobHistoryListEndDateLabel = [
    'resumePage',
    'jobHistoryList',
    'jobHistoryForm',
    'endDateLabel',
  ];
  public static readonly resumePageJobHistoryListEndDateTooltip = [
    'resumePage',
    'jobHistoryList',
    'jobHistoryForm',
    'endDateTooltip',
  ];

  public static readonly resumePageJobHistoryListStillWorkingLabel = [
    'resumePage',
    'jobHistoryList',
    'jobHistoryForm',
    'stillWorkingLabel',
  ];

  public static readonly resumePageJobHistoryListJobTypeFullTime = ['resumePage', 'jobHistoryList', 'jobType', 'fullTime'];
  public static readonly resumePageJobHistoryListJobTypePartTime = ['resumePage', 'jobHistoryList', 'jobType', 'partTime'];
  public static readonly resumePageJobHistoryListJobTypeContract = ['resumePage', 'jobHistoryList', 'jobType', 'contract'];
  public static readonly resumePageJobHistoryListJobTypeInternship = ['resumePage', 'jobHistoryList', 'jobType', 'internship'];
  public static readonly resumePageJobHistoryListJobTypeTemporary = ['resumePage', 'jobHistoryList', 'jobType', 'temporary'];
  public static readonly resumePageJobHistoryListContractTypeCardTitle = [
    'resumePage',
    'jobHistoryList',
    'jobHistoryCard',
    'contractTypeTitle',
  ];
  public static readonly resumePageJobHistoryEditStepHeaderOriginal = [
    'resumePage',
    'jobHistoryList',
    'editPage',
    'stepHeaderTextOriginal',
  ];
  public static readonly resumePageJobHistoryEditStepHeaderJP = ['resumePage', 'jobHistoryList', 'editPage', 'stepHeaderTextJP'];

  // RESUME QUALIFICATION LIST
  public static readonly resumePageQualificationListStepHeaderText = ['resumePage', 'qualificationList', 'stepHeaderText'];
  public static readonly resumePageQualificationListQualificationListSectionTitle = [
    'resumePage',
    'qualificationList',
    'qualificationListSectionTitle',
  ];
  public static readonly resumePageQualificationListQualificationListTooltip = [
    'resumePage',
    'qualificationList',
    'qualificationListTooltip',
  ];
  public static readonly resumePageQualificationListNoQualificationText = [
    'resumePage',
    'qualificationList',
    'noQualificationText',
  ];

  public static readonly resumePageQualificationListDescriptionLabel = [
    'resumePage',
    'qualificationList',
    'qualificationForm',
    'qualificationDescriptionLabel',
  ];
  public static readonly resumePageQualificationLisDescriptionPlaceholder = [
    'resumePage',
    'qualificationList',
    'qualificationForm',
    'qualificationDescriptionPlaceholder',
  ];
  public static readonly resumePageQualificationListDescriptionTooltip = [
    'resumePage',
    'qualificationList',
    'qualificationForm',
    'qualificationDescriptionTooltip',
  ];

  public static readonly resumePageQualificationListReceivedDateLabel = [
    'resumePage',
    'qualificationList',
    'qualificationForm',
    'receivedDateLabel',
  ];
  public static readonly resumePageQualificationListReceivedDateTooltip = [
    'resumePage',
    'qualificationList',
    'qualificationForm',
    'receivedDateTooltip',
  ];
  public static readonly resumePageQualificationEditStepHeaderOriginal = [
    'resumePage',
    'qualificationList',
    'editPage',
    'stepHeaderTextOriginal',
  ];
  public static readonly resumePageQualificationEditStepHeaderJP = [
    'resumePage',
    'qualificationList',
    'editPage',
    'stepHeaderTextJP',
  ];

  // RESUME REASONS FOR APPLYING PAGE
  public static readonly resumePageReasonsForApplyingFormStepHeaderText = [
    'resumePage',
    'reasonsForApplyingForm',
    'stepHeaderText',
  ];
  public static readonly resumePageReasonsForApplyingFormReasonsListTooltip = [
    'resumePage',
    'reasonsForApplyingForm',
    'reasonsListTooltip',
  ];
  public static readonly resumePageReasonsForApplyingFormReasonsListTooltipJP = [
    'resumePage',
    'reasonsForApplyingForm',
    'reasonsListTooltipJP',
  ];
  public static readonly resumePageReasonsForApplyingFormReasonsTextbox = [
    'resumePage',
    'reasonsForApplyingForm',
    'reasonsTextbox',
  ];
  public static readonly resumePageReasonsForApplyingFormReasonsPlaceholder = [
    'resumePage',
    'reasonsForApplyingForm',
    'reasonsTextboxPlaceholder',
  ];
  public static readonly resumePageReasonsForApplyingEditStepHeaderOriginal = [
    'resumePage',
    'reasonsForApplyingForm',
    'editPage',
    'stepHeaderTextOriginal',
  ];
  public static readonly resumePageReasonsForApplyingEditStepHeaderJP = [
    'resumePage',
    'reasonsForApplyingForm',
    'editPage',
    'stepHeaderTextJP',
  ];

  // REQUESTS WISHES PAGE
  public static readonly resumePageRequestsFormStepHeaderText = ['resumePage', 'requestsForm', 'stepHeaderText'];
  public static readonly resumePageRequestsFormRequestsListTooltip = ['resumePage', 'requestsForm', 'requestsListTooltip'];
  public static readonly resumePageRequestsFormRequestsListTooltipJP = ['resumePage', 'requestsForm', 'requestsListTooltipJP'];
  public static readonly resumePageRequestsFormRequestsTextboxLabel = ['resumePage', 'requestsForm', 'requestsTextboxLabel'];
  public static readonly resumePageRequestsFormRequestsTextboxPlaceholder = [
    'resumePage',
    'requestsForm',
    'requestsTextboxPlaceholder',
  ];
  public static readonly resumePageRequestsEditStepHeaderOriginal = [
    'resumePage',
    'requestsForm',
    'editPage',
    'stepHeaderTextOriginal',
  ];
  public static readonly resumePageRequestsEditStepHeaderJP = ['resumePage', 'requestsForm', 'editPage', 'stepHeaderTextJP'];

  // RESUME PHOTO FORM PAGE
  public static readonly resumePagePhotoFormStepHeaderText = ['resumePage', 'photoForm', 'stepHeaderText'];

  public static readonly resumePagePhotoFormExplanationText = ['resumePage', 'photoForm', 'explanationText'];
  public static readonly resumePagePhotoFormImageRequirementsText = ['resumePage', 'photoForm', 'imageRequirementsText'];

  public static readonly resumePagePhotoFormReceiveOffersText = ['resumePage', 'photoForm', 'receiveOffersText'];

  public static readonly resumePagePhotoFormResumePhotoTitle = ['resumePage', 'photoForm', 'resumePhotoTitle'];

  public static readonly resumePagePhotoFormResumePhotoTooltip = ['resumePage', 'photoForm', 'resumePhotoTooltip'];

  // RESUME EDIT PAGE
  // FILE INFORMATION VIEW
  public static readonly resumePageFileInformationViewStepHeaderText = ['resumePage', 'fileInformationView', 'stepHeaderText'];
  public static readonly resumePageFileInformationViewFileInformationTitle = [
    'resumePage',
    'fileInformationView',
    'fileInformationTitle',
  ];
  public static readonly resumePageFileInformationViewFileInformationTooltip = [
    'resumePage',
    'fileInformationView',
    'fileInformationTooltip',
  ];

  // RESUME ABOUT YOU VIEW
  public static readonly resumePageAboutYouViewStepHeaderText = ['resumePage', 'aboutYouForm', 'aboutYouView', 'stepHeaderText'];
  public static readonly resumePageAboutYouViewTitle = ['resumePage', 'aboutYouForm', 'aboutYouView', 'title'];
  public static readonly resumePageAboutYouViewFuriganaErrorDescription = [
    'resumePage',
    'aboutYouForm',
    'aboutYouView',
    'furiganaErrorDescription',
  ];
  public static readonly resumePageAboutYouViewTooltip = ['resumePage', 'aboutYouForm', 'aboutYouView', 'tooltip'];

  // RESUME ADDRESS CONTACT VIEW
  public static readonly resumePageAddressContactViewTitle = ['resumePage', 'addressContactForm', 'addressContactView', 'title'];
  public static readonly resumePageAddressContactViewTooltip = [
    'resumePage',
    'addressContactForm',
    'addressContactView',
    'tooltip',
  ];

  // RESUME VISA VIEW
  public static readonly resumePageVisaViewTitle = ['resumePage', 'visaTypeForm', 'visaView', 'title'];
  public static readonly resumePageVisaViewTooltip = ['resumePage', 'visaTypeForm', 'visaView', 'tooltip'];
  public static readonly resumePageVisaViewVisaTypeLabel = ['resumePage', 'visaTypeForm', 'visaView', 'visaTypeLabel'];
  public static readonly resumePageVisaViewVisaExpiryLabel = ['resumePage', 'visaTypeForm', 'visaView', 'visaExpiryLabel'];

  // RESUME BUILDER END
  public static readonly resumePagePreparingTranslation = ['resumePage', 'preparingTranslation'];

  // DATE
  public static readonly Day1 = ['date', '1'];
  public static readonly Day2 = ['date', '2'];
  public static readonly Day3 = ['date', '3'];
  public static readonly Day4 = ['date', '4'];
  public static readonly Day5 = ['date', '5'];
  public static readonly Day6 = ['date', '6'];
  public static readonly Day7 = ['date', '7'];
  public static readonly Day8 = ['date', '8'];
  public static readonly Day9 = ['date', '9'];
  public static readonly Day10 = ['date', '10'];
  public static readonly Day11 = ['date', '11'];
  public static readonly Day12 = ['date', '12'];
  public static readonly Day13 = ['date', '13'];
  public static readonly Day14 = ['date', '14'];
  public static readonly Day15 = ['date', '15'];
  public static readonly Day16 = ['date', '16'];
  public static readonly Day17 = ['date', '17'];
  public static readonly Day18 = ['date', '18'];
  public static readonly Day19 = ['date', '19'];
  public static readonly Day20 = ['date', '20'];
  public static readonly Day21 = ['date', '21'];
  public static readonly Day22 = ['date', '22'];
  public static readonly Day23 = ['date', '23'];
  public static readonly Day24 = ['date', '24'];
  public static readonly Day25 = ['date', '25'];
  public static readonly Day26 = ['date', '26'];
  public static readonly Day27 = ['date', '27'];
  public static readonly Day28 = ['date', '28'];
  public static readonly Day29 = ['date', '29'];
  public static readonly Day30 = ['date', '30'];
  public static readonly Day31 = ['date', '31'];
  public static readonly DayInMonth = ['date', 'dayInMonth'];
  public static readonly Month = ['date', 'month'];
  public static readonly Months = ['date', 'months'];

  // RECOMMENDED JOBS COMPONENT
  public static readonly recommendedJobsTitle = ['recommendedJobsBar', 'title'];

  // CANDIDATE ACCOUNT PAGE

  public static readonly candidateAccountUnverifiedEmailTitle = ['candidateAccount', 'unverifiedEmail', 'title'];
  public static readonly candidateAccountUnverifiedEmailSubtitle = ['candidateAccount', 'unverifiedEmail', 'subtitle'];
  public static readonly candidateAccountUnverifiedEmailInstructions = ['candidateAccount', 'unverifiedEmail', 'instructions'];
  public static readonly candidateAccountUnverifiedEmailTroubleshooting = [
    'candidateAccount',
    'unverifiedEmail',
    'troubleshooting',
  ];

  // PASSWORD RECOVERY SNS ACCOUNT
  public static readonly candidatePasswordRecoverySnsAccountHeader = ['candidatePasswordRecoverySnsAccount', 'header'];
  public static readonly candidatePasswordRecoverySnsAccountLine1 = ['candidatePasswordRecoverySnsAccount', 'line1'];
  public static readonly candidatePasswordRecoverySnsAccountBackButton = ['candidatePasswordRecoverySnsAccount', 'backButton'];

  public static readonly CandidateAccountTypeSnsTitle = ['candidateAccountPage', 'snsTitle'];
  public static readonly CandidateAccountTypeSnsDescription = ['candidateAccountPage', 'snsDescription'];

  public static readonly candidateAccountOtpRefreshTitle = ['candidateAccount', 'otpRefresh', 'title'];
  public static readonly candidateAccountOtpRefreshInstructions = ['candidateAccount', 'otpRefresh', 'instructions'];
  public static readonly candidateAccountOtpRefreshTroubleshooting = ['candidateAccount', 'otpRefresh', 'troubleshooting'];
  public static readonly candidateAccountOtpRefreshSubmitBtn = ['candidateAccount', 'otpRefresh', 'submitBtnText'];

  public static readonly accountConfirmationSuccessThankYou = ['candidateAccount', 'confirmationSuccess', 'thankYou'];
  public static readonly accountConfirmationSuccessSubtitle = ['candidateAccount', 'confirmationSuccess', 'subtitle'];
  public static readonly accountConfirmationSuccessNextSteps = ['candidateAccount', 'confirmationSuccess', 'nextSteps'];
  public static readonly accountConfirmationSuccessCompleteProfileBtn = [
    'candidateAccount',
    'confirmationSuccess',
    'completeProfileBtn',
  ];
  public static readonly accountConfirmationSuccessJobSearchBtn = ['candidateAccount', 'confirmationSuccess', 'jobSearchBtn'];

  public static readonly guestEmailConfirmationSuccessThankYou = [
    'candidateAccount',
    'guestEmailAccountConfirmationSuccess',
    'thankYou',
  ];
  public static readonly guestEmailConfirmationSuccessSubtitle = [
    'candidateAccount',
    'guestEmailAccountConfirmationSuccess',
    'subtitle',
  ];
  public static readonly guestEmailConfirmationSuccessNextSteps = [
    'candidateAccount',
    'guestEmailAccountConfirmationSuccess',
    'nextSteps',
  ];
  public static readonly guestEmailConfirmationSuccessMyJobsBtnBtn = [
    'candidateAccount',
    'guestEmailAccountConfirmationSuccess',
    'myJobsBtn',
  ];
  public static readonly guestEmailConfirmationSuccessJobSearchBtn = [
    'candidateAccount',
    'guestEmailAccountConfirmationSuccess',
    'jobSearchBtn',
  ];

  public static readonly guestSSOConfirmationSuccessThankYou = [
    'candidateAccount',
    'guestSSOAccountConfirmationSuccess',
    'thankYou',
  ];
  public static readonly guestSSOConfirmationSuccessNextStepsGoogle = [
    'candidateAccount',
    'guestSSOAccountConfirmationSuccess',
    'nextStepsGoogle',
  ];
  public static readonly guestSSOConfirmationSuccessNextStepsFacebook = [
    'candidateAccount',
    'guestSSOAccountConfirmationSuccess',
    'nextStepsFacebook',
  ];
  public static readonly guestSSOConfirmationSuccessMyJobsBtnBtn = [
    'candidateAccount',
    'guestSSOAccountConfirmationSuccess',
    'myJobsBtn',
  ];
  public static readonly guestSSOConfirmationSuccessJobSearchBtn = [
    'candidateAccount',
    'guestSSOAccountConfirmationSuccess',
    'jobSearchBtn',
  ];

  // Similar to the above constants, but for when the account is a duplicate
  public static readonly accountConfirmThankYou = ['candidateAccount', 'accountConfirm', 'thankYou'];
  public static readonly accountConfirmInstructions1 = ['candidateAccount', 'accountConfirm', 'instructions1'];
  public static readonly accountConfirmInstructions2 = ['candidateAccount', 'accountConfirm', 'instructions2'];

  // UI TEXT GENERIC (Used in various placees)
  public static readonly login = ['UITextGeneric', 'login'];
  public static readonly logout = ['UITextGeneric', 'logout'];
  public static readonly email = ['UITextGeneric', 'email'];
  public static readonly notificationsEmail = ['UITextGeneric', 'notificationsEmail'];
  public static readonly currentPassword = ['UITextGeneric', 'currentPassword'];
  public static readonly password = ['UITextGeneric', 'password'];
  public static readonly repeatPassword = ['UITextGeneric', 'repeatPassword'];
  public static readonly backBtn = ['UITextGeneric', 'backBtn'];
  public static readonly topBtn = ['UITextGeneric', 'topBtn'];
  public static readonly confirmBtn = ['UITextGeneric', 'confirmBtn'];
  public static readonly continueBtn = ['UITextGeneric', 'continueBtn'];
  public static readonly cancelBtn = ['UITextGeneric', 'cancelBtn'];
  public static readonly closeBtn = ['UITextGeneric', 'closeBtn'];
  public static readonly finishBtn = ['UITextGeneric', 'finishBtn'];
  public static readonly previousBtn = ['UITextGeneric', 'previousBtn'];
  public static readonly nextBtn = ['UITextGeneric', 'nextBtn'];
  public static readonly startBtn = ['UITextGeneric', 'startBtn'];
  public static readonly goHomeBtn = ['UITextGeneric', 'goHomeBtn'];
  public static readonly dropdownPlaceholder = ['UITextGeneric', 'dropdownPlaceholder'];
  public static readonly dropdownFilterPlaceholder = ['UITextGeneric', 'dropdownFilterPlaceholder'];
  public static readonly dropdownSearchPlaceholder = ['UITextGeneric', 'dropdownSearchPlaceholder'];
  public static readonly noJobsFound = ['UITextGeneric', 'noJobsFound'];
  public static readonly required = ['UITextGeneric', 'required'];
  public static readonly UIGeneratingPDFText = ['UITextGeneric', 'generatingPDFText'];
  public static readonly ofPreposition = ['UITextGeneric', 'ofPreposition'];
  public static readonly forgotPassword = ['UITextGeneric', 'forgotPassword'];
  public static readonly submit = ['UITextGeneric', 'submit'];
  public static readonly create = ['UITextGeneric', 'create'];
  public static readonly applying = ['UITextGeneric', 'applying'];
  public static readonly or = ['UITextGeneric', 'or'];
  public static readonly show = ['UITextGeneric', 'show'];
  public static readonly hide = ['UITextGeneric', 'hide'];
  public static readonly save = ['UITextGeneric', 'save'];
  public static readonly submitEmailChange = ['UITextGeneric', 'submitEmailChange'];
  public static readonly agent = ['UITextGeneric', 'agentText'];
  public static readonly candidate = ['UITextGeneric', 'candidateText'];
  public static readonly edit = ['UITextGeneric', 'edit'];
  public static readonly editManually = ['UITextGeneric', 'editManually'];
  public static readonly editOriginal = ['UITextGeneric', 'editOriginal'];
  public static readonly delete = ['UITextGeneric', 'delete'];
  public static readonly duplicateEmailError = ['UITextGeneric', 'duplicateEmailError'];
  public static readonly address = ['UITextGeneric', 'address'];
  public static readonly zipCode = ['UITextGeneric', 'zipCode'];
  public static readonly zipCodePlaceholder = ['UITextGeneric', 'zipCodePlaceholder'];
  public static readonly addressLine = ['UITextGeneric', 'addressLine'];
  public static readonly addressLinePlaceholder = ['UITextGeneric', 'addressLinePlaceholder'];
  public static readonly buildMansionNumber = ['UITextGeneric', 'buildMansionNumber'];
  public static readonly buildMansionNumberPlaceholder = ['UITextGeneric', 'buildMansionNumberPlaceholder'];
  public static readonly zipCodeSearchError = ['UITextGeneric', 'zipCodeError'];
  public static readonly search = ['UITextGeneric', 'search'];
  public static readonly TranslationPending = ['UITextGeneric', 'translationPending'];
  public static readonly TranslationPendingDescription = ['UITextGeneric', 'translationPendingDescription'];
  public static readonly DeleteConfirmation = ['UITextGeneric', 'deleteConfirmation'];
  public static readonly DeleteConfirmationDescription = ['UITextGeneric', 'deleteConfirmationDescription'];
  public static readonly UIAddNew = ['UITextGeneric', 'UIAddNew'];
  public static readonly UIMonth = ['UITextGeneric', 'UIMonth'];
  public static readonly UIYear = ['UITextGeneric', 'UIYear'];
  public static readonly UIReceived = ['UITextGeneric', 'UIReceived'];
  public static readonly UIYearSuffix = ['UITextGeneric', 'UIYearSuffix'];
  public static readonly UIMonthSuffix = ['UITextGeneric', 'UIMonthSuffix'];
  public static readonly UIColon = ['UITextGeneric', 'UIColon'];
  public static readonly UIComma = ['UITextGeneric', 'UIComma'];
  public static readonly UIName = ['UITextGeneric', 'UIName'];
  public static readonly UIKanji = ['UITextGeneric', 'UIKanji'];
  public static readonly UIFurigana = ['UITextGeneric', 'UIFurigana'];
  public static readonly male = ['UITextGeneric', 'male'];
  public static readonly female = ['UITextGeneric', 'female'];
  public static readonly notSpecified = ['UITextGeneric', 'notSpecified'];
  public static readonly UIUpdatedOn = ['UITextGeneric', 'UIUpdatedOn'];
  public static readonly UIEdit = ['UITextGeneric', 'UIEdit'];
  public static readonly UIContinue = ['UITextGeneric', 'UIContinue'];
  public static readonly UIDelete = ['UITextGeneric', 'UIDelete'];
  public static readonly UIDownload = ['UITextGeneric', 'UIDownload'];

  // GENERIC ERROR MESSAGES
  public static readonly TranslationError = ['genericErrors', 'translationError'];
  public static readonly TranslationErrorDescription = ['genericErrors', 'translationErrorDescription'];
  public static readonly ImageUploaderErrorImageType = ['genericErrors', 'imageTypeError'];
  public static readonly ImageUploaderErrorImageSize = ['genericErrors', 'imageSizeError'];
  public static readonly ImageUploaderErrorUploadFailed = ['genericErrors', 'imageUploadError'];
  public static readonly ImageUploaderErrorImageTypeBody = ['genericErrors', 'imageTypeErrorBody'];
  public static readonly ImageUploaderErrorImageSizeBody = ['genericErrors', 'imageSizeErrorBody'];
  public static readonly ImageUploaderErrorUploadFailedBody = ['genericErrors', 'imageUploadErrorBody'];
  public static readonly ImageUploaderErrorImageRotation = ['genericErrors', 'imageRotationError'];

  // UI TEXT VACANCY CARD SPECIFIC
  public static readonly viewJobBtn = ['vacancyCardUIText', 'viewJobBtn'];
  public static readonly appliedJobBtn = ['vacancyCardUIText', 'appliedJobBtn'];

  // INPUT HELPERS
  public static readonly asWrittenOnResidenceCard = ['inputHelper', 'asWrittenOnResidenceCard'];

  // CANDIDATE MODALS
  public static readonly JobListConfirmationModalHeader = ['candidateModals', 'jobListConfirmationModal', 'header'];
  public static readonly JobListConfirmationModalBody = ['candidateModals', 'jobListConfirmationModal', 'body'];
  public static readonly JobListConfirmationModalConfirmBtn = ['candidateModals', 'jobListConfirmationModal', 'confirmBtn'];
  public static readonly EmailEditModalHeader = ['candidateModals', 'emailEditRedirectModal', 'header'];
  public static readonly EmailEditModalBody = ['candidateModals', 'emailEditRedirectModal', 'body'];
  public static readonly EmailEditModalConfirmBtn = ['candidateModals', 'emailEditRedirectModal', 'confirmBtn'];
  public static readonly JobDetailsConfirmationModalHeader = ['candidateModals', 'jobDetailsConfirmationModal', 'header'];
  public static readonly JobDetailsConfirmationModalBody = ['candidateModals', 'jobDetailsConfirmationModal', 'body'];

  // CANDIDATE LOGIN PAGE
  public static readonly LoginPageSignIn = ['loginPage', 'signin'];
  public static readonly LoginPageLoginWithEmail = ['loginPage', 'loginWith'];
  public static readonly LoginPageSignUpLinkText = ['loginPage', 'signUpLinkText'];
  public static readonly LoginPageErrorDescription = ['loginPage', 'errorDescription'];
  public static readonly LoginPageErrorTitle = ['loginPage', 'errorTitle'];
  public static readonly LoginPageGoogleLoginBtnText = ['loginPage', 'googleLoginBtnText'];
  public static readonly LoginPageFacebookLoginBtnText = ['loginPage', 'facebookLoginBtnText'];

  // AGENT LOGIN PAGE
  public static readonly AgentLoginPageSignIn = ['agentLoginPage', 'signin'];
  public static readonly AgentLoginHint = ['agentLoginPage', 'loginHint'];
  public static readonly AgentSignupBtnText = ['agentLoginPage', 'signupBtnText'];

  // CANDIDATE SIGNUP PAGE
  public static readonly SignUpPageTitle = ['signupPage', 'createAccount'];
  public static readonly SignUpPageCreateWithEmail = ['signupPage', 'createWith'];
  public static readonly SignUpPageLoginLinkText = ['signupPage', 'loginLinkText'];
  public static readonly SignUpPageGoogleSignUpBtnText = ['signupPage', 'googleSignUpBtnText'];
  public static readonly SignUpPageFacebookSignUpBtnText = ['signupPage', 'facebookSignUpBtnText'];
  public static readonly SignUpPagePasswordTip = ['signupPage', 'passwordTip'];
  public static readonly SignUpPageError = ['signupPage', 'signupError'];
  /// PASSWORD RECOVERY PAGE ///
  /// PASSWORD RECOVERY FORM ///
  public static readonly PasswordRecoveryFormHeader = ['passwordRecoveryPage', 'recoveryForm', 'header'];
  public static readonly PasswordRecoveryFormHeaderSize = ['passwordRecoveryPage', 'recoveryForm', 'headerSize'];
  public static readonly PasswordRecoveryFormLine1 = ['passwordRecoveryPage', 'recoveryForm', 'line1'];
  public static readonly PasswordRecoveryFormLine2 = ['passwordRecoveryPage', 'recoveryForm', 'line2'];
  public static readonly PasswordRecoveryFormMail = ['passwordRecoveryPage', 'recoveryForm', 'mail'];
  public static readonly PasswordRecoveryFormReset = ['passwordRecoveryPage', 'recoveryForm', 'reset'];
  public static readonly PasswordRecoveryFormBackButton = ['passwordRecoveryPage', 'recoveryForm', 'backButton'];
  /// PASSWORD RECOVERY SUCCESS ///
  public static readonly PasswordRecoverySuccessHeader = ['passwordRecoveryPage', 'recoverySuccess', 'header'];
  public static readonly PasswordRecoverySuccessLine1 = ['passwordRecoveryPage', 'recoverySuccess', 'line1'];
  public static readonly PasswordRecoverySuccessBackButton = ['passwordRecoveryPage', 'recoverySuccess', 'backButton'];
  /// PASSWORD RECOVERY FAILURE ///
  public static readonly PasswordRecoveryFailureHeader = ['passwordRecoveryPage', 'recoveryFailure', 'header'];
  public static readonly PasswordRecoveryFailureLine1 = ['passwordRecoveryPage', 'recoveryFailure', 'line1'];
  public static readonly PasswordRecoveryFailureRetryButton = ['passwordRecoveryPage', 'recoveryFailure', 'retryButton'];
  public static readonly PasswordRecoveryFailureBackButton = ['passwordRecoveryPage', 'recoveryFailure', 'backButton'];

  // CANDIDATE PROFILE PAGE
  public static readonly ProfilePageTitle = ['candidateProfilePage', 'title'];
  public static readonly ProfilePageBasicInformationTitleText = ['candidateProfilePage', 'basicInformationTitleText'];
  public static readonly ProfilePageVisaTypeTitleText = ['candidateProfilePage', 'visaTypeTitleText'];
  public static readonly ProfilePageVisaRequirementExplanationText = ['candidateProfilePage', 'visaRequirementExplanationText'];
  public static readonly ProfilePageContactInformationTitleText = ['candidateProfilePage', 'contactInformationTitleText'];
  public static readonly ProfilePagePersonalInformationTitleText = ['candidateProfilePage', 'personalInformationTitleText'];
  public static readonly ProfilePageJapaneseLanguageSkillsTitleText = ['candidateProfilePage', 'japaneseLanguageSkillsTitleText'];
  public static readonly ProfilePageSaveProfileButtonText = ['candidateProfilePage', 'saveProfileButtonText'];

  // GUEST APPLICATION ACCOUNT CREATION
  public static readonly GuestApplicationAccountCreationTitle = ['guestApplicationAccountCreation', 'title'];
  public static readonly GuestApplicationAccountCreationParagraph1 = ['guestApplicationAccountCreation', 'paragraph1'];
  public static readonly GuestApplicationAccountCreationParagraph2 = ['guestApplicationAccountCreation', 'paragraph2'];

  // QUICK APPLY
  public static readonly QuickApplyApplyTo = ['quickApply', 'applyTo'];
  public static readonly QuickApplyByApplyingToThisJob = ['quickApply', 'byApplyingToThisJob'];
  public static readonly QuickApplyShowYourResidenceCard = ['quickApply', 'showYourResidenceCard'];
  public static readonly QuickApplyReceiveReminders = ['quickApply', 'receiveReminders'];
  public static readonly QuickApplyFollowAll = ['quickApply', 'followAll'];
  public static readonly QuickApplyResidenceCardRequirement = ['quickApply', 'residenceCardRequirement'];

  // CANDIDATE APPLICATION STEP 1
  // WELCOME PAGE
  public static readonly welcomePageTitle = ['applicationProcess', 'welcomePage', 'title'];
  public static readonly welcomePageDescriptionGuest = ['applicationProcess', 'welcomePage', 'descriptionGuest'];
  public static readonly welcomePageDescriptionLoggedIn = ['applicationProcess', 'welcomePage', 'descriptionLoggedIn'];
  public static readonly welcomePageLetsGoText = ['applicationProcess', 'welcomePage', 'letsGoText'];
  public static readonly welcomePageQuickApplyText = ['applicationProcess', 'welcomePage', 'quickApplyText'];
  public static readonly welcomePageQuickApplyIconAltText = ['applicationProcess', 'welcomePage', 'quickApplyIconAltText'];
  public static readonly welcomePageRealTimeText = ['applicationProcess', 'welcomePage', 'realTimeText'];
  public static readonly welcomePageRealTimeIconAltText = ['applicationProcess', 'welcomePage', 'realTimeIconAltText'];
  public static readonly welcomePageTrackingText = ['applicationProcess', 'welcomePage', 'trackingText'];
  public static readonly welcomePageTrackingIconAltText = ['applicationProcess', 'welcomePage', 'trackingIconAltText'];
  public static readonly welcomePageLiveChatText = ['applicationProcess', 'welcomePage', 'liveChatText'];
  public static readonly welcomePageLiveChatIconAltText = ['applicationProcess', 'welcomePage', 'liveChatIconAltText'];

  // CANDIDATE APPLICATION STEP 1
  // NAME INPUT PAGE
  public static readonly nameInputPageTitle = ['applicationProcess', 'nameInputPage', 'title'];
  public static readonly nameInputPageDescription = ['applicationProcess', 'nameInputPage', 'description'];
  public static readonly firstName = ['applicationProcess', 'nameInputPage', 'firstName'];
  public static readonly firstNamePlaceholder = ['applicationProcess', 'nameInputPage', 'firstNamePlaceholder'];
  public static readonly lastName = ['applicationProcess', 'nameInputPage', 'lastName'];
  public static readonly lastNamePlaceholder = ['applicationProcess', 'nameInputPage', 'lastNamePlaceholder'];
  public static readonly nickname = ['applicationProcess', 'nameInputPage', 'nickname'];
  public static readonly nicknamePlaceholder = ['applicationProcess', 'nameInputPage', 'nicknamePlaceholder'];

  // CANDIDATE APPLICATION STEP 2
  // VISA SELECTION PAGE
  public static readonly ApplyVisaSelectionTitle = ['applicationProcess', 'visaSelectionPage', 'title'];
  public static readonly ApplyVisaSelectionDescription = ['applicationProcess', 'visaSelectionPage', 'description'];
  public static readonly ApplyInvalidVisaError = ['applicationProcess', 'visaSelectionPage', 'invalidVisaError'];
  public static readonly ApplyVisaSelectionResidenceCardRequirement = [
    'applicationProcess',
    'visaSelectionPage',
    'residenceCardRequirement',
  ];
  public static readonly selectedVisaLabel = ['applicationProcess', 'visaSelectionPage', 'selectedVisaLabel'];
  public static readonly residenceCardTitle = ['applicationProcess', 'visaSelectionPage', 'residenceCardRequirement', 'title'];
  public static readonly residenceCardDescription = [
    'applicationProcess',
    'visaSelectionPage',
    'residenceCardRequirement',
    'description',
  ];
  public static readonly ApplyVisaSelectionResidenceCardRequirementWarning = [
    'applicationProcess',
    'visaSelectionPage',
    'residenceCardRequirementWarning',
  ];

  // CANDIDATE APPLICATION STEP 3
  // CONTACT INFORMATION PAGE
  public static readonly contactInformationPageTitle = ['applicationProcess', 'contactInformationPage', 'title'];
  public static readonly contactInformationPageDescription = ['applicationProcess', 'contactInformationPage', 'description'];
  public static readonly contactInformationPagePhoneNumber = ['applicationProcess', 'contactInformationPage', 'phoneNumber'];
  public static readonly contactInformationPagePhoneNumberPlaceholder = [
    'applicationProcess',
    'contactInformationPage',
    'phoneNumberPlaceholder',
  ];
  public static readonly contactInformationPageEmail = ['applicationProcess', 'contactInformationPage', 'email'];
  public static readonly contactInformationPageEmailPlaceholder = [
    'applicationProcess',
    'contactInformationPage',
    'emailPlaceholder',
  ];
  public static readonly contactInformationPageErrorAlreadyApplied = [
    'applicationProcess',
    'contactInformationPage',
    'errorAlreadyApplied',
  ];
  public static readonly contactInformationPageNotificationTandCs = [
    'applicationProcess',
    'contactInformationPage',
    'notificationTandCs',
  ];

  // CANDIDATE APPLICATION STEP 4
  // PERSONAL INFORMATION PAGE
  public static readonly personalInfoPageTitle = ['applicationProcess', 'personalInformationPage', 'title'];
  public static readonly personalInfoPageDescription = ['applicationProcess', 'personalInformationPage', 'description'];
  public static readonly personalInfoPageSex = ['applicationProcess', 'personalInformationPage', 'sex'];
  public static readonly personalInfoPageSexPlaceholder = ['applicationProcess', 'personalInformationPage', 'sexPlaceholder'];
  public static readonly personalInfoPageSexMale = ['applicationProcess', 'personalInformationPage', 'sexMale'];
  public static readonly personalInfoPageSexFemale = ['applicationProcess', 'personalInformationPage', 'sexFemale'];
  public static readonly personalInfoPageDatePlaceholder = ['applicationProcess', 'personalInformationPage', 'datePlaceholder'];
  public static readonly personalInfoPageDOB = ['applicationProcess', 'personalInformationPage', 'dob'];
  public static readonly personalInfoPageCountry = ['applicationProcess', 'personalInformationPage', 'country'];
  public static readonly personalInfoPageCountryPlaceholder = [
    'applicationProcess',
    'personalInformationPage',
    'countryPlaceholder',
  ];
  public static readonly personalInfoPageCity = ['applicationProcess', 'personalInformationPage', 'city'];
  public static readonly personalInfoPageCityPlaceholder = ['applicationProcess', 'personalInformationPage', 'cityPlaceholder'];
  public static readonly personalInfoPageGrowUp = ['applicationProcess', 'personalInformationPage', 'growUp'];
  public static readonly personalInfoPageGrowUpPlaceholder = [
    'applicationProcess',
    'personalInformationPage',
    'growUpPlaceholder',
  ];
  public static readonly personalInfoPageArrival = ['applicationProcess', 'personalInformationPage', 'arrival'];
  public static readonly personalInfoPagePrefecture = ['applicationProcess', 'personalInformationPage', 'prefecture'];
  public static readonly personalInfoPagePrefecturePlaceholder = [
    'applicationProcess',
    'personalInformationPage',
    'prefecturePlaceholder',
  ];
  public static readonly personalInfoPageAlertBody = ['applicationProcess', 'personalInformationPage', 'alertBody'];

  // CANDIDATE APPLICATION STEP 6
  // APPLIED PAGE
  public static readonly ApplicationSignUpPageTitle = ['applicationProcess', 'signUpPage', 'title'];
  public static readonly ApplicationSignUpPageDescription = ['applicationProcess', 'signUpPage', 'description'];
  public static readonly ApplicationSignUpPageInstruction = ['applicationProcess', 'signUpPage', 'instruction'];
  public static readonly ApplicationSignUpPageSocialLogin = ['applicationProcess', 'signUpPage', 'socialLogin'];
  public static readonly ApplicationSignUpPageEmailLogin = ['applicationProcess', 'signUpPage', 'emailLogin'];
  public static readonly ApplicationSignUpPagePasswordPlaceholder = ['applicationProcess', 'signUpPage', 'passwordPlaceholder'];
  public static readonly ApplicationSignUpPagePasswordTitle = ['applicationProcess', 'signUpPage', 'passwordTitle'];
  public static readonly ApplicationSignUpPageAgreementsTitle = ['applicationProcess', 'signUpPage', 'agreementsTitle'];
  public static readonly ApplicationSignUpPageProfileSaved = ['applicationProcess', 'signUpPage', 'profileSaved'];
  public static readonly ApplicationSignUpPageRecommendations = ['applicationProcess', 'signUpPage', 'recommendations'];
  public static readonly reminderText = ['applicationProcess', 'signUpPage', 'reminder', 'title'];
  public static readonly reminderDescription = ['applicationProcess', 'signUpPage', 'reminder', 'description'];
  public static readonly myNumberTitle = ['applicationProcess', 'signUpPage', 'myNumberConfirmation', 'title'];
  public static readonly myNumberDescription = ['applicationProcess', 'signUpPage', 'myNumberConfirmation', 'description'];
  public static readonly termsReminderAgreementText = [
    'applicationProcess',
    'signUpPage',
    'termsAndConditions',
    'remindersAgreementText',
  ];
  public static readonly termsConditionsAgreementText1 = [
    'applicationProcess',
    'signUpPage',
    'termsAndConditions',
    'termsAgreementText1',
  ];
  public static readonly termsConditionsAgreementText2 = [
    'applicationProcess',
    'signUpPage',
    'termsAndConditions',
    'termsAgreementText2',
  ];
  public static readonly termsConditionsLinkText = ['applicationProcess', 'signUpPage', 'termsAndConditions', 'linkText'];
  public static readonly termsConditionsLink = ['applicationProcess', 'signUpPage', 'termsAndConditions', 'link'];

  // CANDIDATE APPLICATION STEP 7
  // VACANCY APPLICATION SUCCESS PAGE
  public static readonly ApplicationSuccessTitle = ['applicationProcess', 'applicationSuccess', 'title'];
  public static readonly ApplicationSuccessDescription = ['applicationProcess', 'applicationSuccess', 'description'];
  public static readonly ApplicationSuccessThankYouStr = ['applicationProcess', 'applicationSuccess', 'thankYouStr'];
  public static readonly ApplicationSuccessThankYouMessage = ['applicationProcess', 'applicationSuccess', 'thankYouMessage'];
  public static readonly ApplicationSuccessCallToActionStr = ['applicationProcess', 'applicationSuccess', 'callToActionStr'];
  public static readonly ApplicationSuccessTipStr = ['applicationProcess', 'applicationSuccess', 'tipStr'];
  public static readonly ApplicationSuccessJobSearchBtnLabel = ['applicationProcess', 'applicationSuccess', 'jobSearchBtnLabel'];
  public static readonly ApplicationSuccessEditProfileBtnLabel = [
    'applicationProcess',
    'applicationSuccess',
    'editProfilehBtnLabel',
  ];

  // Modal
  public static readonly ModalSubmittingApplication = ['modal', 'submittingApplicationText'];
  public static readonly ModalInternetProblemText = ['modal', 'internetProblemText'];
  public static readonly ModalTryAgainText = ['modal', 'tryAgainText'];
  public static readonly ModalPleaseContact = ['modal', 'pleaseContact'];
  public static readonly ModalPleaseContactLink = ['modal', 'pleaseContactLink'];
  public static readonly ErrorOccured = ['modal', 'errorOccured'];

  // TRANSLATION MISSING PAGE
  public static readonly inProgressHeaderText = ['translationMissingPage', 'inProgressHeaderText'];
  public static readonly pleaseWaitText = ['translationMissingPage', 'pleaseWaitText'];
  public static readonly workingOnItText = ['translationMissingPage', 'workingOnItText'];
  public static readonly redirectHomeText = ['translationMissingPage', 'redirectHomeText'];

  // VACANCY DETAILS
  public static readonly performanceBonus = ['detailsPage', 'gnosisKyvos', 'salary', 'performanceBonusText'];
  public static readonly JapaneseSkillsCubeNoJapaneseListed = [
    'detailsPage',
    'gnosisKyvos',
    'japanese',
    'noJapaneseRequiredListed',
  ];
  public static readonly JapaneseSkillsCubeLvl1 = ['detailsPage', 'gnosisKyvos', 'japanese', 'Native'];
  public static readonly JapaneseSkillsCubeLvl2 = ['detailsPage', 'gnosisKyvos', 'japanese', 'N1'];
  public static readonly JapaneseSkillsCubeLvl3 = ['detailsPage', 'gnosisKyvos', 'japanese', 'N2'];
  public static readonly JapaneseSkillsCubeLvl4 = ['detailsPage', 'gnosisKyvos', 'japanese', 'N3'];
  public static readonly JapaneseSkillsCubeLvl5 = ['detailsPage', 'gnosisKyvos', 'japanese', 'N4'];
  public static readonly JapaneseSkillsCubeLvl6 = ['detailsPage', 'gnosisKyvos', 'japanese', 'N5'];
  public static readonly JapaneseSkillsCubeLvl7 = ['detailsPage', 'gnosisKyvos', 'japanese', 'noJapaneseRequired'];

  public static readonly ApplyRibbonClickHereBtnLabel = ['detailsPage', 'applyRibbon', 'clickHereBtnLabel'];
  public static readonly ApplyRibbonGoToMyProfileBtnLabel = ['detailsPage', 'applyRibbon', 'goToMyProfile'];
  public static readonly ApplyRibbonEditMyProfileBtnLabel = ['detailsPage', 'applyRibbon', 'editMyProfile'];
  public static readonly ApplyRibbonSelectJpLevelText = ['detailsPage', 'applyRibbon', 'selectJpLevel'];
  public static readonly ApplyRibbonSelectVisaTypeText = ['detailsPage', 'applyRibbon', 'selectVisaType'];
  public static readonly ApplyRibbonChangeJpLevelBtnLabel = ['detailsPage', 'applyRibbon', 'changeJpLevel'];
  public static readonly ApplyRibbonChangeVisaBtnLabel = ['detailsPage', 'applyRibbon', 'changeVisa'];
  public static readonly ApplyRibbonSelectJpLevelBtnLabel = ['detailsPage', 'applyRibbon', 'selectJpLevelBtnLabel'];
  public static readonly ApplyRibbonSelectVisaTypeBtnLabel = ['detailsPage', 'applyRibbon', 'selectVisaTypeBtnLabel'];
  public static readonly ApplyRibbonUpdateProfileText = ['detailsPage', 'applyRibbon', 'updateProfileText'];
  public static readonly ApplyRibbonOutOfDateProfileText = ['detailsPage', 'applyRibbon', 'outOfDateProfileText'];
  public static readonly ApplyRibbonUpdateInvalidJpLevelAndVisaTextArray = ['detailsPage', 'applyRibbon', 'invalidJpLevelAndVisaTextArray'];
  public static readonly ApplyRibbonUpdateInvalidJpLevelTextArray = ['detailsPage', 'applyRibbon', 'invalidJpLevelTextArray'];
  public static readonly ApplyRibbonUpdateInvalidVisaTextArray = ['detailsPage', 'applyRibbon', 'invalidVisaTextArray'];
  public static readonly ApplyRibbonUpdateValidJpLevelAndVisaTextArray = ['detailsPage', 'applyRibbon', 'validJpLevelAndVisaTextArray'];
  public static readonly ApplyRibbonUpdateValidJpLevelTextArray = ['detailsPage', 'applyRibbon', 'validJpLevelTextArray'];
  public static readonly ApplyRibbonUpdateValidVisaTextArray = ['detailsPage', 'applyRibbon', 'validVisaTextArray'];

  public static readonly ContractDetails = ['vacancyCardTitles', 'contractDetails', 'title'];
  public static readonly ContractType = ['vacancyCardTitles', 'contractDetails', 'subTitles', 'contractType'];
  public static readonly ProbationPeriod = ['vacancyCardTitles', 'contractDetails', 'subTitles', 'probationPeriod'];
  public static readonly EmploymentPeriod = ['vacancyCardTitles', 'contractDetails', 'subTitles', 'employmentPeriod'];
  public static readonly Share = ['shareSocial', 'shareText'];

  public static readonly WagesAndShifts = ['vacancyCardTitles', 'wagesAndShifts', 'title'];
  public static readonly Salary = ['vacancyCardTitles', 'wagesAndShifts', 'subTitles', 'salary'];
  public static readonly DailyShift = ['vacancyCardTitles', 'wagesAndShifts', 'subTitles', 'dailyShift'];
  public static readonly SalaryClosingDay = ['vacancyCardTitles', 'wagesAndShifts', 'subTitles', 'salaryClosingDay'];
  public static readonly WorkingDays = ['vacancyCardTitles', 'wagesAndShifts', 'subTitles', 'workingDays'];
  public static readonly PossibleOvertime = ['vacancyCardTitles', 'wagesAndShifts', 'subTitles', 'possibleOvertime'];
  public static readonly Payday = ['vacancyCardTitles', 'wagesAndShifts', 'subTitles', 'payday'];
  public static readonly SalaryIncentivesAndPerformanceBonus = ['vacancyCardTitles', 'wagesAndShifts', 'salaryIncentivesAndPerformanceBonus'];
  public static readonly NoListed = ['vacancyCardTitles', 'wagesAndShifts', 'subTitles', 'noListed'];
  public static readonly Start = ['vacancyCardTitles', 'wagesAndShifts', 'subTitles', 'start'];
  public static readonly Finish = ['vacancyCardTitles', 'wagesAndShifts', 'subTitles', 'finish'];
  public static readonly Monday = ['vacancyCardTitles', 'wagesAndShifts', 'subTitles', 'monday'];
  public static readonly Tuesday = ['vacancyCardTitles', 'wagesAndShifts', 'subTitles', 'tuesday'];
  public static readonly Wednesday = ['vacancyCardTitles', 'wagesAndShifts', 'subTitles', 'wednesday'];
  public static readonly Thursday = ['vacancyCardTitles', 'wagesAndShifts', 'subTitles', 'thursday'];
  public static readonly Friday = ['vacancyCardTitles', 'wagesAndShifts', 'subTitles', 'friday'];
  public static readonly Saturday = ['vacancyCardTitles', 'wagesAndShifts', 'subTitles', 'saturday'];
  public static readonly Sunday = ['vacancyCardTitles', 'wagesAndShifts', 'subTitles', 'sunday'];
  public static readonly OvertimePerMonth = ['vacancyCardTitles', 'wagesAndShifts', 'subTitles', 'overtimePerMonth'];
  public static readonly FromSalary = ['vacancyCardTitles', 'wagesAndShifts', 'subTitles', 'fromSalary'];
  public static readonly UpToSalary = ['vacancyCardTitles', 'wagesAndShifts', 'subTitles', 'upToSalary'];
  public static readonly WageAndSalary = ['vacancyCardTitles', 'wagesAndShifts', 'subTitles', 'wageAndSalary'];
  public static readonly HasNoWageDefined = ['vacancyCardTitles', 'wagesAndShifts', 'subTitles', 'hasNoWageDefined'];
  public static readonly ManyWagesAndShift = ['vacancyCardTitles', 'wagesAndShifts', 'subTitles', 'manyWagesAndShift'];

  public static readonly JapaneseSkills = ['vacancyCardTitles', 'japaneseSkills', 'title'];
  public static readonly JapaneseSkillsReading = ['vacancyCardTitles', 'japaneseSkills', 'subTitles', 'reading'];
  public static readonly JapaneseSkillsListening = ['vacancyCardTitles', 'japaneseSkills', 'subTitles', 'listening'];
  public static readonly JapaneseSkillsWriting = ['vacancyCardTitles', 'japaneseSkills', 'subTitles', 'writing'];
  public static readonly JapaneseSkillsSpeaking = ['vacancyCardTitles', 'japaneseSkills', 'subTitles', 'speaking'];
  public static readonly JapaneseSkillsAdditionalInformation = ['vacancyCardTitles', 'japaneseSkills', 'additionalInformation'];

  public static readonly VisaSelectBtnChangeLabel = ['detailsPage', 'visaEligibility', 'visaSelectBtnChangeLabel'];
  public static readonly SelectVisaEligibilty = ['detailsPage', 'visaEligibility', 'selectVisaText'];
  public static readonly SelectVisaType = ['detailsPage', 'visaEligibility', 'selectVisaType'];
  public static readonly GoToMyProfileText = ['detailsPage', 'visaEligibility', 'goToMyProfileText'];
  public static readonly ValidVisaText = ['detailsPage', 'visaEligibility', 'validVisaText'];
  public static readonly Apply = ['detailsPage', 'visaEligibility', 'applyBtnLabel'];
  public static readonly Applied = ['detailsPage', 'visaEligibility', 'appliedBtnLabel'];
  public static readonly InvalidVisaText = ['detailsPage', 'visaEligibility', 'invalidVisaText'];
  public static readonly ValidVisaClass = ['detailsPage', 'visaEligibility', 'validVisaText'];

  public static readonly RequiredSkills = ['vacancyCardTitles', 'requiredSkills', 'title'];
  public static readonly Year = ['vacancyCardTitles', 'requiredSkills', 'year'];
  public static readonly Years = ['vacancyCardTitles', 'requiredSkills', 'years'];

  public static readonly RaisesAndBonuses = ['vacancyCardTitles', 'raisesAndBonuses', 'title'];
  public static readonly SalaryIncrease = ['vacancyCardTitles', 'raisesAndBonuses', 'salaryIncrease'];
  public static readonly YearlyBonus = ['vacancyCardTitles', 'raisesAndBonuses', 'yearlyBonus'];
  public static readonly AchievementBasedBonus = ['vacancyCardTitles', 'raisesAndBonuses', 'achievementBasedBonus'];
  public static readonly IndividualIncentives = ['vacancyCardTitles', 'raisesAndBonuses', 'individualIncentives'];
  public static readonly InsuranceAndBenefits = ['vacancyCardTitles', 'insuranceAndBenefits', 'title'];
  public static readonly DesiredSkills = ['vacancyCardTitles', 'desiredSkills', 'title'];
  public static readonly JobDescription = ['vacancyCardTitles', 'jobDescription', 'title'];
  public static readonly LocationVacancyDetails = ['vacancyCardTitles', 'location', 'title'];
  public static readonly WorkplaceDetails = ['vacancyCardTitles', 'workplaceDetails', 'title'];
  public static readonly Benefits = ['vacancyCardTitles', 'workplaceDetails', 'title'];
  public static readonly Allowances = ['vacancyCardTitles', 'allowances', 'title'];
  public static readonly JapaneseBusinessSkills = ['vacancyCardTitles', 'japaneseBusinessSkills', 'title'];

  // Tooltips
  public static readonly TooltipDateOfBirthHeader = ['tooltip', 'dateOfBirth', 'header'];
  public static readonly TooltipDateOfBirthDescription = ['tooltip', 'dateOfBirth', 'description'];
  public static readonly TooltipDateOfBirthTip = ['tooltip', 'dateOfBirth', 'tip'];

  public static readonly TooltipSexHeader = ['tooltip', 'sex', 'header'];
  public static readonly TooltipSexDescription = ['tooltip', 'sex', 'description'];
  public static readonly TooltipSexTip = ['tooltip', 'sex', 'tip'];

  /////////////// META TAGS ///////////////
  /// Generic Tags Used on all Meta Tags ///
  public static readonly genericMetaTagKeywords = ['metatags', 'genericKeywords'];
  /// Candidate - Vacancy List Page ///
  public static readonly VacancyListPageMetaTagTitle = ['metatags', 'vacancyListPage', 'title'];
  public static readonly VacancyListPageMetaTagDescription = ['metatags', 'vacancyListPage', 'description'];
  public static readonly VacancyListPageMetaTagKeywords = ['metatags', 'vacancyListPage', 'keywords'];
  /// Candidate - Account Page ///
  public static readonly CandidateAccountPageTitle = ['candidateAccountPage', 'title'];
  public static readonly CandidateAccountPageEmailLabel = ['candidateAccountPage', 'emailLabel'];
  public static readonly CandidateAccountPagePasswordResultModalHeader = ['candidateAccountPage', 'passwordResultModalHeader'];
  public static readonly CandidateAccountPagePasswordResultModalSuccessText = ['candidateAccountPage', 'passwordResultModalText'];
  public static readonly CandidateAccountPagePasswordResultModalErrorText = [
    'candidateAccountPage',
    'passwordResultModalErrorText',
  ];
  /// Candidate - Email Updated Page ///
  public static readonly CandidateEmailUpdatedPageTitle = ['candidateEmailUpdatedPage', 'title'];
  public static readonly CandidateEmailUpdatedPageParagraph1 = ['candidateEmailUpdatedPage', 'paragraph1'];
  public static readonly CandidateEmailUpdatedPageParagraph2 = ['candidateEmailUpdatedPage', 'paragraph2'];
  /// Agent - Login Page ///
  public static readonly AgentLoginPageMetaTagTitle = ['metatags', 'agentLoginPage', 'title'];
  public static readonly AgentLoginPageMetaTagDescription = ['metatags', 'agentLoginPage', 'description'];
  public static readonly AgentLoginPageMetaTagKeywords = ['metatags', 'agentLoginPage', 'keywords'];
  /// Agent - Sign Up Page ///
  public static readonly AgentSignupPageMetaTagTitle = ['metatags', 'agentSignupPage', 'title'];
  public static readonly AgentSignupPageMetaTagDescription = ['metatags', 'agentSignupPage', 'description'];
  public static readonly AgentSignupPageMetaTagKeywords = ['metatags', 'agentSignupPage', 'keywords'];
  /// Agent - Forgot Password Page ///
  public static readonly AgentForgotPasswordPageMetaTagTitle = ['metatags', 'agentForgotPasswordPage', 'title'];
  public static readonly AgentForgotPasswordPageMetaTagDescription = ['metatags', 'agentForgotPasswordPage', 'description'];
  /// Candidate - Vacancy List Page ///
  public static readonly CandidateForgotPasswordPageMetaTagTitle = ['metatags', 'candidateForgotPasswordPage', 'title'];
  public static readonly CandidateForgotPasswordPageMetaTagDescription = [
    'metatags',
    'candidateForgotPasswordPage',
    'description',
  ];
  public static readonly CandidateForgotPasswordPageMetaTagKeywords = ['metatags', 'candidateForgotPasswordPage', 'keywords'];
  /// Candidate - Login Page ///
  public static readonly CandidateLoginPageMetaTagTitle = ['metatags', 'candidateLoginPage', 'title'];
  public static readonly CandidateLoginPageMetaTagDescription = ['metatags', 'candidateLoginPage', 'description'];
  public static readonly CandidateLoginPageMetaTagKeywords = ['metatags', 'candidateLoginPage', 'keywords'];
  /// Candidate - SignUp Page ///
  public static readonly CandidateSignupPageMetaTagTitle = ['metatags', 'candidateSignupPage', 'title'];
  public static readonly CandidateSignupPageMetaTagDescription = ['metatags', 'candidateSignupPage', 'description'];
  public static readonly CandidateSignupPageMetaTagKeywords = ['metatags', 'candidateSignupPage', 'keywords'];
  /// Candidate - My Profile ///
  public static readonly ProfilePageMetaTagTitle = ['metatags', 'profilePage', 'title'];
  /// Candidate - Application List View page
  public static readonly CandidateApplicationViewPagesMetaTagTitle = ['metatags', 'candidateApplicationViewPages', 'title'];
  /// Candidate - Application Phone Number Auth Component
  public static readonly CandidateApplicationViewPagesPhoneNumberAuthTitle = [
    'candidateApplicationViewPages',
    'phoneNumberAuthComponent',
    'phoneNumberAuthTitle',
  ];
  public static readonly CandidateApplicationViewPagesPhoneNumberInputTitle = [
    'candidateApplicationViewPages',
    'phoneNumberAuthComponent',
    'phoneNumberInputTitle',
  ];
  public static readonly CandidateApplicationViewPagesPhoneNumberAuthErrorText = [
    'candidateApplicationViewPages',
    'phoneNumberAuthComponent',
    'validationErrorText',
  ];

  public static readonly AccountMigrationPageWelcome = ['accountMigrationPage', 'welcome'];
  public static readonly AccountMigrationPageCreateAcc = ['accountMigrationPage', 'createAcc'];
  public static readonly AccountMigrationPageApply = ['accountMigrationPage', 'apply'];
  public static readonly AccountMigrationPageMissingEmail = ['accountMigrationPage', 'missingEmail'];
  public static readonly AccountMigrationPageMissingEmailDesc = ['accountMigrationPage', 'missingEmailDesc'];

  /// Candidate Navigation
  public static readonly CandidateBackToHomepage = ['candidate', 'backToHomepage'];

  /// Candidate - Application List View Page
  public static readonly CandidateApplicationViewPagesListPageTitle = ['candidateApplicationViewPages', 'listPage', 'title'];
  public static readonly CandidateApplicationViewPagesListPageClosedApplications = [
    'candidateApplicationViewPages',
    'listPage',
    'closedApplications',
  ];
  public static readonly CandidateApplicationViewPagesListPageViewClosedBtnText = [
    'candidateApplicationViewPages',
    'listPage',
    'viewClosedBtnText',
  ];
  public static readonly CandidateApplicationViewPagesListPageViewActiveBtnText = [
    'candidateApplicationViewPages',
    'listPage',
    'viewActiveBtnText',
  ];

  public static readonly CandidateApplicationViewPagesListPageLast30DaysText = [
    'candidateApplicationViewPages',
    'listPage',
    'last30DaysText',
  ];
  public static readonly CandidateApplicationViewPagesListPageNoCurrentApplications = [
    'candidateApplicationViewPages',
    'listPage',
    'noCurrentApplications',
  ];
  public static readonly CandidateApplicationViewPagesListPageNoClosedApplications = [
    'candidateApplicationViewPages',
    'listPage',
    'noClosedApplications',
  ];
  /// Candidate - Application Details View page
  public static readonly CandidateApplicationViewPagesDetailsPageApplicationTitleText = [
    'candidateApplicationViewPages',
    'detailsPage',
    'applicationTitleText',
  ];
  public static readonly CandidateApplicationViewPagesDetailsPageStatusTitleText = [
    'candidateApplicationViewPages',
    'detailsPage',
    'updateTitleText',
  ];
  public static readonly CandidateApplicationViewPagesDetailsPageWithdrawBtn = [
    'candidateApplicationViewPages',
    'detailsPage',
    'withdrawBtn',
  ];
  public static readonly CandidateApplicationViewPagesDetailsPageBackBtn = [
    'candidateApplicationViewPages',
    'detailsPage',
    'backBtn',
  ];
  /// Candidate - Application Vacancy Card Component
  public static readonly CandidateApplicationViewPagesVacancyCardAppliedOnText = [
    'candidateApplicationViewPages',
    'vacancyCard',
    'appliedOnText',
  ];
  public static readonly CandidateApplicationViewPagesVacancyCardLastUpdatedText = [
    'candidateApplicationViewPages',
    'vacancyCard',
    'lastUpdateText',
  ];
  public static readonly CandidateApplicationViewPagesVacancyCardViewStatusText = [
    'candidateApplicationViewPages',
    'vacancyCard',
    'viewStatusText',
  ];
  public static readonly CandidateApplicationViewPagesVacancyCardViewJobText = [
    'candidateApplicationViewPages',
    'vacancyCard',
    'viewJobText',
  ];
  public static readonly CandidateApplicationViewPagesVacancyCardNewMessageText = [
    'candidateApplicationViewPages',
    'vacancyCard',
    'newMessageText',
  ];
  /// Candidate - Application Job Withdraw Modal
  public static readonly CandidateApplicationViewPagesWithdrawModalTitle = [
    'candidateApplicationViewPages',
    'jobWithdrawalModal',
    'title',
  ];
  public static readonly CandidateApplicationViewPagesWithdrawModalLine1 = [
    'candidateApplicationViewPages',
    'jobWithdrawalModal',
    'line1',
  ];
  public static readonly CandidateApplicationViewPagesWithdrawModalLine2 = [
    'candidateApplicationViewPages',
    'jobWithdrawalModal',
    'line2',
  ];
  public static readonly CandidateApplicationViewPagesWithdrawModalLine3 = [
    'candidateApplicationViewPages',
    'jobWithdrawalModal',
    'line3',
  ];
  public static readonly CandidateApplicationViewPagesWithdrawModalCancelBtnText = [
    'candidateApplicationViewPages',
    'jobWithdrawalModal',
    'cancelBtnText',
  ];
  public static readonly CandidateApplicationViewPagesWithdrawModalWithdrawBtnText = [
    'candidateApplicationViewPages',
    'jobWithdrawalModal',
    'withdrawBtnText',
  ];
  public static readonly CandidateApplicationViewPagesWithdrawModalWithdrawTextboxPlaceholder = [
    'candidateApplicationViewPages',
    'jobWithdrawalModal',
    'withdrawalTextboxPlaceholder',
  ];

  /// Candidate - Application Verify Page ///
  public static readonly ApplicationVerifyReadyToSendYourApplication = [
    'applicationProcess',
    'applicationVerify',
    'readyToSendYourApplication',
  ];
  public static readonly ApplicationVerifyYourAccountHaBeenValidatedWith = [
    'applicationProcess',
    'applicationVerify',
    'yourAccountHaBeenValidatedWith',
  ];
  public static readonly ApplicationVerifyEmailAndPassword = ['applicationProcess', 'applicationVerify', 'emailAndPassword'];
  public static readonly ApplicationVerifySendApplication = ['applicationProcess', 'applicationVerify', 'sendApplication'];

  /// Agent - Job preview Page ///
  public static readonly JobPreviewPageMetaTagTitle = ['metatags', 'jobPreviewPage', 'title'];
  public static readonly JobPreviewPageMetaTagDescription = ['metatags', 'jobPreviewPage', 'description'];
  /// Candidate - Vacancy Passsword Reset Page ///
  public static readonly PasswordResetPageMetaTagTitle = ['metatags', 'passwordResetPage', 'title'];
  /// Candidate - Vacancy Register success Page ///
  public static readonly RegisterSuccessPageMetaTagTitle = ['metatags', 'registerSuccessPage', 'title'];
  /// Candidate - No translation page
  public static readonly TranslationNotAvailablePageMetaTagTitle = ['metatags', 'translationNotAvailablePage', 'title'];
  /// Candidate - Vacancy Map Page ///
  public static readonly VacancyMapPageMetaTagTitle = ['metatags', 'vacancyMapPage', 'title'];
  public static readonly VacancyMapPageMetaTagDescription = ['metatags', 'vacancyMapPage', 'description'];
  public static readonly VacancyMapPageMetaTagKeywords = ['metatags', 'vacancyMapPage', 'keywords'];
  /// Candidate - Vacancy Details Page ///
  public static readonly VacancyDetailPageMetaTagTitle = ['metatags', 'vacancyDetailPage', 'title'];
  /// AG-GRID STOCK LOCALIZATIONS///
  public static readonly AGGridLocaleSetFilter = ['agGridLanguageLocale', 'setFilter'];
  public static readonly AGGridLocaleNumberAndTextFilter = ['agGridLanguageLocale', 'numberAndTextFilter'];
  public static readonly AGGridLocaleNumberFilter = ['agGridLanguageLocale', 'numberFilter'];
  public static readonly AGGridLocaleTextFilter = ['agGridLanguageLocale', 'textFilter'];
  public static readonly AGGridLocaleDateFilter = ['agGridLanguageLocale', 'dateFilter'];
  public static readonly AGGridLocaleFilterButtons = ['agGridLanguageLocale', 'filterButtons'];
  public static readonly AGGridLocaleSidebar = ['agGridLanguageLocale', 'sideBar'];
  public static readonly AGGridLocaleMenu = ['agGridLanguageLocale', 'menu'];
  /// AG-GRID CUSTOM LOCALIZATIONS///
  public static readonly AGGridLocaleCustomFilterLabelPublished = ['agGridLanguageLocale', 'customFilterLabels', 'published'];
  public static readonly AGGridLocaleCustomFilterLabelUnPublished = ['agGridLanguageLocale', 'customFilterLabels', 'unpublished'];
  public static readonly AGGridLocaleCustomFilterLabelImageExists = ['agGridLanguageLocale', 'customFilterLabels', 'imageExists'];
  public static readonly AGGridLocaleCustomFilterLabelImageDoesNotExist = [
    'agGridLanguageLocale',
    'customFilterLabels',
    'imageDoesNotExist',
  ];
  public static readonly AGGridLocaleCustomFilterLabelTranslated = ['agGridLanguageLocale', 'customFilterLabels', 'translated'];
  public static readonly AGGridLocaleCustomFilterLabelNotTranslated = [
    'agGridLanguageLocale',
    'customFilterLabels',
    'notTranslated',
  ];
  public static readonly AGGridLocaleCustomFilterLabelDateFilterGreaterThan = [
    'agGridLanguageLocale',
    'customFilterLabels',
    'dateFilterGreaterThan',
  ];
  public static readonly AGGridLocaleCustomFilterLabelDateFilterLessThan = [
    'agGridLanguageLocale',
    'customFilterLabels',
    'dateFilterLessThan',
  ];
  public static readonly AGGridLocaleCustomFilterLabelDateFilterEquals = [
    'agGridLanguageLocale',
    'customFilterLabels',
    'dateFilterEquals',
  ];
  public static readonly AGGridLocaleCustomFilterLabelDateFilterInRange = [
    'agGridLanguageLocale',
    'customFilterLabels',
    'dateFilterInRange',
  ];
  /// AG=GRID APPLICATION LIST PAGE///
  public static readonly AgentArchiveApplicationModalTitle = ['AgentApplicationArchiveModal', 'title'];
  public static readonly AgentArchiveApplicationModalDescription = ['AgentApplicationArchiveModal', 'description'];
  public static readonly AgentArchiveApplicationModalArchiveBtnText = ['AgentApplicationArchiveModal', 'archiveBtnText'];
  public static readonly AgentArchiveApplicationModalDoNotMoveToArchiveBtnText = [
    'AgentApplicationArchiveModal',
    'doNotMoveToArchiveBtnText',
  ];
  public static readonly AgentHireApplicationModalTitle = ['AgentApplicationHireModal', 'title'];
  public static readonly AgentHireApplicationModalDescription = ['AgentApplicationHireModal', 'description'];
  public static readonly AgentHireApplicationModalHireBtnText = ['AgentApplicationHireModal', 'hireBtnText'];
  public static readonly AgentHireApplicationModalDoNotMoveToHiredBtnText = [
    'AgentApplicationHireModal',
    'doNotMoveToHiredBtnText',
  ];
  /// TIMELINE ///
  public static readonly TimelineDeleteConfirm = ['timeline', 'deleteConfirm'];
  public static readonly TimelineYearFormat = ['timeline', 'timestampYearFormat'];
  public static readonly TimelineMonthFormat = ['timeline', 'timestampMonthFormat'];
  public static readonly TimelineDayFormat = ['timeline', 'timestampDayFormat'];

  // Translation Switcher
  public static readonly TranslationSwitcherOriginalTextLabel = ['translationSwitcher', 'originalText'];
  public static readonly TranslationSwitcherJpTextLabel = ['translationSwitcher', 'jpText'];

  // RESUME
  // CARDS
  public static readonly ResumeGenericTitle = ['resumeCards', 'genericTitle'];
  public static readonly ResumeCardPeriod = ['resumeCards', 'period'];
  public static readonly ResumeCardToPresent = ['resumeCards', 'toPresent'];
  public static readonly ResumeEducationCardGraduation = ['resumeEducationCard', 'graduationYear'];
  public static readonly ResumeEducationCardStillStudying = ['resumeEducationCard', 'stillStudying'];
  public static readonly ResumeEducationCardStillStudyingFrom = ['resumeEducationCard', 'stillStudyingFrom'];
  public static readonly ResumeEducationCardTitle = ['resumeEducationCard', 'title'];
  public static readonly ResumeEducationCardStartYear = ['resumeEducationCard', 'startYear'];
  public static readonly ResumeEducationCardStartMonth = ['resumeEducationCard', 'startMonth'];
  public static readonly ResumeEducationCardCompletionYear = ['resumeEducationCard', 'completionYear'];
  public static readonly ResumeEducationCardCompletionMonth = ['resumeEducationCard', 'completionMonth'];
  public static readonly ResumeJobHistoryCardTitle = ['resumeJobHistoryCard', 'title'];
  public static readonly ResumeQualificationCardTitle = ['resumeQualificationCard', 'title'];

  // RESUME PROCESSING PAGE
  public static readonly ResumeReturnToJobList = ['resumeProcessingPage', 'returnToJobList'];
  public static readonly ResumeComplete = ['resumeProcessingPage', 'resumeComplete'];
  public static readonly ResumeProcessing = ['resumeProcessingPage', 'resumeProcessing'];

  // COMPONENTS
  // C-IMAGE-UPLOADER
  public static readonly ComponentsCImageUploaderEmptyUploadText = ['components', 'cImageUploader', 'emptyUploadText'];
  public static readonly ComponentsCImageUploaderEmptyUploadDropText = ['components', 'cImageUploader', 'emptyUploadDropText'];
  public static readonly ComponentsCImageUploaderSidemenuUploadLabel = ['components', 'cImageUploader', 'sidemenu', 'upload'];
  public static readonly ComponentsCImageUploaderSidemenuChangeLabel = ['components', 'cImageUploader', 'sidemenu', 'change'];
  public static readonly ComponentsCImageUploaderSidemenuEditLabel = ['components', 'cImageUploader', 'sidemenu', 'edit'];
  public static readonly ComponentsCImageUploaderSidemenuDeleteLabel = ['components', 'cImageUploader', 'sidemenu', 'delete'];
  public static readonly ComponentsCImageUploaderModalFlipHorizontalLabel = [
    'components',
    'cImageUploader',
    'modal',
    'flipHorizontal',
  ];
  public static readonly ComponentsCImageUploaderModalFlipVerticalLabel = [
    'components',
    'cImageUploader',
    'modal',
    'flipVertical',
  ];
  public static readonly ComponentsCImageUploaderModalRotateLeftLabel = ['components', 'cImageUploader', 'modal', 'rotateLeft'];
  public static readonly ComponentsCImageUploaderModalRotateRightLabel = ['components', 'cImageUploader', 'modal', 'rotateRight'];

  // C-JP-LEVEL
  public static readonly CJPLevelInterviewText = ['components', 'cJPLevel', 'interviewText'];
}
