import VueRouter from 'vue-router'
import { LanguageURL, LocalizationData, LocalizationPath } from '../../../../../../core/src'
import { LocalizationService } from '../../../../../core/src'
import { RouterLink } from '../../router-links/router-link'
import { RouterLinkFactory } from '../../router-links/router-link.factory'
import { IconFlip } from '../c-icon/enum/icon-flip.enum'
import { IconPlacement } from '../c-icon/enum/icon-placement.enum'
import { IconSize } from '../c-icon/enum/icon-size.enum'
import { CIconFactory } from '../c-icon/factory/c-icon.factory'
import { IButton } from './interface'

/**
 * Creates differnt types of buttons.
 * @todo Create builder for the buttons / css classes /etc
 */
export class ButtonFactory {
  /**
   * Generic button.
   */
  static button(props: IButton): IButton {
    return {
      label: props.label || '',
      classes: props.classes || '',
      icon: props.icon || undefined,
      link: props.link || undefined,
      width: props.width || '100%',
      height: props.height || undefined,
      cyTag: props.cyTag || 'button-cy',
      ...props
    }
  }
  static addLogTripleButton(label: string): IButton {
    return {
      label: label,
      classes: 'theme-shades-light1-white row-center br-5 p-5 text-regular',
      hoverClasses: 'theme-blue-dark1-color row-center br-5 p-5 text-regular',
      width: '80px',
      cyTag: `${label}-btn-cy`
    }
  }

  /**
   * Button that is not really meant to behave like a button. It is used to display the current value of a Dropdown list.
   * @param label
   * @returns
   */
  static dropdownButton(label: string): IButton {
    return {
      label: label,
      classes: 'theme-shades-light1-white input-lg row-between g-10 fullwidth text-regular no-wrap ellipsis',
      icon: CIconFactory.DropdownCaret(),
      width: '100%',
      cyTag: 'dropdown-button'
    }
  }

  /**
   * Generic cancel button.
   * @param props Optional props, allowing button overrides.
   * @returns
   */
  static cancelButton(props?: IButton): IButton {
    const defaultButtonProps: IButton = {
      label: 'Cancel',
      icon: CIconFactory.Icon({ icon: 'circle-xmark' }),
      classes: 'theme-shades-light1-white row-start align-center g-5 btn-sm',
      width: '100%'
    }

    return { ...defaultButtonProps, ...props }
  }

  /**
   * Generic save button
   * @param props Optional props, allowing button overrides.
   * @returns
   */
  static saveButton(props?: IButton): IButton {
    const defaultButtonProps: IButton = {
      label: 'Save',
      icon: CIconFactory.Icon({ icon: 'circle-check' }),
      classes: 'theme-blue-dark1-color row-start align-center g-5 btn-sm',
      width: '100%'
    }
    return { ...defaultButtonProps, ...props }
  }

  /**
   * Generic save button
   * @param props Optional props, allowing button overrides.
   * @returns
   */
  static applyButton(props?: IButton): IButton {
    const defaultButtonProps: IButton = {
      label: 'Apply',
      icon: CIconFactory.Icon({ icon: 'circle-check' }),
      classes: 'theme-blue-dark1-color row-between align-center g-5 btn-md',
      width: '100%'
    }
    return { ...defaultButtonProps, ...props }
  }

  /**
   * Generic delete button
   * @param props Optional props, allowing button overrides.
   * @returns
   */
  static deleteButton(props?: IButton): IButton {
    const defaultButtonProps: IButton = {
      label: 'Delete',
      icon: CIconFactory.Icon({ icon: 'trash-can' }),
      classes: 'theme-orange-dark1-color row-start align-center g-5 btn-sm',
      width: '100%'
    }
    return { ...defaultButtonProps, ...props }
  }
  /**
   * Generic delete button
   * @param props Optional props, allowing button overrides.
   * @returns
   */
  static continueToApplication(props?: IButton): IButton {
    const defaultButtonProps: IButton = {
      label: 'Continue to your Application',
      icon: CIconFactory.Icon({ icon: 'paper-plane-top' }),
      classes: 'theme-blue-dark1-color row-between align-center g-5 btn-sm',
      width: '100%'
    }
    return { ...defaultButtonProps, ...props }
  }
  static goToJobSearch(link: RouterLink, props?: IButton): IButton {
    const defaultButtonProps: IButton = {
      icon: CIconFactory.Icon({
        icon: 'magnifying-glass',
        placement: IconPlacement.Left
      }),
      classes: 'theme-blue-dark2-white color-blue-dark1 row-start align-center g-10 btn-lg fullwidth',
      width: '100%',
      link: link
    }
    return { ...defaultButtonProps, ...props }
  }
  static goToApplication(link: RouterLink, props?: IButton): IButton {
    const defaultButtonProps: IButton = {
      label: 'View Application',
      icon: CIconFactory.Icon({
        icon: 'file-user',
        placement: IconPlacement.Left
      }),
      classes: 'theme-green-dark1-white color-green-dark1 row-start align-center g-10 btn-lg fullwidth',
      width: '100%',
      link: link
    }
    return { ...defaultButtonProps, ...props }
  }
  static goToProfile(link: RouterLink, props?: IButton): IButton {
    const defaultButtonProps: IButton = {
      label: 'Improve my Profile',
      icon: CIconFactory.Icon({
        icon: 'id-card',
        flip: IconFlip.Horizontal,
        placement: IconPlacement.Left
      }),
      classes: 'theme-shades-medium2-white row-start align-center g-10 btn-lg fullwidth',
      width: '100%',
      link: link
    }
    return { ...defaultButtonProps, ...props }
  }
  static goToResumeCreation(link: RouterLink, props?: IButton): IButton {
    const defaultButtonProps: IButton = {
      label: 'Create a Resume',
      icon: CIconFactory.Icon({
        icon: 'file-user',
        placement: IconPlacement.Left
      }),
      classes: 'theme-green-dark2-white color-green-dark1-outline row-start align-center g-10 btn-lg fullwidth',
      width: '100%',
      link: link
    }
    return { ...defaultButtonProps, ...props }
  }
  static goToDashboard(link: RouterLink, props?: IButton): IButton {
    const defaultButtonProps: IButton = {
      label: 'Dashboard',
      icon: CIconFactory.Icon({
        icon: 'grid-horizontal',
        placement: IconPlacement.Left
      }),
      classes: 'theme-shades-light1-color-border row-start align-center g-10 btn-lg fullwidth',
      width: '100%',
      link: link
    }
    return { ...defaultButtonProps, ...props }
  }
  /**
   * Buttons for the edit profile page options
   */
  static profileMenu(link: RouterLink, props: IButton): IButton {
    const defaultButtonProps: IButton = {
      label: props.label,
      classes: props.classes || 'theme-shades-light1-white row-center align-center g-10 input-md no-wrap text-big',
      link: link,
      width: '100%'
    }
    return { ...defaultButtonProps, ...props }
  }
  /**
   * Save button for the profile menu footer
   */
  static menuBackButton(link: RouterLink, props?: IButton): IButton {
    const defaultButtonProps: IButton = {
      label: 'back',
      icon: CIconFactory.Icon({
        icon: 'circle-arrow-left',
        size: IconSize.medium,
        placement: IconPlacement.Left
      }),
      classes: 'color-text-base column-center align-center',
      link: link,
      width: '100%'
    }
    return { ...defaultButtonProps, ...props }
  }
  /**
   * Generic link button
   * @param link
   * @param props
   * @returns
   */
  static linkButton(link: RouterLink, props?: IButton): IButton {
    const defaultButtonProps: IButton = {
      label: props?.label,
      classes: 'theme-blue-dark1-color row-start align-center g-10 btn-md',
      link: link,
      width: '100%'
    }
    return { ...defaultButtonProps, ...props }
  }
  /**
   * Nuxt link button with only text and no icon or bg color
   */
  static textOnlyLink(link: RouterLink, props?: IButton): IButton {
    const defaultButtonProps: IButton = {
      label: 'back',
      classes: 'color-text-base column-center align-center',
      link: link,
      width: '100%'
    }
    return { ...defaultButtonProps, ...props }
  }
  /**
   * Nuxt link blue underlined text only
   */
  static textOnlyLinkBlueUnderline(props?: IButton): IButton {
    const defaultButtonProps: IButton = {
      label: props?.label,
      classes: 'link-format theme-blue-medium1-clear column-center align-center',
      link: props?.link,
      width: props?.width || 'fit-content'
    }
    return { ...defaultButtonProps, ...props }
  }
  /**
   * Generic submit button
   */
  static submit(props?: IButton): IButton {
    return this.button({
      label: 'Submit',
      icon: CIconFactory.Icon({
        icon: 'circle-check',
        size: IconSize.medium,
        placement: IconPlacement.Left
      }),
      classes: 'theme-blue-dark1-color row-start br-15 p-10 g-10 align-center text-regular',
      width: '100%',
      ...props
    })
  }
  static loginCandidate(props?: IButton): IButton {
    const defaultButtonProps: IButton = {
      classes: 'theme-blue-dark1-color btn-lg row-center-fullwidth align-center',
      width: '100%'
    }
    return { ...defaultButtonProps, ...props }
  }

  static loginAgent(props?: IButton): IButton {
    const defaultButtonProps: IButton = {
      classes: 'theme-yellow-medium1-color btn-lg row-center-fullwidth align-center',
      width: '100%'
    }
    return { ...defaultButtonProps, ...props }
  }

  static previous(props?: IButton): IButton {
    const defaultButtonProps: IButton = {
      label: '戻る',
      icon: { icon: 'arrow-left', placement: IconPlacement.Left },
      classes: 'btn-lg theme-shades-light1-white row-start-fullwidth align-center g-10',
      width: '100%'
    }
    return { ...defaultButtonProps, ...props }
  }

  static next(props?: IButton): IButton {
    const defaultButtonProps: IButton = {
      label: '次へ',
      icon: { icon: 'arrow-right', placement: IconPlacement.Right },
      classes: 'btn-lg theme-blue-dark1-color row-between-fullwidth align-center g-10',
      width: '100%',
      cyTag: 'next-btn'
    }
    return { ...defaultButtonProps, ...props }
  }

  /**
   * Vacancy Application previous button
   */
  static applicationPreviousButton(localization: LocalizationData, props?: IButton): IButton {
    const defaultButtonProps: IButton = {
      label: LocalizationService.t<string>(localization, LocalizationPath.backBtn),
      icon: CIconFactory.Icon({ icon: 'arrow-left', placement: IconPlacement.Left }),
      classes: 'btn-md theme-shades-light1-white row-start-fullwidth align-center g-10',
      width: '100%'
    }
    return { ...defaultButtonProps, ...props }
  }

  /**
   * Vacancy Application previous button disabled
   */
  static applicationPreviousButtonDisabled(localization: LocalizationData): IButton {
    return this.applicationPreviousButton(localization, {
      disabled: true
    })
  }

  /**
   * Vacancy Application previous button
   */
  static applicationContinueButton(localization: LocalizationData, props?: IButton): IButton {
    const defaultButtonProps: IButton = {
      label: LocalizationService.t<string>(localization, LocalizationPath.continueBtn),
      icon: CIconFactory.Icon({ icon: 'arrow-right', placement: IconPlacement.Left }),
      classes: 'btn-md theme-blue-dark1-color row-start-fullwidth align-center g-10',
      width: '100%'
    }
    return { ...defaultButtonProps, ...props }
  }

  /**
   * Vacancy Application save button
   * @param props Optional props, allowing button overrides.
   */
  static applicationSaveButton(localization: LocalizationData, props?: IButton): IButton {
    const defaultButtonProps: IButton = {
      label: LocalizationService.t<string>(localization, LocalizationPath.finishBtn),
      width: '100%',
      icon: CIconFactory.Icon({ icon: 'circle-check', placement: IconPlacement.Left }),
      classes: 'theme-blue-dark1-color row-start align-center g-10 btn-md',
      spinnerActive: false,
      disabled: false
    }
    return { ...defaultButtonProps, ...props }
  }

  /**
   * Vacancy Application save button waiting for response
   */
  static applicationSaveButtonLoading(localization: LocalizationData): IButton {
    return this.applicationSaveButton(localization, {
      spinnerActive: true,
      disabled: true
    })
  }

  /**
   * Preview page button to switch to Japanese
   */
  static viewApplicationStatus(props?: IButton): IButton {
    const defaultButtonProps: IButton = {
      icon: CIconFactory.Icon({
        icon: 'eye',
        placement: IconPlacement.Left,
        size: IconSize.large,
        classes: 'color-shades-white'
      })
    }
    return { ...defaultButtonProps, ...props }
  }

  /**
   * Preview page button to switch to Japanese
   */
  static previewLanguageSwitchBtn(props?: IButton): IButton {
    const defaultButtonProps: IButton = {
      icon: CIconFactory.Icon({
        icon: 'circle-check'
      }),
      width: '120px',
      height: '40px',
      ...props
    }
    return { ...defaultButtonProps, ...props }
  }

  /**
   * Preview page button to switch to Japanese
   */
  static previewJPLanguageSwitchBtn(props?: IButton): IButton {
    return this.previewLanguageSwitchBtn({
      label: '日本語',
      cyTag: 'jp-lang-btn-cy',
      ...props
    })
  }

  /**
   * Preview page button to switch to English
   */
  static previewENLanguageSwitchBtn(props?: IButton): IButton {
    return this.previewLanguageSwitchBtn({
      label: '英語',
      cyTag: 'en-lang-btn-cy',
      ...props
    })
  }

  /**
   * Link to open a job
   * @param router
   * @param props
   * @param viewJobLink
   * @param locale
   * @returns
   */
  static vacancyLinkButton(localization: LocalizationData, props?: IButton): IButton {
    const defaultButtonProps: IButton = {
      icon: CIconFactory.Icon({
        icon: 'briefcase',
        placement: IconPlacement.Left,
        size: IconSize.medium
      }),
      height: 'fit-content',
      label: LocalizationService.t<string>(localization, LocalizationPath.viewJobBtn),
      classes: 'row-center-fullwidth align-center g-10 theme-blue-dark1-color text-regular ph-10 pv-5 br-7'
    }
    return { ...defaultButtonProps, ...props }
  }

  static vacancyLinkButtonDisabled(localization: LocalizationData, props?: IButton): IButton {
    const label = LocalizationService.t<string>(localization, LocalizationPath.appliedJobBtn)
    const mixedProps: IButton = {
      classes: 'row-center-fullwidth align-center g-10 theme-shades-medium1-color text-regular ph-10 pv-5 br-7',
      label,
      ...props,
      disabled: true
    }
    return this.vacancyLinkButton(localization, mixedProps)
  }

  static backButton(
    localization: LocalizationData,
    language: LanguageURL,
    router: VueRouter,
    linkPath: string,
    props?: IButton
  ): IButton {
    const localizedLink = RouterLinkFactory.localizedLink(linkPath, language)
    const backLink = RouterLinkFactory.link({ router, link: localizedLink })
    const backBtnText = LocalizationService.t<string>(localization, LocalizationPath.CandidateBackToHomepage)
    const btn = ButtonFactory.button({
      icon: CIconFactory.Icon({ icon: 'arrow-left', placement: IconPlacement.Left }),
      link: backLink,
      label: backBtnText,
      classes: 'row-center align-center g-10 text-big color-blue-medium1',
      width: 'fit-content',
      ...props
    })
    return btn
  }

  static newButton(localization: LocalizationData, props?: IButton): IButton {
    const btn = ButtonFactory.button({
      label: LocalizationService.t<string>(localization, LocalizationPath.UIAddNew),
      icon: CIconFactory.Icon({ icon: 'circle-plus' }),
      classes: 'btn-lg theme-blue-dark1-color row-between g-10 align-center',
      cyTag: 'add-new-btn',
      ...props
    })
    return btn
  }
}
