
/**
 * Typed representation of language in full words.
 */
export enum LanguageOriginal {
  Japanese = '日本語',
  English = 'English',
  Vietnamese = 'Tiếng Việt',
  Chinese = '中文'
}
