import { EnumDictionary } from '../../../utils/types/enum-dictionary'
import { ApplicationAgentStatusId } from './application-agent-status-id.enum'

/**
 * Fontawesome Icon name for each application status.
 * An empty string implies that the theme will depend on the substatus.
 */
export const ApplicationStatusIcons: EnumDictionary<ApplicationAgentStatusId, string> = {
  [ApplicationAgentStatusId.NewApplication]: '', // New Application
  [ApplicationAgentStatusId.ApplicationReview]: '', // Reviewing
  [ApplicationAgentStatusId.Contacting]: '', // Contacting
  [ApplicationAgentStatusId.NotHiredBeforeInterview]: '', // Interviewing
  [ApplicationAgentStatusId.InterviewPreparation]: '', // Agent Declined
  [ApplicationAgentStatusId.InterviewRescheduling]: '', // Work Trial
  [ApplicationAgentStatusId.InterviewCompleted]: '', // Offer
  [ApplicationAgentStatusId.NotHiredAfterInterview]: '', // Candidate Declined
  [ApplicationAgentStatusId.Offer]: '', // Hired
  [ApplicationAgentStatusId.Employment]: '', // End of Employment
  [ApplicationAgentStatusId.CandidateWithdrew]: '', // Candidate Withdrew
  [ApplicationAgentStatusId.Memo]: '', // End of Employment
}
