import { defineNuxtPlugin } from '@nuxtjs/composition-api'
import { componentsVeeValidate, dateBetweenValidator, dateAfterValidator } from '@tokyojob/frontend-components'
import { appLocalization } from '@tokyojob/frontend-core'
import { extend, localize, setInteractionMode } from 'vee-validate'
import {
  alpha_spaces as alphaSpaces, confirmed,
  email, ext, image, max,
  min, min_value as minValue, regex,
  required,
  size
} from 'vee-validate/dist/rules'
import { validateAutocompleteSelection } from '~/modules/app/validators/autocomplete.select.validator'

export default defineNuxtPlugin((ctx: any) => {
  /**
   * Vee-validate rules imported in the project. Any time a new is required, it needs to be imported here first.
   * @link https://vee-validate.logaretm.com/v2/guide/rules.html
   */

  setInteractionMode('eager')
  // TODO: i18n
  extend('confirmed', {
    ...confirmed
  })
  extend('email', {
    ...email
  })
  extend('max', {
    ...max
  })
  extend('min', {
    ...min
  })
  extend('regex', {
    ...regex
  })
  extend('required', {
    ...required
  })
  extend('size', {
    ...size
  })
  extend('image', {
    ...image
  })
  extend('ext', {
    ...ext
  })
  extend('min_value', {
    ...minValue
  })
  extend('alpha_spaces', {
    ...alphaSpaces
  })
  extend('autocomplete_select', {
    validate: validateAutocompleteSelection
  })
  extend('date_between', {
    params: ['min', 'max'],
    validate: dateBetweenValidator
  })
  extend('date_after', {
    params: ['min'],
    validate: dateAfterValidator
  })

  componentsVeeValidate(ctx)

  // We extract the routeMeta from the context's route of the nuxt instance
  const route = ctx.route
  let routeMeta = undefined
  if (!route.meta) routeMeta = {}
  if (Array.isArray(route.meta)) routeMeta = route.meta[0]
  
  // We set the vee-validate's localization json
  localize(appLocalization)

  // If language if present in the route's meta, we're going to set that to vee-validate
  if (routeMeta && routeMeta.language) localize(routeMeta.language)
})
