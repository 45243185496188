import { IResume, UserType } from '@tokyojob/core'
import { BaseUsecaseImpl, LogType } from '@tokyojob/frontend-core'
import { th } from 'date-fns/locale'
import { IApp } from '~/types/nuxt'

/**
 * Sets a resume as finished. Any further changes must be done in the edit page.
 */
export class FinishResumeBuilderUsecase extends BaseUsecaseImpl {
  /**
   * @param app IApp instance used to access the Gateway and Log services.
   */
  constructor(private readonly app: IApp, userType: UserType) {
    super(app.state, userType)
  }

  async execute(resume: IResume): Promise<boolean> {
    const { baseGateway, logService } = this.app
    try {
      if (!resume.id) throw new Error('Resume ID is required.')
      await this.evaluateAuth()
      return baseGateway.resume.FinishResumeBuilder(resume.id, resume)
    } catch (error) {
      await logService.handle({ type: LogType.Error, error: error as Error })
      throw new Error('Failed to update file info.')
    }
  }
}
