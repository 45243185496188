/**
 * Event Pages for anonymous candidates.
 */
 export enum AnalyticsCandidateWebAnonymousPages {
  VacancyApplication = "VacancyApplication",
  VacancyApplicationStart = "VacancyApplication.Start",
  VacancyApplicationName = "VacancyApplication.Name",
  VacancyApplicationVisa = "VacancyApplication.Visa",
  VacancyApplicationContact = "VacancyApplication.Contact",
  VacancyApplicationPersonalInfo = "VacancyApplication.PersonalInfo",
  VacancyApplicationJapaneseSkills = "VacancyApplication.JapaneseSkills",
  VacancyApplicationConfirmation = "VacancyApplication.Confirmation",
  VacancyApplicationFinish = "VacancyApplication.Finish",
  VacancyDetails = "VacancyDetails"
}
