import { SpaceApp } from '../schema/namespace'
import { APIRequest, HTTPMethod } from '@tokyojob/frontend-core'
import { IVisaType, LanguageLocale } from '@tokyojob/core'

export class FetchVisaTypeAllRequest implements APIRequest<SpaceApp.VisaTypeFetchAllResponse> {
  response!: SpaceApp.VisaTypeFetchAllResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(locale: LanguageLocale = LanguageLocale.USen) {
    this.path = `/visa_type/all?locale=${locale}`
  }
}

export class FetchVisaTypePlainRequest implements APIRequest<IVisaType[]> {
  response!: IVisaType[]
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(locale: LanguageLocale = LanguageLocale.USen) {
    this.path = `/visa_type/plain?locale=${locale}`
  }
}
