import { Middleware } from '@nuxt/types'
import { Context } from '@nuxt/types/app'
import { APIClient, JWTHandler, LogType } from '@tokyojob/frontend-core'

const authenticatedOptional: Middleware = async ({ App, redirect }: Context) => {
  try {
    const apiClient = new APIClient(App.state)
    const url = (process.server ? process.env.serverBaseUrl : process.env.clientBaseUrl) || '/api'
    const jwtHandler = new JWTHandler(apiClient)
    await jwtHandler.evaluateAuth(App.state, redirect, undefined, false)
  } catch (error) {
    App.logService.handle({ type: LogType.Error, error: error as Error })
    redirect('/auth/login')
  }
}

export default authenticatedOptional
