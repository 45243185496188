import { ICandidate } from '../../../models';
import { CompositeSpecification } from '../../base/specification';

/**
 * Whether the provided candidate has a residence card (back) attachment or not.
 */
export class CandidateHasResidenceCardBackSpecification extends CompositeSpecification<ICandidate> {
  isSatisfiedBy(candidate: ICandidate): boolean {
    if (candidate.candidateVisa?.cardBackId) return true;
    if (candidate.candidateVisa?.cardBack?.id) return true;
    return false;
  }
}
