/**
 * Custom vee-validate rule for Japanese Mobile phone number
 */

import { ValidationRuleResult } from 'vee-validate/dist/types/types'
import { JapaneseMobileRegex } from '../../../../../core/src'

/**
 * Check if text input confirms to japanese mobile number regex
 */
export const mobileValidator = (
  value: any,
): boolean | string | Promise<boolean | string | ValidationRuleResult> => {
  return JapaneseMobileRegex.test(value)
}