import { IUser, LanguageLocale, UserLocaleChangeDto, UserPasswordChangeDto } from '@tokyojob/core'
import { APIRequest, HTTPMethod, QueryStringEntity } from '@tokyojob/frontend-core'
import { SpaceUser } from '../schema/namespace'

/**
 * HTTP Request to get specific user information by user id.
 */
export class GetRequest implements APIRequest<SpaceUser.GetResponse> {
  response!: SpaceUser.GetResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  constructor(id: string) {
    this.path = '/user/' + id
  }
}

/**
 * HTTP Request to get current user information.
 */
export class GetMeRequest implements APIRequest<SpaceUser.GetMeResponse> {
  response!: SpaceUser.GetMeResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor() {
    this.path = '/user/profile'
  }
}

/**
 * HTTP Request to get user list with paginate information.
 */
export class PaginateRequest implements APIRequest<SpaceUser.PaginateResponse> {
  response!: SpaceUser.PaginateResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(entity: QueryStringEntity) {
    this.path = '/user/' + entity.queries
  }
}

/**
 * HTTP Request to update the user's information.
 */
export class UpdateRequest implements APIRequest<SpaceUser.UpdateResponse> {
  response!: SpaceUser.UpdateResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.PUT
  params: SpaceUser.UpdateRequest
  constructor(seed: IUser, locale: LanguageLocale = LanguageLocale.USen) {
    this.params = seed
    /**
     * @todo Make this work
     */
    this.path = `/user?locale=${locale}`
  }
}

/**
 * HTTP Request to update the user's password.
 */
export class PasswordChangeRequest implements APIRequest<boolean> {
  response!: boolean
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: UserPasswordChangeDto
  constructor(dto: UserPasswordChangeDto) {
    this.params = dto
    this.path = '/user/password/change'
  }
}

export class DeleteRequest implements APIRequest<SpaceUser.DeleteResponse> {
  response!: SpaceUser.DeleteResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.DELETE
  constructor(id: string | number) {
    this.path = '/user/' + id
  }
}

export class UserUpdateLocale implements APIRequest<boolean> {
  response!: boolean
  path: string
  contentType = 'application/json'
  method = HTTPMethod.PUT
  params: UserLocaleChangeDto
  constructor(dto: UserLocaleChangeDto) {
    this.params = dto
    this.path = '/user/locale/change'
  }
}
