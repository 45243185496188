import { IResume, UserType } from '@tokyojob/core'
import { BaseUsecaseImpl, LogType } from '@tokyojob/frontend-core'
import { IApp } from '~/types/nuxt'

/**
 * Usecase for updating "File Info" of a resume.
 */
export class FileInfoUpdateUsecase extends BaseUsecaseImpl {
  /**
   * @param app IApp instance used to access the Gateway and Log services.
   */
  constructor(private readonly app: IApp, userType: UserType) {
    super(app.state, userType)
  }

  async execute(resumeId: number, resume: IResume): Promise<boolean> {
    const { baseGateway, logService } = this.app
    try {
      await this.evaluateAuth()
      return baseGateway.resume.UpdateFileInfo(resumeId, resume)
    } catch (error) {
      await logService.handle({ type: LogType.Error, error: error as Error })
      throw new Error('Failed to update file info.')
    }
  }
}
