import { IsString, IsNotEmpty } from 'class-validator';
import { AttachmentType } from '../enum/attachment-type';

/**
 * The DTO for temporary file creation
 */
export class TemporaryFileCreateDto {
  @IsString()
  name: string;

  @IsNotEmpty()
  attachmentType: AttachmentType;
}
