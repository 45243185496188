import Language from './language'
import LanguageCertification from './language.certification'

/**
 * Language certificate information.
 */
export default interface LanguageCertificateGroup {
  /**
   * Identifier of the language certificate group.
   */
  id: AvailableLanguageCertificateGroups

  /**
   * What language the certificate group is for.
   */
  language: Language

  /**
   * What language the certificate group is for, represented in a unique identifier.
   * @example "US-en", "JA-jp"
   */
  locale: string

  /**
   * Whether the certificate group is currently enabled.
   * @remarks When a certificate group is not enabled, certain actions may not be allowed in the API.
   */
  isVisible?: boolean

  /**
   * List of translations.
   */
  info: LanguageCertificateGroupi18n[]

  /**
   * List of certificates in the group.
   */
  certificates: LanguageCertification[]
}

/**
 * Translation of a Japanese Conversation item.
 */
export interface LanguageCertificateGroupi18n {
  /**
   * The translation of the item's name.
   * @example "Daily Conversation", "日常会話"
   */
  name: string

  /**
   * The description of what the item means.
   * @example "Can write high school level kanji.", "Can write middle school level kanji."
   */
  description: string

  /**
   * The locale of the translation.
   * @example "US-en", "JA-jp"
   */
  locale: string
}

/**
 * Required for specific business logic rules, includes a list of Certificate Group Ids that are considered constant in the application.
 * TODO: Guarantee that the Ids present here are saved as such in the Database through proper seeding.
 */
export enum AvailableLanguageCertificateGroups {
  JLPT = 1,
  TOEFL = 2
}
