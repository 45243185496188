import { EnumDictionary } from '../../../utils/types/enum-dictionary'
import { ApplicationAgentSubStatusId } from './application-agent-substatus-id.enum'

/**
 * CSS classes and styles usecd for each application SubStatus.
 * An empty string implies that the theme will depend on the parent status.
 * If both parent and child are empty, then the theme will be the default one.
 */
export const ApplicationTimelineSubStatusThemes: EnumDictionary<ApplicationAgentSubStatusId, string> = {
  [ApplicationAgentSubStatusId.NewApplicationWebGuest]: '',
  [ApplicationAgentSubStatusId.NewApplicationWebAccount]: '',
  [ApplicationAgentSubStatusId.NewApplicationIOS]: '',
  [ApplicationAgentSubStatusId.NewApplicationMessenger]: '',
  [ApplicationAgentSubStatusId.ReviewingInternalReview]: '',
  [ApplicationAgentSubStatusId.ReviewingOther]: '',
  [ApplicationAgentSubStatusId.ContactingAttemptingContact]: '',
  [ApplicationAgentSubStatusId.ContactingCouldNotGetInContactAfter3Attempts]: '',
  [ApplicationAgentSubStatusId.ContactingOther]: '',
  [ApplicationAgentSubStatusId.NotHiredBeforeInterviewVacancyFilled]: '',
  [ApplicationAgentSubStatusId.NotHiredBeforeInterviewCandidateWithdrew]: '',
  [ApplicationAgentSubStatusId.NotHiredBeforeInterviewInsufficientJapaneseLevel]: '',
  [ApplicationAgentSubStatusId.NotHiredBeforeInterviewInsufficientDocumentation]: '',
  [ApplicationAgentSubStatusId.NotHiredBeforeInterviewInvalidVisa]: '',
  [ApplicationAgentSubStatusId.NotHiredBeforeInterviewCouldNotGetInContact]: '',
  [ApplicationAgentSubStatusId.NotHiredBeforeInterviewCandidateMissedTheInterview]: '',
  [ApplicationAgentSubStatusId.NotHiredBeforeInterviewOther]: '',
  [ApplicationAgentSubStatusId.InterviewPreparationScheduling]: '',
  [ApplicationAgentSubStatusId.InterviewPreparationScheduled]: '',
  [ApplicationAgentSubStatusId.InterviewPreparationOther]: '',
  [ApplicationAgentSubStatusId.InterviewReschedulingRescheduling]: '',
  [ApplicationAgentSubStatusId.InterviewReschedulingCandidateMissedInterview]: '',
  [ApplicationAgentSubStatusId.InterviewReschedulingOther]: '',
  [ApplicationAgentSubStatusId.InterviewCompletedReviewingInterviewResults]: '',
  [ApplicationAgentSubStatusId.InterviewCompletedMovedToNextInterview]: '',
  [ApplicationAgentSubStatusId.InterviewCompletedJobOfferDecided]: '',
  [ApplicationAgentSubStatusId.InterviewCompletedOther]: '',
  [ApplicationAgentSubStatusId.NotHiredAfterInterviewVacancyFilled]: '',
  [ApplicationAgentSubStatusId.NotHiredAfterInterviewCandidateWithdrew]: '',
  [ApplicationAgentSubStatusId.NotHiredAfterInterviewInsufficientJapaneseLevel]: '',
  [ApplicationAgentSubStatusId.NotHiredAfterInterviewInsufficientDocuments]: '',
  [ApplicationAgentSubStatusId.NotHiredAfterInterviewInvalidVisa]: '',
  [ApplicationAgentSubStatusId.NotHiredAfterInterviewCouldNotGetInContact]: '',
  [ApplicationAgentSubStatusId.NotHiredAfterInterviewOther]: '',
  [ApplicationAgentSubStatusId.OfferContactingCandidate]: '',
  [ApplicationAgentSubStatusId.OfferPostOfferCandidateContact]: '',
  [ApplicationAgentSubStatusId.OfferEmploymentPaperworkPreparation]: '',
  [ApplicationAgentSubStatusId.OfferEmploymentPaperworkDone]: '',
  [ApplicationAgentSubStatusId.OfferOther]: '',
  [ApplicationAgentSubStatusId.EmploymentStarted]: '',
  [ApplicationAgentSubStatusId.EmploymentCandidateWithdrewAfterEmployment]: 'theme-red-tokyo-white',
  [ApplicationAgentSubStatusId.EmploymentFiredOrQuitAfterEmployment]: 'theme-red-tokyo-white',
  [ApplicationAgentSubStatusId.EmploymentOther]: '',
  [ApplicationAgentSubStatusId.CandidateWithdrewAnotherOffer]: '',
  [ApplicationAgentSubStatusId.CandidateWithdrewNotInterestedInThePositionAnymore]: '',
  [ApplicationAgentSubStatusId.CandidateWithdrewLocationTooFarAway]: '',
  [ApplicationAgentSubStatusId.CandidateWithdrewJobNotWhatWasExpected]: '',
  [ApplicationAgentSubStatusId.CandidateWithdrewOther]: '',
  [ApplicationAgentSubStatusId.Memo]: ''
}

export const ApplicationAGGridSubStatusThemes: EnumDictionary<ApplicationAgentSubStatusId, string> = {
  [ApplicationAgentSubStatusId.NewApplicationWebGuest]: '',
  [ApplicationAgentSubStatusId.NewApplicationWebAccount]: '',
  [ApplicationAgentSubStatusId.NewApplicationIOS]: '',
  [ApplicationAgentSubStatusId.NewApplicationMessenger]: '',
  [ApplicationAgentSubStatusId.ReviewingInternalReview]: '',
  [ApplicationAgentSubStatusId.ReviewingOther]: '',
  [ApplicationAgentSubStatusId.ContactingAttemptingContact]: '',
  [ApplicationAgentSubStatusId.ContactingCouldNotGetInContactAfter3Attempts]: '',
  [ApplicationAgentSubStatusId.ContactingOther]: '',
  [ApplicationAgentSubStatusId.NotHiredBeforeInterviewVacancyFilled]: '',
  [ApplicationAgentSubStatusId.NotHiredBeforeInterviewCandidateWithdrew]: '',
  [ApplicationAgentSubStatusId.NotHiredBeforeInterviewInsufficientJapaneseLevel]: '',
  [ApplicationAgentSubStatusId.NotHiredBeforeInterviewInsufficientDocumentation]: '',
  [ApplicationAgentSubStatusId.NotHiredBeforeInterviewInvalidVisa]: '',
  [ApplicationAgentSubStatusId.NotHiredBeforeInterviewCouldNotGetInContact]: '',
  [ApplicationAgentSubStatusId.NotHiredBeforeInterviewCandidateMissedTheInterview]: '',
  [ApplicationAgentSubStatusId.NotHiredBeforeInterviewOther]: '',
  [ApplicationAgentSubStatusId.InterviewPreparationScheduling]: '',
  [ApplicationAgentSubStatusId.InterviewPreparationScheduled]: '',
  [ApplicationAgentSubStatusId.InterviewPreparationOther]: '',
  [ApplicationAgentSubStatusId.InterviewReschedulingRescheduling]: '',
  [ApplicationAgentSubStatusId.InterviewReschedulingCandidateMissedInterview]: '',
  [ApplicationAgentSubStatusId.InterviewReschedulingOther]: '',
  [ApplicationAgentSubStatusId.InterviewCompletedReviewingInterviewResults]: '',
  [ApplicationAgentSubStatusId.InterviewCompletedMovedToNextInterview]: '',
  [ApplicationAgentSubStatusId.InterviewCompletedJobOfferDecided]: '',
  [ApplicationAgentSubStatusId.InterviewCompletedOther]: '',
  [ApplicationAgentSubStatusId.NotHiredAfterInterviewVacancyFilled]: '',
  [ApplicationAgentSubStatusId.NotHiredAfterInterviewCandidateWithdrew]: '',
  [ApplicationAgentSubStatusId.NotHiredAfterInterviewInsufficientJapaneseLevel]: '',
  [ApplicationAgentSubStatusId.NotHiredAfterInterviewInsufficientDocuments]: '',
  [ApplicationAgentSubStatusId.NotHiredAfterInterviewInvalidVisa]: '',
  [ApplicationAgentSubStatusId.NotHiredAfterInterviewCouldNotGetInContact]: '',
  [ApplicationAgentSubStatusId.NotHiredAfterInterviewOther]: '',
  [ApplicationAgentSubStatusId.OfferContactingCandidate]: '',
  [ApplicationAgentSubStatusId.OfferPostOfferCandidateContact]: '',
  [ApplicationAgentSubStatusId.OfferEmploymentPaperworkPreparation]: '',
  [ApplicationAgentSubStatusId.OfferEmploymentPaperworkDone]: '',
  [ApplicationAgentSubStatusId.OfferOther]: '',
  [ApplicationAgentSubStatusId.EmploymentStarted]: '',
  [ApplicationAgentSubStatusId.EmploymentCandidateWithdrewAfterEmployment]: 'theme-red-tokyo-white',
  [ApplicationAgentSubStatusId.EmploymentFiredOrQuitAfterEmployment]: 'theme-red-tokyo-white',
  [ApplicationAgentSubStatusId.EmploymentOther]: '',
  [ApplicationAgentSubStatusId.CandidateWithdrewAnotherOffer]: '',
  [ApplicationAgentSubStatusId.CandidateWithdrewNotInterestedInThePositionAnymore]: '',
  [ApplicationAgentSubStatusId.CandidateWithdrewLocationTooFarAway]: '',
  [ApplicationAgentSubStatusId.CandidateWithdrewJobNotWhatWasExpected]: '',
  [ApplicationAgentSubStatusId.CandidateWithdrewOther]: '',
  [ApplicationAgentSubStatusId.Memo]: ''
}


export const ApplicationSubStatusIconThemes: EnumDictionary<ApplicationAgentSubStatusId, string> = {
  [ApplicationAgentSubStatusId.NewApplicationWebGuest]: '',
  [ApplicationAgentSubStatusId.NewApplicationWebAccount]: '',
  [ApplicationAgentSubStatusId.NewApplicationIOS]: '',
  [ApplicationAgentSubStatusId.NewApplicationMessenger]: '',
  [ApplicationAgentSubStatusId.ReviewingInternalReview]: '',
  [ApplicationAgentSubStatusId.ReviewingOther]: '',
  [ApplicationAgentSubStatusId.ContactingAttemptingContact]: '',
  [ApplicationAgentSubStatusId.ContactingCouldNotGetInContactAfter3Attempts]: '',
  [ApplicationAgentSubStatusId.ContactingOther]: '',
  [ApplicationAgentSubStatusId.NotHiredBeforeInterviewVacancyFilled]: '',
  [ApplicationAgentSubStatusId.NotHiredBeforeInterviewCandidateWithdrew]: '',
  [ApplicationAgentSubStatusId.NotHiredBeforeInterviewInsufficientJapaneseLevel]: '',
  [ApplicationAgentSubStatusId.NotHiredBeforeInterviewInsufficientDocumentation]: '',
  [ApplicationAgentSubStatusId.NotHiredBeforeInterviewInvalidVisa]: '',
  [ApplicationAgentSubStatusId.NotHiredBeforeInterviewCouldNotGetInContact]: '',
  [ApplicationAgentSubStatusId.NotHiredBeforeInterviewCandidateMissedTheInterview]: '',
  [ApplicationAgentSubStatusId.NotHiredBeforeInterviewOther]: '',
  [ApplicationAgentSubStatusId.InterviewPreparationScheduling]: '',
  [ApplicationAgentSubStatusId.InterviewPreparationScheduled]: '',
  [ApplicationAgentSubStatusId.InterviewPreparationOther]: '',
  [ApplicationAgentSubStatusId.InterviewReschedulingRescheduling]: '',
  [ApplicationAgentSubStatusId.InterviewReschedulingCandidateMissedInterview]: '',
  [ApplicationAgentSubStatusId.InterviewReschedulingOther]: '',
  [ApplicationAgentSubStatusId.InterviewCompletedReviewingInterviewResults]: '',
  [ApplicationAgentSubStatusId.InterviewCompletedMovedToNextInterview]: '',
  [ApplicationAgentSubStatusId.InterviewCompletedJobOfferDecided]: '',
  [ApplicationAgentSubStatusId.InterviewCompletedOther]: '',
  [ApplicationAgentSubStatusId.NotHiredAfterInterviewVacancyFilled]: '',
  [ApplicationAgentSubStatusId.NotHiredAfterInterviewCandidateWithdrew]: '',
  [ApplicationAgentSubStatusId.NotHiredAfterInterviewInsufficientJapaneseLevel]: '',
  [ApplicationAgentSubStatusId.NotHiredAfterInterviewInsufficientDocuments]: '',
  [ApplicationAgentSubStatusId.NotHiredAfterInterviewInvalidVisa]: '',
  [ApplicationAgentSubStatusId.NotHiredAfterInterviewCouldNotGetInContact]: '',
  [ApplicationAgentSubStatusId.NotHiredAfterInterviewOther]: '',
  [ApplicationAgentSubStatusId.OfferContactingCandidate]: '',
  [ApplicationAgentSubStatusId.OfferPostOfferCandidateContact]: '',
  [ApplicationAgentSubStatusId.OfferEmploymentPaperworkPreparation]: '',
  [ApplicationAgentSubStatusId.OfferEmploymentPaperworkDone]: '',
  [ApplicationAgentSubStatusId.OfferOther]: '',
  [ApplicationAgentSubStatusId.EmploymentStarted]: '',
  [ApplicationAgentSubStatusId.EmploymentCandidateWithdrewAfterEmployment]: 'color-red-tokyo',
  [ApplicationAgentSubStatusId.EmploymentFiredOrQuitAfterEmployment]: 'color-red-tokyo',
  [ApplicationAgentSubStatusId.EmploymentOther]: '',
  [ApplicationAgentSubStatusId.CandidateWithdrewAnotherOffer]: '',
  [ApplicationAgentSubStatusId.CandidateWithdrewNotInterestedInThePositionAnymore]: '',
  [ApplicationAgentSubStatusId.CandidateWithdrewLocationTooFarAway]: '',
  [ApplicationAgentSubStatusId.CandidateWithdrewJobNotWhatWasExpected]: '',
  [ApplicationAgentSubStatusId.CandidateWithdrewOther]: '',
  [ApplicationAgentSubStatusId.Memo]: ''
}
