import { IconSize } from '../../c-icon'
import { FaIcon } from '../class/fa-icon'
import { IFaIcon } from '../interfaces/i-fa-icon'

const s3DefaultIconURL = 'https://tokyojob-public.s3.ap-northeast-1.amazonaws.com/icon_svgs/fas/circle-0.svg'
const s3BaseURL = 'https://tokyojob-public.s3.ap-northeast-1.amazonaws.com/icon_svgs'

export class FaIconFactory {
  new(props: IFaIcon): FaIcon {
    if (!props.size) props.size = IconSize.size16
    return {
      ...props
    }
  }

  getUrl(faIcon: string): string {
    try {
      const data = faIcon.split(' ')
      const category = data[0]
      const iconStartingIndex = data[1].indexOf('-')
      if (iconStartingIndex === -1) throw new Error('- character is not found')
      const icon = data[1].substring(iconStartingIndex + 1)
      if (icon.length === 0) throw new Error('icon is empty')
      return `${s3BaseURL}/${category}/${icon}.svg`
    } catch (e) {
      return s3DefaultIconURL
    }
  }
}
