
import { defineComponent, ref, watch, onMounted } from 'vue'
import { CIcon, FilterQueryManager } from '@tokyojob/frontend-components'
import { AppEnvironment, StaticURLBuilder } from '@tokyojob/frontend-core'
import CMobileSidebar from '../c-sidebar/mobile/c-mobile-sidebar.component.vue'
import { IApp } from '~/types/nuxt'
import { getProvidedApp, getRoute } from '~/base/utils/provided-getter'

const vacancyListURL = '/'

export default defineComponent({
  components: {
    CIcon,
    CMobileSidebar
  },
  setup() {
    const route = getRoute() // Use Vue Router's Composition API to access route
    const logo = ref('')
    const app: IApp = getProvidedApp()
    const filterQueryManager = new FilterQueryManager()
    const query = filterQueryManager.cookieToQueryString(app, '')
    const url = ref(`${vacancyListURL}${query}`)
    // Watches the $route object for changes
    watch(
      () => route.value.path,
      (newPath) => {
        updateLogoLink() // Update logo link based on the route
      }
    )

    const updateLogoLink = () => {
      url.value = `${vacancyListURL}${query}`
    }

    onMounted(() => {
      logo.value = StaticURLBuilder.logo(process.env.APP_ENV as AppEnvironment)
      updateLogoLink()
    })

    return {
      logo,
      url
    }
  }
})
