/**
 * A class used to format a string
 */
export class StringFormatter {
  
  /**
   * Formats all of the strings inside of the passed value using the passed replacement array
   * @param value 
   * @param replacements 
   * @returns 
   */
  format(value: any, replacements: string[]): string | string[] | object | object[] {
    if (typeof value === 'string') return this.formatString(value, replacements)
    else if (Array.isArray(value) || typeof value === 'object') {
      const strValue = JSON.stringify(value)
      const result = this.formatString(strValue, replacements)
      return JSON.parse(result)
    }
    // Just return the value as is if it's not a string, array, or object
    return value
  }

  /**
   * Formats the passed format string using the passed replacement array
   * Ex. My name is {0} and I love {1}., ['ChatGPT', 'Google'] -> My name is ChatGPT and I love Google.
   * @param value 
   * @param replacements 
   * @returns 
   */
  formatString(value: string, replacements: string[]): string {
    let result = value;
    for (let i = 0; i < replacements.length; i++) {
      var replaceKey = `{${i}}`
      // Put this back when we move to Node 16
      // The lib (ES2021) for this is currently not avaiable for below Node 15
      // result = result.replaceAll(replaceKey, replacements[i])
      
      // This replaces the replaceKey with replacements[i]
      result = result.split(replaceKey).join(replacements[i])
    }
    return result;
  }
}
