
import { defineComponent, onUnmounted, ref, watch } from 'vue'
import { CIconFactory, IconSize } from '../c-icon'
import CIcon from '../c-icon/c-icon.component.vue'

export default defineComponent({
  components: {
    CIcon
  },
  props: {
    initialShow: {
      type: Boolean,
      default: false
    },
    // The maximum duration the loader will be shown
    // If the loader is shown for longer than this duration, it will be hidden
    maxDuration: {
      type: Number,
      required: false,
      default: 15000 //15 seconds
    },
    label: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props) {
    const loadingIcon = CIconFactory.Spinner({ size: IconSize.size50 })
    const showLoader = ref(props.initialShow)
    const timeoutRef = ref<NodeJS.Timeout | null>(null)

    function hide(): void {
      showLoader.value = false
    }

    function show(): void {
      showLoader.value = true
      // Clear any existing timeout to ensure we don't hide prematurely
      if (timeoutRef.value) clearTimeout(timeoutRef.value)
      // Set a new timeout to hide the loader after the specified duration
      timeoutRef.value = setTimeout(() => {
        hide()
      }, props.maxDuration)
    }

    watch(
      () => props.initialShow,
      (newVal) => {
        if (newVal) {
          show()
        } else {
          hide()
        }
      }
    )

    // Cleanup on component unmount to prevent memory leaks
    onUnmounted(() => {
      if (timeoutRef.value) clearTimeout(timeoutRef.value)
    })

    return {
      loadingIcon,
      showLoader,
      show,
      hide
    }
  }
})
