import { JWTBroadcastRequestDto } from '../../../../../../../core/src/models/auth/dto/jwt-broadcast-request.dto'
import { JWTRefreshRequest } from '../../../../../../../core/src/models/auth/dto/jwt-refresh-request'
import { APIRequest } from '../../../../base/api/api.request'
import { HTTPMethod } from '../../../../base/api/types'
import { SpaceAuth } from '../namepsace'

const pathRoot = '/auth'

/**
 * HTTP Request to retrieve a new pair of JWTs.
 */
export class RefreshTokenPairRequest implements APIRequest<SpaceAuth.RefreshTokenPairResponse> {
  response!: SpaceAuth.RefreshTokenPairResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: SpaceAuth.RefreshTokenPairRequest
  constructor(token: JWTRefreshRequest) {
    this.params = token
    this.path = `${pathRoot}/refresh-token-pair`
  }
}

/**
 * HTTP Request to generate a broadcast that will send a new JWT to a messenger user.
 */
export class NewJWTBroadcastRequest implements APIRequest<SpaceAuth.NewJWTBroadcastResponse> {
  response!: SpaceAuth.NewJWTBroadcastResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: SpaceAuth.NewJWTBroadcastRequest
  constructor(requestData: JWTBroadcastRequestDto) {
    this.params = requestData
    this.path = `${pathRoot}/new-jwt-broadcast`
  }
}
