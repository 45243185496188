import { IsBoolean, IsOptional, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';
import { VacancySaveVisaTypesDto } from './vacancy-save-visa-types.dto';
import { VacancySaveBasicInformationDto } from './vacancy-save-basic-information.dto';
import { VacancySaveBenefitsDto } from './vacancy-save-benefits.dto';
import { VacancySaveEmploymentDto } from './vacancy-save-employment.dto';
import { VacancySaveJapaneseLanguageDto } from './vacancy-save-japanese-language.dto';
import { VacancySaveSkillsDto } from './vacancy-save-skills.dto';

/**
 * The DTO of the vacancy creation/update
 */
export class VacancySaveDto {
  /**
   * The DTO that validates the visa types section
   */
  @ValidateNested()
  @Type(() => VacancySaveVisaTypesDto)
  visaTypes: VacancySaveVisaTypesDto;

  /**
   * The DTO that validates the basic information section
   */
  @ValidateNested()
  @Type(() => VacancySaveBasicInformationDto)
  basicInformation: VacancySaveBasicInformationDto;

  /**
   * The DTO that validates the employment section
   */
  @ValidateNested()
  @Type(() => VacancySaveEmploymentDto)
  employment: VacancySaveEmploymentDto;

  /**
   * The DTO that validates the benefits section
   */
  @ValidateNested()
  @Type(() => VacancySaveBenefitsDto)
  benefits: VacancySaveBenefitsDto;

  /**
   * The DTO that validates the japanese langauge section
   */
  @ValidateNested()
  @Type(() => VacancySaveJapaneseLanguageDto)
  japaneseLanguage: VacancySaveJapaneseLanguageDto;

  /**
   * The DTO that validates the skills section
   */
  @ValidateNested()
  @Type(() => VacancySaveSkillsDto)
  skills: VacancySaveSkillsDto;

  @IsBoolean()
  published: boolean;

  /**
   * The custom id of the vacancy, provided by the user
   */
  @IsOptional()
  customId?: string;
}
