import { IsEnum, IsNumber, IsOptional, IsString } from 'class-validator';
import { ApplicationStatusTriggeredBy } from '../enum/application-status-triggered-by.enum';

export class ApplicationStatusAgentLogDto {
  @IsNumber()
  @IsOptional()
  id?: number;

  @IsNumber()
  @IsOptional()
  applicationId?: number;

  @IsOptional()
  @IsString()
  memo?: string;

  @IsEnum(ApplicationStatusTriggeredBy)
  triggeredBy: ApplicationStatusTriggeredBy;

  @IsNumber()
  applicationStatusAgentId?: number;

  @IsOptional()
  @IsNumber()
  applicationSubStatusAgentId?: number;
}
