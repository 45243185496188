import { EnumDictionary } from '../../../utils';
import { ApplicationStatus } from './application-status.enum';

/**
 * Names of the application statuses.
 */
export const ApplicationStatusNames: EnumDictionary<ApplicationStatus, string> = {
  [ApplicationStatus.New]: 'Applied',
  [ApplicationStatus.CandidateHired]: 'Hired',
  [ApplicationStatus.CandidateRejected]: 'Rejected',
  [ApplicationStatus.CancelledByCandidate]: 'Cancelled by candidate',
  [ApplicationStatus.CancelledByAgent]: 'Cancelled by agent',
};
