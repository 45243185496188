import { IApp } from '@/types/nuxt'
import { IResume, UserType } from '@tokyojob/core'
import { BaseUsecaseImpl, LogType, errors } from '@tokyojob/frontend-core'

/**
 * Usecase to obtain the candidate's resumes.
 */
export default class ListUsecase extends BaseUsecaseImpl {
  /**
   * @param app IApp instance used to access the Gateway and Log services.
   */
  constructor(private readonly app: IApp, userType: UserType) {
    super(app.state, userType)
  }

  /**
   * Gets the candidate's resumes.
   */
  async execute(): Promise<IResume[]> {
    const { baseGateway, logService } = this.app
    try {
      await this.evaluateAuth()
      return baseGateway.resume.GetResumes()
    } catch (error) {
      await logService.handle({ type: LogType.Error, error: error as Error })
      throw error
    }
  }
}
