import { v4 } from 'uuid';
export class StringUtils {
  static uuid(): string {
    return v4();
  }

  /**
   * This formats the passed string to a proper Japanese phone number.
   * @param phone
   * @returns
   */
  static stringToPhoneE164(phone: string): string {
    try {
      let formattedPhone = StringUtils.stringToPhone(phone, false);
      if (formattedPhone.charAt(0) === '0') formattedPhone = formattedPhone.slice(1, formattedPhone.length);
      return `+81${formattedPhone}`;
    } catch (e) {
      return '';
    }
  }

  /**
   * Makes a phone string (with hyphens) from the provided string.
   * @param phone
   * @returns
   */
  static stringToFormattedPhoneNumber(phone: string) {
    try {
      let formattedPhone = phone;
      if (formattedPhone.charAt(0) === '+') formattedPhone = formattedPhone.replace('+81', '0');
      formattedPhone = StringUtils.stringToPhone(formattedPhone, true);
      return `${formattedPhone.substring(0, 3)}-${formattedPhone.substring(3, 7)}-${formattedPhone.substring(7, 11)}`;
    } catch (e) {
      return '';
    }
  }

  /**
   * Makes a phone string (without hyphens or spaces) from the provided string.
   * @example "070-1234-1234" -> "07012341234".
   * @param phone
   * @param addMissingLeadingZero Whether a leading zero should be added. Ex: "70-1234-1234" -> "07012341234"
   * @returns
   */
  static stringToPhone(phone: string, addMissingLeadingZero: boolean = false): string {
    let formattedPhone = StringUtils.replaceAll(phone, [/[^0-9]/], '');
    const shouldAddLeadingZero = addMissingLeadingZero && formattedPhone.charAt(0) !== '0';
    if (shouldAddLeadingZero) formattedPhone = `0${formattedPhone}`;
    const lengthWithLeadingZero = 11;
    const lengthWithoutLeadingZero = 10;
    const lengthIsCorrect = formattedPhone.length === lengthWithLeadingZero || formattedPhone.length === lengthWithoutLeadingZero;
    if (!lengthIsCorrect) {
      throw new Error('Provided parameter is not a valid phone number');
    }
    return formattedPhone;
  }

  /**
   * Removes all extra space in the string. Any sequence of spaces is considered extra and will be shortened to just 1 empty space.
   * Also trims the extra spaces as well.
   * @remarks All Japanese full width empty space is removed in the process.
   * @param value
   * @returns
   */
  static removeEmptySpace(value: string): string {
    if (!value) return '';
    const trimmedString = StringUtils.replaceAll(value, [/[　]/, /  +/], ' ').trim();
    return trimmedString;
  }

  /**
   * Removes all of the specified characters. Uses a regex to remove everything.
   * @param value String to remove characters from.
   * @param characters Collection of characters or regexes to remove. All instances will be replaced.
   * @param replaceWith Characters to replace the strings with.
   * @returns
   */
  static replaceAll(value: string, characters: string[] | RegExp[], replaceWith: string): string {
    if (!value) return value;
    let trimmedString = value;
    for (let index = 0; index < characters.length; index++) {
      const element = characters[index];
      const regex = new RegExp(element, 'g');
      trimmedString = trimmedString.replace(regex, replaceWith);
    }
    return trimmedString;
  }

  /**
   * Removes all of the specified characters. Uses a regex to remove everything.
   * @param value String to remove characters from.
   * @param characters Collection of characters or regexes to remove. All instances will be replaced.
   * @param replaceWith Characters to replace the strings with.
   * @returns
   */
  static Capitalize(value: string): string {
    const uppercaseChar = value.charAt(0).toUpperCase();
    const lowercaseChars = value.toLocaleLowerCase().slice(1);
    return `${uppercaseChar}${lowercaseChars}`;
  }

  /**
   * Returns date number with appropriate suffix.
   */
  static DayOfMonth(value: number): string {
    const nd = [2, 22];
    const rd = [3, 23];
    const st = [1, 21, 31];
    if (nd.includes(value)) return value + 'nd';
    if (rd.includes(value)) return value + 'rd';
    if (st.includes(value)) return value + 'st';
    return value + 'th';
  }

  /**
   * Trims away the specified character from the string.
   * @param str
   * @param ch
   * @returns
   * @link https://stackoverflow.com/questions/26156292/trim-specific-character-from-a-string
   */
  static trimChar(str: string, ch: string) {
    let start = 0;
    let end = str.length;

    while (start < end && str[start] === ch) ++start;

    while (end > start && str[end - 1] === ch) --end;

    return start > 0 || end < str.length ? str.substring(start, end) : str;
  }

  /**
   * Trims away the specified characters from the string.
   * @param str
   * @param chars
   * @returns
   */
  static trimChars(str: string, chars: string[]) {
    for (let index = 0; index < chars.length; index++) {
      const element = chars[index];
      //str = StringUtils.trimChar(str, element);
      str.replaceAll(element, '');
    }
    return str;
  }

  /**
   * Email validation through regex.
   * @param email
   * @returns
   */
  static isEmail(email: string): boolean {
    const trimmedStr = StringUtils.removeEmptySpace(email);
    if (!trimmedStr) return false;
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return emailRegex.test(trimmedStr);
  }

  /**
   *
   * @param input
   * @returns
   */
  static toHalfWidth(input: string): string {
    // Mapping full-width characters to their half-width equivalents
    const fullWidthMap: { [key: string]: string } = {
      '：': ':', // Full-width colon to half-width colon
      '０': '0',
      '１': '1',
      '２': '2',
      '３': '3',
      '４': '4',
      '５': '5',
      '６': '6',
      '７': '7',
      '８': '8',
      '９': '9',
      // Add more mappings as needed
    };

    // Transforming the input string character by character
    let result = '';
    for (const char of input) {
      result += fullWidthMap[char] || char; // Replace with half-width equivalent if exists, else keep the original character
    }
    return result;
  }
}
