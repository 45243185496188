import { APIRequest, HTTPMethod } from "@tokyojob/frontend-core"
import { SpaceLocalization } from "../schema/namespace"

export class GetLocalizationRequest
  implements APIRequest<SpaceLocalization.IGetLocalizationResponse> {
  response!: SpaceLocalization.IGetLocalizationResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor() {
    this.path = 'i18n'
  }
}
