import { APIRequest } from '../../../base/api/api.request'
import { IJapaneseConversation } from '../../../../../../core/src/models/language/interface/i-japanese-conversation'
import { HTTPMethod } from '../../../base/api/types'
import { LanguageLocale } from '../../../../../../core/src/models/language/constants/language-locale.enum'

/**
 * Execute an API request to get a catalogue of Japanese skills.
 */
export class GetJapaneseSkillListRequest implements APIRequest<IJapaneseConversation[]> {
  response!: IJapaneseConversation[]
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(locale: LanguageLocale) {
    this.path = `/language/japanese?locale=${locale}`
  }
}
