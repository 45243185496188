export enum ApplicationAgentSubStatusId {
  NewApplicationWebGuest = 101,
  NewApplicationWebAccount = 102,
  NewApplicationIOS = 103,
  NewApplicationMessenger = 104,
  ReviewingInternalReview = 201,
  ReviewingOther = 202,
  ContactingAttemptingContact = 301,
  ContactingCouldNotGetInContactAfter3Attempts = 302,
  ContactingOther = 303,
  NotHiredBeforeInterviewVacancyFilled = 401,
  NotHiredBeforeInterviewCandidateWithdrew = 402,
  NotHiredBeforeInterviewInsufficientJapaneseLevel = 403,
  NotHiredBeforeInterviewInsufficientDocumentation = 404,
  NotHiredBeforeInterviewInvalidVisa = 405,
  NotHiredBeforeInterviewCouldNotGetInContact = 406,
  NotHiredBeforeInterviewCandidateMissedTheInterview = 407,
  NotHiredBeforeInterviewOther = 408,
  InterviewPreparationScheduling = 501,
  InterviewPreparationScheduled = 502,
  InterviewPreparationOther = 503,
  InterviewReschedulingRescheduling = 601,
  InterviewReschedulingCandidateMissedInterview = 602,
  InterviewReschedulingOther = 603,
  InterviewCompletedReviewingInterviewResults = 701,
  InterviewCompletedMovedToNextInterview = 702,
  InterviewCompletedJobOfferDecided = 703,
  InterviewCompletedOther = 704,
  NotHiredAfterInterviewVacancyFilled = 801,
  NotHiredAfterInterviewCandidateWithdrew = 802,
  NotHiredAfterInterviewInsufficientJapaneseLevel = 803,
  NotHiredAfterInterviewInsufficientDocuments = 804,
  NotHiredAfterInterviewInvalidVisa = 805,
  NotHiredAfterInterviewCouldNotGetInContact = 806,
  NotHiredAfterInterviewOther = 807,
  OfferContactingCandidate = 901,
  OfferPostOfferCandidateContact = 902,
  OfferEmploymentPaperworkPreparation = 903,
  OfferEmploymentPaperworkDone = 904,
  OfferOther = 905,
  EmploymentStarted = 1001,
  EmploymentCandidateWithdrewAfterEmployment = 1002,
  EmploymentFiredOrQuitAfterEmployment = 1003,
  EmploymentOther = 1004,
  CandidateWithdrewAnotherOffer = 1101,
  CandidateWithdrewNotInterestedInThePositionAnymore = 1102,
  CandidateWithdrewLocationTooFarAway = 1103,
  CandidateWithdrewJobNotWhatWasExpected = 1104,
  CandidateWithdrewOther = 1105,
  Memo = 9999999,
}