import { defineStore } from 'pinia'
import {
  IResume,
  IResumeEducation,
  IResumeJobHistory,
  IResumeQualification,
  ResumeComparer,
  ResumeEducationLevel,
  ResumeTranslationStatus
} from '@tokyojob/core'
import ListUsecase from '../usecases/list.usecase'
import CreateUsecase from '../usecases/create.usecase'
import EditUsecase from '../usecases/edit.usecase'
import { DeleteUsecase } from '../usecases/delete.usecase'
import { IApp } from '~/types/nuxt'
import GetUsecase from '../usecases/get.usecase'
import { FileInfoUpdateUsecase } from '../usecases/update-file-info.usecase'
import { AboutYouUpdateUsecase } from '../usecases/update-about-you.usecase'
import { ContactAndAddressUpdateUsecase } from '../usecases/update-contact-address.usecase'
import { VisaUpdateUsecase } from '../usecases/update-visa.usecase'
import { EducationUpdateUsecase } from '../usecases/update-education.usecase'
import { WorkExperienceUpdateUsecase } from '../usecases/update-work-experience.usecase'
import { QualificationUpdateUsecase } from '../usecases/update-qualification.usecase'
import { ReasonToApplyUpdateUsecase } from '../usecases/update-reason-to-apply.usecase'
import { PersonalRequestsUpdateUsecase } from '../usecases/update-personal-requests.usecase'
import { EducationDeleteUsecase } from '../usecases/delete-education.usecase'
import { QualificationDeleteUsecase } from '../usecases/delete-qualification.usecase'
import { WorkExperienceDeleteUsecase } from '../usecases/delete-work-experience.usecase'
import { AboutYouTranslationUpdateUsecase } from '../usecases/update-about-you-translation.usecase'
import { ContactAndAddressTranslationUpdateUsecase } from '../usecases/update-contact-address-translation.usecase'
import { EducationTranslationUpdateUsecase } from '../usecases/update-education-translation.usecase'
import { WorkExperienceTranslationUpdateUsecase } from '../usecases/update-work-experience-translation.usecase'
import { QualificationTranslationUpdateUsecase } from '../usecases/update-qualification-translation.usecase'
import { ReasonToApplyTranslationUpdateUsecase } from '../usecases/update-reason-to-apply-translation.usecase'
import { PersonalRequestsTranslationUpdateUsecase } from '../usecases/update-personal-requests-translation.usecase'
import { PictureUpdateUsecase } from '../usecases/update-picture.usecase'
import { PictureDeleteUsecase } from '../usecases/delete-picture.usecase'
import { FinishResumeBuilderUsecase } from '../usecases/finish-resume-builder.usecase'

// TODO: Handle JWT refresh
export const useResumeStore = defineStore('resume', {
  state: () => ({
    /**
     * List of resumes in order. This list contains only basic data used in the resume list.
     */
    resumeList: [] as IResume[],
    /**
     * Map for direct access, each resume here contains the full resume data.
     */
    resumeMap: {} as Record<number, IResume>
  }),
  getters: {
    allResumes: (state): IResume[] => state.resumeList,
    getResumeById: (state) => (id: number): IResume | undefined => state.resumeMap[id]
  },
  actions: {
    async fetchResumes(app: IApp, forceAPIFetch?: boolean): Promise<void> {
      if (this.resumeList.length && !forceAPIFetch) return
      try {
        const token = app.state.decodedToken
        const usecase = new ListUsecase(app, token!.role)
        const response: IResume[] = await usecase.execute()
        this.resumeList = response // Update the list
        // Note that this DOES NOT populate the map, as that one is only done when fetching by ID
      } catch (error) {
        console.error('Error fetching resumes:', error)
      }
    },
    async fetchResumeById(id: number, app: IApp, forceAPIFetch?: boolean): Promise<IResume | undefined> {
      let resume = this.getResumeById(id)
      if (!resume || forceAPIFetch) {
        try {
          const language = app.localizationSelector.languageLocale
          const token = app.state.decodedToken
          const usecase = new GetUsecase(app, token!.role)
          resume = await usecase.execute(id, language)
          this.resumeMap[id] = resume // Update the map
          // Update the list if the resume is already part of it
          const index = this.resumeList.findIndex((r) => r.id === id)
          if (index !== -1) this.resumeList[index] = resume
        } catch (error) {
          console.error(`Error fetching resume with ID ${id}:`, error)
        }
      }
      return resume
    },
    async createResume(resumeData: IResume, app: IApp): Promise<IResume> {
      const token = app.state.decodedToken
      const usecase = new CreateUsecase(app, token!.role)
      const response: IResume = await usecase.execute(resumeData)
      this.resumeList.push(response)
      if (response.id) this.resumeMap[response.id] = response
      return response
    },
    async deleteResume(resumeId: number, app: IApp): Promise<void> {
      try {
        const token = app.state.decodedToken
        const usecase = new DeleteUsecase(app, token!.role)
        const success: boolean = await usecase.execute(resumeId)
        if (success) {
          this.resumeList = this.resumeList.filter((resume) => resume.id !== resumeId)
          delete this.resumeMap[resumeId]
        }
      } catch (error) {
        console.error('Error deleting resume:', error)
      }
    },
    async deletePicture(resumeId: number, app: IApp): Promise<boolean> {
      try {
        const token = app.state.decodedToken
        const usecase = new PictureDeleteUsecase(app, token!.role)
        const response = await usecase.execute(resumeId)
        if (response) {
          const resume = this.getResumeById(resumeId)
          if (resume) {
            resume.picture = undefined
            this.updateResumeItemDataStore(resume)
          }
        }
        return response
      } catch (error) {
        console.error('Error deleting picture of resume:', error)
        return false
      }
    },
    async updateResume(resumeData: IResume, app: IApp): Promise<void> {
      try {
        const token = app.state.decodedToken
        const usecase = new EditUsecase(app, token!.role)
        const response = await usecase.execute(resumeData)
        if (response) this.updateResumeItemDataStore(resumeData)
      } catch (error) {
        console.error('Error updating resume:', error)
      }
    },
    /**
     * Update file info in the backend and store.
     * The store is not updated if the backend update fails.
     * @param resumeId
     * @param resumeData
     * @param app
     */
    async updateFileInfo(resumeId: number, resumeData: IResume, app: IApp): Promise<boolean> {
      try {
        // If the current store data is the same as the provided data, do not update the backend
        const currentResume = this.getResumeById(resumeId)
        if (currentResume && ResumeComparer.isFileInfoEqual(currentResume, resumeData)) return true
        const token = app.state.decodedToken
        const usecase = new FileInfoUpdateUsecase(app, token!.role)
        const response = await usecase.execute(resumeId, resumeData)
        if (response) this.updateResumeItemDataStore(resumeData)
        return true
      } catch (error) {
        // TODO: maybe just throw the error?
        console.error('Error updating file info of resume:', error)
        return false
      }
    },
    /**
     * Update about you in the backend and store.
     * The store is not updated if the backend update fails.
     * @param resumeId
     * @param resumeData
     * @param app
     */
    async updateAboutYou(resumeId: number, resumeData: IResume, app: IApp): Promise<boolean> {
      try {
        // If the current store data is the same as the provided data, do not update the backend
        const currentResume = this.getResumeById(resumeId)
        if (!currentResume) return false
        if (currentResume && ResumeComparer.isAboutYouEqual(currentResume, resumeData)) return true
        // if the translateable fields changed, set the translation status to pending.
        // if the furigana also changed, there is no need to retranslate.
        if (
          !ResumeComparer.isLastNameEqual(currentResume, resumeData) &&
          ResumeComparer.isLastNameFuriganaEqual(currentResume, resumeData)
        ) {
          resumeData.lastNameFuriganaStatus = ResumeTranslationStatus.Pending
        }
        if (
          !ResumeComparer.isFirstNameEqual(currentResume, resumeData) &&
          ResumeComparer.isFirstNameFuriganaEqual(currentResume, resumeData)
        ) {
          resumeData.firstNameFuriganaStatus = ResumeTranslationStatus.Pending
        }
        if (!ResumeComparer.isNativeLanguagesEqual(currentResume, resumeData))
          resumeData.nativeLanguagesJapaneseStatus = ResumeTranslationStatus.Pending
        if (!ResumeComparer.isJapaneseSkillLevelSummaryEqual(currentResume, resumeData))
          resumeData.japaneseSkillLevelSummaryJapaneseStatus = ResumeTranslationStatus.Pending

        const token = app.state.decodedToken
        const usecase = new AboutYouUpdateUsecase(app, token!.role)
        const response = await usecase.execute(resumeId, resumeData)
        if (response) this.updateResumeItemDataStore(resumeData)
        return true
      } catch (error) {
        // TODO: maybe just throw the error?
        console.error('Error updating about you section of resume:', error)
        return false
      }
    },
    async updateAboutYouTranslation(resumeId: number, resumeData: IResume, app: IApp): Promise<boolean> {
      try {
        // If the current store data is the same as the provided data, do not update the backend
        const currentResume = this.getResumeById(resumeId)
        if (!currentResume) return false
        if (currentResume && ResumeComparer.isAboutYouTranslationEqual(currentResume, resumeData)) return true
        // if the translateable fields changed, set the translation status to completed.
        if (!ResumeComparer.isLastNameFuriganaEqual(currentResume, resumeData))
          resumeData.lastNameFuriganaStatus = ResumeTranslationStatus.Completed
        if (!ResumeComparer.isFirstNameFuriganaEqual(currentResume, resumeData))
          resumeData.firstNameFuriganaStatus = ResumeTranslationStatus.Completed
        if (!ResumeComparer.isNativeLanguagesEqual(currentResume, resumeData))
          resumeData.nativeLanguagesJapaneseStatus = ResumeTranslationStatus.Completed
        if (!ResumeComparer.isJapaneseSkillLevelSummaryEqual(currentResume, resumeData))
          resumeData.japaneseSkillLevelSummaryJapaneseStatus = ResumeTranslationStatus.Completed
        const token = app.state.decodedToken
        const usecase = new AboutYouTranslationUpdateUsecase(app, token!.role)
        const response = await usecase.execute(resumeId, resumeData)
        if (response) this.updateResumeItemDataStore(resumeData)
        return true
      } catch (error) {
        console.error('Error updating about you translation of resume:', error)
        return false
      }
    },
    /**
     * Update contact and address in the backend and store.
     * The store is not updated if the backend update fails.
     * @param resumeId
     * @param resumeData
     * @param app
     */
    async updateContactAndAddress(resumeId: number, resumeData: IResume, app: IApp): Promise<boolean> {
      try {
        // If the current store data is the same as the provided data, do not update the backend
        const currentResume = this.getResumeById(resumeId)
        if (!currentResume) return false
        if (currentResume && ResumeComparer.isContactAndAddressEqual(currentResume, resumeData)) return true
        // if the translateable fields changed, set the translation status to pending.
        if (!ResumeComparer.isBuildingAndRoomNumberEqual(currentResume, resumeData)) {
          resumeData.buildingAndRoomNumberJapaneseStatus = ResumeTranslationStatus.Pending
          resumeData.buildingAndRoomNumberKanaStatus = ResumeTranslationStatus.Pending
        }
        // If the optional fields are now empty, set the translation status to completed
        if (!resumeData.buildingAndRoomNumber) {
          resumeData.buildingAndRoomNumberJapaneseStatus = ResumeTranslationStatus.Completed
          resumeData.buildingAndRoomNumberKanaStatus = ResumeTranslationStatus.Completed
        }
        const token = app.state.decodedToken
        const usecase = new ContactAndAddressUpdateUsecase(app, token!.role)
        const response = await usecase.execute(resumeId, resumeData)
        if (response) this.updateResumeItemDataStore(resumeData)
        return true
      } catch (error) {
        console.error('Error updating contact and address resume:', error)
        return false
      }
    },
    async updateContactAndAddressTranslation(resumeId: number, resumeData: IResume, app: IApp): Promise<boolean> {
      try {
        // If the current store data is the same as the provided data, do not update the backend
        const currentResume = this.getResumeById(resumeId)
        if (currentResume && ResumeComparer.isContactAndAddressTranslationEqual(currentResume, resumeData)) return true
        const token = app.state.decodedToken
        const usecase = new ContactAndAddressTranslationUpdateUsecase(app, token!.role)
        const response = await usecase.execute(resumeId, resumeData)
        if (response) this.updateResumeItemDataStore(resumeData)
        return true
      } catch (error) {
        console.error('Error updating contact and address translation of resume:', error)
        return false
      }
    },
    /**
     * Update visa in the backend and store.
     * The store is not updated if the backend update fails.
     */
    async updateVisa(resumeId: number, resumeData: IResume, app: IApp): Promise<boolean> {
      try {
        // If the current store data is the same as the provided data, do not update the backend
        const currentResume = this.getResumeById(resumeId)
        if (currentResume && ResumeComparer.isVisaEqual(currentResume, resumeData)) return true
        const token = app.state.decodedToken
        const usecase = new VisaUpdateUsecase(app, token!.role)
        const response = await usecase.execute(resumeId, resumeData)
        if (response) this.updateResumeItemDataStore(resumeData)
        return true
      } catch (error) {
        console.error('Error updating visa of resume:', error)
        return false
      }
    },
    /**
     * Update the education in the backend and store
     * Note: Deletion is a separate endpoint
     */
    async updateEducation(resumeId: number, resumeData: IResume, app: IApp): Promise<IResumeEducation[]> {
      try {
        // If the current store data is the same as the provided data, do not update the backend
        const currentResume = this.getResumeById(resumeId)
        if (currentResume && ResumeComparer.areEducationsEqual(currentResume, resumeData)) return resumeData.education || []
        // If non-high school education items have their specialization changed, set the translation status to pending
        for (const e of resumeData.education || []) {
          if (e.educationLevel === ResumeEducationLevel.HighSchool) continue
          const currentEducation = currentResume?.education?.find((ce) => ce.id === e.id)
          if (!currentEducation) continue
          if (!ResumeComparer.isSpecialtyEqual(e, currentEducation)) e.specialtyJapaneseStatus = ResumeTranslationStatus.Pending
        }
        const token = app.state.decodedToken
        const usecase = new EducationUpdateUsecase(app, token!.role)
        const response = await usecase.execute(resumeId, resumeData)
        if (response) {
          resumeData.education = response
          this.updateResumeItemDataStore(resumeData)
        }
        return response
      } catch (error) {
        console.error('Error updating educations of resume:', error)
        return resumeData.education || []
      }
    },
    async updateEducationTranslation(resumeId: number, resumeData: IResume, app: IApp): Promise<IResumeEducation[]> {
      try {
        // If the current store data is the same as the provided data, do not update the backend
        const currentResume = this.getResumeById(resumeId)
        if (currentResume && ResumeComparer.areEducationsTranslationEqual(currentResume, resumeData))
          return resumeData.education || []
        const token = app.state.decodedToken
        const usecase = new EducationTranslationUpdateUsecase(app, token!.role)
        const response = await usecase.execute(resumeId, resumeData)
        if (response) {
          resumeData.education = response
          this.updateResumeItemDataStore(resumeData)
        }
        return response
      } catch (error) {
        console.error('Error updating educations translation of resume:', error)
        return resumeData.education || []
      }
    },
    async deleteEducation(resumeId: number, id: number, app: IApp): Promise<boolean> {
      try {
        const currentResume = this.getResumeById(resumeId)
        if (!currentResume) return false
        const token = app.state.decodedToken
        const usecase = new EducationDeleteUsecase(app, token!.role)
        const response = await usecase.execute(resumeId, [id])
        if (response) {
          const index = currentResume.education?.findIndex((e) => e.id === id)!
          if (index !== -1) {
            currentResume.education?.splice(index, 1) // Remove the old item and add the new one at the same index
          }
        }
        return response
      } catch (error) {
        console.error('Error updating educations of resume:', error)
        return false
      }
    },
    /**
     * Update the work experience in the backend and store
     * Note: Deletion is a separate endpoint
     */
    async updateWorkExperience(resumeId: number, resumeData: IResume, app: IApp): Promise<IResumeJobHistory[]> {
      try {
        // If the current store data is the same as the provided data, do not update the backend
        const currentResume = this.getResumeById(resumeId)
        if (currentResume && ResumeComparer.areWorkExperiencesEqual(currentResume, resumeData))
          return resumeData.workExperience || []
        // If the job item description changed, set the translation status to pending
        for (const e of resumeData.workExperience || []) {
          const currentWorkExperience = currentResume?.workExperience?.find((ce) => ce.id === e.id)
          if (!currentWorkExperience) continue
          if (e.jobDescription !== currentWorkExperience.jobDescription)
            e.jobDescriptionJapaneseStatus = ResumeTranslationStatus.Pending
        }
        const token = app.state.decodedToken
        const usecase = new WorkExperienceUpdateUsecase(app, token!.role)
        const response = await usecase.execute(resumeId, resumeData)
        if (response) {
          resumeData.workExperience = response
          this.updateResumeItemDataStore(resumeData)
        }
        return response
      } catch (error) {
        console.error('Error updating work experiences of resume:', error)
        return resumeData.workExperience || []
      }
    },
    async updateWorkExperienceTranslation(resumeId: number, resumeData: IResume, app: IApp): Promise<IResumeJobHistory[]> {
      try {
        // If the current store data is the same as the provided data, do not update the backend
        const currentResume = this.getResumeById(resumeId)
        if (currentResume && ResumeComparer.areWorkExperiencesTranslationEqual(currentResume, resumeData))
          return resumeData.workExperience || []
        const token = app.state.decodedToken
        const usecase = new WorkExperienceTranslationUpdateUsecase(app, token!.role)
        const response = await usecase.execute(resumeId, resumeData)
        if (response) {
          resumeData.workExperience = response
          this.updateResumeItemDataStore(resumeData)
        }
        return response
      } catch (error) {
        console.error('Error updating work experiences translation of resume:', error)
        return resumeData.workExperience || []
      }
    },
    async deleteWorkExperience(resumeId: number, id: number, app: IApp): Promise<boolean> {
      try {
        const currentResume = this.getResumeById(resumeId)
        if (!currentResume) return false
        const token = app.state.decodedToken
        const usecase = new WorkExperienceDeleteUsecase(app, token!.role)
        const response = await usecase.execute(resumeId, [id])
        if (response) {
          const index = currentResume.workExperience?.findIndex((e) => e.id === id)!
          if (index !== -1) currentResume.workExperience?.splice(index, 1) // Remove the old item and add the new one at the same index
        }
        return response
      } catch (error) {
        console.error('Error updating work experiences of resume:', error)
        return false
      }
    },
    /**
     * Update the qualification in the backend and store
     * Note: Deletion is a separate endpoint
     */
    async updateQualification(resumeId: number, resumeData: IResume, app: IApp): Promise<IResumeQualification[]> {
      try {
        // If the current store data is the same as the provided data, do not update the backend
        const currentResume = this.getResumeById(resumeId)
        if (currentResume && ResumeComparer.areQualificationsEqual(currentResume, resumeData))
          return resumeData.qualifications || []
        // If the qualification description changed, set the translation status to pending
        for (const e of resumeData.qualifications || []) {
          const currentWorkExperience = currentResume?.qualifications?.find((ce) => ce.id === e.id)
          if (!currentWorkExperience) continue
          if (e.description !== currentWorkExperience.description) e.descriptionJapaneseStatus = ResumeTranslationStatus.Pending
        }
        const token = app.state.decodedToken
        const usecase = new QualificationUpdateUsecase(app, token!.role)
        const response = await usecase.execute(resumeId, resumeData)
        if (response) {
          resumeData.qualifications = response
          this.updateResumeItemDataStore(resumeData)
        }
        return response
      } catch (error) {
        console.error('Error updating qualifications of resume:', error)
        return resumeData.education || []
      }
    },
    async updateQualificationTranslation(resumeId: number, resumeData: IResume, app: IApp): Promise<IResumeQualification[]> {
      try {
        // If the current store data is the same as the provided data, do not update the backend
        const currentResume = this.getResumeById(resumeId)
        if (currentResume && ResumeComparer.areQualificationsTranslationEqual(currentResume, resumeData))
          return resumeData.qualifications || []
        const token = app.state.decodedToken
        const usecase = new QualificationTranslationUpdateUsecase(app, token!.role)
        const response = await usecase.execute(resumeId, resumeData)
        if (response) {
          resumeData.qualifications = response
          this.updateResumeItemDataStore(resumeData)
        }
        return response
      } catch (error) {
        console.error('Error updating qualifications translation of resume:', error)
        return resumeData.education || []
      }
    },
    async deleteQualification(resumeId: number, id: number, app: IApp): Promise<boolean> {
      try {
        const currentResume = this.getResumeById(resumeId)
        if (!currentResume) return false
        const token = app.state.decodedToken
        const usecase = new QualificationDeleteUsecase(app, token!.role)
        const response = await usecase.execute(resumeId, [id])
        if (response) {
          const index = currentResume.qualifications?.findIndex((e) => e.id === id)!
          if (index !== -1) currentResume.qualifications?.splice(index, 1) // Remove the old item and add the new one at the same index
        }
        return response
      } catch (error) {
        console.error('Error updating qualifications of resume:', error)
        return false
      }
    },
    /**
     * Update the reason to apply in the backend and store
     * Note: Deletion is a separate endpoint
     */
    async updateReasonToApply(resumeId: number, resumeData: IResume, app: IApp): Promise<boolean> {
      try {
        // If the resumeData.reasonsForApplying is undefined we'll set it to empty string to trigger the translation complete in the backend
        if (!resumeData.reasonsForApplying) {
          resumeData.reasonsForApplying = ''
        }
        // If the current store data is the same as the provided data, do not update the backend
        const currentResume = this.getResumeById(resumeId)
        if (!currentResume) return false
        if (currentResume && ResumeComparer.areReasonToApplyEqual(currentResume, resumeData)) return true
        // If the reason to apply changed, set the translation status to pending
        if (!ResumeComparer.areReasonToApplyEqual(currentResume, resumeData))
          resumeData.reasonsForApplyingJapaneseStatus = ResumeTranslationStatus.Pending
        const token = app.state.decodedToken
        const usecase = new ReasonToApplyUpdateUsecase(app, token!.role)
        const response = await usecase.execute(resumeId, resumeData)
        if (response) this.updateResumeItemDataStore(resumeData)
        return true
      } catch (error) {
        console.error('Error updating reason to apply of resume:', error)
        return false
      }
    },
    async updateReasonToApplyTranslation(resumeId: number, resumeData: IResume, app: IApp): Promise<boolean> {
      try {
        // If the current store data is the same as the provided data, do not update the backend
        const currentResume = this.getResumeById(resumeId)
        if (currentResume && ResumeComparer.areReasonToApplyTranslationEqual(currentResume, resumeData)) return true
        const token = app.state.decodedToken
        const usecase = new ReasonToApplyTranslationUpdateUsecase(app, token!.role)
        const response = await usecase.execute(resumeId, resumeData)
        if (response) this.updateResumeItemDataStore(resumeData)
        return true
      } catch (error) {
        console.error('Error updating reason to apply translation of resume:', error)
        return false
      }
    },
    /**
     * Update the reason to apply in the backend and store
     * Note: Deletion is a separate endpoint
     */
    async updatePersonalRequests(resumeId: number, resumeData: IResume, app: IApp): Promise<boolean> {
      try {
         // If the resumeData.personalRequests is undefined we'll set it to empty string to trigger the translation complete in the backend
         if (!resumeData.personalRequests) {
          resumeData.personalRequests = ''
        }
        // If the current store data is the same as the provided data, do not update the backend
        const currentResume = this.getResumeById(resumeId)
        if (!currentResume) return false
        if (currentResume && ResumeComparer.arePersonalRequestsEqual(currentResume, resumeData)) return true
        // If the personal requests changed, set the translation status to pending
        if (!ResumeComparer.arePersonalRequestsEqual(currentResume, resumeData))
          resumeData.personalRequestsJapaneseStatus = ResumeTranslationStatus.Pending
        const token = app.state.decodedToken
        const usecase = new PersonalRequestsUpdateUsecase(app, token!.role)
        const response = await usecase.execute(resumeId, resumeData)
        if (response) this.updateResumeItemDataStore(resumeData)
        return true
      } catch (error) {
        console.error('Error updating personal requests of resume:', error)
        return false
      }
    },
    async updatePersonalRequestsTranslation(resumeId: number, resumeData: IResume, app: IApp): Promise<boolean> {
      try {
        // If the current store data is the same as the provided data, do not update the backend
        const currentResume = this.getResumeById(resumeId)
        if (currentResume && ResumeComparer.arePersonalRequestsTranslationEqual(currentResume, resumeData)) return true
        const token = app.state.decodedToken
        const usecase = new PersonalRequestsTranslationUpdateUsecase(app, token!.role)
        const response = await usecase.execute(resumeId, resumeData)
        if (response) this.updateResumeItemDataStore(resumeData)
        return true
      } catch (error) {
        console.error('Error updating personal requests translation of resume:', error)
        return false
      }
    },
    async updatePicture(
      resumeId: number,
      resumeData: IResume,
      attachmentFile: File,
      editedFile: File,
      app: IApp
    ): Promise<boolean> {
      try {
        if (!attachmentFile || !editedFile) return false
        // if we have no file to upload, we skip. Deletion is done automatically by clicking the "Delete" button in the UI,,
        // no need to check it here
        if (!resumeData.picture) throw new Error('Picture data is missing')
        const token = app.state.decodedToken
        const usecase = new PictureUpdateUsecase(app, token!.role)
        const response = await usecase.execute(resumeId, resumeData, attachmentFile, editedFile)
        resumeData.picture = { ...resumeData.picture, id: response?.id }
        if (response) this.updateResumeItemDataStore(resumeData)
        return true
      } catch (error) {
        console.error('Error updating picture of resume:', error)
        return false
      }
    },
    /**
     * Update resume data in the store.
     * @param resumeData
     */
    updateResumeItemDataStore(resumeData: IResume): void {
      const index = this.resumeList.findIndex((resume) => resume.id === resumeData.id)
      if (index !== -1) this.resumeList[index] = resumeData
      if (resumeData.id) this.resumeMap[resumeData.id] = resumeData
    },
    async finishResumeBuilder(updatedResume: IResume, app: IApp): Promise<void> {
      try {
        if (!updatedResume.id) throw new Error('Resume ID is missing')
        const token = app.state.decodedToken
        const usecase = new FinishResumeBuilderUsecase(app, token!.role)
        const res = await usecase.execute(updatedResume)
        if (res) {
          const resume = this.getResumeById(updatedResume.id)
          if (resume) {
            resume.builderCompleted = true
            this.updateResumeItemDataStore(resume)
          }
        }
      } catch (error) {
        console.error('Error finishing resume builder:', error)
      }
    }
  }
})
