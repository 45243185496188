import { VacancyBenefit } from '../network/schema/models'
import BenefitEntity, {
  BenefitEntityFactory,
  IBenefitProps
} from '~/modules/app/entities/benefit'
import BenefitUnitEntity, {
  BenefitUnitEntityFactory
} from '~/modules/app/entities/benefit.unit'
import { LanguageLocale } from '@tokyojob/core'

export interface IVacancyBenefitProps extends VacancyBenefit {}

/**
 * Represents 1 selected benefit in a vacancy.
 */
export default class VacancyBenefitEntity {
  private _props: IVacancyBenefitProps
  /**
   * List of children of this vacancy benefit.
   * @remarks Had to include this because otherwise, building the Benefits UI became
   * a very complex task. This eased up the reactivity issues.
   */
  childrenCache: VacancyBenefitEntity[]

  constructor(props: IVacancyBenefitProps) {
    this._props = props
    this.childrenCache = []
    if (props.children) {
      this.childrenCache = props.children?.map((e) =>
        VacancyBenefitEntityFactory(e)
      )
    }
  }

  get props(): IVacancyBenefitProps {
    return this._props
  }

  get id(): number | undefined {
    return this.props.id
  }

  /**
   * The benefit that owns the benefit selection.
   */
  get benefit(): BenefitEntity {
    return BenefitEntityFactory(this.props.benefit)
  }

  set benefit(value: BenefitEntity) {
    this.props.benefit = value.props
  }

  /**
   * The unique identifier of the parent benefit.
   */
  get benefitId(): number | undefined {
    return this.props.benefitId
  }

  set benefitId(value: number | undefined) {
    this.props.benefitId = value
  }

  /**
   * The benefit unit used in this benefit, if any.
   */
  get benefitUnit(): BenefitUnitEntity {
    return BenefitUnitEntityFactory(this.props.benefitUnit)
  }

  set benefitUnit(value: BenefitUnitEntity) {
    this.props.benefitUnit = value.props
  }

  /**
   * The unique identifier of the benefit unit that was selected, if any.
   */
  get benefitUnitId(): number | undefined {
    return this.props.benefitUnitId
  }

  set benefitUnitId(value: number | undefined) {
    this.props.benefitUnitId = value
  }

  /**
   * The amount of money, if any, that was selected for this benefit.
   */
  get amount(): number | undefined {
    // Because the API returns this as a decimal, JS is treating it as a string. "120.0000"
    if (!this.props.amount) return
    const parsed = parseFloat(this.props.amount.toString())
    return parsed
  }

  set amount(value: number | undefined) {
    this.props.amount = value
  }

  /**
   * Formats the number in the user's locale style.
   * @example "1,500,000"
   */
  get formattedAmount(): string | undefined {
    return this.amount?.toLocaleString()
  }

  /**
   * Vacancy benefit translation information.
   */
  get description(): string | undefined {
    if (this.props.i18n && this.props.i18n.length > 0) {
      return this.props.i18n[0].description
    }
  }

  set description(value: string | undefined) {
    // TODO: multiple languages
    if (!this.props.i18n || this.props.i18n.length === 0) {
      this.props.i18n = [{ description: '', locale: LanguageLocale.JAjp }]
    }
    this.props.i18n[0].description = value ?? ''
  }

  /**
   * Gets the benefits's children, in VacancyBenefit form.
   */
  get children(): VacancyBenefitEntity[] {
    return this.childrenCache
  }

  set vacancyBenefits(vacancyBenefits: VacancyBenefitEntity[]) {
    this.childrenCache = vacancyBenefits
  }

  get checked(): boolean {
    if (this.props.checked === undefined) return false
    return this.props.checked
  }

  set checked(value: boolean) {
    this.props.checked = value
  }
}

export const EmptyVacancyBenefitPropsFactory = (
  props?: Partial<IVacancyBenefitProps>
): IVacancyBenefitProps => ({
  id: 0,
  benefit: {
    id: ''
  },
  benefitId: 0,
  ...props
})

export const VacancyBenefitPropsFactory = (
  props?: Partial<IVacancyBenefitProps>
): IVacancyBenefitProps => ({
  id: 0,
  benefit: {
    id: ''
  },
  benefitId: 0,
  ...props
})

export const EmptyVacancyBenefitEntityFactory = (
  props?: Partial<IVacancyBenefitProps>
): VacancyBenefitEntity => {
  return new VacancyBenefitEntity(EmptyVacancyBenefitPropsFactory(props))
}

export const VacancyBenefitEntityFactory = (
  props?: Partial<IVacancyBenefitProps>
): VacancyBenefitEntity => {
  return new VacancyBenefitEntity(VacancyBenefitPropsFactory(props))
}

/**
 * Creates a vacancy benefit props object from a benefit object.
 * @param props
 * @returns
 */
export const BenefitToVacancyBenefitPropsFactory = (
  props?: Partial<IBenefitProps>
): IVacancyBenefitProps => {
  let benefitId: number = 0
  if (props?.id) {
    benefitId = parseInt(props.id)
  }
  return {
    id: 0,
    benefitId,
    benefit: {
      id: '',
      ...props
    }
  }
}

/**
 * Creates a vacancy benefit entity from a benefit object.
 * @param props
 * @returns
 */
export const BenefitToVacancyBenefitEntityFactory = (
  props?: Partial<IBenefitProps>
): VacancyBenefitEntity => {
  return new VacancyBenefitEntity(BenefitToVacancyBenefitPropsFactory(props))
}
