import { defineStore } from "pinia";
import { IFilterableDataSetDictionary } from "../../../../../core/src/models/common/filter/i-filterable-data-set-dictionary";
import { FilterKey } from "../../../../../core/src/models/vacancy/enum/filter-key.enum";
import { IVacancyFilterTreeData } from "../components/c-filter/interface/i-vacancy-filter-tree-data";

interface State {
  dict: IVacancyFilterTreeData
}

export const getVacancyTreeFilterData = defineStore('vacancy-tree-filter-data', {
  state: (): State => ({
    dict: {}
  }),
  actions: {
    save(filterKey: FilterKey, treeData: IFilterableDataSetDictionary): void {
      this.dict[filterKey] = treeData
    },
    get(filterKey: FilterKey): IFilterableDataSetDictionary {
      return this.dict[filterKey]
    },
    all(): IVacancyFilterTreeData {
      return this.dict
    }
  }
})
