import { Category } from '../network/schema/models'
import { CategoryInfo } from '../network/schema/models/category'

export interface ICategoryProps extends Category {}

export default class CategoryEntity {
  private _props: ICategoryProps
  public isSelected: boolean
  constructor(props: ICategoryProps) {
    this._props = props
    this.isSelected = false
  }

  get props(): ICategoryProps {
    return this._props
  }

  get id(): number {
    return this.props.id
  }

  get informations(): CategoryInfo[] {
    return this.props.info
  }

  /**
   * Gets the name of the category in the current locale.
   */
  get name(): string {
    return this.props.info[0].name
  }

  /**
   * Gets a short version of the name of the category in the current locale.
   * When the category's name is longer than 30 characters, it is truncated
   * and an ellipsis is added.
   */
  get nameShort(): string {
    let name = this.name
    if (name.length > 30) {
      name = `${name.substr(0, 30)}...`
    }
    return name
  }

  /**
   * Gets the category's child categories.
   */
  get children(): CategoryEntity[] {
    return this.props.children.map((e) => CategoryEntityFactory(e))
  }

  /**
   * Updates the category's children.
   */
  set children(items: CategoryEntity[]) {
    this.props.children.push(...items.map((e) => e.props))
  }
}

export const EmptyCategoryPropsFactory = (
  props?: Partial<ICategoryProps>
): ICategoryProps => {
  if (!props?.info) {
    // Some endpoints are returning 'info' and some 'informations'
    // TODO: Fix this issue by setting for a standard way of naming translation
    // information properties.
    props!.info = (props as any).informations
  }
  return {
    id: 0,
    parentId: 0,
    code: '',
    slug: '',
    info: [],
    children: [],
    ...props
  }
}

export const CategoryPropsFactory = (
  props?: Partial<ICategoryProps>
): ICategoryProps => {
  if (!props?.info) {
    // Some endpoints are returning 'info' and some 'informations'
    // TODO: Fix this issue by setting for a standard way of naming translation
    // information properties.
    props!.info = (props as any).informations
  }
  return {
    id: 0,
    parentId: 0,
    code: '',
    slug: '',
    info: [],
    children: [],
    ...props
  }
}

export const EmptyCategoryEntityFactory = (
  props?: Partial<ICategoryProps>
): CategoryEntity => {
  return new CategoryEntity(EmptyCategoryPropsFactory(props))
}

export const CategoryEntityFactory = (
  props?: Partial<ICategoryProps>
): CategoryEntity => {
  return new CategoryEntity(CategoryPropsFactory(props))
}
