import { LanguageLocale } from '@tokyojob/core'
import { APIRequest, HTTPMethod } from '@tokyojob/frontend-core'
import { SpaceVisaTypeHierarchy } from '../schema/namespace'

export class FetchVisaTypeHierarchyRequest
  implements APIRequest<SpaceVisaTypeHierarchy.VisaTypeHierarchyFetchResponse> {
  response!: SpaceVisaTypeHierarchy.VisaTypeHierarchyFetchResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(locale: LanguageLocale) {
    this.path = `/visa-type-category/hierarchy?locale=${locale}`
  }
}
