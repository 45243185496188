import { APIRequest, HTTPMethod, QueryStringEntity } from '@tokyojob/frontend-core'
import { SpaceApp } from '../schema/namespace'

export class FetchCategoryRequest
  implements APIRequest<SpaceApp.CategoryFetchResponse> {
  response!: SpaceApp.CategoryFetchResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(entity?: QueryStringEntity) {
    const queries = entity ? entity.queries : ''
    this.path = '/category/' + queries
  }
}

/**
 * Gets the top categories in the hierarchy.
 */
export class FetchParentCategoriesRequest
  implements APIRequest<SpaceApp.CategoryFetchResponse> {
  response!: SpaceApp.CategoryFetchResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor() {
    this.path = '/category/hierarchy/top'
  }
}

/**
 * Gets the children of the specified category.
 */
export class FetchCategoryChildrensRequest
  implements APIRequest<SpaceApp.SingleCategoryFetchResponse> {
  response!: SpaceApp.SingleCategoryFetchResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(parentId: number) {
    this.path = `/category/hierarchy/${parentId}`
  }
}

/**
 * Searches for all categories that match the provided search term. Returns a hierarchy matching the provided items.
 */
export class SearchCategoriesHierarchyRequest
  implements APIRequest<SpaceApp.CategoryFetchResponse> {
  response!: SpaceApp.CategoryFetchResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(search: string) {
    this.path = `/category/hierarchy?q=${search}`
  }
}

/**
 * Gets all categories in the hierarchy.
 */
export class FetchCategoryHierarchyRequest
  implements APIRequest<SpaceApp.CategoryFetchResponse> {
  response!: SpaceApp.CategoryFetchResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor() {
    this.path = '/category/hierarchy'
  }
}
