import { SchoolType } from '../network/schema/models/index'
import { SchoolTypei18n } from '../network/schema/models/school.type'

export interface ISchoolTypeProps extends SchoolType {}

export default class SchoolTypeEntity {
  private _props: ISchoolTypeProps

  constructor(props: ISchoolTypeProps) {
    this._props = props
  }

  get props(): ISchoolTypeProps {
    return this._props
  }

  get id(): number {
    return this.props.id
  }

  get name(): string {
    return this.props.i18n[0].name
  }

  get slug(): string {
    return this.props.slug || ''
  }

  set slug(value: string) {
    this.props.slug = value
  }

  get informations(): SchoolTypei18n[] {
    return this.props.i18n
  }
}

export const EmptySchoolTypePropsFactory = (
  props?: Partial<ISchoolTypeProps>
): ISchoolTypeProps => ({
  id: 0,
  slug: '',
  i18n: [],
  ...props
})

export const SchoolTypePropsFactory = (
  props?: Partial<ISchoolTypeProps>
): ISchoolTypeProps => ({
  id: 0,
  slug: '',
  i18n: [],
  ...props
})

export const EmptySchoolTypeEntityFactory = (
  props?: Partial<ISchoolTypeProps>
): SchoolTypeEntity => {
  return new SchoolTypeEntity(EmptySchoolTypePropsFactory(props))
}

export const SchoolTypeEntityFactory = (
  props?: Partial<ISchoolTypeProps>
): SchoolTypeEntity => {
  return new SchoolTypeEntity(SchoolTypePropsFactory(props))
}
