import { LanguageLocale } from '@tokyojob/core'
import { APIRequest, HTTPMethod } from '@tokyojob/frontend-core'
import { SpaceApp } from '../schema/namespace'

/**
 * Gets all categories in the hierarchy.
 */
export class FetchSkillsRequest
  implements APIRequest<SpaceApp.SkillFetchResponse> {
  response!: SpaceApp.SkillFetchResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor() {
    this.path = `/skill/full?locale=${LanguageLocale.USen}`
  }
}
