
/**
 * Levels of Japanese listening ability.
 */
 export enum JapaneseListeningLevelEn {
  Zero = 'Cannot understand',
  N5 = 'Can understand greetings',
  N4 = 'Basic conversation',
  N3 = 'Daily conversation',
  N2 = 'Business level',
  N1 = 'Almost native',
  Native = 'Full domain of the language'
}