import { IResume, IResumeQualification, UserType } from '@tokyojob/core'
import { BaseUsecaseImpl, LogType } from '@tokyojob/frontend-core'
import { IApp } from '~/types/nuxt'

/**
 * Usecase for updating "Qualification" of a resume.
 */
export class QualificationTranslationUpdateUsecase extends BaseUsecaseImpl {
  /**
   * @param app IApp instance used to access the Gateway and Log services.
   */
    constructor(private readonly app: IApp, userType: UserType) {
    super(app.state, userType)
  }

  async execute(resumeId: number, resume: IResume): Promise<IResumeQualification[]> {
    const { baseGateway, logService } = this.app
    try {
      await this.evaluateAuth()
      return baseGateway.resume.UpdateQualificationTranslation(resumeId, resume)
    } catch (error) {
      await logService.handle({ type: LogType.Error, error: error as Error })
      throw new Error('Failed to update qualification.')
    }
  }
}
