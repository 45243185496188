import { LanguageLocale } from '@tokyojob/core'
import { APIRequest, HTTPMethod } from '@tokyojob/frontend-core'
import { SpaceLocations } from '../schema/namespace'

/**
 * Retrieves a list of available countries.
 */
export class FetchCountriesRequest implements APIRequest<SpaceLocations.CountryFetchResponse> {
  response!: SpaceLocations.CountryFetchResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(locale: LanguageLocale) {
    this.path = `/country?locale=${locale}`
  }
}
