import { IWage } from '../../../models/vacancy/interface/i-wage';
import { StringFormatter } from '../../../utils';
import { PaymentPeriodGetters } from '../../payment-period/getters/payment-period.getters';

export class WageGetters {
  /**
   * Returns an array of wages based on priority
   * @param wages
   * @returns
   */
  static getPrioritizedWage(wages: IWage[]): IWage {
    const map: Record<number, IWage[]> = {};

    // Create a record with the paymentPeriod id as the key to easily categorized each wage via paymentPeriod
    wages.forEach((e) => {
      if (map[e.paymentPeriod?.id!]) map[e.paymentPeriod?.id!].push(e);
      else map[e.paymentPeriod?.id!] = [e];
    });

    // Get all keys which is equivalent to all of the paymentPeriod in the passed wage array
    const keys = Object.keys(map).map((e) => parseInt(e));

    // Get the one with the highest priority. In this case the lower the paymentPeriod id the higher the priority is
    // hourly > daily > monthly > yearly
    const prioritizedPaymentPeriod = Math.min(...keys);
    const prioritizedWages = map[prioritizedPaymentPeriod];

    // Get the lowest amount in all the wages with the highest priority of paymentPeriod id
    // We have a ternary operator inside to avoid passing an undefined or null to the min operation
    const amount = Math.min(...prioritizedWages.map((e) => (!!e.amount ? e.amount : 0)));

    // Get the highest amountMaximum in all the wages with the highest priority of paymentPeriod id
    // We have a ternary operator inside to avoid passing an undefined or null to the max operation
    const amountMaximum = Math.max(...prioritizedWages.map((e) => (!!e.amountMaximum ? e.amountMaximum : 0)));

    return {
      paymentPeriod: prioritizedWages[0].paymentPeriod,

      // If the amount is 0 we can say that the lowest amount was undefined based on the ternary operation above
      amount: amount === 0 ? undefined : amount,

      // If the amountMaximum is 0 we can say that the highest amount was undefined based on the ternary operation above
      amountMaximum: amountMaximum === 0 ? undefined : amountMaximum,
    };
  }

  static getFormattedWage(
    fromSalaryI18n: string,
    upToSalaryI18n: string,
    wage: IWage,
    textSeparator: string = '~',
    textPeriodSeparator: string = '/',
    quantityPrefix: string = '',
  ): string {
    const stringFormatter = new StringFormatter();
    let formattedMessage = ``;
    if (!wage.amount && !wage.amountMaximum) {
      return formattedMessage;
    } else if (wage.amount === wage.amountMaximum) {
      const amount = Math.round(wage.amount!);
      formattedMessage = `${quantityPrefix}${amount.toLocaleString()}`;
    } else if (!!wage.amount && !!wage.amountMaximum) {
      const amount = Math.round(wage.amount!);
      const amountMaximum = Math.round(wage.amountMaximum);
      formattedMessage += `${quantityPrefix}${amount.toLocaleString()}${textSeparator}${amountMaximum.toLocaleString()}`;
    } else if (!!wage.amount) {
      const amount = Math.round(wage.amount!);
      formattedMessage += stringFormatter.format(fromSalaryI18n, [`${quantityPrefix}${amount.toLocaleString()}`]);
    } else {
      const amountMaximum = Math.round(wage.amountMaximum!);
      formattedMessage += stringFormatter.format(upToSalaryI18n, [`${quantityPrefix}${amountMaximum.toLocaleString()}`]);
    }
    const paymentPeriod = wage.paymentPeriod;
    formattedMessage += PaymentPeriodGetters.getWageLabelFriendlyName(paymentPeriod, textPeriodSeparator);
    return formattedMessage;
  }
}
