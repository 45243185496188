// japanese-level-dictionary.factory.ts
import { JapaneseConversationGetters } from '../../../../../../core/src/domain/japanese-language/japanese-conversation.getters';
import { IFilterableDataSetItem } from '../../../../../../core/src/models/common/filter/i-filterable-data-set-item';
import { IJapaneseConversation } from '../../../../../../core/src/models/language/interface/i-japanese-conversation';

export class JapaneseLevelTypeDictionaryFactory {
  public static build(jpList: IJapaneseConversation[]): Record<string, IFilterableDataSetItem> {
    const japaneseLevelDictionary: Record<string, IFilterableDataSetItem> = {};
    for (const item of jpList) {
      const name = JapaneseConversationGetters.getLabel(item);
      const key = `${item.id}` // Assuming the name is unique and can be used as a key
      japaneseLevelDictionary[key] = { id: key, name: name, path: '' };
    }
    return japaneseLevelDictionary;
  }
}