/* eslint-disable prettier/prettier */
import { Contact, ContactSeed } from '../network/schema/models/index'
import ContactMethodEntity, {
  EmptyContactMethodEntityFactory
} from './contact.method'

export interface IContactProps extends Contact {}
export interface IContactSeed extends ContactSeed {}

export default class ContactEntity {
  private _props: IContactProps

  constructor(props: IContactProps) {
    this._props = props
  }

  get props(): IContactProps {
    return this._props
  }

  get id(): number | undefined {
    return this.props.id
  }

  get contactMethod(): ContactMethodEntity {
    return EmptyContactMethodEntityFactory(this.props.contactMethod)
  }

  set contactMethod(value: ContactMethodEntity) {
    this.props.contactMethod = value.props
  }

  get account(): string {
    return this.props.account || ''
  }

  set account(value: string) {
    this.props.account = value
  }

  get other(): string {
    return this.props.other || ''
  }

  set other(value: string) {
    this.props.other = value
  }

  get notification(): boolean {
    return this.props.notification || false
  }

  set notification(value: boolean) {
    this.props.notification = value
  }

  get contactMethodId(): number | undefined {
    return this.props.contactMethodId
  }

  set contactMethodId(value: number | undefined) {
    this.props.contactMethodId = value
  }
}

export const EmptyContactPropsFactory = (
  props?: Partial<IContactProps>
): IContactProps => ({
  ...props
})

export const ContactPropsFactory = (
  props?: Partial<IContactProps>
): IContactProps => ({
  ...props
})

export const EmptyContactEntityFactory = (
  props?: Partial<IContactProps>
): ContactEntity => {
  return new ContactEntity(EmptyContactPropsFactory(props))
}

export const ContactEntityFactory = (
  props?: Partial<IContactProps>
): ContactEntity => {
  return new ContactEntity(ContactPropsFactory(props))
}
