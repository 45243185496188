import { MetaPropertyCharset, MetaPropertyEquiv, MetaPropertyMicrodata, MetaPropertyName, MetaPropertyProperty } from 'vue-meta'
import { IVacancy, LanguageLocale, LocalizationData, LocalizationPath, VacancyGetters } from '../../../../../../../core/src'
import { LocalizationService } from '../../../language/localization-service'
import { VacancyCardText } from '../../../vacancy'
import { ogTypes } from '../../constants'
import { MetaTagFactory } from '../meta-tag.factory'

/**
 * Vacancy details page meta tags.
 */
export class VacancyDetailsMetaFactory {
  static metaTags(
    localization: LocalizationData,
    vacancy: IVacancy,
    locale: LanguageLocale
  ): (MetaPropertyCharset | MetaPropertyEquiv | MetaPropertyName | MetaPropertyMicrodata | MetaPropertyProperty)[] {
    const summary = VacancyGetters.getSummary(vacancy)
    const imageUrl = VacancyGetters.getEditedImageUrl(vacancy)
    const title = VacancyGetters.getSEOTitle(
      vacancy,
      LocalizationService.t(localization, LocalizationPath.VacancyDetailPageMetaTagTitle)
    )
    const titleTags = MetaTagFactory.titleTags(title)
    const siteName = MetaTagFactory.siteName()
    const twitterTitleTags = MetaTagFactory.twitterTitle(title)
    const typeTags = MetaTagFactory.type(ogTypes.jobVacancy)
    const formattedWages = VacancyCardText.getFormattedWage(localization, vacancy, ' to ', '/', '¥')
    const descriptions = MetaTagFactory.descriptionTags(`${formattedWages.join(' ')}. ${summary}`)
    const keywords = MetaTagFactory.vacancyDetailKeywords(localization, vacancy)
    const locales = MetaTagFactory.localeTags(locale)
    const robots = MetaTagFactory.indexedRobots()
    const twitterImageTags = MetaTagFactory.twitterImage(imageUrl)
    const twitterTags = MetaTagFactory.twitterTags()

    /**
     * TEMPORARY TEST FUNCTION TO A B TEST OPTIONS FOR META TAGS IN MULTILINGUAL. REMOVE WHEN DONE.
     * @returns
     */
    const imageTagsTest = () => {
      if (vacancy.id === 1314) {
        return MetaTagFactory.imageTags1314Test(imageUrl)
      }
      if (vacancy.id === 1315) {
        return MetaTagFactory.imageTags1315Test(imageUrl)
      }
      return [MetaTagFactory.image(imageUrl)]
    }
    const imageTags = imageTagsTest()
    
    /**
     * RE-ADD THIS AND UPDATE WITH ADDITIONAL TAGS AS NECESSARY AFTER TEST COMPLETE.
     * @returns
     */

    return [
      ...imageTags,
      ...titleTags,
      twitterTitleTags,
      typeTags,
      twitterImageTags,
      ...descriptions,
      keywords,
      ...locales,
      robots,
      ...twitterTags,
      siteName,
    ]
  }
}