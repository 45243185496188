
/**
 * Levels of Japanese speaking ability.
 */
 export enum JapaneseSpeakingLevelEn {
  Zero = 'Cannot speak in Japanese',
  N5 = 'Can only say greetings',
  N4 = 'Basic conversation',
  N3 = 'Daily conversation',
  N2 = 'Business level',
  N1 = 'Almost native',
  Native = 'Full domain of the language'
}