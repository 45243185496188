import { IVisaType } from '../../../models';
import { CompositeSpecification } from '../../base/specification';

/**
 * Whether the provided visa type or any of its children (if provided) require designation papers.
 */
export class VisaTypeRequiresDesignationPapersSpecification extends CompositeSpecification<IVisaType> {
  isSatisfiedBy(visaType: IVisaType): boolean {
    if (visaType.designationPapersRequired) return true;
    if (visaType.children && visaType.children.length > 0) {
      for (let index = 0; index < visaType.children.length; index++) {
        const element = visaType.children[index];
        if (element.designationPapersRequired) return true;
      }
    }
    return false;
  }
}
