import { IVacancy } from '../../../../../../../core/src'
import { GACustomEvent } from '../constants/custom-tracking-events.enum'
import { ICustomGTagEvent } from '../interfaces/i-custom-gtag-event'
import { VacancyGetters } from '../../../../../../../core/src'
import { GTagEventBaseFactory } from './gtag-event-base.factory'

/**
 * Factory class responsible for creating Google Tag (GTag) events
 * pertaining to misc events within the application.
 */
export class JobGTagEventFactory extends GTagEventBaseFactory {
  /**
   * Creates an event for when a user changes their visa type in the application process.
   * @remarks this one matters because, in theory the user should already have their
   * visa type selected when they get to the application process.
   * If they change it, it could mean that people are figuring out what visas they
   * can use to apply, rather than selecting the one they have.
   * @param oldVisaTypeId
   * @param newVisaTypeId
   * @returns
   */
  static applicationProcessVisaTypeChange(oldVisaTypeId: number[] | undefined, newVisaTypeId: number[]): ICustomGTagEvent {
    return this.createEvent(GACustomEvent.ApplicationProcessVisaTypeChange, {
      oldVisaType: oldVisaTypeId,
      newVisaType: newVisaTypeId,
    })
  }

  /**
   * Events for when a user inputs their email in the application process.
   * @param email
   * @returns
   */
  static applicationOnProcessEmailInput(email: string): ICustomGTagEvent {
    return this.createEvent(GACustomEvent.ApplicationProcessEmailInput, {
      email,
    })
  }

  /**
   * When a user clicks a recommended job card.
   * @param sourceJobId
   * @param recommendedJobId
   * @param industryId
   * @param jobSectorId
   * @param prefectureId
   * @param cityId
   * @returns
   */
  static recommendedJobOpened(recommendedJob: IVacancy): ICustomGTagEvent {
    const recommendedJobId = recommendedJob.id
    const industryId = VacancyGetters.getIndustry(recommendedJob)?.id
    const jobSectorId = VacancyGetters.getJobSector(recommendedJob)?.id
    const prefectureId = VacancyGetters.getPrefectureID(recommendedJob)
    const cityId = VacancyGetters.getCityID(recommendedJob)
    return this.createEvent(GACustomEvent.RecommendedJobOpened, {
      recommendedJobId,
      industryId,
      jobSectorId,
      prefectureId,
      cityId,
    })
  }

  /**
   * When a user clicks a social share button.
   * @param socialNetwork
   * @returns
   */
  static socialShared(socialNetwork: string): ICustomGTagEvent {
    return this.createEvent(GACustomEvent.SocialShared, {
      socialNetwork,
    })
  }

  /**
   * When a user changes their visa type while on the job details page.
   * @param oldVisaTypeId
   * @param newVisaTypeId
   * @returns
   */
  static jobDetailsVisaTypeChange(oldVisaTypeId: (number | undefined)[], newVisaTypeId: number[]): ICustomGTagEvent {
    return this.createEvent(GACustomEvent.JobDetailsVisaTypeChange, {
      oldVisaType: oldVisaTypeId,
      newVisaType: newVisaTypeId,
    })
  }

  /**
   * When a user changes their jp level while on the job details page.
   * @param oldVisaTypeId
   * @param newVisaTypeId
   * @returns
   */
  static jobDetailsJpLevelChange(oldJpLevelId: number | undefined, newJpLevelId: number): ICustomGTagEvent {
    return this.createEvent(GACustomEvent.JobDetailsJpLevelChange, {
      oldJpLevel: oldJpLevelId,
      newJpLevel: newJpLevelId,
    })
  }

  /**
   * When a top banner is clicked, triggering an industry filter change.
   * @param industryId
   * @returns
   */
  static industryJobGridBannerClick(industryId: number): ICustomGTagEvent {
    return this.createEvent(GACustomEvent.JobGridBannerClick, {
      industryId,
    })
  }
  static couponBannerClick(): ICustomGTagEvent {
    return this.createEvent(GACustomEvent.CouponBannerClick, {})
  }
  static couponBannerDisplay(): ICustomGTagEvent {
    return this.createEvent(GACustomEvent.CouponBannerDisplay, {})
  }
}
