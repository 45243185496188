/**
 * The enum for the available filter keys for URL queries
 */
export enum FilterKey {
  visa = 'visa',
  category = 'category',
  location = 'location',
  contract = 'contract',
  japanese = 'japanese',
}
