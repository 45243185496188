import { Type } from 'class-transformer';
import {
  IsArray,
  IsDefined,
  IsEnum,
  IsNotEmpty,
  IsNotEmptyObject,
  IsObject,
  IsOptional,
  IsString,
  Length,
  MaxLength,
  ValidateNested,
} from 'class-validator';
import { CandidateUpdateDto } from '../../../candidate/dto/update/candidate-update.dto';
import { LanguageLocale } from '../../../language';
import { Gender } from '../../constants';
import { UserUpdateContactDto } from './user-update-contact.dto';
/**
 *
 */
export class UserUpdateDto {
  /**
   * The first name of this user.
   */
  @IsString()
  @Length(2, 20)
  firstName: string;

  /**
   * The last name of this user.
   */
  @IsString()
  @Length(2, 20)
  lastName: string;

  /**
   * The nickname of this user.
   */
  @IsString()
  @MaxLength(20)
  @IsOptional()
  nickname?: string;

  /**
   * The gender of this user. Candidate's are limited to the 2 Sex options allowed in a residence card.
   */
  @IsEnum(Gender)
  gender: Gender

  /**
   * The language of the user.
   */
  @IsEnum(LanguageLocale)
  locale: LanguageLocale

  /**
   * The contact information associated with this user's profile.
   */
  @IsArray()
  @IsDefined()
  @IsNotEmpty()
  @ValidateNested({ each: true })
  @Type(() => UserUpdateContactDto)
  contacts: UserUpdateContactDto[];

  /**
   * Candidate data. It applies, of course, only to users whose userType is candidate.
   */
  @IsDefined()
  @IsNotEmptyObject()
  @IsObject()
  @ValidateNested()
  @Type(() => CandidateUpdateDto)
  candidate: CandidateUpdateDto;
}
