import { AnonymousApplicationWithdrawDto } from '../dto/anonymous-application-withdraw.dto';

export class AnonymousApplicationWithdrawFactory {
  static Create(statusId: number, reason: string): AnonymousApplicationWithdrawDto {
    const dto = new AnonymousApplicationWithdrawDto();
    dto.statusId = statusId;
    dto.reason = reason;
    return dto;
  }
}
