import { IUser } from '../../user/interface/i-user';
import { CandidateNameDto } from '../dto/candidate-name.dto';

export class CandidateNameDtoFactory {
  new(user: IUser): CandidateNameDto {
    const firstName = user.firstName?.trim();
    const lastName = user.lastName?.trim();
    if (!firstName || !lastName) throw new Error('Expected non empty first and last names');
    const dto = new CandidateNameDto();
    dto.firstName = firstName;
    dto.lastName = lastName;
    dto.nickname = user.nickname ?? null;
    return dto;
  }
}
