/**
 * The types of files that may be supported by the our file uploader.
 */
export enum UploaderFileType {
  Images = 'image/*',
  Doc = '.doc',
  DocX = '.docx',
  PDF = '.pdf',
  PPT = '.ppt',
  PPTX = '.pptx',
  XLS = '.xls',
  XLSX = '.xlsx',
  Keynote = '.key',
  Pages = '.pages',
  Numbers = '.numbers'
}
