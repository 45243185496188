import { EnumDictionary } from '../../../utils/types/enum-dictionary';
import { ApplicationCandidateStatusId } from './application-candidate-status-id.enum';

/**
 * Fontawesome Icon name for each application status.
 * An empty string implies that the theme will depend on the substatus.
 */
export const ApplicationCandidateStatusIcons: EnumDictionary<ApplicationCandidateStatusId, string> = {
  [ApplicationCandidateStatusId.Submitted]: 'seedling', // New Application
  [ApplicationCandidateStatusId.Check]: '',
  [ApplicationCandidateStatusId.Contacting]: '',
  [ApplicationCandidateStatusId.Rejected]: '',
  [ApplicationCandidateStatusId.Interview]: '',
  [ApplicationCandidateStatusId.Hired]: '',
  [ApplicationCandidateStatusId.Memo]: '',
  [ApplicationCandidateStatusId.Withdrawn]: ''
};
