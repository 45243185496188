
/**
 * Typed representation of language in URLs.
 */
export enum LanguageURL {
  JP = 'jp',
  EN = 'en',
  VI = 'vi',
  ZH = 'zh'
}
