/**
 * Enumeration of custom events for Google Analytics tracking related to user interactions.
 */
export enum GACustomEvent {
  /**
   * Triggered when the user switches visa types during the application process.
   * Payload: old visa type, new visa type
   */
  ApplicationProcessVisaTypeChange = 'application_process_visa_type_change',

  /**
   * Triggered when the user inputs an email while in the application process.
   */
  ApplicationProcessEmailInput = 'application_process_email_input',

  /**
   * Triggered when a job is opened from the recommended jobs component.
   * Currently, this is observed only on the job details page.
   * It might be beneficial to differentiate this based on the page in the future.
   * Payload: job id, category, and location
   */
  RecommendedJobOpened = 'recommended_job_opened',

  /**
   * Triggered when a user clicks on a social share button link.
   * Payload: job id, category, and location
   */
  SocialShared = 'social_shared',

  /**
   * Triggered when the visa type is modified on the job details page.
   * Payload: old visa type, new visa type
   */
  JobDetailsVisaTypeChange = 'job_details_visa_type_change',

  /**
   * Triggered when the jp level is modified on the job details page.
   * Payload: old jp level, new jp level
   */
  JobDetailsJpLevelChange = 'job_details_jp_level_change',

  /**
   * Triggered when the top banner on the job grid is clicked.
   * Payload: banner parameters
   */
  JobGridBannerClick = 'job_grid_banner_click',
  CouponBannerClick = 'coupon_banner_clicked',
  CouponBannerDisplay = 'coupon_banner_display'
}

/**
 * Enumeration of page view events for Google Analytics tracking related to the job application process.
 * Each stage of the application process has a unique event.
 * The event name corresponds to the section that the user will navigate to.
 */
export enum GAJobApplyPageViewEvent {
  Welcome = 'application_welcome_page',
  Name = 'application_name_page',
  VisaType = 'application_visa_type_page',
  ContactInformation = 'application_contact_information_page',
  PersonalInformation = 'application_personal_information_page',
  JapaneseLanguage = 'application_japanese_language_page',
  ApplicationConfirmation = 'application_confirmation_page',
  ApplicationSuccess = 'application_success_page',
}
