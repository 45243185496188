export class GenericObjectFactory {
  /**
   * Clones any object that is passed into it
   * @param obj
   * @returns
   */
  static clone<T>(obj: T): T {
    // Handle null or undefined
    if (obj === null || obj === undefined) {
      return obj;
    }

    // Handle primitive types (string, number, boolean, etc.)
    if (typeof obj !== 'object') {
      return obj;
    }

    // Handle Date
    if (obj instanceof Date) {
      return new Date(obj.getTime()) as any;
    }

    // Handle Array
    if (Array.isArray(obj)) {
      const arrCopy = [] as any[];
      for (const item of obj) {
        arrCopy.push(this.clone(item));
      }
      return arrCopy as any;
    }

    // Handle Object
    const objCopy = {} as { [key: string]: any };
    for (const key in obj) {
      if ((obj as unknown as object).hasOwnProperty(key)) {
        objCopy[key] = this.clone((obj as any)[key]);
      }
    }
    return objCopy as T;
  }
}
