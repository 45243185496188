/**
 * Handles html element UI related issues.
 */
export class HtmlElementUtils {
  /**
   * Smooth scroll to top of page.
   * Doesnt work correctly when overflow: hidden it applied to the body, layout, html etc.
   */
  static scrollTop(): void {
    setTimeout(function () {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }, 1)
  }
  /**
   * Checks that an element'S scroll is at its lowest possible value, within an offset.
   * @param element Element whose scorll values will be checked.
   * @param bottomOffset How far from the very bottom the element can be.
   * @todo Make this actually work. Currently it's very unreliable due to how the html elements are placed.
   * @returns
   */
  static isElementScrollAtBottom(element: HTMLElement, bottomOffset: number = 75): boolean {
    // const scrollOffset = 10 // element.clientHeight - element.scrollTop
    // const test =
    //   (1 - element.scrollTop / (element.clientHeight - scrollOffset)) *
    //   element.scrollHeight
    //   const isAtBottom = test < bottomOffset
    const pixelsAwayFromBottom = element.scrollHeight - element.scrollTop - element.clientHeight
    const isAtBottom = pixelsAwayFromBottom < bottomOffset
    return isAtBottom
  }

  /**
   * Checks if the specified DOM element is inside the viewport of the specified parent element.
   * @param child
   * @param container
   * @link https://stackoverflow.com/questions/56999795/how-detect-which-child-element-is-visible-after-scrolling-the-parent-div
   * @returns
   */
  static isElementInViewport = (child: HTMLElement, container: HTMLElement) => {
    const scrollTop = container.scrollTop
    const scrollBot = scrollTop + container.clientHeight
    const containerRect = container.getBoundingClientRect()
    const eleRect = child.getBoundingClientRect()
    const rect = { top: 0, right: 0, bottom: 0, left: 0 }
    rect.top = eleRect.top - containerRect.top
    rect.right = eleRect.right - containerRect.right
    rect.bottom = eleRect.bottom - containerRect.bottom
    rect.left = eleRect.left - containerRect.left
    const eleTop = rect.top + scrollTop
    const eleBot = eleTop + child.offsetHeight
    const visibleTop = eleTop < scrollTop ? scrollTop : eleTop
    const visibleBot = eleBot > scrollBot ? scrollBot : eleBot
    const isVisible = visibleBot - visibleTop === 0
    return isVisible
  }
}
