import { Type } from 'class-transformer';
import { IsNumber, IsObject, IsOptional, IsString, ValidateNested } from 'class-validator';
import { VacancyContactDto } from '../vacancy-contact.dto';
import { VacancyPictureDto } from '../vacancy-picture.dto';

/**
 * The DTO that validates the basic information section
 */
export class VacancySaveBasicInformationDto {
  @IsOptional()
  customId: string;
  /**
   * The workplace id where the vacancy belongs to
   */
  @IsNumber()
  workplaceId: number;

  /**
   * The id of the industry where the vacancy will belong to
   */
  @IsNumber()
  industryId: number;

  /**
   * The id of the job sector where the vacany will belong to
   */
  @IsOptional()
  @IsNumber()
  jobSectorId: number;

  /**
   * The title of the vacancy
   */
  @IsString()
  title: string;

  /**
   * The summary of the vacancy
   */
  @IsString()
  summary: string;

  /**
   * The description of the vacancy
   */
  @IsString()
  description: string;

  /**
   * The image information of the vacancy
   */
  @IsOptional()
  pictures: VacancyPictureDto[];

  /**
   * The memo of the vacancy
   */
  @IsOptional()
  @IsString()
  clientMemo: string;

  /**
   * The contact list of vacancy to be notified
   */
  @ValidateNested({ each: true })
  @Type(() => VacancyContactDto)
  contacts: VacancyContactDto[];
}
