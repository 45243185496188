import { LanguageLocale } from "../../../../../../core/src"

export class MetaTagLocaleFactory {
  /**
   * Returns a formatted locale that can be used in an og:locale meta tag.
   * @param locale 
   * @example "JA-jp" -> "jp_JA"
   * @returns 
   */
  static localeToMetaLocale(locale: LanguageLocale): string {
    const parts = locale.split('-')
    const result = `${parts[1]}_${parts[0]}`
    return result
  }

  /**
   * Returns a formatted locale that can be used in an content-language meta tag.
   * @param locale 
   * @example "JA-jp" -> "jp-ja"
   * @returns 
   */
  static localeToMetacontentLanguage(locale: LanguageLocale): string {
    const parts = locale.split('-')
    const result = `${parts[1]}-${parts[0].toLowerCase()}`
    return result
  }

}