import { MetaPropertyCharset, MetaPropertyEquiv, MetaPropertyMicrodata, MetaPropertyName, MetaPropertyProperty } from 'vue-meta'
import { IVacancy, LanguageLocale, VacancyGetters } from '../../../../../../../core/src'
import { MetaTagFactory } from '../meta-tag.factory'

/**
 * Vacancy application meta tags.
 */
export class ApplicationMetaFactory {
  static metaTags(
    vacancy: IVacancy,
    locale: LanguageLocale
  ): (MetaPropertyCharset | MetaPropertyEquiv | MetaPropertyName | MetaPropertyMicrodata | MetaPropertyProperty)[] {
    const siteName = MetaTagFactory.siteName()
    const locales = MetaTagFactory.localeTags(locale)
    const robots = MetaTagFactory.nonindexedRobots()
    const imageUrl = VacancyGetters.getEditedImageUrl(vacancy)
    const ogImage = MetaTagFactory.image(imageUrl)
    const twitterImage = MetaTagFactory.twitterImage(imageUrl)
    return [siteName, ...locales, robots, ogImage, twitterImage]
  }
}
