import { LanguageLocale } from '@tokyojob/core'
import { APIRequest, HTTPMethod } from '@tokyojob/frontend-core'
import { ICandidateVisaSeed } from '../../entities/candidate.visa'
import { SpaceCandidate } from '../schema/namespace'

/**
 * HTTP Request to Create current candidate visa information
 */
export class CreateCandidateVisaRequest
  implements APIRequest<SpaceCandidate.UpdateProfileResponse> {
  response!: SpaceCandidate.UpdateProfileResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: SpaceCandidate.UpdateProfileRequest
  constructor(seed: ICandidateVisaSeed) {
    this.params = seed
    this.path = '/visa/'
  }
}

/**
 * HTTP Request to update current candidate visa information
 */
export class UpdateCandidateProfile
  implements APIRequest<SpaceCandidate.UpdateProfileResponse> {
  response!: SpaceCandidate.UpdateProfileResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.PUT
  params: SpaceCandidate.UpdateProfileRequest
  constructor(seed: ICandidateVisaSeed) {
    this.params = seed
    this.path = '/candidate'
  }
}

/**
 * HTTP Request to get current candidate visa information
 * @todo DELETE after it is fully replaced by GetCurrentICandidateVisaRequest
 */
export class GetCurrentVisaRequest
  implements APIRequest<SpaceCandidate.GetCurrentVisaResponse> {
  response!: SpaceCandidate.GetCurrentVisaResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(locale: LanguageLocale) {
    this.path = `/visa/current?locale=${locale}`
  }
}


/**
 * HTTP Request to get current candidate visa information
 */
 export class GetCurrentICandidateVisaRequest
 implements APIRequest<SpaceCandidate.GetCurrentICandidateVisaResponse> {
 response!: SpaceCandidate.GetCurrentICandidateVisaResponse
 path: string
 contentType = 'application/json'
 method = HTTPMethod.GET
 constructor(locale: LanguageLocale) {
   this.path = `/visa/current?locale=${locale}`
 }
}
