import { APIRequest, HTTPMethod, QueryStringEntity } from '@tokyojob/frontend-core'
import { SpaceApp } from '../schema/namespace'

export class FetchContactMethodRequest
  implements APIRequest<SpaceApp.ContactMethodFetchResponse> {
  response!: SpaceApp.ContactMethodFetchResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(entity?: QueryStringEntity) {
    const queries = entity ? entity.queries : ''
    this.path = '/contact_method/' + queries
  }
}

/**
 * Replaces the old FetchContactMethodRequest
 * @todo Delete FetchContactMethodRequest after it is no longer used
 */
export class FetchIContactMethodRequest
  implements APIRequest<SpaceApp.IContactMethodFetchResponse> {
  response!: SpaceApp.IContactMethodFetchResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(entity?: QueryStringEntity) {
    const queries = entity ? entity.queries : ''
    this.path = '/contact_method/' + queries
  }
}