import { ICandidate } from '../../../models';
import { CompositeSpecification } from '../../base/specification';

/**
 * Whether the provided candidate has a designation papers attachment or not.
 */
export class CandidateHasDesignationPapersSpecification extends CompositeSpecification<ICandidate> {
  isSatisfiedBy(candidate: ICandidate): boolean {
    if (candidate.candidateVisa?.designationPapersAttachmentId) return true;
    if (candidate.candidateVisa?.designationPapersAttachment?.id) return true;
    return false;
  }
}
