import { IsNumber, IsOptional } from "class-validator";

/**
 * The DTO that validates the benefits information
 */
export class VacancyBenefitDto {
  /**
   * The id of the benefit that is related to the vacancy
   */
  @IsNumber()
  id?: number;

  /**
   * The id of the unit related to the benefit
   */
  @IsNumber()
  @IsOptional()
  benefitUnitId: number;

  /**
   * The amount related related to the benefit
   */
  @IsNumber()
  @IsOptional()
  amount: number;
}