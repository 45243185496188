import { IFormInputData } from '@tokyojob/frontend-core'
import { APIRequest, HTTPMethod } from '@tokyojob/frontend-core'

/**
 * Request data to upload temporary files.
 */
export class VacancyLocationGetRequest implements APIRequest<IFormInputData[]> {
  response!: IFormInputData[]
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(title: string) {
    this.path = `location/name?title=${title}`
  }
}
