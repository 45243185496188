
import { defineComponent, ref, watch } from 'vue'
import { CMSLinkFactory, IJoomlaFooterLink, LocalizationData, LocalizationPath } from '@tokyojob/core'
import { ButtonFactory, CButton, getLocalization, IButton } from '@tokyojob/frontend-components'
import { AppEnvironment, LocalizationService, StaticURLBuilder } from '@tokyojob/frontend-core'
import { IApp } from '~/types/nuxt'
import { getProvidedApp, getRoute } from '~/base/utils/provided-getter'
import { getSideMenuContentStore } from '~/store/side-menu.store'

export default defineComponent({
  components: {
    CButton,
  },
  setup() {
    const app: IApp = getProvidedApp()
    const route = getRoute()
    const localization: LocalizationData = getLocalization(app)

    const ltbUrl = 'www.livetobusiness.co.jp'
    const logoWhite = StaticURLBuilder.logoWhite(process.env.APP_ENV as AppEnvironment)
    const tokyoJobSummaryText = ref(LocalizationService.t<string>(localization, LocalizationPath.tokyoJobSummary))
    const jobsNewsletter = ref(LocalizationService.t<string>(localization, LocalizationPath.jobsNewsletter))
    const jobsNewsletterBody = ref(LocalizationService.t<string>(localization, LocalizationPath.jobsNewsletterBody))
    const subscribeBtn = ref<IButton>(
      ButtonFactory.button({
        classes: 'row-center theme-shades-light1-white br-5 color-blue-dark1 align-center',
        width: '100%',
        height: '30px',
        label: LocalizationService.t<string>(localization, LocalizationPath.subscribe),
        link: { link: '' },
      })
    )

    const footerJoomlaLinks = ref<IJoomlaFooterLink[]>([])

    const joomlaLayoutContentUpdate = () => {
      const sideMenuContentStore = getSideMenuContentStore()
      const locale = app.localizationSelector.languageLocale
      const joomlaLayoutContent = sideMenuContentStore.get(locale)
      footerJoomlaLinks.value = CMSLinkFactory.getFooterLinks(joomlaLayoutContent)
      const subscribeBtnTemp: IButton = subscribeBtn.value as IButton
      subscribeBtnTemp.link!.link = CMSLinkFactory.getNewsletterLink(joomlaLayoutContent)
      // Needed the as any because the compiler complains
      subscribeBtn.value = ButtonFactory.button(subscribeBtnTemp) as any
    }
    joomlaLayoutContentUpdate()

    watch(
      () => route.value.path,
      (newPath) => {
        tokyoJobSummaryText.value = LocalizationService.t<string>(localization, LocalizationPath.tokyoJobSummary)
        jobsNewsletter.value = LocalizationService.t<string>(localization, LocalizationPath.jobsNewsletter)
        jobsNewsletterBody.value = LocalizationService.t<string>(localization, LocalizationPath.jobsNewsletterBody)
        joomlaLayoutContentUpdate()
      }
    )

    return {
      logoWhite,
      footerJoomlaLinks,
      tokyoJobSummaryText,
      jobsNewsletter,
      jobsNewsletterBody,
      ltbUrl,
      subscribeBtn,
    }
  },
})
