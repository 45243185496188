import { IAttachment } from "../../../../../../../core/src"

/**
 * A file attachment. Can be an image, document, etc.
 */
export class AttachmentEntity {
  private _props: IAttachment

  constructor(props: IAttachment) {
    this._props = props
  }

  get props(): IAttachment {
    return this._props
  }

  /**
   * Unique identifier of the attachment.
   */
  get id(): number | undefined {
    return this.props.id
  }

  /**
   * Name of the Attachment as saved in our database. Tends to be a uuid.
   */
  get name(): string {
    return this.props.name!
  }

  set name(value: string) {
    this.props.name = value
  }

  /**
   * The name of the attachment as it was originally saved by the uploader.
   */
  get originalName(): string {
    return this.props.originalName!
  }

  set originalName(value: string) {
    this.props.originalName = value
  }

  /**
   * Description of the attachment, if any.
   * @remarks Currently does not seem to be in use, might delete it eventually.
   */
  get description(): string {
    return this.props.description!
  }

  /**
   * URL where the attachment is stored at. Usually a temporary secure URL that expires after a set amount of time.
   */
  set description(value: string) {
    this.props.description = value
  }

  get url(): string | undefined {
    return this.props.url
  }

  /**
   * Text to print in the image's alt attribute.
   */
  get alt(): string | undefined {
    return this.originalName
  }

  /**
   * Shorter version of the original name, no longer than 20 characters.
   * Appends ellipsis if the length is exceeded.
   */
  get shortTitle(): string {
    const maxTitleLength = 20
    let title = this.originalName
    if (title?.length > maxTitleLength) {
      title = `${title.substr(0, maxTitleLength)}...`
    }
    return title
  }
}
