import { defineStore } from 'pinia'

interface ArrayBufferProcess {
  fetchPromise?: Promise<ArrayBuffer>
  result?: ArrayBuffer
}

interface State {
  items: Record<string, ArrayBufferProcess>
}

export const getBufferStore = defineStore('bufferStore', {
  state: (): State => ({
    items: {},
  }),
  actions: {
    async fetchData(url: string) {
      try {
        if (this.items[url] && this.items[url].fetchPromise) {
          return;
        }
        const fetchPromise = fetch(url).then(res => res.arrayBuffer())
        this.items[url] = { fetchPromise }
        const result = await fetchPromise
        this.items[url] = { result }
      } catch(e) {
        delete this.items[url]
      }
    },
    clearCache(url: string) {
      if (!this.items[url]) return
      delete this.items[url]
    },
    getCache(key: string): ArrayBufferProcess {
      return this.items[key]
    },
  },
})
