import { defineNuxtPlugin } from '@nuxtjs/composition-api'
import { AppEnvironment, AppList, GTagService, GoogleAnalyticsConfig } from '@tokyojob/frontend-core'

/**
 * Sets up Google Analytics through the Tag Manager.
 * @link https://github.com/nuxt-community/gtm-module/issues/82#issuecomment-762153556 Sample setup
 */
export default defineNuxtPlugin((ctx) => {
  const environment = process.env.APP_ENV as AppEnvironment
  const analyticsId = GoogleAnalyticsConfig.getGTagId(environment, AppList.Candidate)
  if (analyticsId) {
    const dataLayer = (window as any).dataLayer || []
    ctx.$gtag = new GTagService(dataLayer)
  } else {
    console.log('Skipping GA in development')
  }
})
