/**
 * Levels of Japanese literacy (reading and writing).
 */
 export enum JapaneseWritingSkillId {
  Zero = 1,
  Hiragana = 2,
  Katakana = 3,
  N5 = 4,
  N4 = 5,
  N3 = 6,
  N2 = 7,
  N1 = 8
}
