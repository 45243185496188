import {
  AWSConfigs,
  AppEnvironment,
  BucketVisibility,
  S3BucketNameDesignation,
  S3BucketsEnvironments,
  S3ReportBucketsEnvironments,
} from '../../configs';

/**
 * Utility object to resolve and check various settings related to S3 buckets.
 */
export class S3Bucket {
  /**
   * Returns a bucket depending on the provided parameters.
   * @param environment The environment for which the bucket is for.
   * @param bucketVisibility The required visibility of the bucket. Defaults to Private.
   * @returns
   */
  resolveBucketName(
    environment?: AppEnvironment,
    bucketVisibility: BucketVisibility = BucketVisibility.Private,
  ): S3BucketNameDesignation {
    if (!environment) environment = process.env.APP_ENV as AppEnvironment;

    return S3BucketsEnvironments[environment][bucketVisibility];
  }

  /**
   * Returns a report bucket based on the passed/current environment
   * @param environment
   * @returns
   */
  resolveReportBucketName(environment?: AppEnvironment) {
    if (!environment) environment = process.env.APP_ENV as AppEnvironment;

    return S3ReportBucketsEnvironments[environment];
  }

  /**
   * Checks if the provided bucket is of public access.
   * @param bucket Bucket whose access will be checked.
   * @returns
   */
  isPublicBucket(bucket: S3BucketNameDesignation): boolean {
    const publicBuckets = [
      S3BucketsEnvironments.development.Public,
      S3BucketsEnvironments.production.Public,
      S3BucketsEnvironments.test.Public,
    ];
    return publicBuckets.includes(bucket);
  }

  /**
   * Generates the file URL. This URL may or may not be publicly accessible, depending on the file's bucket's policies.
   * @param bucketName The name of the bucket in which the file is located in.
   * @param path The url path of the file. Should NOT include the domain and the file name. Ex: "files/"
   * @param fileName The name of the file. Should NOT include the domain and path. Ex: "myImageFile.jpg"
   * @returns
   */
  resolveOriginalUrl(bucketName: S3BucketNameDesignation, path: string, fileName: string) {
    const s3BaseUrl = `https://${bucketName}${AWSConfigs.S3URL}`;
    return `${s3BaseUrl}${path}${fileName}`;
  }
}
