import { LanguageLocale } from '@tokyojob/core'
import { APIRequest, HTTPMethod } from '@tokyojob/frontend-core'
import { SpaceSchoolType } from '../schema/namespace'

/**
 * Gets all available school types.
 */
export class FetchSchoolTypeRequest
  implements APIRequest<SpaceSchoolType.FetchResponse> {
  response!: SpaceSchoolType.FetchResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor() {
    this.path = `/school_type?locale=${LanguageLocale.USen}`
  }
}
