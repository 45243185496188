import { LocalizationData, LocalizationPath, PaymentPeriodGetters } from '../../../../../core/src'
import { IWage } from '../../../../../core/src/models/vacancy/interface/i-wage'
import { DateFormatter } from '../../../../../core/src/utils/date/date-formatter'
import { LocalizationService } from '../language'

export class WageCardText {
  /**
   * Returns shift start time formatted into a string 'Start' prefixed.
   * @param vacancy
   * @returns
   */
  static getShiftFrom(localization: LocalizationData, wage: IWage): string | undefined {
    if (wage.shiftFrom) {
      const formatter = new DateFormatter()
      // Had to do this due to how data comes as fro mthe db.
      const str = wage.shiftFrom as any as string
      return LocalizationService.t<string>(localization, LocalizationPath.Start, [formatter.getHMFromString(str)])
    }
    return undefined
  }

  /**
   * Returns shift end time formatted into a string 'Finish' prefixed.
   * @param vacancy
   * @returns
   */
  static getShiftUntil(localization: LocalizationData, wage: IWage): string | undefined {
    if (wage.shiftUntil) {
      const formatter = new DateFormatter()
      // Had to do this due to how data comes as fro mthe db.
      const str = wage.shiftUntil as any as string
      return LocalizationService.t<string>(localization, LocalizationPath.Finish, [formatter.getHMFromString(str)])
    }
    return undefined
  }

  /**
   * Returns Array of strings describing work days for a Vacancy
   * @param vacancy
   * @returns
   */
  static getWorkingDays(localization: LocalizationData, wage: IWage): string[] | undefined {
    const formattedArray = []
    if (wage.monday) formattedArray.push(LocalizationService.t<string>(localization, LocalizationPath.Monday))
    if (wage.tuesday) formattedArray.push(LocalizationService.t<string>(localization, LocalizationPath.Tuesday))
    if (wage.wednesday) formattedArray.push(LocalizationService.t<string>(localization, LocalizationPath.Wednesday))
    if (wage.thursday) formattedArray.push(LocalizationService.t<string>(localization, LocalizationPath.Thursday))
    if (wage.friday) formattedArray.push(LocalizationService.t<string>(localization, LocalizationPath.Friday))
    if (wage.saturday) formattedArray.push(LocalizationService.t<string>(localization, LocalizationPath.Saturday))
    if (wage.sunday) formattedArray.push(LocalizationService.t<string>(localization, LocalizationPath.Sunday))
    return formattedArray.length > 0 ? formattedArray : undefined
  }

  /**
   * Returns overtime hours formatted to a string with period details appended
   * @param vacancy
   * @returns
   */
  static getPossibleOvertime(localization: LocalizationData, wage: IWage): string | undefined {
    if (wage.overtime && wage.overtimeHoursPerMonth) {
      return LocalizationService.t<string>(localization, LocalizationPath.OvertimePerMonth, [
        wage.overtimeHoursPerMonth.toString(),
      ])
    }
    return undefined
  }

  /**
   * Returns salary close day formatted to string with added date decoration
   * @param vacancy
   * @returns
   */
  static getSalaryClosingDay(localization: LocalizationData, wage: IWage): string | undefined {
    if (wage.salaryClosingDay) {
      const day = LocalizationService.t<string>(localization, (LocalizationPath as any)[`Day${wage.salaryClosingDay}`] as string[])
      return LocalizationService.t<string>(localization, LocalizationPath.DayInMonth, [day])
    }
    return undefined
  }

  /**
   * Returns salary pay day formatted to string with added date decoration
   * @param vacancy
   * @returns
   */
  static getSalaryPaymentDay(localization: LocalizationData, wage: IWage): string | undefined {
    if (wage.salaryPaymentDay) {
      const day = LocalizationService.t<string>(localization, (LocalizationPath as any)[`Day${wage.salaryPaymentDay}`] as string[])
      return LocalizationService.t<string>(localization, LocalizationPath.DayInMonth, [day])
    }
    return undefined
  }

  /**
   * @param wage
   * @returns
   */
  static getFormattedWage(
    localization: LocalizationData,
    wage: IWage,
    textSeparator: string = '~',
    textPeriodSeparator: string = '/',
    quantityPrefix: string = ''
  ): string {
    let formattedMessage = ``
    if (!wage.amount && !wage.amountMaximum) {
      return formattedMessage
    } else if (wage.amount === wage.amountMaximum) {
      const amount = Math.round(wage.amount!)
      formattedMessage = `${quantityPrefix}${amount.toLocaleString()}`
    } else if (!!wage.amount && !!wage.amountMaximum) {
      const amount = Math.round(wage.amount!)
      const amountMaximum = Math.round(wage.amountMaximum)
      formattedMessage += `${quantityPrefix}${amount.toLocaleString()}${textSeparator}${amountMaximum.toLocaleString()}`
    } else if (!!wage.amount) {
      const amount = Math.round(wage.amount!)
      formattedMessage += LocalizationService.t<string>(localization, LocalizationPath.FromSalary, [
        `${quantityPrefix}${amount.toLocaleString()}`,
      ])
    } else {
      const amountMaximum = Math.round(wage.amountMaximum!)
      formattedMessage += LocalizationService.t<string>(localization, LocalizationPath.UpToSalary, [
        `${quantityPrefix}${amountMaximum.toLocaleString()}`,
      ])
    }
    const paymentPeriod = wage.paymentPeriod
    formattedMessage += PaymentPeriodGetters.getWageLabelFriendlyName(paymentPeriod, textPeriodSeparator)
    return formattedMessage
  }

  /**
   * Returns an array of wages based on priority
   * @param wages
   * @returns
   */
  static getPrioritizedWage(wages: IWage[]): IWage {
    const map: Record<number, IWage[]> = {}

    // Create a record with the paymentPeriod id as the key to easily categorized each wage via paymentPeriod
    wages.forEach((e) => {
      if (map[e.paymentPeriod?.id!]) map[e.paymentPeriod?.id!].push(e)
      else map[e.paymentPeriod?.id!] = [e]
    })

    // Get all keys which is equivalent to all of the paymentPeriod in the passed wage array
    const keys = Object.keys(map).map((e) => parseInt(e))

    // Get the one with the highest priority. In this case the lower the paymentPeriod id the higher the priority is
    // hourly > daily > monthly > yearly
    const prioritizedPaymentPeriod = Math.min(...keys)
    const prioritizedWages = map[prioritizedPaymentPeriod]

    // Get the lowest amount in all the wages with the highest priority of paymentPeriod id
    // We have a ternary operator inside to avoid passing an undefined or null to the min operation
    const amount = Math.min(...prioritizedWages.map((e) => (!!e.amount ? e.amount : 0)))

    // Get the highest amountMaximum in all the wages with the highest priority of paymentPeriod id
    // We have a ternary operator inside to avoid passing an undefined or null to the max operation
    const amountMaximum = Math.max(...prioritizedWages.map((e) => (!!e.amountMaximum ? e.amountMaximum : 0)))

    return {
      paymentPeriod: prioritizedWages[0].paymentPeriod,

      // If the amount is 0 we can say that the lowest amount was undefined based on the ternary operation above
      amount: amount === 0 ? undefined : amount,

      // If the amountMaximum is 0 we can say that the highest amount was undefined based on the ternary operation above
      amountMaximum: amountMaximum === 0 ? undefined : amountMaximum,
    }
  }
}
