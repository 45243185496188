import User from '@/modules/user/gateways/user'
import Auth from '~/modules/auth/gateways/auth'
import Common from '~/modules/app/gateways/common'
import Contact from '~/modules/app/gateways/contact'
import Candidate from '~/modules/user/gateways/candidate'
import Language from '~/modules/language/gateways/language'
import Vacancy from '~/modules/vacancy/gateways/vacancy'
import Application from '~/modules/application/gateways/application'
import Geolocation from '~/modules/location/gateways/geolocation'
import Benefit from '~/modules/app/gateways/benefit'
import Onboarding from '~/modules/onboarding/gateways/onboarding'
import FileStorage from '~/modules/app/gateways/file.storage'
import FilterItems from '~/modules/app/gateways/filter-items'
import Location from '~/modules/app/gateways/location'
import CMS from '~/modules/cms/gateways/cms'
import Localization from '~/modules/localization/gateways/localization'
import { APIClient } from '@tokyojob/frontend-core'
import Resume from '~/modules/resume/gateways/resume'

export default class BaseGateway {
  apiClient: APIClient
  auth: Auth
  user: User
  common: Common
  fileStorage: FileStorage
  contact: Contact
  candidate: Candidate
  onboarding: Onboarding
  language: Language
  vacancy: Vacancy
  application: Application
  geolocation: Geolocation
  benefit: Benefit
  filterItems: FilterItems
  location: Location
  resume: Resume
  cms: CMS
  localization: Localization

  constructor(apiClient: APIClient) {
    this.apiClient = apiClient
    this.auth = new Auth(apiClient)
    this.user = new User(apiClient)
    this.common = new Common(apiClient)
    this.contact = new Contact(apiClient)
    this.candidate = new Candidate(apiClient)
    this.onboarding = new Onboarding(apiClient)
    this.language = new Language(apiClient)
    this.vacancy = new Vacancy(apiClient)
    this.application = new Application(apiClient)
    this.geolocation = new Geolocation(apiClient)
    this.benefit = new Benefit(apiClient)
    this.resume = new Resume(apiClient)
    this.fileStorage = new FileStorage(apiClient)
    this.filterItems = new FilterItems(apiClient)
    this.location = new Location(apiClient)
    this.cms = new CMS(apiClient)
    this.localization = new Localization(apiClient)
  }
}
