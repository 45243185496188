import { ICountry, ICountryi18n } from '../../../../../../../core/src'
import { IFormInputData } from '../../../html/forms/inputs/interfaces/form-input-data'

export class CountryEntity implements IFormInputData {
  private _props: ICountry

  constructor(props: ICountry) {
    this._props = props
  }

  get props(): ICountry {
    return this._props
  }

  /**
   * Unique identifier used in IFormInputData
   */
  get value(): number | undefined {
    return this.id
  }

  /**
   * Name used in IFormInputData
   */
  get label(): string {
    return this.name
  }

  /**
   * Unique identifier of the country.
   */
  get id(): number | undefined {
    return this.props.id
  }

  set id(value: number | undefined) {
    this.props.id = value
  }

  /**
   * The country's name.
   */
  get name(): string {
    // In case of reactivity issues, remove and figure out a workaround.
    if (this._props.i18n!.length === 0) return ''
    return this.props.i18n![0].name!
  }

  set name(value: string) {
    this.props.i18n![0].name = value
  }

  /**
   * A Country's ISO 3166 code. 2 digits.
   * @example "AF", "BD", "JP".
   */
  get countryCode2Digits(): string {
    return this.props.countryCode2Digits!
  }

  set countryCode2Digits(value: string) {
    this.props.countryCode2Digits = value
  }

  /**
   * A Country's ISO 3166 code. 3 digits.
   * @example "AFG", "BGD", "JPN ".
   */
  get countryCode3Digits(): string {
    return this.props.countryCode3Digits!
  }

  set countryCode3Digits(value: string) {
    this.props.countryCode3Digits = value
  }

  get i18n(): ICountryi18n[] {
    return this.props.i18n!
  }
}
