/**
 * Levels of Japanese communication (listening and speaking).
 */
 export enum JapaneseSpeakingSkillId {
  Zero = 7,
  N5 = 6,
  N4 = 5,
  N3 = 4,
  N2 = 3,
  N1 = 2,
  Native = 1
}
