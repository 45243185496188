import { IGuestCandidateJWT } from '../interfaces/i-guest-candidate-jwt';
import { IUserJWT } from '../interfaces/i-user-jwt';

/**
 * Validates and decodes strings into JWTs.
 * @remarks While this can be used in the frontend, take into account that using it there does not guarantee that the
 * token has not been tampered with. That check can only be performed in the frontend, by using the same JWT signing
 * signature/password. If we try to obtain sensitive data using an invalid token, the API will reject the call.
 */
export class JWTDecoder {
  /**
   *
   * @param value
   * @returns
   */
  decode(value: string): IUserJWT | undefined {
    // TODO: get rid of the double try catch. Had to hurry up due to atob causing errors.
    try {
      const decodedTokenStr = atob(value.split('.')[1]);
      return JSON.parse(decodedTokenStr);
    } catch (err) {
      try {
        const decodedTokenStr = Buffer.from(value.split('.')[1], 'base64').toString();
        return JSON.parse(decodedTokenStr);
      } catch {
        return undefined;
      }
    }
  }

  /**
   * Whether the provided token is valid or not. Valid just means that the token was successfully decoded, not that the token has not been tampered with.
   * @param value
   * @returns
   */
  isValidToken(value: string): boolean {
    const token = this.decode(value);
    return !!token;
  }

  /**
   * Whether the provided value is an expired token or not. Invalid tokens will return false.
   * @param value
   * @returns
   */
  isExpired(value: string): boolean {
    const token = this.decode(value);
    if (!token) return true;
    const expiredOn = token.exp;
    // .now returns miliseconds, but token.exp is using seconds.
    // We do not need to be too precise here, a difference of a few seconds is harmless.
    const currentTime = Math.round(Date.now() / 1000);
    return currentTime > expiredOn;
  }

  decodeGuestCandidateToken(value: string): IGuestCandidateJWT | null {
    const decodedTokenStr = Buffer.from(value.split('.')[1], 'base64').toString();
    const decodedToken = JSON.parse(decodedTokenStr);
    return decodedToken as IGuestCandidateJWT;
  }

  /**
   * Whether the provided value is an expired token or not. Invalid tokens will return false.
   * @param value
   * @returns
   */
  guestCandidateTokenIsExpire(value: string): boolean {
    const decodedToken = this.decodeGuestCandidateToken(value);
    if (!decodedToken) return true;
    const expiredOn = decodedToken.exp;
    // .now returns miliseconds, but token.exp is using seconds.
    // We do not need to be too precise here, a difference of a few seconds is harmless.
    const currentTime = Math.round(Date.now() / 1000);
    return currentTime > expiredOn;
  }
}
