import { MetaPropertyCharset, MetaPropertyEquiv, MetaPropertyMicrodata, MetaPropertyName, MetaPropertyProperty } from 'vue-meta'
import { LocalizationData, LocalizationPath } from '../../../../../../../core/src'
import { LanguageLocale } from '../../../../../../../core/src/models/language/constants/language-locale.enum'
import { LocalizationService } from '../../../language/localization-service'
import { MetaTagFactory } from '../meta-tag.factory'

/**
 * Agent login meta tags.
 */
export class AgentLoginMetaFactory {
  static metaTags(
    localization: LocalizationData,
    locale: LanguageLocale
  ): (MetaPropertyCharset | MetaPropertyEquiv | MetaPropertyName | MetaPropertyMicrodata | MetaPropertyProperty)[] {
    const pageTitle: string = LocalizationService.t<string>(localization, LocalizationPath.AgentLoginPageMetaTagTitle)
    const descriptionString: string = LocalizationService.t<string>(
      localization,
      LocalizationPath.AgentLoginPageMetaTagDescription
    )
    const keywordsArray: string[] = LocalizationService.t<string[]>(localization, LocalizationPath.AgentLoginPageMetaTagKeywords)
    const titles = MetaTagFactory.titleTags(pageTitle)
    const siteName = MetaTagFactory.siteName()
    const descriptions = MetaTagFactory.descriptionTags(descriptionString)
    const keywords = MetaTagFactory.keywords(localization, keywordsArray)
    const locales = MetaTagFactory.localeTags(locale)
    const robots = MetaTagFactory.nonindexedRobots()
    return [...titles, siteName, ...descriptions, keywords, ...locales, robots]
  }
}
