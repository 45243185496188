import { BenefitUnit, BenefitUnitInfo } from '../network/schema/models'

export interface IBenefitUnitProps extends BenefitUnit {}

export default class BenefitUnitEntity {
  private _props: IBenefitUnitProps

  constructor(props: IBenefitUnitProps) {
    this._props = props
  }

  get props(): IBenefitUnitProps {
    return this._props
  }

  get id(): string {
    return this.props.id
  }

  get order(): string | undefined {
    return this.props.order
  }

  get informations(): BenefitUnitInfo[] {
    if (!this.props.i18n) {
      // Ugly fix, we should standarize our translation data to all be i18n.
      this.props.i18n = (this.props as any).info
    }
    return this.props.i18n || []
  }

  /**
   * Gets the name of the benefit unit in the current locale.
   */
  get name(): string {
    if (this.informations.length === 0) return ''
    return this.informations[0].name
  }
}

export const EmptyBenefitUnitPropsFactory = (
  props?: Partial<IBenefitUnitProps>
): IBenefitUnitProps => ({
  id: '',
  ...props
})

export const BenefitUnitPropsFactory = (
  props?: Partial<IBenefitUnitProps>
): IBenefitUnitProps => ({
  id: '',
  ...props
})

export const EmptyBenefitUnitEntityFactory = (
  props?: Partial<IBenefitUnitProps>
): BenefitUnitEntity => {
  return new BenefitUnitEntity(EmptyBenefitUnitPropsFactory(props))
}

export const BenefitUnitEntityFactory = (
  props?: Partial<IBenefitUnitProps>
): BenefitUnitEntity => {
  return new BenefitUnitEntity(BenefitUnitPropsFactory(props))
}
