import { DateFormatter } from "../../../../../core/src/utils/date/date-formatter"

/**
 * Today's date
 */
const dateFormatter = new DateFormatter()
const today = new Date()
const todayStr = dateFormatter.getYYYYMMDDString(today)!
const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
const yesterdayStr = dateFormatter.getYYYYMMDDString(yesterday)!
const minus18Years = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate())
const minus18YearsStr = dateFormatter.getYYYYMMDDString(minus18Years)!
const minus18YearsPlus1 = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate() +1)
const minus18YearsPlus1Str = dateFormatter.getYYYYMMDDString(minus18YearsPlus1)!

/**
 * Default min date value for the date picker
 */
export const DefaultDateValues = {
  minDate: '1920-01-01',
  minus18Years: minus18Years,
  minus18YearsStr: minus18YearsStr,
  minus18YearsPlus1: minus18YearsPlus1,
  minus18YearsPlus1Str: minus18YearsPlus1Str,
  today: today,
  todayStr: todayStr,
  yesterday: yesterday,
  yesterdayStr: yesterdayStr
}
