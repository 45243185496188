import { GatewayBase } from '@/base/gateways/base'
import { IFormInputData } from '@tokyojob/frontend-core'
import { VacancyLocationGetRequest } from '../network/requests/get-vacancy-locations'

/**
 * API Gateway to fetch items inside of a filter component.
 */
export default class FilterItems extends GatewayBase {
  /**
   * Attempts to fetch the possible vacancy titles from the API based on the passed text string.
   * @param text The current text that is in the input field.
   */
  async GetLocations(text: string): Promise<IFormInputData[]> {
    const items = await this.apiClient.request<IFormInputData[]>(new VacancyLocationGetRequest(text))
    return items
  }
}
