import { MetaPropertyCharset, MetaPropertyEquiv, MetaPropertyMicrodata, MetaPropertyName, MetaPropertyProperty } from 'vue-meta'
import { IVacancy, LanguageLocale, LocalizationData, LocalizationPath, VacancyGetters } from '../../../../../../core/src'
import { RobotsTagOptions } from '../constants/robots-tag-options'
import { MetaTagLocaleFactory } from './meta-tag-locale.factory'
import { LocalizationService } from '../../language/localization-service'

// Meta tag names
const description = 'description'
const keywords = 'keywords'
const contentLanguage = 'content-language'
const robots = 'robots'
const appleTitle = 'apple-mobile-web-app-title'
const twitterHandle = '@peregreworks'

const twitterTitle = 'twitter:title'
const twitterDescription = 'twitter:description'
const twitterImage = 'twitter:image'
const twitterSite = 'twitter:site'
const twitterCreator = 'twitter:creator'

const ogLocale = 'og:locale'
const ogTitle = 'og:title'
const ogSiteName = 'og:site_name'
const ogType = 'og:type'
const ogImage = 'og:image'
const ogDescription = 'og:description'

export class MetaTagFactory {
  /**
   * Generates a basic tag, includes all required properties to override nuxt meta tags as well.
   * @param name
   * @param content
   * @param extraProps
   * @returns
   */
  static genericTag(
    name: string,
    content: string,
    extraProps?: Record<string, string>
  ): MetaPropertyCharset | MetaPropertyEquiv | MetaPropertyName | MetaPropertyMicrodata | MetaPropertyProperty {
    return { hid: name, name, property: name, content, ...extraProps }
  }

  static title(
    content: string
  ): MetaPropertyCharset | MetaPropertyEquiv | MetaPropertyName | MetaPropertyMicrodata | MetaPropertyProperty {
    return MetaTagFactory.genericTag(ogTitle, content)
  }

  static appleTitle(
    content: string
  ): MetaPropertyCharset | MetaPropertyEquiv | MetaPropertyName | MetaPropertyMicrodata | MetaPropertyProperty {
    return MetaTagFactory.genericTag(appleTitle, content)
  }

  static twitterTitle(
    content: string
  ): MetaPropertyCharset | MetaPropertyEquiv | MetaPropertyName | MetaPropertyMicrodata | MetaPropertyProperty {
    return MetaTagFactory.genericTag(twitterTitle, content)
  }

  /**
   * Returns all of the different title meta tags. Convenient to use instead of calling all of them separately.
   * @param content
   * @returns
   */
  static titleTags(
    content: string,
  ): (MetaPropertyCharset | MetaPropertyEquiv | MetaPropertyName | MetaPropertyMicrodata | MetaPropertyProperty)[] {
    return [MetaTagFactory.title(content), MetaTagFactory.appleTitle(content), MetaTagFactory.twitterTitle(content)]
  }

  static siteName(): MetaPropertyCharset | MetaPropertyEquiv | MetaPropertyName | MetaPropertyMicrodata | MetaPropertyProperty {
    return MetaTagFactory.genericTag(ogSiteName, 'Peregre Works')
  }

  static description(
    content: string
  ): MetaPropertyCharset | MetaPropertyEquiv | MetaPropertyName | MetaPropertyMicrodata | MetaPropertyProperty {
    return MetaTagFactory.genericTag(description, content)
  }

  static ogDescription(
    content: string
  ): MetaPropertyCharset | MetaPropertyEquiv | MetaPropertyName | MetaPropertyMicrodata | MetaPropertyProperty {
    return MetaTagFactory.genericTag(ogDescription, content)
  }

  static twitterDescription(
    content: string
  ): MetaPropertyCharset | MetaPropertyEquiv | MetaPropertyName | MetaPropertyMicrodata | MetaPropertyProperty {
    return MetaTagFactory.genericTag(twitterDescription, content)
  }

  /**
   * Returns all of the different description meta tags. Convenient to use instead of calling all of them separately.
   * @param content
   * @returns
   */
  static descriptionTags(
    content: string
  ): (MetaPropertyCharset | MetaPropertyEquiv | MetaPropertyName | MetaPropertyMicrodata | MetaPropertyProperty)[] {
    return [
      MetaTagFactory.description(content),
      MetaTagFactory.ogDescription(content),
      MetaTagFactory.twitterDescription(content),
    ]
  }

  static keywords(localization: LocalizationData, 
    keywordsList: string[]
  ): MetaPropertyCharset | MetaPropertyEquiv | MetaPropertyName | MetaPropertyMicrodata | MetaPropertyProperty {
    const genericKeywords = LocalizationService.t<string>(localization, LocalizationPath.genericMetaTagKeywords);
    const content = keywordsList ? keywordsList : []
    const finalKeywords = [...genericKeywords, ...content].join(', ')
    return MetaTagFactory.genericTag(keywords, finalKeywords)
  }

  static vacancyDetailKeywords(localization: LocalizationData, 
    vacancy: IVacancy
  ): MetaPropertyCharset | MetaPropertyEquiv | MetaPropertyName | MetaPropertyMicrodata | MetaPropertyProperty {
    const genericKeywords = LocalizationService.t<string>(localization, LocalizationPath.genericMetaTagKeywords);
    const industry = VacancyGetters.getIndustryString(vacancy)
    const sector = VacancyGetters.getSectorString(vacancy)
    const prefecture = VacancyGetters.getPrefecture(vacancy)
    const city = VacancyGetters.getCity(vacancy)
    const jobKeywords = [prefecture, city, industry]
    if (sector) jobKeywords.push(sector)
    const finalKeywords = [...genericKeywords, ...jobKeywords].join(', ')
    return MetaTagFactory.genericTag(keywords, finalKeywords)
  }

  static locale(
    locale: LanguageLocale
  ): MetaPropertyCharset | MetaPropertyEquiv | MetaPropertyName | MetaPropertyMicrodata | MetaPropertyProperty {
    const content = MetaTagLocaleFactory.localeToMetaLocale(locale)
    return MetaTagFactory.genericTag(ogLocale, content)
  }

  static contentLanguage(
    locale: LanguageLocale
  ): MetaPropertyCharset | MetaPropertyEquiv | MetaPropertyName | MetaPropertyMicrodata | MetaPropertyProperty {
    const content = MetaTagLocaleFactory.localeToMetacontentLanguage(locale)
    return MetaTagFactory.genericTag(contentLanguage, content, { httpEquiv: contentLanguage })
  }

  /**
   * Returns all of the different locale meta tags. Convenient to use instead of calling all of them separately.
   * @param content
   * @returns
   */
  static localeTags(
    locale: LanguageLocale
  ): (MetaPropertyCharset | MetaPropertyEquiv | MetaPropertyName | MetaPropertyMicrodata | MetaPropertyProperty)[] {
    return [MetaTagFactory.locale(locale), MetaTagFactory.contentLanguage(locale)]
  }

  static otherLocaleTags(
    locale: LanguageLocale
  ): (MetaPropertyCharset | MetaPropertyEquiv | MetaPropertyName | MetaPropertyMicrodata | MetaPropertyProperty)[] {
    return [MetaTagFactory.locale(locale), MetaTagFactory.contentLanguage(locale)]
  }

  static indexedRobots():
    | MetaPropertyCharset
    | MetaPropertyEquiv
    | MetaPropertyName
    | MetaPropertyMicrodata
    | MetaPropertyProperty {
    const content = [RobotsTagOptions.follow, RobotsTagOptions.index].join(', ')
    return MetaTagFactory.genericTag(robots, content)
  }

  static nonindexedRobots():
    | MetaPropertyCharset
    | MetaPropertyEquiv
    | MetaPropertyName
    | MetaPropertyMicrodata
    | MetaPropertyProperty {
    const content = [RobotsTagOptions.noFollow, RobotsTagOptions.noIndex].join(', ')
    return MetaTagFactory.genericTag(robots, content)
  }

  static type(
    typeStr: string
  ): MetaPropertyCharset | MetaPropertyEquiv | MetaPropertyName | MetaPropertyMicrodata | MetaPropertyProperty {
    return MetaTagFactory.genericTag(ogType, typeStr)
  }

  static image(
    imageUrl: string
  ): MetaPropertyCharset | MetaPropertyEquiv | MetaPropertyName | MetaPropertyMicrodata | MetaPropertyProperty {
    return MetaTagFactory.genericTag(ogImage, imageUrl)
  }

  static twitterImage(
    imageUrl: string
  ): MetaPropertyCharset | MetaPropertyEquiv | MetaPropertyName | MetaPropertyMicrodata | MetaPropertyProperty {
    return MetaTagFactory.genericTag(twitterImage, imageUrl)
  }

  static twitterSite():
    | MetaPropertyCharset
    | MetaPropertyEquiv
    | MetaPropertyName
    | MetaPropertyMicrodata
    | MetaPropertyProperty {
    return MetaTagFactory.genericTag(twitterSite, twitterHandle)
  }

  static twitterCreator():
    | MetaPropertyCharset
    | MetaPropertyEquiv
    | MetaPropertyName
    | MetaPropertyMicrodata
    | MetaPropertyProperty {
    return MetaTagFactory.genericTag(twitterCreator, twitterHandle)
  }

  /**
   * Returns the two tags for our twitter handle
   * @param content
   * @returns
   */
  static twitterTags(): (
    | MetaPropertyCharset
    | MetaPropertyEquiv
    | MetaPropertyName
    | MetaPropertyMicrodata
    | MetaPropertyProperty
  )[] {
    return [MetaTagFactory.twitterSite(), MetaTagFactory.twitterCreator()]
  }

  /**
   * Returns all of the different image meta tags. Convenient to use instead of calling all of them separately.
   * @param content
   * @returns
   */
  static imageTags(
    imageUrl: string
  ): (MetaPropertyCharset | MetaPropertyEquiv | MetaPropertyName | MetaPropertyMicrodata | MetaPropertyProperty)[] {
    return [MetaTagFactory.image(imageUrl), MetaTagFactory.twitterImage(imageUrl)]
  }

  /**
   * TEMPORARY TEST FUNCTION TO A B TEST TWO OPTIONS FOR META TAGS IN MULTILINGUAL. REMOVE WHEN DONE.
   * @param content
   * @returns
   */
  static imageTags1314Test(
    imageUrl: string
  ): (MetaPropertyCharset | MetaPropertyEquiv | MetaPropertyName | MetaPropertyMicrodata | MetaPropertyProperty)[] {
    const imageUrl1 = 'https://tokyojob-public.s3.ap-northeast-1.amazonaws.com/public/job-test/job_1314_image_01_en.jpg'
    const imageUrl2 = 'https://tokyojob-public.s3.ap-northeast-1.amazonaws.com/public/job-test/job_1314_image_02_pt.jpg'
    const imageUrl3 = 'https://tokyojob-public.s3.ap-northeast-1.amazonaws.com/public/job-test/job_1314_image_03_cn.jpg'
    const imageUrl4 = 'https://tokyojob-public.s3.ap-northeast-1.amazonaws.com/public/job-test/job_1314_image_04_es.jpg'
    const tagArray = [
      MetaTagFactory.image(imageUrl),
      MetaTagFactory.image(imageUrl1),
      MetaTagFactory.image(imageUrl2),
      MetaTagFactory.image(imageUrl3),
      MetaTagFactory.image(imageUrl4),
    ]
    tagArray[1].hid = 'Img1'
    tagArray[2].hid = 'Img2'
    tagArray[3].hid = 'Img3'
    tagArray[4].hid = 'Img4'
    return tagArray
  }

  /**
   * TEMPORARY TEST FUNCTION TO A B TEST TWO OPTIONS FOR META TAGS IN MULTILINGUAL. REMOVE WHEN DONE.
   * @param content
   * @returns
   */
  static imageTags1315Test(
    imageUrl: string
  ): (MetaPropertyCharset | MetaPropertyEquiv | MetaPropertyName | MetaPropertyMicrodata | MetaPropertyProperty)[] {
    const imageUrl1 = 'https://tokyojob-public.s3.ap-northeast-1.amazonaws.com/public/job-test/job_1315_image_01_en.jpg'
    const tagArray = [MetaTagFactory.image(imageUrl), MetaTagFactory.image(imageUrl1)]
    tagArray[1].hid = 'Img1'
    return tagArray
  }
}