import { IApp } from '@/types/nuxt'
import { IFilterableDataSetDictionary, LanguageLocale } from '@tokyojob/core'
import { LogType } from '@tokyojob/frontend-core'
/**
 * Fetches job categories in a hierarchical object.
 * @todo Move to frontend/core
 */
export default class JobCategoryHierarchyGetUsecase {
  /**
   * @param app IApp instance used to access the Gateway and Log services.
   */
  constructor(private readonly app: IApp) {}

  /**
   * Attempts to fetch the job categories from the API.
   */
  async execute(locale: LanguageLocale): Promise<IFilterableDataSetDictionary> {
    const { baseGateway, logService } = this.app
    try {
      const res = await baseGateway.common.FetchJobCategoryHierarchy(locale)
      if (!res) throw new Error()
      return res
    } catch (error) {
      await logService.handle({ type: LogType.Error, error: error as Error })
      return {}
    }
  }
}
