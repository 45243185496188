import {
  IApplicationStatusCandidate,
  IApplicationStatusCandidateI18n,
  IFilterableDataSetContextualDictionary,
} from '../../../models';
export class ApplicationCandidateStatusGetters {
  static getName(status: IApplicationStatusCandidate): string {
    const i18n = this.getI18n(status);
    if (!i18n) return '';
    return i18n.name!;
  }

  static getDescription(status: IApplicationStatusCandidate): string {
    const i18n = this.getI18n(status);
    if (!i18n) return '';
    return i18n.description!;
  }

  static getI18n(status: IApplicationStatusCandidate): IApplicationStatusCandidateI18n | undefined {
    if (!status || !status.i18n || !status.i18n.length) return undefined;
    return status.i18n[0];
  }
}
