/**
 * Date object helpers.
 */
export class DateFormatter {
  /**
   * Gets a Date object from the provided value. Mostly used to deal with funky Date implementations.
   * @param value
   * @returns
   */
  getDate(value: Date | string): Date | undefined {
    try {
      if (!value) return undefined;
      // Only doing this to see check if getFullYear triggers an error.
      const _ = (value as Date).getFullYear();
      return value as Date;
    } catch {
      return new Date(value);
    }
  }
  /**
   * Convert Date Object to a YYYY-MM-DD string for Input type="date" fields
   */
  getYYYYMMDDString(value: Date | undefined, separator: string = '-'): string | undefined {
    if (!value) return undefined;
    if (typeof value === 'string') value = new Date(value);
    const year = value.getFullYear();
    const month = (value.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is 0-indexed
    const day = value.getDate().toString().padStart(2, '0');

    const formattedString = `${year}${separator}${month}${separator}${day}`;
    return formattedString;
  }

  /**
   * Gets only the hour and minute portion of a time string.
   * @param timeStr
   * @returns
   */
  getHMFromString(timeStr: string | undefined): string {
    if (!timeStr) return ``;
    let formattedStr = timeStr;
    const lastColonIndex = formattedStr.lastIndexOf(':');
    if (lastColonIndex > 2) {
      return formattedStr.slice(0, lastColonIndex);
    }
    return timeStr;
  }

  /**
   * Gets only the hour and minute portion of a time string.
   * @param timeStr
   * @returns
   */
  getHMFromDate(timeDate: Date | undefined): string {
    if (!timeDate) return '';
    // Convert string to Date object if necessary
    if (typeof timeDate === 'string') {
      timeDate = new Date(timeDate as any);
    }

    // Pad the hours / minutes with leading zero if they are less than 10
    let hours = timeDate.getHours().toString().padStart(2, '0');
    let minutes = timeDate.getMinutes().toString().padStart(2, '0');

    let formattedStr = `${hours}:${minutes}`;
    return formattedStr;
  }

  /**
   *
   * @param date
   * @param fromUTCConvert
   * @returns
   */
  getJpFormattedDate(date: Date, fromUTCConvert: boolean = false): string {
    if (fromUTCConvert) date = this.fromUtcToJp(date);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const str = `${year}年${month}月${day}日`;
    return str;
  }

  /**
   *
   * @param date
   * @param fromUTCConvert
   * @returns
   */
  getJpFormattedDateTime(date: Date, fromUTCConvert: boolean = false): string {
    let str = this.getJpFormattedDate(date, fromUTCConvert);
    const hour = date.getHours();
    const minute = date.getMinutes();
    const hourMinute = `　${hour}時${minute}分`;
    str = `${str}${hourMinute}`;
    return str;
  }

  /**
   *
   * @param date
   * @returns
   */
  fromUtcToJp(date: Date): Date {
    // Golly, I sure hope no time saving issues mess with this
    const utcJpDifferenceInHours = 9;
    const minutesPerHour = 60;
    const secondsPerMinute = 60;
    const millisendsPerSecond = 1000;
    const timeToAdd = utcJpDifferenceInHours * minutesPerHour * secondsPerMinute * millisendsPerSecond;
    const newDate = new Date();
    newDate.setTime(date.getTime() + timeToAdd);
    return date;
  }

  /**
   *
   * @param date1
   * @param date2
   * @returns
   */
  yearsDifference(date1: Date, date2: Date): number {
    // Get the years difference
    let difference = date1.getFullYear() - date2.getFullYear();

    // If date1's month is less than date2's month or if the month is the same
    // but date1's day is less, then subtract a year
    if (date1.getMonth() < date2.getMonth() || (date1.getMonth() === date2.getMonth() && date1.getDate() < date2.getDate())) {
      difference--;
    }

    return difference;
  }

  getCurrentYear(): number {
    const date = new Date();
    return date.getFullYear();
  }
}
