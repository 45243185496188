/**
 * The pages that are being tracked. While we could automatically generate this from
 * the page's url, if we change a page name due to a typo or any other reason, it
 * will make it hard to keep of track of the events properly. Instead, we set them up here
 * for the specific pages we want to track. There are also instances where
 * we do not change pages, and instead render a different component.
 * @remarks The names should be kept simple and in English. The reasoning for this is that the analytics will
 * be checked by people that may not be all that good at either language, but can understand basic English words.
 * The disadvantage is that event and page names end up being longer.
 */
export enum AnalyticsCandidateMessengerPages {
  OnboardingWelcome = 'Onboarding.Welcome',
  OnboardingName = 'Onboarding.Name',
  OnboardingVisa = 'Onboarding.Visa',
  OnboardingContact = 'Onboarding.Contact',
  OnboardingPersonalInfo = 'Onboarding.PersonalInfo',
  OnboardingJapaneseSkills = 'Onboarding.JapaneseSkills',
  OnboardingJobStatus = 'Onboarding.JobStatus',
  // OnboardingFinish = 'Onboarding.Finish',
  MenuName = 'Menu.Name',
  MenuVisa = 'Menu.Visa',
  MenuContact = 'Menu.Contact',
  MenuPersonalInfo = 'Menu.PersonalInfo',
  MenuJapaneseSkills = 'Menu.JapaneseSkills',
  VacancyDetails = "VacancyDetails"
}
