/**
 * Levels of Japanese listening
 */
 export enum JapaneseListeningSkillId {
  Zero = 1,
  N5 = 2,
  N4 = 3,
  N3 = 4,
  N2 = 5,
  N1 = 6,
  Native = 7
}
