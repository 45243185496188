import { EnumDictionary } from '../../../utils/types/enum-dictionary';
import { ApplicationAgentSubStatusId } from './application-agent-substatus-id.enum';

/**
 * Fontawesome Icon name for each application substatus.
 * An empty string implies that the theme will depend on the parent status icon.
 * If both parent and child are empty, then the theme will be the default one.
 */
export const ApplicationSubStatusIcons: EnumDictionary<ApplicationAgentSubStatusId, string> = {
  [ApplicationAgentSubStatusId.NewApplicationWebGuest]: 'seedling',
  [ApplicationAgentSubStatusId.NewApplicationWebAccount]: '',
  [ApplicationAgentSubStatusId.NewApplicationIOS]: '',
  [ApplicationAgentSubStatusId.NewApplicationMessenger]: '',
  [ApplicationAgentSubStatusId.ReviewingInternalReview]: 'file-magnifying-glass',
  [ApplicationAgentSubStatusId.ReviewingOther]: 'file-circle-question',
  [ApplicationAgentSubStatusId.ContactingAttemptingContact]: 'phone',
  [ApplicationAgentSubStatusId.ContactingCouldNotGetInContactAfter3Attempts]: 'phone-missed',
  [ApplicationAgentSubStatusId.ContactingOther]: 'phone-volume',
  [ApplicationAgentSubStatusId.NotHiredBeforeInterviewVacancyFilled]: 'person-rays',
  [ApplicationAgentSubStatusId.NotHiredBeforeInterviewCandidateWithdrew]: 'person-circle-xmark',
  [ApplicationAgentSubStatusId.NotHiredBeforeInterviewInsufficientJapaneseLevel]: 'language',
  [ApplicationAgentSubStatusId.NotHiredBeforeInterviewInsufficientDocumentation]: 'person-arrow-down-to-line',
  [ApplicationAgentSubStatusId.NotHiredBeforeInterviewInvalidVisa]: 'user-large-slash',
  [ApplicationAgentSubStatusId.NotHiredBeforeInterviewCouldNotGetInContact]: 'phone-slash',
  [ApplicationAgentSubStatusId.NotHiredBeforeInterviewCandidateMissedTheInterview]: 'ghost',
  [ApplicationAgentSubStatusId.NotHiredBeforeInterviewOther]: 'person-circle-question',
  [ApplicationAgentSubStatusId.InterviewPreparationScheduling]: 'calendar-pen',
  [ApplicationAgentSubStatusId.InterviewPreparationScheduled]: 'calendar-star',
  [ApplicationAgentSubStatusId.InterviewPreparationOther]: 'calendar',
  [ApplicationAgentSubStatusId.InterviewReschedulingRescheduling]: 'calendar-lines-pen',
  [ApplicationAgentSubStatusId.InterviewReschedulingCandidateMissedInterview]: 'ghost',
  [ApplicationAgentSubStatusId.InterviewReschedulingOther]: 'calendars',
  [ApplicationAgentSubStatusId.InterviewCompletedReviewingInterviewResults]: 'person-circle-question',
  [ApplicationAgentSubStatusId.InterviewCompletedMovedToNextInterview]: 'calendar-plus',
  [ApplicationAgentSubStatusId.InterviewCompletedJobOfferDecided]: 'hand-holding-dollar',
  [ApplicationAgentSubStatusId.InterviewCompletedOther]: 'user-magnifying-glass',
  [ApplicationAgentSubStatusId.NotHiredAfterInterviewVacancyFilled]: 'person-rays',
  [ApplicationAgentSubStatusId.NotHiredAfterInterviewCandidateWithdrew]: 'thumbs-down',
  [ApplicationAgentSubStatusId.NotHiredAfterInterviewInsufficientJapaneseLevel]: 'language',
  [ApplicationAgentSubStatusId.NotHiredAfterInterviewInsufficientDocuments]: 'person-arrow-down-to-line',
  [ApplicationAgentSubStatusId.NotHiredAfterInterviewInvalidVisa]: 'user-large-slash',
  [ApplicationAgentSubStatusId.NotHiredAfterInterviewCouldNotGetInContact]: 'phone-slash',
  [ApplicationAgentSubStatusId.NotHiredAfterInterviewOther]: 'person-circle-question',
  [ApplicationAgentSubStatusId.OfferContactingCandidate]: 'phone-arrow-up-right',
  [ApplicationAgentSubStatusId.OfferPostOfferCandidateContact]: 'handshake-simple',
  [ApplicationAgentSubStatusId.OfferEmploymentPaperworkPreparation]: 'file-pen',
  [ApplicationAgentSubStatusId.OfferEmploymentPaperworkDone]: 'file-check',
  [ApplicationAgentSubStatusId.OfferOther]: 'business-time',
  [ApplicationAgentSubStatusId.EmploymentStarted]: 'stars',
  [ApplicationAgentSubStatusId.EmploymentCandidateWithdrewAfterEmployment]: 'person-through-window',
  [ApplicationAgentSubStatusId.EmploymentFiredOrQuitAfterEmployment]: 'hand',
  [ApplicationAgentSubStatusId.EmploymentOther]: 'circle-question',
  [ApplicationAgentSubStatusId.CandidateWithdrewAnotherOffer]: 'right-from-bracket',
  [ApplicationAgentSubStatusId.CandidateWithdrewNotInterestedInThePositionAnymore]: 'right-from-bracket',
  [ApplicationAgentSubStatusId.CandidateWithdrewLocationTooFarAway]: 'right-from-bracket',
  [ApplicationAgentSubStatusId.CandidateWithdrewJobNotWhatWasExpected]: 'right-from-bracket',
  [ApplicationAgentSubStatusId.CandidateWithdrewOther]: 'right-from-bracket',
  [ApplicationAgentSubStatusId.Memo]: ''
};
