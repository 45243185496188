import { ContactMethod } from '../network/schema/models/index'
import { ContactMethodInfo } from '../network/schema/models/contact.method'

export interface IContactMethodProps extends ContactMethod {}

export default class ContactMethodEntity {
  private _props: IContactMethodProps

  constructor(props: IContactMethodProps) {
    this._props = props
  }

  get props(): IContactMethodProps {
    return this._props
  }

  get id(): number {
    return this.props.id
  }

  get slug(): string {
    return this.props.slug || ''
  }

  set slug(value: string) {
    this.props.slug = value
  }

  get informations(): ContactMethodInfo[] {
    return this.props.informations
  }

  get name(): string {
    return this.informations[0].name
  }

  getByLanguage(locale: string): ContactMethodInfo {
    const infos = this._props.informations.filter((information) => {
      return information.locale === locale
    })
    return infos[0] || {}
  }

  getJapanese(): ContactMethodInfo {
    return this.getByLanguage('JA-jp')
  }
}

export const EmptyContactMethodPropsFactory = (
  props?: Partial<IContactMethodProps>
): IContactMethodProps => ({
  id: 0,
  slug: '',
  informations: [],
  ...props
})

export const ContactMethodPropsFactory = (
  props?: Partial<IContactMethodProps>
): IContactMethodProps => ({
  id: 0,
  slug: '',
  informations: [],
  ...props
})

export const EmptyContactMethodEntityFactory = (
  props?: Partial<IContactMethodProps>
): ContactMethodEntity => {
  return new ContactMethodEntity(EmptyContactMethodPropsFactory(props))
}

export const ContactMethodEntityFactory = (
  props?: Partial<IContactMethodProps>
): ContactMethodEntity => {
  return new ContactMethodEntity(ContactMethodPropsFactory(props))
}
