import { CandidateVisaTypeDto, ICandidateVisa, IContact, UserContactsDto } from "../../../../../../core/src"

/**
 * Generates Dtos based off of different models.
 */
export class CandidateDtoTranslator {
  /**
   * Creates a new DTO required to save a candidate's visa changes.
   * @param candidateVisa
   * @returns
   */
  candidateVisaToDto(candidateVisa: ICandidateVisa): CandidateVisaTypeDto {
    let visaTypeId = undefined
    if (candidateVisa.visaType) {
      visaTypeId = candidateVisa.visaType.id
    }
    let visaSubTypeId = undefined
    if (candidateVisa.visaSubType) {
      visaSubTypeId = candidateVisa.visaSubType.id
    }
    const dto = {
      visaTypeId: visaTypeId!,
      visaSubTypeId: visaSubTypeId!,
    }
    return dto
  }

  /**
   * Creates a new DTO required to save a candidate's contact information changes.
   * @param contacts 
   * @returns 
   */
  contactsToUserContactDto(contacts: IContact[]): UserContactsDto {
    const dto = new UserContactsDto()
    dto.contacts = contacts
    return dto
  }
}
