/**
 * The candidate's sex, as specified in an official document like a residence card.
 * This has to match the Gender field as saved in the database, with the main difference that
 * this is limited by what the "Sex" field in a residence card permits.
 */
export enum CandidateSex {
  Male = 'male',
  Female = 'female',
  Unknown = 'unkown'
}
