import { LanguageLocale } from "./language-locale.enum"

/**
 * Levels of Japanese ability.
 */
interface ILanguageEntry {
  cert: string
  USen: string
  JAjp: string
  ZHcn: string
}
LanguageLocale.JAjp

interface ILanguageRecord {
  [key: string]:ILanguageEntry
}

export const LanguageData: Record<string, ILanguageRecord> = {
  listening: {
    1: { cert: 'Zero', USen: 'Cannot understand', JAjp: '日本語は不要', ZHcn: '不需要日语' },
    2: { cert: 'N5', USen: 'Can understand greetings', JAjp: '挨拶程度', ZHcn: '打招呼程度' },
    3: { cert: 'N4', USen: 'Basic conversation', JAjp: '初級会話', ZHcn: '初级会话' },
    4: { cert: 'N3', USen: 'Daily conversation', JAjp: '日常会話', ZHcn: '日常会话' },
    5: { cert: 'N2', USen: 'Business level', JAjp: 'ビジネスレベル', ZHcn: '商务水平' },
    6: { cert: 'N1', USen: 'Almost native', JAjp: '準ネイティブ', ZHcn: '准母语' },
    7: { cert: 'Native', USen: 'Full domain of the language', JAjp: 'ネイティブ', ZHcn: '母语' },
  },
  speaking: {
    7: { cert: 'Zero', USen: 'Cannot speak in Japanese', JAjp: '日本語は不要', ZHcn: '不需要日语' },
    6: { cert: 'N5', USen: 'Can only say greetings', JAjp: '挨拶程度', ZHcn: '打招呼程度' },
    5: { cert: 'N4', USen: 'Basic conversation', JAjp: '初級会話', ZHcn: '初级会话' },
    4: { cert: 'N3', USen: 'Daily conversation', JAjp: '日常会話', ZHcn: '日常会话' },
    3: { cert: 'N2', USen: 'Business level', JAjp: 'ビジネスレベル', ZHcn: '商务水平' },
    2: { cert: 'N1', USen: 'Almost native', JAjp: '準ネイティブ', ZHcn: '准母语' },
    1: { cert: 'Native', USen: 'Full domain of the language', JAjp: 'ネイティブ', ZHcn: '母语' },
  },
  reading: {
    8: { cert: 'Zero', USen: 'Cannot read in Japanese', JAjp: '読めなくてもいい', ZHcn: '不需要阅读能力' },
    1: { cert: 'Hiragana', USen: 'Hiragana', JAjp: '平仮名', ZHcn: '平假名' },
    2: { cert: 'Katakana', USen: 'Hiragana + Katakana', JAjp: '平仮名とカタカナ', ZHcn: '平假名和片假名' },
    3: { cert: 'N5', USen: 'Basic sentences and Kanji', JAjp: '漢字（小１レベル）', ZHcn: '汉字（小学1年级水平）' },
    4: { cert: 'N4', USen: 'Understand common language', JAjp: '漢字（小２〜３レベル）', ZHcn: '汉字（小学2-3年级水平）' },
    5: { cert: 'N3', USen: 'Good understanding', JAjp: '漢字（小４〜５レベル）', ZHcn: '汉字（小学4-5年级水平）' },
    6: { cert: 'N2', USen: 'Business level', JAjp: '漢字（小６〜中１レベル）', ZHcn: '汉字（小学6年级-初中1年级水平）' },
    7: { cert: 'N1', USen: 'Domain of the language', JAjp: '漢字（中２〜高１レベル）', ZHcn: '汉字（初中2年级-高中1年级水平）' },
  },
  writing: {
    1: { cert: 'Zero', USen: 'Cannot write in Japanese', JAjp: '書けなくてもいい', ZHcn: '不需要写作能力' },
    2: { cert: 'Hiragana', USen: 'Hiragana', JAjp: '平仮名', ZHcn: '平假名' },
    3: { cert: 'Katakana', USen: 'Hiragana + Katakana', JAjp: '平仮名とカタカナ', ZHcn: '平假名和片假名' },
    4: { cert: 'N5', USen: 'Basic sentences and Kanji', JAjp: '漢字（小１レベル）', ZHcn: '汉字（小学1年级水平）' },
    5: { cert: 'N4', USen: 'Understand common language', JAjp: '漢字（小２〜３レベル）', ZHcn: '汉字（小学2-3年级水平）' },
    6: { cert: 'N3', USen: 'Good understanding', JAjp: '漢字（小４〜５レベル）', ZHcn: '汉字（小学4-5年级水平）' },
    7: { cert: 'N2', USen: 'Business level', JAjp: '漢字（小６〜中１レベル）', ZHcn: '汉字（小学6年级-初中1年级水平）' },
    8: { cert: 'N1', USen: 'Domain of the language', JAjp: '漢字（中２〜高１レベル）', ZHcn: '汉字（初中2年级-高中1年级水平）' },
  },
};
