import { Type } from 'class-transformer';
import { IsArray, ValidateNested } from 'class-validator';
import { VacancySkillDto } from '../vacancy-skill.dto';

/**
 * The DTO that validates the skills section
 */
export class VacancySaveSkillsDto {
  /**
   * The required skills for this vacancy
   */
  @ValidateNested({ each: true })
  @Type(() => VacancySkillDto)
  requiredSkills: VacancySkillDto[];

  /**
   * The desired skills for this vacancy
   */
   @ValidateNested({ each: true })
   @Type(() => VacancySkillDto)
  desiredSkills: VacancySkillDto[];
}
