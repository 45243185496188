import { IApp } from '@/types/nuxt'
import { UserType } from '@tokyojob/core'
import { BaseUsecaseImpl, LogType } from '@tokyojob/frontend-core'

/**
 * Usecase to delete a candidate's resume.
 */
export class DeleteUsecase extends BaseUsecaseImpl {
  /**
   * @param app IApp instance used to access the Gateway and Log services.
   */
  constructor(private readonly app: IApp, userType: UserType) {
    super(app.state, userType)
  }

  /**
   * Deletes a resume.
   * @param id
   */
  async execute(id: number): Promise<boolean> {
    const { baseGateway, logService } = this.app
    try {
      await this.evaluateAuth()
      return baseGateway.resume.DeleteResume(id)
    } catch (error) {
      await logService.handle({ type: LogType.Error, error: error as Error })
      throw error
    }
  }
}
