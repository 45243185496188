import {
  IResume,
  IResumeEducation,
  IResumeJobHistory,
  IResumeQualification,
  JapanId,
  ResumeTranslationStatus,
} from '../../models';
import { ArrayComparer, ObjectComparer } from '../../utils';

export class ResumeComparer {
  static isFileInfoEqual(resume1: IResume, resume2: IResume): boolean {
    const properties: (keyof IResume)[] = ['title', 'jobType', 'companyName'];
    return ObjectComparer.arePropertiesEqual(resume1, resume2, properties);
  }

  static isAboutYouEqual(resume1: IResume, resume2: IResume): boolean {
    const properties: (keyof IResume)[] = [
      'lastName',
      'lastNameFurigana',
      'firstName',
      'firstNameFurigana',
      'gender',
      'nationalityCountry',
      'birthDate',
      'nativeLanguages',
      'japaneseSkillLevelSummary',
    ];
    return ObjectComparer.arePropertiesEqual(resume1, resume2, properties);
  }

  static isLastNameEqual(resume1: IResume, resume2: IResume): boolean {
    const properties: (keyof IResume)[] = ['lastName'];
    return ObjectComparer.arePropertiesEqual(resume1, resume2, properties);
  }

  static isLastNameFuriganaEqual(resume1: IResume, resume2: IResume): boolean {
    const properties: (keyof IResume)[] = ['lastNameFurigana'];
    return ObjectComparer.arePropertiesEqual(resume1, resume2, properties);
  }

  static isFirstNameEqual(resume1: IResume, resume2: IResume): boolean {
    const properties: (keyof IResume)[] = ['firstName'];
    return ObjectComparer.arePropertiesEqual(resume1, resume2, properties);
  }

  static isFirstNameFuriganaEqual(resume1: IResume, resume2: IResume): boolean {
    const properties: (keyof IResume)[] = ['firstNameFurigana'];
    return ObjectComparer.arePropertiesEqual(resume1, resume2, properties);
  }

  static isNativeLanguagesEqual(resume1: IResume, resume2: IResume): boolean {
    const properties: (keyof IResume)[] = ['nativeLanguages'];
    return ObjectComparer.arePropertiesEqual(resume1, resume2, properties);
  }

  static isJapaneseSkillLevelSummaryEqual(resume1: IResume, resume2: IResume): boolean {
    const properties: (keyof IResume)[] = ['japaneseSkillLevelSummary'];
    return ObjectComparer.arePropertiesEqual(resume1, resume2, properties);
  }

  static isAboutYouTranslationEqual(resume1: IResume, resume2: IResume): boolean {
    const properties: (keyof IResume)[] = [
      'lastNameFurigana',
      'firstNameFurigana',
      'nativeLanguagesJapanese',
      'japaneseSkillLevelSummaryJapanese',
    ];
    return ObjectComparer.arePropertiesEqual(resume1, resume2, properties);
  }

  static isContactAndAddressEqual(resume1: IResume, resume2: IResume): boolean {
    const properties: (keyof IResume)[] = [
      'currentResidenceCountry',
      'phoneNumber',
      'email',
      'zipCode',
      'prefecture',
      'city',
      'town',
      'addressLine',
      'buildingAndRoomNumber',
    ];
    return ObjectComparer.arePropertiesEqual(resume1, resume2, properties);
  }

  static isBuildingAndRoomNumberEqual(resume1: IResume, resume2: IResume): boolean {
    const properties: (keyof IResume)[] = ['buildingAndRoomNumber'];
    return ObjectComparer.arePropertiesEqual(resume1, resume2, properties);
  }

  static isContactAndAddressTranslationEqual(resume1: IResume, resume2: IResume): boolean {
    const properties: (keyof IResume)[] = ['addressLineJapanese', 'buildingAndRoomNumberJapanese', 'buildingAndRoomNumberKana'];
    return ObjectComparer.arePropertiesEqual(resume1, resume2, properties);
  }

  static isVisaEqual(resume1: IResume, resume2: IResume): boolean {
    const properties: (keyof IResume)[] = ['visaType', 'visaSubType', 'visaExpiryDate'];
    return ObjectComparer.arePropertiesEqual(resume1, resume2, properties);
  }

  static areEducationsEqual(resume1: IResume, resume2: IResume): boolean {
    const educationProperties: (keyof IResumeEducation)[] = [
      'schoolName',
      'educationLevel',
      'startYear',
      'startMonth',
      'finishYear',
      'finishMonth',
      'stillStudyingHere',
      'specialty',
    ];
    if (!resume1.education || !resume2.education) return false;
    return ArrayComparer.areArraysEqual(resume1.education, resume2.education, educationProperties);
  }

  static isSpecialtyEqual(resume1: IResumeEducation, resume2: IResumeEducation): boolean {
    const properties: (keyof IResumeEducation)[] = ['specialty'];
    return ObjectComparer.arePropertiesEqual(resume1, resume2, properties);
  }

  static areEducationsTranslationEqual(resume1: IResume, resume2: IResume): boolean {
    const educationTranslationProperties: (keyof IResumeEducation)[] = ['specialtyJapanese'];
    if (!resume1.education || !resume2.education) return false;
    return ArrayComparer.areArraysEqual(resume1.education, resume2.education, educationTranslationProperties);
  }

  static areWorkExperiencesEqual(resume1: IResume, resume2: IResume): boolean {
    const workExperienceProperties: (keyof IResumeJobHistory)[] = [
      'companyName',
      'jobDescription',
      'resumeContractType',
      'startYear',
      'startMonth',
      'finishYear',
      'finishMonth',
      'stillWorkingHere',
    ];
    if (!resume1.workExperience || !resume2.workExperience) return false;
    return ArrayComparer.areArraysEqual(resume1.workExperience, resume2.workExperience, workExperienceProperties);
  }

  static areWorkExperiencesTranslationEqual(resume1: IResume, resume2: IResume): boolean {
    const workExperienceTranslationProperties: (keyof IResumeJobHistory)[] = ['jobDescriptionJapanese'];
    if (!resume1.workExperience || !resume2.workExperience) return false;
    return ArrayComparer.areArraysEqual(resume1.workExperience, resume2.workExperience, workExperienceTranslationProperties);
  }

  static areQualificationsEqual(resume1: IResume, resume2: IResume): boolean {
    const qualificationProperties: (keyof IResumeQualification)[] = ['year', 'month', 'description'];
    if (!resume1.qualifications || !resume2.qualifications) return false;
    return ArrayComparer.areArraysEqual(resume1.qualifications, resume2.qualifications, qualificationProperties);
  }

  static areQualificationsTranslationEqual(resume1: IResume, resume2: IResume): boolean {
    const qualificationTranslationProperties: (keyof IResumeQualification)[] = ['descriptionJapanese'];
    if (!resume1.qualifications || !resume2.qualifications) return false;
    return ArrayComparer.areArraysEqual(resume1.qualifications, resume2.qualifications, qualificationTranslationProperties);
  }

  static areReasonToApplyEqual(resume1: IResume, resume2: IResume): boolean {
    const properties: (keyof IResume)[] = ['reasonsForApplying'];
    return ObjectComparer.arePropertiesEqual(resume1, resume2, properties);
  }

  static areReasonToApplyTranslationEqual(resume1: IResume, resume2: IResume): boolean {
    const properties: (keyof IResume)[] = ['reasonsForApplyingJapanese'];
    return ObjectComparer.arePropertiesEqual(resume1, resume2, properties);
  }

  static arePersonalRequestsEqual(resume1: IResume, resume2: IResume): boolean {
    const properties: (keyof IResume)[] = ['personalRequests'];
    return ObjectComparer.arePropertiesEqual(resume1, resume2, properties);
  }

  static arePersonalRequestsTranslationEqual(resume1: IResume, resume2: IResume): boolean {
    const properties: (keyof IResume)[] = ['personalRequestsJapanese'];
    return ObjectComparer.arePropertiesEqual(resume1, resume2, properties);
  }

  static isPictureEqual(resume1: IResume, resume2: IResume): boolean {
    const properties: (keyof IResume)[] = ['picture'];
    return ObjectComparer.arePropertiesEqual(resume1, resume2, properties);
  }

  static isFullyTranslated(resume: IResume): boolean {
    // should check all of the "XYZJapaneseTranslation" properties, including
    // ones in the nested objects
    // first check the ones in the resume itself
    // if any item is not ResumeTranslationStatus.Completed, return false
    if (!resume) return false;
    if (
      resume.lastNameFuriganaStatus !== ResumeTranslationStatus.Completed ||
      resume.firstNameFuriganaStatus !== ResumeTranslationStatus.Completed ||
      resume.nativeLanguagesJapaneseStatus !== ResumeTranslationStatus.Completed
    ) {
      return false;
    }
    // The address translations should check both kanji and kana, but ONLY if the address is in Japan
    // then check education, work history, and qualifications
    if (resume.currentResidenceCountry?.id === JapanId) {
      if (
        resume.addressLineJapaneseStatus !== ResumeTranslationStatus.Completed ||
        resume.buildingAndRoomNumberJapaneseStatus !== ResumeTranslationStatus.Completed ||
        resume.addressLineKanaStatus !== ResumeTranslationStatus.Completed ||
        resume.buildingAndRoomNumberKanaStatus !== ResumeTranslationStatus.Completed
      ) {
        return false;
      }
    }
    resume.education?.forEach((e) => {
      if (e.specialtyJapaneseStatus !== ResumeTranslationStatus.Completed) return false;
    });
    resume.workExperience?.forEach((w) => {
      if (w.jobDescriptionJapaneseStatus !== ResumeTranslationStatus.Completed) return false;
    });
    resume.qualifications?.forEach((q) => {
      if (q.descriptionJapaneseStatus !== ResumeTranslationStatus.Completed) return false;
    });
    return true;
  }
}
