import { BenefitCategory, BenefitCategoryInfo } from '../network/schema/models'
import BenefitEntity, { BenefitEntityFactory } from './benefit'
import { LanguageLocale } from '@tokyojob/core'
import VacancyBenefitEntity, {
  VacancyBenefitEntityFactory
} from '@/modules/vacancy/entities/vacancy.benefit'

export interface IBenefitCategoryProps extends BenefitCategory {}

export default class BenefitCategoryEntity {
  private _props: IBenefitCategoryProps
  /**
   * List of children of this vacancy benefit.
   * @remarks Had to include this because otherwise, building the Benefits UI became
   * a very complex task. This eased up the reactivity issues.
   */
  vacancyBenefitsCache: VacancyBenefitEntity[]

  constructor(props: IBenefitCategoryProps) {
    this._props = props
    this.vacancyBenefitsCache = []
    if (props.vacancyBenefits) {
      this.vacancyBenefitsCache = props.vacancyBenefits?.map((e) =>
        VacancyBenefitEntityFactory(e)
      )
    }
  }

  get props(): IBenefitCategoryProps {
    return this._props
  }

  get id(): string {
    return this.props.id
  }

  get order(): string | undefined {
    return this.props.order
  }

  get informations(): BenefitCategoryInfo[] {
    return this.props.i18n || []
  }

  /**
   * Gets the name of the benefit in the current locale.
   */
  get name(): string {
    if (this.props.i18n) {
      return this.props.i18n[0].name
    }
    return ''
  }

  /**
   * Gets the benefits.
   */
  get benefits(): BenefitEntity[] {
    if (!this.props.benefits) {
      return []
    }
    return this.props.benefits.map((e) => BenefitEntityFactory(e))
  }

  /**
   * Get the benefits of this category, but as VacancyBenefit entities.
   */
  get vacancyBenefits(): VacancyBenefitEntity[] {
    return this.vacancyBenefitsCache
  }

  set vacancyBenefits(vacancyBenefits: VacancyBenefitEntity[]) {
    this.vacancyBenefitsCache = vacancyBenefits
  }
}

export const EmptyBenefitCategoryPropsFactory = (
  props?: Partial<IBenefitCategoryProps>
): IBenefitCategoryProps => ({
  id: '',
  ...props
})

export const BenefitCategoryPropsFactory = (
  props?: Partial<IBenefitCategoryProps>
): IBenefitCategoryProps => {
  const vacancyBenefits = props?.benefits?.map((e) => {
    return {
      benefit: e,
      benefitId: parseInt(e.id),
      id: undefined,
      benefitUnit: undefined,
      benefitUnitId: undefined,
      amount: undefined,
      checked: false,
      i18n: [
        {
          locale: LanguageLocale.JAjp,
          description: ''
        }
      ],
      children: e.children?.map((z) => {
        return {
          benefit: z,
          benefitId: parseInt(z.id),
          id: undefined,
          benefitUnit: undefined,
          benefitUnitId: undefined,
          amount: undefined,
          checked: false,
          i18n: [
            {
              locale: LanguageLocale.JAjp,
              description: ''
            }
          ]
        }
      })
    }
  })
  return {
    id: '',
    vacancyBenefits,
    ...props
  }
}

export const EmptyBenefitCategoryEntityFactory = (
  props?: Partial<IBenefitCategoryProps>
): BenefitCategoryEntity => {
  return new BenefitCategoryEntity(EmptyBenefitCategoryPropsFactory(props))
}

export const BenefitCategoryEntityFactory = (
  props?: Partial<IBenefitCategoryProps>
): BenefitCategoryEntity => {
  return new BenefitCategoryEntity(BenefitCategoryPropsFactory(props))
}
