import { LogService } from '../../base/services/log.service'
import { Route } from 'vue-router'
import { LanguageLocale, LanguageURL, LanguageURLToLocaleMap } from '../../../../../core/src'
import { LanguageISO6391 } from '../../../../../core/src/models/language/constants/language-iso639-1.enum'
import { LanguageURLToISO6391 } from '../../../../../core/src/models/language/map/language-url-to-iso639-1.map'
import { LocalizationService } from './localization-service'

export class LocalizationSelector {
  constructor(
    private readonly languageCookieSetter: (value: string | undefined) => void, // points to a setter
    private readonly logService: LogService,
    public readonly language: LanguageURL // points to a getter
  ) {}

  private languageUpdateRequest: (locale: LanguageLocale) => Promise<void>

  /**
   * Returns the currently selected language locale
   */
  get languageLocale(): LanguageLocale {
    return LanguageURLToLocaleMap[this.language]
  }

  /**
   * Returns the currently selected language ISO6391
   */
  get languageISO6391(): LanguageISO6391 {
    return LanguageURLToISO6391[this.language]
  }

  /**
   * Sets the request needed to call when changing language
   * @param value
   */
  async setLanguageUpdateRequest(value: (locale: LanguageLocale) => Promise<void>) {
    this.languageUpdateRequest = value
  }

  /**
   * Changes the language to a specific language
   * @param language
   */
  async changeLanguage(language: LanguageURL, route: Route): Promise<void> {
    this.languageCookieSetter(language)
    LocalizationService.logService = this.logService
    const expectedPath = LocalizationService.getExpectedPath(route, language)

    const locale = LanguageURLToLocaleMap[language]
    await this.languageUpdateRequest(locale)

    // Full redirect is easier to handle than SPA redirect.
    window.location.href = `${process.env.baseWebUrl}${expectedPath}`
  }
}
