import { OnboardingStep } from './constants';

export class OnboardingCurrentStepEvaluator {
  /**
   * Checks the passed OnboardingStep if it is OnboardingStep.Welcome
   * Returns true if yes, returns false otherwise
   * @param currentStep
   * @returns
   */
  public static IsOnboardingStepWelcome(currentStep: number): boolean {
    return currentStep === OnboardingStep.Welcome;
  }

  /**
   * Checks the passed OnboardingStep if it is OnboardingStep.Name
   * Returns true if yes, returns false otherwise
   * @param currentStep
   * @returns
   */
  public static IsOnboardingStepName(currentStep: number): boolean {
    return currentStep === OnboardingStep.Name;
  }

  /**
   * Checks the passed OnboardingStep if it is OnboardingStep.Contact
   * Returns true if yes, returns false otherwise
   * @param currentStep
   * @returns
   */
  public static IsOnboardingStepContact(currentStep: number): boolean {
    return currentStep === OnboardingStep.Contact;
  }

  /**
   * Checks the passed OnboardingStep if it is OnboardingStep.PersonalInformation
   * Returns true if yes, returns false otherwise
   * @param currentStep
   * @returns
   */
  public static IsOnboardingStepPersonalInformation(currentStep: number): boolean {
    return currentStep === OnboardingStep.PersonalInformation;
  }

  /**
   * Checks the passed OnboardingStep if it is OnboardingStep.JobStatus
   * Returns true if yes, returns false otherwise
   * @param currentStep
   * @returns
   */
  public static IsOnboardingStepJobStatus(currentStep: number): boolean {
    return currentStep === OnboardingStep.JobStatus;
  }

  /**
   * Whether the provided input equals the application confirmation step.
   * @param currentStep
   * @returns
   */
  public static IsOnboardingApplicationConfirmation(currentStep: number): boolean {
    return currentStep === OnboardingStep.ApplicationConfirmation;
  }
}
