import { defineStore } from "pinia";
import { IVisaType } from "../../../../core/src/models/visa-type/models/i-visa-type";

interface IState {
  data: Record<string, Record<number, IVisaType>>
}

const generateCompositeKey = (contractTypeId: number, industryId: number, jobSectorId: number): string => {
  return `${contractTypeId}-${industryId}-${jobSectorId}`;
};

export const useVisaTypePresetsStore = defineStore('visaPresetsStore', {
  state: (): IState => ({
    data: {} as Record<string, Record<number, IVisaType>>,
  }),
  getters: {
    getVisaByCompositeId: (state) => (contractTypeId: number, industryId: number, jobSectorId: number): Record<number, IVisaType> | undefined => {
      const compositeId = generateCompositeKey(contractTypeId, industryId, jobSectorId);
      return state.data[compositeId];
    },
    getVisasArrayByCompositeId: (state) => (contractTypeId: number, industryId: number, jobSectorId: number): IVisaType[] => {
      const compositeId = generateCompositeKey(contractTypeId, industryId, jobSectorId);
      const visaMap = state.data[compositeId];
      if (visaMap) return Object.values(visaMap);
      return [];
    },
    getVisaById: (state) => (contractTypeId: number, industryId: number, jobSectorId: number, visaId: number): IVisaType | undefined => {
      const compositeId = generateCompositeKey(contractTypeId, industryId, jobSectorId);
      const visaMap = state.data[compositeId];
      if (visaMap) return visaMap[visaId]
      return undefined;
    },
  },
  actions: {
    setVisaTypePreset(contractTypeId: number, industryId: number, jobSectorId: number, visas: IVisaType[]): void {
      const compositeId = generateCompositeKey(contractTypeId, industryId, jobSectorId);
      const visaMap: Record<number, IVisaType> = {};
      visas.forEach(visa => {
        if (!visa || !visa.id) return
        visaMap[visa.id] = visa;
      });
      this.data[compositeId] = visaMap;
    }
  }
})