/**
 * The possible sources from which an application was created.
 */
export enum ApplicationSource {
  /**
   * Generated by a candidate applying to a vacancy through our website.
   */
  Web = 'Web',
  /**
   * Generated by a candidate applying to a vacancy through our Messenger bot.
   */
  Messenger = 'Messenger',
  /**
   * Applications generated by unregistered users
   */
  WebAnonymous = 'WebAnonymous',
}
