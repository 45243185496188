import { LanguageLocale, LanguageURL } from '@tokyojob/core'
import { APIRequest, HTTPMethod } from '@tokyojob/frontend-core'
import { SpaceCMS } from '../schema/cms'

export class GetVacancyGridContentRequest implements APIRequest<SpaceCMS.GetVacancyGridContentResponse> {
  response!: SpaceCMS.GetVacancyGridContentResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor() {
    this.path = '/cms/vacancy-grid'
  }
}

export class GetJoomlaCMSLayoutContentRequest implements APIRequest<SpaceCMS.GetJoomlaCMSLayoutContentResponse> {
  response!: SpaceCMS.GetJoomlaCMSLayoutContentResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  baseURL = process.server ? process.env.contentServerSSR : process.env.contentServerClient
  constructor(locale: LanguageLocale) {
    this.path = `/index.php?&tmpl=json_menu&locale=${locale}`
  }
}
