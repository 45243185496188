/**
 * The interface equivalent of the Picture entity from the database
 */
export enum ImageMimeType {
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  GIF = 'image/gif',
  WEBP = 'image/webp',
}
