import { MetaPropertyCharset, MetaPropertyEquiv, MetaPropertyMicrodata, MetaPropertyName, MetaPropertyProperty } from 'vue-meta'
import { LanguageLocale } from '../../../../../../../core/src'
import { MetaTagFactory } from '../meta-tag.factory'

export class CandidateLoggedInMetaFactory {
  static metaTags(
    locale: LanguageLocale
  ): (MetaPropertyCharset | MetaPropertyEquiv | MetaPropertyName | MetaPropertyMicrodata | MetaPropertyProperty)[] {
    const locales = MetaTagFactory.localeTags(locale)
    return [...locales]
  }
}
