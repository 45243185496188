import UserEntity, { IUserSeed, IUserProps, IEmailSeed } from '@/modules/user/entities/user'

export const toUserProps = (props: IUserProps): IUserProps => {
  const {
    id,
    firstName,
    lastName,
    fullName,
    email,
    avatar,
    country,
    countryId,
    grewUpInCountry,
    grewUpInCountryId,
    updatedAt,
    bio,
    contactInformation,
    userType,
    hasPassedOnboardingWelcome,
    hasPassedOnboardingName
  } = props
  // For some reason the dates were not being properly converted.
  let { firstArrivalDate, birthDate, gender } = props
  if (typeof firstArrivalDate === 'string') {
    firstArrivalDate = new Date(firstArrivalDate)
  }
  if (typeof birthDate === 'string') {
    birthDate = new Date(birthDate)
  }
  if (gender !== 'male' && gender !== 'female') {
    gender = 'male'
  }
  return {
    id,
    firstName,
    lastName,
    fullName,
    email,
    avatar,
    bio,
    firstArrivalDate,
    birthDate,
    gender,
    country,
    countryId,
    grewUpInCountry,
    grewUpInCountryId,
    updatedAt,
    contactInformation,
    userType,
    hasPassedOnboardingWelcome,
    hasPassedOnboardingName
  }
}

export const toUserSeed = (props: UserEntity): IUserSeed => {
  const { firstName, lastName, avatar, firstArrivalDate, birthDate, country, gender } = props
  const newPassword = props.newPassword || undefined
  const password = props.password || undefined
  const email = props.email || undefined
  return {
    firstName,
    lastName,
    email,
    avatar,
    password,
    newPassword,
    firstArrivalDate,
    birthDate,
    countryId: country?.id,
    gender
  }
}

/**
 * Maps a user to an email seed object.
 * @param props User to map the email data from.
 */
export const toEmailSeed = (props: UserEntity): IEmailSeed => {
  const password = props.password
  const email = props.email
  return {
    newEmail: email,
    password
  }
}
