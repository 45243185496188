import { IContact } from '../../../../../../core/src'
import { ContactMethodEntity, ContactMethodEntityFactory } from './contact-method.entity'

/**
 * Contact information of a user, company, etc.
 */
export class ContactEntity {
  /**
   * Model used as a base for the data that will be used by the entity.
   */
  private _props: IContact
  /**
   * Contact Method entity cache.
   */
  private _contactMethod: ContactMethodEntity

  constructor(props: IContact) {
    this._props = props
  }

  get props(): IContact {
    return this._props
  }

  /**
   * Identifier of the contact item.
   */
  get id(): number | undefined {
    return this.props.id
  }

  /**
   * Contact method. Uses a a cached entity, built from the provided props.
   */
  get contactMethod(): ContactMethodEntity {
    if (!this._contactMethod) {
      this._contactMethod = ContactMethodEntityFactory.new(this.props.contactMethod)
    }
    return this._contactMethod
  }

  set contactMethod(value: ContactMethodEntity) {
    this.props.contactMethod = value.props
    this._contactMethod = ContactMethodEntityFactory.new(this.props.contactMethod)
    this.props.contactMethodId = this._contactMethod.id
  }

  /**
   * The number, id, account, etc. of the contact information.
   * @example "070-1234-1234", "eppur@muove.si"
   */
  get account(): string {
    return this.props.account || ''
  }

  set account(value: string) {
    this.props.account = value
  }

  /**
   * Extra notes for this contact.
   */
  get other(): string {
    return this.props.other || ''
  }

  set other(value: string) {
    this.props.other = value
  }

  /**
   * Whether the user wants to receive notifications in this contact item.
   */
  get notification(): boolean {
    return this.props.notification || false
  }

  set notification(value: boolean) {
    this.props.notification = value
  }

  /**
   * The contact method used for this item.
   */
  get contactMethodId(): number | undefined {
    return this.props.contactMethodId
  }

  set contactMethodId(value: number | undefined) {
    this.props.contactMethodId = value
  }
}

/**
 * Creates new contact entities.
 * @remarks Had to leave this in this file, otherwise it causes circular dependency issues.
 */
export class ContactEntityFactory {
  /**
   * Generates a contact model, including default values for any required members.
   * @param props
   * @returns
   */
  static defaultProps(props?: Partial<IContact>): IContact {
    const newProps = {
      contactMethod: {
        i18n: [{ name: '--', locale: '' }],
        id: 0,
      },
      ...props,
    }
    return newProps
  }

  /**
   * Instantiates a new entity.
   * @param props
   * @returns
   */
  static new(props?: Partial<IContact>): ContactEntity {
    return new ContactEntity(ContactEntityFactory.defaultProps(props))
  }
}
