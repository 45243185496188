import { LanguageLocale } from '@tokyojob/core'
import { APIRequest, HTTPMethod } from '@tokyojob/frontend-core'
import { SpaceLocationHierarchy } from '../schema/namespace'

export class FetchLocationHierarchyRequest
  implements APIRequest<SpaceLocationHierarchy.LocationHierarchyFetchResponse> {
  response!: SpaceLocationHierarchy.LocationHierarchyFetchResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(locale: LanguageLocale) {
    this.path = `/region/hierarchy?locale=${locale}`
  }
}
