import { IsNumber, IsOptional, IsString } from 'class-validator';

export class ApplicationArchiveRequestDto {
  @IsOptional()
  @IsNumber()
  applicationSubStatusAgentId?: number;

  @IsOptional()
  @IsString()
  memo?: string;
}
