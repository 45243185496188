import { IUser } from '../../user/interface/i-user';
import { CandidateJapaneseLanguageSkillsDto } from '../dto/candidate-japanese-language-skills.dto';

export class CandidateJapaneseLanguageSkillsDtoFactory {
  new(user: IUser): CandidateJapaneseLanguageSkillsDto {
    const japaneseConversationId = user.candidate?.japaneseConversation?.id || user.candidate?.japaneseConversationId;
    const dto = new CandidateJapaneseLanguageSkillsDto();
    dto.japaneseConversationId = japaneseConversationId;
    return dto;
  }
}
