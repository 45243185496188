/**
 * Collection of Mime Types that may be used throughout the application.
 */
export enum MimeType {
  Text = 'text/plain',
  Csv = 'text/csv',
  Xls = 'application/vnd.ms-excel',
  Xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  Doc = 'application/msword',
  Docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  Pdf = 'application/pdf',
  Ppt = 'application/vnd.ms-powerpoint',
  Pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  Keynote = 'application/vnd.apple.keynote',
  Pages = 'application/vnd.apple.pages',
  Numbers = 'application/vnd.apple.numbers',
}
