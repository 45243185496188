
/**
 * Typed representation of language in full words.
 */
export enum LanguageFullJP {
  Japanese = '日本語',
  English = '英語',
  Vietnamese = 'ベトナム語',
  Chinese = '中国語',
}
