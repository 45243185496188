
import { MetaPropertyCharset, MetaPropertyEquiv, MetaPropertyMicrodata, MetaPropertyName, MetaPropertyProperty } from 'vue-meta'
import { LanguageLocale, LocalizationData } from '../../../../../../../core/src'
import { LocalizationService } from '../../../language/localization-service'
import { MetaTagFactory } from '../meta-tag.factory'
import { LocalizationPath } from '../../../../../../../core/src'

/**
 * Agent Vacancy Preview page meta tags.
 */
export class JobPreviewMetaFactory {
  static metaTags(localization: LocalizationData, locale: LanguageLocale): (
    | MetaPropertyCharset
    | MetaPropertyEquiv
    | MetaPropertyName
    | MetaPropertyMicrodata
    | MetaPropertyProperty
  )[] {
    const title: string = LocalizationService.t<string>(localization, LocalizationPath.JobPreviewPageMetaTagTitle)
    const description: string = LocalizationService.t<string>(localization, LocalizationPath.JobPreviewPageMetaTagDescription)
    const titles = MetaTagFactory.titleTags(title)
    const siteName = MetaTagFactory.siteName()
    const descriptions = MetaTagFactory.descriptionTags(description)
    const locales = MetaTagFactory.localeTags(locale)
    const robots = MetaTagFactory.nonindexedRobots()
    return [...titles, siteName, ...descriptions, ...locales, robots]
  }
}
