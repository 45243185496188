import { IsArray, IsNumber, IsOptional, IsString } from 'class-validator';

/**
 * The DTO that validates the japanese langauge section
 */
export class VacancySaveJapaneseLanguageDto {
  /**
   * The ids of the japanese conversation that is required in the vacancy
   */
  @IsNumber()
  japaneseConversationId: number;

  /**
   * Extra details about the japanese language requirements
   */
  @IsOptional()
  @IsString()
  japaneseLanguageDetails?: string;
}
