import { toContactSeed } from '~/modules/app/gateways/translator/contact'
import CandidateVisaEntity, { ICandidateVisaProps, ICandidateVisaSeed } from '../../entities/candidate.visa'
import UserEntity from '../../entities/user'
import { toCandidateVisaAttachmentSeed } from './candidate.visa.attachment'

export const toCandidateVisaProps = (props: ICandidateVisaProps): ICandidateVisaProps => {
  // The date is not being properly converted sometimes.
  // TODO: Remove the check once the issue is corrected.
  let { validDate } = props
  if (typeof validDate === 'string') {
    validDate = new Date((validDate as unknown) as string)
  }
  // TODO: Normalize all of our translation data to be i18n.
  const shouldCheckVisaType = props?.visaType && !props.visaType?.i18n
  if (shouldCheckVisaType) {
    props.visaType!.i18n = (props.visaType as any).informations
  }
  const shouldCheckVisaSubType = props.visaSubType && !props.visaSubType?.i18n
  if (shouldCheckVisaSubType) {
    props.visaSubType!.i18n = (props.visaSubType as any).informations
  }
  return {
    ...props,
    validDate
  }
}

export const toCandidateVisaSeed = (userProps: UserEntity, candidateVisaProps: CandidateVisaEntity): ICandidateVisaSeed => {
  const { cardId, validDate, visaTypeId, visaSubTypeId, candidateVisaAttachments } = candidateVisaProps
  const cardFrontId = candidateVisaProps.cardFront?.id
  const cardBackId = candidateVisaProps.cardBack?.id
  const designationPapersAttachmentId = candidateVisaProps.designationPapersAttachment?.id
  const { firstName, lastName, birthDate, firstArrivalDate, gender, countryId, grewUpInCountryId, contactInformation } = userProps
  const newFirstName = firstName || ''
  const newLastName = lastName || ''
  const newBirthDate = birthDate || new Date()
  const newFirstArrivalDate = firstArrivalDate
  const newGender = gender || ''
  const newFrontAttachmenntId = cardFrontId
  const newBackAttachmentId = cardBackId
  const newDesginationAttachmentId = designationPapersAttachmentId
  const newExpirationDate = validDate
  const newContactInformation = contactInformation?.map((e) => toContactSeed(e))
  const candidateVisaAttachmentSeeds = candidateVisaAttachments?.map((e) => toCandidateVisaAttachmentSeed(e.props))
  return {
    firstName: newFirstName,
    lastName: newLastName,
    birthDate: newBirthDate,
    firstArrivalDate: newFirstArrivalDate,
    gender: newGender,
    countryId,
    grewUpInCountryId,
    visaTypeId: visaTypeId!,
    visaSubTypeId,
    residenceCardFrontAttachmentId: newFrontAttachmenntId,
    residenceCardBackAttachmentId: newBackAttachmentId,
    designationPapersAttachmentId: newDesginationAttachmentId,
    cardId,
    visaExpirationDate: newExpirationDate,
    contactInformation: newContactInformation,
    candidateVisaAttachments: candidateVisaAttachmentSeeds || []
  }
}
