import { IApplicationSubStatusAgent, IApplicationSubStatusAgentI18n } from '../../../models';

export class ApplicationAgentSubStatusGetters {
  static getName(status: IApplicationSubStatusAgent): string {
    const i18n = this.getI18n(status);
    if (!i18n) return '';
    return i18n.name!;
  }

  static getDescription(status: IApplicationSubStatusAgent): string {
    const i18n = this.getI18n(status);
    if (!i18n) return '';
    return i18n.description!;
  }

  static getI18n(status: IApplicationSubStatusAgent): IApplicationSubStatusAgentI18n | undefined {
    if (!status || !status.i18n || !status.i18n.length) return undefined;
    return status.i18n[0];
  }
}
