import { EnumDictionary } from '../../../utils/types/enum-dictionary';
import { ApplicationAgentStatusId } from './application-agent-status-id.enum';

/**
 * CSS classes and styles usecd for each application status.
 * An empty string implies that the theme will depend on the substatus.
 * If the child is also empty, then the theme will be the default one.
 */
export const ApplicationAGGridStatusThemes: EnumDictionary<ApplicationAgentStatusId, string> = {
  [ApplicationAgentStatusId.NewApplication]: 'theme-purple-medium1-color', // New Application
  [ApplicationAgentStatusId.ApplicationReview]: 'theme-shades-medium1-white', // Reviewing
  [ApplicationAgentStatusId.Contacting]: 'theme-shades-dark1-white', // Contacting
  [ApplicationAgentStatusId.NotHiredBeforeInterview]: 'theme-orange-dark1-white', // Interviewing
  [ApplicationAgentStatusId.InterviewPreparation]: 'theme-blue-light1-white', // Agent Declined
  [ApplicationAgentStatusId.InterviewRescheduling]: 'theme-blue-dark1-white', // Work Trial
  [ApplicationAgentStatusId.InterviewCompleted]: 'theme-green-pastel-medium-white', // Offer
  [ApplicationAgentStatusId.NotHiredAfterInterview]: 'theme-red-tokyo-white', // Candidate Declined
  [ApplicationAgentStatusId.Offer]: 'theme-green-dark1-white', // Hired
  // This is the only one that has a few substatuses with a different theme
  [ApplicationAgentStatusId.Employment]: 'theme-yellow-medium1-white', // End of Employment
  [ApplicationAgentStatusId.CandidateWithdrew]: 'theme-red-tokyo-white', // Candidate Withdrew
  [ApplicationAgentStatusId.Memo]: 'theme-shades-light1-white', // End of Employment
};

export const ApplicationTimelineStatusThemes: EnumDictionary<ApplicationAgentStatusId, string> = {
  [ApplicationAgentStatusId.NewApplication]: 'theme-purple-light1-white color-text-dark1', // New Application
  [ApplicationAgentStatusId.ApplicationReview]: 'theme-shades-light1-white shadow', // Reviewing
  [ApplicationAgentStatusId.Contacting]: 'theme-shades-dark1-white', // Contacting
  [ApplicationAgentStatusId.NotHiredBeforeInterview]: 'theme-orange-dark1-white', // Interviewing
  [ApplicationAgentStatusId.InterviewPreparation]: 'theme-blue-light1-white', // Agent Declined
  [ApplicationAgentStatusId.InterviewRescheduling]: 'theme-blue-dark1-white', // Work Trial
  [ApplicationAgentStatusId.InterviewCompleted]: 'theme-green-pastel-medium-white', // Offer
  [ApplicationAgentStatusId.NotHiredAfterInterview]: 'theme-red-tokyo-white', // Candidate Declined
  [ApplicationAgentStatusId.Offer]: 'theme-green-dark1-white', // Hired
  // This is the only one that has a few substatuses with a different theme
  [ApplicationAgentStatusId.Employment]: 'theme-yellow-medium1-white', // End of Employment
  [ApplicationAgentStatusId.CandidateWithdrew]: 'theme-red-tokyo-white', // Candidate Withdrew
  [ApplicationAgentStatusId.Memo]: 'theme-shades-light1-white', // Standalone Memo
};

export const ApplicationAGGridStatusIconThemes: EnumDictionary<ApplicationAgentStatusId, string> = {
  [ApplicationAgentStatusId.NewApplication]: 'color-shades-white', // New Application
  [ApplicationAgentStatusId.ApplicationReview]: 'color-shades-dark1', // Reviewing
  [ApplicationAgentStatusId.Contacting]: '', // Contacting
  [ApplicationAgentStatusId.NotHiredBeforeInterview]: 'color-orange-dark1-outline', // Interviewing
  [ApplicationAgentStatusId.InterviewPreparation]: 'color-blue-medium1', // Agent Declined
  [ApplicationAgentStatusId.InterviewRescheduling]: 'color-blue-dark1', // Work Trial
  [ApplicationAgentStatusId.InterviewCompleted]: 'color-green-pastel-dark', // Offer
  [ApplicationAgentStatusId.NotHiredAfterInterview]: 'color-red-tokyo', // Candidate Declined
  [ApplicationAgentStatusId.Offer]: 'color-green-dark1-outline', // Hired
  [ApplicationAgentStatusId.Employment]: 'color-gold-dark1', // End of Employment
  [ApplicationAgentStatusId.CandidateWithdrew]: 'color-red-tokyo', // Candidate Withdrew
  [ApplicationAgentStatusId.Memo]: '', // Standalone Memo
};

export const ApplicationTimelineStatusIconThemes: EnumDictionary<ApplicationAgentStatusId, string> = {
  [ApplicationAgentStatusId.NewApplication]: 'color-purple-medium1', // New Application
  [ApplicationAgentStatusId.ApplicationReview]: 'color-shades-dark1', // Reviewing
  [ApplicationAgentStatusId.Contacting]: '', // Contacting
  [ApplicationAgentStatusId.NotHiredBeforeInterview]: 'color-orange-dark1-outline', // Interviewing
  [ApplicationAgentStatusId.InterviewPreparation]: 'color-blue-medium1', // Agent Declined
  [ApplicationAgentStatusId.InterviewRescheduling]: 'color-blue-dark1', // Work Trial
  [ApplicationAgentStatusId.InterviewCompleted]: 'color-green-pastel-dark', // Offer
  [ApplicationAgentStatusId.NotHiredAfterInterview]: 'color-red-tokyo', // Candidate Declined
  [ApplicationAgentStatusId.Offer]: 'color-green-dark1-outline', // Hired
  [ApplicationAgentStatusId.Employment]: 'color-gold-dark1', // End of Employment
  [ApplicationAgentStatusId.CandidateWithdrew]: 'color-red-tokyo', // Candidate Withdrew
  [ApplicationAgentStatusId.Memo]: '', // Standalone Memo
};
