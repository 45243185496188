import { IIndustry, IIndustryI18n } from '../../../models/category';

/**
 * Getter methods for industry objects
 */
export class IndustryGetters {
  /**
   * Gets the first available name of the item.
   * @param item
   * @returns
   */
  static getName(item: IIndustry): string {
    if (!IndustryGetters.hasi18n(item)) return '';
    const i18n: IIndustryI18n[] | undefined = item.i18n;
    return i18n![0].name!;
  }

  /**
   * Whether the provided item has translation data or not
   * @param item
   * @returns
   */
  static hasi18n(item: IIndustry): boolean {
    if (!item) return false;
    const i18n: IIndustryI18n[] | undefined = item.i18n;
    return !!(i18n && i18n.length > 0);
  }
}
