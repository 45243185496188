import { IContactMethod, IContactMethodi18n } from '../../../../../../core/src'
import { IFormInputData } from '../../html/forms/inputs/interfaces/form-input-data'

/**
 * Contact method type.
 */
export class ContactMethodEntity implements IFormInputData {
  private _props: IContactMethod

  constructor(props: IContactMethod) {
    if (!props.i18n) props.i18n = []
    this._props = props
  }

  get props(): IContactMethod {
    return this._props
  }

  /**
   * Unique identifier.
   */
  get id(): number {
    return this.props.id!
  }

  set id(value: number) {
    this.props.id = value
  }

  /**
   * Translation information.
   */
  get i18n(): IContactMethodi18n[] {
    return this.props.i18n!
  }

  /**
   * Translated name of the contact method.
   */
  get name(): string {
    return this.i18n[0].name
  }

  set name(value: string) {
    this.i18n[0].name = value
  }

  /**
   * Label to use when displaying in the UI.
   */
  get label(): string {
    return this.name
  }

  set label(value: string) {
    this.name = value
  }

  /**
   * The value to use when this is used in form fields.
   */
  get value(): number {
    return this.id
  }

  set value(value: number) {
    this.id = value
  }
}

/**
 * Creates new contact method entities.
 * @remarks Had to leave this in this file, otherwise it causes circular dependency issues.
 */
export class ContactMethodEntityFactory {
  /**
   * Generates a contact method model, including default values for any required members.
   * @param props
   * @returns
   */
  static defaultProps(props?: Partial<IContactMethod>): IContactMethod {
    // .informations is the old way in which the i18n item was named.
    if (props && (props as any).informations) {
      props.i18n = (props as any).informations
    }
    return {
      id: 0,
      i18n: [],
      ...props,
    }
  }

  /**
   * Instantiates a new entity.
   * @param props
   * @returns
   */
  static new(props?: Partial<IContactMethod>): ContactMethodEntity {
    return new ContactMethodEntity(ContactMethodEntityFactory.defaultProps(props))
  }
}
