import { APIRequest, HTTPMethod, QueryStringEntity } from '@tokyojob/frontend-core'
import { SpaceLocation } from '../schema/namespace'

export class FetchPrefectureListRequest
  implements APIRequest<SpaceLocation.FetchPrefectureListRequest> {
  response!: SpaceLocation.FetchPrefectureListResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(entity?: QueryStringEntity) {
    const queries = entity ? entity.queries : ''
    this.path = '/prefecture/' + queries
  }
}
