
import { computed, defineComponent, onBeforeUnmount, onMounted } from 'vue'
import { HtmlAttributesFactory } from '@tokyojob/frontend-core'
import CSidebar from '~/modules/app/components/app/c-sidebar/c-sidebar.component.vue'
import CTopbar from '~/modules/app/components/app/c-topbar/c-topbar.component.vue'
import { getAppStore } from '~/store/app.store'
import CCandidateFooter from '../modules/app/components/app/footer/c-candidate-footer.component.vue'
import { CandidateDashboardStatsComposable } from '~/modules/user/composables/candidate-dashboard-stats.composable'
import CFloatingNotifications from '~/modules/user/components/c-floating-notifications.component.vue'

export default defineComponent({
  components: {
    CSidebar,
    CTopbar,
    CCandidateFooter,
    CFloatingNotifications
  },
  setup() {
    const { initPoller, stopPoller } = CandidateDashboardStatsComposable()
    const appStore = getAppStore()
    const hideFooter = computed(() => {
      return appStore.hideFooter
    })
    onMounted(async () => {
      initPoller()
    })
    onBeforeUnmount(() => {
      stopPoller()
    })
    return {
      hideFooter
    }
  },
  head() {
    return {
      htmlAttrs: HtmlAttributesFactory.basicAttributes(this.App.localizationSelector.languageISO6391)
    }
  }
})
