/**
 * Default coordinates to use in a map. Located in the center of Tokyo, around Chiyoda.
 * @remarks the name of the properties lat and lng are set as they are used in the google maps API.
 */
 export const DefaultMapCoordinates = {
    lat: 35.70194,
    lng: 139.7291363
  }
  
  /**
   * Default zoom in which a map should be displayed in.
   */
  export const DefaultNewMapZoom = 11
  
  /**
   * Default zoom in which a map should be displayed in when a marker / location
   * is already set for it.
   */
  export const DefaultMapWithMarkerZoom = 16
  
  /**
   * Default zoom to which the map should be updated when a map marker is updated.
   */
  export const DefaultMapZoomOnMarkerChange = 16
  