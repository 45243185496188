import CandidateJapaneseLanguageEntity, { ICandidateJapaneseLanguageProps } from '../entities/candidate.japanese.language'
import {
  GetCandidateJapaneseSkillsRequest,
  GetJapaneseBusinessSkillnRequest,
  GetJapaneseConversationRequest,
  GetJapaneseListeningRequest,
  GetJapaneseReadingRequest,
  GetJapaneseWritingRequest,
  GetLanguageCertificateGroupRequest,
  PutCandidateJapaneseSkills
} from '../network/requests/language'
import JapaneseListeningEntity, { JapaneseListeningEntityFactory } from '../entities/japanese.listening'
import JapaneseReadingEntity, { JapaneseReadingEntityFactory } from '../entities/japanese.reading'
import JapaneseConversationEntity, { JapaneseConversationEntityFactory } from '../entities/japanese.conversation'
import JapaneseWritingEntity, { JapaneseWritingEntityFactory } from '../entities/japanese.writing'
import LanguageCertificateGroupEntity, { LanguageCertificateGroupEntityFactory } from '../entities/language.certificate.group'
import { AvailableLanguageCertificateGroups } from '../network/schema/models/language.certificate.group'
import JapaneseBusinessEntity, { JapaneseBusinessEntityFactory } from '../entities/japanese.business'
import { toCandidateJapaneseSkillsProps, toCandidateJapaneseSkillsSeed } from './translator/candidate.japanese.skills'
import { GatewayBase } from '@/base/gateways/base'
import { IJapaneseConversation, LanguageLocale } from '@tokyojob/core'
import { GetJapaneseSkillListRequest } from '@tokyojob/frontend-core'

/**
 * Language related queries.
 */
export default class Language extends GatewayBase {
  /**
   * Gets the candidate's Japanese language skills.
   * @deprecated Delete after deletion of old JP skills
   */
  async GetJapaneseSkills(): Promise<ICandidateJapaneseLanguageProps> {
    const item = await this.apiClient.request(new GetCandidateJapaneseSkillsRequest())
    return toCandidateJapaneseSkillsProps(item)
  }

  /**
   * Gets the system's catalogue of Japanese Listening skills.
   * @deprecated Delete after deletion of old JP skills
   */
  async GetListeningLevels(): Promise<JapaneseListeningEntity[]> {
    const items = await this.apiClient.request(new GetJapaneseListeningRequest())
    return items.map((el) => JapaneseListeningEntityFactory(el))
  }

  /**
   * Gets the system's catalogue of Japanese Reading skills.
   */
  async GetReadingLevels(): Promise<JapaneseReadingEntity[]> {
    const items = await this.apiClient.request(new GetJapaneseReadingRequest())
    return items.map((el) => JapaneseReadingEntityFactory(el))
  }

  /**
   * Gets the system's catalogue of Japanese Writing skills.
   * @deprecated Delete after deletion of old JP skills
   */
  async GetWritingLevels(): Promise<JapaneseWritingEntity[]> {
    const items = await this.apiClient.request(new GetJapaneseWritingRequest())
    return items.map((el) => JapaneseWritingEntityFactory(el))
  }

  /**
   * Gets the system's catalogue of Japanese Conversation skills.
   * @deprecated Delete after deletion of old JP skills
   */
  async GetConversationLevels(): Promise<JapaneseConversationEntity[]> {
    const items = await this.apiClient.request(new GetJapaneseConversationRequest())
    return items.map((el) => JapaneseConversationEntityFactory(el))
  }

  /**
   * Gets a list of language certificates.
   * @remarks  The system supports multiple certificates, not only JLPT. But for now, only JLPT is taken into account.
   */
  async GetLanguageCertificateGroup(
    group: AvailableLanguageCertificateGroups = AvailableLanguageCertificateGroups.JLPT
  ): Promise<LanguageCertificateGroupEntity> {
    const item = await this.apiClient.request(new GetLanguageCertificateGroupRequest(group))
    return LanguageCertificateGroupEntityFactory(item)
  }

  /**
   * Gets the system's catalogue of Japanese Business skills.
   */
  async GetJapaneseBusinessSkills(): Promise<JapaneseBusinessEntity[]> {
    const items = await this.apiClient.request(new GetJapaneseBusinessSkillnRequest())
    return items.map((el) => JapaneseBusinessEntityFactory(el))
  }

  /**
   * Updates the Candidate's Language Skills.
   * @param entity The skills to be updated.
   */
  async UpdateCandidateSkills(entity: CandidateJapaneseLanguageEntity) {
    const item = await this.apiClient.request(new PutCandidateJapaneseSkills(toCandidateJapaneseSkillsSeed(entity.props)))
    return item ? toCandidateJapaneseSkillsProps(item) : null
  }

  /**
   * Fetch the available JP conversation levels.
   */
  async GetJapaneseSkillsList(locale: LanguageLocale): Promise<IJapaneseConversation[]> {
    return this.apiClient.request(new GetJapaneseSkillListRequest(locale))
  }
}
