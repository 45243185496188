import { AttributeProperty } from 'vue-meta'
import { LanguageISO6391 } from '../../../../../../../core/src/models/language/constants/language-iso639-1.enum'
import { LocalizationService } from '../../../language/localization-service'

/**
 * Generates attributes used for html tag
 */
export class HtmlAttributesFactory {
  static basicAttributes(lang: LanguageISO6391): AttributeProperty {
    return { lang }
  }
}
