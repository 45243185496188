import { IsDefined, IsNumber, IsOptional } from 'class-validator';

/**
 * The DTO for updating the visa type of the candidate
 */
export class CandidateVisaTypeDto {
  /**
   * The unque identifier of the visa type of the candidate.
   */
  @IsNumber()
  visaTypeId: number;

  /**
   * The unque identifier of the visa sub type of the candidate.
   */
  @IsOptional()
  @IsNumber()
  visaSubTypeId?: number;
}
