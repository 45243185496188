import { ArrayMinSize, IsArray, IsDefined } from 'class-validator';
import { IContact } from '../../contact';

/**
 * Used when performing data save operations for the User's contact information.
 */
export class UserContactsDto {
  /**
   * User's updated contact infomation.
   */
  @IsArray()
  @ArrayMinSize(1)
  @IsDefined()
  contacts: IContact[];
}
