import { IVacancy, LocalizationData, LocalizationPath } from '../../../../../core/src'
import { VacancyGetters } from '../../../../../core/src/domain/vacancy/getters/vacancy.getters'
import { LocalizationService } from '../language'
import { WageCardText } from '../wage/wage-card-text'

export class VacancyCardText {
  /**
   * Returns an array of wage formatted strings.
   * @param vacancy
   * @returns
   */
  static getFormattedWage(
    localization: LocalizationData,
    vacancy: IVacancy,
    textSeparator: string = '~',
    textPeriodSeparator: string = '/',
    quantityPrefix: string = ''
  ): string[] {
    if (!VacancyGetters.hasWages(vacancy)) {
      return [LocalizationService.t<string>(localization, LocalizationPath.HasNoWageDefined)]
    }
    return vacancy.wages?.map((e) => WageCardText.getFormattedWage(localization, e, textSeparator, textPeriodSeparator, quantityPrefix))!
  }

  /**
   * Handled differently from a normal vacancy, since the vacancy map interface
   * includes extra data related to its wages.
   * @param vacancy
   */
  static getMapFormattedWage(localization: LocalizationData, vacancy?: IVacancy): string {
    if (!vacancy) return ''
    if (!vacancy.wages || vacancy.wages.length === 0) return ''
    if (vacancy.wages.length > 1) return LocalizationService.t<string>(localization, LocalizationPath.ManyWagesAndShift)
    return WageCardText.getFormattedWage(localization, vacancy.wages[0])
  }

  /**
   * Returns exactly one wage with prioritization
   * @param vacancy
   * @param textSeparator
   * @param textPeriodSeparator
   * @param quantityPrefix
   * @param removeAdverbSuffix
   * @returns
   */
  static getFormattedPrioritizedWage(
    localization: LocalizationData,
    vacancy: IVacancy,
    textSeparator: string = '~',
    textPeriodSeparator: string = '/',
    quantityPrefix: string = ''
  ): string[] {
    if (!VacancyGetters.hasWages(vacancy)) {
      return [LocalizationService.t<string>(localization, LocalizationPath.HasNoWageDefined)]
    }
    const wage = WageCardText.getPrioritizedWage(vacancy.wages!)
    return [WageCardText.getFormattedWage(localization, wage, textSeparator, textPeriodSeparator, quantityPrefix)]
  }

  /**
   * Handled differently from a normal vacancy, since the vacancy map interface
   * includes extra data related to its wages.
   * @param vacancy
   */
  static getMapFormattedPrioritizedWage(localization: LocalizationData, vacancy?: IVacancy): string {
    if (!vacancy || !vacancy.wages || vacancy.wages?.length === 0) return ''
    const wage = WageCardText.getPrioritizedWage([vacancy.wages[0]])
    return WageCardText.getFormattedWage(localization, wage)
  }

  /**
   * Returns employmentPeriodMonths formatted into a string with 'months'.
   * @param vacancy
   * @returns
   */
  static getEmployementPeriod(localization: LocalizationData, vacancy: IVacancy): string | undefined {
    if (vacancy.employmentPeriodMonths) {
      if (vacancy.employmentPeriodMonths > 1)
        return LocalizationService.t<string>(localization, LocalizationPath.Months, [vacancy.employmentPeriodMonths.toString()])
      return LocalizationService.t<string>(localization, LocalizationPath.Month, [vacancy.employmentPeriodMonths.toString()])
    }
    return undefined
  }

  /**
   * Returns TrialPeriod formatted into a string with 'months'.
   * @param vacancy
   * @returns
   */
  static getTrialPeriod(localization: LocalizationData, vacancy: IVacancy): string | undefined {
    if (vacancy.trialPeriodMonths) {
      if (vacancy.trialPeriodMonths > 1)
        return LocalizationService.t<string>(localization, LocalizationPath.Months, [vacancy.trialPeriodMonths.toString()])
      return LocalizationService.t<string>(localization, LocalizationPath.Month, [vacancy.trialPeriodMonths.toString()])
    }
    return undefined
  }

  /**
   * Return an array of strings describing Raise and Bonus details for a vacancy
   * @param IWage
   * @returns
   */
  static getRaisesAndBonuses(localization: LocalizationData, vacancy: IVacancy): string[] | undefined {
    const labelArray = []
    if (vacancy.bonus) labelArray.push(LocalizationService.t<string>(localization, LocalizationPath.YearlyBonus))
    // if (vacancy.bonusAchievements) labelArray.push(RaisesAndBonuses.AchievementBasedBonus);
    if (vacancy.salaryIncrease) labelArray.push(LocalizationService.t<string>(localization, LocalizationPath.SalaryIncrease))
    // if (vacancy.otherSalariesAndBonusesDetails) labelArray.push(vacancy.otherSalariesAndBonusesDetails);
    if (vacancy.otherSalariesAndBonuses)
      labelArray.push(LocalizationService.t<string>(localization, LocalizationPath.IndividualIncentives))
    if (labelArray.length > 0) return labelArray
    return undefined
  }
}
