import { Attachment, AttachmentSeed } from '~/modules/app/network/schema/models'
import { AttachmentType } from '~/modules/app/network/schema/models/attachment.type'
import { ICandidateVisaAttachmentProps, ICandidateVisaAttachmentSeed } from '../../entities/candidate.visa.attachment'

/**
 * Creates a new seed item from the provided props.
 * @param value
 * @returns
 */
export const toCandidateVisaAttachmentSeed = (value: ICandidateVisaAttachmentProps): ICandidateVisaAttachmentSeed => {
  const { attachment, attachmentThumbnail, id } = value
  const seedAttachment = attachmentToAttachmentSeed(attachment)
  let seedAttachmentThumbnail
  if (attachmentThumbnail) {
    seedAttachmentThumbnail = attachmentToAttachmentSeed(attachmentThumbnail)
  }
  return {
    id,
    attachment: seedAttachment,
    attachmentThumbnail: seedAttachmentThumbnail
  }
}

const attachmentToAttachmentSeed = (value: Attachment): AttachmentSeed => {
  return {
    attachmentType: AttachmentType.TemporaryImage,
    file: {} as File,
    description: '',
    ...value
  }
}
