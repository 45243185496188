import { IsEnum } from 'class-validator';
import { LanguageLocale } from '../../language';

/**
 * Dto for when a user has requested to update theit locale
 */
export class UserLocaleChangeDto {
  @IsEnum(LanguageLocale)
  locale: LanguageLocale;
}
