import { ICandidate } from '../../../models';
import { CompositeSpecification } from '../../base/specification';

/**
 * Whether the provided candidate has a residence card (front) attachment or not.
 */
export class CandidateHasResidenceCardFrontSpecification extends CompositeSpecification<ICandidate> {
  isSatisfiedBy(candidate: ICandidate): boolean {
    if (candidate.candidateVisa?.cardFrontId) return true;
    if (candidate.candidateVisa?.cardFront?.id) return true;
    return false;
  }
}
