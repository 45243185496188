import { IsNumber, IsOptional, IsString } from 'class-validator';

export class UserUpdateContactDto {
  @IsOptional()
  id?: number

  /**
   * Id, account, etc. of the contact data.
   * @example "myemail@gmail.com", "070-1234-1234", "myLI.NEid"
   */
  @IsString()
  account: string;

  /**
   * Identifier of the contact method. Mostly used when the contactMethod object is not available.
   */
  @IsNumber()
  contactMethodId: number;
}
