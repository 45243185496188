import { UserPlatform } from '../../user/constants/user-platform';
import { UserType } from '../../user/constants/user-type.enum';
import { AnalyticsUserPrefixMap } from '../constants/analytics-user-prefix-map';

/**
 * Builds strings used for event tracking.
 * @remarks The event name needs to follow an easy to understand convention in the form of [UserTypeID]-[PageID]-[EventId].
 * For example "CW-Bot.Onboarding.VisaTypeForm-VisaTypeDropdown.Open".
 */
export class AnalyticsEventsBuilder {
  /**
   * Name of the page in which the event took place.
   */
  private _page: string;

  /**
   * The name of the event that was triggered.
   * @example "Saved", "Visited", etc. Look at the analytics/constants/*.events.enum.ts files to check available events.
   */
  private _event: string;

  /**
   *
   * @param userType The user type for which the event is being triggered.
   */
  constructor(public userType: UserType, public userPlatform: UserPlatform) {}

  /**
   * The page in which the event takes place.
   * In order to ensure a correct page name, should use an Analytics Pages enum like AnalyticsCandidateMessengerPages.
   * @param name
   * @example "Onboarding.Name"
   * @returns
   */
  page(name: string): AnalyticsEventsBuilder {
    this._page = name;
    return this;
  }

  /**
   * The name of the specific event that is being triggered.
   * In order to ensure a correct event name, should use an Analytics Event enum.
   * @param name
   * @returns
   */
  event(name: string): AnalyticsEventsBuilder {
    this._event = name;
    return this;
  }

  /**
   * Generates a string based on the user type and provided information.
   * @returns
   */
  build(): string {
    if (!this._page) throw new Error('No page was specified');
    if (!this._event) throw new Error('No event was specified');
    const separator = '-';
    const userPrefix = AnalyticsUserPrefixMap[this.userType][this.userPlatform];
    return `${userPrefix}${separator}${this._page}${separator}${this._event}`;
  }
}
