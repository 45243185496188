import { LanguageLocale } from '../../../models';
import { IFilterableDataSetDictionary } from '../../../models/common/filter/i-filterable-data-set-dictionary';
import { IFilterableDataSetItem } from '../../../models/common/filter/i-filterable-data-set-item';
import { IVisaType } from '../../../models/visa-type/models/i-visa-type';

export class VisaPathHandler {
  getVisaTypeId(visaPath: number[]): number | undefined {
    if (!visaPath) return undefined;
    if (visaPath.length < 2) return undefined;
    // visa type ids can only ever be in the second position
    return visaPath[1];
  }

  getVisaSubTypeId(visaPath: number[]) {
    if (!visaPath) return undefined;
    if (visaPath.length < 3) return undefined;
    // subtypes are only available in arrays with 3 to 5 items
    // the last item is the subtype id
    return visaPath[visaPath.length - 1];
  }

  getVisaType(visaPath: number[], visaHierarchy: IFilterableDataSetDictionary): IFilterableDataSetItem | undefined {
    // use the path to navigate through each level of the hierarchy
    if (!visaPath) return undefined;
    if (visaPath.length < 2) return undefined;
    const visaCategory = visaHierarchy[visaPath[0]];
    if (!visaCategory || !visaCategory.children) return undefined;
    const visaType = visaCategory?.children[visaPath[1]];
    return visaType;
  }

  getVisaSubType(visaPath: number[], visaHierarchy: IFilterableDataSetDictionary): IFilterableDataSetItem | undefined {
    // use the path to navigate through each level of the hierarchy
    const visaType = this.getVisaType(visaPath, visaHierarchy);
    if (!visaType) return undefined;
    // for subtype, we can go up to 5 levels deep
    if (visaPath.length < 3 || !visaType.children) return undefined;
    const visaSubType = visaType?.children[visaPath[2]];
    if (visaSubType && visaSubType.children && visaPath.length == 4) {
      const visaLevel2 = visaSubType.children[visaPath[3]];
      return visaLevel2;
    } else if (visaSubType && visaSubType.children && visaPath.length == 5) {
      const visaLevel2 = visaSubType.children[visaPath[3]];
      if (!visaLevel2 || !visaLevel2.children) return undefined;
      const visaLevel3 = visaLevel2.children[visaPath[4]];
      return visaLevel3;
    }
    return visaSubType;
  }

  getVisaTypeObject(
    visaPath: number[],
    visaHierarchy: IFilterableDataSetDictionary,
    locale: LanguageLocale,
  ): IVisaType | undefined {
    const visaType = this.getVisaType(visaPath, visaHierarchy);
    if (!visaType) return undefined;
    const obj: IVisaType = {
      id: parseInt(visaType.id),
      i18n: [{ locale, name: visaType.name }],
    };
    return obj;
  }

  getVisaSubTypeObject(
    visaPath: number[],
    visaHierarchy: IFilterableDataSetDictionary,
    locale: LanguageLocale,
  ): IVisaType | undefined {
    const visaSubType = this.getVisaSubType(visaPath, visaHierarchy);
    if (!visaSubType) return undefined;
    const obj: IVisaType = {
      id: parseInt(visaSubType.id),
      i18n: [{ locale, name: visaSubType.name }],
    };
    return obj;
  }
}
