import { QueryStringEntity } from '@tokyojob/frontend-core'
import {
  GetRequest,
  DeleteRequest,
  UpdateRequest,
  CreateRequest,
  FetchRequest
} from '../network/requests/contact'
import ContactEntity, {
  ContactEntityFactory,
  IContactProps
} from '../entities/contact'
import { toContactProps, toContactSeed } from './translator/contact'
import { GatewayBase } from '@/base/gateways/base'

export default class Contact extends GatewayBase {
  async Fetch(options?: QueryStringEntity): Promise<ContactEntity[]> {
    const items = await this.apiClient.request(new FetchRequest(options))
    return items.map((item) => ContactEntityFactory(item))
  }

  async Get(id: number): Promise<IContactProps | null> {
    const item = await this.apiClient.request(new GetRequest(id))
    return item ? toContactProps(item) : null
  }

  async Post(entity: ContactEntity): Promise<IContactProps | null> {
    const item = await this.apiClient.request(
      new CreateRequest(toContactSeed(entity))
    )
    return item ? toContactProps(item) : null
  }

  async Put(entity: ContactEntity): Promise<IContactProps | null> {
    const item = await this.apiClient.request(
      new UpdateRequest(entity.id!, toContactSeed(entity))
    )
    return item ? toContactProps(item) : null
  }

  async Delete(id: number): Promise<boolean> {
    return await this.apiClient.request(new DeleteRequest(id))
  }
}
