import JapaneseListening from '../network/schema/models/japanese.listening'

/**
 * Interface used as a base when reading the Candidate's Japanese listening skills data.
 */
export interface IJapaneseListeningProps extends JapaneseListening {}

/**
 * Entity containing a candidate's listening skills.
 * TODO: Implement proper i18n for all translatable props
 */
export default class JapaneseListeningEntity {
  private _props: IJapaneseListeningProps

  constructor(props: IJapaneseListeningProps) {
    this._props = props
  }

  /**
   * Raw properties of the entity. Used in cases when the data provided by the entity's getters and setters are insufficient.
   * For most cases, consider adding another getter / setter instead of interacting directly with the props.
   */
  get props(): IJapaneseListeningProps {
    return this._props
  }

  /**
   * The id of the listening  skill.
   */
  get id(): number {
    return this.props.id
  }

  /**
   * The name of the listening  skill.
   */
  get name(): string {
    if(this.props.info?.length === 0) return this.props.info[0].name
    return ''
  }

  /**
   * Explains what this item is.
   */
  get description(): string | null {
    if(this.props.info?.length === 0) return this.props.info[0].description || null
    return ''
  }

  // getByLanguage(locale: string): JapaneseListeningInfo {
  //   const infos = this._props.informations.filter((information) => {
  //     return information.locale === locale
  //   })
  //   return infos[0] || null
  // }

  // getJapanese(): JapaneseListeningInfo {
  //   return this.getByLanguage('JA-jp')
  // }
}

/**
 * Generates an IJapaneseListeningProps object. Used to add extra properties during the conversion process.
 * @param props Data to use when creating the object.
 */
export const JapaneseListeningPropsFactory = (
  props: IJapaneseListeningProps
): IJapaneseListeningProps => ({
  ...props
})

/**
 * Generates a JapaneseListeningEntity object.
 * @param props Data to use when creating the object.
 */
export const JapaneseListeningEntityFactory = (
  props: IJapaneseListeningProps
): JapaneseListeningEntity => {
  return new JapaneseListeningEntity(JapaneseListeningPropsFactory(props))
}
