import JapaneseConversation from '../network/schema/models/japanese.conversation'

/**
 * Interface used as a base when conversation the Candidate's Japanese conversation skills data.
 */
export interface IJapaneseConversationProps extends JapaneseConversation {}

/**
 * Entity containing a candidate's conversation skills.
 * TODO: Implement proper i18n for all translatable props
 */
export default class JapaneseConversationEntity {
  private _props: IJapaneseConversationProps

  constructor(props: IJapaneseConversationProps) {
    this._props = props
  }

  /**
   * Raw properties of the entity. Used in cases when the data provided by the entity's getters and setters are insufficient.
   * For most cases, consider adding another getter / setter instead of interacting directly with the props.
   */
  get props(): IJapaneseConversationProps {
    return this._props
  }

  /**
   * The id of the conversation  skill.
   */
  get id(): number {
    return this.props.id
  }

  /**
   * The name of the conversation  skill.
   */
  get name(): string {
    if(this.props.info?.length === 0) return this.props.info[0].name
    return ''
  }

  /**
   * Explains what this item is.
   */
  get description(): string | null {
    if(this.props.info?.length === 0) return this.props.info[0].description || null
    return ''
  }

  // getByLanguage(locale: string): JapaneseConversationInfo {
  //   const infos = this._props.informations.filter((information) => {
  //     return information.locale === locale
  //   })
  //   return infos[0] || null
  // }

  // getJapanese(): JapaneseConversationInfo {
  //   return this.getByLanguage('JA-jp')
  // }
}

/**
 * Generates an IJapaneseConversationProps object. Used to add extra properties during the conversion process.
 * @param props Data to use when creating the object.
 */
export const JapaneseConversationPropsFactory = (
  props: IJapaneseConversationProps
): IJapaneseConversationProps => ({
  ...props
})

/**
 * Generates a JapaneseConversationEntity object.
 * @param props Data to use when creating the object.
 */
export const JapaneseConversationEntityFactory = (
  props: IJapaneseConversationProps
): JapaneseConversationEntity => {
  return new JapaneseConversationEntity(JapaneseConversationPropsFactory(props))
}
