import { ObjectComparer } from '../object';

export class ArrayComparer {
  /**
   * Compares two arrays of objects for equality based on specified properties.
   * @param arr1 - The first array to compare.
   * @param arr2 - The second array to compare.
   * @param properties - The list of properties to check for equality.
   * @returns True if the arrays are equal, false otherwise.
   */
  static areArraysEqual<T>(arr1: T[], arr2: T[], properties: (keyof T)[]): boolean {
    // If both arrays are null or undefined, they are considered equal.
    if (!arr1 && !arr2) return true;

    // If only one of the arrays is null or undefined, they are not equal.
    if (!arr1 || !arr2) return false;

    // If the arrays have different lengths, they are not equal.
    if (arr1.length !== arr2.length) return false;

    // Iterate through each element in the arrays.
    for (let i = 0; i < arr1.length; i++) {
      // Use ObjectComparer to compare the properties of each object.
      if (!ObjectComparer.arePropertiesEqual(arr1[i], arr2[i], properties)) {
        return false;
      }
    }
    // If all properties of all elements are equal, the arrays are considered equal.
    return true;
  }
}
