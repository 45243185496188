import VueRouter, { Route } from 'vue-router'
import { Dictionary } from '../../../../../../../../../core/src/utils/types/utils'

/**
 * This class encapsulates the process of replacing the query string in the URL.
 * This was done this way for ease of testing.
 */
export class RouterReplaceHelper {
  /**
   * Replaces the query string in the URL.
   * @param route
   * @param router
   * @param query
   */
  replace(query: any, App: any, router?: VueRouter, route?: Route, additionalQuery: Dictionary<string | (string | null)[]> = {}) {
    if (App) App.state.formattedFilters = JSON.stringify(query)
    // We only need to save the passed query but the actualy URL query should be the combined additionalQuery and query
    // additionalQuery contains the query data that is not part of the vacancy filters.
    // Ex. lowerLeftLng (map bounds in the map page)
    const resultQuery = {
      ...query,
      ...additionalQuery
    }
    // Kind of stupid but I don't have a choice.
    // Please forgive me glorious leader (man_bowing)
    // Did a JSON.stringify compare here to check if there is a query change in the original query.
    if (router && route && JSON.stringify(route.query) !== JSON.stringify(resultQuery))
      router.replace({ query: resultQuery }).catch()
  }
}
