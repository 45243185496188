import { FilterKey, IUser, LanguageLocale } from '@tokyojob/core'
import { FilterQueryManager } from '@tokyojob/frontend-components'
import { getApplicationStore, JWTHandler } from '@tokyojob/frontend-core'
import { IApp } from '~/types/nuxt'
import { SelectedVacancyFiltersDomain } from '../app/components/c-filter/util/selected-vacancy-filters-domain'
import { GetCandidateApplicationIdAndVacancyIdListUsecase } from '../application/usecases/get-candidate-application-id-and-vacancy-id-list.usecase'
import { CandidateFullProfileUsecase } from './usecases/candidate-full-profile.usecase'
import SaveUseCase from './usecases/save.usecase'
import { GetCandidateDashboardStatsUsecase } from './usecases/candidate-dashboard-stats.usecase'
import { getAppStore } from '~/store/app.store'
import LocaleUpdateUseCase from './usecases/locale-update.usecase'

/**
 * Pulls the candidate profile data, saves the data in Pinia, and override the currently selected vacancy visa type filter
 */
export class CandidateFullProfileDomain {
  constructor(
    private readonly App: IApp,
    private readonly candidateFullProfileUsecase: CandidateFullProfileUsecase,
    private readonly saveUseCase: SaveUseCase,
    private readonly getCandidateApplicationIdAndVacancyIdListUsecase: GetCandidateApplicationIdAndVacancyIdListUsecase,
    private readonly jwtHandler: JWTHandler,
    private readonly filterQueryManager: FilterQueryManager,
    private readonly selectedVacancyFiltersDomain: SelectedVacancyFiltersDomain,
    private readonly localeUpdateUsecase: LocaleUpdateUseCase
  ) {}

  async fullFetchRequest(locale: LanguageLocale = LanguageLocale.USen): Promise<boolean> {
    if (!this.App.state.token || !this.App.state.isLoggedInCandidate) return false

    try {
      if (this.isTokenExpired()) await this.refreshTokens()
      await Promise.all([
        this.fetchCandidateProfile(locale),
        this.statsFetch(),
        this.fetchCandidateApplicationIdAndVacancyIdList()
      ])
      return true
    } catch (e) {
      return false
    }
  }

  async statsFetch(): Promise<void> {
    const appStore = this.getAppStore()
    if (appStore && this.App.state.isLoggedInCandidate) {
      const dashboardStatsUsecase = new GetCandidateDashboardStatsUsecase(this.App)
      const stats = await dashboardStatsUsecase.execute()
      appStore.setDashboardStats(stats!)
    }
  }

  async fetchCandidateApplicationIdAndVacancyIdList(): Promise<void> {
    const result = await this.getCandidateApplicationIdAndVacancyIdListUsecase.execute()
    if (!result) return

    const applicationStore = this.getApplicationStore()
    applicationStore.save(result)
  }

  async saveProfileRequest(user: IUser, locale: LanguageLocale = LanguageLocale.USen): Promise<boolean> {
    const result = await this.saveUseCase.execute(user, locale)
    if (!result || !result.result) return false
    this.saveProfile(result.updatedProfile)
    return true
  }

  /**
   * Returns true of the token is expired, else returns false
   * @returns
   */
  isTokenExpired() {
    if (!this.App.state.token) return true
    return this.jwtHandler.isTokenExpired(this.App.state.token)
  }

  /**
   * Returns the pinia repository of app-candidate
   * @returns
   */
  getAppStore() {
    return getAppStore()
  }

  /**
   * Returns the pinia repository of application
   * @returns
   */
  getApplicationStore() {
    return getApplicationStore()
  }

  /**
   * Refreshes the user's tokens
   */
  async refreshTokens() {
    const response = await this.jwtHandler.refreshTokens(this.App.state, this.App.state.refreshToken!)
    this.jwtHandler.storeTokens(this.App.state, response)
  }

  /**
   * Pulls candidate profile data, saves the data in Pinia, and override the currently selected vacancy visa type filter
   * @param locale
   */
  async fetchCandidateProfile(locale: LanguageLocale) {
    const candidateFullProfile = await this.candidateFullProfileUsecase.execute(locale)
    this.saveProfile(candidateFullProfile)
  }

  /**
   * Saves the profile to the memory storage
   * @param user
   * @returns
   */
  saveProfile(user: IUser | null): void {
    if (!user) return
    const appStore = this.getAppStore()
    appStore.setProfile(user)
    if (user.candidate?.candidateVisa?.visaTypePath) this.overrideVacancyVisaTypeFilter(user.candidate.candidateVisa.visaTypePath)
    if (user.candidate?.japaneseConversationId) this.overrideJapaneseLevel(user.candidate.japaneseConversationId)
  }

  /**
   * Overrides the currently selected vacancy visa type filter
   * @param visaPath
   */
  overrideVacancyVisaTypeFilter(visaPath: number[]) {
    const dataSource = this.selectedVacancyFiltersDomain.getDataSource(FilterKey.visa)
    const item = this.selectedVacancyFiltersDomain.getDataFromIFilterableDataSetDictionary(dataSource, visaPath)
    if (item) this.filterQueryManager.modifyFilter(FilterKey.visa, item, this.App)
  }

  /**
   * Overrides the currently selected Japanese level filter
   * @param japaneseLevel
   */
  overrideJapaneseLevel(japaneseLevel: number) {
    const dataSource = this.selectedVacancyFiltersDomain.getDataSource(FilterKey.japanese)
    const item = this.selectedVacancyFiltersDomain.getDataFromIFilterableDataSetDictionary(dataSource, [japaneseLevel])
    if (item) this.filterQueryManager.modifyFilter(FilterKey.japanese, item, this.App)
  }

  /**
   * Update's the user's current locale
   * @param locale
   * @returns
   */
  async updateLocale(locale: LanguageLocale): Promise<boolean> {
    const appStore = this.getAppStore()
    if (!appStore || !this.App.state.isLoggedInCandidate) return false
    return this.localeUpdateUsecase.execute(locale)
  }
}
