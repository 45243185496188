import { LanguageLocale } from '@tokyojob/core'
import { APIRequest, HTTPMethod } from '@tokyojob/frontend-core'
import { SpaceContractType } from '../schema/namespace'

/**
 * Gets all available contract types.
 */
export class FetchContractTypeRequest
  implements APIRequest<SpaceContractType.FetchResponse> {
  response!: SpaceContractType.FetchResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor() {
    this.path = `/contract_type?locale=${LanguageLocale.USen}`
  }
}
