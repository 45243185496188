import { ICompositeSpecification } from './interfaces/i-composite-specification';

/**
 * Allows you to compose complex specifications by joining them with the provided conditions.
 * @remarks All specification base classes are in the same file to avoid circular dependency issues when compiling some projects.
 */
export abstract class CompositeSpecification<T> implements ICompositeSpecification<T> {
  abstract isSatisfiedBy(object: T): boolean;

  and(other: ICompositeSpecification<T>): ICompositeSpecification<T> {
    return new AndSpecification<T>(this, other);
  }

  or(other: ICompositeSpecification<T>): ICompositeSpecification<T> {
    return new OrSpecification<T>(this, other);
  }

  not(): ICompositeSpecification<T> {
    return new NotSpecification<T>(this);
  }

  andNot(other: ICompositeSpecification<T>): ICompositeSpecification<T> {
    return new AndNotSpecification<T>(this, other);
  }

  orNot(other: ICompositeSpecification<T>): ICompositeSpecification<T> {
    return new OrNotSpecification<T>(this, other);
  }
}

/**
 * Equivalent of an && operator
 */
export class AndSpecification<T> extends CompositeSpecification<T> {
  constructor(public left: ICompositeSpecification<T>, public right: ICompositeSpecification<T>) {
    super();
  }

  isSatisfiedBy(object: T): boolean {
    return this.left.isSatisfiedBy(object) && this.right.isSatisfiedBy(object);
  }
}

/**
 * Equivalent of an || operator
 */
export class OrSpecification<T> extends CompositeSpecification<T> {
  constructor(public left: ICompositeSpecification<T>, public right: ICompositeSpecification<T>) {
    super();
  }

  isSatisfiedBy(object: T): boolean {
    return this.left.isSatisfiedBy(object) || this.right.isSatisfiedBy(object);
  }
}

/**
 * Equivalent of a negation ! operator.
 */
export class NotSpecification<T> extends CompositeSpecification<T> {
  constructor(public spec: ICompositeSpecification<T>) {
    super();
  }

  isSatisfiedBy(object: T): boolean {
    return !this.spec.isSatisfiedBy(object);
  }
}

/**
 * Equivalent of an && ! operator.
 */
export class AndNotSpecification<T> extends CompositeSpecification<T> {
  constructor(public left: ICompositeSpecification<T>, public right: ICompositeSpecification<T>) {
    super();
  }

  isSatisfiedBy(object: T): boolean {
    return this.left.isSatisfiedBy(object) && !this.right.isSatisfiedBy(object);
  }
}

/**
 * Equivalent of an || ! operator.
 */
export class OrNotSpecification<T> extends CompositeSpecification<T> {
  constructor(public left: ICompositeSpecification<T>, public right: ICompositeSpecification<T>) {
    super();
  }

  isSatisfiedBy(object: T): boolean {
    return this.left.isSatisfiedBy(object) || !this.right.isSatisfiedBy(object);
  }
}
