import { IApp } from '@/types/nuxt'
import { UserType } from '@tokyojob/core'
import { BaseUsecaseImpl, LogType } from '@tokyojob/frontend-core'

/**
 * Usecase to delete a candidate's education within a resume.
 */
export class EducationDeleteUsecase extends BaseUsecaseImpl {
  /**
   * @param app IApp instance used to access the Gateway and Log services.
   */
    constructor(private readonly app: IApp, userType: UserType) {
    super(app.state, userType)
  }

  /**
   * Deletes educations within a resume.
   * @param id
   * @param ids
   */
  async execute(id: number, ids: number[]): Promise<boolean> {
    const { baseGateway, logService } = this.app
    try {
      await this.evaluateAuth()
      return baseGateway.resume.DeleteResumeEducation(id, ids)
    } catch (error) {
      await logService.handle({ type: LogType.Error, error: error as Error })
      throw error
    }
  }
}
