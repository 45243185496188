
/**
 * Levels of Japanese reading ability.
 * @todo replace with a DB call
 */
export enum JapaneseReadingLevelEn {
  Zero = 'Cannot read in Japanese',
  Hiragana = 'Hiragana',
  Katakana = 'Hiragana + Katakana', 
  N5 = 'Basic sentences and Kanji',
  N4 = 'Understand common language',
  N3 = 'Good understanding',
  N2 = 'Business level',
  N1 = 'Domain of the language'
}