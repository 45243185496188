import { IAttachment } from "../../../models";

export class AttachmentGetters {
  /**
   * @remarks Only works for plain U
   * @param item
   */
  static getUrl(item: IAttachment): string {
    if (!item) return ''
    if (item.url) return item.url;
    if (!item.container || !item.path || !item.name) return ''
    // TODO: Move to a constant in models
    const S3Url = '.s3-ap-northeast-1.amazonaws.com/'
    return `https://${item.container}${S3Url}${item.path}${item.name}`;
  }
}
