import LanguageCertification from '../network/schema/models/language.certification'

/**
 * Interface used as a base when reading the Candidate's Japanese reading skills data.
 */
export interface ILanguageCertificationProps extends LanguageCertification {}

/**
 * Entity containing a language certification's information.
 */
export default class LanguageCertificationEntity {
  private _props: ILanguageCertificationProps

  constructor(props: ILanguageCertificationProps) {
    this._props = props
  }

  /**
   * Raw properties of the entity. Used in cases when the data provided by the entity's getters and setters are insufficient.
   * For most cases, consider adding another getter / setter instead of interacting directly with the props.
   */
  get props(): ILanguageCertificationProps {
    return this._props
  }

  /**
   * The id of the certification.
   */
  get id(): number {
    return this.props.id
  }

  /**
   * The name of the certification.
   */
  get name(): string {
    return this.props.info[0].name
  }

  /**
   * The order in which the certificate should be sorted when compared against other certificates in the same group.
   */
  get order(): number {
    return this.props.order
  }
}

/**
 * Generates an ILanguageCertificationProps object. Used to add extra properties during the conversion process.
 * @param props Data to use when creating the object.
 */
export const LanguageCertificationPropsFactory = (
  props: ILanguageCertificationProps
): ILanguageCertificationProps => ({
  ...props
})

/**
 * Generates a LanguageCertificationEntity object.
 * @param props Data to use when creating the object.
 */
export const LanguageCertificationEntityFactory = (
  props: ILanguageCertificationProps
): LanguageCertificationEntity => {
  return new LanguageCertificationEntity(
    LanguageCertificationPropsFactory(props)
  )
}
