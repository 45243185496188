import { ICity, LanguageLocale } from '../../models';
import { HepburnConverter } from '../../utils/language/japanese/hepburn-converter';
import { StringUtils } from '../../utils/string/string-utils';

export class CityGetters {
  static getName(city?: ICity, locale?: LanguageLocale): string {
    if (!city) return '';
    if (!city.i18n) return '';
    // if no locale is provided, return the first available item
    if (!locale) {
      if (!city.i18n[0].name) return '';
      return city.i18n[0].name;
    }
    // find the item that matches the locale
    const item = city.i18n.find((i) => i.locale === locale);
    if (!item || !item.name) return '';
    return item.name;
  }
  static getFuriganaName(city?: ICity, locale?: LanguageLocale): string {
    if (!city) return '';
    if (!city.i18n) return '';
    // if no locale is provided, return the first available item
    if (!locale) {
      if (!city.i18n[0].nameKana) return '';
      return StringUtils.Capitalize(city.i18n[0].nameKana);
    }
    // find the item that matches the locale
    const item = city.i18n.find((i) => i.locale === locale);
    if (!item || !item.nameKana) return '';
    return StringUtils.Capitalize(item.nameKana);
  }
  static getKatakanaName(city?: ICity): string {
    const converter = new HepburnConverter();
    if (!city) return '';
    if (!city.i18n) return '';
    // find the item that matches the locale
    const item = city.i18n.find((i) => i.locale === LanguageLocale.JAjp);
    if (!item || !item.nameKana) return '';
    return converter.convert(item.nameKana).katakana;
  }
}
