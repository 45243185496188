import { ICandidate, IJapaneseConversation, IUser, IVisaType, UserType } from '../../models';
import { IVacancy } from '../../models/vacancy/interface/i-vacancy';

export enum ApplyInvalidReason {
  NoVisa,
  InvalidVisa,
  NoJapanese,
  InvalidJapanese,
}

export class CandidateCanApply {
  /**
   * Validates if the user can apply to the vacancy
   * @param vacancy
   * @param user
   * @param formattedFilters
   * @param japaneseConversations
   * @returns
   */
  validate(
    vacancy: IVacancy,
    user: IUser,
    formattedFilters: string,
    japaneseConversations: IJapaneseConversation[],
  ): ApplyInvalidReason[] {
    // User is not logged in
    if (!user) {
      return this.validateGuestCandidate(vacancy, formattedFilters, japaneseConversations);
    }

    // User is either agent or admin
    const candidate = user.candidate;
    if (!candidate) {
      return [];
    }

    // User is a logged in candidate
    return this.validateRegisteredCandidate(vacancy, candidate);
  }

  /**
   * Validation process for guest candidate
   * @param vacancy
   * @param formattedFilters
   * @param japaneseConversations
   * @returns
   */
  validateGuestCandidate(
    vacancy: IVacancy,
    formattedFilters: string,
    japaneseConversations: IJapaneseConversation[],
  ): ApplyInvalidReason[] {
    const invalidReason = [];

    // if formattedFilters is not present, then the visa and japanese conversation is invalid
    if (!formattedFilters) {
      invalidReason.push(ApplyInvalidReason.NoVisa);
      invalidReason.push(ApplyInvalidReason.NoJapanese);
      return invalidReason;
    }

    // if formattedFilters is present, then we need to validate the visa and japanese conversation
    // parsing formattedFilters to an object will give us the visa and japanese conversation with ease
    const formattedFiltersObj = JSON.parse(formattedFilters);

    // visa type validation section
    // if visa type is not present, then the visa is invalid
    // if visa type is present, then we need to extract the visa type id and visa sub type id
    // then validate if either of the two ids are allowed in the vacancy
    const visaTypeQuery = formattedFiltersObj['visa'] as string;
    if (!visaTypeQuery) {
      invalidReason.push(ApplyInvalidReason.NoVisa);
    } else {
      try {
        const visaTypeIds = visaTypeQuery
          .split('_')[0]
          .split('-')
          .map((e) => parseInt(e));
        const visaTypeId = visaTypeIds[0];
        const visaSubTypeId = visaTypeIds[1];
        if (!this.isVisaAllowed(vacancy.allowedVisaTypes || [], visaTypeId, visaSubTypeId)) {
          invalidReason.push(ApplyInvalidReason.InvalidVisa);
        }
      } catch (error) {
        invalidReason.push(ApplyInvalidReason.InvalidVisa);
      }
    }

    // japanese conversation validation section
    // if japanese conversation is not present, then the japanese conversation is invalid
    // if japanese conversation is present, then we need to extract the japanese conversation id
    // then validate if the japanese is allowed in the vacancy via order
    const japaneseQuery = formattedFiltersObj['japanese'] as string;
    if (!japaneseQuery) {
      invalidReason.push(ApplyInvalidReason.NoJapanese);
    } else {
      try {
        const candidateJapaneseConversationId = parseInt(japaneseQuery);
        const candidateJapaneseConversation = japaneseConversations.find((e) => e.id === candidateJapaneseConversationId) || {};
        const vacancyJapaneseConversation = vacancy.japaneseConversation || {};
        if (!this.isJapaneseAllowed(candidateJapaneseConversation, vacancyJapaneseConversation)) {
          invalidReason.push(ApplyInvalidReason.InvalidJapanese);
        }
      } catch (error) {
        invalidReason.push(ApplyInvalidReason.InvalidJapanese);
      }
    }

    return invalidReason;
  }

  /**
   * Validation process for registered candidate
   * @param vacancy
   * @param candidate
   * @returns
   */
  validateRegisteredCandidate(vacancy: IVacancy, candidate: ICandidate): ApplyInvalidReason[] {
    const invalidReason = [];

    // visa type validation section
    // direclty validate if either of the two ids are allowed in the vacancy
    const candidateVisa = candidate.candidateVisa;
    const visaTypeId = candidateVisa?.visaType?.id || 0;
    if (!visaTypeId) {
      invalidReason.push(ApplyInvalidReason.NoVisa);
    } else {
      const visaSubTypeId = candidateVisa?.visaSubType?.id || 0;
      const isVisaAllowed = this.isVisaAllowed(vacancy.allowedVisaTypes || [], visaTypeId, visaSubTypeId);
      if (!isVisaAllowed) {
        invalidReason.push(ApplyInvalidReason.InvalidVisa);
      }
    }

    // japanese conversation validation section
    // direclty validate if the japanese is allowed in the vacancy via order
    const candidateJapaneseConversation = candidate.japaneseConversation || {};
    if (!candidateJapaneseConversation.id) {
      invalidReason.push(ApplyInvalidReason.NoJapanese);
    } else {
      const vacancyJapaneseConversation = vacancy.japaneseConversation || {};
      const isJapaneseAllowed = this.isJapaneseAllowed(candidateJapaneseConversation, vacancyJapaneseConversation);
      if (!isJapaneseAllowed) {
        invalidReason.push(ApplyInvalidReason.InvalidJapanese);
      }
    }

    return invalidReason;
  }

  /**
   * Validates if the candidate's visa is allowed in the vacancy
   * @param allowedVisaTypes
   * @param visaTypeId
   * @param visaSubTypeId
   * @returns
   */
  isVisaAllowed(allowedVisaTypes: IVisaType[], visaTypeId: number, visaSubTypeId: number): boolean {
    if (!allowedVisaTypes || allowedVisaTypes.length === 0) {
      return false;
    }
    return allowedVisaTypes.findIndex((e) => e.id === visaTypeId || e.id === visaSubTypeId) !== -1;
  }

  /**
   * Validates if the candidate's japanese conversation is allowed in the vacancy via order
   * @param candidateJapaneseConversation
   * @param vacancyJapaneseConversation
   * @returns
   */
  isJapaneseAllowed(
    candidateJapaneseConversation: IJapaneseConversation,
    vacancyJapaneseConversation: IJapaneseConversation,
  ): boolean {
    if (!candidateJapaneseConversation || !candidateJapaneseConversation) {
      return false;
    }
    return candidateJapaneseConversation.order! >= vacancyJapaneseConversation.order!;
  }
}
