import { Type } from 'class-transformer';
import {
  IsEmail,
  IsEnum, IsNotEmptyObject, IsObject, IsOptional, IsString, Length, NotEquals, ValidateNested
} from 'class-validator';
import { UserType } from '../../user/constants/user-type.enum';
import { UserUpdateDto } from '../../user/dto/update/user-update.dto';

export class DtoUserRegister {
  @IsEnum(UserType)
  @NotEquals(UserType.Admin)
  userType: UserType;

  @IsEmail()
  account: string;

  @Length(10, 100)
  password: string;

  /**
   * Agent only
   */
  @IsOptional()
  @IsString()
  company: string;

  @IsOptional()
  @IsObject()
  @IsNotEmptyObject()
  @ValidateNested()
  @Type(() => UserUpdateDto)
  userInfo?: UserUpdateDto
}
