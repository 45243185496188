import { MetaPropertyCharset, MetaPropertyEquiv, MetaPropertyMicrodata, MetaPropertyName, MetaPropertyProperty } from 'vue-meta'
import { LanguageLocale, LocalizationData, LocalizationPath } from '../../../../../../../core/src'
import { StaticFileUrls } from '../../../../base/constants/static-file-urls'
import { LocalizationService } from '../../../language/localization-service'
import { ogTypes } from '../../constants/og-types'
import { MetaTagFactory } from '../meta-tag.factory'

/**
 * Vacancy map meta tags.
 */
export class VacancyMapMetaFactory {
  static metaTags(
    localization: LocalizationData,
    locale: LanguageLocale
  ): (MetaPropertyCharset | MetaPropertyEquiv | MetaPropertyName | MetaPropertyMicrodata | MetaPropertyProperty)[] {
    const title: string = LocalizationService.t<string>(localization, LocalizationPath.VacancyMapPageMetaTagTitle)
    const description: string = LocalizationService.t<string>(localization, LocalizationPath.VacancyMapPageMetaTagDescription)
    const keywordsArray: string[] = LocalizationService.t<string[]>(localization, LocalizationPath.VacancyMapPageMetaTagKeywords)
    const titles = MetaTagFactory.titleTags(title)
    const twitter = MetaTagFactory.twitterTags()
    const siteName = MetaTagFactory.siteName()
    const descriptions = MetaTagFactory.descriptionTags(description)
    const keywords = MetaTagFactory.keywords(localization, keywordsArray)
    const locales = MetaTagFactory.localeTags(locale)
    const robots = MetaTagFactory.indexedRobots()
    const ogType = MetaTagFactory.type(ogTypes.jobMap)
    const images = MetaTagFactory.imageTags(StaticFileUrls.facebookImage)
    return [...titles, ...twitter, siteName, ...descriptions, keywords, ...locales, robots, ogType, ...images]
  }
}
