import { delay } from './delay';

/**
 * Waits for the passed condition function to return true before resolving true.
 * If a positive timeoutDuration was passed this can resolve false when this Promise waited for timeoutDuration already.
 * @param condition
 * @param timeoutDuration
 * @returns
 */
export const waitFor = (condition: () => boolean, timeoutDuration: number = 0): Promise<boolean> => {
  const delayDuration = 100;
  let waitForDuration = timeoutDuration;
  return new Promise(async (resolve) => {
    while (true) {
      if (condition()) break;
      await delay(delayDuration);
      if (waitForDuration > 0) {
        waitForDuration -= delayDuration;
        if (waitForDuration <= 0) {
          console.log('timeout duration has been reached');
          return resolve(false);
        }
      }
    }
    resolve(true);
  });
};
