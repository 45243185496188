/**
 * Types of attachments handled in the application.
 */
export enum AttachmentType {
  /**
   * A candidate's language certificate image, like JLPT, TOEFL, etc.
   */
  CandidateLanguageCertificate = 2,
  /**
   * File used to import categories into the system.
   */
  CategoryImport = 3,
  /**
   * File used to import visa types into the system.
   */
  VisaTypeImportOld = 4,
  /**
   * Import for the location information, including cities, towns, and zip codes. Prefectures are not included, as those are done through seeding.
   */
  LocationImport = 6,
  /**
   * Import for transit stations. Trains, etc.
   */
  TransitStationImport = 7,
  /**
   * Import for train lines. JR Tohoku, etc.
   */
  TrainLineImport = 8,
  /**
   * Import for skills.
   */
  SkillImport = 9,
  /**
   * A vacancy's image, displayed in the public vacancy page.
   */
  VacancyImageType = 10,
  /**
   * A candidate's residence card. Includes both the front and the back.
   */
  ResidenceCard = 11,
  /**
   * A candidaate's designation papers. Only applies for some visa types.
   */
  DesignationPapers = 12,
  /**
   * A candidate's front residence card.
   */
  ResidenceCardFront = 13,
  /**
   * A candidate's back residence card.
   */
  ResidenceCardBack = 14,
  /**
   * Any non-image temporary file.
   */
  TemporaryFile = 15,
  /**
   * Any image temporary file. Image files will have a thumbnail autogenerated for them.
   */
  TemporaryImage = 16,
  /**
   * A candidate's resume attachment.
   */
  ResumeAttachment = 17,
  /**
   * A candidate's application resume attachment.
   */
  ApplicationResumeAttachment = 18,
  /**
   * A candidate's personal id attachment.
   */
  CandidateVisaAttachment = 19,
  /**
   * A vacancy picture's attachmment
   */
  VacancyPictureAttachment = 20,
  /**
   * A vacancy picture's edited
   */
  VacancyPictureEdited = 21,
  /**
   * A vacancy picture's thumbnail
   */
  VacancyPictureThumbnail = 22,
  /**
   * Industry data import, including translations.
   */
  IndustryImport = 23,
  /**
   * Job sector data import, including translations.
   */
  JobSectorImport = 24,
  /**
   * Region data import, including translations.
   */
  RegionImport = 25,
  /**
   * City data import, including translations.
   */
  CityImport = 26,
  /**
   * Visa Type Category import, including translations.
   */
  VisaTypeCategoryImport = 27,
  /**
   * Visa Type Level 2 import, including translations.
   */
  VisaTypeLevel2Import = 28,
  /**
   * Visa Type import, including translations.
   */
  VisaTypeImport = 29,
  /**
   * Visa Type Level 3 import, including translations.
   */
  VisaTypeLevel3Import = 30,
  /**
   * Visa Type Level 3 import, including translations.
   */
  VacancyIndustryMap = 31,
  JobImport = 32,
  /**
   * Photo in the resume
   */
  ResumePhoto = 33,
  VisaTypePresetImport = 34,
}
