import { GatewayBase } from '@/base/gateways/base'
import ContactEntity from '~/modules/app/entities/contact'
import CandidateJapaneseLanguageEntity from '~/modules/language/entities/candidate.japanese.language'
import CandidateVisaEntity from '~/modules/user/entities/candidate.visa'
import UserEntity from '~/modules/user/entities/user'
import {
  UpdateBirthdate, UpdateCandidateDesignationPaperFiles, UpdateCandidateGender, UpdateCandidateJapanArrivalDate, UpdateCandidatePersonalIdFiles, UpdateCandidatePersonalInformation, UpdateCandidateResidenceCardBackFile, UpdateCandidateResidenceCardFrontFile, UpdateCandidateVisaType, UpdateContactInformation,
  UpdateJapaneseConversationSkill,
  UpdateJapaneseListeningSkill,
  UpdateJapaneseReadingSkill,
  UpdateJapaneseWritingSkill
} from '../network/requests/candidate'
import {
  toCandidateBirthdateSeed,
  toCandidateContactSeed, toCandidateDesignationPapersSeed, toCandidateGenderSeed, toCandidateJapanArrivalDateSeed, toCandidateJapaneseConversationSeed,
  toCandidateJapaneseListeningSeed,
  toCandidateJapaneseReadingSeed,
  toCandidateJapaneseWritingSeed,
  toCandidatePersonalIdsSeed, toCandidatePersonalInformationSeed, toCandidateResidenceCardBackSeed, toCandidateResidenceCardFrontSeed, toCandidateVisaTypeSeed
} from './translator/candidate'

/**
 * Candidate onboarding related endpoints.
 */
export default class Onboarding extends GatewayBase {
  /**
   * Update current candidate's visa type and subtype.
   * @returns Promise<ICandidateVisaProps | null>
   */
  async UpdateVisaType(entity: CandidateVisaEntity, isEditing: boolean, onboardingCompleted: boolean): Promise<boolean> {
    const seed = toCandidateVisaTypeSeed(entity, isEditing, onboardingCompleted)
    const item = await this.apiClient.request(new UpdateCandidateVisaType(seed))
    return item
  }

  /**
   * Update current candidate's residence card files.
   * @returns Promise<ICandidateVisaProps | null>
   */
  async UpdateResidenceCardFrontFile(
    entity: CandidateVisaEntity,
    isEditing: boolean,
    onboardingCompleted: boolean
  ): Promise<boolean> {
    const seed = toCandidateResidenceCardFrontSeed(entity, isEditing, onboardingCompleted)
    const item = await this.apiClient.request(new UpdateCandidateResidenceCardFrontFile(seed))
    return item
  }

  /**
   * Update current candidate's residence card files.
   * @returns Promise<ICandidateVisaProps | null>
   */
  async UpdateResidenceCardBackFile(
    entity: CandidateVisaEntity,
    isEditing: boolean,
    onboardingCompleted: boolean
  ): Promise<boolean> {
    const seed = toCandidateResidenceCardBackSeed(entity, isEditing, onboardingCompleted)
    const item = await this.apiClient.request(new UpdateCandidateResidenceCardBackFile(seed))
    return item
  }

  /**
   * Update current candidate's designation papers.
   * @returns Promise<ICandidateVisaProps | null>
   */
  async UpdateDesignationPaperFiles(
    entity: CandidateVisaEntity,
    isEditing: boolean,
    onboardingCompleted: boolean
  ): Promise<boolean> {
    const seed = toCandidateDesignationPapersSeed(entity, isEditing, onboardingCompleted)
    const item = await this.apiClient.request(new UpdateCandidateDesignationPaperFiles(seed))
    return item
  }

  /**
   * Update current candidate's visa attachments
   * @returns Promise<boolean> containing the result of the request
   */
  async UpdatePersonalIdFiles(isEditing: boolean, onboardingCompleted: boolean): Promise<boolean> {
    const seed = toCandidatePersonalIdsSeed(isEditing, onboardingCompleted)
    const item = await this.apiClient.request(new UpdateCandidatePersonalIdFiles(seed))
    return item
  }

  /**
   * Update current candidate's personal information
   * @returns Promise<boolean>
   */
  async UpdatePersonal(entity: UserEntity, isEditing: boolean, onboardingCompleted: boolean): Promise<boolean> {
    const seed = toCandidatePersonalInformationSeed(entity, isEditing, onboardingCompleted)
    const item = await this.apiClient.request(new UpdateCandidatePersonalInformation(seed))
    return item
  }

  /**
   * Update current candidate's arrival date to Japan
   * @returns Promise<boolean>
   */
  async UpdateJapanArrivalDate(entity: UserEntity, isEditing: boolean, onboardingCompleted: boolean): Promise<boolean> {
    const seed = toCandidateJapanArrivalDateSeed(entity, isEditing, onboardingCompleted)
    const item = await this.apiClient.request(new UpdateCandidateJapanArrivalDate(seed))
    return item
  }

  /**
   * Update current candidate's gender
   */
  async UpdateGender(entity: UserEntity, isEditing: boolean, onboardingCompleted: boolean): Promise<boolean> {
    const seed = toCandidateGenderSeed(entity, isEditing, onboardingCompleted)
    const item = await this.apiClient.request(new UpdateCandidateGender(seed))
    return item
  }

  /**
   * Update current candidate's birthdate
   */
  async UpdateBirthdate(entity: UserEntity, isEditing: boolean, onboardingCompleted: boolean): Promise<boolean> {
    const seed = toCandidateBirthdateSeed(entity, isEditing, onboardingCompleted)
    const item = await this.apiClient.request(new UpdateBirthdate(seed))
    return item
  }

  /**
   * Update current candidate's contact information
   */
  async UpdateContactInformation(entities: ContactEntity[], isEditing: boolean, onboardingCompleted: boolean): Promise<boolean> {
    const seed = toCandidateContactSeed(entities, isEditing, onboardingCompleted)
    const item = await this.apiClient.request(new UpdateContactInformation(seed))
    return item
  }

  /**
   * Update current candidate's japanese conversation skill
   */
  async UpdateJapaneseConversationSkill(
    entity: CandidateJapaneseLanguageEntity,
    isEditing: boolean,
    onboardingCompleted: boolean
  ): Promise<boolean> {
    const seed = toCandidateJapaneseConversationSeed(entity, isEditing, onboardingCompleted)
    const item = await this.apiClient.request(new UpdateJapaneseConversationSkill(seed))
    return item
  }

  /**
   * Update current candidate's japanese listening skill
   */
  async UpdateJapaneseListeningSkill(
    entity: CandidateJapaneseLanguageEntity,
    isEditing: boolean,
    onboardingCompleted: boolean
  ): Promise<boolean> {
    const seed = toCandidateJapaneseListeningSeed(entity, isEditing, onboardingCompleted)
    const item = await this.apiClient.request(new UpdateJapaneseListeningSkill(seed))
    return item
  }

  /**
   * Update current candidate's japanese reading skill
   */
  async UpdateJapaneseReadingSkill(
    entity: CandidateJapaneseLanguageEntity,
    isEditing: boolean,
    onboardingCompleted: boolean
  ): Promise<boolean> {
    const seed = toCandidateJapaneseReadingSeed(entity, isEditing, onboardingCompleted)
    const item = await this.apiClient.request(new UpdateJapaneseReadingSkill(seed))
    return item
  }

  /**
   * Update current candidate's japanese writing skill
   */
  async UpdateJapaneseWritingSkill(
    entity: CandidateJapaneseLanguageEntity,
    isEditing: boolean,
    onboardingCompleted: boolean
  ): Promise<boolean> {
    const seed = toCandidateJapaneseWritingSeed(entity, isEditing, onboardingCompleted)
    const item = await this.apiClient.request(new UpdateJapaneseWritingSkill(seed))
    return item
  }
}
