import { GatewayBase } from '@/base/gateways/base'
import { FileHelper, IFile, IUIFile } from '@tokyojob/frontend-core'
import {
  OnboardingPersonalIdImageFileCreateRequest,
  OnboardingPersonalIdImageFileDeleteRequest,
  TemporaryFileCreateRequest,
  TemporaryImageFileCreateRequest
} from '../network/requests/file.storage'
import { AttachmentType } from '../network/schema/models/attachment.type'

/**
 * API Gateway to store files.
 * The difference between this and the Attachments gateway is that this one focuses purely
 * on file uploading, while the other one also handles Attachment row management.
 */
export default class FileStorage extends GatewayBase {
  /**
   * Uploads a temporary non-image file to our service, belonging to the currently logged in user.
   * It will be deleted 1 day after it has been created, unless copied over to another location
   * and associated with a row in our database.
   * @remarks This file is NOT associated with any attachment or data.
   * @param file
   * @returns
   */
  async PostTemporaryFile(file: File): Promise<IFile> {
    const formData = FileHelper.fileToFormData(file, AttachmentType.TemporaryFile)
    const item = await this.apiClient.request<IFile>(
      new TemporaryFileCreateRequest(formData)
    )
    return item
  }

  /**
   * Uploads a temporary image file to our service, belonging to the currently logged in user.
   * It will be deleted 1 day after it has been created, unless copied over to another location
   * and associated with a row in our database.
   * @remarks This file is NOT associated with any attachment or data.
   * The file will automatically generate a thumbnail for the image.
   * @param file
   * @returns
   */
  async PostTemporaryImageFile(file: File): Promise<IUIFile> {
    const formData = FileHelper.fileToFormData(file, AttachmentType.TemporaryImage)
    const item = await this.apiClient.request<IUIFile>(
      new TemporaryImageFileCreateRequest(formData)
    )
    return item
  }

  /**
   * Uploads the file and saves it in the database immediately.
   * @param file
   * @returns
   */
   async PostOnboardingPersonalIdImageFile(file: File): Promise<IUIFile> {
    const formData = FileHelper.fileToFormData(
      file,
      AttachmentType.CandidateVisaAttachment
    )
    const item = await this.apiClient.request<IUIFile>(
      new OnboardingPersonalIdImageFileCreateRequest(formData)
    )
    return item
  }

  /**
   * Uploads the file and saves it in the database immediately.
   * @param file
   * @returns
   */
  async DeleteOnboardingPersonalIdImageFile(id: number): Promise<boolean> {
    const result = await this.apiClient.request<boolean>(
      new OnboardingPersonalIdImageFileDeleteRequest(id)
    )
    return result
  }
}
