
import { EnumDictionary } from '../../../utils';
import { LanguageURL } from './language-url.enum';

/**
 * Typed representation of language in URLs.
 */
export const LanguageDatepickerMapper: EnumDictionary<LanguageURL, string> = {
  [LanguageURL.JP]: 'ja',
  [LanguageURL.EN]: 'en',
  [LanguageURL.VI]: 'vi',
  [LanguageURL.ZH]: 'zh-cn'
};
