import { ICountry } from '../../../../../../../core/src'
import { CountryEntity } from '../entities'

export class CountryFactory {
  static defaultProps(props?: Partial<ICountry>): ICountry {
    return {
      countryCode2Digits: '',
      countryCode3Digits: '',
      i18n: [],
      ...props,
    }
  }

  static new(props?: Partial<ICountry>): CountryEntity {
    return new CountryEntity(CountryFactory.defaultProps(props))
  }
}
