import { Weekdays, WeekdaysNamesJAjp, WeekdaysNamesUSen } from './constants/weekdays.enum'
import { IListItem } from '../../../base/interfaces/list.item'

/**
 * TODO Need to remove one of these and make it dynamic
 * 
 * A collection of list items containing the days of the week, usable for dropdowns etc.
 */
 export const WeekdaysListItemsJAjp: IListItem[] = [
  {
    id: Weekdays.Monday,
    name: WeekdaysNamesJAjp[Weekdays.Monday],
    tooltip: ''
  },
  {
    id: Weekdays.Tuesday,
    name: WeekdaysNamesJAjp[Weekdays.Tuesday],
    tooltip: ''
  },
  {
    id: Weekdays.Wednesday,
    name: WeekdaysNamesJAjp[Weekdays.Wednesday],
    tooltip: ''
  },
  {
    id: Weekdays.Thursday,
    name: WeekdaysNamesJAjp[Weekdays.Thursday],
    tooltip: ''
  },
  {
    id: Weekdays.Friday,
    name: WeekdaysNamesJAjp[Weekdays.Friday],
    tooltip: ''
  },
  {
    id: Weekdays.Saturday,
    name: WeekdaysNamesJAjp[Weekdays.Saturday],
    tooltip: ''
  },
  {
    id: Weekdays.Sunday,
    name: WeekdaysNamesJAjp[Weekdays.Sunday],
    tooltip: ''
  }
]

export const WeekdaysListItemsUSen: IListItem[] = [
  {
    id: Weekdays.Monday,
    name: WeekdaysNamesUSen[Weekdays.Monday],
    tooltip: ''
  },
  {
    id: Weekdays.Tuesday,
    name: WeekdaysNamesUSen[Weekdays.Tuesday],
    tooltip: ''
  },
  {
    id: Weekdays.Wednesday,
    name: WeekdaysNamesUSen[Weekdays.Wednesday],
    tooltip: ''
  },
  {
    id: Weekdays.Thursday,
    name: WeekdaysNamesUSen[Weekdays.Thursday],
    tooltip: ''
  },
  {
    id: Weekdays.Friday,
    name: WeekdaysNamesUSen[Weekdays.Friday],
    tooltip: ''
  },
  {
    id: Weekdays.Saturday,
    name: WeekdaysNamesUSen[Weekdays.Saturday],
    tooltip: ''
  },
  {
    id: Weekdays.Sunday,
    name: WeekdaysNamesUSen[Weekdays.Sunday],
    tooltip: ''
  }
]

/**
 * TODO Need to remove one of these and make it dynamic
 * 
 * Converts a list of numbers into the string representation of the day they represent.
 * Numbers that are not part of the Enum will be skipped.
 * @param numbers Numbers to convert into string representations.
 */
export const numbersToWeekdayStringJAjp = (numbers: number[]): string[] => {
  const result: string[] = []
  numbers.forEach((item) => {
    if (item in Weekdays) {
      const weekDayEnum = item as Weekdays
      result.push(WeekdaysNamesJAjp[weekDayEnum])
    }
  })
  return result
}

export const numbersToWeekdayStringUSen = (numbers: number[]): string[] => {
  const result: string[] = []
  numbers.forEach((item) => {
    if (item in Weekdays) {
      const weekDayEnum = item as Weekdays
      result.push(WeekdaysNamesUSen[weekDayEnum])
    }
  })
  return result
}
