
import CCandidateFooter from '../modules/app/components/app/footer/c-candidate-footer.component.vue'
import CTopbar from '~/modules/app/components/app/c-topbar/c-topbar.component.vue'
import CSidebar from '~/modules/app/components/app/c-sidebar/c-sidebar.component.vue'
import { defineComponent } from 'vue'
import { HtmlAttributesFactory } from '@tokyojob/frontend-core'

export default defineComponent({
  components: {
    CSidebar,
    CTopbar,
    CCandidateFooter
  },
  head() {
    return {
      htmlAttrs: HtmlAttributesFactory.basicAttributes(this.App.localizationSelector.languageISO6391)
    }
  }
})
