import 'reflect-metadata';
import { IsDefined } from 'class-validator';

export class AnonymousApplicationVerifyPhoneLocaleDto {
  @IsDefined()
  locale: string;

  @IsDefined()
  phoneNumber: string;
}
