import { IJapaneseConversation, IJapaneseConversationi18n } from '../../models';

export class JapaneseConversationGetters {
  static getProperty(item: IJapaneseConversation, property: keyof IJapaneseConversationi18n): string {
    if (!JapaneseConversationGetters.hasi18n(item)) return '';
    // TODO: Remove informations, this is used for backwards compatibility with old endpoints
    const i18n: IJapaneseConversationi18n[] = item.i18n || (item as any).informations;
    return i18n[0][property] || '';
  }
  static getLabel(item: IJapaneseConversation): string {
    return JapaneseConversationGetters.getProperty(item, 'name');
  }

  static getDescription(item: IJapaneseConversation): string {
    return JapaneseConversationGetters.getProperty(item, 'description');
  }
  
  static getValue(item: IJapaneseConversation): number {
    return item.id!;
  }

  static hasi18n(item: IJapaneseConversation): boolean {
    if (!item) return false;
    // TODO: Remove informations, this is used for backwards compatibility with old endpoints
    const i18n: IJapaneseConversationi18n[] = item.i18n || (item as any).informations;
    return !!(i18n && i18n.length > 0);
  }

  static getAgentLabel(item: IJapaneseConversation): string {
    if (!JapaneseConversationGetters.hasi18n(item)) return '';
    if (!item.i18n) return '';
    const i18n: IJapaneseConversationi18n[] = item.i18n || (item as any).informations;
    return i18n[0].nameAgent!;
  }
}
