import { EnumDictionary } from '../../../utils/types/enum-dictionary';
import { ApplicationCandidateSubStatusId } from './application-candidate-substatus-id.enum';

/**
 * CSS classes and styles usecd for each application SubStatus.
 * An empty string implies that the theme will depend on the parent status.
 * If both parent and child are empty, then the theme will be the default one.
 */
export const ApplicationCandidateTimelineSubStatusThemes: EnumDictionary<ApplicationCandidateSubStatusId, string> = {
  [ApplicationCandidateSubStatusId.NewApplicationWebGuest]: '',
  [ApplicationCandidateSubStatusId.NewApplicationWebAccount]: '',
  [ApplicationCandidateSubStatusId.NewApplicationIOS]: '',
  [ApplicationCandidateSubStatusId.NewApplicationMessenger]: '',
  [ApplicationCandidateSubStatusId.Memo]: '',
  [ApplicationCandidateSubStatusId.CheckWaitingForAgent]: '',
  [ApplicationCandidateSubStatusId.CheckOther]: '',
  [ApplicationCandidateSubStatusId.ContactingWaitingForAgentContact]: '',
  [ApplicationCandidateSubStatusId.ContactingAgentCouldNotGetInContactAfter3Attempts]: '',
  [ApplicationCandidateSubStatusId.ContactingAgentOther]: '',
  [ApplicationCandidateSubStatusId.RejecteVacancyFilled]: '',
  [ApplicationCandidateSubStatusId.RejecteCandidateWithdrew]: '',
  [ApplicationCandidateSubStatusId.RejecteInsufficientJapaneseLevel]: '',
  [ApplicationCandidateSubStatusId.RejecteInsufficientDocumentation]: '',
  [ApplicationCandidateSubStatusId.RejecteInvalidVisa]: '',
  [ApplicationCandidateSubStatusId.RejecteCouldNotGetInContact]: '',
  [ApplicationCandidateSubStatusId.RejecteCandidateMissedTheInterview]: '',
  [ApplicationCandidateSubStatusId.RejectedOther]: '',
  [ApplicationCandidateSubStatusId.InterviewWaitingForInterviewSchedule]: '',
  [ApplicationCandidateSubStatusId.InterviewScheduled]: '',
  [ApplicationCandidateSubStatusId.InterviewInterviewOther]: '',
  [ApplicationCandidateSubStatusId.HiredStarted]: '',
  [ApplicationCandidateSubStatusId.HiredCandidateWithdrewAfterEmployment]: '',
  [ApplicationCandidateSubStatusId.HiredFiredQuitAfterEmployment]: '',
  [ApplicationCandidateSubStatusId.HiredOther]: '',
  [ApplicationCandidateSubStatusId.WithdrawnIHaveAnotherOffer]: '',
  [ApplicationCandidateSubStatusId.WithdrawnNotInterestedInThePositionAnymore]: '',
  [ApplicationCandidateSubStatusId.WithdrawnLocationTooFarAway]: '',
  [ApplicationCandidateSubStatusId.WithdrawnJobNotWhatWasExpected]: '',
  [ApplicationCandidateSubStatusId.WithdrawnOther]: ''
};

export const ApplicationCandidateAGGridSubStatusThemes: EnumDictionary<ApplicationCandidateSubStatusId, string> = {
  [ApplicationCandidateSubStatusId.NewApplicationWebGuest]: '',
  [ApplicationCandidateSubStatusId.NewApplicationWebAccount]: '',
  [ApplicationCandidateSubStatusId.NewApplicationIOS]: '',
  [ApplicationCandidateSubStatusId.NewApplicationMessenger]: '',
  [ApplicationCandidateSubStatusId.Memo]: '',
  [ApplicationCandidateSubStatusId.CheckWaitingForAgent]: '',
  [ApplicationCandidateSubStatusId.CheckOther]: '',
  [ApplicationCandidateSubStatusId.ContactingWaitingForAgentContact]: '',
  [ApplicationCandidateSubStatusId.ContactingAgentCouldNotGetInContactAfter3Attempts]: '',
  [ApplicationCandidateSubStatusId.ContactingAgentOther]: '',
  [ApplicationCandidateSubStatusId.RejecteVacancyFilled]: '',
  [ApplicationCandidateSubStatusId.RejecteCandidateWithdrew]: '',
  [ApplicationCandidateSubStatusId.RejecteInsufficientJapaneseLevel]: '',
  [ApplicationCandidateSubStatusId.RejecteInsufficientDocumentation]: '',
  [ApplicationCandidateSubStatusId.RejecteInvalidVisa]: '',
  [ApplicationCandidateSubStatusId.RejecteCouldNotGetInContact]: '',
  [ApplicationCandidateSubStatusId.RejecteCandidateMissedTheInterview]: '',
  [ApplicationCandidateSubStatusId.RejectedOther]: '',
  [ApplicationCandidateSubStatusId.InterviewWaitingForInterviewSchedule]: '',
  [ApplicationCandidateSubStatusId.InterviewScheduled]: '',
  [ApplicationCandidateSubStatusId.InterviewInterviewOther]: '',
  [ApplicationCandidateSubStatusId.HiredStarted]: '',
  [ApplicationCandidateSubStatusId.HiredCandidateWithdrewAfterEmployment]: '',
  [ApplicationCandidateSubStatusId.HiredFiredQuitAfterEmployment]: '',
  [ApplicationCandidateSubStatusId.HiredOther]: '',
  [ApplicationCandidateSubStatusId.WithdrawnIHaveAnotherOffer]: '',
  [ApplicationCandidateSubStatusId.WithdrawnNotInterestedInThePositionAnymore]: '',
  [ApplicationCandidateSubStatusId.WithdrawnLocationTooFarAway]: '',
  [ApplicationCandidateSubStatusId.WithdrawnJobNotWhatWasExpected]: '',
  [ApplicationCandidateSubStatusId.WithdrawnOther]: ''
};

export const ApplicationCandidateSubStatusIconThemes: EnumDictionary<ApplicationCandidateSubStatusId, string> = {
  [ApplicationCandidateSubStatusId.NewApplicationWebGuest]: '',
  [ApplicationCandidateSubStatusId.NewApplicationWebAccount]: '',
  [ApplicationCandidateSubStatusId.NewApplicationIOS]: '',
  [ApplicationCandidateSubStatusId.NewApplicationMessenger]: '',
  [ApplicationCandidateSubStatusId.Memo]: '',
  [ApplicationCandidateSubStatusId.CheckWaitingForAgent]: '',
  [ApplicationCandidateSubStatusId.CheckOther]: '',
  [ApplicationCandidateSubStatusId.ContactingWaitingForAgentContact]: '',
  [ApplicationCandidateSubStatusId.ContactingAgentCouldNotGetInContactAfter3Attempts]: '',
  [ApplicationCandidateSubStatusId.ContactingAgentOther]: '',
  [ApplicationCandidateSubStatusId.RejecteVacancyFilled]: '',
  [ApplicationCandidateSubStatusId.RejecteCandidateWithdrew]: '',
  [ApplicationCandidateSubStatusId.RejecteInsufficientJapaneseLevel]: '',
  [ApplicationCandidateSubStatusId.RejecteInsufficientDocumentation]: '',
  [ApplicationCandidateSubStatusId.RejecteInvalidVisa]: '',
  [ApplicationCandidateSubStatusId.RejecteCouldNotGetInContact]: '',
  [ApplicationCandidateSubStatusId.RejecteCandidateMissedTheInterview]: '',
  [ApplicationCandidateSubStatusId.RejectedOther]: '',
  [ApplicationCandidateSubStatusId.InterviewWaitingForInterviewSchedule]: '',
  [ApplicationCandidateSubStatusId.InterviewScheduled]: '',
  [ApplicationCandidateSubStatusId.InterviewInterviewOther]: '',
  [ApplicationCandidateSubStatusId.HiredStarted]: '',
  [ApplicationCandidateSubStatusId.HiredCandidateWithdrewAfterEmployment]: '',
  [ApplicationCandidateSubStatusId.HiredFiredQuitAfterEmployment]: '',
  [ApplicationCandidateSubStatusId.HiredOther]: '',
  [ApplicationCandidateSubStatusId.WithdrawnIHaveAnotherOffer]: '',
  [ApplicationCandidateSubStatusId.WithdrawnNotInterestedInThePositionAnymore]: '',
  [ApplicationCandidateSubStatusId.WithdrawnLocationTooFarAway]: '',
  [ApplicationCandidateSubStatusId.WithdrawnJobNotWhatWasExpected]: '',
  [ApplicationCandidateSubStatusId.WithdrawnOther]: ''
};
