import { ICustomGTagEvent } from './interfaces'

/**
 * Tracks events and custom data using google tag manager.
 */
export class GTagService {
  constructor(private gTagDataLayer: any[]) {}

  /**
   * Pushes a custom event to the Analytics data layer.
   * @param gtagEvent
   */
  track(gtagEvent: ICustomGTagEvent): void {
    const dateTimestamp = new Date().getTime()
    this.gTagDataLayer.push({
      ...gtagEvent.customData,
      date: dateTimestamp,
      event: gtagEvent.eventName,
    })
  }
}
