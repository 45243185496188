
/**
 * Levels of Japanese listening ability.
 * @todo i18n. Might have to delete the names from the codebase and rely on the db data only later
 */
 export enum JapaneseListeningLevel {
  Zero = '日本語は不要',
  N5 = '挨拶程度',
  N4 = '初級会話',
  N3 = '日常会話',
  N2 = 'ビジネスレベル',
  N1 = '準ネイティブ',
  Native = 'ネイティブ'
}