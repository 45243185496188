import { IPicture, IVacancy } from '../../../models';
import { placeholderImageUrl } from '../../constants/media';
import { PictureGetters } from '../../picture/getters/picture.getters';

/**
 * Gets picture data from a vacancy
 */
export class VacancyPictureGetters {
  /**
   * Gets the URL of the main picture's original image. We do not normally need to do this when displaying
   * these to candidates.
   * @param vacancy
   * @returns
   */
  static mainOriginalUrl(vacancy: IVacancy): string {
    const picture = VacancyPictureGetters.mainPicture(vacancy);
    if (!picture) return placeholderImageUrl;
    const url = PictureGetters.originalUrl(picture);
    return url || placeholderImageUrl;
  }

  /**
   * Gets the URL of the cropped picture. When displaying the pictures to users, you ideally want to
   * use the cropped picture unless you have a very specific reason not to do so.
   * @param vacancy
   * @returns
   */
  static mainCroppedUrl(vacancy: IVacancy): string {
    const picture = VacancyPictureGetters.mainPicture(vacancy);
    if (!picture) return placeholderImageUrl;
    const url = PictureGetters.croppedUrl(picture) || PictureGetters.originalUrl(picture);
    return url || placeholderImageUrl;
  }

  /**
   * Thumbnail of the main picture.
   * @param vacancy
   * @returns
   */
  static mainThumbnailUrl(vacancy: IVacancy): string {
    const picture = VacancyPictureGetters.mainPicture(vacancy);
    if (!picture) return placeholderImageUrl;
    const url = PictureGetters.thumbnailUrl(picture);
    return url || placeholderImageUrl;
  }

  /**
   * Whether the provided url is the placeholder url used for vacancies.
   * @param url
   * @returns
   */
  static isVacancyUrlPlaceholder(url: string): boolean {
    return url === placeholderImageUrl;
  }

  /**
   * Gets the main picture of the vacancy.
   * @todo Will eventually rework how the "main" picture works, but for now we only have 1 image per vacancy.
   * @param vacancy
   * @returns
   */
  static mainPicture(vacancy: IVacancy): IPicture | undefined {
    if (!vacancy || !vacancy.pictures) return undefined;
    const { pictures } = vacancy;
    if (pictures.length === 0) return undefined;
    // For now fetching the first item as is, later on we will either have a
    // flag or an order property in each picture.
    const vacancyPicture = pictures[0];
    const mainPicture = vacancyPicture.picture;
    return mainPicture;
  }
}
