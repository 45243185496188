import ContactEntity from '~/modules/app/entities/contact'
import CandidateJapaneseLanguageEntity from '~/modules/language/entities/candidate.japanese.language'
import CandidateVisaEntity from '~/modules/user/entities/candidate.visa'
import UserEntity from '~/modules/user/entities/user'
import { ICandidateBirthdateSeed } from '../../entities/candidate.birthdate'
import { ICandidateContactSeed } from '../../entities/candidate.contact'
import { ICandidateDesignationPapersSeed } from '../../entities/candidate.designation.papers'
import { ICandidateGenderSeed } from '../../entities/candidate.gender'
import { ICandidateJapanArrivalDateSeed } from '../../entities/candidate.japan.arrival.date'
import { ICandidateJapaneseConversationSeed } from '../../entities/candidate.japanese.conversation.skill'
import { ICandidateJapaneseListeningSeed } from '../../entities/candidate.japanese.listening.skill'
import { ICandidateJapaneseReadingSeed } from '../../entities/candidate.japanese.reading.skill'
import { ICandidateJapaneseWritingSeed } from '../../entities/candidate.japanese.writing.skill'
import { ICandidatePersonalIdsSeed } from '../../entities/candidate.personal.ids'
import { ICandidatePersonalInformationSeed } from '../../entities/candidate.personal.information'
import { ICandidateResidenceCardBackSeed } from '../../entities/candidate.residence.card.back'
import { ICandidateResidenceCardFrontSeed } from '../../entities/candidate.residence.card.front'
import { ICandidateVisaTypeSeed } from '../../entities/candidate.visa.type'

/**
 *
 * @param candidateVisaProps
 * @returns
 */
export const toCandidateVisaTypeSeed = (
  candidateVisaProps: CandidateVisaEntity,
  isEditing: boolean,
  onboardingCompleted: boolean
): ICandidateVisaTypeSeed => {
  const { visaTypeId, visaSubTypeId } = candidateVisaProps
  return {
    visaTypeId: visaTypeId!,
    visaSubTypeId: visaSubTypeId!,
    isEditing,
    onboardingCompleted
  }
}

/**
 * @param candidateVisaProps
 * @returns
 */
export const toCandidateResidenceCardFrontSeed = (
  candidateVisaProps: CandidateVisaEntity,
  isEditing: boolean,
  onboardingCompleted: boolean
): ICandidateResidenceCardFrontSeed => {
  const cardFrontId = candidateVisaProps.cardFront?.id
  const newFrontAttachmentId = cardFrontId || 0
  return {
    residenceCardFrontAttachmentId: newFrontAttachmentId,
    isEditing,
    onboardingCompleted
  }
}

/**
 * @param candidateVisaProps
 * @returns
 */
export const toCandidateResidenceCardBackSeed = (
  candidateVisaProps: CandidateVisaEntity,
  isEditing: boolean,
  onboardingCompleted: boolean
): ICandidateResidenceCardBackSeed => {
  const cardBackId = candidateVisaProps.cardBack?.id
  const newBackAttachmentId = cardBackId || 0
  return {
    residenceCardBackAttachmentId: newBackAttachmentId,
    isEditing,
    onboardingCompleted
  }
}

/**
 * @param candidateVisaProps
 * @returns
 */
export const toCandidateDesignationPapersSeed = (
  candidateVisaProps: CandidateVisaEntity,
  isEditing: boolean,
  onboardingCompleted: boolean
): ICandidateDesignationPapersSeed => {
  const designationPapersAttachmentId = candidateVisaProps.designationPapersAttachment?.id
  const newDesginationAttachmentId = designationPapersAttachmentId || 0
  return {
    designationPapersAttachmentId: newDesginationAttachmentId,
    isEditing,
    onboardingCompleted
  }
}

/**
 * @param candidateVisaProps
 * @returns
 */
export const toCandidatePersonalIdsSeed = (isEditing: boolean, onboardingCompleted: boolean): ICandidatePersonalIdsSeed => {
  return {
    isEditing,
    onboardingCompleted
  }
}

/**
 * @param userEntity
 * @returns
 */
export const toCandidatePersonalInformationSeed = (
  userEntity: UserEntity,
  isEditing: boolean,
  onboardingCompleted: boolean
): ICandidatePersonalInformationSeed => {
  const { firstName, lastName, country, grewUpInCountry } = userEntity
  return {
    firstName: firstName!,
    lastName: lastName!,
    countryId: country?.id,
    grewUpInCountryId: grewUpInCountry?.id,
    isEditing,
    onboardingCompleted
  }
}

/**
 * @param userEntity
 * @returns
 */
export const toCandidateJapanArrivalDateSeed = (
  userEntity: UserEntity,
  isEditing: boolean,
  onboardingCompleted: boolean
): ICandidateJapanArrivalDateSeed => {
  const { firstArrivalDate } = userEntity
  return {
    japanArrivalDate: firstArrivalDate!,
    isEditing,
    onboardingCompleted
  }
}

/**
 * @param userEntity
 * @returns
 */
export const toCandidateGenderSeed = (
  userEntity: UserEntity,
  isEditing: boolean,
  onboardingCompleted: boolean
): ICandidateGenderSeed => {
  const { gender } = userEntity
  return {
    gender: gender!,
    isEditing,
    onboardingCompleted
  }
}

/**
 * @param userEntity
 * @returns
 */
export const toCandidateBirthdateSeed = (
  userEntity: UserEntity,
  isEditing: boolean,
  onboardingCompleted: boolean
): ICandidateBirthdateSeed => {
  const { birthDate } = userEntity
  return {
    birthdate: birthDate!,
    isEditing,
    onboardingCompleted
  }
}

/**
 * @param contactEntity
 * @returns
 */
export const toCandidateContactSeed = (
  contactEntity: ContactEntity[],
  isEditing: boolean,
  onboardingCompleted: boolean
): ICandidateContactSeed => {
  return {
    contactInformation: contactEntity.map((e) => e.props),
    isEditing,
    onboardingCompleted
  }
}

/**
 * @param candidateJapaneseLanguageEntity
 * @returns
 */
export const toCandidateJapaneseConversationSeed = (
  candidateJapaneseLanguageEntity: CandidateJapaneseLanguageEntity,
  isEditing: boolean,
  onboardingCompleted: boolean
): ICandidateJapaneseConversationSeed => {
  return {
    conversationId: candidateJapaneseLanguageEntity.conversationLevelId!,
    isEditing,
    onboardingCompleted
  }
}

/**
 * @param candidateJapaneseLanguageEntity
 * @returns
 */
export const toCandidateJapaneseListeningSeed = (
  candidateJapaneseLanguageEntity: CandidateJapaneseLanguageEntity,
  isEditing: boolean,
  onboardingCompleted: boolean
): ICandidateJapaneseListeningSeed => {
  return {
    listeningId: candidateJapaneseLanguageEntity.listeningLevelId!,
    isEditing,
    onboardingCompleted
  }
}

/**
 * @param candidateJapaneseLanguageEntity
 * @returns
 */
export const toCandidateJapaneseReadingSeed = (
  candidateJapaneseLanguageEntity: CandidateJapaneseLanguageEntity,
  isEditing: boolean,
  onboardingCompleted: boolean
): ICandidateJapaneseReadingSeed => {
  return {
    readingId: candidateJapaneseLanguageEntity.readingLevelId!,
    isEditing,
    onboardingCompleted
  }
}

/**
 * @param candidateJapaneseLanguageEntity
 * @returns
 */
export const toCandidateJapaneseWritingSeed = (
  candidateJapaneseLanguageEntity: CandidateJapaneseLanguageEntity,
  isEditing: boolean,
  onboardingCompleted: boolean
): ICandidateJapaneseWritingSeed => {
  return {
    writingIds: candidateJapaneseLanguageEntity.writingSkills!,
    isEditing,
    onboardingCompleted
  }
}
