import { EnumDictionary } from '../../../utils/types/enum-dictionary';
import { ApplicationCandidateSubStatusId } from './application-candidate-substatus-id.enum';

/**
 * Fontawesome Icon name for each application substatus.
 * An empty string implies that the theme will depend on the parent status icon.
 * If both parent and child are empty, then the theme will be the default one.
 */
export const ApplicationCandidateSubStatusIcons: EnumDictionary<ApplicationCandidateSubStatusId, string> = {
  [ApplicationCandidateSubStatusId.NewApplicationWebGuest]: 'seedling',
  [ApplicationCandidateSubStatusId.NewApplicationWebAccount]: 'seedling',
  [ApplicationCandidateSubStatusId.NewApplicationIOS]: 'seedling',
  [ApplicationCandidateSubStatusId.NewApplicationMessenger]: 'seedling',
  [ApplicationCandidateSubStatusId.CheckWaitingForAgent]: 'file-magnifying-glass',
  [ApplicationCandidateSubStatusId.CheckOther]: 'file-circle-question',
  [ApplicationCandidateSubStatusId.ContactingWaitingForAgentContact]: 'phone',
  [ApplicationCandidateSubStatusId.ContactingAgentCouldNotGetInContactAfter3Attempts]: 'phone-missed',
  [ApplicationCandidateSubStatusId.ContactingAgentOther]: 'phone-volume',
  [ApplicationCandidateSubStatusId.RejecteVacancyFilled]: 'person-rays',
  [ApplicationCandidateSubStatusId.RejecteCandidateWithdrew]: 'person-circle-xmark',
  [ApplicationCandidateSubStatusId.RejecteInsufficientJapaneseLevel]: 'language',
  [ApplicationCandidateSubStatusId.RejecteInsufficientDocumentation]: 'person-arrow-down-to-line',
  [ApplicationCandidateSubStatusId.RejecteInvalidVisa]: 'user-large-slash',
  [ApplicationCandidateSubStatusId.RejecteCouldNotGetInContact]: 'phone-slash',
  [ApplicationCandidateSubStatusId.RejecteCandidateMissedTheInterview]: 'ghost',
  [ApplicationCandidateSubStatusId.RejectedOther]: 'person-circle-question',
  [ApplicationCandidateSubStatusId.InterviewWaitingForInterviewSchedule]: 'calendar-pen',
  [ApplicationCandidateSubStatusId.InterviewScheduled]: 'calendar-star',
  [ApplicationCandidateSubStatusId.InterviewInterviewOther]: 'calendar',
  [ApplicationCandidateSubStatusId.HiredStarted]: 'stars',
  [ApplicationCandidateSubStatusId.HiredCandidateWithdrewAfterEmployment]: '',
  [ApplicationCandidateSubStatusId.HiredFiredQuitAfterEmployment]: 'person-through-window',
  [ApplicationCandidateSubStatusId.HiredOther]: 'circle-question',
  [ApplicationCandidateSubStatusId.WithdrawnIHaveAnotherOffer]: 'right-from-bracket',
  [ApplicationCandidateSubStatusId.WithdrawnNotInterestedInThePositionAnymore]: 'right-from-bracket',
  [ApplicationCandidateSubStatusId.WithdrawnLocationTooFarAway]: 'right-from-bracket',
  [ApplicationCandidateSubStatusId.WithdrawnJobNotWhatWasExpected]: 'right-from-bracket',
  [ApplicationCandidateSubStatusId.WithdrawnOther]: 'right-from-bracket',
  [ApplicationCandidateSubStatusId.Memo]: '',
};
