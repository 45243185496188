/**
 * Test userAgent string to see if it contains signs of being a mobile user
 * @remarks 'Mobi' is apparently present in safari, opera and all others
 * @remarks 'Android' is apparently to catch Android Chrome which doesnt have Mobi in the string
 * @todo Review this every so often to make sure we are catching any changes in the 'Meta' 
 */
export class UserAgent {
    static isMobile(userAgent: string) {
        return !!/Mobi|Android/i.test(userAgent)
    }
}
