import { LanguageLocale } from '../../../../../../core/src/models/language/constants/language-locale.enum'
import { IJapaneseConversation } from '../../../../../../core/src/models/language/interface/i-japanese-conversation'
import { LocalizationSelector } from '../localization-selector'
import { LogService, LogType } from '../../../base/services/log.service'
import { BaseUsecase } from '../../../modules/usecases/base/usecase'
import { getJapaneseLanguageListStore } from '../../../../../core/src/store/japanese-language-list.store'

interface IAppBaseGatewayLanguage {
  GetJapaneseSkillsList: (locale: LanguageLocale) => Promise<IJapaneseConversation[]>
}

interface IAppBaseGateway {
  language: IAppBaseGatewayLanguage
}

interface IApp {
  baseGateway: IAppBaseGateway
  localizationSelector: LocalizationSelector
  logService: LogService
}

export class JapaneseLanguageListUsecase implements BaseUsecase {
  constructor(private readonly app: IApp) {}

  async execute(): Promise<IJapaneseConversation[]> {
    const { baseGateway, logService, localizationSelector } = this.app
    try {
      const jpStore = getJapaneseLanguageListStore()
      const locale = localizationSelector.languageLocale
      const data = await baseGateway.language.GetJapaneseSkillsList(locale)
      jpStore.save(data)
      return data
    } catch (error) {
      await logService.handle({ type: LogType.Error, error: error as Error })
      return []
    }
  }
}
