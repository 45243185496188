import { Type } from 'class-transformer';
import { IsEnum, IsNumber, IsOptional, ValidateNested } from 'class-validator';
import { LanguageLocale } from '../../language';
import { UserUpdateDto } from '../../user';

/**
 * Information required to create a new application.
 */
export class ApplicationCandidateCreateDto {
  /**
   * The id of the vacancy being applied to.
   */
  @IsNumber()
  vacancyId: number;

  /**
   * The locale of the candidate that applied
   */
  @IsEnum(LanguageLocale)
  locale: LanguageLocale

  @IsOptional()
  @ValidateNested()
  @Type(() => UserUpdateDto)
  user?: UserUpdateDto
}
