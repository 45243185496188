/**
 * When going through these events, we're thinking of 2 separate participants.
 * I = Me, the person creating this websocket connection.
 * Other / Participant = The other users in the socket room (using a separate socket connection).
 * @todo Switch this to be an enum / dictionary
 */
export enum WebSocketEventName {
  /**
   * When received, informs me that I successfully connected to the socket. Has not joined the room yet.
   */
  Connect = 'connect',
  /**
   * When received, informs me that I was disconnected from the socket, or the connection was somehow lost.
   */
  Disconnect = 'disconnect',
  /**
   * When received, informs me that the socket connection was successful but I am unauthorized
   * and cannot join the room. Usually preceeds a disconnect event.
   */
  Unauthorized = 'unauthorized',
  /**
   * When received, informs me that a user (other than myself) joined the room.
   * Other participants being in the room before I join won't trigger this event.
   * @remarks When anyone joins a room, both this and the UserJoinRoomSuccess event will trigger.
   * The only difference is who receives the event (received by all users ALREADY in the room)
   */
  UserJoined = 'user-joined',
  /**
   * When received, informs me that I successfully joined the room and can now send messages.
   * The event also includes the last messages in the room, if any.
   * @remarks When anyone joins a room, both this and the UserJoined event will trigger.
   * The only difference is who receives the event.
   */
  UserJoinRoomSuccess = 'user-join-room-success',
  /**
   * When emitted, informs the other user that I am now in the room.
   * When received, informs me that the other user is now in the room.
   * @remarks The reason for this is that once I join (an empty room), I need to be notified after someone joins.
   * Received by everyone except myself.
   */
  UserInRoom = 'user-in-room',
  /**
   * When emitted, it notifies the other user that I'm typing a message.
   * When received, it lets me know that the other user is typing.
   */
  UserTyping = 'user-typing',
  /**
   * When emitted, it notifies the other user that I sent a message. The event includes the message contents.
   * When received, it lets me know that the other user wrote a message. The event includes the message contents.
   */
  UserSendMessage = 'user-send-message',
  /**
   * When received, it lets me know that the message has been successfully sent to the other user.
   */
  UserSendMessageSuccess = 'user-send-message-success',
  /**
   * When received, it lets me that the other user was disconnected.
   */
  UserDisconnected = 'user-disconnected',
  /**
   * When emitted, informs the API that I want to fetch the older messages
   */
  UserOlderMessageRequest = 'user-older-message-request',
  /**
   * When received, informs me of the older messages
   */
  UserOlderMessageResponse = 'user-older-message-response',
  /**
   * When received, it marks an application item's messages as read depending on what user sent the event.
   */
  MarkApplicationMessagesAsRead = 'mark-application-messages-as-read',
  /**
   * When emitted, marks an application's candidateNotificationPending property to true.
   * Notifications with this flag will attempt to send a message to the candidate only once.
   * Once the message is sent, the flag is automatically set back to false so prevent
   * spamming the user.
   * @remarks Currently only used for Messenger Users.
   */
  SetCandidateNotificationPending = 'set-candidate-notification-pending'
}
