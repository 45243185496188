import { ContractType } from '../network/schema/models'
import { ContractTypeInfo } from '../network/schema/models/contract.type'

export interface IContractTypeProps extends ContractType {}

export default class ContractTypeEntity {
  private _props: IContractTypeProps

  constructor(props: IContractTypeProps) {
    this._props = props
  }

  get props(): IContractTypeProps {
    return this._props
  }

  get id(): number {
    return this.props.id
  }

  get slug(): string {
    return this.props.slug || ''
  }

  set slug(value: string) {
    this.props.slug = value
  }

  get informations(): ContractTypeInfo[] {
    return this.props.informations
  }

  get name(): string {
    if(this.props.informations?.length === 0) return ''
    return this.props.informations[0].name
  }
}

export const EmptyContractTypePropsFactory = (
  props?: Partial<IContractTypeProps>
): IContractTypeProps => ({
  id: 0,
  slug: '',
  informations: [],
  ...props
})

export const ContractTypePropsFactory = (
  props?: Partial<IContractTypeProps>
): IContractTypeProps => ({
  id: 0,
  slug: '',
  informations: [],
  ...props
})

export const EmptyContractTypeEntityFactory = (
  props?: Partial<IContractTypeProps>
): ContractTypeEntity => {
  return new ContractTypeEntity(EmptyContractTypePropsFactory(props))
}

export const ContractTypeEntityFactory = (
  props?: Partial<IContractTypeProps>
): ContractTypeEntity => {
  return new ContractTypeEntity(ContractTypePropsFactory(props))
}
