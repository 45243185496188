/**
 * Images used in website
 */
export const StaticFileUrls = {
  residenceCardFront: `https://tokyojob-public.s3-ap-northeast-1.amazonaws.com/files/samples/residence+card+face+and+back+.001.jpeg`,
  residenceCardBack: `https://tokyojob-public.s3-ap-northeast-1.amazonaws.com/files/samples/residence+card+face+and+back+.002.jpeg`,
  samplePapersUrl: `https://tokyojob-public.s3-ap-northeast-1.amazonaws.com/files/samples/residence+card+image+.004.jpeg`,
  sessionInvalid: `https://tokyojob-public.s3.ap-northeast-1.amazonaws.com/public/session.png`,
  sessionExpired: `https://tokyojob-public.s3.ap-northeast-1.amazonaws.com/public/session_2.png`,
  cityBackdropUrl: 'https://tokyojob-public.s3.ap-northeast-1.amazonaws.com/public/login_BG_opt.jpg',
  tokyoSkylineUrl: 'https://tokyojob-public.s3.ap-northeast-1.amazonaws.com/public/cityscape.png',
  tokyoJobBanner: 'https://tokyojob-public.s3.ap-northeast-1.amazonaws.com/bg/hero_tokyo_landscape_02.jpg',
  tokyoJobBannerMobile: 'https://tokyojob-public.s3.ap-northeast-1.amazonaws.com/bg/hero_tokyo_landscape_02_mobile.jpg',
  facebookImage: 'https://tokyojob-public.s3.ap-northeast-1.amazonaws.com/bg/hero_tokyo_landscape_text.jpg',
  LTBLogo: 'https://tokyojob-public.s3.ap-northeast-1.amazonaws.com/files/ltb-logo.png',
  mobileMockup: 'https://tokyojob-public.s3.ap-northeast-1.amazonaws.com/files/mobile_mockup.png',
  translationMissing: 'https://tokyojob-public.s3.ap-northeast-1.amazonaws.com/files/translation_image.png',
  restaurantAdBannerBgWeb: 'https://tokyojob-public.s3.ap-northeast-1.amazonaws.com/banner/restaurant-campaign/restaurant_banner_bg_desktop.jpg',
  restaurantAdBannerBgMobile: 'https://tokyojob-public.s3.ap-northeast-1.amazonaws.com/banner/restaurant-campaign/restaurant_banner_bg_mobile.jpg',
  restaurantAdBannerEggWebEn: 'https://tokyojob-public.s3.ap-northeast-1.amazonaws.com/banner/restaurant-campaign/restaurant_banner_text_desktop.png',
  restaurantAdBannerEggMobileEn: 'https://tokyojob-public.s3.ap-northeast-1.amazonaws.com/banner/restaurant-campaign/restaurant_banner_text_mobile.png',
  restaurantAdBannerEggWebJp: 'https://tokyojob-public.s3.ap-northeast-1.amazonaws.com/banner/restaurant-campaign/restaurant_banner_text_desktop_jp.png',
  restaurantAdBannerEggMobileJp: 'https://tokyojob-public.s3.ap-northeast-1.amazonaws.com/banner/restaurant-campaign/restaurant_banner_text_mobile_jp.png',
  briefcaseSVG: 'https://tokyojob-public.s3.ap-northeast-1.amazonaws.com/icon_svgs/custom/briefcase-icon.svg',
  chatMessageSVG: 'https://tokyojob-public.s3.ap-northeast-1.amazonaws.com/icon_svgs/custom/envelope.icon.svg',
  magnifyingGlassSVG: 'https://tokyojob-public.s3.ap-northeast-1.amazonaws.com/icon_svgs/custom/magnifier-icon.svg',
  timerSVG: 'https://tokyojob-public.s3.ap-northeast-1.amazonaws.com/icon_svgs/custom/timer-icon.svg',
  couponBannerJP: 'https://tokyojob-public.s3.ap-northeast-1.amazonaws.com/banner/coupons/coupon-banner-JP-16-10.jpg',
  couponBannerEN: 'https://tokyojob-public.s3.ap-northeast-1.amazonaws.com/banner/coupons/coupon-banner-EN-16-10.jpg',
}
