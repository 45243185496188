/**
 * Defines the current status of translation job
 */
export enum TranslationStatus {
  Pending = 0,
  Running = 1,
  Completed = 2,
  Failed = 3,
  Flagged = 4,
}
