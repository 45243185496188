/**
 * Defines the properties of a Font Awesome Icon
 */
export enum IconType {
  solid = 'fas',
  regular = 'far',
  light = 'fal',
  thin = 'fat',
  duotone = 'fad',
  brand = 'fab',
  kit = 'fak'
}
