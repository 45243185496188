import { IFilterableDataSetItem } from '../../../../../../core/src/models/common/filter/i-filterable-data-set-item'
import { LocalizationData } from '../../../../../../core/src/models/common/localization/localization-data'
import { ContractTypeDictionary } from '../../../../../../core/src/models/contract-type/constants/contract-type.dictionary'
import { LocalizationService } from '../../language/localization-service'

export class ContractTypeDictionaryFactory {
  public static build(localization?: LocalizationData): Record<string, IFilterableDataSetItem> {
    if (!localization) return ContractTypeDictionary
    const contractTypeDictionary = ContractTypeDictionary
    for (const key in contractTypeDictionary) {
      const path = contractTypeDictionary[key].nameTranslationPath
      const localizedName = LocalizationService.t<string>(localization, path!)
      contractTypeDictionary[key].name = localizedName
    }
    return contractTypeDictionary
  }
}
