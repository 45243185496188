import { IApp } from '@/types/nuxt'
import { IUser, IUserProfileUpdateResult, LanguageLocale } from '@tokyojob/core'
import { LogType } from '@tokyojob/frontend-core'
import { getAppStore } from '~/store/app.store'

export default class SaveUseCase implements BaseUsecase {
  app: IApp
  constructor(app: IApp) {
    this.app = app
  }

  async execute(user: IUser, locale: LanguageLocale = LanguageLocale.USen): Promise<IUserProfileUpdateResult | null> {
    const { baseGateway, logService } = this.app
    const appStore = getAppStore()
    try {
      return baseGateway.user.Update(user, locale)
    } catch (error) {
      await logService.handle({ type: LogType.Error, error: error as Error })
      return null
    }
  }
}
