import {
  ICandidateJapaneseLanguageProps,
  ICandidateJapaneseLanguageSeed
} from '../../entities/candidate.japanese.language'
import { ICandidateLanguageCertificationSeed } from '../../entities/candidate.language.certification'
import { ICandidateLanguageCertificateGroupProps } from '../../entities/candidate.language.certification.group'

/**
 * Generates a new candidate language certificate seed.
 * @param props Props to map into the seed object.
 */
export const groupsToCandidateLanguageCertificationSeed = (
  props: ICandidateLanguageCertificateGroupProps[] | undefined
): ICandidateLanguageCertificationSeed[] => {
  if (!props) {
    return []
  }
  const seeds: ICandidateLanguageCertificationSeed[] = []
  props = props.filter(
    (el) => el.languageCertificates && el.languageCertificates.length > 0
  )
  for (const group of props) {
    group.languageCertificates.forEach((el) => {
      // Skip certificates with no attachments, or the API throws a BadRequest error.
      if (!el.attachments || el.attachments.length <= 0) return
      const { languageCertificateId, attachments: attachment } = el
      seeds.push({
        languageCertificateId,
        attachmentIds: attachment.map((atc) => atc.id!)
      })
    })
  }
  return seeds
}

/**
 * Maps the candidate's language skill properties into a Seed interface, mostly used to request an update to the API.
 * @param props Candidate Japanese Skills to map into a seed interface.
 */
export const toCandidateJapaneseSkillsSeed = (
  props: ICandidateJapaneseLanguageProps
): ICandidateJapaneseLanguageSeed => {
  const {
    japaneseListening: listening,
    japaneseReading: reading,
    japaneseConversation: conversation,
    japaneseWriting: writing,
    languageCertificateGroups,
    japaneseBusiness
  } = props
  return {
    listeningId: listening?.id,
    conversationId: conversation?.id,
    writingIds: writing?.map((c) => c.id),
    readingId: reading?.id,
    languageCertificates: groupsToCandidateLanguageCertificationSeed(
      languageCertificateGroups
    ),
    businessIds: japaneseBusiness?.map((c) => c.id)
  }
}

/**
 * Maps a Candidate's Japanese Language Skills object into another one. Usually used when mapping different objects that share the same interface. For example, an API Response that needs to be shown on the UI.
 * @param props Object to map from.
 */
export const toCandidateJapaneseSkillsProps = (
  props: ICandidateJapaneseLanguageProps
): ICandidateJapaneseLanguageProps => {
  const {
    languageCertificateGroups: languageCertificates,
    japaneseListening: listening,
    japaneseReading: reading,
    japaneseConversation: conversation,
    japaneseWriting: writing,
    japaneseBusiness: business
  } = props
  return {
    languageCertificateGroups: languageCertificates,
    japaneseListening: listening,
    japaneseConversation: conversation,
    japaneseWriting: writing,
    japaneseReading: reading,
    japaneseBusiness: business
  }
}
