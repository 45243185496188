import LanguageCertificateGroup from '../network/schema/models/language.certificate.group'
import LanguageCertificationEntity, {
  LanguageCertificationEntityFactory
} from './language.certification'

/**
 * Interface used as a base when reading the Candidate's Japanese reading skills data.
 */
export interface ILanguageCertificateGroupProps
  extends LanguageCertificateGroup {}

/**
 * Entity containing a language certification's information.
 */
export default class LanguageCertificateGroupEntity {
  private _props: ILanguageCertificateGroupProps

  constructor(props: ILanguageCertificateGroupProps) {
    this._props = props
  }

  /**
   * Raw properties of the entity. Used in cases when the data provided by the entity's getters and setters are insufficient.
   * For most cases, consider adding another getter / setter instead of interacting directly with the props.
   */
  get props(): ILanguageCertificateGroupProps {
    return this._props
  }

  /**
   * The id of the certification.
   */
  get id(): number {
    return this.props.id
  }

  /**
   * The name of the certification.
   */
  get name(): string {
    return this.props.info[0].name
  }

  /**
   * List of certificates in the group.
   */
  get certificates(): LanguageCertificationEntity[] {
    return this.props.certificates.map((e) =>
      LanguageCertificationEntityFactory(e)
    )
  }
}

/**
 * Generates an ILanguageCertificateGroupProps object. Used to add extra properties during the conversion process.
 * @param props Data to use when creating the object.
 */
export const LanguageCertificateGroupPropsFactory = (
  props: ILanguageCertificateGroupProps
): ILanguageCertificateGroupProps => ({
  ...props
})

/**
 * Generates a LanguageCertificateGroupEntity object.
 * @param props Data to use when creating the object.
 */
export const LanguageCertificateGroupEntityFactory = (
  props: ILanguageCertificateGroupProps
): LanguageCertificateGroupEntity => {
  return new LanguageCertificateGroupEntity(
    LanguageCertificateGroupPropsFactory(props)
  )
}
