import { extend, localize, setInteractionMode } from 'vee-validate'
import {
  confirmed,
  email,
  ext,
  image,
  max,
  max_value as maxValue,
  min,
  min_value as minValue,
  numeric,
  regex,
  required,
  size
} from 'vee-validate/dist/rules'
import { appLocalization } from '../../../../core/src/modules/language/map/app-localization'
import { validateAutocompleteSelection } from '../../modules/validators/autocomplete.select.validator'
import { dateBetweenValidator } from '../../modules/validators/date_between.validator'
import { dateAfterValidator } from '../../modules/validators/date_after.validator'
import { mobileOrEmailValidator } from '../../modules/validators/mobile-or-email.validator'
import { mobileValidator } from '../../modules/validators/mobile.validator'
import { zipCodeValidator } from '../../modules/validators/zipcode.validator'
import { addressLineWithTownValidator } from '../../modules/validators/addressLineWithTown.validator'
import { katakanaValidator } from '../../modules/validators/katakana.validator'

/**
 * Setting locale/language only works here
 * ctx is set to any because we don't have access to the main nuxt app's context typing
 */
export const componentsVeeValidate = (ctx: any) => {
  /**
   * Vee-validate rules imported in the project. Any time a new is required, it needs to be imported here first.
   * @link https://vee-validate.logaretm.com/v2/guide/rules.html
   */

  setInteractionMode('eager')
  // TODO: i18n
  extend('confirmed', {
    ...confirmed
  })
  extend('email', {
    ...email
  })
  extend('max', {
    ...max
  })
  extend('min', {
    ...min
  })
  extend('regex', {
    ...regex
  })
  extend('required', {
    ...required
  })
  extend('size', {
    ...size
  })
  extend('image', {
    ...image
  })
  extend('ext', {
    ...ext
  })
  extend('min_value', {
    ...minValue
  })
  extend('max_value', {
    ...maxValue
  })
  extend('autocomplete_select', {
    validate: validateAutocompleteSelection
  })
  extend('mobile', {
    validate: mobileValidator
  })
  extend('zipcode', {
    validate: zipCodeValidator
  })
  extend('addressLineWithTown', {
    validate: addressLineWithTownValidator
  })
  extend('katakana', {
    validate: katakanaValidator
  })
  extend('mobileOrEmail', {
    validate: mobileOrEmailValidator
  })
  extend('numeric', {
    ...numeric
  })
  extend('date_between', {
    params: ['min', 'max'],
    validate: dateBetweenValidator
  })
  extend('date_after', {
    params: ['min'],
    validate: dateAfterValidator
  })

  // We extract the routeMeta from the context's route of the nuxt instance
  const route = ctx.route
  let routeMeta = undefined
  if (!route.meta) routeMeta = {}
  if (Array.isArray(route.meta)) routeMeta = route.meta[0]

  // We set the vee-validate's localization json
  localize(appLocalization)

  // If language if present in the route's meta, we're going to set that to vee-validate
  if (routeMeta && routeMeta.language) localize(routeMeta.language)
}
