import {
  ICity,
  IPrefecture,
  IVacancyi18n,
  IWorkplaceDetails,
  LanguageLocale,
  LocalizationData,
  TranslationStatus,
} from '../../../models';
import { WageGetters } from '../../wage/getters/wage.getters';
import { ICandidateVisa } from '../../../models/candidate/interface/i-candidate-visa';
import { IIndustry, IJobSector } from '../../../models/category';
import { ICoordinates } from '../../../models/geolocation/interface/i-coordinates';
import { BenefitCategoryIds } from '../../../models/vacancy/contants/benefit-category-ids.enum';
import { IVacancy } from '../../../models/vacancy/interface/i-vacancy';
import { IVacancyBenefit } from '../../../models/vacancy/interface/i-vacancy-benefit';
import { IWage } from '../../../models/vacancy/interface/i-wage';
import { IWorkplace } from '../../../models/workplace/interface/i-workplace';
import { StringFormatter } from '../../../utils/string/formatter/string-formatter';
import { IndustryGetters, JobSectorGetters } from '../../category';
import { ContractTypeGetters } from '../../contract-type/getters/contract-type.getters';
import { VacancyPictureGetters } from './vacancy-picture.getters';
/**
 * Generates some data from vacancy objects.
 * @remarks This is meant to replace getters from typescript classes, because they break SSR.
 * The functions here should NEVER alter the objects that they receive as params, they should be
 * pure functions and return predictable results.
 */
export class VacancyGetters {
  /**
   * Obtains a nuxt-link shaped string to redirect to the provided vacancy.
   * @param vacancy
   * @returns
   */
  static getVacancyLink(vacancy: IVacancy): string {
    return `/job/${vacancy.id}`;
  }

  /**
   * Obtains the first available thumbnail from the vacancy's pictures collection.
   * @param vacancy
   * @returns
   */
  static getThumbnailUrl(vacancy: IVacancy): string {
    const url = VacancyPictureGetters.mainThumbnailUrl(vacancy);
    return url;
  }

  /**
   * Obtains the first available attachment from the vacancy's pictures collection.
   * @param vacancy
   * @returns
   */
  static getImageUrl(vacancy: IVacancy): string {
    const url = VacancyPictureGetters.mainOriginalUrl(vacancy);
    return url;
  }

  /**
   * Obtains the first available cropped / edited attachment from the vacancy's pictures collection.
   * @param vacancy
   * @returns
   */
  static getEditedImageUrl(vacancy: IVacancy): string {
    const url = VacancyPictureGetters.mainCroppedUrl(vacancy);
    return url;
  }

  /**
   * Returns true if the passed vacancy has applications. Otherwise this returns false.
   * @param vacancy
   * @returns
   */
  static hasApplications(vacancy: IVacancy): boolean {
    return !!vacancy.applications && vacancy.applications.length > 0;
  }

  /**
   * Returns true if the passed vacancy has wages defined. Otherwise this returns false.
   * @param vacancy
   * @returns
   */
  static hasWages(vacancy: IVacancy): boolean {
    return !!vacancy.wages && vacancy.wages.length > 0;
  }

  /**
   * Returns true if the passed vacancy has workplaces defined. Otherwise this returns false.
   * @param vacancy
   * @returns
   */
  static hasWorkplace(vacancy: IVacancy): boolean {
    return !!vacancy.workplaces && vacancy.workplaces.length > 0;
  }

  /**
   * Gets the name of the first available workplace.
   * @param vacancy
   * @returns
   */
  static getWorkplaceName(vacancy: IVacancy): string {
    if (!VacancyGetters.hasWorkplace(vacancy)) return '';
    const workplace = VacancyGetters.getWorkplace(vacancy);
    return workplace!.name || '';
  }
  /**
   * Gets the vacancy's title in the specified language.
   * @param vacancy
   * @returns
   */
  static getTitle(vacancy: IVacancy): string {
    if (!VacancyGetters.hasi18n(vacancy)) return '';
    const title = vacancy.i18n![0].title;
    return title || '';
  }

  /**
   * Gets the vacancy's title in the specified language.
   * @param vacancy
   * @returns
   */
  static getLocaleTitle(vacancy: IVacancy, locale: LanguageLocale): string {
    if (!VacancyGetters.hasi18n(vacancy)) return '';
    const i18n = vacancy.i18n!.find((e) => e.locale == locale);
    if (!i18n) return '';
    const title = i18n.title;
    return title || '';
  }

  /**
   * Gets the vacancy's salary/bonuses details
   * @param vacancy
   * @returns
   */
  static getOtherSalariesAndBonusesDetails(vacancy: IVacancy): string {
    if (!VacancyGetters.hasi18n(vacancy)) return '';
    const title = vacancy.i18n![0].otherSalariesAndBonusesDetails;
    return title || '';
  }

  /**
   * Generates an SEO friendly title for the vacancy.
   * @param vacancy
   * @returns
   */
  static getSEOTitle(vacancy: IVacancy, titleString: string): string {
    const stringFormatter = new StringFormatter();
    const basicTitle = VacancyGetters.getTitle(vacancy);
    const location = VacancyGetters.getLocation(vacancy);
    const title = stringFormatter.format(titleString, [location, basicTitle]) as string;
    return title;
  }

  /**
   * Get vacancy summary in specified language.
   */
  static getSummary(vacancy: IVacancy): string {
    if (!VacancyGetters.hasi18n(vacancy)) return '';
    const title = vacancy.i18n![0].summary;
    return title || '';
  }

  static getDescription(vacancy: IVacancy): string {
    if (!VacancyGetters.hasi18n(vacancy)) return '';
    const title = vacancy.i18n![0].description;
    return title || '';
  }

  /**
   * Gets the location string of the passed vacancy.
   * @param IVacancy
   * @returns
   */
  static getLocation(vacancy: IVacancy): string {
    if (!VacancyGetters.hasWorkplace(vacancy)) return '';
    const { prefecture, city } = vacancy.workplaces![0];
    if (!prefecture) return '';

    const prefectureName = !!prefecture!.i18n && prefecture!.i18n.length > 0 ? prefecture!.i18n[0].name! : '';
    if (city && city.i18n && city.i18n.length > 0) {
      const cityName = city.i18n.length > 0 ? city.i18n[0].name! : '';
      return `${cityName}, ${prefectureName}`;
    }
    return prefectureName;
  }

  /**
   * Get the string of the japaneseLanguageDetails in i18n
   */
  static getJapaneseLanguageDetails(vacancy: IVacancy): string {
    if (!VacancyGetters.hasi18n(vacancy)) return '';
    const japaneseLanguageDetails = vacancy.i18n![0].japaneseLanguageDetails;
    return japaneseLanguageDetails || '';
  }

  /**
   * Retrieves the prefecture from a given vacancy.
   *
   * @param {IVacancy} vacancy - The vacancy object containing workplace details.
   * @returns {IPrefecture | undefined} - The prefecture or undefined if not found.
   */
  static getPrefectureFromVacancy(vacancy: IVacancy): IPrefecture | undefined {
    if (!VacancyGetters.hasWorkplace(vacancy)) return undefined;
    const { prefecture } = vacancy.workplaces![0];
    return prefecture;
  }

  /**
   * Retrieves the prefecture ID from a given vacancy.
   *
   * @param {IVacancy} vacancy - The vacancy object containing workplace details.
   * @returns {number | undefined} - The prefecture ID or undefined if not found.
   */
  static getPrefectureID(vacancy: IVacancy): number | undefined {
    const prefecture = this.getPrefectureFromVacancy(vacancy);
    return prefecture ? prefecture.id : undefined;
  }

  /**
   * Gets the prefecture name of the vacancy
   * @param vacancy
   * @returns
   */
  static getPrefecture(vacancy: IVacancy): string {
    const prefecture = this.getPrefectureFromVacancy(vacancy);
    if (!prefecture) return '';
    const prefectureName = !!prefecture!.i18n && prefecture!.i18n.length > 0 ? prefecture!.i18n[0].name! : '';
    return prefectureName;
  }

  /**
   * Retrieves the city from a given vacancy.
   *
   * @param {IVacancy} vacancy - The vacancy object containing workplace details.
   * @returns {ICity | undefined} - The city or undefined if not found.
   */
  static getCityFromVacancy(vacancy: IVacancy): ICity | undefined {
    if (!VacancyGetters.hasWorkplace(vacancy)) return undefined;
    const { city } = vacancy.workplaces![0];
    return city;
  }

  /**
   * Retrieves the city ID from a given vacancy.
   *
   * @param {IVacancy} vacancy - The vacancy object containing workplace details.
   * @returns {number | undefined} - The city ID or undefined if not found.
   */
  static getCityID(vacancy: IVacancy): number | undefined {
    const city = this.getCityFromVacancy(vacancy);
    return city ? city.id : undefined;
  }

  /**
   * Gets the city name of the vacancy
   * @param vacancy
   * @returns
   */
  static getCity(vacancy: IVacancy): string {
    const city = this.getCityFromVacancy(vacancy);
    if (!city) return '';
    const cityName = !city.i18n || city.i18n.length > 0 ? city.i18n![0].name! : '';
    return cityName;
  }

  static canBeDeleted(vacancy: IVacancy): boolean {
    return !VacancyGetters.hasApplications(vacancy);
  }

  /**
   * The vacancy's location
   * @param vacancy
   * @returns
   */
  static getCoordinates(vacancy: IVacancy): ICoordinates | undefined {
    if (!VacancyGetters.hasWorkplace(vacancy)) return undefined;
    const lng = vacancy.workplaces![0].longitude;
    const lat = vacancy.workplaces![0].latitude;
    if (!lat || !lng) return undefined;
    return {
      lat,
      lng,
    };
  }

  /**
   * Retrieves the first available wage
   * @param vacancy
   * @returns
   */
  static getWage(vacancy: IVacancy): IWage | undefined {
    if (!VacancyGetters.hasWages(vacancy)) return undefined;
    return vacancy.wages![0];
  }

  static getJapaneseConversationName(vacancy: IVacancy): string {
    if (!vacancy.japaneseConversation) return '';
    const level = vacancy.japaneseConversation;
    if (!level.i18n || level.i18n.length === 0) return '';
    return level.i18n[0].name!;
  }

  static getJapaneseConversationID(vacancy: IVacancy): number | undefined {
    if (!vacancy.japaneseConversation) return undefined;
    return vacancy.japaneseConversation.id;
  }

  /**
   * Return an array of strings describing Raise and Bonus details for a vacancy
   * @todo The entire benefits section needs to be redone.
   * @param IWage
   * @returns
   */

  static getSocialInsuranceAndBenefits(vacancy: IVacancy): string[] | undefined {
    return VacancyGetters.getBenefitType(vacancy, BenefitCategoryIds.SocialInsurance);
  }

  static getBenefits(vacancy: IVacancy): IVacancyBenefit[] | undefined {
    return VacancyGetters.getVacancyBenefits(vacancy, BenefitCategoryIds.Benefits);
  }

  static getAllowances(vacancy: IVacancy): IVacancyBenefit[] | undefined {
    return VacancyGetters.getVacancyBenefits(vacancy, BenefitCategoryIds.Allowances);
  }

  /**
   * Checks whether the vacancy has a localization data array or not.
   * @param vacancy
   * @returns
   */
  static hasi18n(vacancy: IVacancy): boolean {
    return !!(vacancy && vacancy.i18n && vacancy.i18n.length > 0);
  }

  /**
   * @todo delete after the benefit data structure is sorted out
   * @param vacancy
   * @param benefitTypeId
   * @returns
   */
  static getVacancyBenefits(vacancy: IVacancy, benefitTypeId: BenefitCategoryIds): IVacancyBenefit[] | undefined {
    const benefitsList: IVacancyBenefit[] = [];
    if (vacancy.benefits) {
      const benefitItems = vacancy.benefits.filter((e) => e.benefitCategoryId === benefitTypeId);
      for (let index = 0; index < benefitItems.length; index++) {
        const vacancyBenefit = benefitItems[index];
        const { amount, benefitCategoryId, benefitId, benefitUnitId } = vacancyBenefit;
        const childrenVacBenefits: IVacancyBenefit[] | undefined = vacancyBenefit.benefit?.children?.map((benefit) => {
          const item: IVacancyBenefit = { ...benefit, children: [] };
          return item;
        });
        const newBenefit: IVacancyBenefit = {
          benefit: {
            id: vacancyBenefit.benefit?.id || 0,
            i18n: vacancyBenefit.benefit?.i18n,
          },
          amount,
          benefitCategoryId,
          benefitId,
          benefitUnitId,
          children: childrenVacBenefits || [],
        };
        benefitsList.push(newBenefit);
      }
    }
    if (benefitsList.length > 0) return benefitsList;
    return undefined;
  }

  static getBenefitType(vacancy: IVacancy, benefitTypeId: BenefitCategoryIds): string[] | undefined {
    const labelArray: string[] = [];
    if (vacancy.benefits) {
      const benefits = vacancy.benefits.filter((e) => e.benefitCategoryId === benefitTypeId);
      for (let index = 0; index < benefits.length; index++) {
        const vacancyBenefit = benefits[index];
        const i18n = vacancyBenefit.benefit?.i18n;
        if (i18n) labelArray.push(i18n[0].name!);
      }
    }
    if (labelArray.length > 0) return labelArray;
    return undefined;
  }

  static getBusinessSkillsNames(vacancy: IVacancy): string[] | undefined {
    const items = [vacancy.japaneseBusiness ? vacancy.japaneseBusiness.i18n![0].name! : ''];
    return items;
  }

  /**
   * Return Contract Type as string
   * @param wage
   * @returns
   */
  static getContractType(vacancy: IVacancy): string | undefined {
    if (vacancy.contractType) return ContractTypeGetters.getName(vacancy.contractType);
    return undefined;
  }

  /**
   * Returns the first available workplace. For now, we do not really make use of the fact
   * that a vacancy has an array of workplaces.
   * @param vacancy
   * @returns
   */
  static getWorkplace(vacancy: IVacancy): IWorkplace | undefined {
    if (!VacancyGetters.hasWorkplace(vacancy)) return undefined;
    return vacancy.workplaces![0];
  }

  /**
   * Whether the vacancy is allowed to be published or not.
   * Already published vacancies automatically count as publishable.
   * @param vacancy
   * @returns
   */
  static canBePublished(vacancy: IVacancy): boolean {
    return vacancy.published || VacancyGetters.hasPictures(vacancy);
  }

  /**
   * The UI only needs to know if the translation was completed or not, everything else
   * is displayed as "Running".
   * @returns
   */
  static uiTranslationStatus(vacancy: IVacancy): TranslationStatus {
    if (!vacancy) return TranslationStatus.Running;
    if (vacancy.translationStatus === TranslationStatus.Completed) return TranslationStatus.Completed;
    return TranslationStatus.Running;
  }

  /**
   * Fairly superficial check to see if the job has a picture or not.
   * For a more thorough check, use the hasPictures method.
   * @param vacancy
   * @returns
   */
  static hasPictureData(vacancy: IVacancy): boolean {
    return !!vacancy.pictures && vacancy.pictures.length > 0;
  }

  /**
   * Whether the vacancy has non-placeholder pictures or not.
   * @param vacancy
   */
  static hasPictures(vacancy: IVacancy): boolean {
    // Depending on the scenario, the API may send only one of the possible attachments.
    // So we check for any of them.
    const mainPictureUrl = VacancyGetters.getImageUrl(vacancy);
    const editedPictureUrl = VacancyGetters.getEditedImageUrl(vacancy);
    const thumbnailUrl = VacancyGetters.getThumbnailUrl(vacancy);

    const isMainPictureNotAPlaceholder = !VacancyPictureGetters.isVacancyUrlPlaceholder(mainPictureUrl);
    const isEditedPictureNotAPlaceholder = !VacancyPictureGetters.isVacancyUrlPlaceholder(editedPictureUrl);
    const isThumbPictureNotAPlaceholder = !VacancyPictureGetters.isVacancyUrlPlaceholder(thumbnailUrl);

    return isMainPictureNotAPlaceholder || isEditedPictureNotAPlaceholder || isThumbPictureNotAPlaceholder;
  }

  /**
   * Gets a string representing the vacancy's category. When the vacancy has a JobSector, the job sector name is returned.
   * If no job sector is present, but an industry is, the industry name is returned. If both are present, the job sector name
   * is prioritized.
   * @param vacancy
   */
  static getCategoryString(vacancy: IVacancy): string {
    if (vacancy.jobSector) {
      return JobSectorGetters.getName(vacancy.jobSector);
    } else if (vacancy.industry) {
      return IndustryGetters.getName(vacancy.industry);
    }
    return '';
  }
  /**
   *
   * @param vacancy Get only the jobsector string
   * @returns
   */
  static getSectorString(vacancy: IVacancy): string | undefined {
    if (!vacancy.jobSector) return undefined;
    return JobSectorGetters.getName(vacancy.jobSector);
  }
  /**
   *
   * @param vacancy Get only the industry string
   * @returns
   */
  static getIndustryString(vacancy: IVacancy): string | undefined {
    if (!vacancy.industry) return undefined;
    return IndustryGetters.getName(vacancy.industry);
  }

  static getIndustry(vacancy: IVacancy): IIndustry | undefined {
    if (!vacancy) return undefined;
    return vacancy.industry;
  }

  static getJobSector(vacancy: IVacancy): IJobSector | undefined {
    if (!vacancy) return undefined;
    return vacancy.jobSector;
  }

  static getCombinedIndustryJobSectorString(vacancy: IVacancy): string {
    if (!vacancy || !vacancy.industry) return '';
    if (!vacancy.jobSector) return IndustryGetters.getName(vacancy.industry!);
    return `${IndustryGetters.getName(vacancy.industry!)}, ${JobSectorGetters.getName(vacancy.jobSector!)}`;
  }

  /**
   * Whether the provided candidateVisa object contains visa types allowed in the vacancy.
   * @param vacancy
   * @param candidateVisa
   * @returns
   */
  static visaTypeCanApply(vacancy: IVacancy, candidateVisa: ICandidateVisa): boolean {
    if (!vacancy || !candidateVisa) return false;
    const { visaTypeId, visaSubTypeId } = candidateVisa;
    const found = vacancy.allowedVisaTypes?.find((e) => e.id === visaTypeId || e.id === visaSubTypeId);
    return !!found;
  }

  /**
   * Checks if the vacancy text has been updated
   * @param oldVacancyI18n
   * @param newVacancyI18n
   * @returns
   */
  static hasUpdatedI18n(oldVacancyI18n: IVacancyi18n, newVacancyI18n: IVacancyi18n): boolean {
    if (!oldVacancyI18n || !newVacancyI18n) return false;
    const { title, summary, description, otherSalariesAndBonusesDetails, japaneseLanguageDetails } = oldVacancyI18n;
    const newTitle = newVacancyI18n.title;
    const newSummary = newVacancyI18n.summary;
    const newDescription = newVacancyI18n.description;
    const newOtherSalariesAndBonusesDetails = newVacancyI18n.otherSalariesAndBonusesDetails;
    const newJapaneseLanguageDetails = newVacancyI18n.japaneseLanguageDetails;

    const sameDetails = newOtherSalariesAndBonusesDetails === otherSalariesAndBonusesDetails;
    const sameTitle = newTitle === title;
    const sameSummary = newSummary === summary;
    const sameDescription = newDescription === description;
    const sameJapaneseLanguageDetails = newJapaneseLanguageDetails === japaneseLanguageDetails;

    const hasSameI18n = sameTitle && sameSummary && sameDescription && sameDetails && sameJapaneseLanguageDetails;
    return !hasSameI18n;
  }

  static workplaceDetails(vacancy: IVacancy): IWorkplaceDetails[] {
    if (!vacancy.workplaces || vacancy.workplaces.length === 0) return [];
    return vacancy.workplaces![0].workplaceDetails || [];
  }

  /**
   * Returns exactly one wage with prioritization
   * @param vacancy
   * @param textSeparator
   * @param textPeriodSeparator
   * @param quantityPrefix
   * @param removeAdverbSuffix
   * @returns
   */
  static getFormattedPrioritizedWage(
    fromSalaryI18n: string,
    upToSalaryI18n: string,
    noWageDefinedI18n: string,
    vacancy: IVacancy,
    textSeparator: string = '~',
    textPeriodSeparator: string = '/',
    quantityPrefix: string = '',
  ): string[] {
    if (!VacancyGetters.hasWages(vacancy)) {
      return [noWageDefinedI18n];
    }
    const wage = WageGetters.getPrioritizedWage(vacancy.wages!);
    return [
      WageGetters.getFormattedWage(fromSalaryI18n, upToSalaryI18n, wage, textSeparator, textPeriodSeparator, quantityPrefix),
    ];
  }
}
