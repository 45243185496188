/**
 * Language codes used by HTML language tags.
 * @link https://www.w3schools.com/tags/ref_language_codes.asp
 */
export enum LanguageISO6391 {
  EN = 'en',
  JA = 'ja',
  VI = 'vi',
  ZH = 'zh'
}
