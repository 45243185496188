
import { defineComponent } from 'vue'

interface ISetup {
  methods: IMethod
}

interface IMethod {
  closeModal: () => void
  clickOutside: () => void
}

export default defineComponent({
  name: 'Accordion',
  props: {
    /**
     * Height of the modal
     */
    height: {
      type: String,
      required: false,
      default: '80%'
    },
    /**
     * Width of the modal
     */
    width: {
      type: String,
      required: false,
      default: 'min(440px, 90%)'
    },
    /**
     * Whether the overlay div should close modal on click
     */
    clickOutsideEnabled: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * Unique key used to force rerendering of the body slot. The problem with this is that any state (on focus etc) is lost on rerender,
     * which means that everything will depend on the state of the component (props, data, etc) to make sure that
     * the rerendered UI is correct.
     * Instead of setting the key in the slot directly in this file (which would force the entire slot to rerender), the parent
     * should use the key here to determine what parts of the slot they want to rerender.
     * @remarks I'm so sorry.
     * @todo Find the non-dumb way of making slots reactive according to the parent's data.
     */
    rerenderKey: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * cyTag for this modal uses in Cypress
     */
    cyTag: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props, { emit }): ISetup {
    const methods: IMethod = {
      /**
       * Close the modal
       */
      closeModal() {
        emit('close')
      },
      /**
       * Function to close modal if overlay is set to be clickable
       */
      clickOutside() {
        if (props.clickOutsideEnabled) {
          methods.closeModal()
        }
      }
    }
    return {
      methods
    }
  }
})
