import { GatewayBase } from '@/base/gateways/base'
import {
  IPicture,
  IResume,
  IResumeContractType,
  IResumeEducation,
  IResumeEducationLevel,
  IResumeJobHistory,
  IResumeQualification,
  LanguageLocale
} from '@tokyojob/core'
import {
  DeleteResumeEducationRequest,
  DeleteResumeQualificationRequest,
  DeleteResumeRequest,
  DeleteResumeWorkExperienceRequest,
  GetResumeRequest,
  GetResumesRequest,
  GetResumeEducationLevelRequest,
  PostResume,
  PutResume,
  UpdateAboutYouRequest,
  UpdateContactAndAddressRequest,
  UpdateEducationRequest,
  UpdateFileInfoRequest,
  UpdatePersonalRequestsRequest,
  UpdateQualificationRequest,
  UpdateReasonToApplyRequest,
  UpdateVisaRequest,
  UpdateWorkExperienceRequest,
  GetResumeContractTypeRequest,
  UpdateAboutYouTranslationRequest,
  UpdateContactAndAddressTranslationRequest,
  UpdateWorkExperienceTranslationRequest,
  UpdateEducationTranslationRequest,
  UpdateQualificationTranslationRequest,
  UpdateReasonToApplyTranslationRequest,
  UpdatePersonalRequestsTranslationRequest,
  ResumeNotificationRequest,
  UpdatePictureRequest,
  DeletePictureRequest,
  ResumeFinishBuilderRequest
} from '../network/requests/resume'

/**
 * Resume related queries.
 */
export default class Resume extends GatewayBase {
  /**
   * Gets the candidate's Resumes.
   */
  async GetResumes(): Promise<IResume[]> {
    return this.apiClient.request(new GetResumesRequest())
  }

  /**
   * Gets the specified resume.
   * @param id Id of the resume to fetch.
   */
  async GetResume(id: number, locale: LanguageLocale): Promise<IResume> {
    return await this.apiClient.request(new GetResumeRequest(id, locale))
  }

  /**
   * Gets a list of education level
   */
  async GetEducationLevelList(locale: LanguageLocale): Promise<IResumeEducationLevel[]> {
    return await this.apiClient.request(new GetResumeEducationLevelRequest(locale))
  }

  /**
   * Gets a list of contract types.
   * @param locale
   * @returns
   */
  async GetContractTypeList(locale: LanguageLocale): Promise<IResumeContractType[]> {
    return await this.apiClient.request(new GetResumeContractTypeRequest(locale))
  }

  /**
   * Creates a Candidate Resume.
   */
  async CreateResume(entity: IResume): Promise<IResume> {
    return await this.apiClient.request(new PostResume(entity))
  }

  /**
   * Updates the Candidate's Resume.
   */
  async UpdateResume(id: number, entity: IResume): Promise<IResume> {
    return this.apiClient.request(new PutResume(id, entity))
  }

  /**
   * Updates the Candidate's Resume file info.
   * @param id
   * @param entity
   * @returns
   */
  async UpdateFileInfo(id: number, entity: IResume): Promise<boolean> {
    return this.apiClient.request(new UpdateFileInfoRequest(id, entity))
  }

  async UpdatePicture(id: number, resumeData: IResume, attachmentFile: File, editedFile: File): Promise<IPicture | null> {
    if (!attachmentFile || !editedFile) return null
    if (!resumeData.picture) throw new Error('Picture data is missing')
    const form = new FormData()
    form.append('file', attachmentFile)
    form.append('editedFile', editedFile)
    // append all the picture data
    const { left, top, width, height, rotationAngle, flipHorizontal, flipVertical, zoom } = resumeData.picture
    if (left !== undefined) form.append('left', left.toString())
    if (top !== undefined) form.append('top', top.toString())
    if (width !== undefined) form.append('width', width.toString())
    if (height !== undefined) form.append('height', height.toString())
    if (rotationAngle !== undefined) form.append('rotationAngle', rotationAngle.toString())
    if (flipHorizontal !== undefined) form.append('flipHorizontal', flipHorizontal.toString())
    if (flipVertical !== undefined) form.append('flipVertical', flipVertical.toString())
    if (zoom !== undefined) form.append('zoom', zoom.toString())
    return this.apiClient.request(new UpdatePictureRequest(id, form))
  }

  async DeletePicture(id: number): Promise<boolean> {
    return this.apiClient.request(new DeletePictureRequest(id))
  }

  /**
   * Updates the Candidate's Resume about you.
   * @param id
   * @param entity
   * @returns
   */
  async UpdateAboutYou(id: number, entity: IResume): Promise<boolean> {
    return this.apiClient.request(new UpdateAboutYouRequest(id, entity))
  }

  /**
   * Updates the Candidate's Resume about you translation.
   */
  async UpdateAboutYouTranslation(id: number, entity: IResume): Promise<boolean> {
    return this.apiClient.request(new UpdateAboutYouTranslationRequest(id, entity))
  }

  /**
   * Updates the Candidate's Resume contact and address.
   * @param id
   * @param entity
   * @returns
   */
  async UpdateContactAndAddress(id: number, entity: IResume): Promise<boolean> {
    return this.apiClient.request(new UpdateContactAndAddressRequest(id, entity))
  }

  async UpdateContactAndAddressTranslation(id: number, entity: IResume): Promise<boolean> {
    return this.apiClient.request(new UpdateContactAndAddressTranslationRequest(id, entity))
  }

  /**
   * Updates the Candidate's Resume visa.
   * @param id
   * @param entity
   * @returns
   */
  async UpdateVisa(id: number, entity: IResume): Promise<boolean> {
    return this.apiClient.request(new UpdateVisaRequest(id, entity))
  }

  /**
   * Updates the Candidate's resume education.
   * @param id
   * @param entity
   * @returns
   */
  async UpdateEducation(id: number, entity: IResume): Promise<IResumeEducation[]> {
    return this.apiClient.request(new UpdateEducationRequest(id, entity))
  }

  async UpdateEducationTranslation(id: number, entity: IResume): Promise<IResumeEducation[]> {
    return this.apiClient.request(new UpdateEducationTranslationRequest(id, entity))
  }

  /**
   * Updates the Candidate's resume work experience.
   * @param id
   * @param entity
   * @returns
   */
  async UpdateWorkExperience(id: number, entity: IResume): Promise<IResumeJobHistory[]> {
    return this.apiClient.request(new UpdateWorkExperienceRequest(id, entity))
  }

  async UpdateWorkExperienceTranslation(id: number, entity: IResume): Promise<IResumeJobHistory[]> {
    return this.apiClient.request(new UpdateWorkExperienceTranslationRequest(id, entity))
  }

  /**
   * Updates the Candidate's resume qualification.
   * @param id
   * @param entity
   * @returns
   */
  async UpdateQualification(id: number, entity: IResume): Promise<IResumeQualification[]> {
    return this.apiClient.request(new UpdateQualificationRequest(id, entity))
  }

  async UpdateQualificationTranslation(id: number, entity: IResume): Promise<IResumeQualification[]> {
    return this.apiClient.request(new UpdateQualificationTranslationRequest(id, entity))
  }

  /**
   * Updates the Candidate's resume reason to apply.
   * @param id
   * @param entity
   * @returns
   */
  async UpdateReasonToApply(id: number, entity: IResume): Promise<boolean> {
    return this.apiClient.request(new UpdateReasonToApplyRequest(id, entity))
  }

  async UpdateReasonToApplyTranslation(id: number, entity: IResume): Promise<boolean> {
    return this.apiClient.request(new UpdateReasonToApplyTranslationRequest(id, entity))
  }

  /**
   * Updates the Candidate's resume personal requests.
   * @param id
   * @param entity
   * @returns
   */
  async UpdatePersonalRequests(id: number, entity: IResume): Promise<boolean> {
    return this.apiClient.request(new UpdatePersonalRequestsRequest(id, entity))
  }

  async UpdatePersonalRequestsTranslation(id: number, entity: IResume): Promise<boolean> {
    return this.apiClient.request(new UpdatePersonalRequestsTranslationRequest(id, entity))
  }

  /**
   * Deletes the specified resume.
   * @param id Id of the resume to delete.
   */
  async DeleteResume(id: number): Promise<boolean> {
    return this.apiClient.request(new DeleteResumeRequest(id))
  }

  /**
   * Deletes the specified educations within the resume.
   * @param id
   * @param ids
   */
  async DeleteResumeEducation(id: number, ids: number[]): Promise<boolean> {
    return this.apiClient.request(new DeleteResumeEducationRequest(id, ids))
  }

  /**
   * Deletes the specified resume.
   * @param id
   * @param ids
   */
  async DeleteResumeWorkExperience(id: number, ids: number[]): Promise<boolean> {
    return this.apiClient.request(new DeleteResumeWorkExperienceRequest(id, ids))
  }

  /**
   * Deletes the specified resume.
   * @param id
   * @param ids
   */
  async DeleteResumeQualification(id: number, ids: number[]): Promise<boolean> {
    return this.apiClient.request(new DeleteResumeQualificationRequest(id, ids))
  }

  /**
   * Sends a notification to the admins that a new resume has submitted.
   * @param id
   * @returns
   */
  async SendResumeNotification(id: number): Promise<void> {
    return this.apiClient.request(new ResumeNotificationRequest(id))
  }

  /**
   * Finish the resume builder.
   * @param id
   * @returns
   */
  async FinishResumeBuilder(id: number, resume: IResume): Promise<boolean> {
    return this.apiClient.request(new ResumeFinishBuilderRequest(id, resume))
  }
}
