import { IMenuProps } from '@tokyojob/frontend-core'
import { defineStore } from 'pinia'
import { IBreadcrumbProps } from '~/modules/app/entities/breadcrumb'
import { ICandidateDashboardStats, IUser } from '@tokyojob/core'

interface State {
  _loading: boolean
  _pageTitle: string
  _profile?: IUser
  _dashboardStats?: ICandidateDashboardStats
  _drawer: boolean
  _hasBotFloatingButton: boolean
  _dialog: object
  _breadcrumbs: IBreadcrumbProps[]
  _menus: IMenuProps[]
  _subs: IMenuProps[]
  _form: any
  _errors: object
  _hideFooter: boolean
}

export const getAppStore = defineStore('app-candidate', {
  state: (): State => ({
    _loading: false,
    _pageTitle: '',
    _profile: undefined,
    _dashboardStats: undefined,
    _drawer: false,
    _hasBotFloatingButton: false,
    _dialog: {},
    _breadcrumbs: [],
    _menus: [],
    _subs: [],
    _form: null,
    _errors: {},
    _hideFooter: false
  }),
  getters: {
    dialog: (state): object => state._dialog,
    pageTitle: (state): string => state._pageTitle,
    loading: (state): boolean => state._loading,
    hasBotFloatingButton: (state): boolean => state._hasBotFloatingButton,
    profile: (state): IUser | undefined => state._profile as IUser,
    dashboardStats: (state): ICandidateDashboardStats | undefined => state._dashboardStats,
    drawer: (state): boolean => state._drawer,
    breadcrumbs: (state): IBreadcrumbProps[] => state._breadcrumbs,
    menus: (state): IMenuProps[] => state._menus,
    subs: (state): IMenuProps[] => state._subs,
    form: (state): any[] => state._form,
    errors: (state): any => state._errors,
    hideFooter: (state): boolean => state._hideFooter
  },
  actions: {
    setDialog(type: string, value: boolean | null): void {
      this._dialog = { type, value }
    },
    setPageTitle(value: string): void {
      this._pageTitle = value
    },
    setLoading(value: boolean): void {
      this._loading = value
    },
    setProfile(value?: IUser): void {
      this._profile = value
    },
    setDashboardStats(value?: ICandidateDashboardStats): void {
      this._dashboardStats = value
    },
    setDrawer(value: boolean): void {
      this._drawer = value
    },
    setBreadcrumbs(value: IBreadcrumbProps[]): void {
      this._breadcrumbs = value
    },
    setMenus(value: IMenuProps[]): void {
      this._menus = value
    },
    setSubs(value: IMenuProps[]): void {
      this._subs = value
    },
    setForm(value: any[]): void {
      this._form = value
    },
    setErrors(value: any): void {
      this._errors = value
    },
    setHideFooter(value: boolean): void {
      this._hideFooter = value
    }
  }
})
