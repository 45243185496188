
import { defineComponent } from 'vue'
import { JWTDecoder, UserType } from '@tokyojob/core'
import { HtmlAttributesFactory } from '@tokyojob/frontend-core'
import CSidebar from '~/modules/app/components/app/c-sidebar/c-sidebar.component.vue'
import CTopbarAgent from '~/modules/app/components/app/c-topbar/agent/c-topbar-agent.component.vue'
import CAgentFooter from '~/modules/app/components/app/footer/agent/c-agent-footer.component.vue'
import { IApp } from '~/types/nuxt'
import { getProvidedApp, getRouter } from '~/base/utils/provided-getter'

const jwtDecoder = new JWTDecoder()

export default defineComponent({
  components: {
    CTopbarAgent,
    CAgentFooter,
    CSidebar
  },
  setup() {
    const router = getRouter()
    const app: IApp = getProvidedApp()
    const token = app.state.token
    let dashboardUrl: string | undefined = undefined
    let isAgent = false
    if (token) {
      const tokenData = jwtDecoder.decode(app.state.token!)
      if (tokenData) {
        dashboardUrl = tokenData.role === UserType.Candidate ? '/' : '/agent'
        isAgent = tokenData.role === UserType.Agent
      }
    }

    return {
      dashboardUrl,
      isAgent,
      router
    }
  },
  head() {
    return {
      htmlAttrs: HtmlAttributesFactory.basicAttributes(this.App.localizationSelector.languageISO6391)
    }
  }
})
