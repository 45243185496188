export enum ApplicationAgentStatusId {
  NewApplication = 100,
  ApplicationReview = 200,
  Contacting = 300,
  NotHiredBeforeInterview = 400, // Archive
  InterviewPreparation = 500,
  InterviewRescheduling = 600,
  InterviewCompleted = 700,
  NotHiredAfterInterview = 800, // Archive
  Offer = 900,
  Employment = 1000, // Hire
  CandidateWithdrew = 1100, // Triggered by candidate logs
  Memo = 9999999
}

/**
 * Statuses that only need a parent label.
 */
export const ApplicationWithParentLabelOnly: ApplicationAgentStatusId[] = [
  ApplicationAgentStatusId.NewApplication,
]