import { ICustomGTagEvent } from "../interfaces/i-custom-gtag-event";

export class GTagEventBaseFactory {
  /**
   * Utility method to generate a basic GTag event structure.
   *
   * @param eventName - The name of the event.
   * @param customData - Custom data associated with the event.
   * @returns An ICustomGTagEvent object with specified event name and data.
   */
  static createEvent(eventName: string, customData: any): ICustomGTagEvent {
    return { eventName, customData }
  }
}
