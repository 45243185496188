import { IJoomlaCMSLayoutContent, UrlUtils } from '@tokyojob/core'

/**
 * JoomlaContentUrlChecker
 */
export class JoomlaContentUrlChecker {
  /**
   * Adds a missing forward slash.
   * The joomla content may not include a forward slash at the beginning of the route.
   * To avoid messed up routes we need to add a forward slash.
   * @param content 
   * @returns 
   */
  addLeadingSlash(content: IJoomlaCMSLayoutContent | null): IJoomlaCMSLayoutContent | null {
    if (!content) return null
    for (const key in content.sidebar) {
      if (Object.prototype.hasOwnProperty.call(content.sidebar, key)) {
        const element = content.sidebar[key]
        element.route = UrlUtils.addLeadingSlash(element.route)
      }
    }
    for (const key in content.footer) {
      if (Object.prototype.hasOwnProperty.call(content.footer, key)) {
        const element = content.footer[key]
        element.route = UrlUtils.addLeadingSlash(element.route)
      }
    }
    for (const key in content.newsletter_link) {
      if (Object.prototype.hasOwnProperty.call(content.newsletter_link, key)) {
        const element = content.newsletter_link[key]
        content.newsletter_link[key] = UrlUtils.addLeadingSlash(element)
      }
    }
    return content
  }
}
