import { IsEnum, IsNumber, IsOptional } from 'class-validator';
import { ApplicationStatusTriggeredBy } from '../enum/application-status-triggered-by.enum';

export class ApplicationStatusGuestCandidateLogDto {
  @IsEnum(ApplicationStatusTriggeredBy)
  triggeredBy: ApplicationStatusTriggeredBy

  @IsNumber()
  applicationStatusCandidateId: number;

  @IsNumber()
  applicationSubStatusCandidateId: number;
}
