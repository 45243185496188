import { EnumDictionary } from '../../../utils';
import { LanguageLocale } from './language-locale.enum';

/**
 * Typed representation of language in full words.
 */
export enum LanguageFull {
  Japanese = 'Japanese',
  English = 'English',
  Vietnamese = 'Vietnamese',
  Chinese = 'Chinese',
}

export const LanguageFullMapper: EnumDictionary<LanguageLocale, LanguageFull> = {
  [LanguageLocale.JAjp]: LanguageFull.Japanese,
  [LanguageLocale.USen]: LanguageFull.English,
  [LanguageLocale.VIvn]: LanguageFull.Vietnamese,
  [LanguageLocale.ZHcn]: LanguageFull.Chinese,
};
