/**
 * Images hosted on aws that are used in the project
 */

/**
 * Image depicting a hand pointing to name on residence card
 * @todo I think we will have multiple of these with hand pointing to different areas?
 */
export const residenceCardNamePointer = 'https://tokyojob-public.s3.ap-northeast-1.amazonaws.com/public/residence-hand.png';
/**
 * Image depicting a hand pointing to designation papers
 */
export const designationPapersPointer = 'https://tokyojob-public.s3.ap-northeast-1.amazonaws.com/public/docs-hand.png';
