import {
  IResume,
  IResumeEducation,
  IResumeEducationLevel,
  JapanId,
  LanguageLocale,
  ResumeTranslationStatus,
} from '../../../models';
import { HepburnConverter, StringUtils } from '../../../utils';
import { CityGetters, LocationGetters, PrefectureGetters } from '../../location';

export class ResumeGetters {
  /**
   * Check nationality country exists in resume
   *
   * @param {IResume} resume - The resume object containing nationality country details.
   * @returns {boolean} - If nationality country exists, return true. Otherwise, return false.
   */
  static hasNationalityCountry(resume: IResume): boolean {
    return !!resume.nationalityCountry && !!resume.nationalityCountry.i18n && resume.nationalityCountry.i18n.length > 0;
  }

  /**
   * Gets the nationality country name from the resume
   * @param {IResume} resume - The resume object
   * @returns {string}
   */
  static getNationalityCountryName(resume: IResume, locale: LanguageLocale): string {
    if (!this.hasNationalityCountry(resume)) return '';
    const i18n = resume!.nationalityCountry!.i18n!.find((i) => i.locale === locale);
    return i18n && i18n.name ? i18n.name : '';
  }

  /**
   * Check nationality country exists in resume
   *
   * @param {IResume} resume - The resume object containing nationality country details.
   * @returns {boolean} - If nationality country exists, return true. Otherwise, return false.
   */
  static hasCurrentResidencyCountry(resume: IResume): boolean {
    return (
      !!resume.currentResidenceCountry && !!resume.currentResidenceCountry.i18n && resume.currentResidenceCountry.i18n.length > 0
    );
  }

  /**
   * Gets the nationality country name from the resume
   * @param {IResume} resume - The resume object
   * @returns {string}
   */
  static getCurrentResidencyCountryName(resume: IResume, locale: LanguageLocale = LanguageLocale.USen): string {
    if (!this.hasCurrentResidencyCountry(resume)) return '';
    const i18n = resume!.currentResidenceCountry!.i18n!.find((i) => i.locale === locale);
    return i18n && i18n.name ? i18n.name : '';
  }

  /**
   * Gets the education level Japanese for an education entry
   * @param educationItem
   * @returns {string}
   */
  static getEducationLevelJapanese(educationItem: IResumeEducation, locale: LanguageLocale = LanguageLocale.JAjp): string {
    const educationLevel = educationItem.educationLevel as IResumeEducationLevel;
    if (educationLevel && educationLevel.i18n && educationLevel.i18n.length > 0) {
      const i18n = educationLevel.i18n!.find((i) => i.locale === locale);
      return i18n && i18n.name ? StringUtils.Capitalize(i18n.name) : '';
    }
    return '';
  }

  /**
   * Gets the education level Japanese for an education entry
   * @param educationItem
   * @returns {string}
   */
  static getSchoolName(educationItem: IResumeEducation): string {
    return educationItem.schoolName!;
  }

  static getResumeTranslationStatus(resume: IResume): number {
    // If any entity's status has an error, the translation status is set to error
    // Otherwise, if any is set to "Pending", the translation status is set to "Pending"
    // Otherwise, the translation status is set to "Completed"
    if (!resume) return ResumeTranslationStatus.Failed;

    const props: (keyof IResume)[] = [
      'lastNameFuriganaStatus',
      'firstNameFuriganaStatus',
      'nativeLanguagesJapaneseStatus',
      'japaneseSkillLevelSummaryJapaneseStatus',
      'personalRequestsJapaneseStatus',
      'reasonsForApplyingJapaneseStatus',
    ];

    let hasPending = false;

    // Check the base list of properties
    for (const prop of props) {
      if (resume[prop] === ResumeTranslationStatus.Failed) return ResumeTranslationStatus.Failed;
      if (resume[prop] === ResumeTranslationStatus.Pending) hasPending = true;
    }

    // The address translations should check both kanji and kana, but ONLY if the address is in Japan
    // then check education, work history, and qualifications
    if (resume.currentResidenceCountry?.id === JapanId) {
      if (
        resume.addressLineJapaneseStatus === ResumeTranslationStatus.Failed ||
        resume.buildingAndRoomNumberJapaneseStatus === ResumeTranslationStatus.Failed ||
        resume.addressLineKanaStatus === ResumeTranslationStatus.Failed ||
        resume.buildingAndRoomNumberKanaStatus === ResumeTranslationStatus.Failed
      ) {
        return ResumeTranslationStatus.Failed;
      } else if (
        resume.addressLineJapaneseStatus === ResumeTranslationStatus.Pending ||
        resume.buildingAndRoomNumberJapaneseStatus === ResumeTranslationStatus.Pending ||
        resume.addressLineKanaStatus === ResumeTranslationStatus.Pending ||
        resume.buildingAndRoomNumberKanaStatus === ResumeTranslationStatus.Pending
      ) {
        hasPending = true;
      }
    }

    // Check education
    if (resume.education) {
      for (const e of resume.education) {
        if (e.specialtyJapaneseStatus === ResumeTranslationStatus.Failed) return ResumeTranslationStatus.Failed;
        if (e.specialtyJapaneseStatus === ResumeTranslationStatus.Pending) hasPending = true;
      }
    }

    // Check work experience
    if (resume.workExperience) {
      for (const w of resume.workExperience) {
        if (w.jobDescriptionJapaneseStatus === ResumeTranslationStatus.Failed) return ResumeTranslationStatus.Failed;
        if (w.jobDescriptionJapaneseStatus === ResumeTranslationStatus.Pending) hasPending = true;
      }
    }

    // Check qualifications
    if (resume.qualifications) {
      for (const q of resume.qualifications) {
        if (q.descriptionJapaneseStatus === ResumeTranslationStatus.Failed) return ResumeTranslationStatus.Failed;
        if (q.descriptionJapaneseStatus === ResumeTranslationStatus.Pending) hasPending = true;
      }
    }

    return hasPending ? ResumeTranslationStatus.Pending : ResumeTranslationStatus.Completed;
  }

  static allItemsAreCompleted(list: IResume[]): boolean {
    return list.every((item) => this.getResumeTranslationStatus(item) === ResumeTranslationStatus.Completed);
  }

  /**
   * Gets the kana address line within the resume
   * @param resume
   * @returns
   */
  static getAddressLineKana(resume: IResume): string {
    const converter = new HepburnConverter();
    const prefecture = PrefectureGetters.getFuriganaName(resume.prefecture, LanguageLocale.JAjp);
    const city = CityGetters.getFuriganaName(resume.city, LanguageLocale.JAjp);
    const town = LocationGetters.getFuriganaName(resume.town, LanguageLocale.JAjp);
    const addressLine = resume.addressLineKana;
    const buildingAndRoomNumber =
      resume.buildingAndRoomNumberKana == null || resume.buildingAndRoomNumberKana === 'null'
        ? ''
        : resume.buildingAndRoomNumberKana;
    return converter.convert(`${prefecture}${city}${town}${addressLine}${buildingAndRoomNumber}`).katakana;
  }

  /**
   * Gets the japanese address line within the resume
   * @param resume
   * @returns
   */
  static getAddressLineJapanese(resume: IResume): string {
    const prefecture = PrefectureGetters.getName(resume.prefecture, LanguageLocale.JAjp);
    const city = CityGetters.getName(resume.city, LanguageLocale.JAjp);
    const town = LocationGetters.getName(resume.town, LanguageLocale.JAjp);
    const addressLine = resume.addressLine;
    const buildingAndRoomNumber =
      resume.buildingAndRoomNumberJapanese == null || resume.buildingAndRoomNumberJapanese === 'null'
        ? ''
        : resume.buildingAndRoomNumberJapanese;
    return `${prefecture}${city}${town}${addressLine}${buildingAndRoomNumber}`;
  }
}
