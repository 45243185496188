import { UserType } from "../../user";
import { IAgentSignup } from "../interfaces/i-agent-signup";
import { ICandidateSignup } from "../interfaces/i-candidate-signup";

export class SignupFactory {
  static Candidate(): ICandidateSignup {
    return { account: '', password: '', userType: UserType.Candidate }
  }
  static Agent(): IAgentSignup {
    return { company: '', account: '', password: '', userType: UserType.Agent }
  }
}