import { LanguageLocale } from "../../../../../../core/src/models/language/constants/language-locale.enum";
import { EnumDictionary } from "../../../../../../core/src/utils/types/enum-dictionary";

/**
 * Set of constants to be Grid.
 */
export const AGGridNoRowsMessage: EnumDictionary<LanguageLocale, string> = {
  [LanguageLocale.USen]: 'No rows to display',
  [LanguageLocale.JAjp]: 'データ無し',
  [LanguageLocale.VIvn]: 'Không có hàng để hiển thị',
  [LanguageLocale.ZHcn]: '无数据'
}
