import { ApplicationAgentStatusId, ApplicationWithParentLabelOnly, IApplicationStatusAgentLog, IStatusLabels } from '../../../models';
import { ApplicationAgentStatusGetters } from './application-status-agent.getters';
import { ApplicationAgentSubStatusGetters } from './application-substatus-agent.getters';

export class ApplicationAgentStatusLogGetters {
  /**
   * Returns a formatted label for the given status log.
   * If substatus exists, combines parent and sub labels, otherwise returns parent label.
   * @param statusLog
   * @returns
   */
  static getStatusLabel(statusLog: IApplicationStatusAgentLog): IStatusLabels {
    // if (!statusLog.applicationStatusAgent) return '';
    const statusLabel = ApplicationAgentStatusGetters.getName(statusLog.applicationStatusAgent!);
    // Some status do not need a substatus label. Ignore those.
    if (!this.needsSubstatusLabel(statusLog)) return {status: statusLabel, subStatus: ''};
    // if (!statusLog.applicationSubStatusAgent) return parentLabel;
    const subStatusLabel = ApplicationAgentSubStatusGetters.getName(statusLog.applicationSubStatusAgent!);
    return {status: statusLabel, subStatus: subStatusLabel};
  }

  /**
   * Determines whether a substatus label is needed for the given status log.
   * @param statusLog
   * @returns
   */
  static needsSubstatusLabel(statusLog: IApplicationStatusAgentLog): boolean {
    if (!statusLog.applicationStatusAgent) return false;
    if (!statusLog.applicationStatusAgentId) return false;
    const isInParentLabelOnlyArray = ApplicationWithParentLabelOnly.includes(
      statusLog.applicationStatusAgentId! as ApplicationAgentStatusId,
    );
    return !isInParentLabelOnlyArray;
  }
}
