import { EmailSeed, EmailConfirm, PasswordResetSeed } from '../schema/models'
import { SpaceAuth } from '../schema/namespace'
import { APIRequest, HTTPMethod } from '@tokyojob/frontend-core'
import {
  ISignin,
  ICandidateSignup,
  IAgentSignup,
  IGuestCandidateApplicationAuth,
  OTPVerifyDto,
  IUserVerify,
  IOTPValidationResult,
  EmailChangeRequestDto
} from '@tokyojob/core'

export class SigninRequest implements APIRequest<SpaceAuth.SigninResponse> {
  response!: SpaceAuth.SigninResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: SpaceAuth.SigninRequest
  constructor(params: ISignin) {
    this.params = params
    this.path = '/auth/signin/'
  }
}

export class OtpValidateRequest implements APIRequest<SpaceAuth.OtpValidateResponse> {
  response!: SpaceAuth.OtpValidateResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: SpaceAuth.OtpValidateRequest
  constructor(dto: OTPVerifyDto) {
    this.params = dto
    this.path = '/otp/validate/'
  }
}

export class EmailChangeRequestOTP implements APIRequest<IUserVerify> {
  response!: IUserVerify
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: EmailChangeRequestDto
  constructor(dto: EmailChangeRequestDto) {
    this.params = dto
    this.path = '/otp/email-change-request/'
  }
}

export class EmailChangeVerifyOTP implements APIRequest<IOTPValidationResult> {
  response!: IOTPValidationResult
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params?: OTPVerifyDto
  constructor(dto: OTPVerifyDto) {
    this.params = dto
    this.path = '/otp/email-change-verify/'
  }
}

/**
 * Retrieves the otp data for the current user, if any exists.
 */
export class OtpValidateFetchRequest implements APIRequest<SpaceAuth.OtpValidateFetchResponse> {
  response!: SpaceAuth.OtpValidateFetchResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  params: SpaceAuth.OtpValidateFetchRequest
  constructor() {
    this.path = '/otp/fetch/'
  }
}

export class OtpRefreshRequest implements APIRequest<SpaceAuth.OtpRefreshResponse> {
  response!: SpaceAuth.OtpRefreshResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  params: SpaceAuth.OtpValidateFetchRequest
  constructor() {
    this.path = '/otp/refresh/'
  }
}

/**
 * Only applies for email accounts that try to create a duplicate account when their account is still unconfirmed.
 */
export class ValidateUnconfirmedAccountRequest implements APIRequest<SpaceAuth.ValidateUnconfirmedAccountResponse> {
  response!: SpaceAuth.ValidateUnconfirmedAccountResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(uuid: string) {
    this.path = `/auth/unconfirmed-account?uuid=${uuid}`
  }
}

export class PhoneNumberAuthRequest implements APIRequest<SpaceAuth.SigninResponse> {
  response!: SpaceAuth.PhoneNumberAuthResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: SpaceAuth.PhoneNumberAuthRequest
  constructor(params: IGuestCandidateApplicationAuth) {
    this.params = params
    this.path = '/auth/guest-signin/'
  }
}

export class CandidateSignupRequest implements APIRequest<SpaceAuth.CandidateSignupResponse> {
  response!: SpaceAuth.CandidateSignupResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: SpaceAuth.CandidateSignupRequest
  constructor(params: ICandidateSignup) {
    this.params = params
    this.path = '/auth/candidate-signup/'
  }
}

export class AgentSignupRequest implements APIRequest<SpaceAuth.AgentSignupResponse> {
  response!: SpaceAuth.AgentSignupResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: SpaceAuth.AgentSignupRequest
  constructor(params: IAgentSignup) {
    this.params = params
    this.path = '/auth/agent-signup/'
  }
}

export class RefreshRequest implements APIRequest<SpaceAuth.authRefreshResponse> {
  response!: SpaceAuth.authRefreshResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor() {
    this.path = '/user/token/refresh/'
  }
}

/**
 * API Request to perform an email address update verification for users that already have an account. It's NOT used to verify an account for the first time.
 */
export class EmailUpdateVerifyRequest implements APIRequest<SpaceAuth.EmailUpdateVerifyResponse> {
  response!: SpaceAuth.EmailUpdateVerifyResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: SpaceAuth.EmailUpdateVerifyRequest
  constructor(token: string) {
    this.path = '/auth/email/verifychange'
    this.params = { token }
  }
}

/**
 * API Request to perform an account confirmation for newly signed up users.
 */
export class AccountRegistrationConfirmationRequest implements APIRequest<SpaceAuth.AccountConfirmationResponse> {
  response!: SpaceAuth.AccountConfirmationResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: SpaceAuth.AccountConfirmationRequest
  constructor(token: string) {
    this.path = '/auth/email/verify'
    this.params = { token }
  }
}

/**
 * HTTP Request to update the user's email. This only creates a request that must be later confirmed by the user.
 */
export class EmailChangeRequest implements APIRequest<SpaceAuth.EmailChangeResponse> {
  response!: SpaceAuth.EmailChangeResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.PATCH
  params: SpaceAuth.EmailChangeRequest
  constructor(seed: EmailSeed) {
    this.params = seed
    this.path = '/auth/email/change'
  }
}

/**
 * HTTP Request to resend the confirmation email.
 */
export class EmailReconfirmRequest implements APIRequest<SpaceAuth.EmailReconfirmResponse> {
  response!: SpaceAuth.EmailReconfirmResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: SpaceAuth.EmailReconfirmRequest
  constructor(props: EmailConfirm) {
    this.params = props
    this.path = '/auth/email/resend-email-confirmation'
  }
}

/**
 * HTTP Request to send a password reset email.
 */
export class PasswordRecoveryRequest implements APIRequest<SpaceAuth.PasswordRecoveryResponse> {
  response!: SpaceAuth.PasswordRecoveryResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: SpaceAuth.PasswordRecoveryRequest
  constructor(props: EmailConfirm) {
    this.params = props
    this.path = '/auth/password/recovery'
  }
}

/**
 * HTTP Request to verify the password reset token.
 */
export class PasswordResetTokenVerifyRequest implements APIRequest<SpaceAuth.PasswordResetTokenVerifyResponse> {
  response!: SpaceAuth.PasswordResetTokenVerifyResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: SpaceAuth.PasswordResetTokenVerifyRequest
  constructor(token: string) {
    this.params = { token }
    this.path = '/auth/password/reset-token-verify'
  }
}

/**
 * HTTP Request to reset the password.
 */
export class PasswordResetRequest implements APIRequest<SpaceAuth.PasswordResetResponse> {
  response!: SpaceAuth.PasswordResetResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: SpaceAuth.PasswordResetRequest
  constructor(props: PasswordResetSeed) {
    this.params = props
    this.path = '/auth/password/reset'
  }
}
