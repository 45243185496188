import { IVacancyMapPoint } from '../../../models';
import { CoordinatesGetters } from '../../geocoding-location/getters/coordinates.getter';

/**
 * Shortcuts to handle vacancy map point properties.
 */
export class VacancyMapPointGetters {
  /**
   * Formatted coordinates string.
   * @param point
   * @returns
   */
  static getCoordinateStr(point: IVacancyMapPoint): string | undefined {
    const hasValidCoords = point && point.coordinates;
    if (!hasValidCoords) return undefined;
    return CoordinatesGetters.getCoordinatesString(point.coordinates);
  }
}
