import { Type } from 'class-transformer';
import { IsArray, ValidateNested } from 'class-validator';
import { VacancyBenefitDto } from '../vacancy-benefit.dto';

/**
 * The DTO that validates the benefits section
 */
export class VacancySaveBenefitsDto {
  /**
   * Benefits information
   */
  @ValidateNested({ each: true })
  @Type(() => VacancyBenefitDto)
  benefits: VacancyBenefitDto[];
}
