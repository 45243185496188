// Using @base breaks the tests :D
import { AppList } from '../../../config/constants/app.enum'
import { AppEnvironment } from '../../../base/app-environment'
import { EnumDictionary } from '../../../../../../core/src/utils/types/enum-dictionary'

/**
 * The ids are filtered through a whitelist, so there is no need to save them as secrets.
 * You can also just find it in the network tab of the developer console anyway.
 */
export const AgentProductionDataStreamId = 'GTM-KRJKPRZ'
export const AgentTestDataStreamId = 'GTM-5JN38RT2'
export const CandidateProductionDataStreamId = 'GTM-MRQHP54'
export const CandidateTestDataStreamId = 'GTM-PJN7PZVK'

export const EnvAppDictionary: EnumDictionary<AppEnvironment, EnumDictionary<AppList, string>> = {
  [AppEnvironment.Development]: {
    [AppList.Agent]: AgentTestDataStreamId,
    [AppList.Candidate]: CandidateTestDataStreamId,
  },
  [AppEnvironment.Test]: {
    [AppList.Agent]: AgentTestDataStreamId,
    [AppList.Candidate]: CandidateTestDataStreamId,
  },
  [AppEnvironment.Production]: {
    [AppList.Agent]: AgentProductionDataStreamId,
    [AppList.Candidate]: CandidateProductionDataStreamId,
  },
}

/**
 * Google analytics related configuration object.
 */
export class GoogleAnalyticsConfig {
  /**
   * Obscures the tag itself, but it is still visible in the network tab.
   * Use an hid for quick identification.
   * @param env
   * @param app
   * @returns
   */
  static buildScriptTagDataUri(env: AppEnvironment, app: AppList): string {
    const code = GoogleAnalyticsConfig.buildScriptTag(env, app)
    const dataUri = Buffer.from(code).toString('base64')
    return `data:text/javascript;base64,${dataUri}`
  }

  /**
   * Creates the google Analytics script tag.
   * @param env
   * @param app
   * @returns
   */
  static buildScriptTag(env: AppEnvironment, app: AppList): string {
    const gtagId = GoogleAnalyticsConfig.getGTagId(env, app)
    // it is horrible, but is copied over directly as provided from google
    let gtagStr = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtagId}');
      `
    return gtagStr
  }

  /**
   * Returns a data stream id to use depending on the provided environment.
   * @param env
   * @param app
   * @returns
   */
  static getGTagId(env: AppEnvironment, app: AppList): string {
    return EnvAppDictionary[env][app]
  }
}
