import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _68f6d2b3 = () => interopDefault(import('../app/pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _01953096 = () => interopDefault(import('../app/pages/application/index.vue' /* webpackChunkName: "pages/application/index" */))
const _69f5699d = () => interopDefault(import('../app/pages/japanese-resume-builder/index.vue' /* webpackChunkName: "pages/japanese-resume-builder/index" */))
const _adf4b1a2 = () => interopDefault(import('../app/pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _d54c7806 = () => interopDefault(import('../app/pages/resume/index.vue' /* webpackChunkName: "pages/resume/index" */))
const _5139f490 = () => interopDefault(import('../app/pages/a/account-confirmation.vue' /* webpackChunkName: "pages/a/account-confirmation" */))
const _1db8f783 = () => interopDefault(import('../app/pages/account/account-sso-confirm.vue' /* webpackChunkName: "pages/account/account-sso-confirm" */))
const _19b1e86b = () => interopDefault(import('../app/pages/account/email-updated.vue' /* webpackChunkName: "pages/account/email-updated" */))
const _02b669df = () => interopDefault(import('../app/pages/account/unverified-email.vue' /* webpackChunkName: "pages/account/unverified-email" */))
const _37864fe2 = () => interopDefault(import('../app/pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _6bbc69a6 = () => interopDefault(import('../app/pages/auth/password-recovery.vue' /* webpackChunkName: "pages/auth/password-recovery" */))
const _208fd23c = () => interopDefault(import('../app/pages/auth/signup.vue' /* webpackChunkName: "pages/auth/signup" */))
const _c02cd7e0 = () => interopDefault(import('../app/pages/auth/test-page.vue' /* webpackChunkName: "pages/auth/test-page" */))
const _56705f60 = () => interopDefault(import('../app/pages/job/map/index.vue' /* webpackChunkName: "pages/job/map/index" */))
const _48c209f6 = () => interopDefault(import('../app/pages/auth/agent/login.vue' /* webpackChunkName: "pages/auth/agent/login" */))
const _57492d23 = () => interopDefault(import('../app/pages/auth/agent/password-recovery.vue' /* webpackChunkName: "pages/auth/agent/password-recovery" */))
const _42708ced = () => interopDefault(import('../app/pages/auth/agent/register-success.vue' /* webpackChunkName: "pages/auth/agent/register-success" */))
const _36cb5aa8 = () => interopDefault(import('../app/pages/auth/agent/signup.vue' /* webpackChunkName: "pages/auth/agent/signup" */))
const _55727315 = () => interopDefault(import('../app/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _54bb81c8 = () => interopDefault(import('../app/pages/auth/agent/password-reset/_token.vue' /* webpackChunkName: "pages/auth/agent/password-reset/_token" */))
const _56629930 = () => interopDefault(import('../app/pages/admin/resume/_id/index.vue' /* webpackChunkName: "pages/admin/resume/_id/index" */))
const _4ea20ad2 = () => interopDefault(import('../app/pages/auth/password-reset/_token.vue' /* webpackChunkName: "pages/auth/password-reset/_token" */))
const _d9266d14 = () => interopDefault(import('../app/pages/a/_shortId/index.vue' /* webpackChunkName: "pages/a/_shortId/index" */))
const _52981081 = () => interopDefault(import('../app/pages/application/_id/index.vue' /* webpackChunkName: "pages/application/_id/index" */))
const _0c723f2e = () => interopDefault(import('../app/pages/job/_id/index.vue' /* webpackChunkName: "pages/job/_id/index" */))
const _629eedec = () => interopDefault(import('../app/pages/vacancy/_id/index.vue' /* webpackChunkName: "pages/vacancy/_id/index" */))
const _30c84de2 = () => interopDefault(import('../app/pages/job/_id/application-success.vue' /* webpackChunkName: "pages/job/_id/application-success" */))
const _cb121a66 = () => interopDefault(import('../app/pages/job/_id/apply/index.vue' /* webpackChunkName: "pages/job/_id/apply/index" */))
const _23232d0c = () => interopDefault(import('../app/pages/job/_id/apply-verify.vue' /* webpackChunkName: "pages/job/_id/apply-verify" */))
const _5ca86cb2 = () => interopDefault(import('../app/pages/job/_id/preview/index.vue' /* webpackChunkName: "pages/job/_id/preview/index" */))
const _06693bed = () => interopDefault(import('../app/pages/job/_id/quick-apply/index.vue' /* webpackChunkName: "pages/job/_id/quick-apply/index" */))
const _25908be2 = () => interopDefault(import('../app/pages/job/_id/translation-not-available.vue' /* webpackChunkName: "pages/job/_id/translation-not-available" */))
const _63f1699e = () => interopDefault(import('../app/pages/resume/_id/builder.vue' /* webpackChunkName: "pages/resume/_id/builder" */))
const _0c664438 = () => interopDefault(import('../app/pages/resume/_id/edit.vue' /* webpackChunkName: "pages/resume/_id/edit" */))
const _44188e26 = () => interopDefault(import('../app/pages/resume/_id/processing.vue' /* webpackChunkName: "pages/resume/_id/processing" */))
const _890b3ac6 = () => interopDefault(import('../app/pages/job/_id/preview/mobile.vue' /* webpackChunkName: "pages/job/_id/preview/mobile" */))
const _fda16f58 = () => interopDefault(import('../app/pages/a/_shortId/details/_id/index.vue' /* webpackChunkName: "pages/a/_shortId/details/_id/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _68f6d2b3,
    meta: {"prefix":"","language":"en","originalPath":"\u002Faccount"},
    name: "account"
  }, {
    path: "/application",
    component: _01953096,
    meta: {"prefix":"","language":"en","originalPath":"\u002Fapplication"},
    name: "application"
  }, {
    path: "/japanese-resume-builder",
    component: _69f5699d,
    meta: {"prefix":"","language":"en","originalPath":"\u002Fjapanese-resume-builder"},
    name: "japanese-resume-builder"
  }, {
    path: "/profile",
    component: _adf4b1a2,
    meta: {"prefix":"","language":"en","originalPath":"\u002Fprofile"},
    name: "profile"
  }, {
    path: "/resume",
    component: _d54c7806,
    meta: {"prefix":"","language":"en","originalPath":"\u002Fresume"},
    name: "resume"
  }, {
    path: "/a/account-confirmation",
    component: _5139f490,
    meta: {"prefix":"","language":"en","originalPath":"\u002Fa\u002Faccount-confirmation"},
    name: "a-account-confirmation"
  }, {
    path: "/account/account-sso-confirm",
    component: _1db8f783,
    meta: {"prefix":"","language":"en","originalPath":"\u002Faccount\u002Faccount-sso-confirm"},
    name: "account-account-sso-confirm"
  }, {
    path: "/account/email-updated",
    component: _19b1e86b,
    meta: {"prefix":"","language":"en","originalPath":"\u002Faccount\u002Femail-updated"},
    name: "account-email-updated"
  }, {
    path: "/account/unverified-email",
    component: _02b669df,
    meta: {"prefix":"","language":"en","originalPath":"\u002Faccount\u002Funverified-email"},
    name: "account-unverified-email"
  }, {
    path: "/auth/login",
    component: _37864fe2,
    meta: {"prefix":"","language":"en","originalPath":"\u002Fauth\u002Flogin"},
    name: "auth-login"
  }, {
    path: "/auth/password-recovery",
    component: _6bbc69a6,
    meta: {"prefix":"","language":"en","originalPath":"\u002Fauth\u002Fpassword-recovery"},
    name: "auth-password-recovery"
  }, {
    path: "/auth/signup",
    component: _208fd23c,
    meta: {"prefix":"","language":"en","originalPath":"\u002Fauth\u002Fsignup"},
    name: "auth-signup"
  }, {
    path: "/auth/test-page",
    component: _c02cd7e0,
    meta: {"prefix":"","language":"en","originalPath":"\u002Fauth\u002Ftest-page"},
    name: "auth-test-page"
  }, {
    path: "/job/map",
    component: _56705f60,
    meta: {"prefix":"","language":"en","originalPath":"\u002Fjob\u002Fmap"},
    name: "job-map"
  }, {
    path: "/auth/agent/login",
    component: _48c209f6,
    meta: {"prefix":"","language":"en","originalPath":"\u002Fauth\u002Fagent\u002Flogin"},
    name: "auth-agent-login"
  }, {
    path: "/auth/agent/password-recovery",
    component: _57492d23,
    meta: {"prefix":"","language":"en","originalPath":"\u002Fauth\u002Fagent\u002Fpassword-recovery"},
    name: "auth-agent-password-recovery"
  }, {
    path: "/auth/agent/register-success",
    component: _42708ced,
    meta: {"prefix":"","language":"en","originalPath":"\u002Fauth\u002Fagent\u002Fregister-success"},
    name: "auth-agent-register-success"
  }, {
    path: "/auth/agent/signup",
    component: _36cb5aa8,
    meta: {"prefix":"","language":"en","originalPath":"\u002Fauth\u002Fagent\u002Fsignup"},
    name: "auth-agent-signup"
  }, {
    path: "/",
    component: _55727315,
    meta: {"prefix":"","language":"en","originalPath":"\u002F"},
    name: "index"
  }, {
    path: "/auth/agent/password-reset/:token?",
    component: _54bb81c8,
    meta: {"prefix":"","language":"en","originalPath":"\u002Fauth\u002Fagent\u002Fpassword-reset\u002F:token?"},
    name: "auth-agent-password-reset-token"
  }, {
    path: "/admin/resume/:id",
    component: _56629930,
    meta: {"prefix":"","language":"en","originalPath":"\u002Fadmin\u002Fresume\u002F:id"},
    name: "admin-resume-id"
  }, {
    path: "/auth/password-reset/:token?",
    component: _4ea20ad2,
    meta: {"prefix":"","language":"en","originalPath":"\u002Fauth\u002Fpassword-reset\u002F:token?"},
    name: "auth-password-reset-token"
  }, {
    path: "/a/:shortId",
    component: _d9266d14,
    meta: {"prefix":"","language":"en","originalPath":"\u002Fa\u002F:shortId"},
    name: "a-shortId"
  }, {
    path: "/application/:id",
    component: _52981081,
    meta: {"prefix":"","language":"en","originalPath":"\u002Fapplication\u002F:id"},
    name: "application-id"
  }, {
    path: "/job/:id",
    component: _0c723f2e,
    meta: {"prefix":"","language":"en","originalPath":"\u002Fjob\u002F:id"},
    name: "job-id"
  }, {
    path: "/vacancy/:id",
    component: _629eedec,
    meta: {"prefix":"","language":"en","originalPath":"\u002Fvacancy\u002F:id"},
    name: "vacancy-id"
  }, {
    path: "/job/:id?/application-success",
    component: _30c84de2,
    meta: {"prefix":"","language":"en","originalPath":"\u002Fjob\u002F:id?\u002Fapplication-success"},
    name: "job-id-application-success"
  }, {
    path: "/job/:id?/apply",
    component: _cb121a66,
    meta: {"prefix":"","language":"en","originalPath":"\u002Fjob\u002F:id?\u002Fapply"},
    name: "job-id-apply"
  }, {
    path: "/job/:id?/apply-verify",
    component: _23232d0c,
    meta: {"prefix":"","language":"en","originalPath":"\u002Fjob\u002F:id?\u002Fapply-verify"},
    name: "job-id-apply-verify"
  }, {
    path: "/job/:id?/preview",
    component: _5ca86cb2,
    meta: {"prefix":"","language":"en","originalPath":"\u002Fjob\u002F:id?\u002Fpreview"},
    name: "job-id-preview"
  }, {
    path: "/job/:id?/quick-apply",
    component: _06693bed,
    meta: {"prefix":"","language":"en","originalPath":"\u002Fjob\u002F:id?\u002Fquick-apply"},
    name: "job-id-quick-apply"
  }, {
    path: "/job/:id?/translation-not-available",
    component: _25908be2,
    meta: {"prefix":"","language":"en","originalPath":"\u002Fjob\u002F:id?\u002Ftranslation-not-available"},
    name: "job-id-translation-not-available"
  }, {
    path: "/resume/:id/builder",
    component: _63f1699e,
    meta: {"prefix":"","language":"en","originalPath":"\u002Fresume\u002F:id\u002Fbuilder"},
    name: "resume-id-builder"
  }, {
    path: "/resume/:id/edit",
    component: _0c664438,
    meta: {"prefix":"","language":"en","originalPath":"\u002Fresume\u002F:id\u002Fedit"},
    name: "resume-id-edit"
  }, {
    path: "/resume/:id/processing",
    component: _44188e26,
    meta: {"prefix":"","language":"en","originalPath":"\u002Fresume\u002F:id\u002Fprocessing"},
    name: "resume-id-processing"
  }, {
    path: "/job/:id?/preview/mobile",
    component: _890b3ac6,
    meta: {"prefix":"","language":"en","originalPath":"\u002Fjob\u002F:id?\u002Fpreview\u002Fmobile"},
    name: "job-id-preview-mobile"
  }, {
    path: "/a/:shortId?/details/:id",
    component: _fda16f58,
    meta: {"prefix":"","language":"en","originalPath":"\u002Fa\u002F:shortId?\u002Fdetails\u002F:id"},
    name: "a-shortId-details-id"
  }, {
    path: "/jp/account",
    component: _68f6d2b3,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002Faccount"},
    name: "account-jp"
  }, {
    path: "/jp/application",
    component: _01953096,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002Fapplication"},
    name: "application-jp"
  }, {
    path: "/jp/japanese-resume-builder",
    component: _69f5699d,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002Fjapanese-resume-builder"},
    name: "japanese-resume-builder-jp"
  }, {
    path: "/jp/profile",
    component: _adf4b1a2,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002Fprofile"},
    name: "profile-jp"
  }, {
    path: "/jp/resume",
    component: _d54c7806,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002Fresume"},
    name: "resume-jp"
  }, {
    path: "/jp/a/account-confirmation",
    component: _5139f490,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002Fa\u002Faccount-confirmation"},
    name: "a-account-confirmation-jp"
  }, {
    path: "/jp/account/account-sso-confirm",
    component: _1db8f783,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002Faccount\u002Faccount-sso-confirm"},
    name: "account-account-sso-confirm-jp"
  }, {
    path: "/jp/account/email-updated",
    component: _19b1e86b,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002Faccount\u002Femail-updated"},
    name: "account-email-updated-jp"
  }, {
    path: "/jp/account/unverified-email",
    component: _02b669df,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002Faccount\u002Funverified-email"},
    name: "account-unverified-email-jp"
  }, {
    path: "/jp/auth/login",
    component: _37864fe2,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002Fauth\u002Flogin"},
    name: "auth-login-jp"
  }, {
    path: "/jp/auth/password-recovery",
    component: _6bbc69a6,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002Fauth\u002Fpassword-recovery"},
    name: "auth-password-recovery-jp"
  }, {
    path: "/jp/auth/signup",
    component: _208fd23c,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002Fauth\u002Fsignup"},
    name: "auth-signup-jp"
  }, {
    path: "/jp/auth/test-page",
    component: _c02cd7e0,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002Fauth\u002Ftest-page"},
    name: "auth-test-page-jp"
  }, {
    path: "/jp/job/map",
    component: _56705f60,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002Fjob\u002Fmap"},
    name: "job-map-jp"
  }, {
    path: "/jp/auth/agent/login",
    component: _48c209f6,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002Fauth\u002Fagent\u002Flogin"},
    name: "auth-agent-login-jp"
  }, {
    path: "/jp/auth/agent/password-recovery",
    component: _57492d23,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002Fauth\u002Fagent\u002Fpassword-recovery"},
    name: "auth-agent-password-recovery-jp"
  }, {
    path: "/jp/auth/agent/register-success",
    component: _42708ced,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002Fauth\u002Fagent\u002Fregister-success"},
    name: "auth-agent-register-success-jp"
  }, {
    path: "/jp/auth/agent/signup",
    component: _36cb5aa8,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002Fauth\u002Fagent\u002Fsignup"},
    name: "auth-agent-signup-jp"
  }, {
    path: "/jp/",
    component: _55727315,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002F"},
    name: "index-jp"
  }, {
    path: "/jp/auth/agent/password-reset/:token?",
    component: _54bb81c8,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002Fauth\u002Fagent\u002Fpassword-reset\u002F:token?"},
    name: "auth-agent-password-reset-token-jp"
  }, {
    path: "/jp/admin/resume/:id",
    component: _56629930,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002Fadmin\u002Fresume\u002F:id"},
    name: "admin-resume-id-jp"
  }, {
    path: "/jp/auth/password-reset/:token?",
    component: _4ea20ad2,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002Fauth\u002Fpassword-reset\u002F:token?"},
    name: "auth-password-reset-token-jp"
  }, {
    path: "/jp/a/:shortId",
    component: _d9266d14,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002Fa\u002F:shortId"},
    name: "a-shortId-jp"
  }, {
    path: "/jp/application/:id",
    component: _52981081,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002Fapplication\u002F:id"},
    name: "application-id-jp"
  }, {
    path: "/jp/job/:id",
    component: _0c723f2e,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002Fjob\u002F:id"},
    name: "job-id-jp"
  }, {
    path: "/jp/vacancy/:id",
    component: _629eedec,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002Fvacancy\u002F:id"},
    name: "vacancy-id-jp"
  }, {
    path: "/jp/job/:id?/application-success",
    component: _30c84de2,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002Fjob\u002F:id?\u002Fapplication-success"},
    name: "job-id-application-success-jp"
  }, {
    path: "/jp/job/:id?/apply",
    component: _cb121a66,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002Fjob\u002F:id?\u002Fapply"},
    name: "job-id-apply-jp"
  }, {
    path: "/jp/job/:id?/apply-verify",
    component: _23232d0c,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002Fjob\u002F:id?\u002Fapply-verify"},
    name: "job-id-apply-verify-jp"
  }, {
    path: "/jp/job/:id?/preview",
    component: _5ca86cb2,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002Fjob\u002F:id?\u002Fpreview"},
    name: "job-id-preview-jp"
  }, {
    path: "/jp/job/:id?/quick-apply",
    component: _06693bed,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002Fjob\u002F:id?\u002Fquick-apply"},
    name: "job-id-quick-apply-jp"
  }, {
    path: "/jp/job/:id?/translation-not-available",
    component: _25908be2,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002Fjob\u002F:id?\u002Ftranslation-not-available"},
    name: "job-id-translation-not-available-jp"
  }, {
    path: "/jp/resume/:id/builder",
    component: _63f1699e,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002Fresume\u002F:id\u002Fbuilder"},
    name: "resume-id-builder-jp"
  }, {
    path: "/jp/resume/:id/edit",
    component: _0c664438,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002Fresume\u002F:id\u002Fedit"},
    name: "resume-id-edit-jp"
  }, {
    path: "/jp/resume/:id/processing",
    component: _44188e26,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002Fresume\u002F:id\u002Fprocessing"},
    name: "resume-id-processing-jp"
  }, {
    path: "/jp/job/:id?/preview/mobile",
    component: _890b3ac6,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002Fjob\u002F:id?\u002Fpreview\u002Fmobile"},
    name: "job-id-preview-mobile-jp"
  }, {
    path: "/jp/a/:shortId?/details/:id",
    component: _fda16f58,
    meta: {"prefix":"jp","language":"jp","originalPath":"\u002Fa\u002F:shortId?\u002Fdetails\u002F:id"},
    name: "a-shortId-details-id-jp"
  }, {
    path: "/vi/account",
    component: _68f6d2b3,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002Faccount"},
    name: "account-vi"
  }, {
    path: "/vi/application",
    component: _01953096,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002Fapplication"},
    name: "application-vi"
  }, {
    path: "/vi/japanese-resume-builder",
    component: _69f5699d,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002Fjapanese-resume-builder"},
    name: "japanese-resume-builder-vi"
  }, {
    path: "/vi/profile",
    component: _adf4b1a2,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002Fprofile"},
    name: "profile-vi"
  }, {
    path: "/vi/resume",
    component: _d54c7806,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002Fresume"},
    name: "resume-vi"
  }, {
    path: "/vi/a/account-confirmation",
    component: _5139f490,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002Fa\u002Faccount-confirmation"},
    name: "a-account-confirmation-vi"
  }, {
    path: "/vi/account/account-sso-confirm",
    component: _1db8f783,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002Faccount\u002Faccount-sso-confirm"},
    name: "account-account-sso-confirm-vi"
  }, {
    path: "/vi/account/email-updated",
    component: _19b1e86b,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002Faccount\u002Femail-updated"},
    name: "account-email-updated-vi"
  }, {
    path: "/vi/account/unverified-email",
    component: _02b669df,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002Faccount\u002Funverified-email"},
    name: "account-unverified-email-vi"
  }, {
    path: "/vi/auth/login",
    component: _37864fe2,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002Fauth\u002Flogin"},
    name: "auth-login-vi"
  }, {
    path: "/vi/auth/password-recovery",
    component: _6bbc69a6,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002Fauth\u002Fpassword-recovery"},
    name: "auth-password-recovery-vi"
  }, {
    path: "/vi/auth/signup",
    component: _208fd23c,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002Fauth\u002Fsignup"},
    name: "auth-signup-vi"
  }, {
    path: "/vi/auth/test-page",
    component: _c02cd7e0,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002Fauth\u002Ftest-page"},
    name: "auth-test-page-vi"
  }, {
    path: "/vi/job/map",
    component: _56705f60,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002Fjob\u002Fmap"},
    name: "job-map-vi"
  }, {
    path: "/vi/auth/agent/login",
    component: _48c209f6,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002Fauth\u002Fagent\u002Flogin"},
    name: "auth-agent-login-vi"
  }, {
    path: "/vi/auth/agent/password-recovery",
    component: _57492d23,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002Fauth\u002Fagent\u002Fpassword-recovery"},
    name: "auth-agent-password-recovery-vi"
  }, {
    path: "/vi/auth/agent/register-success",
    component: _42708ced,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002Fauth\u002Fagent\u002Fregister-success"},
    name: "auth-agent-register-success-vi"
  }, {
    path: "/vi/auth/agent/signup",
    component: _36cb5aa8,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002Fauth\u002Fagent\u002Fsignup"},
    name: "auth-agent-signup-vi"
  }, {
    path: "/vi/",
    component: _55727315,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002F"},
    name: "index-vi"
  }, {
    path: "/vi/auth/agent/password-reset/:token?",
    component: _54bb81c8,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002Fauth\u002Fagent\u002Fpassword-reset\u002F:token?"},
    name: "auth-agent-password-reset-token-vi"
  }, {
    path: "/vi/admin/resume/:id",
    component: _56629930,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002Fadmin\u002Fresume\u002F:id"},
    name: "admin-resume-id-vi"
  }, {
    path: "/vi/auth/password-reset/:token?",
    component: _4ea20ad2,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002Fauth\u002Fpassword-reset\u002F:token?"},
    name: "auth-password-reset-token-vi"
  }, {
    path: "/vi/a/:shortId",
    component: _d9266d14,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002Fa\u002F:shortId"},
    name: "a-shortId-vi"
  }, {
    path: "/vi/application/:id",
    component: _52981081,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002Fapplication\u002F:id"},
    name: "application-id-vi"
  }, {
    path: "/vi/job/:id",
    component: _0c723f2e,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002Fjob\u002F:id"},
    name: "job-id-vi"
  }, {
    path: "/vi/vacancy/:id",
    component: _629eedec,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002Fvacancy\u002F:id"},
    name: "vacancy-id-vi"
  }, {
    path: "/vi/job/:id?/application-success",
    component: _30c84de2,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002Fjob\u002F:id?\u002Fapplication-success"},
    name: "job-id-application-success-vi"
  }, {
    path: "/vi/job/:id?/apply",
    component: _cb121a66,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002Fjob\u002F:id?\u002Fapply"},
    name: "job-id-apply-vi"
  }, {
    path: "/vi/job/:id?/apply-verify",
    component: _23232d0c,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002Fjob\u002F:id?\u002Fapply-verify"},
    name: "job-id-apply-verify-vi"
  }, {
    path: "/vi/job/:id?/preview",
    component: _5ca86cb2,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002Fjob\u002F:id?\u002Fpreview"},
    name: "job-id-preview-vi"
  }, {
    path: "/vi/job/:id?/quick-apply",
    component: _06693bed,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002Fjob\u002F:id?\u002Fquick-apply"},
    name: "job-id-quick-apply-vi"
  }, {
    path: "/vi/job/:id?/translation-not-available",
    component: _25908be2,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002Fjob\u002F:id?\u002Ftranslation-not-available"},
    name: "job-id-translation-not-available-vi"
  }, {
    path: "/vi/resume/:id/builder",
    component: _63f1699e,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002Fresume\u002F:id\u002Fbuilder"},
    name: "resume-id-builder-vi"
  }, {
    path: "/vi/resume/:id/edit",
    component: _0c664438,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002Fresume\u002F:id\u002Fedit"},
    name: "resume-id-edit-vi"
  }, {
    path: "/vi/resume/:id/processing",
    component: _44188e26,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002Fresume\u002F:id\u002Fprocessing"},
    name: "resume-id-processing-vi"
  }, {
    path: "/vi/job/:id?/preview/mobile",
    component: _890b3ac6,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002Fjob\u002F:id?\u002Fpreview\u002Fmobile"},
    name: "job-id-preview-mobile-vi"
  }, {
    path: "/vi/a/:shortId?/details/:id",
    component: _fda16f58,
    meta: {"prefix":"vi","language":"vi","originalPath":"\u002Fa\u002F:shortId?\u002Fdetails\u002F:id"},
    name: "a-shortId-details-id-vi"
  }, {
    path: "/zh/account",
    component: _68f6d2b3,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002Faccount"},
    name: "account-zh"
  }, {
    path: "/zh/application",
    component: _01953096,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002Fapplication"},
    name: "application-zh"
  }, {
    path: "/zh/japanese-resume-builder",
    component: _69f5699d,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002Fjapanese-resume-builder"},
    name: "japanese-resume-builder-zh"
  }, {
    path: "/zh/profile",
    component: _adf4b1a2,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002Fprofile"},
    name: "profile-zh"
  }, {
    path: "/zh/resume",
    component: _d54c7806,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002Fresume"},
    name: "resume-zh"
  }, {
    path: "/zh/a/account-confirmation",
    component: _5139f490,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002Fa\u002Faccount-confirmation"},
    name: "a-account-confirmation-zh"
  }, {
    path: "/zh/account/account-sso-confirm",
    component: _1db8f783,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002Faccount\u002Faccount-sso-confirm"},
    name: "account-account-sso-confirm-zh"
  }, {
    path: "/zh/account/email-updated",
    component: _19b1e86b,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002Faccount\u002Femail-updated"},
    name: "account-email-updated-zh"
  }, {
    path: "/zh/account/unverified-email",
    component: _02b669df,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002Faccount\u002Funverified-email"},
    name: "account-unverified-email-zh"
  }, {
    path: "/zh/auth/login",
    component: _37864fe2,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002Fauth\u002Flogin"},
    name: "auth-login-zh"
  }, {
    path: "/zh/auth/password-recovery",
    component: _6bbc69a6,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002Fauth\u002Fpassword-recovery"},
    name: "auth-password-recovery-zh"
  }, {
    path: "/zh/auth/signup",
    component: _208fd23c,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002Fauth\u002Fsignup"},
    name: "auth-signup-zh"
  }, {
    path: "/zh/auth/test-page",
    component: _c02cd7e0,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002Fauth\u002Ftest-page"},
    name: "auth-test-page-zh"
  }, {
    path: "/zh/job/map",
    component: _56705f60,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002Fjob\u002Fmap"},
    name: "job-map-zh"
  }, {
    path: "/zh/auth/agent/login",
    component: _48c209f6,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002Fauth\u002Fagent\u002Flogin"},
    name: "auth-agent-login-zh"
  }, {
    path: "/zh/auth/agent/password-recovery",
    component: _57492d23,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002Fauth\u002Fagent\u002Fpassword-recovery"},
    name: "auth-agent-password-recovery-zh"
  }, {
    path: "/zh/auth/agent/register-success",
    component: _42708ced,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002Fauth\u002Fagent\u002Fregister-success"},
    name: "auth-agent-register-success-zh"
  }, {
    path: "/zh/auth/agent/signup",
    component: _36cb5aa8,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002Fauth\u002Fagent\u002Fsignup"},
    name: "auth-agent-signup-zh"
  }, {
    path: "/zh/",
    component: _55727315,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002F"},
    name: "index-zh"
  }, {
    path: "/zh/auth/agent/password-reset/:token?",
    component: _54bb81c8,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002Fauth\u002Fagent\u002Fpassword-reset\u002F:token?"},
    name: "auth-agent-password-reset-token-zh"
  }, {
    path: "/zh/admin/resume/:id",
    component: _56629930,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002Fadmin\u002Fresume\u002F:id"},
    name: "admin-resume-id-zh"
  }, {
    path: "/zh/auth/password-reset/:token?",
    component: _4ea20ad2,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002Fauth\u002Fpassword-reset\u002F:token?"},
    name: "auth-password-reset-token-zh"
  }, {
    path: "/zh/a/:shortId",
    component: _d9266d14,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002Fa\u002F:shortId"},
    name: "a-shortId-zh"
  }, {
    path: "/zh/application/:id",
    component: _52981081,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002Fapplication\u002F:id"},
    name: "application-id-zh"
  }, {
    path: "/zh/job/:id",
    component: _0c723f2e,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002Fjob\u002F:id"},
    name: "job-id-zh"
  }, {
    path: "/zh/vacancy/:id",
    component: _629eedec,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002Fvacancy\u002F:id"},
    name: "vacancy-id-zh"
  }, {
    path: "/zh/job/:id?/application-success",
    component: _30c84de2,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002Fjob\u002F:id?\u002Fapplication-success"},
    name: "job-id-application-success-zh"
  }, {
    path: "/zh/job/:id?/apply",
    component: _cb121a66,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002Fjob\u002F:id?\u002Fapply"},
    name: "job-id-apply-zh"
  }, {
    path: "/zh/job/:id?/apply-verify",
    component: _23232d0c,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002Fjob\u002F:id?\u002Fapply-verify"},
    name: "job-id-apply-verify-zh"
  }, {
    path: "/zh/job/:id?/preview",
    component: _5ca86cb2,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002Fjob\u002F:id?\u002Fpreview"},
    name: "job-id-preview-zh"
  }, {
    path: "/zh/job/:id?/quick-apply",
    component: _06693bed,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002Fjob\u002F:id?\u002Fquick-apply"},
    name: "job-id-quick-apply-zh"
  }, {
    path: "/zh/job/:id?/translation-not-available",
    component: _25908be2,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002Fjob\u002F:id?\u002Ftranslation-not-available"},
    name: "job-id-translation-not-available-zh"
  }, {
    path: "/zh/resume/:id/builder",
    component: _63f1699e,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002Fresume\u002F:id\u002Fbuilder"},
    name: "resume-id-builder-zh"
  }, {
    path: "/zh/resume/:id/edit",
    component: _0c664438,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002Fresume\u002F:id\u002Fedit"},
    name: "resume-id-edit-zh"
  }, {
    path: "/zh/resume/:id/processing",
    component: _44188e26,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002Fresume\u002F:id\u002Fprocessing"},
    name: "resume-id-processing-zh"
  }, {
    path: "/zh/job/:id?/preview/mobile",
    component: _890b3ac6,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002Fjob\u002F:id?\u002Fpreview\u002Fmobile"},
    name: "job-id-preview-mobile-zh"
  }, {
    path: "/zh/a/:shortId?/details/:id",
    component: _fda16f58,
    meta: {"prefix":"zh","language":"zh","originalPath":"\u002Fa\u002F:shortId?\u002Fdetails\u002F:id"},
    name: "a-shortId-details-id-zh"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
