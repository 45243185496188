import { IFormInputData } from '../../../modules/html/forms/inputs/interfaces/form-input-data'

/**
 * Make a list of numbers for use in a CDropdown. E.g. minutes in increments of 5.
 */
export class NumberList {
  static Create(limit: number, increment: number = 1, digits: number = 1): IFormInputData[] {
    if (increment < 1 || limit < 1) throw new Error('Parameter is not a positive integer!')
    const numberList = []
    for (let i = 0; i < limit; i += increment) {
      let num = i.toString()
      while (num.length < digits) num = '0' + num
      numberList.push({
        label: num,
        value: num,
      })
    }
    return numberList
  }

  static Range(start: number, end: number, isAscending: boolean = true, increment: number = 1): IFormInputData[] {
    if (increment < 1 || end < start) throw new Error('Parameter is not a positive integer or end is less than start!')

    const numberList: IFormInputData[] = []

    if (isAscending) {
      for (let i = start; i <= end; i += increment) {
        let num = Math.floor(i)
        numberList.push({ label: num.toString(), value: num.toString() })
      }
    } else {
      for (let i = end; i >= start; i -= increment) {
        let num = Math.floor(i)
        numberList.push({ label: num.toString(), value: num.toString() })
      }
    }

    return numberList
  }
}
