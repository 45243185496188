import { LocalizationPath } from '../../../models/common/localization/constants/localization-path';

/**
 * Required for specific business logic rules, includes a list of Certificate Group Ids that are considered constant in the application.
 * TODO: Guarantee that the Ids present here are saved as such in the Database through proper seeding.
 */
export const JapaneseConversationKnowledgeCube: Record<number, string[]> = {
  1: LocalizationPath.JapaneseSkillsCubeLvl1,
  2: LocalizationPath.JapaneseSkillsCubeLvl2,
  3: LocalizationPath.JapaneseSkillsCubeLvl3,
  4: LocalizationPath.JapaneseSkillsCubeLvl4,
  5: LocalizationPath.JapaneseSkillsCubeLvl5,
  6: LocalizationPath.JapaneseSkillsCubeLvl6,
  7: LocalizationPath.JapaneseSkillsCubeLvl7,
};
