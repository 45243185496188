import { IContractType, IContractTypei18n } from '../../../models';

/**
 *
 */
export class ContractTypeGetters {
  /**
   *
   * @param contract
   * @returns
   */
  static getName(contract: IContractType) {
    if (!ContractTypeGetters.hasi18n(contract)) return '';
    // TODO: Remove informations, this is used for backwards compatibility with old endpoints
    const i18n: IContractTypei18n[] = contract.i18n || (contract as any).informations;
    return i18n[0].name;
  }

  /**
   *
   * @param contract
   * @returns
   */
  static hasi18n(contract: IContractType): boolean {
    if (!contract) return false;
    // TODO: Remove informations, this is used for backwards compatibility with old endpoints
    const i18n: IContractTypei18n[] = contract.i18n || (contract as any).informations;
    return !!(i18n && i18n.length > 0);
  }
}
