
import { defineComponent, reactive, ref, Ref, watch } from 'vue'
import { LanguageOriginal, LanguageURL, LanguageOriginalToURLMapper } from '@tokyojob/core'
import {
  ButtonFactory,
  CButton,
  CIcon,
  CIconFactory,
  CLanguageSwitchModal,
  IconSize,
  IconType,
  CLoader
} from '@tokyojob/frontend-components'
import { IApp } from '~/types/nuxt'
import CSidebar from '../c-sidebar.component.vue'
import { getProvidedApp, getRoute, getRouter } from '~/base/utils/provided-getter'

const closeButton = CIconFactory.Icon({
  icon: 'times',
  classes: 'text-h3'
})
const burgerMenu = CIconFactory.Icon({
  icon: 'bars',
  size: IconSize.medium
})
const languageIcon = CIconFactory.Globe()
const downCarotIcon = CIconFactory.DropdownCaret()

export default defineComponent({
  components: {
    CIcon,
    CButton,
    CSidebar,
    CLanguageSwitchModal,
    CLoader
  },
  setup() {
    const app: IApp = getProvidedApp()
    const route = getRoute()
    const router = getRouter()
    const menuOpen = ref(false)
    const currentLanguage = ref(app.localizationSelector.language)
    const showLanguageSwitchModal = ref(false)
    const loadingOverlay: Ref<typeof CLoader> = ref(null) as any
    const cookieRepository = reactive(app.state)
    // Buttons
    const quickLoginButton = ButtonFactory.button({
      link: { router: router, link: '/auth/login' },
      icon: CIconFactory.Icon({
        icon: 'right-to-bracket',
        type: IconType.light,
        size: IconSize.medium
      }),
      label: 'Login',
      classes: 'row-start align-center text-regular theme-blue-dark1-clear g-5'
    })

    const isLoggedIn = ref(cookieRepository.isLoggedIn || !!cookieRepository.guestToken)
    watch(
      () => cookieRepository.isLoggedIn,
      (value) => (isLoggedIn.value = value || !!cookieRepository.guestToken)
    )
    watch(
      () => cookieRepository.guestToken,
      (value) => (isLoggedIn.value = cookieRepository.isLoggedIn || !!value)
    )

    const methods = {
      /**
       * Toggle menu open and closed
       */
      toggleMenu() {
        menuOpen.value = !menuOpen.value
      },

      /**
       * Assign CSS class depending on whether the sidemenu is currently open or closed
       */
      menuState(value: boolean): string {
        return value ? 'menu-open' : 'menu-closed'
      },
      /**
       * Prevents clicks passing through to content behind the menu
       */
      handleClick(e: Event) {
        e.stopPropagation()
      },
      /**
       * Open the language switch modal
       */
      toggleLanguageSwitchModal(): void {
        showLanguageSwitchModal.value = !showLanguageSwitchModal.value
      },
      /**
       * Change language using localizationSelector
       */
      async setLanguage(language: LanguageOriginal): Promise<void> {
        loadingOverlay.value.show()
        const languageURL = LanguageOriginalToURLMapper[language]
        await app.localizationSelector.changeLanguage(languageURL, route.value)
        currentLanguage.value = languageURL
        showLanguageSwitchModal.value = false
        loadingOverlay.value.hide()
      },
      /**
       * Check if language is currently select. If so apply css class active
       */
      isActive(language: LanguageURL) {
        return app.localizationSelector.language === language
      }
    }
    return {
      menuOpen,
      closeButton,
      burgerMenu,
      methods,
      downCarotIcon,
      languageIcon,
      currentLanguage,
      showLanguageSwitchModal,
      quickLoginButton,
      isLoggedIn,
      loadingOverlay
    }
  }
})
