import axios, { AxiosResponse } from 'axios'
import { APIRequest } from './api.request'
import { HTTPMethod } from './types'
import { APIError } from './api.error'
import { ICookieRepository } from '../../../../core/src/modules/auth/token/i-cookie-repository'

interface HttpError extends Error {
  response?: {
    status: number
  }
  message: string
}

export class APIClient {
  serverBaseURL: string = process.env.serverBaseUrl || ''
  clientBaseURL: string = process.env.clientBaseUrl || ''
  timeout: number = 15 * 1000

  constructor(readonly tokenRepository: ICookieRepository) {}

  async request<U>(request: APIRequest<U>, onUploadProgressCallback?: (progressEvent: any) => void): Promise<U> {
    const url = (process as any).server ? this.serverBaseURL : this.clientBaseURL

    const isRead = request.method === HTTPMethod.GET
    const data = (!isRead && request.params) || undefined

    try {
      const response = await axios.request({
        url: request.path,
        method: request.method,
        params: isRead ? request.params : undefined,
        data,
        timeout: this.timeout,
        baseURL: request.baseURL || url,
        headers: this.createHeaders(),
        onUploadProgress: onUploadProgressCallback,
      })

      return request.parse ? request.parse(response) : this.parse<U>(response)
    } catch (err: any) {
      throw this.normalizeError(err)
    }
  }

  parse<U>(response: AxiosResponse): U {
    return response.data
  }

  normalizeError(error: HttpError): APIError {
    return {
      status: error.response?.status,
      message: error.message,
      raw: error,
    }
  }

  createHeaders() {
    if (!this.tokenRepository) {
      return { 'Content-Type': 'application/json' }
    }

    let header: Record<string, any> = {
      'Content-Type': 'application/json',
      xhrFields: true,
      withCredentials: true,
      'Access-Control-Allow-Origin': '*',
      'X-Requested-With': 'XMLHttpRequest',
    }

    const cookieJWT = this.tokenRepository.token
    if (cookieJWT) {
      header.Authorization = 'Bearer ' + cookieJWT
    }

    return header
  }
}
