import { LocalizationLanguage } from '@tokyojob/core'
import { GatewayBase } from '~/base/gateways/base'
import { GetLocalizationRequest } from '../network/requests/localization'

export default class Localization extends GatewayBase {
  async GetLocalization(): Promise<LocalizationLanguage | null> {
    const item = await this.apiClient.request(new GetLocalizationRequest())
    return item
  }
}
