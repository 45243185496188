import { IsArray, IsLatitude, IsLongitude, IsOptional, IsString, Length } from 'class-validator';

export class WorkplaceSaveDto {
  public constructor(
    name: string,
    latitude: number,
    longitude: number,
    code: string,
    stationDistanceInformation: string,
    workplaceDetailsIds: number[],
  ) {
    this.name = name;
    this.latitude = latitude;
    this.longitude = longitude;
    this.code = code;
    this.stationDistanceInformation = stationDistanceInformation;
    this.workplaceDetailsIds = workplaceDetailsIds;
  }

  /**
   * Name of the workplace.
   */
  @IsString()
  @Length(1, 50)
  name: string;

  /**
   * Latitude location of the workplace.
   */
  @IsLatitude()
  latitude: number;

  /**
   * Longitude location of the workplace.
   */
  @IsLongitude()
  longitude: number;

  /**
   * The zip code where the workplace is located.
   */
  @IsString()
  code: string;

  /**
   * Distance information from the nearest train station.
   */
  @IsOptional()
  stationDistanceInformation: string;

  /**
   * The workplace details that is related to this workplace.
   */
  @IsOptional()
  @IsArray()
  workplaceDetailsIds: number[];
}
