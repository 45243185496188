import { EnumDictionary } from '../../../utils/types/enum-dictionary';
import { ApplicationCandidateStatusId } from './application-candidate-status-id.enum';

/**
 * CSS classes and styles usecd for each application status.
 * An empty string implies that the theme will depend on the substatus.
 * If the child is also empty, then the theme will be the default one.
 */
export const ApplicationCandidateAGGridStatusThemes: EnumDictionary<ApplicationCandidateStatusId, string> = {
  [ApplicationCandidateStatusId.Submitted]: 'theme-purple-medium1-color',
  [ApplicationCandidateStatusId.Check]: 'theme-shades-medium1-white',
  [ApplicationCandidateStatusId.Contacting]: 'theme-shades-dark1-white',
  [ApplicationCandidateStatusId.Rejected]: 'theme-orange-dark1-white',
  [ApplicationCandidateStatusId.Interview]: 'theme-blue-light1-white',
  [ApplicationCandidateStatusId.Hired]: 'theme-yellow-medium1-white',
  [ApplicationCandidateStatusId.Withdrawn]: 'theme-red-tokyo-white',
  [ApplicationCandidateStatusId.Memo]: 'theme-shades-light1-white',
};

export const ApplicationCandidateTimelineStatusThemes: EnumDictionary<ApplicationCandidateStatusId, string> = {
  [ApplicationCandidateStatusId.Submitted]: 'theme-purple-light1-white color-text-dark1',
  [ApplicationCandidateStatusId.Check]: 'theme-shades-light1-white shadow',
  [ApplicationCandidateStatusId.Contacting]: 'theme-shades-dark1-white',
  [ApplicationCandidateStatusId.Rejected]: 'theme-orange-dark1-white',
  [ApplicationCandidateStatusId.Interview]: 'theme-blue-light1-white',
  [ApplicationCandidateStatusId.Hired]: 'theme-yellow-medium1-white',
  [ApplicationCandidateStatusId.Withdrawn]: 'theme-red-tokyo-white',
  [ApplicationCandidateStatusId.Memo]: 'theme-shades-light1-white',
};

export const ApplicationCandidateAGGridStatusIconThemes: EnumDictionary<ApplicationCandidateStatusId, string> = {
  [ApplicationCandidateStatusId.Submitted]: 'color-shades-white',
  [ApplicationCandidateStatusId.Check]: 'color-shades-dark1',
  [ApplicationCandidateStatusId.Contacting]: '',
  [ApplicationCandidateStatusId.Rejected]: 'color-orange-dark1-outline',
  [ApplicationCandidateStatusId.Interview]: 'color-blue-medium1',
  [ApplicationCandidateStatusId.Hired]: 'color-gold-dark1',
  [ApplicationCandidateStatusId.Withdrawn]: 'color-red-tokyo',
  [ApplicationCandidateStatusId.Memo]: '',
};

export const ApplicationCandidateTimelineStatusIconThemes: EnumDictionary<ApplicationCandidateStatusId, string> = {
  [ApplicationCandidateStatusId.Submitted]: 'color-purple-medium1',
  [ApplicationCandidateStatusId.Check]: 'color-shades-dark1',
  [ApplicationCandidateStatusId.Contacting]: '',
  [ApplicationCandidateStatusId.Interview]: 'color-blue-medium1',
  [ApplicationCandidateStatusId.Rejected]: 'color-orange-dark1-outline',
  [ApplicationCandidateStatusId.Hired]: 'color-gold-dark1',
  [ApplicationCandidateStatusId.Withdrawn]: 'color-red-tokyo',
  [ApplicationCandidateStatusId.Memo]: '',
};
