import { Middleware } from '@nuxt/types'
import { Context } from '@nuxt/types/app'

const dashboardPath = '/dashboard'

/**
 * A middleware that restricts a page to be rendered by directing the user to the dashboard
 * @param ctx 
 */
export const serverRenderingRestrictor: Middleware = async (ctx: Context) => {
  const { redirect } = ctx
  try {
    if (process.server) {
      redirect(dashboardPath)
    } 
  } catch (error) {
    redirect(dashboardPath)
  }
}
