import { IApplication } from '../../../../../../../core/src'
import { ApplicationGetters } from '../../../../../../../core/src/domain/application/getters/application.getters'
import { getApplicationStore } from '../../../../store/application.store'

export function VacancyHasAppliedComposable() {
  /**
   * Returns all applications in from the pinia storage
   * @returns
   */
  function getApplications(): IApplication[] {
    const applicationStore = getApplicationStore()
    return applicationStore.values()
  }

  /**
   * Returns true if the passed vacancy id has been applied to, otherwise return false
   */
  function hasApplied(vacancyId: number | undefined): boolean {
    if (!vacancyId) return false
    const applications = getApplications()
    return ApplicationGetters.hasApplied(vacancyId!, applications)
  }

  /**
   * 
   * @param vacancyId 
   * @returns 
   */
  function getApplicationIdForJob(vacancyId: number | undefined): number | undefined {
    if (!vacancyId) return undefined
    const applications = getApplications()
    return ApplicationGetters.getApplicationIdForJob(applications, vacancyId)
  }

  return {
    hasApplied,
    getApplicationIdForJob
  }
}
