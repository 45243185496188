import { IsDefined, IsOptional } from 'class-validator';

export class AnonymousApplicationVerifyVisaTypeDto {
  @IsDefined()
  vacancyId: number;

  @IsDefined()
  visaTypeId: number;

  @IsOptional()
  visaSubTypeId: number;
}
