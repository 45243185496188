import { Middleware } from '@nuxt/types'
import { Context } from '@nuxt/types/app'
import { UserType } from '@tokyojob/core'
import { APIClient, JWTHandler, LogType } from '@tokyojob/frontend-core'
import { RouterLinkFactory } from '../../../../components/src/lib-components/router-links'

const adminAuth: Middleware = async ({ App, redirect, route }: Context) => {
  try {
    const apiClient = new APIClient(App.state)
    const jwtHandler = new JWTHandler(apiClient)
    await jwtHandler.evaluateAuth(App.state, redirect, UserType.Admin, true)
  } catch (error) {
    // remove cookies and redirect to login page
    App.state.token = ''
    App.state.refreshToken = ''
    App.logService.handle({ type: LogType.Error, error: error as Error })
    const loginPage = RouterLinkFactory.localizedLink('/auth/login', App.localizationSelector.language)
    redirect(loginPage)
  }
}

export default adminAuth
