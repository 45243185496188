import { IsString, IsNumber, IsBoolean, IsOptional } from 'class-validator';

/**
 * The DTO that validates the wage information
 */
export class VacancyWageDto {
  /**
   * TODO add comment.
   */
  @IsOptional()
  id: number;

  @IsNumber()
  paymentPeriodId: number;

  /**
   * The amount of money to be paid in this salary. If amountMaximum was also provided,
   * this field will act as the minimum amount in a range. (Ex: from 1,200 to 2,000 yen per hour).
   * If amountMaximum was not provided, this value will be considered to as the only
   * possible salary. (Ex 1,200 yen per hour)
   */
  @IsOptional()
  @IsNumber()
  amount: number;

  /**
   * The maximum amount of money to be paid in this salary. If amount was also provided,
   * this field will act as the maximum amount in a range. (Ex: from 1,200 to 2,000 yen per hour).
   * If amount was not provided, this value will be considered as the highest amount that
   * may be paid, within an unspecified minimum value. (Ex: Up to 2,000 yen per hour)
   */
  @IsOptional()
  @IsNumber()
  amountMaximum: number;

  /**
   * Whether this day applies to this wage or not.
   */
  @IsOptional()
  @IsBoolean()
  monday: boolean;

  /**
   * Whether this day applies to this wage or not.
   */
  @IsOptional()
  @IsBoolean()
  tuesday: boolean;

  /**
   * Whether this day applies to this wage or not.
   */
  @IsOptional()
  @IsBoolean()
  wednesday: boolean;

  /**
   * Whether this day applies to this wage or not.
   */
  @IsOptional()
  @IsBoolean()
  thursday: boolean;

  /**
   * Whether this day applies to this wage or not.
   */
  @IsOptional()
  @IsBoolean()
  friday: boolean;

  /**
   * Whether this day applies to this wage or not.
   */
  @IsOptional()
  @IsBoolean()
  saturday: boolean;

  /**
   * Whether this day applies to this wage or not.
   */
  @IsOptional()
  @IsBoolean()
  sunday: boolean;

  /**
   * Time at which the shift starts, in a 24 hour format.
   */
  @IsOptional()
  @IsString()
  shiftFrom: string;

  /**
   * Time at which the shift ends, in a 24 hour format.
   */
  @IsOptional()
  @IsString()
  shiftUntil: string;

  /**
   * Whether this wage might include overtime or not.
   */
  @IsOptional()
  @IsBoolean()
  overtime: boolean;

  /**
   * The amount of hours per month of potential overtime (if any).
   */
  @IsOptional()
  @IsNumber()
  overtimeHoursPerMonth: number;

  /**
   * Day of the month in which the salary is paid.
   */
  @IsOptional()
  @IsNumber()
  salaryPaymentDay?: number;

  /**
   * Day of the month in which the 'working month' closes.
   * @remarks In theory, a salary is paid based on the work performed during the previous month's
   * closing date, and the current one.
   */
  @IsOptional()
  @IsNumber()
  salaryClosingDay?: number;
}
