import { IVisaType } from '../../../models';
import { CompositeSpecification } from '../../base/specification';

/**
 * Whether the provided candidateVisa has a visa subtype that belongs to its visaType property.
 * @remarks The visaType and its children properties must be properly set for this to return the appropriate response.
 * The subtype id can be set in either visaSubTypeId or the visaSubType.id properties.
 */
export class VisaTypeHasChildrenSpecification extends CompositeSpecification<IVisaType> {
  isSatisfiedBy(visaType: IVisaType): boolean {
    return !!(visaType && visaType.children && visaType.children.length > 0)
  }
}
