import { IAttachment } from '../../../../../../../core/src'
import { AttachmentEntity } from '../entities/attachment'

export class AttachmentEntityFactory {
  static defaultProps(props?: Partial<IAttachment>): IAttachment {
    return {
      ...props,
    }
  }

  static new(props?: Partial<IAttachment>): AttachmentEntity {
    return new AttachmentEntity(AttachmentEntityFactory.defaultProps(props))
  }
}
