/**
 * Generate an object with CSS types.
 * @param errors If any errors exist, an error type is returned.
 * @param passed If true, a success type is returned.
 */
export const getTypes = (errors: string[], passed: boolean): Object => {
  return {
    'is-danger': errors ? errors[0] : false,
    'is-success': passed ?? false
  }
}
