import { DateFormatter } from '../../../../../../../core/src/utils/date/date-formatter'
import { GAJobApplyPageViewEvent } from '../constants/custom-tracking-events.enum'
import { ICustomGTagEvent } from '../interfaces/i-custom-gtag-event'
import { GTagEventBaseFactory } from './gtag-event-base.factory'

/**
 * Factory class responsible for creating Google Tag (GTag) events
 * pertaining to different stages of the job application process.
 */
export class JobApplicationGTagEventFactory extends GTagEventBaseFactory {
  /**
   * Creates an event for when a user visits the Name page in the application process.
   *
   * @param currentVisaTypeId - Current visa type ID of the user.
   * @param currentVisaSubtypeId - Current visa subtype ID of the user.
   * @returns An ICustomGTagEvent representing the event.
   */
  static welcomePage(): ICustomGTagEvent {
    return this.createEvent(GAJobApplyPageViewEvent.Welcome, {})
  }
  /**
   * Creates an event for when a user visits the Name page in the application process.
   *
   * @param currentVisaTypeId - Current visa type ID of the user.
   * @param currentVisaSubtypeId - Current visa subtype ID of the user.
   * @returns An ICustomGTagEvent representing the event.
   */
  static namePage(currentVisaTypeId: number | undefined, currentVisaSubtypeId: number | undefined): ICustomGTagEvent {
    return this.createEvent(GAJobApplyPageViewEvent.Name, { currentVisaTypeId, currentVisaSubtypeId })
  }
  /**
   * Creates an event for when a user visits the Visa Type page in the application process.
   *
   * @param currentVisaTypeId - Current visa type ID of the user.
   * @param currentVisaSubtypeId - Current visa subtype ID of the user.
   * @returns An ICustomGTagEvent representing the event.
   */
  static visaTypePage(currentVisaTypeId: number | undefined, currentVisaSubtypeId: number | undefined): ICustomGTagEvent {
    return this.createEvent(GAJobApplyPageViewEvent.VisaType, { currentVisaTypeId, currentVisaSubtypeId })
  }

  /**
   * Creates an event for when a user visits the Contact Information page in the application process.
   *
   * @returns An ICustomGTagEvent representing the event.
   */
  static contactInformationPage(): ICustomGTagEvent {
    return this.createEvent(GAJobApplyPageViewEvent.ContactInformation, {})
  }

  /**
   * Creates an event for when a user visits the Personal Information page in the application process.
   *
   * @returns An ICustomGTagEvent representing the event.
   */
  static personalInformationPage(): ICustomGTagEvent {
    return this.createEvent(GAJobApplyPageViewEvent.PersonalInformation, {})
  }

  /**
   * Creates an event for when a user visits the Japanese Language page in the application process.
   *
   * @param gender - Gender of the user.
   * @param country - Country ID where the user resides.
   * @param grewUpInCountry - Country ID where the user grew up.
   * @param prefecture - Prefecture ID of the user's location.
   * @param city - City ID of the user's location.
   * @param birthDate - Birthdate of the user.
   * @returns An ICustomGTagEvent representing the event with the collected data.
   */
  static japaneseLanguagePage(
    gender: string,
    country: number | undefined,
    grewUpInCountry: number | undefined,
    prefecture: number | undefined,
    city: number | undefined,
    birthDate: Date | undefined
  ): ICustomGTagEvent {
    const dateFormatter = new DateFormatter()
    const yearOfBirth = dateFormatter.getYYYYMMDDString(birthDate)
    return this.createEvent(GAJobApplyPageViewEvent.JapaneseLanguage, {
      gender,
      country,
      grewUpInCountry,
      prefecture,
      city,
      yearOfBirth,
    })
  }

  /**
   * Creates an event for when a user visits the Application Confirmation page and provides Japanese Language Proficiencies.
   *
   * @param jpListeningId - Listening proficiency ID.
   * @param jpReadingId - Reading proficiency ID.
   * @param jpWritingId - Writing proficiency ID.
   * @param jpSpeakingId - Speaking proficiency ID.
   * @returns An ICustomGTagEvent representing the event with the proficiency data.
   */
  static applicationConfirmationPage(jpSpeakingId: number | undefined): ICustomGTagEvent {
    return this.createEvent(GAJobApplyPageViewEvent.ApplicationConfirmation, {
      jpSpeakingId,
    })
  }

  /**
   * Creates an event for when a user visits the Application Success page, concluding the entire process.
   * @returns
   */
  static applicationSuccessPage(): ICustomGTagEvent {
    return this.createEvent(GAJobApplyPageViewEvent.ApplicationSuccess, {})
  }
}
