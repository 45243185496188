import { AttachmentType } from '../../../../../../core/src/models/common/attachment/enum/attachment-type'

/**
 * General use functions for file manipulation.
 */
export class FileHelper {
  /**
   * Checks the file's mime type to determine if the file is an image.
   * @remarks This check IS NOT strict, as the type could be modified by the user. A more precise method
   * involves checking the file byte's, but we don't really need that much precision at the moment.
   * @param file File to check.
   * @link https://stackoverflow.com/questions/18299806/how-to-check-file-mime-type-with-javascript-before-upload
   * For a more detailed check.
   * @returns
   */
  static fileIsImage(file: File): boolean {
    const type = file.type
    return type.toLowerCase().startsWith('image/')
  }

  /**
   * Generates the a Post request FormData object, used for image file uploads.
   * @remarks Usually, these type of FormData objects are not used to not generate an attachment item.
   * It's up to other code to generate the attachment and reference the file that was saved.
   * @param file The file to upload.
   * @returns
   */
  static fileToFormData(file: File, attachmentType: AttachmentType): FormData {
    const form = new FormData()
    form.append('name', file.name)
    form.append('attachmentType', attachmentType.toString())
    form.append('file', file)
    return form
  }

  /**
   * Converts a Blob to a File.
   * @param blob
   * @param fileName
   * @returns
   */
  static blobToFile(blob: Blob, fileName: string): File {
    return new File([blob], fileName, { type: blob.type })
  }

  /**
   * Converts a Blob string to a File.
   * @example blob:http://localhost/38faf6cd-6ed0-4b5c-aa94-51a6feb3a86a -> File
   * @param blobUrl
   * @param fileName
   * Returns null if the conversion failed.
   */
  static async blobStrToFile(blobUrl: string, fileName: string): Promise<File | null> {
    // if the blobUrl is not a blob, return null
    if (!blobUrl.startsWith('blob:')) return null
    const res = await fetch(blobUrl)
    const blob = await res.blob()
    return FileHelper.blobToFile(blob, fileName)
  }
}
