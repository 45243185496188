import { IFilterableDataSetItem } from '../../common/filter/i-filterable-data-set-item';
import { LocalizationPath } from '../../common/localization/constants/localization-path';

export const ContractTypeDictionary: Record<string, IFilterableDataSetItem> = {
  '1': { id: '1', name: 'Part-time job', path: '', nameTranslationPath: LocalizationPath.partTimeEmployee },
  '2': { id: '2', name: 'Regular Employee', path: '', nameTranslationPath: LocalizationPath.regularEmployee },
  '3': { id: '3', name: 'Contract Employee', path: '', nameTranslationPath: LocalizationPath.temporaryEmployee },
  '4': { id: '4', name: 'Dispatched Employee', path: '', nameTranslationPath: LocalizationPath.dispatch },
  '5': { id: '5', name: 'Day labor', path: '', nameTranslationPath: LocalizationPath.dayLabor },
  '6': { id: '6', name: 'Outsourcing', path: '', nameTranslationPath: LocalizationPath.outsourcing },
  '7': { id: '7', name: 'Contracting', path: '', nameTranslationPath: LocalizationPath.contracting },
};
