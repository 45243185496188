import { IVisaType, VisaTypeGetters, ICountry, LanguageLocale } from '../../../../../../../../core/src'
import { IFormInputData } from '../interfaces'

/**
 * Creates form inputs from diverse sources.
 */
export class FormInputFactory {
  /**
   *
   * @param visaTypes
   * @returns
   */
  static visaTypeToFormInputList(visaTypes: IVisaType[]): IFormInputData[] {
    return visaTypes.map((e) => {
      return FormInputFactory.visaTypeToFormInput(e)!
    })
  }

  static visaTypeToFormInput(visaType?: IVisaType): IFormInputData | undefined {
    if (!visaType) return undefined
    return { value: visaType.id, label: VisaTypeGetters.getName(visaType) }
  }

  static countryToFormInputList(countries: ICountry[], locale: LanguageLocale = LanguageLocale.USen): IFormInputData[] {
    return countries.map((e) => FormInputFactory.countryToFormInput(e, locale)!)
  }

  static countryToFormInput(country: ICountry, locale: LanguageLocale = LanguageLocale.USen): IFormInputData | undefined {
    if (!country || !country.i18n || country.i18n.length === 0) return undefined
    let label = country.i18n!.find((e) => e.locale === locale)?.name
    // if not found, use the first one
    if (!label) label = country.i18n![0].name
    return { value: country.id, label: label || '' }
  }
}
