import { IUser, CandidateSex, UserType, LanguageLocale } from '../../../../../../core/src'
import { CandidateEntityFactory } from '../../candidate/factories/candidate.factory'
import { UserEntity } from '../entities/user'

export class UserFactory {
  public candidateFactory = new CandidateEntityFactory()
  defaultProps(props?: Partial<IUser>): IUser {
    if (props) {
      props.gender = props.gender !== CandidateSex.Unknown ? props.gender : ''
    }
    return {
      firstName: '',
      lastName: '',
      nickname: '',
      email: '',
      gender: '',
      userType: UserType.Candidate,
      password: '',
      contactInformation: [],
      contacts: [],
      messengerUserId: '',
      ...props,
      candidate: this.candidateFactory.defaultProps(props?.candidate),
    }
  }

  new(props?: Partial<IUser>): UserEntity {
    return new UserEntity(this.defaultProps(props))
  }

  /**
   * @todo DELETE / Clean up, this is horrible.
   * @returns
   */
  newAnonymousUser(locale: LanguageLocale = LanguageLocale.USen): IUser {
    return this.defaultProps({
      lastName: '',
      firstName: '',
      nickname: '',
      email: '',
      gender: '',
      userType: UserType.Candidate,
      contactInformation: [],
      locale,
      candidate: {
        japaneseConversationId: 0,
        birthDate: undefined,
        countryId: 0,
        grewUpInCountryId: 0,
        firstArrivalDate: undefined,
        residenceCardRequirementAccepted: false,
        country: {
          countryCode2Digits: '',
          countryCode3Digits: '',
          i18n: [],
        },
        grewUpInCountry: {
          countryCode2Digits: '',
          countryCode3Digits: '',
          i18n: [],
        },
        candidateVisa: {
          visaTypeId: 0,
          visaSubTypeId: 0,
          cardFrontId: undefined,
          cardBackId: undefined,
          designationPapersAttachmentId: undefined,
          cardId: '',
          validDate: undefined,
          visaType: {
            parentId: undefined,
            designationPapersRequired: false,
            hasExpirationDate: false,
            hasResidenceCard: false,
            i18n: [],
            children: [],
          },
          visaSubType: {
            parentId: undefined,
            designationPapersRequired: false,
            hasExpirationDate: false,
            hasResidenceCard: false,
            i18n: [],
            children: [],
          },
          cardFront: {},
          cardBack: {},
          designationPapersAttachment: {},
        },
        japaneseConversation: {
          i18n: [],
        },
        hasFinishedOnboarding: false,
        hasPassedOnboardingName: false,
        hasPassedOnboardingWelcome: false,
      },
    })
  }
}
