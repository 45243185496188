import { IsBoolean, IsObject, IsOptional, IsString } from 'class-validator';
import { IContactMethod } from '../../contact/interface/i-contact-method';

/**
 * Contact information set by agent when creating a vacancy
 */
export class VacancyContactDto {
  @IsOptional()
  id: number
  
  @IsString()
  account: string;

  @IsBoolean()
  enabled: boolean;

  @IsObject()
  @IsOptional()
  contactMethod: IContactMethod;
}
