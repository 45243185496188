/**
 * Lists all possible file types that can be uploaded to the API.
 * The selected type will determine various attachment settings, like its saving location, permissions, etc.
 */
export enum AttachmentType {
  /**
   * A candidate's language certificate image, like JLPT, TOEFL, etc.
   */
  CandidateLanguageCertificate = 2,
  /**
   * A candidate's residence card. Includes both the front and the back.
   */
  ResidenceCard = 11,
  /**
   * A candidaate's designation papers. Only applies for some visa types.
   */
  DesignationPapers = 12,
  /**
   * A candidate's front residence card.
   */
  ResidenceCardFront = 13,
  /**
   * A candidate's front residence card.
   */
  ResidenceCardBack = 14,
  /**
   * Any non-image temporary file.
   */
  TemporaryFile = 15,
  /**
   * Any image temporary file. Image files will have a thumbnail autogenerated for them.
   */
  TemporaryImage = 16,
  /**
   * A candidate's resume attachment.
   */
  ResumeAttachment = 17,
  // We don't need this for now. Just added this here for consistency with the API.
  // ApplicationResumeAttachment = 18,
  /**
   * A candidate's visa attachments.
   * Also known as personal id.
   * Only for Japanese Citizens
   */
   CandidateVisaAttachment = 19
}
