import { IApp } from '@/types/nuxt'
import { ICandidateDashboardStats } from '@tokyojob/core'
import { LogType } from '@tokyojob/frontend-core'

/**
 * Get a list of a user's stats.
 */
export class GetCandidateDashboardStatsUsecase implements BaseUsecase {
  app: IApp
  constructor(app: IApp) {
    this.app = app
  }

  async execute(): Promise<ICandidateDashboardStats | null> {
    const { baseGateway, logService } = this.app
    try {
      const res = await baseGateway.candidate.GetCandidateDashboardStatsUsecase()
      return res
    } catch (error) {
      await logService.handle({ type: LogType.Error, error: error as Error })
      return null
    }
  }
}
