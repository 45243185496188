
import { computed, defineComponent } from 'vue'
import { HtmlAttributesFactory } from '@tokyojob/frontend-core'
import authenticated from '~/middleware/authenticated'
import CSidebar from '~/modules/app/components/app/c-sidebar/c-sidebar.component.vue'
import CTopbar from '~/modules/app/components/app/c-topbar/c-topbar.component.vue'
import { getAppStore } from '~/store/app.store'
import CCandidateFooterMini from '../modules/app/components/app/footer/c-candidate-footer-mini.component.vue'

export default defineComponent({
  components: {
    CSidebar,
    CTopbar,
    CCandidateFooterMini
  },
  middleware: [authenticated],
  setup() {
    const appStore = getAppStore()
    const hideFooter = computed(() => {
      return appStore.hideFooter
    })
    return {
      hideFooter
    }
  },
  head() {
    return {
      htmlAttrs: HtmlAttributesFactory.basicAttributes(this.App.localizationSelector.languageISO6391)
    }
  }
})
