import { APIRequest, HTTPMethod, QueryStringEntity } from '@tokyojob/frontend-core'
import { SpaceLocation } from '../schema/namespace'

export class FetchPrefectureCityListRequest
  implements APIRequest<SpaceLocation.FetchPrefectureCityListRequest> {
  response!: SpaceLocation.FetchPrefectureCityListResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(prefectureId: number, entity?: QueryStringEntity) {
    const queries = entity ? entity.queries : ''
    this.path = `/city/prefecture/${prefectureId}` + queries
  }
}
