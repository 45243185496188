import { IUrlBuilder } from './i-url.builder';
import { IUrlParam } from './i-url-param';

const paramsInitializer = '?';
const paramsSeparator = '&';

/**
 * URL strings builder. Can generated encoded and unencoded url strings built from a base URL + a list of parameters.
 */
export class UrlBuilder implements IUrlBuilder {
  /**
   * Creates a new builder for the specific
   * @param baseUrl The basic URL to attach parameters into. Can be any string, as no domain name validation is performed.
   */
  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  /**
   * URL to attach parameters into.
   */
  baseUrl: string;

  /**
   * Set of query string parameters to include in the URL.
   */
  params: IUrlParam[] = [];

  /**
   * Adds a new query parameter to the collection.
   * @param param
   */
  addParam(param: IUrlParam): UrlBuilder {
    this.params.push(param);
    return this;
  }

  /**
   * Generates the URL based on the parameters and base url.
   */
  build(encodeUrl: boolean = true): string {
    let url = this.baseUrl;
    for (let index = 0; index < this.params.length; index++) {
      const param = this.params[index];
      url = this.appendParamToUrl(url, param);
    }
    if (encodeUrl) return encodeURI(url);
    return url;
  }

  /**
   * Attaches a new parameter to the provided URL. If the parameter already exists it will be skipped.
   * @param url
   * @param param
   * @returns
   */
  appendParamToUrl(url: string, param: IUrlParam): string {
    if (this.urlHasParam(url, param)) return url;
    const urlParamsInitialized = url.includes(paramsInitializer);
    if (urlParamsInitialized) {
      url = `${url}${paramsSeparator}`;
    } else {
      url = `${url}${paramsInitializer}`;
    }
    url = `${url}${param.key}=${param.value}`;
    return url;
  }

  /**
   * Determines if the specified URL already has the provided key value pair combination as a query parameter.
   * @param url Url to evaluate.
   * @param param Key Value pair. Will be searched for as a normal key=value pair in a query string.
   * @returns
   */
  urlHasParam(url: string, param: IUrlParam): boolean {
    const paramStr = `${param.key}=${param.value}`;
    return url.includes(paramStr);
  }
}
