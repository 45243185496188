/**
 * Custom vee-validate rule for Mobile OR Email input
 */

import { ValidationRuleResult } from 'vee-validate/dist/types/types'
import { email } from 'vee-validate/dist/rules'
import { JapaneseMobileRegex } from '../../../../../core/src'

export const mobileOrEmailValidator = (
  value: any,
): boolean | string | Promise<boolean | string | ValidationRuleResult> => {
  // Check for either of these to return true
  return email.validate(value) || JapaneseMobileRegex.test(value)
}