import { EnumDictionary } from '../../../../modules/types/enum-dictionary'

/**
 * Identifiers for days of the week, mostly used in lists.
 */
export enum Weekdays {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7
}

/**
 * TODO Need to remove one of these and make it dynamic
 * 
 * Names of the days of the week.
 */
export const WeekdaysNamesJAjp: EnumDictionary<Weekdays, string> = {
  [Weekdays.Monday]: '月曜日',
  [Weekdays.Tuesday]: '火曜日',
  [Weekdays.Wednesday]: '水曜日',
  [Weekdays.Thursday]: '木曜日',
  [Weekdays.Friday]: '金曜日',
  [Weekdays.Saturday]: '土曜日',
  [Weekdays.Sunday]: '日曜日'
}

export const WeekdaysNamesUSen: EnumDictionary<Weekdays, string> = {
  [Weekdays.Monday]: 'Monday',
  [Weekdays.Tuesday]: 'Tuesday',
  [Weekdays.Wednesday]: 'Wednesday',
  [Weekdays.Thursday]: 'Thursday',
  [Weekdays.Friday]: 'Friday',
  [Weekdays.Saturday]: 'Saturday',
  [Weekdays.Sunday]: 'Sunday'
}

export const WeekdaysNamesVIvn: EnumDictionary<Weekdays, string> = {
  [Weekdays.Monday]: 'Thứ hai',
  [Weekdays.Tuesday]: 'Thứ ba',
  [Weekdays.Wednesday]: 'Thứ tư',
  [Weekdays.Thursday]: 'Thứ năm',
  [Weekdays.Friday]: 'Thứ sáu',
  [Weekdays.Saturday]: 'Thứ bảy',
  [Weekdays.Sunday]: 'Chủ nhật'
}