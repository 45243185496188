import JapaneseBusiness from '../network/schema/models/japanese.business'

/**
 * Interface used as a wrapper for a Japanese Business Skill object.
 */
export interface IJapaneseBusinessProps extends JapaneseBusiness {}

/**
 * Entity containing a Japanese Business Skill object.
 * TODO: Implement proper i18n for all translatable props
 */
export default class JapaneseBusinessEntity {
  private _props: IJapaneseBusinessProps

  constructor(props: IJapaneseBusinessProps) {
    this._props = props
  }

  /**
   * Raw properties of the entity. Used in cases when the data provided by the entity's getters and setters are insufficient.
   * For most cases, consider adding another getter / setter instead of interacting directly with the props.
   */
  get props(): IJapaneseBusinessProps {
    return this._props
  }

  /**
   * The id of the business skill.
   */
  get id(): number {
    return this.props.id
  }

  /**
   * The name of the business skill.
   */
  get name(): string {
    return this.props.info[0].name
  }

  /**
   * Explains what this item is.
   */
  get description(): string | null {
    return this.props.info[0].description || null
  }

  // getByLanguage(locale: string): JapaneseBusinessInfo {
  //   const infos = this._props.informations.filter((information) => {
  //     return information.locale === locale
  //   })
  //   return infos[0] || null
  // }

  // getJapanese(): JapaneseBusinessInfo {
  //   return this.getByLanguage('JA-jp')
  // }
}

/**
 * Generates an IJapaneseBusinessProps object. Used to add extra properties during the conversion process.
 * @param props Data to use when creating the object.
 */
export const JapaneseBusinessPropsFactory = (
  props: IJapaneseBusinessProps
): IJapaneseBusinessProps => ({
  ...props
})

/**
 * Generates a JapaneseBusinessEntity object.
 * @param props Data to use when creating the object.
 */
export const JapaneseBusinessEntityFactory = (
  props: IJapaneseBusinessProps
): JapaneseBusinessEntity => {
  return new JapaneseBusinessEntity(JapaneseBusinessPropsFactory(props))
}
