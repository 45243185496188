/**
 * Availabble data types in benefit items.
 */
export enum BenefitDataType {
  /**
   * Can only display an option to be selected.
   */
  Simple = 1,
  /**
   * Displays an option that can be selected AND allows the user to input custom text in it.
   */
  OpenText = 2,
  /**
   * Displays an option that can be selected AND allows the agent to input an amount of money.
   */
  Money = 3,
  /**
   * Displays an option that can be selected AND allows the agent to input an amount of money, and also select a "unit" that measures that amount. For example Hours, Days, etc.
   */
  MoneyPerUnit = 4,
  /**
   * Displays an option that can be selected AND allows the agent to input a percentage, and also select a "unit" that measures that amount, for example Hours, Days, etc.
   */
  MoneyPercentagePerUnit = 5,
}
