import { EnumDictionary } from "../../../../../core/src/utils/types/enum-dictionary"
import { AppEnvironment } from "../app-environment"

/**
 * List of buckets used in our different environments.
 * Each bucket is associated with different roles, policies, and permissions. Because of this,
 * each bucket is only available in specific environments.
 * Production and Test buckets are inaccessible
 * to any process that is not associated with their respective AWS Roles, and as such the are unavailable
 * for the dev environment.
 * Test environments have no access to Production buckets, and Production environments have no access
 * to test buckets.
 * Public buckets files can be viewed from any location, but uploading to a public bucket also
 * requires permissions granted through AWS roles and policies.
 */
export enum S3BucketNameDesignation {
  /**
   * Main bucket to store all production users' data. Files here can only be viewed if permission is granted
   * or if a Signed URL is provided. The Signed URL will expire after a set amount of time.
   * This bucket also encrypts all files and has file versioning enabled. It will also log all access attempts.
   */
  ProductionPrivate = 'tokyojob-private',
  /**
   * Main bucket to store all production public files, like icons, vacancy images, and other files that require
   * no permission to be viewed.
   * This bucket also has file versioning enabled.
   */
  ProductionPublic = 'tokyojob-public',
  /**
   * Main bucket to store all tests users' data. Files here can only be viewed if permission is granted
   * or if a Signed URL is provided. The Signed URL will expire after a set amount of time.
   * This bucket also encrypts all files. It will also log all access attempts.
   */
  TestPrivate = 'tokyojob-private-test',
  /**
   * Main bucket to store all test public files, like icons, vacancy images, and other files that require
   * no permission to be viewed.
   */
  TestPublic = 'tokyojob-public-test',
  /**
   * Main bucket to store all development users' data. Files here can only be viewed if permission is granted
   * or if a Signed URL is provided. The Signed URL will expire after a set amount of time.
   * This bucket also encrypts all files.
   */
  DevelopmentPrivate = 'tokyojob-private-dev',
  /**
   * Main bucket to store all development public files, like icons, vacancy images, and other files that require
   * no permission to be viewed.
   */
  DevelopmentPublic = 'tokyojob-public-dev'
}

/**
 * Determines the circumstances under which a bucket's files are publicly accessible.
 */
export enum BucketVisibility {
  /**
   * File can be accesed if the user has a link to it. No special method required.
   */
  Public = 'Public',
  /**
   * File is only accessible with the appropriate AWS permissions, or if a Signed URL is provided.
   */
  Private = 'Private'
}

/**
 * Representation of each environment and its buckets, allowing access like:
 * S3BucketsEnvironments['production'][Private]
 * S3BucketsEnvironments[environmentVar][Public]
 */
export const S3BucketsEnvironments: EnumDictionary<
  AppEnvironment,
  EnumDictionary<BucketVisibility, S3BucketNameDesignation>
> = {
  [AppEnvironment.Production]: {
    [BucketVisibility.Public]: S3BucketNameDesignation.ProductionPublic,
    [BucketVisibility.Private]: S3BucketNameDesignation.ProductionPrivate
  },
  [AppEnvironment.Test]: {
    [BucketVisibility.Public]: S3BucketNameDesignation.TestPublic,
    [BucketVisibility.Private]: S3BucketNameDesignation.TestPrivate
  },
  [AppEnvironment.Development]: {
    [BucketVisibility.Public]: S3BucketNameDesignation.DevelopmentPublic,
    [BucketVisibility.Private]: S3BucketNameDesignation.DevelopmentPrivate
  }
}

export const getCurrentEnvironmentBucket = (
  visibility: BucketVisibility
): string => {
  const bucket =
    S3BucketsEnvironments[process.env.APP_ENV as AppEnvironment][visibility]
  return bucket
}
