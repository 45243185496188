import { AccountType, IUser, UserType } from '../../../models';
import { StringUtils } from '../../../utils';

export class UserGetters {
  static isAdmin(user: IUser): boolean {
    return user.userType === UserType.Admin;
  }
  static isAgent(user: IUser): boolean {
    return user.userType === UserType.Agent;
  }
  static isCandidate(user: IUser): boolean {
    return user.userType === UserType.Candidate;
  }
  static isSnsAccount(user: IUser): boolean {
    if (user.accountType !== AccountType.social) return false;
    return !!user.snsAccountType;
  }
  static getSnsAccountName(user: IUser): string {
    if (!UserGetters.isSnsAccount(user)) return '';
    const upperCased = StringUtils.Capitalize(user.snsAccountType!)
    return upperCased;
  }
}
