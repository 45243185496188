/**
 * The numerical value of each onboarding step
 */
export enum OnboardingStep {
  Welcome = 1,
  Name = 2,
  Contact = 3,
  PersonalInformation = 4,
  ApplicationConfirmation = 5,
  JobStatus = 10
}
