import { EnumDictionary } from '../../../utils';
import { UserPlatform } from '../../user/constants/user-platform';
import { UserType } from '../../user/constants/user-type.enum';

/**
 * Available user prefixes, dependent on the user and platform being used.
 */
export const AnalyticsUserPrefixMap: EnumDictionary<UserType, EnumDictionary<UserPlatform, string>> = {
  [UserType.Candidate]: {
    [UserPlatform.Messenger]: 'CMSN',
    [UserPlatform.Web]: 'CWEB',
    [UserPlatform.WebAnonymous]: 'CWAN',
    [UserPlatform.Android]: 'CAND',
    [UserPlatform.IOS]: 'CIOS',
  },
  [UserType.Agent]: {
    [UserPlatform.Messenger]: 'AGMSN',
    [UserPlatform.Web]: 'AGWEB',
    [UserPlatform.WebAnonymous]: '', // There are no anoymous agents
    [UserPlatform.Android]: 'AGAND',
    [UserPlatform.IOS]: 'AGIOS',
  },
  [UserType.Admin]: {
    [UserPlatform.Messenger]: 'ADMSN',
    [UserPlatform.Web]: 'ADWEB',
    [UserPlatform.WebAnonymous]: '', // There are no anoymous admins
    [UserPlatform.Android]: 'ADAND',
    [UserPlatform.IOS]: 'ADIOS',
  },
};
