import { ContactMethodTypePhoneSMSId } from '../../contact';
import { IUser } from '../../user/interface/i-user';
import { UserContactsDto } from '../dto/user-contacts.dto';

export class UserContactDtoFactory {
  new(user: IUser): UserContactsDto {
    const dto = new UserContactsDto();
    const contacts = user.contactInformation;
    if (!contacts || contacts.length === 0) throw new Error('Expected the user to have a contact collection of at least 1 item');
    const phone = contacts.find(
      (e) => e.contactMethodId === ContactMethodTypePhoneSMSId || e.contactMethod?.id === ContactMethodTypePhoneSMSId,
    );
    if (!phone) throw new Error('Expected at least 1 phone number contact item');
    dto.contacts = contacts;
    return dto;
  }
}
