import { LanguageURL } from '../constants';
import { LanguageISO6391 } from '../constants/language-iso639-1.enum';

/**
 * Maps the LanguageURL to the LanguageISO6391, mostly used by our localization services.
 */
export const LanguageURLToISO6391: Record<LanguageURL, LanguageISO6391> = {
  [LanguageURL.EN]: LanguageISO6391.EN,
  [LanguageURL.JP]: LanguageISO6391.JA,
  [LanguageURL.VI]: LanguageISO6391.VI,
  [LanguageURL.ZH]: LanguageISO6391.ZH,
};
