import { ICMSResponse, IJoomlaCMSLayoutContent, LanguageLocale } from '@tokyojob/core'
import { GatewayBase } from '~/base/gateways/base'
import { GetJoomlaCMSLayoutContentRequest, GetVacancyGridContentRequest } from '../network/request/cms'
import { JoomlaContentUrlChecker } from '../utils/joomla-content-url-checker'

export default class CMS extends GatewayBase {
  async GetVacancyGridContent(): Promise<ICMSResponse | null> {
    const item = await this.apiClient.request(new GetVacancyGridContentRequest())
    return item
  }

  async GetJoomlaCMSLayoutContent(locale: LanguageLocale): Promise<IJoomlaCMSLayoutContent | null> {
    const content = await this.apiClient.request(new GetJoomlaCMSLayoutContentRequest(locale))
    const checker = new JoomlaContentUrlChecker()
    const correctecContent = checker.addLeadingSlash(content)
    return correctecContent
  }
}
