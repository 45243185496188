/**
 * Hard coded list of contact method IDs. Since these rarely ever change in the DB, this serves as a
 * as an easy shortcut for them.
 * @remarks Ideally, we would put this in a big object, like { email: { id: 1 }, etc }
 * but TS exports / imports remove the values, so it was not working out.
 * The other possible option is to save it as an object returned in a function instead.
 */
export const ContactMethodTypeEmailId = 1;
export const ContactMethodTypeEmailNameEn = 'Email';
export const ContactMethodTypePhoneSMSId = 2;
export const ContactMethodTypePhoneSMSNameEn = 'Phone/SMS';
export const ContactMethodTypeLINEId = 3;
export const ContactMethodTypeLINENameEn = 'LINE';
export const ContactMethodTypeViberId = 4;
export const ContactMethodTypeViberNameEn = 'Viber';
export const ContactMethodTypeMessengerId = 5;
export const ContactMethodTypeMessengerNameEn = 'Messenger';
export const ContactMethodTypeWhatsAppId = 6;
export const ContactMethodTypeWhatsAppNameEn = 'Whats App';
