import { MetaPropertyCharset, MetaPropertyEquiv, MetaPropertyMicrodata, MetaPropertyName, MetaPropertyProperty } from 'vue-meta'
import { LanguageLocale, LocalizationData, LocalizationPath } from '../../../../../../../core/src'
import { LocalizationService } from '../../../language/localization-service'
import { MetaTagFactory } from '../meta-tag.factory'

/**
 * Agent forgot password meta tags.
 */
export class AgentForgotPasswordMetaFactory {
  static metaTags(
    localization: LocalizationData,
    locale: LanguageLocale
  ): (MetaPropertyCharset | MetaPropertyEquiv | MetaPropertyName | MetaPropertyMicrodata | MetaPropertyProperty)[] {
    const pageTitle: string = LocalizationService.t<string>(localization, LocalizationPath.AgentForgotPasswordPageMetaTagTitle)
    const description: string = LocalizationService.t<string>(
      localization,
      LocalizationPath.AgentForgotPasswordPageMetaTagDescription
    )
    const titles = MetaTagFactory.titleTags(pageTitle)
    const siteName = MetaTagFactory.siteName()
    const descriptions = MetaTagFactory.descriptionTags(description)
    const keywords = MetaTagFactory.keywords(localization, [])
    const locales = MetaTagFactory.localeTags(locale)
    const robots = MetaTagFactory.nonindexedRobots()
    return [...titles, siteName, ...descriptions, keywords, ...locales, robots]
  }
}
