
/**
 * Levels of Japanese writing ability.
 * @todo i18n. Might have to delete the names from the codebase and rely on the db data only later
 */
 export enum JapaneseWritingLevel {
  Zero = '書けなくてもいい',
  Hiragana = '平仮名',
  Katakana = '平仮名とカタカナ', 
  N5 = '漢字（小１レベル）',
  N4 = '漢字（小２〜３レベル）',
  N3 = '漢字（小４〜５レベル）',
  N2 = '漢字（小６〜中１レベル）',
  N1 = '漢字（中２〜高１レベル）'
}