import { IApp } from '@/types/nuxt'
import { IApplication } from '@tokyojob/core'
import { LogType } from '@tokyojob/frontend-core'

/**
 * Get a list of all applications (id and vacancy id) for a logged in user
 */
export class GetCandidateApplicationIdAndVacancyIdListUsecase implements BaseUsecase {
  app: IApp
  constructor(app: IApp) {
    this.app = app
  }

  async execute(): Promise<IApplication[] | null> {
    const { baseGateway, logService } = this.app
    try {
      const res = await baseGateway.application.GetCandidateApplicationIdAndVacancyIdList()
      return res
    } catch (error) {
      await logService.handle({ type: LogType.Error, error: error as Error })
      return null
    }
  }
}
