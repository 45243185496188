import { Benefit, BenefitInfo } from '../network/schema/models'
import BenefitUnitEntity, { BenefitUnitEntityFactory } from './benefit.unit'

export interface IBenefitProps extends Benefit {}

export default class BenefitEntity {
  private _props: IBenefitProps

  constructor(props: IBenefitProps) {
    this._props = props
  }

  get props(): IBenefitProps {
    return this._props
  }

  get id(): string {
    return this.props.id
  }

  get isParent() {
    return this.props.isParent
  }

  get order(): string {
    return this.props.order || '0'
  }

  get group(): any {
    return this.props.group || '0'
  }

  get dataType(): number {
    return Number(this.props.dataType) || 0
  }

  get informations(): BenefitInfo[] {
    return this.props.i18n || []
  }

  /**
   * Gets the name of the benefit in the current locale.
   */
  get name(): string {
    return this.informations[0].name
  }

  /**
   * Gets the description of the benefit in the current locale.
   */
  get description(): string {
    return this.informations[0].description
  }

  /**
   * Gets the benefits's children.
   */
  get children(): BenefitEntity[] {
    if (!this.props.children) {
      return []
    }
    return this.props.children.map((e) => BenefitEntityFactory(e))
  }

  /**
   * Gets the benefits's units.
   */
  get benefitUnits(): BenefitUnitEntity[] {
    if (!this.props.benefitUnits) {
      return []
    }
    return this.props.benefitUnits.map((e) => BenefitUnitEntityFactory(e))
  }

  /**
   * Whether the benefit contains any benefit units at all.
   */
  get hasBenefitUnits(): boolean {
    return this.benefitUnits.length > 0
  }
}

export const EmptyBenefitPropsFactory = (
  props?: Partial<IBenefitProps>
): IBenefitProps => ({
  id: '',
  ...props
})

export const BenefitPropsFactory = (
  props?: Partial<IBenefitProps>
): IBenefitProps => ({
  id: '',
  ...props
})

export const EmptyBenefitEntityFactory = (
  props?: Partial<IBenefitProps>
): BenefitEntity => {
  return new BenefitEntity(EmptyBenefitPropsFactory(props))
}

export const BenefitEntityFactory = (
  props?: Partial<IBenefitProps>
): BenefitEntity => {
  return new BenefitEntity(BenefitPropsFactory(props))
}

/**
 * Availabble data types in benefit items.
 */
export enum BenefitDataType {
  /**
   * Can only display an option to be selected.
   */
  Simple = 1,
  /**
   * Displays an option that can be selected AND allows the user to input custom text in it.
   */
  OpenText = 2,
  /**
   * Displays an option that can be selected AND allows the agent to input an amount of money.
   */
  Money = 3,
  /**
   * Displays an option that can be selected AND allows the agent to input an amount of money, and also select a "unit" that measures that amount. For example Hours, Days, etc.
   */
  MoneyPerUnit = 4,
  /**
   * Displays an option that can be selected AND allows the agent to input a percentage, and also select a "unit" that measures that amount, for example Hours, Days, etc.
   */
  MoneyPercentagePerUnit = 5
}
