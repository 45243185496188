import { Middleware } from '@nuxt/types'
import { Context } from '@nuxt/types/app'
import { UserType } from '@tokyojob/core'
import { APIClient, JWTHandler, LogType } from '@tokyojob/frontend-core'
import { RouterLinkFactory } from '../../../../components/src/lib-components/router-links'

const authenticated: Middleware = async ({ App, redirect, route }: Context) => {
  try {
    const apiClient = new APIClient(App.state)
    const jwtHandler = new JWTHandler(apiClient)
    await jwtHandler.evaluateAuth(App.state, redirect, UserType.Candidate, true)
    // if account email validation is missing, redirect to the email validation page
    const isUnverifiedCandidate = jwtHandler.isUnverifiedCandidate(App.state?.token || '')
    if (isUnverifiedCandidate) {
      const unverifiedEmailPage = RouterLinkFactory.localizedLink('/account/unverified-email', App.localizationSelector.language)
      // only direct if we are not already on that page
      if (route.path !== unverifiedEmailPage) {
        redirect(unverifiedEmailPage)
      }
    }
  } catch (error) {
    // remove cookies and redirect to login page
    App.state.token = ''
    App.state.refreshToken = ''
    App.logService.handle({ type: LogType.Error, error: error as Error })
    const loginPage = RouterLinkFactory.localizedLink('/auth/login', App.localizationSelector.language)
    redirect(loginPage)
  }
}

export default authenticated
