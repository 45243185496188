import { EnumDictionary } from '../../../utils';
import { LanguageFullJP } from './language-fullJP.enum';
import { LanguageLocale } from './language-locale.enum';
import { LanguageOriginal } from './language-original.enum';
import { LanguageURL } from './language-url.enum';

/**
 * Converters to LanguageURL
 */
export const LanguageOriginalToURLMapper: EnumDictionary<LanguageOriginal, LanguageURL> = {
  [LanguageOriginal.Japanese]: LanguageURL.JP,
  [LanguageOriginal.English]: LanguageURL.EN,
  [LanguageOriginal.Vietnamese]: LanguageURL.VI,
  [LanguageOriginal.Chinese]: LanguageURL.ZH,
};

export const LanguageFullJPToURLMapper: EnumDictionary<LanguageFullJP, LanguageURL> = {
  [LanguageFullJP.Japanese]: LanguageURL.JP,
  [LanguageFullJP.English]: LanguageURL.EN,
  [LanguageFullJP.Vietnamese]: LanguageURL.VI,
  [LanguageFullJP.Chinese]: LanguageURL.ZH,
};

export const LanguageLocaleToURLMapper: EnumDictionary<LanguageLocale, LanguageURL> = {
  [LanguageLocale.JAjp]: LanguageURL.JP,
  [LanguageLocale.USen]: LanguageURL.EN,
  [LanguageLocale.VIvn]: LanguageURL.VI,
  [LanguageLocale.ZHcn]: LanguageURL.ZH,
}

/**
 * Converters to LanguageOriginal
 */
export const LanguageURLToOriginalMapper: EnumDictionary<LanguageURL, LanguageOriginal> = {
  [LanguageURL.JP]: LanguageOriginal.Japanese,
  [LanguageURL.EN]: LanguageOriginal.English,
  [LanguageURL.VI]: LanguageOriginal.Vietnamese,
  [LanguageURL.ZH]: LanguageOriginal.Chinese,
};

/**
 * Converters to LanguageLocale
 */
export const LanguageURLToLocaleMapper: EnumDictionary<LanguageURL, LanguageLocale> = {
  [LanguageURL.JP]: LanguageLocale.JAjp,
  [LanguageURL.EN]: LanguageLocale.USen,
  [LanguageURL.VI]: LanguageLocale.VIvn,
  [LanguageURL.ZH]: LanguageLocale.ZHcn,
};

export const LanguageLocaleToOriginalMapper: EnumDictionary<LanguageLocale, LanguageOriginal> = {
  [LanguageLocale.JAjp]: LanguageOriginal.Japanese,
  [LanguageLocale.USen]: LanguageOriginal.English,
  [LanguageLocale.VIvn]: LanguageOriginal.Vietnamese,
  [LanguageLocale.ZHcn]: LanguageOriginal.Chinese,
};

/**
 * Converters to LanguageFullJP
 */
export const LanguageLocaleToFullJPMapper: EnumDictionary<LanguageLocale, LanguageFullJP> = {
  [LanguageLocale.JAjp]: LanguageFullJP.Japanese,
  [LanguageLocale.USen]: LanguageFullJP.English,
  [LanguageLocale.VIvn]: LanguageFullJP.Vietnamese,
  [LanguageLocale.ZHcn]: LanguageFullJP.Chinese,
};
