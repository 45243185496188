
import { defineComponent, PropType, computed } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Icon } from './class/icon'
import HiraganaIcon from '../../../../../core/src/icons/hiragana.icon.vue';
import ZaloIcon from '../../../../../core/src/icons/zalo.icon.vue';
import KakaoIcon from '../../../../../core/src/icons/kakao.icon.vue';

export default defineComponent({
  name: 'c-icon',
  components: {
    FontAwesomeIcon
  },
  props: {
    /**
     * Object containing all item properties.
     */
    icon: {
      type: Object as PropType<Icon>,
      required: true
    }
  },
  setup(props) {
    const iconMapping: { [key: string]: any } = {
      hiragana: HiraganaIcon,
      zalo: ZaloIcon,
      kakao: KakaoIcon
    }
    const iconComponent = computed(() => {
      return props.icon.svg ? iconMapping[props.icon.svg] : null
    })
    const currentIcon = computed((): string | undefined => {
      return props.icon.icon
    })
    const currentClasses = computed((): string => {
      return props.icon.classes!
    })
    const currentSvgClasses = computed((): string => {
      return props.icon.svgClasses!
    })
    return {
      currentIcon,
      currentClasses,
      currentSvgClasses,
      iconComponent
    }
  }
})
