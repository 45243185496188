import { IsArray } from "class-validator";

/**
 * The DTO that validates the visa types section
 */
export class VacancySaveVisaTypesDto {
  /**
   * The ids of each visa types that can apply to the vacancy
   */
  @IsArray()
  visaTypeIds: number[];
}