import { IApp } from '@/types/nuxt'
import { IResume, UserType } from '@tokyojob/core'
import { BaseUsecaseImpl, LogType } from '@tokyojob/frontend-core'

/**
 * Usecase to create a candidate resumes.
 */
export default class CreateUsecase extends BaseUsecaseImpl {
  /**
   * @param app IApp instance used to access the Gateway and Log services.
   */
    constructor(private readonly app: IApp, userType: UserType) {
    super(app.state, userType)
  }

  /**
   * Gets the requested resume.
   * @param id Id of the resume to fetch.
   */
  async execute(entity: IResume): Promise<IResume> {
    const { baseGateway, logService } = this.app
    try {
      await this.evaluateAuth()
      return baseGateway.resume.CreateResume(entity)
    } catch (error) {
      await logService.handle({ type: LogType.Error, error: error as Error })
      throw error
    }
  }
}
