import { UploaderFileType } from '../../../base/constants/uploader.file.type'

/**
 * Utility to handle file types and extensions to filter the files that a file uploader control
 * can use.
 * @link https://www.w3schools.com/tags/att_input_accept.asp
 */
export class UploaderFileTypesTransformer {
  /**
   * The character used to separate each file type / extension.
   */
  static TypeSeparator = ','

  /**
   * From the provided colleciton, generates a string that can be used for the accept parameter.
   * @param types
   * @example ['.xls', '.doc'] => ".xls,.doc"
   */
  static fileTypesToString(types: UploaderFileType[]): string {
    return types.join(UploaderFileTypesTransformer.TypeSeparator)
  }
}
