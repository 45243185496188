import { IVisaType, IVisaTypeInfo, JapaneseCitizenshipId, LanguageLocale } from '../../../models';

export class VisaTypeGetters {
  static getName(item?: IVisaType, locale?: LanguageLocale): string {
    if (!VisaTypeGetters.hasi18n(item)) return '';
    // TODO: Remove informations, this is used for backwards compatibility with old endpoints
    const i18n: IVisaTypeInfo[] = item?.i18n || (item as any)?.informations;
    if (locale) {
      const i18nLocale = i18n.find((i) => i.locale === locale);
      return i18nLocale ? i18nLocale.name! : i18n[0].name!;
    }
    return i18n[0].name!;
  }

  static hasi18n(item?: IVisaType): boolean {
    if (!item) return false;
    // TODO: Remove informations, this is used for backwards compatibility with old endpoints
    const i18n: IVisaTypeInfo[] = item.i18n || (item as any).informations;
    return !!(i18n && i18n.length > 0);
  }

  static isJapaneseCitizen(item: IVisaType | undefined): boolean {
    if (!item) return false;
    return item.id === JapaneseCitizenshipId;
  }

  static hasChildren(item: IVisaType | undefined): boolean {
    if (!item) return false;
    return !!item.children && item.children.length > 0;
  }
}
