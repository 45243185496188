import { GatewayBase } from '@/base/gateways/base'
import { toEmailSeed } from '@/modules/user/gateways/translator/user'
import {
  EmailChangeRequestDto,
  IAgentSignup,
  ICandidateSignup,
  IGuestCandidateApplicationAuth,
  IJWTRefresh,
  IOTPValidationResult,
  IPasswordRecoveryResponse,
  ISignin,
  IUserVerify,
  IUserVerifyCheck,
  JWTBroadcastRequestDto,
  JWTRefreshRequest,
  OTPVerifyDto
} from '@tokyojob/core'
import { NewJWTBroadcastRequest, RefreshTokenPairRequest } from '@tokyojob/frontend-core'
import UserEntity from '~/modules/user/entities/user'
import {
  AccountRegistrationConfirmationRequest,
  EmailChangeRequest,
  EmailReconfirmRequest,
  EmailUpdateVerifyRequest,
  PasswordRecoveryRequest,
  PasswordResetRequest,
  PasswordResetTokenVerifyRequest,
  PhoneNumberAuthRequest,
  RefreshRequest,
  SigninRequest,
  CandidateSignupRequest,
  AgentSignupRequest,
  OtpValidateRequest,
  OtpValidateFetchRequest,
  OtpRefreshRequest,
  ValidateUnconfirmedAccountRequest,
  EmailChangeRequestOTP,
  EmailChangeVerifyOTP
} from '../network/requests/auth'
import { EmailConfirm, PasswordResetSeed } from '../network/schema/models'

export default class Auth extends GatewayBase {
  async signin(props: ISignin) {
    return await this.apiClient.request(new SigninRequest(props))
  }

  async otpValidate(dto: OTPVerifyDto) {
    return this.apiClient.request(new OtpValidateRequest(dto))
  }

  async otpValidateFetch(): Promise<IUserVerifyCheck> {
    return this.apiClient.request(new OtpValidateFetchRequest())
  }

  async otpRefresh(): Promise<IUserVerify> {
    return this.apiClient.request(new OtpRefreshRequest())
  }

  /**
   * Only applies for email accounts that try to create a duplicate account when their account is still unconfirmed.
   * @param uuid
   * @returns
   */
  async validateUnconfirmedAccount(uuid: string): Promise<void> {
    this.apiClient.request(new ValidateUnconfirmedAccountRequest(uuid))
  }

  async candidateSignup(props: ICandidateSignup) {
    return await this.apiClient.request(new CandidateSignupRequest(props))
  }

  async agentSignup(props: IAgentSignup) {
    return await this.apiClient.request(new AgentSignupRequest(props))
  }

  /**
   * Take a shortId and phone number and attempt to authenticate the user.
   * @returns
   */
  async phoneNumberAuth(props: IGuestCandidateApplicationAuth) {
    return await this.apiClient.request(new PhoneNumberAuthRequest(props))
  }

  /**
   * @todo fully replace with the new refresh
   * @returns
   */
  async Refresh(): Promise<string> {
    const auth = await this.apiClient.request(new RefreshRequest())
    return auth ? auth.token : ''
  }

  /**
   * Attempts to get a new pair of JWTs.
   * @param token
   * @returns
   */
  async refreshPair(tokenRequest: JWTRefreshRequest): Promise<IJWTRefresh | undefined> {
    const refreshedTokens = await this.apiClient.request(new RefreshTokenPairRequest(tokenRequest))
    return refreshedTokens || undefined
  }

  /**
   * Attempts to trigger a broadcast to give a messenger user a new JWT.
   * @remarks The user that will get the message is included in the provided Token. If the token is not valid or has been tampered with
   * no brodcast will triggered. The token CAN be expired.
   * @param token
   * @returns
   */
  async broadcastNewJWT(broadcastRequest: JWTBroadcastRequestDto): Promise<boolean> {
    const success = await this.apiClient.request(new NewJWTBroadcastRequest(broadcastRequest))
    return success
  }

  /**
   * Attempts to verify the email address update that matches the provided token. It's NOT used to verify an account for the first time.
   * @param token The token to use in the search. If the token does not exist or is already expired, the email won't be updated.
   */
  async emailUpdateVerify(token: string): Promise<boolean> {
    const verified = await this.apiClient.request(new EmailUpdateVerifyRequest(token))
    return verified
  }

  /**
   * Requests an email change OTP to be sent to the provided email address.
   * @param email
   * @returns
   */
  async emailChangeRequestOtp(email: string): Promise<IUserVerify> {
    const dto = new EmailChangeRequestDto()
    dto.email = email
    const otp = await this.apiClient.request(new EmailChangeRequestOTP(dto))
    return otp
  }

  /**
   * Attempts to verify the email address change that matches the provided OTP.
   * @remarks Only used for email address changes.
   * @param otp
   * @returns
   */
  async emailChangeVerifyOtp(otp: string): Promise<IOTPValidationResult> {
    const dto = new OTPVerifyDto()
    dto.otp = otp
    const verified = await this.apiClient.request(new EmailChangeVerifyOTP(dto))
    return verified
  }

  /**
   * Attempts to verify the signed up account matches the provided token, used when a user signs up for the first t ime.
   * @param token The token to use in the search. If the token does not exist or is already expired, the account won't be activated.
   * @todo Allow the user to send another activation email if the first one expires.
   */
  async accountRegistrationConfirmation(token: string): Promise<boolean> {
    const verified = await this.apiClient.request(new AccountRegistrationConfirmationRequest(token))
    return verified
  }

  /**
   * API call to update a user's email.
   * @param entity The user whose email will be updated. The password and new email parameters must be provided.
   */
  async EmailChange(entity: UserEntity) {
    return await this.apiClient.request(new EmailChangeRequest(toEmailSeed(entity)))
  }

  /**
   * API call to request for a resend of confirmation email
   */
  async emailReconfirm(email: EmailConfirm): Promise<boolean> {
    return await this.apiClient.request(new EmailReconfirmRequest(email))
  }

  /**
   * API call to request for a password reset email
   */
  async passwordRecovery(email: EmailConfirm): Promise<IPasswordRecoveryResponse> {
    return this.apiClient.request(new PasswordRecoveryRequest(email))
  }

  /**
   * API call to verify the token for password reset
   */
  async passwordResetTokenVerify(token: string): Promise<boolean> {
    return await this.apiClient.request(new PasswordResetTokenVerifyRequest(token))
  }

  /**
   * API call to reset the password
   */
  async passwordReset(props: PasswordResetSeed): Promise<boolean> {
    return await this.apiClient.request(new PasswordResetRequest(props))
  }
}
