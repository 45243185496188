import { ICandidateVisa, IVisaType } from '../../../models';

/**
 * This class is responsible in constructing and returning candidate visa types.
 */
export class CandidateVisaGetters {
  /**
   * This returns a formatted visa type name data of a candidate.
   * @param candidate
   * @returns
   */
  static getFormattedVisaName(cVisa: ICandidateVisa): string {
    const hasValidVisaData = !!cVisa;
    if (!hasValidVisaData) return '';
    const { visaType, visaSubType } = cVisa;
    const hasValidVisaTypeData = !!visaType && !!visaType.i18n && visaType.i18n.length > 0;
    if (!hasValidVisaTypeData) return '';
    let str = `${visaType!.i18n![0].name}`;
    const hasValidVisaSubtype = !!visaSubType && !!visaSubType.i18n && visaSubType.i18n.length > 0;
    if (hasValidVisaSubtype) str = `${str} ${visaSubType!.i18n![0].name}`;
    return str;
  }

  static getLowermostSelectedVisaType(cVisa: ICandidateVisa): IVisaType[] {
    if (!cVisa) return [];
    const { visaSubType, visaType } = cVisa;
    let selectedVisaTypes: IVisaType[] = [];
    if (visaSubType && visaSubType.id && visaSubType.id > 0) {
      selectedVisaTypes.push(visaSubType);
    } else if (visaType && visaType.id && visaType.id > 0) {
      selectedVisaTypes.push(visaType);
    }
    return selectedVisaTypes;
  }

  /**
   * Returns the Visa Type ID from a given Candidate Visa.
   *
   * @param {ICandidateVisa} cVisa - The candidate's visa information.
   * @returns {number | undefined} - The ID of the visa type or undefined if not found.
   */
  static getVisaTypeId(cVisa: ICandidateVisa): number | undefined {
    if (!cVisa) return undefined;
    const { visaType } = cVisa;
    if (visaType && visaType.id && visaType.id > 0) return visaType.id;
    return undefined;
  }

  /**
   * Returns the Visa SubType ID from a given Candidate Visa.
   *
   * @param {ICandidateVisa} cVisa - The candidate's visa information.
   * @returns {number | undefined} - The ID of the visa subtype or undefined if not found.
   */
  static getVisaSubTypeId(cVisa: ICandidateVisa): number | undefined {
    if (!cVisa) return undefined;
    const { visaSubType } = cVisa;
    if (visaSubType && visaSubType.id && visaSubType.id > 0) return visaSubType.id;
    return undefined;
  }
}
