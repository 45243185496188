import { IVisaType } from '../../../models';
import { CompositeSpecification } from '../../base/specification';

/**
 * Whether the provided visa type or any of its children (if provided) requires a residence card.
 */
export class VisaTypeRequiresResidenceCardSpecification extends CompositeSpecification<IVisaType> {
  isSatisfiedBy(visaType: IVisaType): boolean {
    if (visaType.hasResidenceCard) return true;
    if (visaType.children && visaType.children.length > 0) {
      for (let index = 0; index < visaType.children.length; index++) {
        const element = visaType.children[index];
        if (element.hasResidenceCard) return true;
      }
    }
    return false;
  }
}
