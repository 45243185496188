const middleware = {}

middleware['admin-auth'] = require('../app/middleware/admin-auth.ts')
middleware['admin-auth'] = middleware['admin-auth'].default || middleware['admin-auth']

middleware['authenticated-optional'] = require('../app/middleware/authenticated-optional.ts')
middleware['authenticated-optional'] = middleware['authenticated-optional'].default || middleware['authenticated-optional']

middleware['authenticated'] = require('../app/middleware/authenticated.ts')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['job-accountless-application'] = require('../app/middleware/job-accountless-application.ts')
middleware['job-accountless-application'] = middleware['job-accountless-application'].default || middleware['job-accountless-application']

middleware['job-signedin-quick-apply'] = require('../app/middleware/job-signedin-quick-apply.ts')
middleware['job-signedin-quick-apply'] = middleware['job-signedin-quick-apply'].default || middleware['job-signedin-quick-apply']

middleware['server-rendering-restrictor'] = require('../app/middleware/server-rendering-restrictor.ts')
middleware['server-rendering-restrictor'] = middleware['server-rendering-restrictor'].default || middleware['server-rendering-restrictor']

export default middleware
