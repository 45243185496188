import { IPicture } from '../../../models';
import { AttachmentGetters } from '../../attachment';

/**
 * Gets a picture's different urls.
 */
export class PictureGetters {
  /**
   * Gets the URL of the picture's original image. We do not normally need to do this when displaying
   * these to candidates.
   * @param picture
   * @returns
   */
  static originalUrl(picture: IPicture): string | undefined {
    if (!picture || !picture.attachment) return undefined;
    const url = AttachmentGetters.getUrl(picture.attachment);
    return url;
  }

  /**
   * Gets the URL of the cropped picture. When displaying the pictures to users, you ideally want to
   * use the cropped picture unless you have a very specific reason not to do so.
   * @param picture
   * @returns
   */
  static croppedUrl(picture: IPicture): string | undefined {
    if (!picture || !picture.edited) return undefined;
    const url = AttachmentGetters.getUrl(picture.edited);
    return url;
  }

  /**
   * Picture thumbnail
   * @param picture
   * @returns
   */
  static thumbnailUrl(picture: IPicture): string | undefined {
    if (!picture || !picture.thumbnail) return undefined;
    const url = AttachmentGetters.getUrl(picture.thumbnail);
    return url;
  }
}
