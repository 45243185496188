import { IApp } from '@/types/nuxt'
import { IUser, LanguageLocale } from '@tokyojob/core'
import { LogType } from '@tokyojob/frontend-core'

/**
 * Retrieves the currently logged in candidate's full user data.
 */
export class CandidateFullProfileUsecase implements BaseUsecase {
  constructor(private app: IApp) {
    this.app = app
  }

  /**
   * Retrieves the currently logged in candidate's visa type.
   * If the user's token is expired, null is returned.
   * @returns
   */
  async execute(locale: LanguageLocale = LanguageLocale.USen): Promise<IUser | null> {
    const { baseGateway, logService } = this.app
    try {
      return baseGateway.candidate.getFullUserProfile(locale)
    } catch (error) {
      await logService.handle({ type: LogType.Error, error: error as Error })
      return null
    }
  }
}
