import { ICandidate } from '../../../../../../core/src/models/candidate/interface/i-candidate'
import { DateFormatter } from '../../../../../../core/src/utils/date/date-formatter'
import { CandidateEntity } from '../entities/candidate'

/**
 * Builds candidate entities.
 */
export class CandidateEntityFactory {
  public dateFormatter = new DateFormatter()

  defaultProps(props?: Partial<ICandidate>): ICandidate {
    let birthDate = props?.birthDate
    let firstArrivalDate = props?.firstArrivalDate
    if (birthDate) {
      birthDate = this.dateFormatter.getDate(birthDate)
    }
    if (firstArrivalDate) {
      firstArrivalDate = this.dateFormatter.getDate(firstArrivalDate)
    }
    return {
      ...props,
      birthDate,
      firstArrivalDate,
      candidateVisa: props?.candidateVisa
    }
  }

  new(props?: Partial<ICandidate>): CandidateEntity {
    return new CandidateEntity(this.defaultProps(props))
  }
}
