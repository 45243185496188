import { ICoordinates } from "../../../models";

/**
 * This class is responsible any logic related to ICoordinates.
 */
export class CoordinatesGetters {
  /**
   * This returns a string of lat lng.
   * @param candidate
   * @returns
   */
  static getCoordinatesString(coordinates: ICoordinates): string | undefined {
    if (!coordinates.lat && !coordinates.lng) return undefined;
    return `${coordinates.lat}, ${coordinates.lng}`;
  }
}
