import { Middleware } from '@nuxt/types'
import { Context } from '@nuxt/types/app'
import { RouterLinkFactory } from '@tokyojob/frontend-components'
import { CandidateProfileCompletion } from '~/modules/user/candidate-profile-completion'
import { CandidateProfileValidationStep } from '~/modules/user/constants/candidate-profile-validation.enum'
import { getAppStore } from '~/store/app.store'

const jobSignedinQuickApply: Middleware = async ({ App, redirect, params }: Context) => {
  try {
    const link = RouterLinkFactory.localizedLink(`/job/${params.id}`, App.localizationSelector.language)
    const appStore = getAppStore()
    const user = appStore.profile
    const { complete, initialMissingStep } = CandidateProfileCompletion.isComplete(user)
    const japaneseIsMissing = initialMissingStep === CandidateProfileValidationStep.JapaneseLevel
    const visaIsMissing = initialMissingStep === CandidateProfileValidationStep.Visa
    if (!complete && (japaneseIsMissing || visaIsMissing)) {
      return redirect(link)
    }
  } catch (error) {
    const link = RouterLinkFactory.localizedLink(`/job/${params.id}`, App.localizationSelector.language)
    redirect(link)
  }
}

export default jobSignedinQuickApply
