import { LanguageLocale } from '@tokyojob/core'
import { APIRequest, HTTPMethod } from '@tokyojob/frontend-core'
import { SpaceJobCategoryHierarchy } from '../schema/namespace'

export class FetchJobCategoryHierarchyRequest
  implements APIRequest<SpaceJobCategoryHierarchy.JobCategoryHierarchyFetchResponse> {
  response!: SpaceJobCategoryHierarchy.JobCategoryHierarchyFetchResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(locale: LanguageLocale) {
    this.path = `/industry/hierarchy?locale=${locale}`
  }
}
