import { LanguageURL } from '../../../../../core/src/models/language/constants/language-url.enum'
import { IRouterLink } from './interface/i-router-link'
import { RouterLink } from './router-link'
/**
 * Generate a RouterLink object.
 */
export class RouterLinkFactory {
  static link(props: IRouterLink): IRouterLink {
    return new RouterLink({
      ...props
    })
  }

  /**
   * Prepends the locale to the link for any Locale that is not english.
   * @param link 
   * @param locale 
   * @returns 
   */
  static localizedLink(link: string, locale: LanguageURL): string {
    // For english we do not prepend anything
    if (locale === LanguageURL.EN) return link
    return `/${locale}${link}`
  }
}
