import { Route, RouteMeta } from 'vue-router'
import { LocalizationData } from '../../../../../core/src/models/common/localization/localization-data'
import { LanguageURL } from '../../../../../core/src/models/language/constants/language-url.enum'
import { StringFormatter } from '../../../../../core/src/utils/string/formatter/string-formatter'
import { LogService, LogType } from '../../base/services/log.service'

interface Logger {
  captureException(params?: Error | string): void
}

interface Logger {
  captureException(params?: Error | string): void
}

interface Logger {
  captureException(params?: Error | string): void
}

/**
 * Manages the localization of the application
 * @remarks It is a singleton. Please be very careful with this and DO NOT alter its state.
 */
export class LocalizationService {
  public static logService: LogService

  /**
   * Evaluates the current path URL if we need to redirect the user to the correct localization or not.
   * @param route
   * @param redirect
   * @returns
   */
  public static initPath(route: Route, redirect: (path: string, query?: Route['query']) => void, langauge: LanguageURL): boolean {
    const expectedPath = LocalizationService.getExpectedPath(route, langauge)

    // let's remove the / at the end, if there's any
    const hasForwardSlashAtEnd = route.path.charAt(route.path.length - 1) === '/'
    const routePath = hasForwardSlashAtEnd ? route.path.substring(0, route.path.length - 1) : route.path
    if (expectedPath !== routePath) {
      redirect(expectedPath)
      return true
    }

    return false
  }

  /**
   * Returns target localized text
   * @param path
   * @param replacements
   * @returns
   */
  public static t<T>(localization: LocalizationData, path: string[], replacements: string[] = []): T {
    try {
      if (!localization) {
        if (LocalizationService.logService) {
          LocalizationService.logService.handle({
            type: LogType.Error,
            error: new Error(`localization is empty!, path is ${path} and replacements are ${replacements}`),
          })
        }
        return '' as unknown as T
      }
      const translation = LocalizationService.extractTranslation(localization, path)
      const stringFormatter = new StringFormatter()
      return stringFormatter.format(translation, replacements) as unknown as T
    } catch (e) {
      if (LocalizationService.logService) {
        LocalizationService.logService.handle({
          type: LogType.Error,
          error: new Error(`something went wrong! error: ${e}`),
        })
      }
      return '' as unknown as T
    }
  }

  /**
   * Returns the data in a specified path
   * @param path
   * @param localizationLanguage
   * @returns
   */
  private static extractTranslation(
    localization: LocalizationData,
    path: string[]
  ): string | string[] | LocalizationData | LocalizationData[] {
    if (path.length === 0) return ''
    let localizationData: string | string[] | LocalizationData | LocalizationData[] = localization[path[0]]
    for (let i = 1; i < path.length; i++) {
      if (typeof localizationData === 'string' || Array.isArray(localizationData)) return ''
      const key: string = path[i]
      localizationData = localizationData[key]
    }
    return localizationData
  }

  /**
   * Kind of weird because if the route came from the Middleware the meta is in array
   * and if the route came from a component or page the meta is an object
   * @param route
   * @returns
   */
  public static getRouteMeta(route: Route): RouteMeta {
    if (!route.meta) {
      LocalizationService.logService.handle({
        type: LogType.Error,
        error: new Error(`route meta is undefined: ${JSON.stringify(route)}`),
      })
      return {}
    }
    if (Array.isArray(route.meta)) {
      if (route.meta.length === 0) {
        LocalizationService.logService.handle({
          type: LogType.Error,
          error: new Error(`route meta is an array but empty: ${JSON.stringify(route)}`),
        })
        return {}
      }
      return route.meta[0]
    }
    return route.meta
  }

  /**
   * Returns the expected path of the URL
   * @param route
   * @returns
   */
  public static getExpectedPath(route: Route, language?: LanguageURL): string {
    const originalPath = LocalizationService.getOriginalPath(route)

    let expectedPath: string = ''
    if (language === LanguageURL.EN) expectedPath = originalPath
    else expectedPath = `/${language}${originalPath}`

    const params = route.params
    if (params) {
      const keys = Object.keys(params)
      keys.map((e) => {
        const value = params[e]
        /**
         * @todo Temporary fix to remove "?" from "_id" folder path in Nuxt 2.
         * Should be able to change to ":id" folder name in Nuxt 3.
         */
        expectedPath = expectedPath.replace(`:${e}?`, value)
        expectedPath = expectedPath.replace(`:${e}`, value)
      })
    }

    // let's remove the / at the end, if there's any
    const hasForwardSlashAtEnd = expectedPath.charAt(expectedPath.length - 1) === '/'
    if (hasForwardSlashAtEnd) return expectedPath.substring(0, expectedPath.length - 1)
    return expectedPath
  }

  /**
   * Returns the language from the route's meta
   * @param route
   * @param logService
   * @returns
   */
  public static getLanguage(route: Route): LanguageURL {
    const routeMeta = LocalizationService.getRouteMeta(route)
    if (!routeMeta || !routeMeta.language) {
      LocalizationService.logService.handle({
        type: LogType.Error,
        error: new Error(`language in route meta is empty: ${route}`),
      })
      return LanguageURL.EN
    }
    return routeMeta.language
  }

  /**
   * Returns the originalPath from the route's meta
   * @param route
   * @returns
   */
  public static getOriginalPath(route: Route): string {
    const routeMeta = LocalizationService.getRouteMeta(route)
    if (!routeMeta || routeMeta.originalPath === undefined) {
      LocalizationService.logService.handle({
        type: LogType.Error,
        error: new Error(`originalPath in route meta is empty: ${route}`),
      })
      return '/'
    }
    return routeMeta.originalPath
  }

  /**
   * Returns the prefix from the route's meta
   * @param route
   * @returns
   */
  public static getURLPrefix(route: Route): string {
    const routeMeta = LocalizationService.getRouteMeta(route)
    if (!routeMeta || routeMeta.prefix === undefined) {
      LocalizationService.logService.handle({
        type: LogType.Error,
        error: new Error(`prefix in route meta is empty: ${route}`),
      })
      console.log('error')
      return ''
    }
    return routeMeta.prefix
  }

  /**
   * Returns a path where the language prefix was added correctly
   * @param route 
   * @param path 
   * @returns 
   */
  public static getResolvedPath(route: Route, path: string): string {
    // If the passed path starts with '/', we need to remove that because that is going to be added to the returned value
    const pathToBeProcessed = path.charAt(0) === '/' ? path.substring(1) : path.substring(0)
    const prefix = this.getURLPrefix(route)
    if (prefix === '') return `/${pathToBeProcessed}`
    return `/${prefix}/${pathToBeProcessed}`
  }
}
