/**
 * Levels of Japanese literacy (reading and writing).
 */
 export enum JapaneseReadingSkillId {
  Zero = 8, // TODO: FIX. This does not actually exist in our DB yet
  Hiragana = 1,
  Katakana = 2,
  N5 = 3,
  N4 = 4,
  N3 = 5,
  N2 = 6,
  N1 = 7
}
