import { GatewayBase } from '@/base/gateways/base'
import { ICountry, LanguageLocale } from '@tokyojob/core'
import { FetchCountriesRequest } from '../network/requests/country'

/**
 * Handles all api calls related to geographical locations like prefectures, countries, train stations.
 */
export default class Geolocation extends GatewayBase {
  /**
   * Gets all available Countrys.
   */
   async FetchCountryListV3(locale: LanguageLocale): Promise<ICountry[]> {
    const items = await this.apiClient.request(new FetchCountriesRequest(locale))
    return items
  }
}
