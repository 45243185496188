import { Gender, IUser, JapaneseCitizenshipId, JapanId } from '@tokyojob/core'
import { CandidateProfileValidationStep } from './constants/candidate-profile-validation.enum'

export class CandidateProfileCompletion {
  static isComplete(user: IUser | undefined): { complete: boolean; initialMissingStep: CandidateProfileValidationStep | null } {
    if (!user) {
      return { complete: false, initialMissingStep: CandidateProfileValidationStep.Name }
    }
    const validationSteps = [
      { step: CandidateProfileValidationStep.Name, validator: () => this.validateName(user) },
      { step: CandidateProfileValidationStep.Visa, validator: () => this.validateVisa(user) },
      { step: CandidateProfileValidationStep.Contact, validator: () => this.validateContact(user) },
      { step: CandidateProfileValidationStep.PersonalInfo, validator: () => this.validatePersonalInfo(user) },
      { step: CandidateProfileValidationStep.JapaneseLevel, validator: () => this.validateJapaneseLevel(user) }
    ]

    for (const { step, validator } of validationSteps) {
      if (!validator()) {
        return { complete: false, initialMissingStep: step }
      }
    }

    return { complete: true, initialMissingStep: null }
  }

  static validateName(user: IUser): boolean {
    return user.lastName !== '' && user.firstName !== ''
  }

  static validateVisa(user: IUser): boolean {
    return !!user.candidate?.candidateVisa?.visaTypeId
  }

  static validateContact(user: IUser): boolean {
    if (!user.contacts || user.contacts.length === 0) return false
    const emailExists = user.contacts.some((contact) => contact.contactMethodId === 1 && contact.account !== '')
    const phoneExists = user.contacts.some((contact) => contact.contactMethodId === 2 && contact.account !== '')
    return emailExists && phoneExists
  }

  static validatePersonalInfo(user: IUser): boolean {
    if (!user.candidate) return false
    const {
      gender,
      candidate: { birthDate, countryId, prefectureId, cityId, firstArrivalDate, grewUpInCountryId }
    } = user
    if (!birthDate) return false
    if (
      user.candidate.candidateVisa?.visaTypeId === JapaneseCitizenshipId &&
      (!grewUpInCountryId || (grewUpInCountryId !== JapanId && !firstArrivalDate))
    ) {
      return false
    } else if (!countryId) return false
    // when the user is a Japanese Citizen, we don't need to check the country id
    if (user.candidate.candidateVisa?.visaTypeId !== JapaneseCitizenshipId && !countryId) return false
    return !!gender && gender !== Gender.Unknown && !!prefectureId && !!cityId
  }

  static validateJapaneseLevel(user: IUser): boolean {
    if (!user.candidate) return false
    const { japaneseConversationId } = user.candidate
    return !!japaneseConversationId
  }
}
