interface IError {
  message: any
}
export function errors(error: any): IError {
  if (error.status === 422) {
    return error.raw.response.data.errors
  } else if (error.status === 400) {
    let message = error.raw.response.data.message
    if (Array.isArray(message)) {
      message = message.join('<br />')
    }
    return { message }
  } else if (error.status === 500) {
    return { message: error.raw.response.data }
  }
  return { message: error.message }
}
