import { ICMSResponse } from '../interface/i-cms-response';
import { IJoomlaSidemenuLink } from '../interface/i-joomla-sidemenu-link';

export class CMSResponseFactory {
  public static readonly EmptyDiv = '<div></div>';

  createContent(top: string, bottom: string): ICMSResponse {
    return {
      top,
      bottom,
    };
  }

  createEmptyContent(): ICMSResponse {
    return {
      top: CMSResponseFactory.EmptyDiv,
      bottom: CMSResponseFactory.EmptyDiv,
    };
  }

  createSideMenu(title: string, route: string, icon: string): IJoomlaSidemenuLink {
    return {
      title,
      route,
      icon,
    };
  }

  createEmptySideMenu(): IJoomlaSidemenuLink {
    return {
      title: '',
      route: '',
      icon: '',
    };
  }
}
