import { IUser, CandidateNameDto } from "../../../../../../core/src"

export const toCandidateNameSeed = (
  user: IUser
): CandidateNameDto => {
  return {
    firstName: user.firstName!,
    lastName: user.lastName!,
    nickname: user.nickname!
  }
}
