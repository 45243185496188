import { SpaceLanguage } from '../schema/namespace'
import { ICandidateJapaneseLanguageSeed } from '../../entities/candidate.japanese.language'
import { AvailableLanguageCertificateGroups } from '../schema/models/language.certificate.group'
import { APIRequest, HTTPMethod } from '@tokyojob/frontend-core'

/**
 * Execute an API request to get a candidate's language skills.
 * @remarks The candidate identifier is determined by the API and the JWT, and as such there's no need to include it in the request.
 */
export class GetCandidateJapaneseSkillsRequest
  implements APIRequest<SpaceLanguage.GetCandidateJapaneseSkillsResponse> {
  response!: SpaceLanguage.GetCandidateJapaneseSkillsResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor() {
    this.path = '/candidate/language'
  }
}

/**
 * Execute an API request to get a catalogue of listening skills.
 */
export class GetJapaneseListeningRequest
  implements APIRequest<SpaceLanguage.GetJapaneseListeningResponse> {
  response!: SpaceLanguage.GetJapaneseListeningResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor() {
    this.path = '/language/japanese/listening'
  }
}

/**
 * Execute an API request to get a catalogue of reading skills.
 */
export class GetJapaneseReadingRequest
  implements APIRequest<SpaceLanguage.GetJapaneseReadingResponse> {
  response!: SpaceLanguage.GetJapaneseReadingResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor() {
    this.path = '/language/japanese/reading'
  }
}

/**
 * Execute an API request to get a catalogue of reading skills.
 */
export class GetJapaneseConversationRequest
  implements APIRequest<SpaceLanguage.GetJapaneseConversationResponse> {
  response!: SpaceLanguage.GetJapaneseConversationResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor() {
    this.path = '/language/japanese/conversation'
  }
}

/**
 * Execute an API request to get a catalogue of Japanese business skills.
 */
export class GetJapaneseBusinessSkillnRequest
  implements APIRequest<SpaceLanguage.GetJapaneseBusinessSkillResponse> {
  response!: SpaceLanguage.GetJapaneseBusinessSkillResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor() {
    this.path = '/language/japanese/business'
  }
}

/**
 * Execute an API request to get a catalogue of reading skills.
 */
export class GetJapaneseWritingRequest
  implements APIRequest<SpaceLanguage.GetJapaneseWritingResponse> {
  response!: SpaceLanguage.GetJapaneseWritingResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor() {
    this.path = '/language/japanese/writing'
  }
}

/**
 * Execute an API request to update a candidate's language skills.
 */
export class PutCandidateJapaneseSkills
  implements APIRequest<SpaceLanguage.PutCandidateJapaneseSkillsResponse> {
  response!: SpaceLanguage.PutCandidateJapaneseSkillsResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.PUT
  params: SpaceLanguage.PutCandidateJapaneseSkillsRequest
  constructor(seed: ICandidateJapaneseLanguageSeed) {
    this.params = seed
    this.path = '/candidate/language'
  }
}

/**
 * Execute an API request to get the available language certificates.
 */
export class GetLanguageCertificateGroupRequest
  implements APIRequest<SpaceLanguage.GetLanguageCertificateGroupResponse> {
  response!: SpaceLanguage.GetLanguageCertificateGroupResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(group: AvailableLanguageCertificateGroups) {
    this.path = '/language/certificate-group/' + group
  }
}
