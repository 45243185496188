/**
 * @deprecated Replaced by the new application statu logs. Delete.
 */
export enum ApplicationStatus {
  New = 1,
  CandidateHired = 2,
  CandidateRejected = 3,
  CancelledByCandidate = 4,
  CancelledByAgent = 5
}
