/**
 * Defines the size of a Font Awesome Icon
 * @remarks Other sizes are available if needed
 * @link https://github.com/FortAwesome/vue-fontawesome/blob/2.x/src/components/FontAwesomeIcon.js
 */
export enum IconSize {
  xsmall = '12px',
  small = '14px',
  medium = '20px',
  large = '24px',
  xlarge = '30px',
  size16 = '16px',
  size18 = '18px',
  size36 = '36px',
  size50 = '50px',
  size100 = '100px'
}
