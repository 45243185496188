import { ApplicationAgentStatusId } from './application-agent-status-id.enum';
import { ApplicationAgentSubStatusId } from './application-agent-substatus-id.enum';

export const ApplicationAgentStatusHidden: Record<number, boolean> = {
  // This one is meant to be automatically set
  [ApplicationAgentStatusId.NewApplication]: true,
};

export const ApplicationAgentSubStatusHidden: Record<number, boolean> = {
  // Not useful for the user
  [ApplicationAgentSubStatusId.NewApplicationWebGuest]: true,
  [ApplicationAgentSubStatusId.NewApplicationMessenger]: true,
  [ApplicationAgentSubStatusId.NewApplicationIOS]: true,
  [ApplicationAgentSubStatusId.NewApplicationWebAccount]: true,
  // Rejection reasons are set in the rejection parent only
  [ApplicationAgentSubStatusId.ReviewingOther]: true,
  [ApplicationAgentSubStatusId.ContactingAttemptingContact]: true,
  [ApplicationAgentSubStatusId.ContactingCouldNotGetInContactAfter3Attempts]: true,
  //
};
