import { IJapaneseConversation } from '../../../../../../core/src'
import { JapaneseConversationEntity } from '../entities/japanese-conversation'

export class JapaneseConversationEntityFactory {
  /**
   * Generates an IJapaneseListening object. Used to add extra properties during the conversion process.
   * @param props Data to use when creating the object.
   */
  defaultProps(props?: IJapaneseConversation): IJapaneseConversation {
    return { id: 0, i18n: [], ...props }
  }

  /**
   * Generates a JapaneseConversationEntity object.
   * @param props Data to use when creating the object.
   */
  new(props?: IJapaneseConversation): JapaneseConversationEntity {
    return new JapaneseConversationEntity(this.defaultProps(props))
  }

  /**
   * Shorthand version to create an entity containing only a value and a label.
   * @param value
   * @param label
   * @returns
   */
  newFromValueLabel(value: number, label: string): JapaneseConversationEntity {
    return new JapaneseConversationEntity(
      this.defaultProps({
        id: value,
        i18n: [{ name: label, description: '', locale: '' }],
      })
    )
  }
}
