import { IsEmail, IsEnum } from 'class-validator';
import { LanguageLocale } from '../../language';

export class UserEmailChangeDto {
  @IsEmail()
  email: string;

  @IsEnum(LanguageLocale)
  locale: LanguageLocale
}
