
import Vue, { PropType } from 'vue'
import { FaIcon } from './class'

export default Vue.extend({
  name: 'c-fa-icon',
  props: {
    icon: {
      type: Object as PropType<FaIcon>,
      required: true
    }
  }
})
