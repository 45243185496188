import { render, staticRenderFns } from "./c-candidate-footer-mini.component.vue?vue&type=template&id=f2d1639e&scoped=true"
import script from "./c-candidate-footer-mini.component.vue?vue&type=script&lang=ts"
export * from "./c-candidate-footer-mini.component.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2d1639e",
  null
  
)

export default component.exports