import { APIRequest, HTTPMethod, QueryStringEntity } from '@tokyojob/frontend-core'
import { IContactSeed } from '../../entities/contact'
import { SpaceContact } from '../schema/namespace'

export class FetchRequest implements APIRequest<SpaceContact.FetchResponse> {
  response!: SpaceContact.FetchResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(entity?: QueryStringEntity) {
    const queries = entity ? entity.queries : ''
    this.path = '/contact/' + queries
  }
}

export class UpdateRequest implements APIRequest<SpaceContact.UpdateResponse> {
  response!: SpaceContact.UpdateResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.PUT
  params: SpaceContact.UpdateRequest
  constructor(id: number, seed: IContactSeed) {
    this.params = seed
    this.path = '/contact/' + id
  }
}

export class CreateRequest implements APIRequest<SpaceContact.CreateResponse> {
  response!: SpaceContact.CreateResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: SpaceContact.CreateRequest
  constructor(seed: IContactSeed) {
    this.params = seed
    this.path = '/contact/'
  }
}

export class GetRequest implements APIRequest<SpaceContact.GetResponse> {
  response!: SpaceContact.GetResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  constructor(id: number) {
    this.path = '/vacancy/' + id
  }
}

export class DeleteRequest implements APIRequest<SpaceContact.DeleteResponse> {
  response!: SpaceContact.DeleteResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.DELETE
  constructor(id: number) {
    this.path = '/contact/' + id
  }
}
