import { useContext, useRoute, useRouter } from '@nuxtjs/composition-api'
import { GTagService } from '@tokyojob/frontend-core'
import { IApp } from '~/types/nuxt'

// This is the format when we move to Nuxt Bridge/Nuxt 3
// export const getProvidedApp = (): IApp => {
//   return useNuxtApp().$App
// }

export const getProvidedApp = (): IApp => {
  const context = useContext() as any
  const app = context.App
  return app
}

// This is the format when we move to Nuxt Bridge/Nuxt 3
// export const getProvidedGtag = (): GTagService => {
//   return useNuxtApp().$gtag
// }

export const getProvidedGtag = (): GTagService => {
  const context = useContext() as any
  return context.$gtag as GTagService
}

export const getRoute = () => {
  // DO NOT return the .value here, it will cause reactivity issues
  return useRoute()
}

export const getRouter = () => {
  return useRouter()
}

export const redirect = (link: string) => {
  const context = useContext() as any
  context.redirect(link)
}

export const getContext = () => {
  const context = useContext() as any
  return context
}
