import { Middleware } from '@nuxt/types'
import { Context } from '@nuxt/types/app'
import { FilterKey } from '@tokyojob/core'
import { FilterQueryManager, RouterLinkFactory } from '@tokyojob/frontend-components'
import { LogType } from '@tokyojob/frontend-core'

const jobAccountlessApplication: Middleware = async ({ App, redirect, params }: Context) => {
  try {
    const strFilters = App.state.formattedFilters
    if (!strFilters) {
      const link = RouterLinkFactory.localizedLink(`/job/${params.id}`, App.localizationSelector.language)
      redirect(link)
      return
    }
    const filters = JSON.parse(strFilters)
    const filterQueryManager = new FilterQueryManager()
    const jpLevel = filterQueryManager.getQueryValue(filters, FilterKey.japanese)
    const visaType = filterQueryManager.getQueryValue(filters, FilterKey.visa)
    // Agents should also get kicked out
    if (!jpLevel || !visaType || App.state.isLoggedInAgent) {
      const link = RouterLinkFactory.localizedLink(`/job/${params.id}`, App.localizationSelector.language)
      redirect(link)
    }
  } catch (error) {
    App.logService.handle({ type: LogType.Error, error: error as Error })
    const link = RouterLinkFactory.localizedLink(`/job/${params.id}`, App.localizationSelector.language)
    redirect(link)
  }
}

export default jobAccountlessApplication
