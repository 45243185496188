import { AppEnvironment } from "../app-environment"

const staticURLBucket: Record<AppEnvironment, string> = {
  [AppEnvironment.Development]: 'https://tokyojob-public-dev.s3-ap-northeast-1.amazonaws.com/',
  [AppEnvironment.Test]: 'https://tokyojob-public-test.s3-ap-northeast-1.amazonaws.com/',
  [AppEnvironment.Production]: 'https://tokyojob-public.s3-ap-northeast-1.amazonaws.com/'
}

export class StaticURLBuilder {
  static logo(environment: AppEnvironment) {
    return `${staticURLBucket[environment]}files/peregre-works-color.svg`
  }

  static logoWhite(environment: AppEnvironment) {
    return `${staticURLBucket[environment]}files/peregre-works-white.svg`
  }
}
