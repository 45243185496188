import { IApplicationStatusCandidateLog } from '../interface';
import { IApplicationStatusLog } from '../interface/application-status/i-application-status-log';

export class ApplicationStatusCandidateLogFactory {
  static toApplicationStatusLog(log: IApplicationStatusCandidateLog): IApplicationStatusLog {
    return {
      id: log.id,
      memo: log.memo,
      reason: log.reason,
      timestamp: log.timestamp,
      applicationId: log.applicationId,
      applicationStatus: log.applicationStatusCandidate,
      applicationStatusId: log.applicationStatusCandidateId,
      applicationSubStatus: log.applicationSubStatusCandidate,
      applicationSubStatusId: log.applicationSubStatusCandidateId,
      triggeredByApplicationStatusLogId: log.triggeredByApplicationStatusAgentLogId,
    };
  }

  static fromApplicationStatusLog(applicationStatusLog: IApplicationStatusLog): IApplicationStatusCandidateLog {
    return {
      id: applicationStatusLog.id,
      memo: applicationStatusLog.memo,
      reason: applicationStatusLog.reason,
      application: applicationStatusLog.application,
      applicationId: applicationStatusLog.applicationId,
      applicationStatusCandidateId: applicationStatusLog.applicationStatusId,
      applicationStatusCandidate: applicationStatusLog.applicationStatus,
      applicationSubStatusCandidateId: applicationStatusLog.applicationSubStatusId,
      applicationSubStatusCandidate: applicationStatusLog.applicationSubStatus,
      timestamp: applicationStatusLog.timestamp,
      triggeredByApplicationStatusAgentLogId: applicationStatusLog.triggeredByApplicationStatusLogId,
    };
  }
}
