export enum ApplicationCandidateStatusId {
  Submitted = 100,
  Check = 200,
  Contacting = 300,
  Rejected = 400,
  Interview = 500,
  Hired = 1000,
  Withdrawn = 1100,
  Memo = 9999999,
}

/**
 * Statuses that only need a parent label.
 */
export const ApplicationWithParentLabelOnlyCandidateStatus: ApplicationCandidateStatusId[] = [ApplicationCandidateStatusId.Submitted];
