import { IsOptional, IsString } from 'class-validator';

/**
 * Used when performing data save operations for the Candidate's name information.
 */
export class CandidateNameDto {
  /**
   * Candidate's first name.
   */
  @IsString()
  firstName: string;

  /**
   * Candidate's last name.
   */
  @IsString()
  lastName: string;

  /**
   * Nickname of the candidate. Because some candidates can have very long or difficult names,
   * providing a nickname makes it easier for the agent to know what to call them.
   * @todo We still have an issue with pronunciation, a katakana field could be good
   * though the nickname itself could also be katakana, if we prompt the candidates for it.
   */
  @IsString()
  @IsOptional()
  nickname: string | null;
}
