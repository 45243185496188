import { IJobSector, IJobSectorI18n } from '../../../models/category';

/**
 * Getter methods for job sector objects
 */
export class JobSectorGetters {
  /**
   * Gets the first available name of the item.
   * @param item
   * @returns
   */
  static getName(item: IJobSector): string {
    if (!JobSectorGetters.hasi18n(item)) return '';
    const i18n: IJobSectorI18n[] | undefined = item.i18n;
    return i18n![0].name!;
  }

  /**
   * Whether the provided item has translation data or not
   * @param item
   * @returns
   */
  static hasi18n(item: IJobSector): boolean {
    if (!item) return false;
    const i18n: IJobSectorI18n[] | undefined = item.i18n;
    return !!(i18n && i18n.length > 0);
  }
}
