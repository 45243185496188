import { IFormInputData } from '../../html/forms/inputs/interfaces/form-input-data'
import IAutocompleteItem from '../../autocomplete/interfaces/autocomplete.item'
import { IVisaType, IVisaTypeInfo } from '../../../../../../core/src'

export const JapaneseCitizenId = 1034
/**
 * @todo Delete the visaTypeEntity in the agent and candidate applications, start using this one instead. Add any missing props / methods as necessary.
 */
export class VisaTypeEntity implements IAutocompleteItem, IFormInputData {
  constructor(private readonly _props: IVisaType) {}

  get props(): IVisaType {
    return this._props
  }

  /**
   * The unique identifier of this visa type.
   */
  get id(): number {
    return this.props.id!
  }

  /**
   * Contains the localization of this visa type.
   */
  get i18n(): IVisaTypeInfo[] {
    return this.props.i18n!
  }

  /**
   * The name of the visa type
   */
  get name(): string {
    if (this._props.i18n!.length === 0) return ''
    return this._props.i18n![0].name!
  }

  /**
   * The name of the visa type in Kana form.
   */
  get nameKana(): string {
    // TODO: Update this when multilingual support is added
    return ''
  }

  get label(): string {
    return this.name
  }

  get value(): number {
    return this.id
  }

  /**
   * Whether a designation paper is required or not.
   */
  get designationPapersRequired(): boolean {
    return this.props.designationPapersRequired!
  }

  /**
   * Whether the visa type has residence card or not.
   */
  get hasResidenceCard(): boolean {
    return this.props.hasResidenceCard!
  }

  /**
   * Whether the visa type has expiration date or not.
   */
  get hasExpirationDate(): boolean {
    return this.props.hasExpirationDate!
  }

  /**
   * The parent of this visa type.
   */
  get parentId(): number | undefined {
    return this.props.parentId
  }

  /**
   * The children of this visa type.
   */
  get children(): VisaTypeEntity[] {
    return this.props.children!.map((e) => VisaTypeEntityFactory(e))
  }

  /**
   * Whether the visa type has children entities or not.
   */
  get hasChildren(): boolean {
    return this.children && this.children.length > 0
  }

  /**
   * This checks if this visa type is Japanese Citizen
   * @returns
   */
  get isJapaneseCitizen(): boolean {
    return this.id === JapaneseCitizenId
  }

  /**
   * Gets the first child visa entity that matches the provided id.
   * @param id
   * @returns
   */
  getChildById(id: number): VisaTypeEntity | undefined {
    if (this.hasChildren) {
      return this.children.find((e) => e.id === id)
    }
  }

  /**
   * This checks if the passed visaEntity is the parent of this visa type
   * @param visaEntity
   * @returns
   */
  isParent(visaEntity: VisaTypeEntity): boolean {
    return visaEntity.id === this.parentId
  }
}

// TODO: Move to factory class

export const VisaTypePropsFactory = (props?: Partial<IVisaType>): IVisaType => ({
  id: 0,
  i18n: [],
  designationPapersRequired: false,
  hasExpirationDate: true,
  hasResidenceCard: true,
  children: [],
  ...props,
})

export const VisaTypeEntityFactory = (props?: Partial<IVisaType>): VisaTypeEntity => {
  return new VisaTypeEntity(VisaTypePropsFactory(props))
}
