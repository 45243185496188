import { ref, Ref } from 'vue'
import { ICandidateDashboardStats } from '@tokyojob/core'
import { APIClient, JWTHandler, Poller } from '@tokyojob/frontend-core'
import { GetCandidateDashboardStatsUsecase } from '~/modules/user/usecases/candidate-dashboard-stats.usecase'
import { getAppStore } from '~/store/app.store'
import { IApp } from '~/types/nuxt'
import { getProvidedApp } from '~/base/utils/provided-getter'

export function CandidateDashboardStatsComposable() {
  const poller: Ref<Poller<ICandidateDashboardStats | null>> = ref(null) as any
  const App = getProvidedApp()
  const getStatsUsecase = new GetCandidateDashboardStatsUsecase(App)
  const appStore = getAppStore()

  async function initPoller() {
    if (process.client) {
      const loggedInCheck = () => App.state.isLoggedInCandidate
      if (App.state.isLoggedInCandidate) {
        const apiClient = new APIClient(App.state)
        const jwtHandler = new JWTHandler(apiClient)
        poller.value = new Poller(() => getStatsUsecase.execute(), loggedInCheck, jwtHandler, App.state)
        const pollingInterval = 60
        poller.value.start(pollingInterval)
        poller.value.onPollCompleted(setStats)
      }
    }
  }

  async function setStats(item: ICandidateDashboardStats | null): Promise<void> {
    if (!item) return
    appStore.setDashboardStats(item)
  }

  function stopPoller() {
    poller.value?.stop()
  }

  return {
    initPoller,
    stopPoller
  }
}
