import { JWTDecoder } from '../../../../../../core/src/models/auth/utils/jwt-decoder'
import { UserType } from '../../../../../../core/src/models/user/constants/user-type.enum'
import { APIClient } from '../../../base/api/api-client'
import { RefreshTokenPairRequest } from '../network/requests/auth-requests'
import { ICookieRepository } from './i-cookie-repository'

export class JWTHandler {
  tokenDecoder: JWTDecoder = new JWTDecoder()
  timeout: number = 30 * 1000
  constructor(public httpClient: APIClient) {}

  async evaluateAuth(
    tokenRepository: ICookieRepository,
    redirect: Function,
    expectedUserType?: UserType,
    shouldRedirectOnInvalidToken: boolean = true
  ): Promise<void> {
    const loginUrl = '/auth/login'
    const accessToken = tokenRepository.token
    const refreshToken = tokenRepository.refreshToken

    if (accessToken) {
      if (this.isTokenExpired(accessToken)) {
        await this.handleExpiredAccessToken(tokenRepository, refreshToken, redirect, shouldRedirectOnInvalidToken, loginUrl)
      }
      this.validateUserType(this.tokenDecoder, accessToken, expectedUserType, redirect, loginUrl)
    } else if (shouldRedirectOnInvalidToken) {
      redirect(loginUrl)
    }
  }

  async handleExpiredAccessToken(
    tokenRepository: ICookieRepository,
    refreshToken: string | undefined,
    redirect: Function,
    shouldRedirect: boolean,
    loginUrl: string
  ) {
    tokenRepository.token = ''
    if (shouldRedirect && (!refreshToken || this.isTokenExpired(refreshToken))) {
      redirect(loginUrl)
      return
    }
    if (refreshToken) {
      const newTokens = await this.refreshTokens(tokenRepository, refreshToken)
      this.storeTokens(tokenRepository, newTokens)
    }
  }

  validateUserType(
    tokenDecoder: JWTDecoder,
    accessToken: string,
    expectedUserType: UserType | undefined,
    redirect: Function,
    loginUrl: string
  ) {
    if (!expectedUserType) {
      return
    }
    const userToken = tokenDecoder.decode(accessToken)
    if (userToken && userToken.role !== expectedUserType) redirect(loginUrl)
  }

  isTokenExpired(token: string): boolean {
    const decodedToken = this.tokenDecoder.decode(token)
    const currentTime = Math.floor(Date.now() / 1000)
    return decodedToken ? decodedToken.exp < currentTime : true
  }

  async refreshTokens(
    tokenRepository: ICookieRepository,
    refreshToken: string
  ): Promise<{ accessToken: string; refreshToken: string }> {
    // Clear tokens from cookies first
    tokenRepository.token = ''
    tokenRepository.refreshToken = ''

    // Implement the logic to call your auth service and get new tokens using the refresh token
    const data = { token: refreshToken }
    const request = new RefreshTokenPairRequest(data)
    const response = await this.httpClient.request(request)

    return {
      accessToken: response.token,
      refreshToken: response.refreshToken,
    }
  }

  storeTokens(tokenRepository: ICookieRepository, tokens: { accessToken: string; refreshToken: string }) {
    tokenRepository.token = tokens.accessToken
    tokenRepository.refreshToken = tokens.refreshToken
  }

  /**
   * Checks if a candidate is unverified based on the provided token.
   *
   * @param {string} token - The token used to identify the candidate.
   *
   * @return {boolean} - True if the candidate is unverified, otherwise false.
   */
  isUnverifiedCandidate(token: string): boolean {
    if (!token) return false
    const decodedToken = this.tokenDecoder.decode(token)
    if (!decodedToken) return false
    const isCandidate = decodedToken.role === UserType.Candidate
    return isCandidate && !decodedToken.emailVerified
  }
}
