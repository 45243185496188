import { CandidateJapaneseLanguageSkillsDtoFactory } from '../../candidate/factory/candidate-japanese-language-skills-dto.factory';
import { CandidateNameDtoFactory } from '../../candidate/factory/candidate-name-dto.factory';
import { CandidatePersonalInformationDtoFactory } from '../../candidate/factory/candidate-personal-information-dto.factory';
import { CandidateVisaTypeDtoFactory } from '../../candidate-visa/factory/candidate-visa-type-dto.factory';
import { LanguageLocale } from '../../language';
import { IUser } from '../../user';
import { UserContactDtoFactory } from '../../user/factory/user-contacts-dto.factory';
import { IVacancy } from '../../vacancy';
import { AnonymousApplicationDto } from '../dto';

export class ApplicationDtoFactory {
  public candidateVisaTypeDtoFactory = new CandidateVisaTypeDtoFactory();
  public candidateNameDtoFactory = new CandidateNameDtoFactory();
  public candidateJapaneseLanguageSkillsDtoFactory = new CandidateJapaneseLanguageSkillsDtoFactory();
  public candidatePersonalInformationDtoFactory = new CandidatePersonalInformationDtoFactory();
  public userContactDtoFactory = new UserContactDtoFactory();

  newAnonymousApplication(user: IUser, vacancy: IVacancy, locale: LanguageLocale): AnonymousApplicationDto {
    const validVacancy = vacancy && !!vacancy.id && vacancy.id;
    if (!validVacancy) throw new Error('Invalid vacancy. Expected vacancy with a non-zero id.');
    const nameDto = this.candidateNameDtoFactory.new(user);
    const visaDto = this.candidateVisaTypeDtoFactory.new(user);
    const jpSkillsDto = this.candidateJapaneseLanguageSkillsDtoFactory.new(user);
    const personalInfoDto = this.candidatePersonalInformationDtoFactory.new(user);
    const contactsDto = this.userContactDtoFactory.new(user);

    const dto = new AnonymousApplicationDto();
    // Stupid fix because all of a sudden it stopped working properly
    dto.vacancyId = typeof vacancy.id! === 'string' ? parseInt((vacancy.id! as unknown) as string, 10) : vacancy.id! as number;

    dto.nameDto = nameDto;
    dto.visaDto = visaDto;
    dto.japaneseSkillsDto = jpSkillsDto;
    dto.personalInformationDto = personalInfoDto;
    dto.contactsDto = contactsDto;
    dto.locale = locale;
    return dto;
  }
}
