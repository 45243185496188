import { Type } from 'class-transformer';
import { IsBoolean, IsNumber, IsOptional, IsString, ValidateIf, ValidateNested } from 'class-validator';
import { VacancyWageDto } from '../vacancy-wage.dto';

/**
 * The DTO that validates the employment section
 */
export class VacancySaveEmploymentDto {
  /**
   * The contract type of the vacancy
   */
  @IsNumber()
  contractTypeId: number;

  /**
   * Flag if the employment has a trial period.
   */
  @IsOptional()
  @IsBoolean()
  trialPeriod: boolean;

  /**
   * If the vacancy has a trial period, how many months is it?
   */
  @IsOptional()
  @IsNumber()
  trialPeriodMonths: number;

  /**
   * The wage information of the vacancy
   */
  @ValidateNested({ each: true })
  @Type(() => VacancyWageDto)
  wages: VacancyWageDto[];

  /**
   * Flag if salary increase is applicable
   */
  @IsOptional()
  @IsBoolean()
  salaryIncrease?: boolean;

  /**
   * Flag if bonus is applicable
   */
  @IsOptional()
  @IsBoolean()
  bonus?: boolean;

  /**
   * Flag if bonus through achievements is applicable
   */
  @IsOptional()
  @IsBoolean()
  bonusAchievements?: boolean;

  /**
   * Flag if there are any application salaries and bonuses
   */
  @IsOptional()
  @IsBoolean()
  otherSalariesAndBonuses?: boolean;

  /**
   * Details of other salaries and bonuses
   */
  @IsOptional()
  @IsString()
  otherSalariesAndBonusesDetail?: string;
}
