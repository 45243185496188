import { IsNumber, IsOptional } from "class-validator";

/**
 * The DTO that validates the skills information
 */
export class VacancySkillDto {
  /**
   * The skill id associated with the vacancy
   */
  @IsNumber()
  id: number;

  /**
   * The number of experience (in years) required for this skill
   */
  @IsNumber()
  @IsOptional()
  years?: number;
}