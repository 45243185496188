import { IsDate, IsDefined, IsNumber, IsOptional, Min } from 'class-validator';
import { CandidateSex } from '../../user/constants/candidate-sex.enum';

/**
 * Candidate Personal Information updpate request structure.
 * @todo conditional validation depending on visa type.
 */
export class CandidatePersonalInformationDto {
  /**
   * Sex of the candidate, as specified in the residence card.
   */
  @IsDefined()
  gender: CandidateSex;

  /**
   * Birthday of the candidate, as specified in the residence card.
   */
  @IsDefined()
  // @IsDate()
  birthDate: Date;

  /**
   * Country / Nationality of the candidate, as specified in the residence card.
   */
  @IsOptional()
  @IsNumber()
  @Min(1)
  countryId?: number;

  /**
   * Date since the candidate started living in Japan.
   */
  @IsOptional()
  // @IsDate()
  firstArrivalDate?: Date;

  /**
   * For candidates with Japanese nationality, country in which they grew up.
   */
  @IsOptional()
  @IsNumber()
  @Min(1)
  grewUpInCountryId?: number;

  @IsDefined()
  @IsNumber()
  @Min(1)
  prefectureId?: number;

  @IsDefined()
  @IsNumber()
  @Min(1)
  cityId?: number;
}
