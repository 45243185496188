import { LanguageURL } from '../../../../../../core/src/'
import ENLocalization from 'vee-validate/dist/locale/en.json'
import JALocalization from 'vee-validate/dist/locale/ja.json'
import VILocalization from 'vee-validate/dist/locale/vi.json'
import ZHLocalization from 'vee-validate/dist/locale/zh_CN.json'

class VeeValidateLocalization {
  messages: Record<string, string>
}

/**
 * Contains the localization data for vee-validate error messages
 */
export const appLocalization: Record<LanguageURL, VeeValidateLocalization> = {
  [LanguageURL.EN]: {
    messages: {
      ...ENLocalization.messages,
      confirmed: '{_field_} does not match {target}.',
      email: '{_field_} must be an email',
      min: '{_field_} must be at least {length} characters long.',
      max: '{_field_} must be no more than {length} characters long.',
      regex: '{_field_} does not match the required pattern.',
      required: 'The {_field_} field is required.',
      size: `The {_field_} cannot be bigger than {size} kilobytes.`,
      image: 'The {_field_} must be an image.',
      ext: 'The {_field_} must be one of the following file types: {extensions}.',
      min_value: 'Please enter {min} or more.',
      max_value: 'Please enter no more than {max} characters.',
      autocomplete_select: 'Please select an item for {_field_}',
      numeric: 'The {_field_} should be a number',
      mobile: 'The {_field_} field must be a valid japanese mobile phone number',
      mobileOrEmail: 'The {_field_} field must be either a valid japanese mobile phone number or e-mail address',
      alpha_spaces: 'Please use only letters and spaces.',
      mindimensions: 'The {_field_} resolution should be {width}px wide and {height}px tall.',
      date_between: 'The date must be between {min} and {max}.',
      date_after: 'The date must be in the future.',
      zipcode: 'The Zip/Postal code must be in the format 1234567 or 123-4567.',
      addressLineWithTown: 'The {_field_} must be in the format xx, xx-xx, xx-xx-xx. e.g. 524-1',
      katakana: 'The {_field_} must be full width Katakana.',
    },
  },
  [LanguageURL.JP]: {
    messages: {
      ...JALocalization.messages,
      confirmed: '{_field_}が{target}と一致しません。',
      email: '{_field_}はメールアドレス形式で入力してください。',
      min: '{_field_}は{length}文字以上入力してください。',
      max: '{_field_}は{length}文字以下入力してください。',
      regex: '{_field_}は指定されたパターンと一致しません。',
      required: '{_field_}は必須項目です。',
      size: `{_field_}は{size}キロバイトを超えることはできません。`,
      image: '{_field_}は画像のみです。',
      ext: '{_field_}は次のファイル形式のみです: {extensions}。',
      min_value: '{min} 以上入力してください。',
      max_value: '{max} 以下入力してください。',
      autocomplete_select: '{_field_}を選択してください。',
      numeric: '{_field_}は数字のみです。',
      mobile: '{_field_}フィールドに日本の携帯電話番号を入力してください。',
      mobileOrEmail: '{_field_}フィールドに日本の携帯電話番号またはメールアドレスを入力してください。',
      alpha_spaces: '文字とスペースのみを入力してください。',
      mindimensions: '{_field_}の解像度は横が{width}pxと縦が{height}pxにしてください。',
      date_between: '日付は{min}から{max}の間でなければなりません。',
      date_after: '日付は将来でなければなりません。',
      zipcode: '郵便番号は1234567または123-4567の形式でなければなりません。',
      addressLineWithTown: '{_field_} は xx、xx-xx、xx-xx-xx の形式である必要があります。例: 524-1。',
      katakana: '{_field_}は全角カタカナのみです。',
    },
  },
  [LanguageURL.VI]: {
    messages: {
      ...VILocalization.messages,
      confirmed: '{_field_} không khớp với {target}.',
      email: '{_field_} phải là dạng địa chỉ email.',
      min: '{_field_} phải có ít nhất {length} ký tự.',
      max: '{_field_} không được vượt quá {length} ký tự.',
      regex: '{_field_} không khớp với mẫu đã được quy định.',
      required: 'Trường {_field_} là bắt buộc.',
      size: 'Kích thước {_field_} không được vượt quá {size} kilobyte.',
      image: '{_field_} phải là một hình ảnh.',
      ext: '{_field_} phải là một trong các loại tệp sau: {extensions}.',
      min_value: 'Vui lòng nhập {min} hoặc nhiều hơn.',
      max_value: 'Vui lòng nhập không quá {max} ký tự.',
      autocomplete_select: 'Vui lòng chọn một mục cho {_field_}.',
      numeric: '{_field_} phải là một số.',
      mobile: 'Trường {_field_} phải là một số điện thoại di động Nhật Bản hợp lệ.',
      mobileOrEmail: 'Trường {_field_} phải là một số điện thoại di động Nhật Bản hợp lệ hoặc địa chỉ email.',
      alpha_spaces: 'Vui lòng chỉ sử dụng chữ và khoảng trắng.',
      mindimensions: 'Độ phân giải {_field_} phải là {width}px chiều rộng và {height}px chiều cao.',
      date_between: 'Ngày phải nằm trong khoảng từ {min} đến {max}.',
      date_after: 'Ngày phải là trong tương lai.',
      zipcode: 'Mã Zip/Postal phải có định dạng 1234567 hoặc 123-4567.',
      addressLineWithTown: '{_field_} phải có định dạng xx, xx-xx, xx-xx-xx. Ví dụ: 524-1.',
      katakana: '{_field_} phải là Katakana toàn bộ chiều rộng.',
    },
  },
  [LanguageURL.ZH]: {
    messages: {
      ...ZHLocalization.messages,
      confirmed: '{_field_}与{target}不匹配。',
      email: '{_field_}请输入电子邮件格式。',
      min: '{_field_}至少输入{length}个字符。',
      max: '{_field_}最多输入{length}个字符。',
      regex: '{_field_}不符合指定的模式。',
      required: '{_field_}为必填项。',
      size: '{_field_}不能超过{size}千字节。',
      image: '{_field_}只能上传图片。',
      ext: '{_field_}只允许以下文件格式: {extensions}。',
      min_value: '请输入大于等于{min}的值。',
      max_value: '请输入小于等于{max}的值。',
      autocomplete_select: '请选择{_field_}。',
      numeric: '{_field_}只能输入数字。',
      mobile: '请输入{_field_}字段的日本手机号码。',
      mobileOrEmail: '请输入{_field_}字段的日本手机号码或电子邮件地址。',
      alpha_spaces: '只能输入字母和空格。',
      mindimensions: '{_field_}的分辨率应为宽{width}px。高{height}px。',
      date_between: '日期必须在{min}到{max}之间。',
      date_after: '日期必须是未来的。',
      zipcode: '邮政编码必须为1234567或123-4567的格式。',
      addressLineWithTown: '{_field_} 必须是 xx、xx-xx、xx-xx-xx 的格式。例如：524-1。',
      katakana: '该{_field_}必须是全角片假名。',
    },
  },
}
