import { IVacancy, IWorkplace, IWorkplaceI18n, TranslationStatus } from '../../../models';

export class WorkplaceGetters {
  static getVacancies(workplace: IWorkplace): IVacancy[] | undefined {
    const hasVacancies = WorkplaceGetters.hasVacancies(workplace);
    if (!hasVacancies) return undefined;
    return workplace.vacancies!;
  }

  static hasVacancies(workplace: IWorkplace): boolean {
    return !!workplace.vacancies && workplace.vacancies.length > 0;
  }

  static canBeDeleted(workplace: IWorkplace): boolean {
    return !WorkplaceGetters.hasVacancies(workplace);
  }

  static getStationDistanceInformation(workplace?: IWorkplace): string {
    if (!workplace) return '';
    if (!WorkplaceGetters.hasi18n(workplace)) return '';
    return workplace.i18n![0].stationDistanceInformation || '';
  }

  static hasi18n(workplace?: IWorkplace): boolean {
    return !!(workplace && workplace.i18n && workplace.i18n.length > 0);
  }

  /**
   * Checks if the workplace text has been updated
   * @param oldWorkplaceI18n
   * @param newWorkplaceI18n
   * @returns
   */
  static hasUpdatedI18n(oldWorkplaceI18n: IWorkplaceI18n, newWorkplaceI18n: IWorkplaceI18n): boolean {
    if (!oldWorkplaceI18n || !newWorkplaceI18n) return false;
    const { stationDistanceInformation } = oldWorkplaceI18n;
    const newStationDistanceInformation = newWorkplaceI18n.stationDistanceInformation;
    if (!newStationDistanceInformation) return false;
    const sameStationDistanceInformation = newStationDistanceInformation === stationDistanceInformation;
    const hasSameI18n = sameStationDistanceInformation;
    return !hasSameI18n;
  }

  /**
   * The UI only needs to know if the translation was completed or not, everything else
   * is displayed as "Running".
   * @returns
   */
  static uiTranslationStatus(workplace: IWorkplace): TranslationStatus {
    if (!workplace) return TranslationStatus.Running;
    if (workplace.translationStatus === TranslationStatus.Completed) return TranslationStatus.Completed;
    return TranslationStatus.Running;
  }
}
