/**
 * This function encodes a given number as a base62 string.
 * It takes a number 'num' as input and returns the corresponding base62-encoded string.
 * @param num 
 * @returns 
 */
export const base62Encode = (num: number): string => {
  // Define the characters used for base62 encoding.
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

  // Initialize the 'result' variable as an empty string.
  let result = '';

  // While 'num' is greater than 0, perform the following:
  while (num > 0) {
    // Calculate the remainder of 'num' when divided by 62 and use it as an index to fetch a character from 'chars'.
    result = chars[num % 62] + result;

    // Update 'num' by performing integer division by 62 (flooring the result).
    num = Math.floor(num / 62);
  }

  // Return the resulting base62-encoded string.
  return result;
};
