
import { IJoomlaSidemenuLink } from '@tokyojob/core';
import { CFaIcon, FaIcon, FaIconFactory } from '@tokyojob/frontend-components';
import Vue, { PropType } from 'vue'

interface IData {
  faIcon: FaIcon
}

export default Vue.extend({
  props: {
    link: {
      type: Object as PropType<IJoomlaSidemenuLink>,
        required: true
      }
  },
  components: { CFaIcon },
  data(): IData {
    const faIconFactory = new FaIconFactory()
    const url = faIconFactory.getUrl(this.link.icon)
    const faIcon = faIconFactory.new({ url })
    return {
      faIcon
    }
  }
})
