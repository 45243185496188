import { IJoomlaCMSLayoutContent, LanguageLocale } from '@tokyojob/core'
import { defineStore } from 'pinia'

interface State {
  data: Record<LanguageLocale, IJoomlaCMSLayoutContent>
}

export const getSideMenuContentStore = defineStore('side-menu', {
  state: (): State => ({
    data: {} as Record<LanguageLocale, IJoomlaCMSLayoutContent>
  }),
  actions: {
    save(locale: LanguageLocale, contentInfo: IJoomlaCMSLayoutContent) {
      this.data[locale] = contentInfo
    },
    get(locale: LanguageLocale): IJoomlaCMSLayoutContent {
      return this.data[locale]
    }
  }
})
