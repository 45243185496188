import { IJoomlaCMSLayoutContent } from '../interface/i-joomla-cms-layout-content';
import { IJoomlaFooterLink } from '../interface/i-joomla-footer-link';
import { IJoomlaSidemenuLink } from '../interface/i-joomla-sidemenu-link';
import { IJoomlaSidemenuTitle } from '../interface/i-joomla-sidemenu-title';

export class CMSLinkFactory {
  static getTitle(joomlaTitleItem: IJoomlaSidemenuTitle): string {
    if (!joomlaTitleItem || !joomlaTitleItem.title) return '';
    return joomlaTitleItem.title.toLocaleUpperCase();
  }

  static getAppSectionTitle(cmsContent: IJoomlaCMSLayoutContent): string {
    if (!cmsContent || !cmsContent.section_titles) return '';
    return CMSLinkFactory.getTitle(cmsContent.section_titles.app_section);
  }

  static getJoomlaSectionTitle(cmsContent: IJoomlaCMSLayoutContent): string {
    if (!cmsContent || !cmsContent.section_titles) return '';
    return CMSLinkFactory.getTitle(cmsContent.section_titles.joomla_section);
  }

  static getOptionsTitle(cmsContent: IJoomlaCMSLayoutContent): string {
    if (!cmsContent || !cmsContent.section_titles) return '';
    return CMSLinkFactory.getTitle(cmsContent.section_titles.options_section);
  }

  static getJoomlaLinks(cmsContent: IJoomlaCMSLayoutContent): IJoomlaSidemenuLink[] {
    if (!cmsContent || !cmsContent.sidebar) return [];
    return cmsContent.sidebar;
  }

  static getFooterLinks(cmsContent: IJoomlaCMSLayoutContent): IJoomlaFooterLink[] {
    if (!cmsContent || !cmsContent.footer) return [];
    return cmsContent.footer;
  }

  static getNewsletterLink(cmsContent: IJoomlaCMSLayoutContent): string {
    if (!cmsContent || !cmsContent.newsletter_link) return '';
    return cmsContent.newsletter_link.link;
  }
}
