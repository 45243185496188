import { MetaPropertyCharset, MetaPropertyEquiv, MetaPropertyMicrodata, MetaPropertyName, MetaPropertyProperty } from 'vue-meta'
import { LanguageLocale, LocalizationData, LocalizationPath } from '../../../../../../../core/src'
import { LocalizationService } from '../../../language/localization-service'
import { MetaTagFactory } from '../meta-tag.factory'

/**
 * Translation not available for this vacancy page meta tags.
 */
export class CandidateGuestApplicationViewMetaFactory {
  static metaTags(
    localization: LocalizationData,
    locale: LanguageLocale
  ): (MetaPropertyCharset | MetaPropertyEquiv | MetaPropertyName | MetaPropertyMicrodata | MetaPropertyProperty)[] {
    const title: string = LocalizationService.t<string>(localization, LocalizationPath.CandidateApplicationViewPagesMetaTagTitle)
    const titles = MetaTagFactory.titleTags(title)
    const siteName = MetaTagFactory.siteName()
    const keywords = MetaTagFactory.keywords(localization, [])
    const locales = MetaTagFactory.localeTags(locale)
    const robots = MetaTagFactory.nonindexedRobots()
    return [...titles, siteName, keywords, ...locales, robots]
  }
}
