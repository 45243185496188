import { ICookieRepository } from './i-cookie-repository'

export class RedirectURLResolver {
  static resolve(cookieRepository: ICookieRepository, defaultURL: string): string {
    // If cookieRepository itself is falsy, return defaultURL
    if (!cookieRepository) {
      return defaultURL
    }

    // If redirectURL in cookieRepository is falsy, return defaultURL
    if (!cookieRepository.redirectURL) {
      return defaultURL
    }

    // Save the redirectURL to a constant 'url'
    const url = cookieRepository.redirectURL

    // Set the cookieRepository's redirectURL to null
    cookieRepository.redirectURL = undefined

    // Return the constant 'url'
    return url
  }
}
