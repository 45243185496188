import { IsNumber, IsObject } from 'class-validator';

/**
 * DTO to contain the image cropper result
 */
export class CropperDto {
  /**
   * One of the edit properties. This defines the x origin coordinate of the cropped rect
   */
  @IsNumber()
  left?: number;

  /**
   * One of the edit properties. This defines the y origin coordinate of the cropped rect
   */
  @IsNumber()
  top?: number;

  /**
   * One of the edit properties. This defines the width the cropped rect
   */
  @IsNumber()
  width?: number;

  /**
   * One of the edit properties. This defines the height the cropped rect
   */
  @IsNumber()
  height?: number;

  /**
   * One of the edit properties. This defines the rotation of the cropped image
   */
  @IsNumber()
  rotationAngle?: number;

  /**
   * One of the edit properties. This defines if the cropped image is flipped horizontally
   */
  @IsNumber()
  flipHorizontal?: boolean;

  /**
   * One of the edit properties. This defines if the cropped image is flipped vertically
   */
  @IsNumber()
  flipVertical?: boolean;

  /**
   * One of the edit properties. This defines the zoom value of the cropped image
   */
  @IsNumber()
  zoom?: number;
}
