import { defineStore } from 'pinia'
import { IPaginateMeta, IPaginateProps } from '../../base/entities/paginate'

export interface State<T> {
  items: Record<string, T>
  paginateMeta: IPaginateMeta
}

export class BaseStoreFactory {
  static Create<T extends Object>(storeName: string) {
    return defineStore(storeName, {
      state: (): State<T> => ({
        items: {},
        paginateMeta: {},
      }),
      actions: {
        save(items: T[] | T, key: string = 'id') {
          // If the passed item is not an array, we convert it to an array
          if (!Array.isArray(items)) {
            if (!(items as any)[key]) return
            items = [items]
          }
          for (var item of items) {
            if (!(item as any)[key]) continue
            this.items[(item as any)[key]] = item
          }
        },
        savePaginate(paginate: IPaginateProps<T>) {
          const items = paginate.rows
          this.paginateMeta = paginate.meta
          this.items = {}
          items.forEach((row) => {
            this.items = {
              ...this.items,
              // TODO: Kill this, please. I'm sorry for this.
              [(row as any).id]: row,
            }
          })
        },
        set(items: T) {
          this.items = items as any
        },
        values(): T[] {
          const values = Object.values<T>(this.items)
          return values ? values : []
        },
        keys(): string[] {
          const keys = Object.keys(this.items)
          return keys
        },
        get(id: string): T | null {
          const item = this.items[id]
          return item ? item : null
        },
        delete(key: string) {
          if (key && this.items[key]) delete this.items[key]
        },
        hasItems(): boolean {
          const items = this.values()
          return items && items.length > 0
        },
      },
    })
  }
}
