import { ValidationRuleResult } from 'vee-validate/dist/types/types'

/**
 * Validates that the selection in an autocomplete component is properly set.
 * If no item is selected, the validation fails.
 * This function must match vee-validate's function signature, and must be set in our vee-validate.ts plugin file.
 * @remarks Used with buefy's autocomplete component.
 * @param value The current text typed into the autocomplete textbox
 * @param params Array containing the item that was selected.
 * @link https://vee-validate.logaretm.com/v2/guide/custom-rules.html
 * @returns
 */
export const validateAutocompleteSelection = (
  value: any,
  params: any[] | Record<string, any>
):
  | boolean
  | string
  | ValidationRuleResult
  | Promise<boolean | string | ValidationRuleResult> => {
  const arrayParams = params as Array<any>
  const valueString = value as string
  // Both an empty textbox value, a non-selected value will be considered invalid.
  // TODO: Consider checking that the value string text and the name of the item in the array match.
  const textIsSet = valueString && valueString.trim().length > 0
  const valueIsSelected =
    arrayParams.length > 0 && arrayParams[0] && arrayParams[0].id
  return textIsSet && valueIsSelected
}
