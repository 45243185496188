import { ICity, IPrefecture, IZipCodeSearchResult, LanguageLocale } from '@tokyojob/core'
import { QueryStringEntity } from '@tokyojob/frontend-core'
import { GatewayBase } from '~/base/gateways/base'
import { FetchPrefectureCityListRequest } from '../network/requests/city'
import { FetchPrefectureListRequest } from '../network/requests/prefecture'
import { ZipCodeSearchRequest } from '../network/requests/zip-code'

/**
 * The Gateway class responsible for any location related API calls.
 */
export default class Location extends GatewayBase {
  /**
   * This API request returns a list of IPrefecture.
   * @param options
   * @returns
   */
  async FetchPrefectureList(options?: QueryStringEntity): Promise<IPrefecture[]> {
    return this.apiClient.request(new FetchPrefectureListRequest(options))
  }

  /**
   * This API returns a list of ICity that are associated with the passed prefecture id.
   * @param prefectureId
   * @param options
   * @returns
   */
  async FetchPrefectureCityListRequest(prefectureId: number, options?: QueryStringEntity): Promise<ICity[]> {
    return this.apiClient.request(new FetchPrefectureCityListRequest(prefectureId, options))
  }

  async ZipCodeSearch(zipCode: string, locale: LanguageLocale): Promise<IZipCodeSearchResult> {
    return this.apiClient.request(new ZipCodeSearchRequest(zipCode, locale))
  }
}
