import { APIRequest, HTTPMethod, IFile, IUIFile } from '@tokyojob/frontend-core'

/**
 * Request data to upload temporary files.
 */
export class TemporaryFileCreateRequest implements APIRequest<IFile> {
  response!: IFile
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: FormData
  constructor(params: FormData) {
    this.params = params
    this.path = `/attachment/temporary`
  }
}

/**
 * Request data to upload temporary files.
 */
export class TemporaryImageFileCreateRequest implements APIRequest<IUIFile> {
  response!: IUIFile
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: FormData
  constructor(params: FormData) {
    this.params = params
    this.path = `/attachment/temporary/image`
  }
}

/**
 * Request data in the personal id section of the bot to upload image files.
 */
export class OnboardingPersonalIdImageFileCreateRequest implements APIRequest<IUIFile> {
  response!: IUIFile
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: FormData
  constructor(params: FormData) {
    this.params = params
    this.path = `/attachment/onboarding/personal-id/upload`
  }
}

/**
 * Request data in the personal id section of the bot to delete image files.
 */
export class OnboardingPersonalIdImageFileDeleteRequest implements APIRequest<boolean> {
  response!: boolean
  path: string
  contentType = 'application/json'
  method = HTTPMethod.DELETE
  constructor(id: number) {
    this.path = `/attachment/onboarding/personal-id/delete/${id}`
  }
}
