import { IsBoolean, IsNumber, IsOptional } from 'class-validator';
import { AttachmentDto } from '../../common/attachment/dto/attachment.dto';
/**
 * The DTO that validates the image information
 */
export class VacancyPictureDto {
  /**
   * Identifier of the vacandy image row.
   */
  @IsOptional()
  id?: number;
  /**
   * Image attachment.
   */
  attachment: AttachmentDto;

  /**
   * Image edited
   */
  edited: AttachmentDto;

  /**
   * Image thumbnail.
   */
  thumbnail: AttachmentDto;

  /**
   * The order in which the image should be displayed inn relation to one another.
   */
  order: number;

  /**
   * One of the edit properties. This defines the x origin coordinate of the cropped rect
   */
  @IsOptional()
  @IsNumber()
  left?: number;

  /**
   * One of the edit properties. This defines the y origin coordinate of the cropped rect
   */
  @IsOptional()
  @IsNumber()
  top?: number;

  /**
   * One of the edit properties. This defines the width the cropped rect
   */
  @IsOptional()
  @IsNumber()
  width?: number;

  /**
   * One of the edit properties. This defines the height the cropped rect
   */
  @IsOptional()
  @IsNumber()
  height?: number;

  /**
   * One of the edit properties. This defines the rotation of the cropped image
   */
  @IsOptional()
  @IsNumber()
  rotationAngle?: number;

  /**
   * One of the edit properties. This defines if the cropped image is flipped horizontally
   */
  @IsOptional()
  @IsBoolean()
  flipHorizontal?: boolean;

  /**
   * One of the edit properties. This defines if the cropped image is flipped vertically
   */
  @IsOptional()
  @IsBoolean()
  flipVertical?: boolean;

  /**
   * One of the edit properties. This defines the zoom value of the cropped image
   */
  @IsOptional()
  @IsNumber()
  zoom?: number;
}
