import { MetaPropertyCharset, MetaPropertyEquiv, MetaPropertyMicrodata, MetaPropertyName, MetaPropertyProperty } from 'vue-meta'
import { LanguageLocale, LocalizationData, LocalizationPath } from '../../../../../../../core/src'
import { LocalizationService } from '../../../language/localization-service'
import { MetaTagFactory } from '../meta-tag.factory'

/**
 * Candidate signup meta tags.
 */
export class CandidateSignupMetaFactory {
  static metaTags(localization: LocalizationData, locale: LanguageLocale): (
    | MetaPropertyCharset
    | MetaPropertyEquiv
    | MetaPropertyName
    | MetaPropertyMicrodata
    | MetaPropertyProperty
  )[] {
    const pageTitle: string = LocalizationService.t<string>(localization, LocalizationPath.CandidateSignupPageMetaTagTitle)
    const descriptionString: string = LocalizationService.t<string>(localization, LocalizationPath.CandidateSignupPageMetaTagDescription)
    const keywordsArray: string[] = LocalizationService.t<string[]>(localization, LocalizationPath.CandidateSignupPageMetaTagKeywords)
    const titles = MetaTagFactory.titleTags(pageTitle)
    const siteName = MetaTagFactory.siteName()
    const descriptions = MetaTagFactory.descriptionTags(descriptionString)
    const keywords = MetaTagFactory.keywords(localization, keywordsArray)
    const locales = MetaTagFactory.localeTags(locale)
    const robots = MetaTagFactory.nonindexedRobots()
    return [...titles, siteName, ...descriptions, keywords, ...locales, robots]
  }
}
