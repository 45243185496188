import { AppEnvironment, BucketVisibility } from '../../../configs';
import {
  ApplicationAGGridStatusIconThemes,
  ApplicationAGGridStatusThemes,
  ApplicationAGGridSubStatusThemes,
  ApplicationAgentStatusId,
  ApplicationAgentSubStatusId,
  ApplicationCandidateAGGridStatusThemes,
  ApplicationCandidateStatusIcons,
  ApplicationCandidateStatusId,
  ApplicationCandidateTimelineStatusThemes,
  ApplicationSource,
  ApplicationSourceTitle,
  ApplicationStatusIcons,
  ApplicationSubStatusIcons,
  ContactMethodTypeEmailId,
  ContactMethodTypeLINEId,
  ContactMethodTypeMessengerId,
  ContactMethodTypePhoneSMSId,
  ContactMethodTypeViberId,
  ContactMethodTypeWhatsAppId,
  IApplication,
  IApplicationStatusAgentLog,
  IApplicationStatusCandidateLog,
  IJPLevel,
  IStatusLabels,
  LanguageLocale,
  LanguageLocaleToURLMapper,
} from '../../../models';
import { ApplicationCandidateSubStatusIcons } from '../../../models/application/enum/application-candidate-substatus-icons.enum';
import { ApplicationCandidateSubStatusId } from '../../../models/application/enum/application-candidate-substatus-id.enum';
import { ApplicationCandidateAGGridSubStatusThemes } from '../../../models/application/enum/application-candidate-substatus-themes.enum';
import { StringUtils } from '../../../utils';
import { CandidateGetters } from '../../candidate';
import { ApplicationCandidateGetters } from '../../candidate/getters/application-candidate.getters';
import { S3Bucket } from '../../storage';
import { VacancyGetters } from '../../vacancy';
import { ApplicationAgentStatusLogGetters } from './application-status-agent-log.getters';
import { ApplicationAgentStatusGetters } from './application-status-agent.getters';
import { ApplicationCandidateStatusLogGetters } from './application-status-candidate-log.getters';

export class ApplicationGetters {
  /**
   * Returns the last name of the candidate
   * @param application
   * @returns
   */
  static getCandidateLastName(application: IApplication): string {
    const isValidApplication = application && application.source;
    if (!isValidApplication) return '';
    if (application.applicationCandidate) {
      return ApplicationCandidateGetters.getLastName(application.applicationCandidate!);
    } else {
      return CandidateGetters.getLastName(application.candidate!);
    }
  }

  /**
   * Returns the first name of the candidate
   * @param application
   * @returns
   */
  static getCandidateFirstName(application: IApplication): string {
    const isValidApplication = application && application.source;
    if (!isValidApplication) return '';
    if (application.applicationCandidate) {
      return ApplicationCandidateGetters.getFirstName(application.applicationCandidate!);
    } else {
      return CandidateGetters.getFirstName(application.candidate!);
    }
  }

  /**
   * Returns the nick name of the candidate
   * @param application
   * @returns
   */
  static getCandidateNickName(application: IApplication): string {
    const isValidApplication = application && application.source;
    if (!isValidApplication) return '';
    if (application.applicationCandidate) {
      return ApplicationCandidateGetters.getNickName(application.applicationCandidate!);
    } else {
      return CandidateGetters.getNickName(application.candidate!);
    }
  }

  /**
   * Return the full name of the candidate
   * @param application
   * @returns
   */
  static getCandidateFullName(application: IApplication): string {
    const isValidApplication = application && application.source;
    if (!isValidApplication) return '';
    if (application.applicationCandidate) {
      return ApplicationCandidateGetters.getFullName(application.applicationCandidate!);
    } else {
      return CandidateGetters.getFullName(application.candidate!);
    }
  }

  static getChatName(application: IApplication): string {
    const isValidApplication = application && application.source;
    if (!isValidApplication) return '';
    const candidate = application.candidate;
    if (!candidate) return '';
    // use nickname if available
    const nickname = CandidateGetters.getNickName(candidate);
    if (nickname) return nickname;
    const fullName = ApplicationGetters.getCandidateFullName(application);
    return fullName;
  }

  /**
   * Returns the gender of the candidate
   * @param candidate
   * @returns
   */
  static getGender(application: IApplication, locale: LanguageLocale): string {
    const isValidApplication = application && application.source;
    if (!isValidApplication) return '';
    if (application.applicationCandidate) {
      return ApplicationCandidateGetters.getGender(application.applicationCandidate!, locale);
    } else {
      return CandidateGetters.getGender(application.candidate!, locale);
    }
  }

  /**
   * Returns the birth date of the candidate
   * @param candidate
   * @returns
   */
  static getBirthDate(application: IApplication): string {
    const isValidApplication = application && application.source;
    if (!isValidApplication) return '';
    if (application.applicationCandidate) {
      return ApplicationCandidateGetters.getBirthDate(application.applicationCandidate!);
    } else {
      return CandidateGetters.getBirthDate(application.candidate!);
    }
  }

  /**
   * Returns the country of the candidate
   * @param candidate
   * @returns
   */
  static getCountry(application: IApplication): string {
    const isValidApplication = application && application.source;
    if (!isValidApplication) return '';
    if (application.applicationCandidate) {
      return ApplicationCandidateGetters.getCountry(application.applicationCandidate!);
    } else {
      return CandidateGetters.getCountryName(application.candidate!);
    }
  }

  /**
   * Returns the birth date of the candidate
   * @param candidate
   * @returns
   */
  static getArrivalDate(application: IApplication): string | undefined {
    const isValidApplication = application && application.source;
    if (!isValidApplication) return '';
    if (application.applicationCandidate) {
      return ApplicationCandidateGetters.getArrivalDate(application.applicationCandidate!);
    } else {
      return CandidateGetters.getArrivalDate(application.candidate!);
    }
  }

  /**
   * Returns the prefecture of the candidate
   * @param candidate
   * @returns
   */
  static getPrefecture(application: IApplication): string {
    const isValidApplication = application && application.source;
    if (!isValidApplication) return '';
    if (application.applicationCandidate) {
      return ApplicationCandidateGetters.getPrefecture(application.applicationCandidate!);
    } else {
      return CandidateGetters.getPrefecture(application.candidate!);
    }
  }

  /**
   * Returns the city of the candidate
   * @param candidate
   * @returns
   */
  static getCity(application: IApplication): string {
    const isValidApplication = application && application.source;
    if (!isValidApplication) return '';
    if (application.applicationCandidate) {
      return ApplicationCandidateGetters.getCity(application.applicationCandidate!);
    } else {
      return CandidateGetters.getCity(application.candidate!);
    }
  }

  /**
   * Returns the formatted visa type name of the candidate
   * @param candidate
   * @returns
   */
  static getFormattedVisaName(application: IApplication, defaultText: string): string {
    const isValidApplication = application && application.source;
    if (!isValidApplication) return defaultText;
    if (application.applicationCandidate) {
      return ApplicationCandidateGetters.getFormattedVisaName(application.applicationCandidate!);
    } else {
      return CandidateGetters.getFormattedVisaName(application.candidate!);
    }
  }

  /**
   *
   * @param application
   * @returns
   */
  static getVacancyName(application: IApplication): string {
    if (!application) return '';
    return VacancyGetters.getTitle(application.vacancy!);
  }

  /**
   * Check if the lastest status of the application allows it to be moved to archived tab in aggrid
   * @param application
   * @returns
   */
  static canMoveToArchived(application: IApplication): boolean {
    if (!application) throw new Error('Application is undefined');
    const substatus = ApplicationGetters.getLatestNonMemoAgentSubStatus(application)?.applicationSubStatusAgent;
    if (!substatus) throw new Error('Application substatus is undefined');
    const canMoveToArchived = substatus.canBeArchived;
    if (canMoveToArchived !== true && canMoveToArchived !== false) throw new Error('canBeArchived status is not set');
    const result = canMoveToArchived;
    return result;
  }

  /**
   * Check if the lastest status of the application allows it to be moved to hired tab in aggrid
   * @param application
   * @returns
   */
  static canMoveToHired(application: IApplication): boolean {
    if (!application) throw new Error('Application is undefined');
    const substatus = ApplicationGetters.getLatestNonMemoAgentSubStatus(application)?.applicationSubStatusAgent;
    if (!substatus) throw new Error('Application substatus is undefined');
    const canMoveToHired = substatus.canBeHired;
    if (canMoveToHired !== true && canMoveToHired !== false) throw new Error('canBeHired status is not set');
    const result = canMoveToHired;
    return result;
  }

  /**
   * Get the full status name of the application.
   * @param application - The application object for which the status name is to be retrieved.
   * @returns The full status name in the format "parentLabel - subLabel" or just "parentLabel" if there's no subLabel.
   */
  static getFullStatusName(application: IApplication): string {
    if (!application) return '';

    const lastStatus = ApplicationGetters.getLatestNonMemoAgentStatus(application);
    if (!lastStatus) return '';

    const parentLabel = ApplicationAgentStatusGetters.getName(lastStatus.applicationStatusAgent!);

    // Some statuses do not require a substatus label. In such cases, return only the parent label.
    if (!ApplicationAgentStatusLogGetters.needsSubstatusLabel(lastStatus)) return parentLabel;
    if (!lastStatus.applicationSubStatusAgent) return parentLabel;

    const subLabel = ApplicationAgentStatusGetters.getName(lastStatus.applicationSubStatusAgent!);

    return `${parentLabel} - ${subLabel}`;
  }

  /**
   *
   * @param application
   * @returns
   */
  static getWorkplaceName(application: IApplication): string {
    const hasValidVacancyProp = application && application.vacancy;
    if (!hasValidVacancyProp) return '';
    return VacancyGetters.getWorkplaceName(application.vacancy!);
  }

  /**
   *
   * @todo move the strings to a constants file,
   * Add a languageLocale parameter to support JP and EN
   * @param application
   * @returns
   */
  static getApplicationSourceName(application: IApplication): string {
    if (!application.source) return '';
    const source = application.source as ApplicationSource;
    return ApplicationSourceTitle[source];
  }

  /**
   * Retrieves the specified type of contact information (email or phone SMS) for an applicant.
   * @param application The application object.
   * @param contactMethodId The ID corresponding to the type of contact method (email or phone SMS).
   * @param defaultText The default text to return if no contact information is found.
   * @returns The contact information as a string, or the default text if not found.
   */
  static getContactInformation(application: IApplication, contactMethodId: number, defaultText: string): string {
    let contactStr: string | undefined = undefined;
    if (application.applicationResume) {
      contactStr = application.applicationResume?.contacts?.find((e) => e.contactMethodId === contactMethodId)?.value;
    } else if (application.source === ApplicationSource.Web) {
      contactStr = application.candidate?.user?.contacts?.find((e) => e.contactMethodId === contactMethodId)?.account;
    }
    return contactStr ? contactStr : defaultText;
  }

  /**
   * Returns the email of the applicant.
   * @param application The application object.
   * @param defaultText The default text to return if no email is found.
   * @returns The email of the applicant, or the default text if not found.
   */
  static getApplicationContactInformationEmail(application: IApplication, defaultText: string): string {
    return this.getContactInformation(application, ContactMethodTypeEmailId, defaultText);
  }

  /**
   * Returns the phone SMS of the applicant.
   * @param application The application object.
   * @param defaultText The default text to return if no phone SMS is found.
   * @returns The phone SMS of the applicant, or the default text if not found.
   */
  static getApplicationContactInformationPhoneSMS(application: IApplication, defaultText: string): string {
    return this.getContactInformation(application, ContactMethodTypePhoneSMSId, defaultText);
  }

  /**
   * Returns the line of the applicant
   * @param application
   * @returns
   */
  static getApplicationContactInformationLine(application: IApplication, defaultText: string): string {
    if (!application.source || !application.applicationResume) return defaultText;
    const contact = application.applicationResume?.contacts?.find((e) => e.contactMethodId === ContactMethodTypeLINEId);
    if (!contact) return defaultText;
    return contact.value!;
  }

  /**
   * Returns the viber of the applicant
   * @param application
   * @returns
   */
  static getApplicationContactInformationViber(application: IApplication, defaultText: string): string {
    if (!application.source || !application.applicationResume) return defaultText;
    const contact = application.applicationResume?.contacts?.find((e) => e.contactMethodId === ContactMethodTypeViberId);
    if (!contact) return defaultText;
    return contact.value!;
  }

  /**
   * Returns the messenger of the applicant
   * @param application
   * @returns
   */
  static getApplicationContactInformationMessenger(application: IApplication, defaultText: string): string {
    if (!application.source || !application.applicationResume) return defaultText;
    const contact = application.applicationResume?.contacts?.find((e) => e.contactMethodId === ContactMethodTypeMessengerId);
    if (!contact) return defaultText;
    return contact.value!;
  }

  /**
   * Returns the whats app of the applicant
   * @param application
   * @returns
   */
  static getApplicationContactInformationWhatsApp(application: IApplication, defaultText: string): string {
    if (!application.source || !application.applicationResume) return defaultText;
    const contact = application.applicationResume?.contacts?.find((e) => e.contactMethodId === ContactMethodTypeWhatsAppId);
    if (!contact) return defaultText;
    return contact.value!;
  }

  /**
   * This returns the japanese language information of a candidate.
   * @param application
   * @returns
   */
  static getApplicationJapaneseLevels(application: IApplication): IJPLevel {
    const isValidApplication = application && application.source;
    if (!isValidApplication) {
      return {
        japaneseConversation: {},
      };
    }
    if (application.applicationCandidate) {
      return ApplicationCandidateGetters.getJapaneseLevels(application.applicationCandidate!);
    } else {
      return CandidateGetters.getJapaneseLevels(application.candidate!);
    }
  }

  /**
   * Gets the list of agent status logs
   * @param application
   * @returns
   */
  static getStatusList(application: IApplication): IApplicationStatusAgentLog[] {
    if (!application) return [];
    return application.applicationStatusLogs || [];
  }

  /**
   * Gets the list of candidate status logs
   * @param application
   * @returns
   */
  static getCandidateStatusList(application: IApplication): IApplicationStatusCandidateLog[] {
    if (!application) return [];
    return application.applicationStatusCandidateLogs || [];
  }

  /**
   * Rerturn true if status parent is a 100 (New Application)
   * TODO: Expand as we add more auto log p[arents]
   * @param application
   * @returns
   */
  static getIsNewApplicationLog(application: IApplication): boolean {
    if (!application) return false;
    const lastStatus = ApplicationGetters.getLatestNonMemoAgentStatus(application);
    if (!lastStatus) return false;
    return lastStatus.applicationStatusAgentId === ApplicationAgentStatusId.NewApplication;
  }

  /**
   * Get the appropriate status icon for the given application.
   * @param application - The application object for which the status icon is to be retrieved.
   * @returns The status icon based on the application's status or substatus.
   */
  static getStatusIcon(application: IApplication): string {
    if (!application) return '';

    // An empty string implies that the icon will depend on the substatus.
    const lastStatus = ApplicationGetters.getLatestNonMemoAgentStatus(application);
    if (!lastStatus) return '';

    const parentStatusIcon = ApplicationStatusIcons[lastStatus.applicationStatusAgentId! as ApplicationAgentStatusId];
    if (parentStatusIcon) return parentStatusIcon;

    // If no parent status icon is found, look up the icon associated with the child (sub) status.
    const childStatusIcon = ApplicationSubStatusIcons[lastStatus.applicationSubStatusAgentId! as ApplicationAgentSubStatusId];
    return childStatusIcon || '';
  }

  /**
   * Get the appropriate status icon for the given application.
   * @param application - The application object for which the status icon is to be retrieved.
   * @returns The status icon based on the application's status or substatus.
   */
  static getCandidateStatusIcon(application: IApplication): string {
    if (!application) return '';

    // An empty string implies that the icon will depend on the substatus.
    const lastStatus = ApplicationGetters.getLatestNonMemoCandidateStatus(application);
    if (!lastStatus) return '';

    const parentStatusIcon =
      ApplicationCandidateStatusIcons[lastStatus.applicationStatusCandidateId! as ApplicationCandidateStatusId];
    if (parentStatusIcon) return parentStatusIcon;

    // If no parent status icon is found, look up the icon associated with the child (sub) status.
    const childStatusIcon =
      ApplicationCandidateSubStatusIcons[lastStatus.applicationSubStatusCandidateId! as ApplicationCandidateSubStatusId];
    return childStatusIcon;
  }

  /**
   * Get the appropriate status icon for the given application.
   * @param application
   * @returns
   */
  static getStatusIconS3URL(application: IApplication): string {
    const environment = process.env.APP_ENV as AppEnvironment;
    const bucket = new S3Bucket();
    const bucketName = bucket.resolveBucketName(environment, BucketVisibility.Public);
    const icon = ApplicationGetters.getStatusIcon(application);
    const fullUrl = bucket.resolveOriginalUrl(bucketName, 'icon_pngs/', `${icon}.png`);
    return fullUrl;
  }

  /**
   * Get the appropriate status label (including substatus label if present) for the given application.
   * @param application - The application object for which the status label is to be retrieved.
   * @returns An object with the main status label and substatus label (if applicable).
   */
  static getStatusLabel(application: IApplication): IStatusLabels {
    const lastStatus = ApplicationGetters.getLatestNonMemoAgentStatus(application);
    if (!lastStatus) return { status: '', subStatus: '' };
    return ApplicationAgentStatusLogGetters.getStatusLabel(lastStatus);
  }

  /**
   * Get the appropriate status label (including substatus label if present) for the given application.
   * @param application - The application object for which the status label is to be retrieved.
   * @returns An object with the main status label and substatus label (if applicable).
   */
  static getCandidateStatusLabel(application: IApplication): IStatusLabels {
    const lastStatus = ApplicationGetters.getLatestNonMemoCandidateStatus(application);
    if (!lastStatus) return { status: '', subStatus: '' };
    return ApplicationCandidateStatusLogGetters.getStatusLabel(lastStatus);
  }

  /**
   * Returns a summarized label of the latest memo associated with the application.
   * It fetches the latest memo status, truncates the memo text to a character limit,
   * and appends ellipsis if needed.
   * @param application - The application to find the latest memo label for.
   * @returns - The summarized memo label or '--' if no memo found.
   */
  static getLatestMemoLabel(application: IApplication): string {
    const latestMemoStatus = ApplicationGetters.getLatestStatusWithMemo(application);
    if (!latestMemoStatus) return '--';
    const characterLimit = 9;
    const memo = latestMemoStatus.memo?.trim() || '';
    const memoStr = memo.substring(0, characterLimit);
    return `${memoStr}...`;
  }

  /**
   * Returns themes associated with the latest status of the application.
   * If the latest status has parent themes, those are returned.
   * Otherwise, if the latest status has child (substatus) themes, those are returned.
   * @param application - The application to get status themes for.
   * @returns - Themes associated with the latest status, or an empty string if not found.
   */
  static getAGGridStatusThemes(application: IApplication): string {
    if (!application) return '';
    // An empty string implies that the icon will depend on the substatus.
    const lastStatus = ApplicationGetters.getLatestNonMemoAgentStatus(application);
    if (!lastStatus) return '';
    const parentStatusThemes = ApplicationAGGridStatusThemes[lastStatus.applicationStatusAgentId! as ApplicationAgentStatusId];
    if (parentStatusThemes) return parentStatusThemes;
    const childStatusThemes =
      ApplicationAGGridSubStatusThemes[lastStatus.applicationSubStatusAgentId! as ApplicationAgentSubStatusId];
    return childStatusThemes;
  }

  /**
   * Returns themes associated with the latest status of the application.
   * If the latest status has parent themes, those are returned.
   * Otherwise, if the latest status has child (substatus) themes, those are returned.
   * @param application - The application to get status themes for.
   * @returns - Themes associated with the latest status, or an empty string if not found.
   */
  static getAGGridCandidateStatusThemes(application: IApplication): string {
    if (!application) return '';
    // An empty string implies that the icon will depend on the substatus.
    const lastStatus = ApplicationGetters.getLatestNonMemoCandidateStatus(application);
    if (!lastStatus) return '';
    const parentStatusThemes =
      ApplicationCandidateAGGridStatusThemes[lastStatus.applicationStatusCandidateId! as ApplicationCandidateStatusId];
    if (parentStatusThemes) return parentStatusThemes;
    const childStatusThemes =
      ApplicationCandidateAGGridSubStatusThemes[lastStatus.applicationSubStatusCandidateId! as ApplicationCandidateSubStatusId];
    return childStatusThemes;
  }
  /**
   * Returns themes associated with the latest status of the application.
   * But to be used for card layouts and not statuses
   * @param application - The application to get status themes for.
   * @returns - Themes associated with the latest status, or an empty string if not found.
   */
  static getAGGridCandidateStatusCardThemes(application: IApplication): string {
    if (!application) return '';
    // An empty string implies that the icon will depend on the substatus.
    const lastStatus = ApplicationGetters.getLatestNonMemoCandidateStatus(application);
    if (!lastStatus) return '';
    const cardTheme =
      ApplicationCandidateTimelineStatusThemes[lastStatus.applicationStatusCandidateId! as ApplicationCandidateStatusId];
    return cardTheme;
  }

  /**
   * Returns themes associated with the latest status of the application.
   * If the latest status has parent themes, those are returned.
   * Otherwise, if the latest status has child (substatus) themes, those are returned.
   * @param application - The application to get status themes for.
   * @returns - Themes associated with the latest status, or an empty string if not found.
   */
  static getAGGridStatusIconThemes(application: IApplication): string {
    if (!application) return '';
    // An empty string implies that the icon will depend on the substatus.
    const lastStatus = ApplicationGetters.getLatestNonMemoAgentStatus(application);
    if (!lastStatus) return '';
    const statusIconThemes = ApplicationAGGridStatusIconThemes[lastStatus.applicationStatusAgentId! as ApplicationAgentStatusId];
    return statusIconThemes;
  }

  /**
   * Returns the latest non-memo status of the application.
   * It iterates through the status list in reverse order and returns
   * the first status that is not a memo status.
   * @param application - The application to find the latest non-memo status for.
   * @returns - The latest non-memo status or undefined if not found.
   */
  static getLatestNonMemoAgentStatus(application: IApplication): IApplicationStatusAgentLog | undefined {
    if (!application) return undefined;
    const statusList = ApplicationGetters.getStatusList(application);

    for (let i = statusList.length - 1; i >= 0; i--) {
      if (statusList[i].applicationStatusAgentId !== ApplicationAgentStatusId.Memo) {
        return statusList[i];
      }
    }
    return undefined;
  }
  /**
   * Returns the latest non-memo status of the application.
   * It iterates through the status list in reverse order and returns
   * the first status that is not a memo status.
   * @param application - The application to find the latest non-memo status for.
   * @returns - The latest non-memo status or undefined if not found.
   */
  static getLatestNonMemoAgentSubStatus(application: IApplication): IApplicationStatusAgentLog | undefined {
    if (!application) return undefined;

    const statusList = ApplicationGetters.getStatusList(application);

    for (let i = statusList.length - 1; i >= 0; i--) {
      if (statusList[i].applicationSubStatusAgentId !== ApplicationAgentSubStatusId.Memo) {
        return statusList[i];
      }
    }

    return undefined;
  }

  /**
   * Returns the latest non-memo candidate status of the application.
   * It iterates through the status list in reverse order and returns
   * the first status that is not a memo status.
   * @param application - The application to find the latest non-memo status for.
   * @returns - The latest non-memo status or undefined if not found.
   */
  static getLatestNonMemoCandidateStatus(application: IApplication): IApplicationStatusCandidateLog | undefined {
    if (!application) return undefined;
    const statusList = ApplicationGetters.getCandidateStatusList(application);

    for (let i = statusList.length - 1; i >= 0; i--) {
      if (statusList[i].applicationStatusCandidateId !== ApplicationCandidateStatusId.Memo) {
        return statusList[i];
      }
    }

    return undefined;
  }

  /**
   * Returns the latest status with a memo associated with the application.
   * It iterates through the status list in reverse order and returns
   * the first status with a non-empty memo.
   * @param application - The application to find the latest memo status for.
   * @returns - The latest memo status or undefined if not found.
   */
  static getLatestStatusWithMemo(application: IApplication): IApplicationStatusAgentLog | undefined {
    if (!application) return undefined;
    const statusList = ApplicationGetters.getStatusList(application);

    // The last memo can be from a Memo status or a non-memo status.
    for (let i = statusList.length - 1; i >= 0; i--) {
      if (!statusList[i].memo) continue;
      const memoStr = StringUtils.removeEmptySpace(statusList[i].memo!);
      if (!!memoStr) {
        return statusList[i];
      }
    }

    return undefined;
  }

  /**
   * Retrieves the language locale associated with the user's application.
   *
   * @param application - The user's application object.
   * @returns The language locale for the user's application, or the default 'USen' if not specified.
   */
  static getApplicationLocale(application: IApplication): LanguageLocale {
    // If the application object is not provided, return the default 'USen' locale.
    if (!application) return LanguageLocale.USen;

    // If the 'locale' property is not set in the application object, return the default 'USen' locale.
    if (!application.locale) return LanguageLocale.USen;

    // Otherwise, return the user's specified language locale from the 'locale' property.
    return application.locale as LanguageLocale;
  }

  /**
   * Returns an application shortlink with the pgwk.jp domain.
   * @param application
   * @returns
   */
  static getApplicationShortLink(domain: string, application: IApplication, locale: LanguageLocale): string {
    if (!application || !application.shortUrlId) return '';
    if (locale === LanguageLocale.USen) return `${domain}/a/${application.shortUrlId}`;
    const languageURL = LanguageLocaleToURLMapper[locale];
    return `${domain}/${languageURL}/a/${application.shortUrlId}`;
  }

  /**
   * Returns true of the passed vacancyId is available in the applicationList
   * @param vacancyId
   * @param applicationList
   * @returns
   */
  static hasApplied(vacancyId: number, applicationList: IApplication[]): boolean {
    for (const application of applicationList) {
      if (application.vacancyId == vacancyId) return true;
    }
    return false;
  }

  /**
   * Returns the application id for the given vacancy id.
   * @param applicationList
   * @param vacancyId
   * @returns
   */
  static getApplicationIdForJob(applicationList: IApplication[], vacancyId: number): number | undefined {
    for (const application of applicationList) {
      if (application.vacancyId === vacancyId) return application.id;
    }
    return undefined;
  }
}
