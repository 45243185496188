import { IUserJWT, JWTDecoder, UserType } from '@tokyojob/core'
import { NuxtCookies } from 'cookie-universal-nuxt'
import { guestTokenTTLSeconds } from '~/base/auth/guest-token.config'
import { languageTTLSeconds } from '~/base/auth/language.config'
import { refreshTokenTTLSeconds, tokenTTLSeconds } from '~/base/auth/token.config'

const refreshTokenStr = 'refreshToken'
const accessTokenKey = 'accessToken'
const guestAccessTokenKey = 'guestAccessToken'
const formattedFiltersKey = 'formattedFilters'
const languageKey = 'language'
const redirectURL = 'redirectURL'
const existingUserRedirectURL = 'existingUserRedirectURL'
const onboardingUserProfile = 'onboardingUserProfile'

export class CookieRepository {
  private _token: string | undefined
  private _guestToken: string | undefined

  constructor(private readonly cookies: NuxtCookies, private readonly jwtDecoder: JWTDecoder = new JWTDecoder()) {
    this._token = this.cookies?.get(accessTokenKey)
    this._guestToken = this.cookies?.get(guestAccessTokenKey)
  }

  refresh() {
    this.token = ''
  }

  get token(): string | undefined {
    return this._token
  }
  set token(value: string | undefined) {
    this._token = value
    if (!value || value === '') {
      this.cookies.remove(accessTokenKey)
    } else {
      this.cookies.set(accessTokenKey, value, {
        maxAge: tokenTTLSeconds,
        path: '/'
      })
      this.guestToken = ''
    }
  }

  get guestToken(): string | undefined {
    return this._guestToken
  }
  set guestToken(value: string | undefined) {
    this._guestToken = value
    if (!value || value === '') {
      this.cookies.remove(guestAccessTokenKey)
    } else {
      this.cookies.set(guestAccessTokenKey, value, {
        maxAge: guestTokenTTLSeconds,
        path: '/'
      })
      this.token = ''
    }
  }

  set formattedFilters(value: string) {
    if (value === '') {
      this.cookies.remove(formattedFiltersKey)
    } else {
      this.cookies.set(formattedFiltersKey, value, {
        maxAge: 60 * 60 * 6,
        path: '/'
      })
    }
  }

  get formattedFilters(): string {
    return this.cookies.get(formattedFiltersKey)
  }

  /**
   * JWT used to get a new pair of tokens.
   */
  get refreshToken(): string | undefined {
    return this.cookies.get(refreshTokenStr)
  }

  set refreshToken(value: string | undefined) {
    if (value === '') {
      this.cookies.remove(refreshTokenStr)
    } else {
      this.cookies.set(refreshTokenStr, value, { maxAge: refreshTokenTTLSeconds, path: '/' })
    }
  }

  get language(): string | undefined {
    const lang = this.cookies.get(languageKey)
    return lang
  }

  set language(value: string | undefined) {
    if (value === '') {
      this.cookies.remove(languageKey)
    } else {
      this.cookies.set(languageKey, value, { maxAge: languageTTLSeconds, path: '/' })
    }
  }

  get decodedToken(): IUserJWT | undefined {
    if (!this.token) return undefined
    return this.jwtDecoder.decode(this.token) as IUserJWT
  }

  /**
   * Returns true when the current user is logged in and is a candidate.
   */
  get isLoggedInCandidate(): boolean {
    if (!this.isLoggedIn) return false
    const decodedToken = this.jwtDecoder.decode(this.token!) as IUserJWT
    if (!decodedToken) return false
    const isCandidate = decodedToken.role === UserType.Candidate
    return isCandidate
  }

  get isLoggedInAdmin(): boolean {
    if (!this.isLoggedIn) return false
    const decodedToken = this.jwtDecoder.decode(this.token!) as IUserJWT
    if (!decodedToken) return false
    const isAdmin = decodedToken.role === UserType.Admin
    return isAdmin
  }

  /**
   * Returns true when the current user is logged in and is an agegnt.
   */
  get isLoggedInAgent(): boolean {
    if (!this.isLoggedIn) return false
    const decodedToken = this.jwtDecoder.decode(this.token!) as IUserJWT
    if (!decodedToken) return false
    const isCandidate = decodedToken.role === UserType.Agent
    return isCandidate
  }

  /**
   * @todo Do something a bit fancier instead of just checking token existence and that it is not expired.
   * ex: check that the token is a proper token
   */
  get isLoggedIn(): boolean {
    if (!this.token) return false
    return this.token?.length > 0
  }

  /**
   * If set, the user will be redirected to this URL after logging.
   */
  get redirectURL(): string | undefined {
    return this.cookies.get(redirectURL)
  }

  set redirectURL(value: string | undefined) {
    if (value === '') {
      this.cookies.remove(redirectURL)
    } else {
      this.cookies.set(redirectURL, value, { maxAge: tokenTTLSeconds, path: '/' })
    }
  }

  /**
   * For when a user attempts to sign up but has an existing account.
   * Used in some scenarios that require redirection by the API/
   */
  get redirectURLForExistingUser(): string | undefined {
    return this.cookies.get(existingUserRedirectURL)
  }

  set redirectURLForExistingUser(value: string | undefined) {
    if (value === '') {
      this.cookies.remove(existingUserRedirectURL)
    } else {
      this.cookies.set(existingUserRedirectURL, value, { maxAge: tokenTTLSeconds, path: '/' })
    }
  }

  /**
   * Contains the current onboarding profile
   */
  get onboardingUserProfile(): string {
    return this.cookies.get(onboardingUserProfile)
  }

  set onboardingUserProfile(value: string | undefined) {
    if (value === '') {
      this.cookies.remove(onboardingUserProfile)
    } else {
      this.cookies.set(onboardingUserProfile, value, { maxAge: tokenTTLSeconds, path: '/' })
    }
  }
}
