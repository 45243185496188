import { APIClient } from '../../../base/api/api-client'
import { BaseUsecase } from './usecase'
import { ICookieRepository } from '../../../modules/auth/token/i-cookie-repository'
import { JWTHandler } from '../../../modules/auth/token/jwt-handler'

export abstract class BaseUsecaseAbstract implements BaseUsecase {
  constructor(public client: APIClient, public cookieRepository: ICookieRepository) {
    if (!client) throw new Error('No jwtHandler provided')
    if (!cookieRepository) throw new Error('No jwtHandler provided')
  }

  async execute(...args: any[]): Promise<any> {
    const apiClient = new APIClient(this.cookieRepository)
    const jwtHandler = new JWTHandler(apiClient)
    // Check and refresh token if needed
    const accessToken = this.cookieRepository.token
    const refreshToken = this.cookieRepository.refreshToken
    if (accessToken && refreshToken && jwtHandler.isTokenExpired(accessToken)) {
      const newTokens = await jwtHandler.refreshTokens(this.cookieRepository, refreshToken)
      jwtHandler.storeTokens(this.cookieRepository, newTokens)
    }
    return this.run(...args)
  }

  public abstract run(...args: any[]): Promise<any>
}
