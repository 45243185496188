import { IUser } from '../../user/interface/i-user';
import { CandidateVisaTypeDto } from '../dto/candidate-visa-type.dto';
import { ICandidate, ICandidateVisa } from '../../candidate/interface';

export class CandidateVisaTypeDtoFactory {
  new(user: IUser): CandidateVisaTypeDto {
    return this.createFromCandidateVisa(user.candidate?.candidateVisa);
  }

  newFromICandidate(candidate: ICandidate): CandidateVisaTypeDto {
    return this.createFromCandidateVisa(candidate.candidateVisa);
  }

  createFromCandidateVisa(candidateVisa: ICandidateVisa | undefined): CandidateVisaTypeDto {
    const visaTypeId = candidateVisa?.visaType?.id || candidateVisa?.visaTypeId;
    if (!visaTypeId) throw Error('Expected a non-zero visa type');
    const visaSubTypeId = candidateVisa?.visaSubType?.id || candidateVisa?.visaSubTypeId;

    const dto = new CandidateVisaTypeDto();
    dto.visaTypeId = visaTypeId;
    dto.visaSubTypeId = visaSubTypeId;

    return dto;
  }
}
