import { IsEmail, IsString } from 'class-validator';

/**
 * The required data to request the API to send a new API token to a Messenger User.
 */
export class JWTBroadcastRequestDto {
  /**
   * The expired token where we will fetch the User id from.
   */
  @IsString()
  token: string;
  /**
   * The path that the user was trying to access when this request was generated.
   */
  @IsString()
  path: string;
}
