import { ICustomQueryStringData, IGenericQueryStringProps, IQueryStringProps } from '../options'

export const OptionsPropsFactory = (props?: Partial<IQueryStringProps>): IQueryStringProps => {
  return {
    page: '',
    ...props,
  }
}

/**
 *
 * @todo delete old factory
 * @param props
 * @returns
 */
export const GenericOptionsPropsFactory = <T extends ICustomQueryStringData>(
  props?: Partial<IGenericQueryStringProps & T>
): IGenericQueryStringProps & T =>
  ({
    page: '',
    locale: '',
    ...props,
  } as IGenericQueryStringProps & T)
