import { ICookieRepository, JWTHandler } from '../../../modules/auth'

export enum PollerEvent {
  onPollCompleted = 'onPollCompleted',
}

export class Poller<TStats> {
  private _secondsSinceStart: number = 0
  public intervalFunc: any | null = null
  public readonly eventTarget: EventTarget = new EventTarget()
  private isRunning: boolean = false

  constructor(
    public pollCallback: () => Promise<TStats>,
    public isLoggedIn: () => boolean,
    public jwtHandler: JWTHandler,
    public tokenRepository: ICookieRepository
  ) {
    if (!pollCallback) throw new Error('No poll callback provided')
    if (!isLoggedIn) throw new Error('No isLoggedIn callback provided')
    if (!jwtHandler) throw new Error('No jwtHandler provided')
  }

  getIsRunning(): boolean {
    return this.isRunning
  }

  start(intervalSeconds: number | undefined = 60): void {
    if (this.isRunning) return
    this.isRunning = true
    this.intervalFunc = setInterval(async () => {
      await this.poll()
      this._secondsSinceStart += intervalSeconds
    }, intervalSeconds * 1000)
  }

  public secondsSinceStart(): number {
    return this._secondsSinceStart
  }

  async poll(): Promise<void> {
    if (!this.isLoggedIn()) {
      this.stop()
      return
    }

    // Check and refresh token if needed
    const accessToken = this.tokenRepository.token
    const refreshToken = this.tokenRepository.refreshToken
    if (accessToken && refreshToken && this.jwtHandler.isTokenExpired(accessToken)) {
      const newTokens = await this.jwtHandler.refreshTokens(this.tokenRepository, refreshToken)
      this.jwtHandler.storeTokens(this.tokenRepository, newTokens)
    }

    const result: TStats = await this.pollCallback()
    const event = new CustomEvent(PollerEvent.onPollCompleted, { detail: result })
    this.eventTarget.dispatchEvent(event)
  }

  stop(): void {
    this.isRunning = false
    if (this.intervalFunc) {
      clearInterval(this.intervalFunc)
      this.intervalFunc = null
    }
  }

  onPollCompleted(callback: (stats: TStats) => void): void {
    this.eventTarget.addEventListener(PollerEvent.onPollCompleted, (event) => {
      const items = (event as CustomEvent).detail as TStats
      callback(items)
    })
  }
}
