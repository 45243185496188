
import { defineComponent, onBeforeUnmount, onMounted } from 'vue'
import { HtmlAttributesFactory } from '@tokyojob/frontend-core'
import authenticated from '~/middleware/authenticated'
import CSidebar from '~/modules/app/components/app/c-sidebar/c-sidebar.component.vue'
import CTopbar from '~/modules/app/components/app/c-topbar/c-topbar.component.vue'
import { CandidateDashboardStatsComposable } from '~/modules/user/composables/candidate-dashboard-stats.composable'
import CCandidateFooter from '../modules/app/components/app/footer/c-candidate-footer.component.vue'
import CFloatingNotifications from '~/modules/user/components/c-floating-notifications.component.vue'

export default defineComponent({
  components: {
    CSidebar,
    CTopbar,
    CCandidateFooter,
    CFloatingNotifications
  },
  middleware: [authenticated],
  setup() {
    const { initPoller, stopPoller } = CandidateDashboardStatsComposable()
    onMounted(async () => {
      initPoller()
    })
    onBeforeUnmount(() => {
      stopPoller()
    })
  },
  head() {
    return {
      htmlAttrs: HtmlAttributesFactory.basicAttributes(this.App.localizationSelector.languageISO6391)
    }
  }
})
