
import { defineComponent, reactive, ref } from 'vue'
import { CMSLinkFactory, IJoomlaFooterLink, LocalizationData, LocalizationPath } from '@tokyojob/core'
import { ButtonFactory, CButton, getLocalization } from '@tokyojob/frontend-components'
import { AppEnvironment, LocalizationService, StaticURLBuilder } from '@tokyojob/frontend-core'
import { getProvidedApp } from '~/base/utils/provided-getter'
import { getSideMenuContentStore } from '~/store/side-menu.store'

export default defineComponent({
  components: {
    CButton,
  },
  setup() {
    const app = getProvidedApp()
    const localization: LocalizationData = getLocalization(app)

    const footerJoomlaLinks = ref<IJoomlaFooterLink[]>([])
    const subscribeBtn = reactive(
      ButtonFactory.button({
        classes: 'row-center theme-shades-light1-white br-5 align-center',
        width: '100%',
        height: '30px',
        label: LocalizationService.t<string>(localization, LocalizationPath.subscribe),
        link: { link: '' },
      })
    )

    const joomlaSidemenuRefresh = async () => {
      const sideMenuContentStore = getSideMenuContentStore()
      const locale = app.localizationSelector.languageLocale
      const joomlaLayoutContent = sideMenuContentStore.get(locale)
      footerJoomlaLinks.value = CMSLinkFactory.getFooterLinks(joomlaLayoutContent)
      subscribeBtn.link!.link = CMSLinkFactory.getNewsletterLink(joomlaLayoutContent)
    }
    joomlaSidemenuRefresh()

    const ltbUrl = 'www.livetobusiness.co.jp'
    const logoWhite = StaticURLBuilder.logoWhite(process.env.APP_ENV as AppEnvironment)
    const tokyoJobSummaryText = ref(LocalizationService.t<string>(localization, LocalizationPath.tokyoJobSummary))
    const jobsNewsletter = ref(LocalizationService.t<string>(localization, LocalizationPath.jobsNewsletter))
    const jobsNewsletterBody = ref(LocalizationService.t<string>(localization, LocalizationPath.jobsNewsletterBody))

    return {
      jobsNewsletter,
      jobsNewsletterBody,
      logoWhite,
      tokyoJobSummaryText,
      ltbUrl,
      subscribeBtn,
      footerJoomlaLinks,
    }
  },
})
