import fontkit from '@pdf-lib/fontkit'
import { PDFDocument, PDFTextField, TextAlignment } from 'pdf-lib'
import { getBufferStore } from '../../../store/buffer.store'

export interface IPDFData {
  value: string
  alignment?: TextAlignment
  fontSize?: number,
}

export class PDFGenerator {
  readonly fileType: string = 'application/pdf'

  async generatePDF(
    formPdfBytes: ArrayBuffer,
    fontBytes: ArrayBuffer,
    information: Record<string, IPDFData>,
    outputFilename: string,
    isReadOnly: boolean
  ): Promise<void> {
    const pdfDoc = await PDFDocument.load(formPdfBytes)
    pdfDoc.registerFontkit(fontkit)
    const font = await pdfDoc.embedFont(fontBytes)
    const form = pdfDoc.getForm()
    const fields = form.getFields()

    await Promise.all(
      fields.map(async (f) => {
        if (f instanceof PDFTextField) {
          const fieldName = f.getName().trim()

          if (fieldName === 'photo') {
            const url = information.photo?.value
            if (url) {
              const bufferStore = getBufferStore()
              let bufferData = bufferStore.getCache(url)
              let photoBytes = undefined
              if (!bufferData) {
                await bufferStore.fetchData(url)
                bufferData = bufferStore.getCache(url)
                photoBytes = bufferData.result
              } else if (bufferData.fetchPromise) {
                photoBytes = await bufferData.fetchPromise
              } else {
                photoBytes = bufferData.result
              }
              const photo = url.includes('.png') ? await pdfDoc.embedPng(photoBytes!) : await pdfDoc.embedJpg(photoBytes!)
              f.setImage(photo)
            }
          } else {
            const data = information[fieldName]
            if (data) {
              let text = data.value
              // These 2 fields are the only ones that are multiline. If we do not insert linebreaks,
              // they overflow in the PDF.
              if (fieldName === 'personal_preference' || fieldName === 'reasons_to_apply') {
                const fieldWidth = this.getFieldWidth(f)
                const fontSize = data.fontSize || 12 // Use the specified font size or default to 12
                text = this.insertLineBreaks(text, fieldWidth, fontSize)
              }
              f.setText(text)
              if (data.alignment !== undefined) {
                f.setAlignment(data.alignment)
              }
              if (data.fontSize !== undefined) {
                f.setFontSize(data.fontSize)
              }
              f.updateAppearances(font)
            }
          }

          if (isReadOnly) {
            f.enableReadOnly()
          }
        }
      })
    )

    const pdfBytes = await pdfDoc.save()

    const blob = new Blob([pdfBytes], { type: this.fileType })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = outputFilename
    document.body.appendChild(a)
    a.click()

    document.body.removeChild(a)
    URL.revokeObjectURL(url)
  }

  isHalfWidth(char: string): boolean {
    const halfWidthRegex = /[\u0020-\u007E\uFF61-\uFFDC\uFFE8-\uFFEE]/
    return halfWidthRegex.test(char)
  }

  insertLineBreaks(text: string, maxWidth: number, fontSize: number): string {
    let result = ''
    let currentWidth = 0
    const fullWidthCharWidth = fontSize * 1 // Approximation for full-width characters
    const halfWidthCharWidth = fontSize * 0.5 // Approximation for half-width characters

    for (let i = 0; i < text.length; i++) {
      const char = text[i]
      const charWidth = this.isHalfWidth(char) ? halfWidthCharWidth : fullWidthCharWidth
      currentWidth += charWidth

      if (currentWidth > maxWidth) {
        result += '\n'
        currentWidth = charWidth // Start new line with the current character's width
      }

      result += char
    }

    return result
  }

  getFieldWidth(field: PDFTextField): number {
    const widgets = field.acroField.getWidgets()
    if (widgets.length > 0) {
      const rect = widgets[0].getRectangle()
      return rect.width
    }
    return 0
  }
}
