import { IsNumber, IsOptional, IsString } from 'class-validator';

export class AnonymousApplicationWithdrawDto {
  /**
   * The status id of the reason of withdrawal
   */
  @IsNumber()
  statusId: number;

  /**
   * Additional data of the withdrawal
   */
  @IsOptional()
  @IsString()
  reason: string;
}
