import {
  IPicture,
  IResume,
  IResumeContractType,
  IResumeEducation,
  IResumeEducationLevel,
  IResumeJobHistory,
  IResumeQualification,
  LanguageLocale
} from '@tokyojob/core'
import { APIRequest, HTTPMethod } from '@tokyojob/frontend-core'

/**
 * Execute an API request to get the currently signed in candidate's resumes.
 */
export class GetResumesRequest implements APIRequest<IResume[]> {
  response!: IResume[]
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor() {
    this.path = '/resume'
  }
}

/**
 * Execute an API request to get 1 specific resume owned by the currently signed in candidate.
 */
export class GetResumeRequest implements APIRequest<IResume> {
  response!: IResume
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(id: number, locale: LanguageLocale) {
    this.path = `/resume/${id}?locale=${locale}`
  }
}

/**
 * Execute an API request to get all education level with a specified locale
 */
export class GetResumeEducationLevelRequest implements APIRequest<IResumeEducationLevel[]> {
  response!: IResumeEducationLevel[]
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(locale: LanguageLocale) {
    this.path = `/resume-education-level?locale=${locale}`
  }
}

export class GetResumeContractTypeRequest implements APIRequest<IResumeContractType[]> {
  response!: IResumeContractType[]
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(locale: LanguageLocale) {
    this.path = `/resume-contract-type?locale=${locale}`
  }
}

// /**
//  * Execute an API request to set 1 resume as the candidate's default resume.
//  * Only 1 resume may be the default one at any given time.
//  */
// export class SetResumeAsDefaultRequest
//   implements APIRequest<SpaceResume.PutResumeDefaultResponse> {
//   response!: SpaceResume.PutResumeDefaultResponse
//   path: string
//   contentType = 'application/json'
//   method = HTTPMethod.PUT
//   constructor(id: number) {
//     this.path = `/resume/${id}/default`
//   }
// }

/**
 * Execute an API request to create a new a resume belonging to the currently signed in candidate.
 */
export class PostResume implements APIRequest<IResume> {
  response!: IResume
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: IResume
  constructor(seed: IResume) {
    this.params = seed
    this.path = '/resume'
  }
}

/**
 * Execute an API request to update a resume belonging to the currently signed in candidate.
 */
export class PutResume implements APIRequest<IResume> {
  response!: IResume
  path: string
  contentType = 'application/json'
  method = HTTPMethod.PUT
  params: IResume
  constructor(id: number, seed: IResume) {
    this.params = seed
    this.path = `/resume/${id}`
  }
}

/**
 * Execute an API request to update the file information of a resume.
 */
export class UpdateFileInfoRequest implements APIRequest<boolean> {
  response!: boolean
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: IResume
  constructor(id: number, seed: IResume) {
    this.params = seed
    this.path = `/resume/${id}/create-file-info`
  }
}

/**
 * Execute an API request to update the "About You" section of a resume.
 */
export class UpdateAboutYouRequest implements APIRequest<boolean> {
  response!: boolean
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: IResume
  constructor(id: number, seed: IResume) {
    this.params = { ...seed, nationalityCountryId: seed.nationalityCountry?.id }
    this.path = `/resume/${id}/about-you`
  }
}

export class UpdateAboutYouTranslationRequest implements APIRequest<boolean> {
  response!: boolean
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: IResume
  constructor(id: number, seed: IResume) {
    this.params = seed
    this.path = `/resume-translation/${id}/about-you`
  }
}

/**
 * Execute an API request to update the "Contact and Address" section of a resume.
 */
export class UpdateContactAndAddressRequest implements APIRequest<boolean> {
  response!: boolean
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: IResume
  constructor(id: number, seed: IResume) {
    this.params = { ...seed }
    this.path = `/resume/${id}/contact-and-address`
  }
}

export class UpdateContactAndAddressTranslationRequest implements APIRequest<boolean> {
  response!: boolean
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: IResume
  constructor(id: number, seed: IResume) {
    this.params = seed
    this.path = `/resume-translation/${id}/contact-and-address`
  }
}

/**
 * Execute an API request to update the "Visa" section of a resume.
 */
export class UpdateVisaRequest implements APIRequest<boolean> {
  response!: boolean
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: IResume
  constructor(id: number, seed: IResume) {
    this.params = seed
    this.path = `/resume/${id}/visa`
  }
}

/**
 * Execute an API request to update the "Education" section of a resume.
 */
export class UpdateEducationRequest implements APIRequest<IResumeEducation[]> {
  response!: IResumeEducation[]
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: IResume
  constructor(id: number, seed: IResume) {
    this.params = seed
    this.path = `/resume/${id}/education`
  }
}

/**
 * Execute an API request to update the "Education" section of a resume.
 */
export class UpdateEducationTranslationRequest implements APIRequest<IResumeEducation[]> {
  response!: IResumeEducation[]
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: IResume
  constructor(id: number, seed: IResume) {
    this.params = seed
    this.path = `/resume-translation/${id}/education`
  }
}

/**
 * Execute an API request to update the "Work Experience" section of a resume.
 */
export class UpdateWorkExperienceRequest implements APIRequest<IResumeJobHistory[]> {
  response!: IResumeJobHistory[]
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: IResume
  constructor(id: number, seed: IResume) {
    this.params = seed
    this.path = `/resume/${id}/work-experience`
  }
}

export class UpdateWorkExperienceTranslationRequest implements APIRequest<IResumeJobHistory[]> {
  response!: IResumeJobHistory[]
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: IResume
  constructor(id: number, seed: IResume) {
    this.params = seed
    this.path = `/resume-translation/${id}/work-experience`
  }
}

/**
 * Execute an API request to update the "Qualification" section of a resume.
 */
export class UpdateQualificationRequest implements APIRequest<IResumeQualification[]> {
  response!: IResumeQualification[]
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: IResume
  constructor(id: number, seed: IResume) {
    this.params = seed
    this.path = `/resume/${id}/qualification`
  }
}

export class UpdateQualificationTranslationRequest implements APIRequest<IResumeQualification[]> {
  response!: IResumeQualification[]
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: IResume
  constructor(id: number, seed: IResume) {
    this.params = seed
    this.path = `/resume-translation/${id}/qualification`
  }
}

/**
 * Execute an API request to update the "Reason To Apply" section of a resume.
 */
export class UpdateReasonToApplyRequest implements APIRequest<boolean> {
  response!: boolean
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: IResume
  constructor(id: number, seed: IResume) {
    this.params = seed
    this.path = `/resume/${id}/reason-to-apply`
  }
}

export class UpdateReasonToApplyTranslationRequest implements APIRequest<boolean> {
  response!: boolean
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: IResume
  constructor(id: number, seed: IResume) {
    this.params = seed
    this.path = `/resume-translation/${id}/reason-to-apply`
  }
}

/**
 * Execute an API request to update the "Personal Requests" section of a resume.
 */
export class UpdatePersonalRequestsRequest implements APIRequest<boolean> {
  response!: boolean
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: IResume
  constructor(id: number, seed: IResume) {
    this.params = seed
    this.path = `/resume/${id}/personal-requests`
  }
}

export class UpdatePersonalRequestsTranslationRequest implements APIRequest<boolean> {
  response!: boolean
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: IResume
  constructor(id: number, seed: IResume) {
    this.params = seed
    this.path = `/resume-translation/${id}/personal-requests`
  }
}

/**
 * Executes an API request to delete a resume.
 */
export class DeleteResumeRequest implements APIRequest<boolean> {
  response!: boolean
  path: string
  contentType = 'application/json'
  method = HTTPMethod.DELETE
  constructor(id: number) {
    this.path = `/resume/${id}`
  }
}

/**
 * Execute an API request to delete the "Education" section of a resume.
 */
export class DeleteResumeEducationRequest implements APIRequest<boolean> {
  response!: boolean
  path: string
  contentType = 'application/json'
  method = HTTPMethod.DELETE
  params: { ids: number[] }
  constructor(id: number, ids: number[]) {
    this.params = { ids }
    this.path = `/resume/${id}/education`
  }
}

/**
 * Execute an API request to delete the "Work Experience" section of a resume.
 */
export class DeleteResumeWorkExperienceRequest implements APIRequest<boolean> {
  response!: boolean
  path: string
  contentType = 'application/json'
  method = HTTPMethod.DELETE
  params: { ids: number[] }
  constructor(id: number, ids: number[]) {
    this.params = { ids }
    this.path = `/resume/${id}/work-experience`
  }
}

/**
 * Execute an API request to delete the "Work Experience" section of a resume.
 */
export class DeleteResumeQualificationRequest implements APIRequest<boolean> {
  response!: boolean
  path: string
  contentType = 'application/json'
  method = HTTPMethod.DELETE
  params: { ids: number[] }
  constructor(id: number, ids: number[]) {
    this.params = { ids }
    this.path = `/resume/${id}/qualification`
  }
}

export class ResumeNotificationRequest implements APIRequest<void> {
  response!: void
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  constructor(id: number) {
    this.path = `/resume/${id}/notification`
  }
}

export class UpdatePictureRequest implements APIRequest<IPicture> {
  response!: IPicture
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: FormData
  constructor(id: number, params: FormData) {
    this.params = params
    this.path = `/resume/${id}/photo`
  }
}

export class DeletePictureRequest implements APIRequest<boolean> {
  response!: boolean
  path: string
  contentType = 'application/json'
  method = HTTPMethod.DELETE
  constructor(id: number) {
    this.path = `/resume/${id}/photo`
  }
}

export class ResumeFinishBuilderRequest implements APIRequest<boolean> {
  params?: IResume
  response!: boolean
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  constructor(id: number, params: IResume) {
    this.params = params
    this.path = `/resume/${id}/finish-builder`
  }
}
