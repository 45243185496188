import {
  IApplicationStatusAgent,
  IApplicationStatusAgentI18n,
  IFilterableDataSetContextualDictionary
} from '../../../models';
import {
  ApplicationAgentStatusHidden,
  ApplicationAgentSubStatusHidden,
} from '../../../models/application/enum/application-agent-status-visibility.enum';

export class ApplicationAgentStatusGetters {
  static getName(status: IApplicationStatusAgent): string {
    const i18n = this.getI18n(status);
    if (!i18n) return '';
    return i18n.name!;
  }

  static getDescription(status: IApplicationStatusAgent): string {
    const i18n = this.getI18n(status);
    if (!i18n) return '';
    return i18n.description!;
  }

  static getI18n(status: IApplicationStatusAgent): IApplicationStatusAgentI18n | undefined {
    if (!status || !status.i18n || !status.i18n.length) return undefined;
    return status.i18n[0];
  }

  /**
   *
   * @param hierarchy
   */
  static filterHiddenStatuses(hierarchy: IFilterableDataSetContextualDictionary): IFilterableDataSetContextualDictionary {
    const filteredHierarchy: IFilterableDataSetContextualDictionary = {};

    for (const itemId in hierarchy) {
      if (!hierarchy.hasOwnProperty(itemId)) continue;
      const itemIdCast = Number(itemId);
      if (this.shouldIncludeItem(itemId, hierarchy)) {
        filteredHierarchy[itemIdCast] = { ...hierarchy[itemIdCast] };

        if (hierarchy[itemIdCast].children) {
          const currentChildren = hierarchy[itemIdCast].children!;
          filteredHierarchy[itemIdCast].children = this.filterHiddenStatuses(currentChildren);
        }
      }
    }

    return filteredHierarchy;
  }

  static shouldIncludeItem(itemId: string, hierarchy: IFilterableDataSetContextualDictionary): boolean {
    const item = hierarchy[itemId];

    if (ApplicationAgentStatusHidden[Number(itemId)]) {
      return false;
    }

    if (item.children) {
      for (const childId in item.children) {
        if (!item.children.hasOwnProperty(childId)) continue;
        if (ApplicationAgentSubStatusHidden[Number(childId)]) {
          return false;
        }
      }
    }

    return true;
  }
}
