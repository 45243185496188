import { CandidateEntityFactory } from '../../candidate/factories/candidate.factory'
import { CandidateEntity } from '../../candidate/entities/candidate'
import { ContactEntity, ContactEntityFactory } from '../../contact/entities/contact.entity'
import { IFormInputData } from '../../html/forms/inputs/interfaces/form-input-data'
import { IUser, UserType } from '../../../../../../core/src'

export class UserEntity {
  private _props: IUser
  private _candidateEntity: CandidateEntity
  private _contactEntities: ContactEntity[]
  private candidateFactory = new CandidateEntityFactory()

  constructor(props: IUser) {
    this._props = props
  }

  get props(): IUser {
    return this._props
  }

  get id(): number {
    return this.props.id!
  }

  get firstName(): string {
    return this.props.firstName!
  }

  set firstName(value: string) {
    this.props.firstName = value
  }

  get lastName(): string {
    return this.props.lastName!
  }
  set lastName(value: string) {
    this.props.lastName = value
  }

  get nickname(): string {
    return this.props.nickname!
  }

  set nickname(value: string) {
    this.props.nickname = value
  }

  get fullName(): string {
    return `${this.lastName} ${this.firstName}`
  }

  get email(): string {
    return this.props.email!
  }

  set email(value: string) {
    this.props.email = value
  }

  get password(): string {
    return this.props.password!
  }

  set password(value: string) {
    this.props.password = value
  }

  /**
   * The gender of the user
   */
  get gender(): string | undefined {
    return this.props.gender
  }

  set gender(value: string | undefined) {
    this.props.gender = value
  }

  get genderFormData(): IFormInputData | undefined {
    if (!this.gender) return undefined
    return {
      value: this.gender,
      label: this.gender,
    }
  }

  set genderFormData(value: IFormInputData | undefined) {
    this.gender = value ? value.value : undefined
  }

  /**
   * The visa information of the candidate
   */
  get candidate(): CandidateEntity {
    if (!this._candidateEntity) {
      this._candidateEntity = this.candidateFactory.new(this.props.candidate)
    }
    return this._candidateEntity
  }

  set candidate(value: CandidateEntity) {
    this.props.candidate = value?.props
    this._candidateEntity = value
  }

  /**
   * Gets the candidate's contact information as saved in their profile.
   */
  get contactInformation(): ContactEntity[] {
    if (!this._contactEntities || this._contactEntities.length === 0) {
      if (this.props.contactInformation) {
        this._contactEntities = this.props.contactInformation.map((e) => ContactEntityFactory.new(e))
      } else {
        this._contactEntities = []
      }
    }
    return this._contactEntities
  }

  /**
   * Sets the candidate's contact information.
   */
  set contactInformation(value: ContactEntity[]) {
    this.props.contactInformation = value.map((e) => e.props)
    this._contactEntities = value
  }

  /**
   * The user type of the candidate
   * This should be Candidate
   */
  get userType(): UserType | undefined {
    return this.props.userType
  }

  /**
   * Returns true if the user is a candidate
   */
  get isCandidate(): boolean {
    return this.userType === UserType.Candidate
  }
}
