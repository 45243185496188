import { IsBooleanString, IsDecimal, IsDefined, IsNumberString, IsOptional, IsString } from 'class-validator';
import { LanguageLocale } from '../../language/constants/language-locale.enum';

export class VacancyMapQueryDto {
  /**
   * The session id of the user
   */
  @IsOptional()
  @IsString()
  sessionId: string;

  @IsOptional()
  locale: LanguageLocale;

  /**
   * The longitude value of the lower left corner of the map viewport.
   * @link https://developers.google.com/maps/documentation/javascript/reference/map#Map.getBounds
   */
  @IsDefined()
  @IsDecimal()
  lowerLeftLng: number;

  /**
   * The latitude value of the lower left corner of the map viewport.
   * @link https://developers.google.com/maps/documentation/javascript/reference/map#Map.getBounds
   */
  @IsDefined()
  @IsDecimal()
  lowerLeftLat: number;

  /**
   * The longitude value of the upper right corner of the map viewport.
   * @link https://developers.google.com/maps/documentation/javascript/reference/map#Map.getBounds
   */
  @IsDefined()
  @IsDecimal()
  upperRightLng: number;

  /**
   * The latitude value of the upper right corner of the map viewport.
   * @link https://developers.google.com/maps/documentation/javascript/reference/map#Map.getBounds
   */
  @IsDefined()
  @IsDecimal()
  upperRightLat: number;

  /**
   * The visa type that is allowed on the vacancy.
   */
  @IsOptional()
  @IsNumberString()
  visaTypeId: number;

  /**
   * The industry id that the vacancy must belong to.
   * @remarks When the jobSectorId is also provided, that one takes precedence over industry.
   */
  @IsOptional()
  @IsNumberString()
  industryId: number;

  /**
   * The job sector id that the vacancy must belong to.
   * @remarks When the industryId is also provided, this one takes precedence over industry.
   */
  @IsOptional()
  @IsNumberString()
  jobSectorId: number;

  /**
   * The contract type that the vacancy must be set to.
   * @remarks When the cityId is also provided, that one takes precedence over prefecture.
   * @todo Might require rework because the way contract types work will likely be updated in the future.
   */
  @IsOptional()
  @IsNumberString()
  contractTypeId: number;

  /**
   * Precision to use with the vacancies' coordinates.
   * High precision can result in vacancies being so close to each other
   * that they overlap each other on the map, or
   * in clusters not being split at some zoom levels.
   */
  @IsOptional()
  decimalPrecision: number = 5;

  /**
   * True if the request came from SSR, false if it came from when the component/page is already mounted.
   */
  @IsBooleanString()
  isSSRRequest: string;

  /**
   * The japanese level requirement set in the filter
   */
  @IsOptional()
  @IsNumberString()
  japaneseConversationId: number;
}
