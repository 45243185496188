import JapaneseReading from '../network/schema/models/japanese.reading'

/**
 * Interface used as a base when reading the Candidate's Japanese reading skills data.
 */
export interface IJapaneseReadingProps extends JapaneseReading {}

/**
 * Entity containing a candidate's reading skills.
 * TODO: Implement proper i18n for all translatable props
 */
export default class JapaneseReadingEntity {
  private _props: IJapaneseReadingProps

  constructor(props: IJapaneseReadingProps) {
    this._props = props
  }

  /**
   * Raw properties of the entity. Used in cases when the data provided by the entity's getters and setters are insufficient.
   * For most cases, consider adding another getter / setter instead of interacting directly with the props.
   */
  get props(): IJapaneseReadingProps {
    return this._props
  }

  /**
   * The id of the reading  skill.
   */
  get id(): number {
    return this.props.id
  }

  /**
   * The name of the reading  skill.
   */
  get name(): string {
    if(this.props.info?.length > 0) return this.props.info[0].name
    return ''
  }

  /**
   * Explains what this item is.
   */
  get description(): string | null {
    if(this.props.info?.length > 0) return this.props.info[0].description || null
    return ''
    
  }

  // getByLanguage(locale: string): JapaneseReadingInfo {
  //   const infos = this._props.informations.filter((information) => {
  //     return information.locale === locale
  //   })
  //   return infos[0] || null
  // }

  // getJapanese(): JapaneseReadingInfo {
  //   return this.getByLanguage('JA-jp')
  // }
}

/**
 * Generates an IJapaneseReadingProps object. Used to add extra properties during the conversion process.
 * @param props Data to use when creating the object.
 */
export const JapaneseReadingPropsFactory = (
  props: IJapaneseReadingProps
): IJapaneseReadingProps => ({
  ...props
})

/**
 * Generates a JapaneseReadingEntity object.
 * @param props Data to use when creating the object.
 */
export const JapaneseReadingEntityFactory = (
  props: IJapaneseReadingProps
): JapaneseReadingEntity => {
  return new JapaneseReadingEntity(JapaneseReadingPropsFactory(props))
}
