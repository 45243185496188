import { JWTHandler } from '../../modules/auth/token/jwt-handler'
import { ICookieRepository } from '../../modules/auth/token/i-cookie-repository'
import { BaseUsecase } from '../../modules/usecases/base/usecase'
import { APIClient } from '../api/api-client'
import { UserType } from '../../../../../core/src/models/user/constants/user-type.enum'

export abstract class BaseUsecaseImpl implements BaseUsecase {
  protected apiClient: APIClient
  protected jwtHandler: JWTHandler
  windowRedir = (url: string) => {
    window.location.href = url
  }

  constructor(protected state: ICookieRepository, protected userType: UserType) {
    this.apiClient = new APIClient(state)
    this.jwtHandler = new JWTHandler(this.apiClient)
  }

  abstract execute(...params: any): Promise<any>

  async evaluateAuth(): Promise<void> {
    await this.jwtHandler.evaluateAuth(this.state, (url: string) => {
      window.location.href = url
    }, this.userType, true)
  }
}
