import { ICandidate } from '../../../models';
import { CompositeSpecification } from '../../base/specification';
import { VisaTypeRequiresDesignationPapersSpecification } from '../../visa-type/specifications/visa-type-requires-designation-papers.specification';
import { VisaTypeRequiresResidenceCardSpecification } from '../../visa-type/specifications/visa-type-requires-residence-card.specification';
import { CandidateHasDesignationPapersSpecification } from './candidate-has-designation-papers.specification';
import { CandidateHasResidenceCardBackSpecification } from './candidate-has-residence-card-back.specification';
import { CandidateHasResidenceCardFrontSpecification } from './candidate-has-residence-card-front.specification';

/**
 * Whether the provided candidate has all the required paperwork, depending on the visa type they are using.
 * @remarks This does not apply to any one specific vacancy, rather it just checks that
 * the document attachments that each visa type legally require, exist.
 */
export class CandidateHasRequiredVisaPaperworkSpecification extends CompositeSpecification<ICandidate> {
  public requiresDesignationPapersSpecification = new VisaTypeRequiresDesignationPapersSpecification();
  public hasDesignationPapersSpecification = new CandidateHasDesignationPapersSpecification();
  public requiresResidenceCardSpecification = new VisaTypeRequiresResidenceCardSpecification();
  public hasResidenceCardFront = new CandidateHasResidenceCardFrontSpecification();
  public hasResidenceCardBack = new CandidateHasResidenceCardBackSpecification();

  isSatisfiedBy(candidate: ICandidate): boolean {
    const objectIncludesVisaType =
      candidate.candidateVisa && candidate.candidateVisa.visaType && candidate.candidateVisa.visaType.id;
    if (!objectIncludesVisaType) return false;

    const visaType = candidate.candidateVisa!.visaType!;
    const requiresDesignationPapers = this.requiresDesignationPapersSpecification.isSatisfiedBy(visaType);
    if (requiresDesignationPapers) {
      const hasSpecificationPapers = this.hasDesignationPapersSpecification.isSatisfiedBy(candidate);
      if (!hasSpecificationPapers) return false;
    }

    const requiresResidenceCard = this.requiresResidenceCardSpecification.isSatisfiedBy(visaType);
    if (requiresResidenceCard) {
      const canApplyToVacancy = this.hasResidenceCardFront.and(this.hasResidenceCardBack);
      return canApplyToVacancy.isSatisfiedBy(candidate);
    }

    return true;
  }
}
