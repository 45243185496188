import {
  AnonymousApplicationDto,
  AnonymousApplicationVerifyPhoneDto,
  AnonymousApplicationVerifyVisaTypeDto,
  AnonymousApplicationWithdrawDto,
  ApplicationCandidateCreateDto,
  ApplicationStatusGuestCandidateLogDto,
  IApplication,
  LanguageLocale,
  TransferApplicationsDto
} from '@tokyojob/core'
import { APIRequest, ContentTypes, HTTPMethod, QueryStringEntity } from '@tokyojob/frontend-core'
import { SpaceApplication } from '../schema/namespace'

const applicationEndpoint = '/application'
const applicationStatusCandidateEndpoint = '/application-status-candidate'
const applicationStatusCandidateLogEndpoint = '/application-status-candidate-log'

export class ApplyVacancyRequest implements APIRequest<SpaceApplication.ApplyResponse> {
  response!: SpaceApplication.ApplyResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: SpaceApplication.ApplyRequest
  constructor(seed: IApplication) {
    this.params = seed
    this.path = `${applicationEndpoint}/`
  }
}

export class MyApplicationsRequest implements APIRequest<SpaceApplication.MyApplicationListResponse> {
  response!: SpaceApplication.MyApplicationListResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(locale: string, getAll?: boolean) {
    this.path = `${applicationEndpoint}/candidate-user/?locale=${locale}&archived=${getAll}`
  }
}

export class CandidateApplicationIdAndVacancyIdListRequest
  implements APIRequest<SpaceApplication.CandidateApplicationIdAndVacancyIdListResponse> {
  response!: SpaceApplication.CandidateApplicationIdAndVacancyIdListResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor() {
    this.path = `${applicationEndpoint}/candidate-application-id-and-vacancy-id-list`
  }
}

export class MyApplicationSingleRequest implements APIRequest<SpaceApplication.MyApplicationSingleResponse> {
  response!: SpaceApplication.MyApplicationSingleResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(locale: string, appId: string) {
    this.path = `${applicationEndpoint}/candidate-user/${appId}?locale=${locale}`
  }
}

export class GetRequest implements APIRequest<SpaceApplication.GetResponse> {
  response!: SpaceApplication.GetResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(id: string) {
    this.path = `${applicationEndpoint}/candidate/${id}`
  }
}

/**
 * Get list of all applications for a phone number
 */
export class GetGuestApplicationListRequest implements APIRequest<SpaceApplication.GetGuestApplicationListResponse> {
  response!: SpaceApplication.GetGuestApplicationListResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(locale: string, getAll?: boolean) {
    this.path = `/application-guest/list?locale=${locale}&archived=${getAll}`
  }
}
/**
 * Get one specific application for a phone number
 */
export class GetGuestApplicationSingleRequest implements APIRequest<SpaceApplication.GetGuestApplicationSingleResponse> {
  response!: SpaceApplication.GetGuestApplicationSingleResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(locale: string, appId: string, shortUrlId: string) {
    this.path = `/application-guest/${shortUrlId}?locale=${locale}&appId=${appId}`
  }
}

/**
 * Creates a new application.
 * @todo Delete the old application creation request, since this uses the new models and dtos.
 */
export class ApplicationRequest implements APIRequest<SpaceApplication.ApplicationResponse> {
  response!: SpaceApplication.ApplicationResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: SpaceApplication.ApplicationRequest
  constructor(data: AnonymousApplicationDto) {
    this.params = data
    this.path = `/application-public`
  }
}

/**
 * Creates a new application.
 * @todo Delete the old application creation request, since this uses the new models and dtos.
 */
export class CandidateApplicationRequest implements APIRequest<boolean> {
  response!: boolean
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: ApplicationCandidateCreateDto
  constructor(dto: ApplicationCandidateCreateDto) {
    this.params = dto
    this.path = `/application`
  }
}

/**
 * Verifies if the anonymous application is not duplicated
 */
export class ApplicationVerifyRequest implements APIRequest<SpaceApplication.ApplicationVerifyResponse> {
  response!: SpaceApplication.ApplicationVerifyResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: SpaceApplication.ApplicationVerifyRequest
  constructor(data: AnonymousApplicationVerifyPhoneDto) {
    this.params = data
    this.path = `/application-public/verify-phone`
  }
}

/**
 * Verifies if the anonymous visa type application is not permitted
 */
export class ApplicationVisaTypeVerifyRequest implements APIRequest<SpaceApplication.ApplicationVisaTypeVerifyResponse> {
  response!: SpaceApplication.ApplicationVisaTypeVerifyResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: SpaceApplication.ApplicationVisaTypeVerifyRequest
  constructor(data: AnonymousApplicationVerifyVisaTypeDto) {
    this.params = data
    this.path = `/vacancy/public/validate-visa-type/`
  }
}

export class CandidateStatusHierarchyRequest implements APIRequest<SpaceApplication.ApplicationStatusCandidateHierarchyResponse> {
  response!: SpaceApplication.ApplicationStatusCandidateHierarchyResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(locale: LanguageLocale = LanguageLocale.USen) {
    this.path = `${applicationStatusCandidateEndpoint}/hierarchy?locale=${locale}`
  }
}

/**
 * Gets all logs for the specified application.
 */
export class ApplicationLogsRequest implements APIRequest<SpaceApplication.ApplicationLogsResponse> {
  response!: SpaceApplication.ApplicationLogsResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(applicationId: number, locale: LanguageLocale) {
    this.path = `${applicationStatusCandidateLogEndpoint}/${applicationId}/logs?locale=${locale}`
  }
}

/**
 * Create a new Log for the specified application.
 */
export class ApplicationLogCreateRequest implements APIRequest<SpaceApplication.ApplicationLogCreateResponse> {
  response!: SpaceApplication.ApplicationLogCreateResponse
  path: string
  contentType = ContentTypes.json
  method = HTTPMethod.POST
  params: SpaceApplication.ApplicationLogCreateRequest
  constructor(dto: ApplicationStatusGuestCandidateLogDto) {
    this.params = dto
    this.path = `${applicationStatusCandidateLogEndpoint}`
  }
}

/**
 * Withdraws from the specified application.
 */
export class ApplicationWithdrawRequest implements APIRequest<SpaceApplication.ApplicationWithdrawResponse> {
  response!: SpaceApplication.ApplicationWithdrawResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: SpaceApplication.ApplicationWithdrawRequest
  constructor(applicationId: number, dto: AnonymousApplicationWithdrawDto) {
    this.path = `${applicationStatusCandidateLogEndpoint}/${applicationId}/withdraw`
    this.params = dto
  }
}

export class GuestApplicationTransferRequest implements APIRequest<boolean> {
  response!: boolean
  path: string
  contentType = 'application/json'
  method = HTTPMethod.PUT
  params: TransferApplicationsDto
  constructor(dto: TransferApplicationsDto) {
    this.params = dto
    this.path = '/application-guest/transfer'
  }
}
