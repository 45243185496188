import { IApp } from '@/types/nuxt'
import { LanguageLocale } from '@tokyojob/core'
import { LogType } from '@tokyojob/frontend-core'

export default class LocaleUpdateUseCase implements BaseUsecase {
  constructor(private readonly app: IApp) {}

  /**
   * Update's the user's current locale
   * @param locale 
   * @returns 
   */
  async execute(locale: LanguageLocale): Promise<boolean> {
    const { baseGateway, logService } = this.app
    try {
      return baseGateway.user.UpdateLocale(locale)
    } catch (error) {
      await logService.handle({ type: LogType.Error, error: error as Error })
      return false
    }
  }
}
