import { AttachmentEntity, AttachmentEntityFactory } from '../../common/attachment'
import { ICandidateVisaAttachment } from '../../../../../../core/src/models/candidate/interface/i-candidate-visa-attachment'
import uniqueId from 'lodash.uniqueid'

/**
 * An attachment associated to a candidate visa.
 */
export class CandidateVisaAttachmentEntity {
  /**
   * Cache for the visa attachment entity.
   */
  private _props: ICandidateVisaAttachment
  /**
   * Cache for the attachment entity.
   */
  private attachmentEntity: AttachmentEntity
  /**
   * Cache for the visa attachment thumbnail entity.
   */
  private attachmentThumbnailEntity: AttachmentEntity | undefined

  constructor(props: ICandidateVisaAttachment) {
    this._props = props

    if (this.props.attachment) {
      this.attachmentEntity = AttachmentEntityFactory.new(this.props.attachment)
    } else {
      this.attachmentEntity = AttachmentEntityFactory.new()
    }

    if (this.props.attachmentThumbnail) {
      this.attachmentThumbnailEntity = AttachmentEntityFactory.new(this.props.attachmentThumbnail)
    }
  }

  get props(): ICandidateVisaAttachment {
    return this._props
  }

  get id(): number | undefined {
    return this.props.id
  }

  /**
   * Identifier of the candidate visa owns the attachment.
   */
  get candidateVisaId(): number {
    return this.props.candidateVisaId
  }

  set candidateVisaId(value: number) {
    this.props.candidateVisaId = value
  }

  /**
   * The attachment itself, containing all the relevant information like URL, name, etc.
   */
  get attachment(): AttachmentEntity {
    return this.attachmentEntity
  }

  set attachment(value: AttachmentEntity) {
    this.attachmentEntity = value

    this.props.attachment = value?.props
  }

  /**
   * The attachment thumbnail itself, containing all the relevant information like URL, name, etc.
   * Only applies to image attachments.
   */
  get attachmentThumbnail(): AttachmentEntity | undefined {
    return this.attachmentThumbnailEntity
  }

  set attachmentThumbnail(value: AttachmentEntity | undefined) {
    this.attachmentThumbnailEntity = value

    this.props.attachmentThumbnail = value?.props
  }

  get createdAt(): string {
    if (!this.props.createdAt) {
      const timestamp = Date.now().toString()
      this.props.createdAt = `${uniqueId(timestamp)}`
    }
    return this.props.createdAt
  }

  /**
   * Whether the item has a thumbnail file or not.
   */
  get hasThumbnail(): boolean {
    const hasValidThumbnail = !!this._props.attachmentThumbnail && !!this._props.attachmentThumbnail.url
    return hasValidThumbnail
  }

  /**
   * The text to set in the HTML alt attribute. Only applies to image files.
   */
  get alt(): string | undefined {
    // TODO
    return ''
  }
}