import { HTTPMethod } from '../../../../base/api/types'
import { APIRequest } from '../../../../base/api/api.request'
import { SpaceCandidate } from '../namespace'
import {
  CandidateNameDto,
  CandidateVisaTypeDto,
  UserContactsDto,
  CandidatePersonalInformationDto,
  CandidateJapaneseLanguageSkillsDto,
  LanguageLocale,
  ICandidateDashboardStats,
} from '../../../../../../../core/src'

const pathRoot = '/candidate'

/**
 * HTTP Request to set the candidate's name information.
 */
export class UpdateCandidateName implements APIRequest<SpaceCandidate.UpdateNameResponse> {
  response!: SpaceCandidate.UpdateNameResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.PUT
  params: SpaceCandidate.UpdateNameRequest
  constructor(seed: CandidateNameDto) {
    this.params = seed
    this.path = `${pathRoot}/set-candidate-name`
  }
}

export class UpdateCandidateVisa implements APIRequest<SpaceCandidate.UpdateVisaResponse> {
  response!: SpaceCandidate.UpdateVisaResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.PUT
  params: SpaceCandidate.UpdateVisaRequest
  constructor(seed: CandidateVisaTypeDto) {
    this.params = seed
    this.path = `${pathRoot}/set-visa-type`
  }
}

export class UpdateCandidateContactInformation implements APIRequest<SpaceCandidate.UpdateContactInformationResponse> {
  response!: SpaceCandidate.UpdateContactInformationResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.PUT
  params: SpaceCandidate.UpdateContactInformationRequest
  constructor(seed: UserContactsDto) {
    this.params = seed
    this.path = `${pathRoot}/set-contacts`
  }
}
export class UpdateCandidatePersonalInformation implements APIRequest<SpaceCandidate.UpdatePersonalInformationResponse> {
  response!: SpaceCandidate.UpdatePersonalInformationResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.PUT
  params: SpaceCandidate.UpdatePersonalInformationRequest
  constructor(seed: CandidatePersonalInformationDto) {
    this.params = seed
    this.path = `${pathRoot}/set-personal-information`
  }
}

export class UpdateCandidateJapaneseLanguageSkills implements APIRequest<SpaceCandidate.UpdateJapaneseLanguageSkillsResponse> {
  response!: SpaceCandidate.UpdateJapaneseLanguageSkillsResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.PUT
  params: SpaceCandidate.UpdateJapaneseLanguageSkillsRequest
  constructor(seed: CandidateJapaneseLanguageSkillsDto) {
    this.params = seed
    this.path = `${pathRoot}/set-japanese-language-skills`
  }
}

export class FullCandidateUserProfile implements APIRequest<SpaceCandidate.CandidateUserFullProfileResponse> {
  response!: SpaceCandidate.CandidateUserFullProfileResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(locale: LanguageLocale = LanguageLocale.USen) {
    this.path = `${pathRoot}/get-full-profile?locale=${locale}`
  }
}

export class CandidateDashboardStats implements APIRequest<ICandidateDashboardStats> {
  response!: ICandidateDashboardStats
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor() {
    this.path = `${pathRoot}-dashboard-stats`
  }
}
