import { IPrefecture, LanguageLocale } from '../../models';
import { HepburnConverter } from '../../utils/language/japanese/hepburn-converter';
import { StringUtils } from '../../utils/string/string-utils';

export class PrefectureGetters {
  static getName(prefecture?: IPrefecture, locale?: LanguageLocale): string {
    if (!prefecture) return '';
    if (!prefecture.i18n) return '';
    // if no locale is provided, return the first available item
    if (!locale) {
      if (!prefecture.i18n[0].name) return '';
      return prefecture.i18n[0].name;
    }
    // find the item that matches the locale
    const item = prefecture.i18n.find((i) => i.locale === locale);
    if (!item || !item.name) return '';
    return StringUtils.Capitalize(item.name);
  }
  static getFuriganaName(prefecture?: IPrefecture, locale?: LanguageLocale): string {
    if (!prefecture) return '';
    if (!prefecture.i18n) return '';
    // if no locale is provided, return the first available item
    if (!locale) {
      if (!prefecture.i18n[0].nameKana) return '';
      return prefecture.i18n[0].nameKana;
    }
    // find the item that matches the locale
    const item = prefecture.i18n.find((i) => i.locale === locale);
    if (!item || !item.nameKana) return '';
    return item.nameKana;
  }
  static getKatakanaName(prefecture?: IPrefecture): string {
    const converter = new HepburnConverter();
    if (!prefecture) return '';
    if (!prefecture.i18n) return '';
    // find the item that matches the locale
    const item = prefecture.i18n.find((i) => i.locale === LanguageLocale.JAjp);
    if (!item || !item.nameKana) return '';
    return converter.convert(item.nameKana).katakana;
  }
}
