import { IZipCodeSearchResult, LanguageLocale } from '@tokyojob/core'
import { APIRequest, HTTPMethod } from '@tokyojob/frontend-core'

export class ZipCodeSearchRequest implements APIRequest<IZipCodeSearchResult> {
  response!: IZipCodeSearchResult
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(zipCode: string, locale: LanguageLocale) {
    this.path = `/zip-code/search?code=${zipCode}&locale=${locale}`
  }
}
