import { AttachmentSeed } from '../../network/schema/models'

/**
 * Generates the FormData required to post a file from an attachment seed.
 * @param props The Attachment seed to use, normally used for files
 * that will be automatically saved as attachments in our db.
 * @returns
 */
export const toAttachmentSeed = (props: AttachmentSeed): FormData => {
  const { name, description, file, attachmentType } = props
  const form = new FormData()
  form.append('name', name ?? file.name)
  form.append('description', description)
  form.append('file', file)
  form.append('attachmentType', attachmentType.toString())
  return form
}
