import {
  IVacancy,
  JapaneseConversationKnowledgeCube,
  LocalizationData,
  LocalizationPath,
  VacancyGetters,
} from '../../../../../core/src'
import { LocalizationService } from '../language'

export class VacancyCubeText {
  /**
   * Returns an array of wage formatted strings.
   * @param vacancy
   * @returns
   */
  public static getText(localization: LocalizationData, vacancy: IVacancy): string {
    const id = VacancyGetters.getJapaneseConversationID(vacancy)
    if (!id) return LocalizationService.t<string>(localization, LocalizationPath.JapaneseSkillsCubeNoJapaneseListed)
    return LocalizationService.t<string>(localization, JapaneseConversationKnowledgeCube[id!])
  }
}
