import { CandidateSex } from '../../user/constants/candidate-sex.enum';
import { IUser } from '../../user/interface/i-user';
import { CandidatePersonalInformationDto } from '../dto/candidate-personal-information.dto';

export class CandidatePersonalInformationDtoFactory {
  new(user: IUser): CandidatePersonalInformationDto {
    const gender: CandidateSex = user.gender as CandidateSex;
    const birthdate = user.candidate?.birthDate;
    if (!user.candidate) throw new Error('Expected valid candidate data');
    const isValidGender = !!gender && (gender === CandidateSex.Male || gender === CandidateSex.Female);
    if (!isValidGender || !birthdate) throw new Error('Expected a valid gender and birth date');
    const { prefecture, city } = user.candidate;
    if (!prefecture) throw new Error('Expected prefecture');
    const prefectureId = prefecture.id
    if (!prefectureId) throw new Error('Expected prefectureId');
    if (!city) throw new Error('Expected city');
    const cityId = city.id
    if (!cityId) throw new Error('Expected cityId');
    const countryId = user.candidate?.country?.id || user.candidate?.countryId;
    const firstArrivalDate = user.candidate?.firstArrivalDate;
    const grewUpInCountryId = user.candidate?.grewUpInCountry?.id || user.candidate?.grewUpInCountryId;
    const dto = new CandidatePersonalInformationDto();
    dto.birthDate = birthdate;
    dto.gender = gender;
    dto.countryId = countryId;
    dto.grewUpInCountryId = grewUpInCountryId;
    dto.firstArrivalDate = firstArrivalDate;
    dto.prefectureId = user.candidate?.prefecture?.id! || user.candidate?.prefectureId;
    dto.cityId = user.candidate?.city?.id! || user.candidate?.cityId;
    return dto;
  }
}
