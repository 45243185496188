import { GatewayBase } from '@/base/gateways/base'
import {
  ICandidateVisa,
  IContact,
  IUser,
  IUserProfileUpdateResult,
  LanguageLocale,
  UserLocaleChangeDto,
  UserPasswordChangeDto
} from '@tokyojob/core'
import {
  CandidateDtoTranslator,
  IPaginateProps,
  QueryStringEntity,
  UpdateCandidateContactInformation,
  UpdateCandidateName,
  UpdateCandidateVisa,
  toCandidateNameSeed
} from '@tokyojob/frontend-core'
import { IUserProps } from '../entities/user'
import {
  DeleteRequest,
  GetMeRequest,
  GetRequest,
  PaginateRequest,
  PasswordChangeRequest,
  UpdateRequest,
  UserUpdateLocale
} from '../network/requests/user'
import { toUserProps } from './translator/user'

export default class User extends GatewayBase {
  async Paginate(entity: QueryStringEntity): Promise<IPaginateProps<IUserProps>> {
    const { rows, ...data } = await this.apiClient.request(new PaginateRequest(entity))
    const _rows = rows ? rows.map((item) => toUserProps(item)) : []
    return {
      ...data,
      rows: _rows
    }
  }

  async Get(id: string): Promise<IUserProps | null> {
    const item = await this.apiClient.request(new GetRequest(id))
    return item ? toUserProps(item) : null
  }

  async Me(): Promise<IUser | null> {
    const item = await this.apiClient.request(new GetMeRequest())
    return item ? item : null
  }

  async Update(user: IUser, locale: LanguageLocale = LanguageLocale.USen): Promise<IUserProfileUpdateResult | null> {
    const item = await this.apiClient.request(new UpdateRequest(user, locale))
    return item ? item : null
  }

  /**
   * API call to update a user's password.
   * @param entity The user whose password will be updated. The password and new password parameters must be provided.
   */
  async PasswordChange(dto: UserPasswordChangeDto) {
    return await this.apiClient.request(new PasswordChangeRequest(dto))
  }

  async Delete(id: string | number): Promise<boolean> {
    return await this.apiClient.request(new DeleteRequest(id))
  }

  /**
   * Update current candidate's name.
   * @remarks Used by the new Onboarding Process.
   */
  async UpdateName(user: IUser): Promise<boolean> {
    const seed = toCandidateNameSeed(user)
    const item = await this.apiClient.request(new UpdateCandidateName(seed))
    return item
  }

  /**
   * Update current candidate's name.
   * @remarks Used by the new Onboarding Process.
   */
  async UpdateVisa(candidateVisa: ICandidateVisa): Promise<boolean> {
    const translator = new CandidateDtoTranslator()
    const dto = translator.candidateVisaToDto(candidateVisa)
    if (!dto.visaTypeId) return false
    const item = await this.apiClient.request(new UpdateCandidateVisa(dto))
    return item
  }

  /**
   * Update current candidate's contact information.
   * @remarks Used by the new Onboarding Process.
   */
  async UpdateContactInformation(contacts: IContact[]): Promise<boolean> {
    const translator = new CandidateDtoTranslator()
    const dto = translator.contactsToUserContactDto(contacts)
    const item = await this.apiClient.request(new UpdateCandidateContactInformation(dto))
    return item
  }

  /**
   * Update's the user's current locale
   * @param locale
   * @returns
   */
  async UpdateLocale(locale: LanguageLocale): Promise<boolean> {
    const dto = new UserLocaleChangeDto()
    dto.locale = locale
    return this.apiClient.request(new UserUpdateLocale(dto))
  }
}
