import { GatewayBase } from '@/base/gateways/base'
import { IContactMethod, IFilterableDataSetDictionary, IVisaType, LanguageLocale } from '@tokyojob/core'
import { QueryStringEntity } from '@tokyojob/frontend-core'
import CategoryEntity, { CategoryEntityFactory } from '../entities/category'
import ContactMethodEntity, { ContactMethodEntityFactory } from '../entities/contact.method'
import ContractTypeEntity, { ContractTypeEntityFactory } from '../entities/contract.type'
import SchoolTypeEntity, { SchoolTypeEntityFactory } from '../entities/school.type'
import SkillEntity, { SkillEntityFactory } from '../entities/skill'
import { CreateRequest } from '../network/requests/attachment'
import {
  FetchCategoryChildrensRequest,
  FetchCategoryHierarchyRequest,
  FetchCategoryRequest,
  FetchParentCategoriesRequest,
  SearchCategoriesHierarchyRequest
} from '../network/requests/category'
import { FetchContactMethodRequest, FetchIContactMethodRequest } from '../network/requests/contact.method'
import { FetchContractTypeRequest } from '../network/requests/contract.type'
import { FetchJobCategoryHierarchyRequest } from '../network/requests/job-category-hierarchy'
import { FetchLocationHierarchyRequest } from '../network/requests/location-hierarchy'
import { FetchSchoolTypeRequest } from '../network/requests/school.type'
import { FetchSkillsRequest } from '../network/requests/skill'
import { FetchVisaTypeHierarchyRequest } from '../network/requests/visa-type-hierarchy'
import { FetchVisaTypeAllRequest, FetchVisaTypePlainRequest } from '../network/requests/visa.type.all'
import { AttachmentSeed } from '../network/schema/models'
import { AttachmentType } from '../network/schema/models/attachment.type'
import { toAttachmentSeed } from './translator/attachment'

export default class Common extends GatewayBase {
  /**
   * Uploads an attachment to our service.
   * @param attachment
   * @param attachmentType Determines where the attachment will be saved and any type of extra processing it needs.
   * @returns
   */
  async PostAttachment(attachment: AttachmentSeed, attachmentType: AttachmentType) {
    const item = await this.apiClient.request(new CreateRequest(toAttachmentSeed(attachment), attachmentType))
    return item
  }

  async FetchCategories(options?: QueryStringEntity): Promise<CategoryEntity[]> {
    const items = await this.apiClient.request(new FetchCategoryRequest(options))
    return items.map((item) => CategoryEntityFactory(item))
  }

  /**
   * Gets the top categories in the hierarchy.
   */
  async FetchCategoryHierarchy(): Promise<CategoryEntity[]> {
    const items = await this.apiClient.request(new FetchCategoryHierarchyRequest())
    return items.map((item) => CategoryEntityFactory(item))
  }

  /**
   * Fetches a list of skills.
   * @remarks For now, the list is quite short so we're pulling all the current skills from the API.
   * These can be queried in the client with ease. However, once we're getting to a high amount of skill, specially
   * if we see degraded performance on the client, we'll have to update how Skill fetching is handled
   * to use paging and filtering.
   * @todo Update this to handle a very large amount of skills.
   */
  async FetchSkillList(): Promise<SkillEntity[]> {
    const items = await this.apiClient.request(new FetchSkillsRequest())
    return items.map((item) => SkillEntityFactory(item))
  }

  /**
   * Gets a catalogue of available contract types.
   */
  async FetchContractTypes(): Promise<ContractTypeEntity[]> {
    const items = await this.apiClient.request(new FetchContractTypeRequest())
    return items.map((item) => ContractTypeEntityFactory(item))
  }

  /**
   * Gets a catalogue of available school types.
   */
  async FetchSchoolTypes(): Promise<SchoolTypeEntity[]> {
    const items = await this.apiClient.request(new FetchSchoolTypeRequest())
    return items.map((item) => SchoolTypeEntityFactory(item))
  }

  /**
   * Gets the top categories in the hierarchy.
   */
  async FetchParentCategories(): Promise<CategoryEntity[]> {
    const items = await this.apiClient.request(new FetchParentCategoriesRequest())
    return items.map((item) => CategoryEntityFactory(item))
  }

  /**
   * Gets the children of the specified category.
   * @param parentId Identifier of the category whose children should be fetched.
   */
  async FetchCategoryChildren(parentId: number): Promise<CategoryEntity[]> {
    const items = await this.apiClient.request(new FetchCategoryChildrensRequest(parentId))
    return items.children.map((item) => CategoryEntityFactory(item))
  }

  /**
   * Searches for all categories that match the provided search term. Returns a hierarchy matching the provided items.
   * @param search Text to use when filtering categories.
   */
  async SearchCategoriesHierarchy(search: string): Promise<CategoryEntity[]> {
    const items = await this.apiClient.request(new SearchCategoriesHierarchyRequest(search))
    return items.map((item) => CategoryEntityFactory(item))
  }

  /**
   * Returns the visa types in a flat structure
   * @example [{id:1, name: "Trainee - Crane Operator"}]
   * @returns
   */
  async FetchAllVisaTypes(locale: LanguageLocale = LanguageLocale.USen): Promise<IVisaType[]> {
    return this.apiClient.request(new FetchVisaTypeAllRequest(locale))
  }

  /**
   * Returns a plain visa type list, no modification to its structure
   * @example [{id:50131, name: "Crane Operator"}]
   * @param locale
   */
  async FetchVisaTypeList(locale: LanguageLocale = LanguageLocale.USen): Promise<IVisaType[]> {
    return this.apiClient.request(new FetchVisaTypePlainRequest(locale))
  }

  async FetchContactMethods(options?: QueryStringEntity): Promise<ContactMethodEntity[]> {
    const items = await this.apiClient.request(new FetchContactMethodRequest(options))
    return items.map((item) => ContactMethodEntityFactory(item))
  }

  /**
   * Replaces the old FetchContactMethods
   * @todo delete the old FetchContactMethods
   * @returns
   */
  async FetchIContactMethods(options?: QueryStringEntity): Promise<IContactMethod[]> {
    const items = await this.apiClient.request(new FetchIContactMethodRequest(options))
    return items
  }

  /**
   * Returns the job categories in a hierarchy
   * @returns
   */
  async FetchJobCategoryHierarchy(locale: LanguageLocale): Promise<IFilterableDataSetDictionary> {
    const JobCategorys = await this.apiClient.request(new FetchJobCategoryHierarchyRequest(locale))
    return JobCategorys
  }
  /**
   * Returns the job categories in a hierarchy
   * @returns
   */
  async FetchLocationHierarchy(locale: LanguageLocale): Promise<IFilterableDataSetDictionary> {
    const Locations = await this.apiClient.request(new FetchLocationHierarchyRequest(locale))
    return Locations
  }
  /**
   * Returns the job categories in a hierarchy
   * @returns
   */
  async FetchVisaTypeHierarchy(locale: LanguageLocale): Promise<IFilterableDataSetDictionary> {
    const VisaTypes = await this.apiClient.request(new FetchVisaTypeHierarchyRequest(locale))
    return VisaTypes
  }
}
