import { ICity, ICountry, IPrefecture, LanguageLocale } from '../../models';
import { StringUtils } from '../../utils/string/string-utils';

export class CountryGetters {
  static countryKanaMap: Record<string, string> = {
    中央アフリカ共和国: 'チュウオウアフリカキョウワコク',
    中国: 'チュウゴク',
    コンゴ共和国: 'コンゴキョウワコク',
    コンゴ民主共和国: 'コンゴミンシュキョウワコク',
    クック諸島: 'クックショトウ',
    ドミニカ国: 'ドミニカコク',
    ドミニカ共和国: 'ドミニカキョウワコク',
    赤道ギニア: 'セキドウギニア',
    北朝鮮: 'キタチョウセン',
    韓国: 'カンコク',
    マケドニア旧ユーゴスラビア共和国: 'マケドニアキュウユーゴスラビアキョウワコク',
    マーシャル諸島: 'マーシャルショトウ',
    ミクロネシア連邦: 'ミクロネシアレンポウ',
    沿ドニエストル: 'エンドニエストル',
    セントビンセントおよびグレナディーン諸島: 'セントビンセントオヨビグレナディーンショトウ',
    ソロモン諸島: 'ソロモンショトウ',
    南アフリカ: 'ミナミアフリカ',
    南オセチア: 'ミナミオセチア',
    南スーダン: 'ミナミスーダン',
    台湾: 'タイワン',
    東ティモール: 'ヒガシティモール',
    アラブ首長国連邦: 'アラブシュチョウコクレンポウ',
    西サハラ: 'ニシサハラ',
    香港: 'ホンコン',
  };
  static getName(country?: ICountry, locale?: LanguageLocale): string {
    if (!country) return '';
    if (!country.i18n) return '';
    // if no locale is provided, return the first available item
    if (!locale) {
      if (!country.i18n[0].name) return '';
      return country.i18n[0].name;
    }
    // find the item that matches the locale
    const item = country.i18n.find((i) => i.locale === locale);
    if (!item || !item.name) return '';
    return item.name;
  }

  static getNameKana(country?: ICountry, locale?: LanguageLocale): string {
    if (!country) return '';
    if (!country.i18n) return '';
    // if no locale is provided, return the first available item
    if (!locale) {
      if (!country.i18n[0].name) return '';
      return this.countryKanaMap[country.i18n[0].name] || country.i18n[0].name;
    }
    // find the item that matches the locale
    const item = country.i18n.find((i) => i.locale === locale);
    if (!item || !item.name) return '';
    // if the country is any of the following, return the hand mapped katakana. Otherwise return the name as is, it is already in katakana.
    return this.countryKanaMap[item.name] || item.name;
  }
}
