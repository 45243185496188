import { LanguageLocale } from '@tokyojob/core'
import { APIRequest, HTTPMethod, QueryStringEntity, QueryStringEntityFactory } from '@tokyojob/frontend-core'
import { SpaceBenefit } from '../schema/namespace'

export class FetchBenefitCategoryRequest
  implements APIRequest<SpaceBenefit.FetchBenefitCategoryResponse> {
  response!: SpaceBenefit.FetchBenefitCategoryResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(entity?: QueryStringEntity) {
    if (!entity) {
      entity = QueryStringEntityFactory({
        locale: LanguageLocale.USen
      })
    }
    this.path = '/benefit.category/' + entity.queries
  }//
}
