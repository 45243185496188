
/**
 * Levels of Japanese writing ability.
 */
 export enum JapaneseWritingLevelEn {
  Zero = 'Cannot write in Japanese',
  Hiragana = 'Hiragana',
  Katakana = 'Hiragana + Katakana', 
  N5 = 'Basic sentences and Kanji',
  N4 = 'Understand common language',
  N3 = 'Good understanding',
  N2 = 'Business level',
  N1 = 'Domain of the language'
}