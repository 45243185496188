
import { defineComponent, ref, watch } from 'vue'
import { LocalizationData, LocalizationPath, SocialMediaLinks } from '@tokyojob/core'
import { ButtonFactory, CButton, CIcon, CIconFactory, getLocalization, IconType, LinkTarget } from '@tokyojob/frontend-components'
import { LocalizationService } from '../../../../../../../../../core/src/modules'
import { getProvidedApp, getRoute } from '~/base/utils/provided-getter'

export default defineComponent({
  components: { CIcon, CButton },
  setup() {
    const route = getRoute()
    const localization: LocalizationData = getLocalization(getProvidedApp())

    const instagramBtn = ButtonFactory.button({
      icon: CIconFactory.Icon({ icon: 'instagram', type: IconType.brand, classes: 'fit' }),
      link: { link: SocialMediaLinks.instagramUrl, target: LinkTarget.Blank },
      classes: 'theme-text-base-clear',
      width: 'fit'
    })
    const facebookBtn = ButtonFactory.button({
      icon: CIconFactory.Icon({ icon: 'facebook', type: IconType.brand, classes: 'fit' }),
      link: { link: SocialMediaLinks.facebookUrl, target: LinkTarget.Blank },
      classes: 'theme-text-base-clear',
      width: 'fit'
    })
    const youtubeBtn = ButtonFactory.button({
      icon: CIconFactory.Icon({ icon: 'youtube', type: IconType.brand, classes: 'fit' }),
      link: { link: SocialMediaLinks.youtubeUrl, target: LinkTarget.Blank },
      classes: 'theme-text-base-clear',
      width: 'fit'
    })

    const followUsOnText = ref(LocalizationService.t<string>(localization, LocalizationPath.followsUsOn))

    watch(
      () => route.value.path,
      async (newPath) => {
        followUsOnText.value = LocalizationService.t<string>(localization, LocalizationPath.followsUsOn)
      }
    )

    return {
      instagramBtn,
      facebookBtn,
      youtubeBtn,
      followUsOnText
    }
  }
})
