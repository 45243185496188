import { IPaymentPeriod, IPaymentPeriodi18n } from '../../../models';
import { PaymentPeriodDictionary } from '../constant';

export class PaymentPeriodGetters {
  static getName(item: IPaymentPeriod): string {
    if (!PaymentPeriodGetters.hasi18n(item)) return '';
    // TODO: Remove informations, this is used for backwards compatibility with old endpoints
    const i18n: IPaymentPeriodi18n[] = item.i18n || (item as any).informations;
    return i18n[0].name!;
  }

  static hasi18n(item: IPaymentPeriod): boolean {
    if (!item) return false;
    // TODO: Remove informations, this is used for backwards compatibility with old endpoints
    const i18n: IPaymentPeriodi18n[] = item.i18n || (item as any).informations;
    return !!(i18n && i18n.length > 0);
  }

  /**
   * Generates a name that can be used in UI labels when the payment period is paired with a wage.
   * @param paymentPeriod
   * @param textPeriodSeparator
   * @param removeAdverbSuffix
   * @returns
   */
  static getWageLabelFriendlyName(
    paymentPeriod?: IPaymentPeriod,
    textPeriodSeparator: string = '/',
  ): string {
    if (!paymentPeriod) return '';
    let name = PaymentPeriodGetters.getName(paymentPeriod);
    let periodStr = `${textPeriodSeparator}${name}`;
    return periodStr;
  }
}
