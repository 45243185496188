import { IsNumber, IsOptional, IsString } from 'class-validator';

/**
 * The DTO for attachments.
 */
export class AttachmentDto {
  /**
   * Unique identifier of the attachment.
   */
  @IsOptional()
  @IsNumber()
  id: number;

  /**
   * Name of the file. Usually a randomize string.
   */
  @IsString()
  name: string;

  /**
   * Name of the file as it was when originally uploaded.
   */
  @IsString()
  originalName: string;

  /**
   * The file's Web URL.
   */
  @IsString()
  url: string;

  /**
   * The attachment's path of the file.
   */
  @IsString()
  path: string;

  /**
   * The name of the bucket where the attachment's file is located in
   */
  @IsString()
  bucket: string;

  /**
   * The mime type of the file.
   * @remarks Only used when sending a temporary file.
   * @example "image/png"
   */
  @IsString()
  mime: string;

  /**
   * The file's extension
   * @remarks Only used when sending a temporary file.
   * @example ".jpg", ".doc"
   */
  @IsString()
  extension: string;

  /**
   * The size in bytes of the file.
   * @example 1938219
   */
  @IsNumber()
  size: number;
}
