import { AttachmentEntity, AttachmentEntityFactory } from '../../common/attachment/'
import { IFormInputData } from '../../html/forms/inputs/interfaces'
import { CandidateVisaAttachmentEntity } from './candidate-visa-attachment'
import { VisaTypeEntity, VisaTypeEntityFactory, VisaTypePropsFactory } from '../../visa-type/entities'
import { ICandidateVisa } from '../../../../../../core/src/models/candidate/interface/i-candidate-visa'
import { ICandidateVisaAttachment } from '../../../../../../core/src/models/candidate/interface/i-candidate-visa-attachment'
/**
 * Maximum file size allowed for residence card files.
 */
export const ResidenceCardFileSizeLimitKbs = 5 * 1024

export class CandidateVisaEntity {
  private _props: ICandidateVisa
  private visaTypeEntity: VisaTypeEntity | undefined
  private visaSubTypeEntity: VisaTypeEntity | undefined
  private residenceCardFrontEntity: AttachmentEntity | undefined
  private residenceCardBackEntity: AttachmentEntity | undefined
  private designationPapersEntity: AttachmentEntity | undefined
  private candidateVisaAttachmentEntities: CandidateVisaAttachmentEntity[] | undefined

  constructor(props: ICandidateVisa) {
    this._props = props
    if (this.props.visaType) {
      this.visaTypeEntity = VisaTypeEntityFactory(this.props.visaType)
    }
    if (this.props.visaSubType) {
      this.visaSubTypeEntity = VisaTypeEntityFactory(this.props.visaSubType)
    }
    this.residenceCardFrontEntity = AttachmentEntityFactory.new(this.props.cardFront)
    this.residenceCardBackEntity = AttachmentEntityFactory.new(this.props.cardBack)
    this.designationPapersEntity = AttachmentEntityFactory.new(this.props.designationPapersAttachment)
    if (this.props.candidateVisaAttachments) {
      this.candidateVisaAttachmentEntities = this.props.candidateVisaAttachments.map((e) =>
        CandidateVisaAttachmentEntityFactory.new(e)
      )
    }
  }

  get props(): ICandidateVisa {
    return this._props
  }

  /**
   * The unique identifier of the candidate visa
   */
  get id(): number | undefined {
    return this.props.id
  }

  /**
   * The unique identifier of the candidate that owns this entity
   */
  get candidateId(): number | undefined {
    return this.props.candidateId
  }

  /**
   * The residence card id of the candidate
   */
  get cardId(): string | undefined {
    return this.props.cardId
  }

  set cardId(value: string | undefined) {
    this.props.cardId = value
  }

  /**
   * The expiration date of the candidate
   */
  get validDate(): Date | undefined {
    return this.props.validDate
  }

  set validDate(value: Date | undefined) {
    this.props.validDate = value
  }

  /**
   * The visa type of the candidate
   */
  get visaType(): VisaTypeEntity | undefined {
    return this.visaTypeEntity
  }

  set visaType(value: VisaTypeEntity | undefined) {
    this.visaTypeEntity = value

    this.props.visaType = value?.props
    this.props.visaTypeId = value?.id
    /**
     * @todo We need to remove subtype when a visa type is selected.
     * But if we do the code below, we are experiencing a bug as the Subtype seems to be set before the Visa type.
     * Check the webuser profile page for reference (when the profile has a subtype)
     */
    // this.props.visaSubType = undefined
    // this.props.visaSubTypeId = undefined
  }

  /**
   * The unique identifier of the visa type of the candidate
   */
  get visaTypeId(): number | undefined {
    return this.props.visaTypeId
  }

  set visaTypeId(value: number | undefined) {
    this.props.visaTypeId = value
  }

  /**
   * The visa sub type of the candidate
   */
  get visaSubType(): VisaTypeEntity | undefined {
    return this.visaSubTypeEntity
  }

  set visaSubType(value: VisaTypeEntity | undefined) {
    this.visaSubTypeEntity = value

    this.props.visaSubType = value?.props
    this.props.visaSubTypeId = value?.id
  }

  /**
   * The unique identifier of the visa sub type of the candidate
   */
  get visaSubTypeId(): number | undefined {
    return this.props.visaSubTypeId
  }

  set visaSubTypeId(value: number | undefined) {
    this.props.visaSubTypeId = value
  }

  /**
   * Whether the candidate has a visa subutype or not.
   */
  get hasVisaSubtype(): boolean {
    const entityExists = !!this.visaSubTypeEntity
    if (!entityExists) return false
    return this.visaSubTypeEntity?.id !== 0
  }

  /**
   * The front residence card attachment of the candidate
   */
  get cardFront(): AttachmentEntity | undefined {
    return this.residenceCardFrontEntity
  }

  set cardFront(value: AttachmentEntity | undefined) {
    this.residenceCardFrontEntity = value

    this.props.cardFront = value?.props
    this.props.cardFrontId = value?.id
  }

  /**
   * The back residence card attachment of the candidate
   */
  get cardBack(): AttachmentEntity | undefined {
    return this.residenceCardBackEntity
  }

  set cardBack(value: AttachmentEntity | undefined) {
    this.residenceCardBackEntity = value

    this.props.cardBack = value?.props
    this.props.cardBackId = value?.id
  }

  /**
   * The designation paper attachment of the candidate
   */
  get designationPapersAttachment(): AttachmentEntity | undefined {
    return this.designationPapersEntity
  }

  set designationPapersAttachment(value: AttachmentEntity | undefined) {
    this.designationPapersEntity = value

    this.props.designationPapersAttachment = value?.props
    this.props.designationPapersAttachmentId = value?.id
  }

  /**
   * The candidate visa attachments of the candidate also known as personal id
   */
  get candidateVisaAttachments(): CandidateVisaAttachmentEntity[] | undefined {
    return this.candidateVisaAttachmentEntities
  }

  set candidateVisaAttachments(value: CandidateVisaAttachmentEntity[] | undefined) {
    this.candidateVisaAttachmentEntities = value

    this.props.candidateVisaAttachments = value?.map((e) => e.props)
  }

  /**
   * Whether the selected visa type has children entities or not.
   */
  get visaTypeHasChildren(): boolean {
    if (!this.visaType) return false
    return this.visaType.hasChildren
  }

  /**
   * Checks whether the card's back attachment exists.
   */
  get cardBackExists(): boolean {
    if (this.cardBack && this.cardBack.url !== undefined && this.cardBack.url.trim().length > 0) return true
    return false
  }

  /**
   * Checks whether the card's front attachment exists.
   */
  get cardFrontExists(): boolean {
    if (this.cardFront && this.cardFront.url !== undefined && this.cardFront.url.trim().length > 0) return true
    return false
  }

  /**
   * Whether the currently selected visa type or visa subtype requires designation papers to be uploaded.
   */
  get requiresDesignationPapers(): boolean {
    if (!this.visaType) return false
    const visaTypeRequiresDesignationPapers = this.visaType.designationPapersRequired
    const visaSubTypeRequiresDesignationPapers =
      this.visaSubType?.isParent(this.visaType) && this.visaSubType?.designationPapersRequired

    const isRequired = visaTypeRequiresDesignationPapers || visaSubTypeRequiresDesignationPapers
    return !!isRequired
  }

  /**
   * Whether the visa type selection has been fulfilled or not.
   */
  get visaTypeHasBeenSelected(): boolean {
    if (this.visaTypeHasChildren) return !!this.visaSubTypeId
    return !!this.visaTypeId
  }

  /**
   * Whether the visa type has a residence card or not.
   */
  get hasResidenceCard(): boolean {
    if (this.visaTypeHasChildren) {
      if (!this.visaSubType) {
        return false
      }
      return this.visaSubType.hasResidenceCard
    } else if (!this.visaType) {
      return false
    }
    return this.visaType.hasResidenceCard
  }

  /**
   * Whether the visa type has an expiration date or not.
   */
  get hasExpirationDate(): boolean {
    if (this.visaTypeHasChildren) {
      if (!this.visaSubType) {
        return false
      }
      return this.visaSubType.hasExpirationDate
    } else if (!this.visaType) {
      return false
    }
    return this.visaType.hasExpirationDate
  }

  /**
   * This checks if this visa type is Japanese Citizen
   * @returns
   */
  get isJapaneseCitizen(): boolean {
    if (!this.visaType) return false
    return this.visaType.isJapaneseCitizen
  }

  /**
   * Returns the list of current visa types. If a subtype is selected, that one takes precedence over a normal visa type.
   * @param visaTypes
   * @returns
   */
  getLowermostSelectedVisaType(): VisaTypeEntity[] {
    let selectedVisaTypes: VisaTypeEntity[] = []
    if (this.visaSubType && this.visaSubType.id > 0) {
      selectedVisaTypes.push(this.visaSubType)
    } else if (this.visaType && this.visaType.id > 0) {
      selectedVisaTypes.push(this.visaType)
    }
    return selectedVisaTypes
  }

  /**
   * Returns the list of current visa types as FormInputData objects. If a subtype is selected, that one takes precedence over a normal visa type.
   * @param visaTypes
   * @returns
   */
  getLowermostSelectedVisaTypeFormInputData(): IFormInputData[] {
    let selectedVisaTypes = this.getLowermostSelectedVisaType().map((e) => {
      return { label: e.name, value: e.id }
    })
    return selectedVisaTypes
  }
}

/**
 * @remarks Moved here to avoid circular dependency issues.
 */
export class CandidateVisaAttachmentEntityFactory {
  static defaultProps(props?: Partial<ICandidateVisaAttachment>): ICandidateVisaAttachment {
    return {
      candidateVisaId: 0,
      attachment: {},
      ...props,
    }
  }

  static new(props?: Partial<ICandidateVisaAttachment>): CandidateVisaAttachmentEntity {
    return new CandidateVisaAttachmentEntity(CandidateVisaAttachmentEntityFactory.defaultProps(props))
  }
}

export class CandidateVisaEntityFactory {
  static defaultProps(props?: Partial<ICandidateVisa>): ICandidateVisa {
    return {
      ...props,
    }
  }

  static new(props?: Partial<ICandidateVisa>): CandidateVisaEntity {
    if (props) {
      props.visaType = props?.visaType || VisaTypePropsFactory()
    }
    return new CandidateVisaEntity(CandidateVisaEntityFactory.defaultProps(props))
  }
}
