import {
  ApplicationCandidateStatusId,
  ApplicationWithParentLabelOnlyCandidateStatus,
  IApplicationStatusCandidateLog,
  IStatusLabels,
} from '../../../models';
import { ApplicationCandidateStatusGetters } from './application-status-candidate.getters';
import { ApplicationCandidateSubStatusGetters } from './application-substatus-candidate.getters';

export class ApplicationCandidateStatusLogGetters {
  /**
   * Returns a formatted label for the given status log.
   * If substatus exists, combines parent and sub labels, otherwise returns parent label.
   * @param statusLog
   * @returns
   */
  static getStatusLabel(statusLog: IApplicationStatusCandidateLog): IStatusLabels {
    // if (!statusLog.applicationStatusCandidate) return '';
    const statusLabel = ApplicationCandidateStatusGetters.getName(statusLog.applicationStatusCandidate!);
    // Some status do not need a substatus label. Ignore those.
    if (!this.needsSubstatusLabel(statusLog)) return { status: statusLabel, subStatus: statusLabel };
    // if (!statusLog.applicationSubStatusCandidate) return parentLabel;
    let subStatusLabel = ApplicationCandidateSubStatusGetters.getName(statusLog.applicationSubStatusCandidate!);
    return { status: statusLabel, subStatus: subStatusLabel };
  }

  /**
   * Determines whether a substatus label is needed for the given status log.
   * @remarks
   * For the new application, we set the substatus to be the same as the parent
   * We should probably remove the child statuses for it
   * At the moment, the substatuses are only used to tell the
   * application source, but this is better done through other data fields instead
   * @param statusLog
   * @returns
   */
  static needsSubstatusLabel(statusLog: IApplicationStatusCandidateLog): boolean {
    if (!statusLog.applicationStatusCandidate) return false;
    if (!statusLog.applicationStatusCandidateId) return false;
    const isInParentLabelOnlyArray = ApplicationWithParentLabelOnlyCandidateStatus.includes(
      statusLog.applicationStatusCandidateId! as ApplicationCandidateStatusId,
    );
    return !isInParentLabelOnlyArray;
  }
}
