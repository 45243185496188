import { QueryStringEntity } from '@tokyojob/frontend-core'
import { FetchBenefitCategoryRequest } from '../network/requests/benefit'
import BenefitCategoryEntity, {
  BenefitCategoryEntityFactory
} from '../entities/benefit.category'
import { GatewayBase } from '@/base/gateways/base'

export default class Benefit extends GatewayBase {
  async FetchBenefitCategories(
    options?: QueryStringEntity
  ): Promise<BenefitCategoryEntity[]> {
    const categories = await this.apiClient.request(
      new FetchBenefitCategoryRequest(options)
    )
    return categories.map((item) => BenefitCategoryEntityFactory(item))
  }
}
