import { IsBoolean, IsDefined, IsEnum, IsNumber, IsOptional, IsString, Min } from 'class-validator';
import { CandidateJapaneseLanguageSkillsDto } from '../../candidate/dto/candidate-japanese-language-skills.dto';
import { CandidateNameDto } from '../../candidate/dto/candidate-name.dto';
import { CandidatePersonalInformationDto } from '../../candidate/dto/candidate-personal-information.dto';
import { CandidateVisaTypeDto } from '../../candidate-visa/dto/candidate-visa-type.dto';
import { LanguageLocale } from '../../language';
import { UserContactsDto } from '../../user/dto/user-contacts.dto';

/**
 * Validation rules and data structure for new applications performed by unregistered users.
 */
export class AnonymousApplicationDto {
  @IsDefined()
  nameDto: CandidateNameDto;

  @IsDefined()
  visaDto: CandidateVisaTypeDto;

  @IsDefined()
  personalInformationDto: CandidatePersonalInformationDto;

  @IsDefined()
  japaneseSkillsDto: CandidateJapaneseLanguageSkillsDto;

  @IsDefined()
  contactsDto: UserContactsDto;

  @IsDefined()
  @IsNumber()
  @Min(1) // Any vacancy id of 1 or greater can in theory exist.
  vacancyId: number;

  @IsBoolean()
  residenceCardConfirmation: boolean;

  @IsEnum(LanguageLocale)
  locale: LanguageLocale
}
