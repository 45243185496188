import { GenericOptionsPropsFactory, OptionsPropsFactory } from '../../interfaces/factories/options'
import { ICustomQueryStringData, IGenericQueryStringProps, IQueryStringProps } from '../../interfaces/options'
import { GenericQueryStringEntity, QueryStringEntity } from '../options'

export const QueryStringEntityFactory = (props?: Partial<IQueryStringProps>): QueryStringEntity => {
  return new QueryStringEntity(OptionsPropsFactory(props))
}

// TODO: Delete the old QueryStringEntityFactory
export const GenericQueryStringEntityFactory = <T extends ICustomQueryStringData>(props?: Partial<IGenericQueryStringProps & T>): GenericQueryStringEntity<T> => {
  return new GenericQueryStringEntity<T>(GenericOptionsPropsFactory(props));
}