import VueRouter from 'vue-router'
import { LinkTarget } from './enum'
import { IRouterLink } from './interface/i-router-link'

/**
 * This class contains the properties of a RouterLink
 * @example Used to pass routerlinks around components library etc without breaking
 * @deprecated just use the interface
 */
export class RouterLink {
  constructor(props: IRouterLink) {
    this.label = props.label
    this.router = props.router
    this.link = props.link
    this.target = props.target
  }
  /**
   * Used by c-breadcrumb or other places a label is required
   */
  label?: string
  /**
   * The VueRouter instance to be used by another library
   */
  router?: VueRouter
  /**
   * The path used by the router instance
   */
  link?: string
  /**
   * Setting target _blank when required
   */
  target?: LinkTarget
}
