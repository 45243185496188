
import { defineComponent } from 'vue'
import { HtmlAttributesFactory } from '@tokyojob/frontend-core'

export default defineComponent({
  head() {
    return {
      htmlAttrs: HtmlAttributesFactory.basicAttributes(this.App.localizationSelector.languageISO6391)
    }
  }
})
