export enum ApplicationCandidateSubStatusId {
  NewApplicationWebGuest = 101,
  NewApplicationWebAccount = 102,
  NewApplicationIOS = 103,
  NewApplicationMessenger = 104,
  CheckWaitingForAgent = 201,
  CheckOther = 202,
  ContactingWaitingForAgentContact = 301,
  ContactingAgentCouldNotGetInContactAfter3Attempts = 302,
  ContactingAgentOther = 303,
  RejecteVacancyFilled = 401,
  RejecteCandidateWithdrew = 402,
  RejecteInsufficientJapaneseLevel = 403,
  RejecteInsufficientDocumentation = 404,
  RejecteInvalidVisa = 405,
  RejecteCouldNotGetInContact = 406,
  RejecteCandidateMissedTheInterview = 407,
  RejectedOther = 408,
  InterviewWaitingForInterviewSchedule = 501,
  InterviewScheduled = 502,
  InterviewInterviewOther = 503,
  HiredStarted = 1001,
  HiredCandidateWithdrewAfterEmployment = 1002,
  HiredFiredQuitAfterEmployment = 1003,
  HiredOther = 1004,
  WithdrawnIHaveAnotherOffer = 1101,
  WithdrawnNotInterestedInThePositionAnymore = 1102,
  WithdrawnLocationTooFarAway = 1103,
  WithdrawnJobNotWhatWasExpected = 1104,
  WithdrawnOther = 1105,
  Memo = 9999999,
}
