import { Context } from '@nuxt/types/app'
import { defineNuxtPlugin } from '@nuxtjs/composition-api'
import { FilterKey, IJoomlaCMSLayoutContent, LanguageLocale, LanguageLocaleToURLMapper, waitFor } from '@tokyojob/core'
import { JapaneseLanguageListUsecase, QueryStringEntityFactory, getCountryStore, getPrefectureStore } from '@tokyojob/frontend-core'
import JobCategoryHierarchyGetUsecase from '~/modules/category/usecase/job-category-hierarchy-get.usecase'
import LocationHierarchyGetUsecase from '~/modules/category/usecase/location-hierarchy-get.usecase'
import VisaTypeHierarchyGetUsecase from '~/modules/category/usecase/visa-type-hierarchy-get.usecase'
import { getAppStore } from '~/store/app.store'
import { getSideMenuContentStore } from '~/store/side-menu.store'
import { getVacancyTreeFilterData } from '@tokyojob/frontend-components'

export default defineNuxtPlugin(async (ctx: Context, redirect) => {
  await waitFor(() => !!ctx.App)

  const App = ctx.App
  const locale = App.localizationSelector.languageLocale
  const vacancyTreeFilterDataStore = getVacancyTreeFilterData()

  const jobCategoryHierarchyFetch = async () => {
    const jobCategoryHierarchyUseCase = new JobCategoryHierarchyGetUsecase(App)
    const jobCategoryHierarchy = await jobCategoryHierarchyUseCase.execute(locale)
    vacancyTreeFilterDataStore.save(FilterKey.category, jobCategoryHierarchy)
  }

  const locationHierarchyFetch = async () => {
    const locationHierarchyUseCase = new LocationHierarchyGetUsecase(App)
    const locationHierarchy = await locationHierarchyUseCase.execute(locale)
    vacancyTreeFilterDataStore.save(FilterKey.location, locationHierarchy)
  }

  const visaTypeHierarchyFetch = async () => {
    const visaTypeHierarchyUseCase = new VisaTypeHierarchyGetUsecase(App)
    const visaTypeHierarchy = await visaTypeHierarchyUseCase.execute(locale)
    vacancyTreeFilterDataStore.save(FilterKey.visa, visaTypeHierarchy)
  }

  const countriesFetch = async () => {
    const countryStore = getCountryStore()
    const countries = await App.baseGateway.geolocation.FetchCountryListV3(locale)
    countryStore.save(countries)
  }

  const prefectureFetch = async () => {
    const prefectureStore = getPrefectureStore()
    const queryParams = QueryStringEntityFactory({ locale: locale })
    const prefectures = await App.baseGateway.location.FetchPrefectureList(queryParams)
    prefectureStore.save(prefectures)
  }

  const japaneseLanguageListFetch = async () => {
    const usecase = new JapaneseLanguageListUsecase(App)
    await usecase.execute()
  }

  await Promise.all([
    jobCategoryHierarchyFetch(),
    locationHierarchyFetch(),
    visaTypeHierarchyFetch(),
    countriesFetch(),
    prefectureFetch(),
    japaneseLanguageListFetch(),
  ])

  await App.candidateFullProfileDomain?.fullFetchRequest(locale)

  const sideMenuContentStore = getSideMenuContentStore()
  sideMenuContentStore.save(LanguageLocale.USen, {
    sidebar: [
      { title: 'Articles', route: '/articles', icon: 'fas fa-file-lines' },
      { title: 'Support', route: '/support', icon: 'fas fa-circle-question' }
    ],
    footer: [
      { title: 'Company Profile', route: '/about' },
      { title: 'Privacy Policy', route: '/terms-and-conditions' },
      { title: 'Support', route: '/support' }
    ],
    section_titles: {
      app_section: { title: 'Job Search' },
      joomla_section: { title: 'Learn More' },
      options_section: { title: 'Options' }
    },
    newsletter_link: { link: '/newsletter' }
  })
  sideMenuContentStore.save(LanguageLocale.JAjp, {
    sidebar: [
      {
        title: '記事',
        route: '/jp/kiji',
        icon: 'fas fa-file-lines'
      },
      {
        title: 'サポート',
        route: '/jp/sapooto',
        icon: 'fas fa-circle-question'
      }
    ],
    footer: [
      { title: '会社概要', route: '/jp/kaisha-gaiyoo' },
      {
        title: 'プライバシーポリシー',
        route: '/jp/puraibashii-porishii'
      },
      { title: 'サポート', route: '/jp/sapooto' }
    ],
    section_titles: {
      app_section: {
        title: '求人検索'
      },
      joomla_section: {
        title: '詳細を学ぶ'
      },
      options_section: {
        title: '選択肢'
      }
    },
    newsletter_link: { link: '/jp/newsletter' }
  })
  sideMenuContentStore.save(LanguageLocale.VIvn, {
    sidebar: [
      {
        title: 'Bài viết',
        route: '/vi/bai-viet',
        icon: 'fas fa-file-lines'
      },
      {
        title: 'Hỗ trợ',
        route: '/vi/ho-tro',
        icon: 'fas fa-circle-question'
      }
    ],
    footer: [
      {
        title: 'Giới thiệu công ty',
        route: '/vi/gioi-thieu-cong-ty'
      },
      {
        title: 'Chính sách bảo mật',
        route: '/vi/chinh-sach-bao-mat'
      },
      {
        title: 'Hỗ trợ',
        route: '/vi/ho-tro'
      }
    ],
    section_titles: {
      app_section: {
        title: 'Tìm kiếm việc làm'
      },
      joomla_section: {
        title: 'Tìm hiểu thêm'
      },
      options_section: {
        title: 'Các tùy chọn'
      }
    },
    newsletter_link: {
      link: '/vi/ban-tin'
    }
  })
  sideMenuContentStore.save(LanguageLocale.ZHcn, {
    sidebar: [
      {
        title: '文章',
        route: '/zh/wen-zhang',
        icon: 'fas fa-file-lines'
      },
      {
        title: '支持',
        route: '/zh/zhi-chi',
        icon: 'fas fa-circle-question'
      }
    ],
    footer: [
      {
        title: '公司简介',
        route: '/zh/gong-si-jian-jie'
      },
      {
        title: '隐私政策',
        route: '/zh/yin-si-zheng-ce'
      },
      {
        title: '支持',
        route: '/zh/zhi-chi'
      }
    ],
    section_titles: {
      app_section: {
        title: '求职'
      },
      joomla_section: {
        title: '了解更多'
      },
      options_section: {
        title: '选项'
      }
    },
    newsletter_link: {
      link: '/zh/tong-xun'
    }
  })

  if (process.client) {
    const appStore = getAppStore()
    const user = appStore.profile
    if (user && user.locale) {
      const app = ctx.App
      if (app.localizationSelector.languageLocale != user.locale) {
        const language = LanguageLocaleToURLMapper[user.locale]
        app.localizationSelector.changeLanguage(language, ctx.route)
      }
    }
  }
})
