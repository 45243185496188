import { defineStore } from 'pinia'
import { VacancyMapPointGetters } from '../../../../core/src/domain/vacancy/getters/vacancy-map.getters'
import { IVacancyMapPoint } from '../../../../core/src/models/vacancy/interface/i-vacancy-map-point'

interface State {
  items: Record<string, IVacancyMapPoint>
}

export const getVacancyMapStore = defineStore('vacancyMap', {
  state: (): State => ({
    items: {}
  }),
  actions: {
    save(items: IVacancyMapPoint | IVacancyMapPoint[]) {
      this.items = {}
      if (!Array.isArray(items)) {
        items = [items]
      }
      items.forEach((e: IVacancyMapPoint) => {
        const id = VacancyMapPointGetters.getCoordinateStr(e)
        if (!id) return
        this.items[id] = e
      })
    },
    remove(item: IVacancyMapPoint) {
      const id = VacancyMapPointGetters.getCoordinateStr(item)
      if (id && !this.items[id]) delete this.items[id]
    },
    all(): IVacancyMapPoint[] {
      const values = Object.values<IVacancyMapPoint>(this.items)
      return values ? values : []
    },
    get(id: string): IVacancyMapPoint | null {
      const item = this.items[id]
      return item ? item : null
    }
  }
})
