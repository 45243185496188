import { APIRequest, HTTPMethod } from '@tokyojob/frontend-core'
import { ICandidateBirthdateSeed } from '../../entities/candidate.birthdate'
import { ICandidateContactSeed } from '../../entities/candidate.contact'
import { ICandidateDesignationPapersSeed } from '../../entities/candidate.designation.papers'
import { ICandidateGenderSeed } from '../../entities/candidate.gender'
import { ICandidateJapanArrivalDateSeed } from '../../entities/candidate.japan.arrival.date'
import { ICandidateJapaneseConversationSeed } from '../../entities/candidate.japanese.conversation.skill'
import { ICandidateJapaneseListeningSeed } from '../../entities/candidate.japanese.listening.skill'
import { ICandidateJapaneseReadingSeed } from '../../entities/candidate.japanese.reading.skill'
import { ICandidateJapaneseWritingSeed } from '../../entities/candidate.japanese.writing.skill'
import { ICandidatePersonalIdsSeed } from '../../entities/candidate.personal.ids'
import { ICandidatePersonalInformationSeed } from '../../entities/candidate.personal.information'
import { ICandidateResidenceCardBackSeed } from '../../entities/candidate.residence.card.back'
import { ICandidateResidenceCardFrontSeed } from '../../entities/candidate.residence.card.front'
import { ICandidateVisaTypeSeed } from '../../entities/candidate.visa.type'
import {
  SpaceCandidateBirthdateOnboarding,
  SpaceCandidateContactInformationOnboarding,
  SpaceCandidateDesignationPapersOnboarding,
  SpaceCandidateGenderOnboarding,
  SpaceCandidateJapanArrivalDateOnboarding,
  SpaceCandidateJapaneseConversationSkillOnboarding,
  SpaceCandidateJapaneseListeningSkillOnboarding,
  SpaceCandidateJapaneseReadingSkillOnboarding,
  SpaceCandidateJapaneseWritingSkillOnboarding,
  SpaceCandidatePersonalIdsOnboarding,
  SpaceCandidatePersonalInformationOnboarding,
  SpaceCandidateResidenceCardBackOnboarding,
  SpaceCandidateResidenceCardFrontOnboarding,
  SpaceCandidateVisaTypeOnboarding
} from '../schema/namespace'
const pathRoot = '/onboarding/candidate'

/**
 * HTTP Request to update current candidate visa type information
 */
export class UpdateCandidateVisaType implements APIRequest<SpaceCandidateVisaTypeOnboarding.UpdateVisaTypeResponse> {
  response!: SpaceCandidateVisaTypeOnboarding.UpdateVisaTypeResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.PUT
  params: SpaceCandidateVisaTypeOnboarding.UpdateVisaTypeRequest
  constructor(seed: ICandidateVisaTypeSeed) {
    this.params = seed
    this.path = `${pathRoot}/profile/visa`
  }
}

/**
 * HTTP Request to update current candidate's residence card front information
 */
export class UpdateCandidateResidenceCardFrontFile
  implements APIRequest<SpaceCandidateResidenceCardFrontOnboarding.UpdateResidenceCardFrontResponse> {
  response!: SpaceCandidateResidenceCardFrontOnboarding.UpdateResidenceCardFrontResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.PUT
  params: SpaceCandidateResidenceCardFrontOnboarding.UpdateResidenceCardFrontRequest
  constructor(seed: ICandidateResidenceCardFrontSeed) {
    this.params = seed
    this.path = `${pathRoot}/profile/residence.card.front`
  }
}

/**
 * HTTP Request to update current candidate's residence card front information
 */
export class UpdateCandidateResidenceCardBackFile
  implements APIRequest<SpaceCandidateResidenceCardBackOnboarding.UpdateResidenceCardBackResponse> {
  response!: SpaceCandidateResidenceCardBackOnboarding.UpdateResidenceCardBackResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.PUT
  params: SpaceCandidateResidenceCardBackOnboarding.UpdateResidenceCardBackRequest
  constructor(seed: ICandidateResidenceCardBackSeed) {
    this.params = seed
    this.path = `${pathRoot}/profile/residence.card.back`
  }
}

/**
 * HTTP Request to update current candidate's residence card information
 */
export class UpdateCandidateDesignationPaperFiles
  implements APIRequest<SpaceCandidateDesignationPapersOnboarding.UpdateDesignationPapersResponse> {
  response!: SpaceCandidateDesignationPapersOnboarding.UpdateDesignationPapersResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.PUT
  params: SpaceCandidateDesignationPapersOnboarding.UpdateDesignationPapersRequest
  constructor(seed: ICandidateDesignationPapersSeed) {
    this.params = seed
    this.path = `${pathRoot}/profile/designation.papers`
  }
}

/**
 * HTTP Request to update current candidate's personal id attachments
 */
export class UpdateCandidatePersonalIdFiles
  implements APIRequest<SpaceCandidatePersonalIdsOnboarding.UpdateCandidatePersonalIdsResponse> {
  response!: SpaceCandidatePersonalIdsOnboarding.UpdateCandidatePersonalIdsResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: SpaceCandidatePersonalIdsOnboarding.UpdateCandidatePersonalIdsRequest
  constructor(seed: ICandidatePersonalIdsSeed) {
    this.params = seed
    this.path = `${pathRoot}/profile/personal.id`
  }
}

/**
 * HTTP Request to update current candidate visa type information
 */
export class UpdateCandidatePersonalInformation
  implements APIRequest<SpaceCandidatePersonalInformationOnboarding.UpdatePersonalInformationResponse> {
  response!: SpaceCandidatePersonalInformationOnboarding.UpdatePersonalInformationResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.PUT
  params: SpaceCandidatePersonalInformationOnboarding.UpdatePersonalInformationRequest
  constructor(seed: ICandidatePersonalInformationSeed) {
    this.params = seed
    this.path = `${pathRoot}/profile/personal`
  }
}

/**
 * HTTP Request to update the japan arrival date information
 */
export class UpdateCandidateJapanArrivalDate
  implements APIRequest<SpaceCandidateJapanArrivalDateOnboarding.UpdateJapanArrivalDateResponse> {
  response!: SpaceCandidateJapanArrivalDateOnboarding.UpdateJapanArrivalDateResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.PUT
  params: SpaceCandidateJapanArrivalDateOnboarding.UpdateJapanArrivalDateRequest
  constructor(seed: ICandidateJapanArrivalDateSeed) {
    this.params = seed
    this.path = `${pathRoot}/profile/japan.arrival.date`
  }
}

/**
 * HTTP Request to update the gender information
 */
export class UpdateCandidateGender implements APIRequest<SpaceCandidateGenderOnboarding.UpdateGenderResponse> {
  response!: SpaceCandidateGenderOnboarding.UpdateGenderResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.PUT
  params: SpaceCandidateGenderOnboarding.UpdateGenderRequest
  constructor(seed: ICandidateGenderSeed) {
    this.params = seed
    this.path = `${pathRoot}/profile/gender`
  }
}

/**
 * HTTP Request to update the birthdate information
 */
export class UpdateBirthdate implements APIRequest<SpaceCandidateBirthdateOnboarding.UpdateBirthdateResponse> {
  response!: SpaceCandidateBirthdateOnboarding.UpdateBirthdateResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.PUT
  params: SpaceCandidateBirthdateOnboarding.UpdateBirthdateRequest
  constructor(seed: ICandidateBirthdateSeed) {
    this.params = seed
    this.path = `${pathRoot}/profile/birthdate`
  }
}

/**
 * HTTP Request to update the contact information
 */
export class UpdateContactInformation
  implements APIRequest<SpaceCandidateContactInformationOnboarding.UpdateContactInformationResponse> {
  response!: SpaceCandidateContactInformationOnboarding.UpdateContactInformationResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.PUT
  params: SpaceCandidateContactInformationOnboarding.UpdateContactInformationRequest
  constructor(seed: ICandidateContactSeed) {
    this.params = seed
    this.path = `${pathRoot}/profile/contact`
  }
}

/**
 * HTTP Request to update the japanese conversation skill
 */
export class UpdateJapaneseConversationSkill
  implements APIRequest<SpaceCandidateJapaneseConversationSkillOnboarding.UpdateJapaneseConversationSkillResponse> {
  response!: SpaceCandidateJapaneseConversationSkillOnboarding.UpdateJapaneseConversationSkillResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.PUT
  params: SpaceCandidateJapaneseConversationSkillOnboarding.UpdateJapaneseConversationSkillRequest
  constructor(seed: ICandidateJapaneseConversationSeed) {
    this.params = seed
    this.path = `${pathRoot}/language/conversation`
  }
}

/**
 * HTTP Request to update the japanese listening skill
 */
export class UpdateJapaneseListeningSkill
  implements APIRequest<SpaceCandidateJapaneseListeningSkillOnboarding.UpdateJapaneseListeningSkillResponse> {
  response!: SpaceCandidateJapaneseListeningSkillOnboarding.UpdateJapaneseListeningSkillResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.PUT
  params: SpaceCandidateJapaneseListeningSkillOnboarding.UpdateJapaneseListeningSkillRequest
  constructor(seed: ICandidateJapaneseListeningSeed) {
    this.params = seed
    this.path = `${pathRoot}/language/listening`
  }
}

/**
 * HTTP Request to update the japanese reading skill
 */
export class UpdateJapaneseReadingSkill
  implements APIRequest<SpaceCandidateJapaneseReadingSkillOnboarding.UpdateJapaneseReadingSkillResponse> {
  response!: SpaceCandidateJapaneseReadingSkillOnboarding.UpdateJapaneseReadingSkillResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.PUT
  params: SpaceCandidateJapaneseReadingSkillOnboarding.UpdateJapaneseReadingSkillRequest
  constructor(seed: ICandidateJapaneseReadingSeed) {
    this.params = seed
    this.path = `${pathRoot}/language/reading`
  }
}

/**
 * HTTP Request to update the japanese writing skill
 */
export class UpdateJapaneseWritingSkill
  implements APIRequest<SpaceCandidateJapaneseWritingSkillOnboarding.UpdateJapaneseWritingSkillResponse> {
  response!: SpaceCandidateJapaneseWritingSkillOnboarding.UpdateJapaneseWritingSkillResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.PUT
  params: SpaceCandidateJapaneseWritingSkillOnboarding.UpdateJapaneseWritingSkillRequest
  constructor(seed: ICandidateJapaneseWritingSeed) {
    this.params = seed
    this.path = `${pathRoot}/language/writing`
  }
}
