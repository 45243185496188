import { ImageMimeType } from '../../models';

/**
 * @remarks Added "helper" to the name because there seems to be a deprecated MimeType class in node.
 */
export class ImageMimeTypeHelper {
  getExtension(mimeType: ImageMimeType): string {
    switch (mimeType) {
      case ImageMimeType.JPG:
        return '.jpg';
      case ImageMimeType.JPEG:
        return 'jpeg';
      case ImageMimeType.PNG:
        return 'png';
      case ImageMimeType.GIF:
        return 'gif';
      case ImageMimeType.WEBP:
        return 'webp';
    }
  }
}
