import { Skill } from '../network/schema/models'
import { SkillInfo } from '../network/schema/models/skill'

export interface ISkillProps extends Skill {}

export default class SkillEntity {
  private _props: ISkillProps

  constructor(props: ISkillProps) {
    this._props = props
  }

  get props(): ISkillProps {
    return this._props
  }

  get id(): string {
    return this.props.id
  }

  get informations(): SkillInfo[] {
    return this.props.informations
  }

  get year(): string | undefined {
    return this.props.year
  }

  set year(value: string | undefined) {
    this.props.year = value
  }

  /**
   * The name of the skill in the current language.
   */
  get name(): string {
    return this.props.informations[0].name
  }
}

export const EmptySkillPropsFactory = (
  props?: Partial<ISkillProps>
): ISkillProps => ({
  id: '',
  informations: [],
  ...props
})

export const SkillPropsFactory = (
  props?: Partial<ISkillProps>
): ISkillProps => ({
  id: '',
  informations: [],
  ...props
})

export const EmptySkillEntityFactory = (
  props?: Partial<ISkillProps>
): SkillEntity => {
  return new SkillEntity(EmptySkillPropsFactory(props))
}

export const SkillEntityFactory = (
  props?: Partial<ISkillProps>
): SkillEntity => {
  return new SkillEntity(SkillPropsFactory(props))
}
