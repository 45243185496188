
import { defineComponent, ref, Ref } from 'vue'
import {
  LanguageLocaleToOriginalMapper,
  LanguageOriginal,
  LanguageOriginalToURLMapper,
  LanguageURLToOriginalMapper
} from '@tokyojob/core'
import { CIcon, CIconFactory, CLanguageSwitchAccordion, CLoader } from '@tokyojob/frontend-components'
import { IApp } from '~/types/nuxt'
import { getProvidedApp, getRoute } from '~/base/utils/provided-getter'

const languageIcon = CIconFactory.Globe()
const downCarotIcon = CIconFactory.DropdownCaret()

export default defineComponent({
  components: { CIcon, CLanguageSwitchAccordion, CLoader },
  setup(props, { emit }) {
    const app: IApp = getProvidedApp()
    const route = getRoute()
    const showLanguageSwitchAccordion = ref(false)
    const languages = LanguageLocaleToOriginalMapper
    const currentLanguage = ref(LanguageURLToOriginalMapper[app.localizationSelector.language])
    const loadingOverlay: Ref<typeof CLoader> = ref(null) as any
    const methods = {
      /**
       * Change language using localizationSelector
       */
      async setLanguage(language: LanguageOriginal): Promise<void> {
        loadingOverlay.value.show()
        const languageURL = LanguageOriginalToURLMapper[language]
        currentLanguage.value = language
        showLanguageSwitchAccordion.value = false
        await app.localizationSelector.changeLanguage(languageURL, route.value)
        loadingOverlay.value.hide()
      },
      toggleLanguageSwitchModal(): void {
        showLanguageSwitchAccordion.value = !showLanguageSwitchAccordion.value
        emit('scrollToBottom')
      }
    }
    return {
      languageIcon,
      downCarotIcon,
      languages,
      methods,
      showLanguageSwitchAccordion,
      currentLanguage,
      loadingOverlay
    }
  }
})
