import { APIRequest, EnumDictionary, HTTPMethod } from '@tokyojob/frontend-core'
import { AttachmentType } from '../schema/models/attachment.type'

/**
 * List of available paths to use when saving a file to Cloud storage.
 * @remarks Some of the paths will include a ${userId} placeholder that should be replaced by an actual user id.
 * @example "user-data/candidate/${userId}/language-certificates/"
 */
const AttachmentTypePathSuffix: EnumDictionary<AttachmentType, string> = {
  [AttachmentType.CandidateLanguageCertificate]: '/certificate-image/',
  [AttachmentType.ResidenceCard]: '.candidate/residence.card/',
  [AttachmentType.DesignationPapers]: '.candidate/designation.papers/',
  [AttachmentType.ResidenceCardFront]: '.candidate/residence.card.front/',
  [AttachmentType.ResidenceCardBack]: '.candidate/residence.card.back/',
  [AttachmentType.TemporaryFile]: '/temporary/file',
  [AttachmentType.TemporaryImage]: '/temporary/image',
  [AttachmentType.ResumeAttachment]: '/resume/attachment',
  [AttachmentType.CandidateVisaAttachment]: '/candidate.visa/attachment'
}

export class CreateRequest implements APIRequest<any> {
  response!: any
  path: string
  contentType = 'application/json'
  method = HTTPMethod.POST
  params: any
  constructor(params: FormData, attachmentType: AttachmentType) {
    this.params = params
    this.path = `/attachment${AttachmentTypePathSuffix[attachmentType]}`
  }
}
