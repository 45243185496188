
import { CIcon } from '@tokyojob/frontend-components'
import { AppEnvironment, StaticURLBuilder } from '@tokyojob/frontend-core'
import Vue from 'vue'
import CMobileSidebar from '../../c-sidebar/mobile/c-mobile-sidebar.component.vue'

const vacancyListURL = '/'

export default Vue.extend({
  components: {
    CIcon,
    CMobileSidebar
  },
  watch: {
    $route({ path }) {
      this.isVacancyList = path === vacancyListURL
    }
  },
  data() {
    const isVacancyList = this.$route.path === vacancyListURL
    return {
      url: vacancyListURL,
      logo: StaticURLBuilder.logo(process.env.APP_ENV as AppEnvironment),
      isVacancyList
    }
  }
})
