import ContactEntity, {
  IContactProps,
  IContactSeed
} from '../../entities/contact'

export const toContactProps = (props: IContactProps): IContactProps => {
  const {
    id,
    account,
    other,
    notification,
    contactMethodId,
    contactMethod
  } = props
  return {
    id,
    account,
    other,
    notification,
    contactMethod,
    contactMethodId
  }
}

export const toContactSeed = (props: ContactEntity): IContactSeed => {
  const { account, other, contactMethodId } = props
  const notification = props.notification || false
  return {
    account,
    other,
    notification,
    contactMethodId
  }
}
