export class ObjectComparer {
  /**
   * Compares two objects for equality based on specified properties using JSON.stringify.
   * @param obj1 - The first object to compare.
   * @param obj2 - The second object to compare.
   * @param properties - The list of properties to check for equality.
   * @returns True if the objects are equal, false otherwise.
   */
  static arePropertiesEqual<T>(obj1: T, obj2: T, properties: (keyof T)[]): boolean {
    // If both objects are null or undefined, they are considered equal.
    if (obj1 == null && obj2 == null) return true;

    // If only one of the objects is null or undefined, they are not equal.
    if (obj1 == null || obj2 == null) return false;

    // Iterate through each specified property.
    for (const prop of properties) {
      const val1 = obj1[prop];
      const val2 = obj2[prop];

      // Skip function properties
      if (typeof val1 === 'function' || typeof val2 === 'function') continue;

      // Convert properties to JSON strings for comparison
      const strVal1 = JSON.stringify(val1);
      const strVal2 = JSON.stringify(val2);

      // If any property value differs between the objects, they are not equal.
      if (strVal1 !== strVal2) return false;
    }
    // If all properties are equal, the objects are considered equal.
    return true;
  }
}
